import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { createCompanyRole, updateCompanyRole, destroyCompanyRole } from "@ROM/CompanyRoles/actions";
import rolesApi from "@ROM/CompanyRoles/api";
import CompanyRole from "@ROM/CompanyAdmin/Company/Roles/CompanyRole";
import CompanyRoleCreateModal from "@ROM/CompanyAdmin/Company/Roles/CompanyRoleCreateModal";
import { ListItemCreate } from "@ROM-components/common/AdminList";

const CompanyRolesList = ({ companyRoles, activeRoleId, setActiveRoleId, className }) => {
  const dispatch = useDispatch();
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);

  const onCreate = async (roleName) => {
    await dispatch(createCompanyRole({ name: roleName }));
    setShowCreateRoleModal(false);
  };

  const onUpdate = async (roleId, roleName) => {
    await dispatch(updateCompanyRole(roleId, { name: roleName }));
  };

  const onDestroy = async (roleId) => {
    if (roleId === activeRoleId) setActiveRoleId(null);
    await dispatch(destroyCompanyRole(roleId));
  };

  const fetchRoleUsers = (id) => rolesApi.fetchCompanyRoleUsers(id);

  return (
    <div className={className}>
      <h3 className="mb-4">Current Roles</h3>
      {companyRoles.map((role) => (
        <CompanyRole
          key={role.id}
          className="mb-3"
          roleName={role.attributes.name}
          isActive={role.id === activeRoleId}
          setActiveRole={() => setActiveRoleId(role.id)}
          fetchRoleUsers={() => fetchRoleUsers(role.id)}
          onUpdate={(roleName) => onUpdate(role.id, roleName)}
          onDestroy={() => onDestroy(role.id)}
        />
      ))}
      <ListItemCreate text="Add Role" className="mb-4" onClick={() => setShowCreateRoleModal(true)} />
      <CompanyRoleCreateModal
        isShowing={showCreateRoleModal}
        onConfirm={onCreate}
        onCancel={() => setShowCreateRoleModal(false)}
      />
    </div>
  );
};

CompanyRolesList.defaultProps = {
  className: "",
  activeRoleId: null,
};

CompanyRolesList.propTypes = {
  className: PropTypes.string,
  companyRoles: PropTypes.arrayOf(PropTypes.shape).isRequired,
  activeRoleId: PropTypes.string,
  setActiveRoleId: PropTypes.func.isRequired,
};

export default CompanyRolesList;
