import React, { useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import Message from "@ROM-common/Message";
import Button from "@ROM-ui/Button";
import Form from "@ROM-ui/Form";
import Spinner from "@ROM-ui/Spinner";
import { companyAdminCustomTablesShowUrl } from "@ROM-utils/urlHelpers";
import { reducer } from "@ROM/App/utils/forms";
import { selectAllCustomTables } from "@ROM/CustomTables/selectors";
import { selectError, selectIsLoading } from "@ROM/CustomTableRows/selectors";
import { createRow, updateRow } from "@ROM/CustomTableRows/actions";
import RelationSelect from "@ROM/CustomTableRows/components/Form/RelationSelect";
import {
  AttachmentRowField,
  DateRowField,
  DateTimeRowField,
  NumberRowField,
  StringRowField,
} from "@ROM/CustomTableRows/components/Form/FormFields";

const fieldByType = (type) => {
  switch (type) {
    case "string":
      return StringRowField;
    case "number":
      return NumberRowField;
    case "date":
      return DateRowField;
    case "datetime":
      return DateTimeRowField;
    case "attachment":
      return AttachmentRowField;
    default:
      return null;
  }
};

const initialByType = (type, initial) => {
  const now = new Date().toISOString();
  switch (type) {
    case "string":
      return initial || "";
    case "number":
      return initial || 0;
    case "date":
      return initial ? new Date(initial) : now;
    case "datetime":
      return initial ? new Date(initial) : now;
    case "attachment":
      return initial ? { attributes: { file: initial }, id: "0", isOriginal: true } : null;
    default:
      return null;
  }
};

const initialRowRelations = (row) => {
  if (!row) {
    return {};
  }

  const rowRelations = row.attributes.relations;

  return Object.keys(rowRelations).reduce(
    (acc, relatedTableName) => ({
      ...acc,
      [relatedTableName]: rowRelations[relatedTableName].map((relatedRow) => relatedRow.id),
    }),
    {}
  );
};

const RowField = ({ field, value, update, errorText }) => {
  const Component = fieldByType(field.type);
  return (
    <Form.Group className="mb-3">
      <Form.Label>{field.displayName || field.name}</Form.Label>

      <Component
        value={value}
        required={field.required}
        onChange={(newValue) => update(field.name, newValue)}
        isInvalid={errorText !== null}
      />
      <Form.Control.Feedback type="invalid" className={errorText !== null ? "d-block" : ""}>
        {errorText}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

RowField.propTypes = {
  field: PropTypes.shape().isRequired,
  value: PropTypes.oneOf(PropTypes.string, PropTypes.number).isRequired,
  update: PropTypes.func.isRequired,
  errorText: PropTypes.string,
};

RowField.defaultProps = {
  errorText: null,
};

const fieldError = (state, field) => {
  if (field.required && (state === undefined || state === null || state === "")) {
    return { [field.name]: `${field.displayName || field.name} is required` };
  }
  return {};
};

/**
 * Removes unchanged attachments from state to avoid sending them on PATCH.
 */
const clearUpdateState = (state, fields) => {
  const fieldTypes = fields.reduce((acc, field) => ({ ...acc, [field.name]: field.type }), {});
  return Object.keys(state).reduce((acc, field) => {
    if (fieldTypes[field] === "attachment" && state[field]?.isOriginal) return acc;
    return { ...acc, [field]: state[field] };
  }, {});
};

const CustomTableRowForm = ({ table, row }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const customTables = useSelector(selectAllCustomTables);

  const {
    attributes: { fields, relations },
  } = table;
  const data = row?.attributes.data || {};

  const initialValue = fields.reduce((acc, field) => ({ ...acc, [field.name]: initialByType(field.type, data[field.name]) }), {});

  const [state, setState] = useReducer(reducer, initialValue);
  const [rowRelations, setRowRelations] = useReducer(reducer, initialRowRelations(row));

  const onFieldUpdate = (fieldName, value) => {
    setState({ [fieldName]: value });
  };

  const onRelationUpdate = (tableName, value) => {
    setRowRelations({ [tableName]: value });
  };

  const onSubmit = async () => {
    const newErrors = fields.reduce((acc, field) => ({ ...acc, ...fieldError(state[field.name], field) }), {});

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const action = row
        ? updateRow(table.id, row.id, { data: clearUpdateState(state, fields), relations: rowRelations })
        : createRow(table.id, { data: state, relations: rowRelations });
      const message = `Row ${row ? "updated" : "created"} successfully`;
      const response = await dispatch(action);
      if (!response.error) {
        history.push(companyAdminCustomTablesShowUrl(table.id), { message });
      }
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <StyledForm>
      <Message message={error} type="danger" />
      {fields.map((field) => (
        <RowField
          key={field.name}
          field={field}
          value={state[field.name]}
          update={onFieldUpdate}
          errorText={errors[field.name]}
        />
      ))}
      {relations.map((relation) => (
        <RelationSelect
          key={relation.withTable}
          relatedTable={customTables.find((customTable) => customTable.attributes.name === relation.withTable)}
          values={rowRelations[relation.withTable] || []}
          update={(value) => onRelationUpdate(relation.withTable, value)}
          className="mb-3"
        />
      ))}
      <Button variant="success" onClick={onSubmit} disabled={isLoading}>
        {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
        {row ? "Update" : "Add"}
      </Button>
    </StyledForm>
  );
};

CustomTableRowForm.propTypes = {
  table: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape()),
      relations: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      data: PropTypes.shape(),
      relations: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }),
};

CustomTableRowForm.defaultProps = {
  row: null,
};

const StyledForm = styled(Form)`
  max-width: 650px;
`;

export default CustomTableRowForm;
