import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";
import humps from "humps";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/products";
  },

  resource(id) {
    return `/products/${id}`;
  },

  topProducts() {
    return "/products/top";
  },
  featuredProducts() {
    return "/products/featured";
  },
};

export default {
  list(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(), {
      params: {
        ...options,
      },
    });
  },

  top(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.topProducts(), {
      params: {
        ...options,
      },
    });
  },

  featured(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.featuredProducts(), { params: options });
  },

  find(id) {
    const client = getApiClient(API_URL);
    return client.get(urls.resource(id));
  },

  update(id, product) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(id), humps.decamelizeKeys(product));
  },

  create(product) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(), humps.decamelizeKeys(product));
  },

  destroy(id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(id));
  },
  createAsset(id, asset) {
    const client = getApiClient(API_URL);
    return client.post(`${urls.resource(id)}/create_asset`, humps.decamelizeKeys(asset));
  },
  deleteAsset(id, asset) {
    const client = getApiClient(API_URL);
    return client.delete(`${urls.resource(id)}/delete_asset`, humps.decamelizeKeys(asset));
  },
};
