import React from "react";
import PropTypes from "prop-types";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";
import Select from "@ROM-components/common/Select";
import CalendarInput from "@ROM-components/common/CalendarInput";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import moment from "moment";
import constants from "@ROM/App/constants";

const PriceListForm = ({ priceList, handleUserInput }) => {
  const options = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "old", label: "Old" },
  ];

  return (
    <Form className="mb-3">
      <Row>
        <Col className="mb-2">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={priceList.name}
            onChange={(e) => handleUserInput("name", e.target.value)}
            disabled={priceList.isFromIntacct}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Form.Label>From date</Form.Label>
          <DayPickerInput
            value={priceList.dateFrom ? moment(priceList.dateFrom).format(constants.date.MMDDYYYY) : priceList.dateFrom}
            onDayChange={(date) => handleUserInput("dateFrom", date)}
            component={CalendarInput}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder=""
            inputProps={{ disabled: priceList.isFromIntacct }}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Form.Label>To date</Form.Label>
          <DayPickerInput
            value={priceList.dateTo ? moment(priceList.dateTo).format(constants.date.MMDDYYYY) : priceList.dateTo}
            onDayChange={(date) => handleUserInput("dateTo", date)}
            component={CalendarInput}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder=""
            inputProps={{ disabled: priceList.isFromIntacct }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Status</Form.Label>
          <Select
            placeholder="Status"
            options={options}
            onChange={(e) => handleUserInput("status", e.value)}
            value={options.find((option) => option.value === priceList.status)}
            disabled={priceList.isFromIntacct}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            checked={priceList.isDefaultPriceList}
            onChange={() => {
              handleUserInput("isDefaultPriceList", !priceList.isDefaultPriceList);
            }}
            type="checkbox"
            label="Is default"
          />
        </Col>
      </Row>
    </Form>
  );
};

PriceListForm.propTypes = {
  priceList: PropTypes.shape().isRequired,
  handleUserInput: PropTypes.func.isRequired,
};

export default PriceListForm;
