import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectAllUsers, selectUsersIncluded, selectUsersPagination } from "@ROM/Users/selectors";

import Pagination from "@ROM-common/Pagination";
import AdminTable from "@ROM-components/common/AdminTable";
import ShadowContainer from "@ROM-ui/ShadowContainer";
import { reducer } from "@ROM/App/utils/forms";
import { list, setPage } from "@ROM/Users/actions";

import Filters from "@ROM/CompanyAdmin/Users/Index/Filters";
import UsersTableHeader from "@ROM/CompanyAdmin/Users/Index/UsersTableHeader";
import UsersTableRow from "@ROM/CompanyAdmin/Users/Index/UsersTableRow";

const UsersTable = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectAllUsers);
  const included = useSelector(selectUsersIncluded);
  const pagination = useSelector(selectUsersPagination);

  const initialFilters = { fullName: null, email: null, roles: [], regionId: null };
  const [filters, setFilter] = useReducer(reducer, initialFilters);

  useEffect(() => {
    const timeOutId = setTimeout(() => dispatch(list({ ...filters })), 500);
    return () => clearTimeout(timeOutId);
  }, [filters]);

  const handleFilterChange = (name, value) => {
    setFilter({ [name]: value });
  };

  const handleClearFilters = () => {
    setFilter(initialFilters);
  };

  const handlePageClick = (page) => {
    dispatch(setPage(page));
    dispatch(list({ ...filters, page }));
  };

  return (
    <>
      <ShadowContainer className="mt-2 mb-4">
        <Filters filters={filters} handleFilterChange={handleFilterChange} handleClearFilters={handleClearFilters} />
      </ShadowContainer>
      <div className="pt-2">
        <AdminTable header={<UsersTableHeader />}>
          {users.map((user) => (
            <UsersTableRow key={user.id} user={user} included={included} />
          ))}
        </AdminTable>
        <Pagination pagination={pagination} handlePageClick={handlePageClick} align="center" />
      </div>
    </>
  );
};

export default UsersTable;
