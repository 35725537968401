import styled from "styled-components";
import Badge from "react-bootstrap/Badge";

const StyledBadge = styled(Badge)`
  text-transform: uppercase;
  font-size: 0.7em;
  padding: 0.3rem 0.5rem;
  line-height: 1.25;
`;

export default StyledBadge;
