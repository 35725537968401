import React from "react";
import PropTypes from "prop-types";

import ItemForm from "./ItemForm";

const CustomPalletRow = ({ editing, palletId, palletWeight, customDescription, setPalletsAttributes, showWeightWarning }) => {
  return (
    <ItemForm
      isDragging={false}
      palletId={palletId}
      showWeightWarning={showWeightWarning}
      name={customDescription}
      onNameChange={editing ? (value) => setPalletsAttributes({ customDescription: value }) : null}
      weight={palletWeight}
      onWeightChange={editing ? (value) => setPalletsAttributes({ weight: value }) : null}
      isLocked={false}
    />
  );
};

CustomPalletRow.propTypes = {
  palletId: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  palletWeight: PropTypes.number.isRequired,
  customDescription: PropTypes.string.isRequired,
  setPalletsAttributes: PropTypes.func.isRequired,
  quantities: PropTypes.shape({
    selected: PropTypes.shape(),
  }).isRequired,
  showWeightWarning: PropTypes.bool.isRequired,
};

export default CustomPalletRow;
