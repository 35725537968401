import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const OptionalSection = ({ show, children }) => <Fieldset className={show ? "show-body" : ""}>{children}</Fieldset>;

const OptionalSectionLegend = ({ children }) => <legend className="legend">{children}</legend>;

const OptionalSectionBody = ({ children }) => <div className="optional-container">{children}</div>;

const Fieldset = styled.fieldset`
  border-radius: 5px;

  .legend {
    font-size: 1rem;
    width: auto;
  }

  .optional-container {
    display: none;
    padding: 1rem;
  }

  &.show-body {
    border: 1px solid #dee2e6;

    .legend {
      padding: 0 0.5rem;
      margin-left: 0.5rem;
    }

    .optional-container {
      display: block;
    }
  }
`;

OptionalSection.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

OptionalSectionBody.propTypes = {
  children: PropTypes.node.isRequired,
};

OptionalSectionLegend.propTypes = {
  children: PropTypes.node.isRequired,
};

OptionalSection.Legend = OptionalSectionLegend;
OptionalSection.Body = OptionalSectionBody;

export default OptionalSection;
