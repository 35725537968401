import React from "react";
import { Route } from "react-router-dom";

import Page from "@ROM-pages/CompanyAdmin/CustomTableRows/New";
import Layout from "@ROM-pages/Layouts/CompanyAdmin";
import Authorize from "@ROM/Auth/components/Authorize";
import { canManageCustomTables } from "@ROM/CustomTables/permissions";

class RouteHandler extends Route {
  render() {
    return (
      <Layout>
        <Authorize permission={canManageCustomTables}>
          <Page customTableId={this.props.computedMatch.params.customTableId} />
        </Authorize>
      </Layout>
    );
  }
}

export default RouteHandler;
