import { apiAction, standardAction } from "@ROM-utils/actions";
import AddressApi from "./api";

export const LIST = "addresses/api/LIST";
export const FIND = "addresses/api/FIND";
export const CREATE = "addresses/api/CREATE";
export const UPDATE = "addresses/api/UPDATE";
export const DESTROY = "addresses/api/DESTROY";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LIST_WITH_SCROLL = "addresses/api/LIST_WITH_SCROLL";
export const SET_PAGE = "SET_PAGE";
export const FETCH_COUNTRIES = "addresses/api/FETCH_COUNTRIES";

export const BILLING_TYPE = "BillingAddress";
export const SHIPPING_TYPE = "ShippingAddress";

export function list(attributes, meta = {}) {
  return apiAction(LIST, AddressApi.list(attributes), meta);
}

export function find(id) {
  return apiAction(FIND, AddressApi.find(id));
}

export function create(attributes = {}, meta = {}) {
  return apiAction(CREATE, AddressApi.create(attributes), { insert: true, ...meta });
}

export function update(id, attributes = {}) {
  return apiAction(UPDATE, AddressApi.update(id, attributes));
}

export function destroy(id, meta = {}) {
  return apiAction(DESTROY, AddressApi.destroy(id), { addressId: id, ...meta });
}

export function clearErrors() {
  return standardAction(CLEAR_ERRORS);
}

export function setPage(page, meta = {}) {
  return standardAction(SET_PAGE, { page }, meta);
}

export function getAddressesWithScroll(attributes) {
  return apiAction(LIST_WITH_SCROLL, AddressApi.list(attributes), {
    customerId: attributes.customerId,
    type: attributes.type,
  });
}

export function fetchCountries() {
  return apiAction(FETCH_COUNTRIES, AddressApi.fetchCountries());
}
