import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import PropTypes from "prop-types";

import { listRows } from "@ROM/CustomTableRows/actions";
import { selectIsLoading, selectAllCustomTables } from "@ROM/CustomTables/selectors";
import { selectAllCustomTableRows } from "@ROM/CustomTableRows/selectors";
import { companyAdminUrl } from "@ROM-utils/urlHelpers";
import Section from "@ROM-components/common/Section";
import { customTablesShowBreadcrumbs } from "@ROM/CompanyAdmin/utils";
import CustomTableRowForm from "@ROM/CustomTableRows/components/Form";

const Page = ({ customTableId, customTableRowId }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const customTables = useSelector(selectAllCustomTables);
  const tableRows = useSelector(selectAllCustomTableRows);

  const [initalized, setInitalized] = useState(false);

  const table = customTables.find((item) => item.id === customTableId);
  const row = tableRows.find((item) => item.id === customTableRowId);

  const breadcrumbs = customTablesShowBreadcrumbs(table, [{ label: "Edit", active: true }]);

  useEffect(() => {
    dispatch(listRows(customTableId)).then(() => setInitalized(true));
  }, [dispatch, customTableId]);

  if (!initalized || isLoading) {
    return null;
  }

  if (!table || !row) {
    return <Redirect to={companyAdminUrl()} />;
  }

  return (
    <Section breadcrumbs={breadcrumbs}>
      <CustomTableRowForm table={table} row={row} />
    </Section>
  );
};

Page.propTypes = {
  customTableId: PropTypes.string.isRequired,
  customTableRowId: PropTypes.string.isRequired,
};

export default Page;
