import { createSelector } from "reselect";

const unitsState = (state) => state.units;

export const isLoading = createSelector(unitsState, (units) => units.all.loading);

export const selectAllUnits = createSelector(unitsState, (units) => units.all.items);

export const selectAllProductUnits = createSelector(selectAllUnits, (units) =>
  units.filter((unit) => unit.attributes.type === "ProductUnit")
);

export const selectAllPackageUnits = createSelector(selectAllUnits, (units) =>
  units.filter((unit) => unit.attributes.type === "PackageUnit")
);
