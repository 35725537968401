/* eslint-disable import/prefer-default-export */
import { createSelector } from "reselect";

const emailGroupsState = (state) => state.emailGroups;

export const selectEmailGroups = createSelector(emailGroupsState, (groups) => groups.all.items);

export const selectEmailGroupsIncluded = createSelector(emailGroupsState, (groups) => groups.all.included);

export const selectEmailGroupUsers = createSelector(selectEmailGroupsIncluded, (items) =>
  items.filter((item) => item.type === "user")
);
