import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SortableStrongPointer from "@ROM-ui/SortableStrongPointer";
import { canSeeExtraInformation, canSeeOrdersFromAllRegions } from "@ROM/Orders/permissions";
import { isBeingSort } from "@ROM-utils/filters";
import OrderListRow from "./OrderListRow";
import Skeleton from "react-loading-skeleton";

const List = ({ loading, orders, handleColumnSort, filters, currentUser }) => {
  const CellHeader = ({ title, sortField }) => (
    <th className="text-nowrap" onClick={() => handleColumnSort(sortField)}>
      <SortableStrongPointer>
        {title}
        <i className={isBeingSort(filters, sortField)} />
      </SortableStrongPointer>
    </th>
  );
  CellHeader.propTypes = {
    title: PropTypes.string.isRequired,
    sortField: PropTypes.string.isRequired,
  };

  const canSeeRegions = canSeeOrdersFromAllRegions(currentUser);
  const canSeeExtra = canSeeExtraInformation(currentUser);

  return (
    <div className="table-responsive p-3 ">
      <StyledTable className="table table-borderless table-sm text-black-50 small">
        <thead>
          <tr>
            <CellHeader title="Status" sortField="status" />
            {canSeeRegions && <CellHeader title="Region" sortField="regions.name" />}
            {canSeeExtra && <CellHeader title="Customer" sortField="customers.name" />}
            <CellHeader title="Invoice No." sortField="invoiceNumber" />
            <CellHeader title="Purchase Order" sortField="poNumber" />
            <CellHeader title="Date" sortField="createdAt" />
            {canSeeExtra && <CellHeader title="Creator" sortField="users.fullName" />}
            <CellHeader title="Items" sortField="orderItemsCount" />
            <th className="text-center">
              <strong>Total</strong>
            </th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <OrderListRow order={order} currentUser={currentUser} key={order.id} />
          ))}
        </tbody>
      </StyledTable>
      {!loading && orders.length === 0 && <h5 className="text-center text-secondary">No orders found</h5>}
      {loading && (
        <div className="text-center">
          <Skeleton width="100%" height="35px" count="10" />
        </div>
      )}
    </div>
  );
};

export default List;

List.propTypes = {
  loading: PropTypes.bool.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })).isRequired,
  handleColumnSort: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
};

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 5px;
`;
