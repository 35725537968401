import React from "react";
import PropTypes from "prop-types";
import StyledRow from "@ROM/CustomerAdmin/components/common/StyledRow";
import OrderStatusBadge from "@ROM/OrderStatuses/components/OrderStatusBadge";
import NumberFormater from "@ROM-ui/NumberFormater";
import Button from "@ROM-ui/Button";
import Spinner from "@ROM-ui/Spinner";
import { orderUrl } from "@ROM-utils/urlHelpers";
import { useHistory } from "react-router-dom";
import Pagination from "@ROM-components/common/Pagination";
import useIsMobile from "@ROM-components/utils/Responsive";

const List = ({ orders, loading, pagination, handlePageClick }) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  return (
    <div>
      {loading && (
        <div className="d-flex justify-content-center">
          <Spinner animation="grow" variant="secondary" />
        </div>
      )}
      {!loading &&
        (orders.length > 0 ? (
          <>
            {orders.map((order) => {
              const { attributes } = order;
              const createdAt = new Date(attributes.createdAt);

              return (
                <StyledRow key={order.id}>
                  <div className={`${isMobile ? "" : "w-10"}`}>
                    <OrderStatusBadge status={attributes.status} />
                  </div>
                  <p className="m-0">{attributes.poNumber}</p>
                  <p className="m-0">{createdAt.toLocaleDateString()}</p>
                  <NumberFormater value={attributes.totalCents} />
                  <Button onClick={() => history.push(orderUrl(order.id))} variant="outline-primary p-0 px-1">
                    View order
                  </Button>
                </StyledRow>
              );
            })}
            <Pagination pagination={pagination} handlePageClick={handlePageClick} />
          </>
        ) : (
          <h5 className="text-center">No orders created yet</h5>
        ))}
    </div>
  );
};

List.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape().isRequired,
  handlePageClick: PropTypes.func.isRequired,
};

export default List;
