import React, { useEffect, useReducer, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canEditCustomerRolesFromUsers, canRemoveCustomerFromUsers } from "@ROM/Users/permissions";
import { list as fetchUsers, setPage, updateCustomerAdminValue, removeFromCustomer } from "@ROM/Users/actions";
import { selectAllUsersNotCustomerCropAdvisor, selectUsersPagination, selectUsersUpdateErrors } from "@ROM/Users/selectors";
import { reducer } from "@ROM/App/utils/forms";
import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import Message from "@ROM-common/Message";
import Pagination from "@ROM-common/Pagination";
import Filters from "./Filters";
import UserRow from "./UserRow";

const initialFilters = {
  fullName: null,
  email: null,
};

const UsersTable = () => {
  const currentUser = useSelector(selectCurrentUser);
  const users = useSelector(selectAllUsersNotCustomerCropAdvisor);
  const pagination = useSelector(selectUsersPagination);
  const updateError = useSelector(selectUsersUpdateErrors);
  const dispatch = useDispatch();
  const customer = useContext(CustomerContext);

  const [filters, setFilter] = useReducer(reducer, initialFilters);
  const [initialized, setInitialized] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const customerId = customer?.id ?? null;

  useEffect(() => {
    if (showError) {
      const errorTimeOut = setTimeout(() => {
        if (showError) setShowError(false);
      }, 1500);
      return () => clearTimeout(errorTimeOut);
    }
    if (showSuccess) {
      const successTimeOut = setTimeout(() => {
        if (showSuccess) setShowSuccess(false);
      }, 1500);
      return () => clearTimeout(successTimeOut);
    }
    return undefined;
  }, [showSuccess, showError]);

  useEffect(() => {
    if (!customerId) {
      return undefined;
    }

    const timeOutId = setTimeout(
      () =>
        dispatch(fetchUsers({ ...filters, customerId })).then(() => {
          if (!initialized) {
            setInitialized(true);
          }
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [customerId, filters]);

  const handleFilterChange = (name, value) => {
    setFilter({ [name]: value });
  };

  const handleClearFilters = () => {
    setFilter(initialFilters);
  };

  const handlePageClick = (page) => {
    dispatch(setPage(page));
    dispatch(fetchUsers({ ...filters, page, customerId }));
  };

  const handleRoleChange = (id, admin) => {
    dispatch(updateCustomerAdminValue(id, { admin, customerId })).then(() => {
      if (!updateError) {
        setShowSuccess(true);
        setShowError(false);
      } else {
        setShowSuccess(false);
        setShowError(true);
      }
    });
  };

  const handleRemove = (id) => {
    dispatch(removeFromCustomer(id, customerId));
  };

  return (
    <>
      <h4>Members</h4>
      <div className="bg-light p-1 mb-2 rounded">
        <Filters handleFilterChange={handleFilterChange} handleClearFilters={handleClearFilters} filters={filters} />
      </div>
      {showError && (
        <Message
          message="There was an unexpected error, please try again later or contact the administrator"
          type="danger"
          className="mb-2"
        />
      )}
      {showSuccess && <Message message="Change saved successfully" type="success" className="mb-2" />}
      {initialized &&
        users.map((user) => (
          <UserRow
            key={user.id}
            user={user}
            updateCustomerAdminValue={canEditCustomerRolesFromUsers(currentUser, customerId) && handleRoleChange}
            handleRemove={canRemoveCustomerFromUsers(currentUser, customerId) && handleRemove}
          />
        ))}

      <Pagination pagination={pagination} handlePageClick={handlePageClick} />
    </>
  );
};

export default UsersTable;
