import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import AdminTable from "@ROM-components/common/AdminTable";
import { FieldTableHeader, FieldTableRow } from "@ROM/CustomTables/components/Field";

const FieldsList = ({ fields, className, updateField, deleteField, reorderField, showError }) => (
  <div className={className}>
    {fields.length > 0 ? (
      <AdminTable header={<FieldTableHeader />}>
        {fields.map((field, index) => (
          <FieldTableRow
            key={field.name}
            index={index}
            field={field}
            fieldCount={fields.length}
            updateField={updateField}
            deleteField={deleteField}
            reorderField={reorderField}
          />
        ))}
      </AdminTable>
    ) : (
      <ZeroState $error={showError}>{showError ? "At least one field is required" : "No fields created yet"}</ZeroState>
    )}
  </div>
);

const ZeroState = styled.div`
  color: ${(props) => (props.$error ? "#721c24" : "#6b7280")};
  background-color: ${(props) => (props.$error ? "#f8d7da" : "#e5e7eb")};
  text-align: center;
  padding: 1.25rem;
  border-radius: 4px;
`;

FieldsList.defaultProps = {
  className: "",
};

FieldsList.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape).isRequired,
  updateField: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  reorderField: PropTypes.func.isRequired,
  showError: PropTypes.bool.isRequired,
};

export default FieldsList;
