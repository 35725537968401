import { createSelector } from "reselect";

const priceListsState = (state) => state.priceLists;

export const selectPriceLists = createSelector(priceListsState, (priceLists) => priceLists.all.items);

export const selectPriceListsPagination = createSelector(priceListsState, (priceLists) => priceLists.pagination);

export const selectCurrentPriceList = createSelector(priceListsState, (priceLists) => priceLists.current.priceList);

export const selectItemsFromCurrentPriceList = createSelector(priceListsState, (priceLists) =>
  priceLists.current.included ? priceLists.current.included : []
);
