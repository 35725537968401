import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import BreadcrumbBootstrap from "react-bootstrap/Breadcrumb";

const Breadcrumb = ({ items, className }) => {
  return (
    <BreadcrumbBootstrap className={className} bsPrefix="breadcrumb bg-white mb-0 px-0">
      {items.map(({ label, href, active }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <BreadcrumbBootstrap.Item key={index} linkAs={Link} linkProps={{ to: href }} active={active}>
          {label}
        </BreadcrumbBootstrap.Item>
      ))}
    </BreadcrumbBootstrap>
  );
};

Breadcrumb.defaultProps = {
  className: "",
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
      active: PropTypes.bool,
    })
  ).isRequired,
};

export default Breadcrumb;
