import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import NumberFormater from "@ROM-ui/NumberFormater";

const Balance = ({ totalCharged, totalRefunded, orderTotal, className }) => {
  const totalPaid = totalCharged - totalRefunded;
  const balance = totalPaid - orderTotal;
  const balanceLabel = balance <= 0 ? "Amount Due" : "Credit Owed";

  return (
    <BalanceContainer className={className} balanceOk={balance === 0}>
      <div className="d-inline-block border text-start">
        <div className="border-bottom px-2 py-1">
          <span className="amount-label">Order Total:</span>
          <NumberFormater value={orderTotal} className="balance-amount" />
        </div>
        <div className="border-bottom px-2 py-1">
          <span className="amount-label">Paid:</span>
          <NumberFormater value={totalPaid} className="balance-amount" />
        </div>
        <div className="px-2 py-1 balance-amount-row">
          <span className="amount-label">{`${balanceLabel}:`}</span>
          <NumberFormater value={balance} className="balance-amount" />
        </div>
      </div>
    </BalanceContainer>
  );
};

const BalanceContainer = styled.div`
  .amount-label {
    display: inline-block;
    width: 6rem;
    margin-right: 0.5rem;
  }

  .balance-amount {
    font-weight: bold;
  }

  .balance-amount-row {
    background-color: ${(props) => (props.balanceOk ? "#D1FAE5" : "#FEF3C7")};
  }
`;

Balance.defaultProps = {
  className: "",
};

Balance.propTypes = {
  className: PropTypes.string,
  totalCharged: PropTypes.number.isRequired,
  totalRefunded: PropTypes.number.isRequired,
  orderTotal: PropTypes.number.isRequired,
};

export default Balance;
