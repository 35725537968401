import React, { useReducer, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import LoadingSplash from "@ROM-components/LoadingSplash/index";
import Section from "@ROM-components/common/Section";
import ProductForm from "@ROM/CompanyAdmin/Products/Form";
import ProductsApi from "@ROM/Products/api";
import Message from "@ROM-common/Message";
import FileUploader from "@ROM-common/FileUploader";
import PropTypes from "prop-types";
import { reducer } from "@ROM/App/utils/forms";
import ProductDetails from "@ROM/CompanyAdmin/Products/Show";
import { productsBreadcrumbs } from "@ROM/CompanyAdmin/utils";
import { companyAdminProductsShowUrl } from "@ROM-utils/urlHelpers";
import { list as listCategories } from "@ROM/Categories/actions";
import { list as listUnits } from "@ROM/Units/actions";
import { list as listWarehouses } from "@ROM/Warehouses/actions";
import {
  list as listWarehouseInventories,
  create as createWarehouseInventory,
  destroy as destroyWarehouseInventory,
} from "@ROM/WarehouseInventories/actions";
import { selectAllCategories, selectAllSubcategories } from "@ROM/Categories/selectors";
import { selectAllProductUnits, selectAllPackageUnits } from "@ROM/Units/selectors";
import { selectAllWarehouseInventories } from "@ROM/WarehouseInventories/selectors";

const Page = ({ productId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const [assetsToDelete, setassetsToDelete] = useState([]);
  const [primaryAssetId, setPrimaryAssetId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAssetError, setShowAssetError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [currentCustomers, setCurrentCustomers] = useState([]);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [refreshProduct, setRefreshProduct] = useState(0);

  const categories = useSelector(selectAllCategories);
  const subcategories = useSelector(selectAllSubcategories);
  const productUnits = useSelector(selectAllProductUnits);
  const packageUnits = useSelector(selectAllPackageUnits);
  const warehouseInventories = useSelector(selectAllWarehouseInventories);

  const initialProduct = {
    name: "",
    description: "",
    isActive: false,
    featured: false,
    unitsPerPackage: 0,
    packagesPerPallet: 0,
    packageWeight: 0,
    palletWeightOverride: null,
    unit: "",
    packageUnit: "",
    assets: [],
    private: false,
    customerProductVisibilitiesAttributes: [],
    subcategoryIds: [],
  };

  const [product, updateState] = useReducer(reducer, initialProduct);
  const [warehouseIds, setWarehouseIds] = useState([]);

  const updateWarehouseIds = (items) => {
    setWarehouseIds(items ? items.map((item) => item.value) : []);
  };

  const updateCurrentCustomersAndOptions = (json) => {
    const customers = [];
    const options = json.data.included
      .filter((element) => element.type === "customerProductVisibility")
      .map((element) => {
        customers.push({ customerId: element.attributes.customerId, id: element.id });
        return { label: element.attributes.name, value: element.attributes.customerId };
      });
    setCurrentCustomers(customers);
    setCurrentOptions(options);
  };

  useEffect(() => {
    ProductsApi.find(productId).then((json) => {
      updateCurrentCustomersAndOptions(json);
      updateState({
        ...json.data.data.attributes,
        assets: json.data.included.filter((element) => element.type === "asset"),
        subcategoryIds: json.data.data.relationships.subcategories.data.map((subcat) => subcat.id),
      });
      setInitialized(true);
      if (json.data.data.attributes.primaryImage) setPrimaryAssetId(json.data.data.attributes.primaryImage.id);
    });
  }, [refreshProduct]);

  useEffect(() => {
    dispatch(listCategories());
    dispatch(listUnits());
    dispatch(listWarehouses());
    dispatch(listWarehouseInventories({ productId }));
  }, []);

  useEffect(() => {
    setWarehouseIds(warehouseInventories.map((inventory) => `${inventory.attributes.warehouseId}`));
  }, [warehouseInventories]);

  const handleCancel = () => {
    history.goBack();
  };

  const handleSuccess = (response) => {
    setLoading(false);
    setShowAssetError(false);
    setShowSuccess(true);
    updateState({ customerProductVisibilitiesAttributes: [] });
    updateCurrentCustomersAndOptions(response);
    setRefreshProduct(refreshProduct + 1);
    window.scroll(0, 0);
  };

  const handleEdit = async () => {
    const { assets } = product;
    setLoading(true);
    setErrorMsg(null);

    try {
      const response = await ProductsApi.update(productId, product);

      // update inventory trackings
      const inventoriesToDelete = warehouseInventories
        .filter((item) => !warehouseIds.includes(`${item.attributes.warehouseId}`))
        .map((item) => item.id);
      const trackedWarehouseIds = warehouseInventories.map((item) => `${item.attributes.warehouseId}`);
      const inventoriesToCreate = warehouseIds.filter((item) => !trackedWarehouseIds.includes(item));

      await Promise.all(inventoriesToCreate.map((warehouseId) => dispatch(createWarehouseInventory({ productId, warehouseId }))));
      await Promise.all(
        inventoriesToDelete.map((warehouseInventoryId) => dispatch(destroyWarehouseInventory(warehouseInventoryId)))
      );

      let assetError = false;

      // create new assets
      try {
        if (assets.length > 0) {
          await ProductsApi.createAsset(response.data.data.id, { assets });
        }
      } catch {
        assetError = true;
        setLoading(false);
        setShowAssetError(true);
        setShowSuccess(false);
        window.scroll(0, 0);
      }

      // delete old assets
      try {
        await Promise.all(assetsToDelete.map((asset) => ProductsApi.deleteAsset(productId, { id: asset })));
      } catch {
        assetError = true;
        setLoading(false);
        setShowAssetError(true);
        setShowSuccess(false);
        window.scroll(0, 0);
      }

      if (!assetError) {
        handleSuccess(response);
      }
    } catch (error) {
      setErrorMsg(error.data.detail || error.data[0].detail);
      setLoading(false);
      setShowAssetError(false);
      setShowSuccess(false);
      window.scroll(0, 0);
    }
  };

  const makePrimary = (assetId) => {
    const primaryId = primaryAssetId;
    /* eslint-disable no-param-reassign */
    product.assets.forEach((asset) => {
      if (asset.id === assetId) {
        if (asset.isPrimary || (asset.attributes && asset.attributes.isPrimary)) {
          asset.isPrimary = false;
          if (asset.attributes && asset.attributes.isPrimary) {
            asset.attributes.isPrimary = false;
          }
          setPrimaryAssetId("");
        } else {
          asset.isPrimary = true;
          setPrimaryAssetId(asset.id);
        }
      } else if (asset.id === primaryId) {
        asset.isPrimary = false;
      }
    });
    /* eslint-enable no-param-reassign */
  };

  const afterAssetUpload = (assets, setUploadingFiles) => {
    const currentAssets = [...product.assets, ...assets];
    updateState({ assets: currentAssets });
    setUploadingFiles(false);
  };

  const onDeleteAsset = (currentAsset, setRemovingFiles) => {
    const currentAssets = product.assets.filter((asset) => asset.id !== currentAsset.id);
    updateState({ assets: currentAssets });
    if (currentAsset.attributes) {
      setassetsToDelete([...assetsToDelete, currentAsset.id]);
    }
    setRemovingFiles(false);
  };

  const handleCustomerSelect = (customers, change) => {
    if (change.action === "select-option") {
      if (currentOptions.find((element) => element.value.toString() === change.option.value.toString())) return;
      setCurrentOptions(customers);
      updateState({
        customerProductVisibilitiesAttributes: [
          ...product.customerProductVisibilitiesAttributes,
          { customerId: change.option.value },
        ],
      });
    } else {
      const customerProductVisibilitiesAttributes = product.customerProductVisibilitiesAttributes.filter(
        (element) => element.customerId !== change.removedValue.value
      );
      const element = currentCustomers.find((item) => item.customerId === change.removedValue.value);
      setCurrentOptions(customers);
      updateState({
        customerProductVisibilitiesAttributes: element
          ? [...customerProductVisibilitiesAttributes, { _destroy: true, ...element }]
          : [...customerProductVisibilitiesAttributes],
      });
    }
  };

  const breadcrumbs = productsBreadcrumbs([
    { label: product?.name, href: companyAdminProductsShowUrl(productId) },
    { label: "Edit", active: true },
  ]);

  const AlertMessage = () => {
    if (showSuccess) {
      return <Message type="success" message="The product was successfully edited" />;
    }
    if (showAssetError) {
      return (
        <Message type="danger" message="The product was updated, but there was a problem updating the assets. Try again later" />
      );
    }
    if (errorMsg) {
      return <Message type="danger" message={errorMsg} />;
    }
    return null;
  };

  return (
    <>
      <Section breadcrumbs={breadcrumbs} containerClassName="ms-md-5">
        {initialized ? (
          <>
            <ProductDetailsContainer>
              <ProductDetails product={product} />
            </ProductDetailsContainer>
            <div style={{ maxWidth: "650px" }}>
              <h5 className="mb-4">Edit Product</h5>
              <AlertMessage />
              <ProductForm
                product={product}
                updateState={updateState}
                handleSubmit={handleEdit}
                handleCancel={handleCancel}
                loading={loading}
                selectedCustomers={currentOptions}
                handleCustomerSelect={handleCustomerSelect}
                categories={categories}
                subcategories={subcategories}
                productUnits={productUnits}
                packageUnits={packageUnits}
                warehouseIds={warehouseIds}
                setWarehouseIds={updateWarehouseIds}
              >
                <FileUploader
                  afterAssetUpload={afterAssetUpload}
                  deleteAsset={onDeleteAsset}
                  currentAssets={product.assets}
                  makePrimary={makePrimary}
                  primaryAssetId={primaryAssetId}
                />
              </ProductForm>
            </div>
          </>
        ) : (
          <LoadingSplash />
        )}
      </Section>
    </>
  );
};

const ProductDetailsContainer = styled.div`
  padding: 0 1.25rem;
  background-color: #f4f4f4;
  border-radius: 0.25rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: -4rem;
  margin-right: -1rem;

  @media (max-width: 768px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`;

Page.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default Page;
