import React from "react";
import PropTypes from "prop-types";
import Modal from "@ROM-components/common/Modal";
import Message from "@ROM-common/Message/index";
import { getErrorMessage } from "@ROM-utils/errors";
import Row from "@ROM-ui/Row";

const FormErrorsModal = ({ show = false, onCancel, errorMessage }) => {
  return (
    <Modal isShowing={show} showCancelButton={false} showConfirmButton={false} onCancel={onCancel} header={"Error!"}>
      <Row>
        <Message message={getErrorMessage(errorMessage)} fluid={true} type="danger" />
      </Row>
    </Modal>
  );
};

FormErrorsModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  errorMessage: PropTypes.array,
};

export default FormErrorsModal;
