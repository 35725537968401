import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@ROM-components/common/Modal";
import OrderStatusBadge from "@ROM/OrderStatuses/components/OrderStatusBadge";
import styled from "styled-components";
import moment from "moment";
import Form from "@ROM-ui/Form";
import Button from "@ROM-ui/Button";
import { addNote } from "@ROM/OrderStatuses/actions";
import { canAddOrderNotes } from "@ROM/OrderStatuses/permissions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectAllDeliveries } from "@ROM/Deliveries/selectors";
import PropTypes from "prop-types";
import DeliveryStatusBadge from "@ROM/Deliveries/components/common/DeliveryStatusBadge";

const StatusesModal = ({ show = false, onCancel, orderStatuses, orderId }) => {
  moment.locale("en-us");

  const currentUser = useSelector(selectCurrentUser);
  const deliveries = useSelector(selectAllDeliveries);
  const dispatch = useDispatch();

  const [note, setNote] = useState("");
  const [notifyNewNote, setNotifyNewNote] = useState(false);
  const [error, setError] = useState(false);

  const getStatus = (orderType) => {
    let status = orderType.split("::")[1];
    status = status.charAt(0).toLowerCase() + status.slice(1);

    return status;
  };

  let oldStatus = "";
  const statuses = orderStatuses.map((orderStatus) => {
    const { attributes } = orderStatus;
    const actualStatus = getStatus(attributes.type);
    const status = {};

    status.type = oldStatus === actualStatus ? null : actualStatus;
    status.message = attributes.message;
    status.createdAt = moment(attributes.createdAt).format("MMM D, YYYY - hh:mm A");

    oldStatus = actualStatus;

    return status;
  });

  const getEndpoint = () => {
    const status = getStatus(orderStatuses[0].attributes.type);

    switch (status) {
      case "opened":
        return "open";
      case "rejected":
        return "reject";
      case "cancelled":
        return "cancel";
      case "approved":
        return "approve";
      case "confirmed":
        return "confirm";
      case "completed":
        return "complete";
      case "inProgress":
        return "in_progress";
      case "shipped":
        return "ship";
      case "invoiced":
        return "invoice";
      default:
        return "unknown";
    }
  };

  const handleAddNote = (message) => {
    dispatch(
      addNote(getEndpoint(), {
        orderId,
        message,
        isNewNote: true,
        notifyNewNote,
      })
    ).then((response) => {
      if (response.payload.status !== 201) {
        setError(true);
      }
    });
  };

  return (
    <Modal isShowing={show} onCancel={onCancel} showCancelButton={false} showConfirmButton={false} header="History">
      <StyledBody>
        {deliveries.length > 0 && (
          <>
            <h6>Deliveries</h6>
            <div className="mb-3">
              {deliveries
                .slice()
                .reverse()
                .map((delivery) => (
                  <div className="d-flex px-3 w-100" key={delivery.id}>
                    <Date>{moment(delivery.attributes.updatedAt).format("MMM D, YYYY - hh:mm A")}</Date>
                    <TimeLine>
                      <TopLine className="bg-secondary" />
                      <BadgeDot className="fas fa-circle" />
                      <BottomLine className="bg-secondary" />
                    </TimeLine>
                    <StyledDiv className="pt-md-3">
                      <span className="me-2">{`Delivery ${delivery.attributes.position}`}</span>
                      <DeliveryStatusBadge status={delivery.attributes.status} />
                      <StyledParagraph>{delivery.attributes.notes}</StyledParagraph>
                    </StyledDiv>
                  </div>
                ))}
            </div>
          </>
        )}
        <h6>Order</h6>
        {statuses.map(({ createdAt, type, message }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="d-flex px-3 w-100" key={i}>
            <Date>{createdAt}</Date>
            <TimeLine>
              <TopLine className="bg-secondary" />
              <BadgeDot className="fas fa-circle" />
              <BottomLine className="bg-secondary" />
            </TimeLine>
            <StyledDiv className="pt-md-3">
              <OrderStatusBadge status={type || ""} className="mb-2 mb-md-0" />
              <StyledParagraph empty={type === null}>{message}</StyledParagraph>
            </StyledDiv>
          </div>
        ))}
        {canAddOrderNotes(currentUser) && (
          <StyledForm>
            <Form.Label>Write a note</Form.Label>
            <Form.Control as="textarea" rows="3" onChange={(e) => setNote(e.target.value)} />
            <div className="d-flex justify-content-between align-items-center px-3 mt-2">
              <Form.Check type="checkbox" label="Send note to customer?" onChange={(e) => setNotifyNewNote(e.target.checked)} />
              <Button disabled={note === ""} variant="primary" type="submit" onClick={() => handleAddNote(note)}>
                Add note
              </Button>
            </div>
            {error && <p className="text-danger mt-2">Something went wrong when adding the note, please try again later</p>}
          </StyledForm>
        )}
      </StyledBody>
    </Modal>
  );
};

export default StatusesModal;

const StyledParagraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 0;
  min-height: 1rem;
  position: ${(props) => (props.empty ? "relative" : "")};
  bottom: ${(props) => (props.empty ? "0.5rem" : "")};
  overflow-wrap: break-word;
`;

const StyledDiv = styled.div`
  width: 65%;
`;

const BadgeDot = styled.i`
  font-size: xx-small;
`;

const StyledForm = styled(Form)`
  font-size: smaller;
  margin-top: 1rem;
`;

const Date = styled.div`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-transform: capitalize;
  align-self: center;
  margin-top: 0.25rem;
  width: 30%;
`;

const TimeLine = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5px;
  }
`;

const TopLine = styled.div`
  width: 1.5px;
  height: 100%;
`;

const BottomLine = styled.div`
  width: 1.5px;
  height: 100%;
`;

const StyledBody = styled.div`
  min-width: 35rem;
`;

StatusesModal.propTypes = {
  orderId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  orderStatuses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  show: PropTypes.bool.isRequired,
};
