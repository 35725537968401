import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { canListInvitations } from "@ROM/Invitations/permissions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import UserTable from "@ROM/Customers/components/Users/UserTable";
import DeleteCustomer from "@ROM/Customers/components/Delete";
import AddressesTable from "@ROM/Customers/components/Addresses/AddressesTable";
import EditCustomerForm from "@ROM/CustomerAdmin/components/Customers/Edit";
import InvitationTable from "@ROM/CustomerAdmin/components/Invitations/InvitationsTable";
import { BILLING_TYPE, SHIPPING_TYPE } from "@ROM/Addresses/actions";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";

const OrganizationPanel = () => {
  const customer = useContext(CustomerContext);
  const currentUser = useSelector(selectCurrentUser);

  return (
    <Row>
      <Col xs={12} lg={4}>
        <EditCustomerForm />
      </Col>
      <Col xs={12} lg={8}>
        <UserTable />
        {canListInvitations(currentUser, customer?.id) && <InvitationTable />}
      </Col>
      <Col xs={12} md={6}>
        <AddressesTable type={BILLING_TYPE} />
      </Col>
      <Col xs={12} md={6}>
        <AddressesTable type={SHIPPING_TYPE} />
      </Col>
      <Col xs={6}>
        <DeleteCustomer />
      </Col>
    </Row>
  );
};

export default OrganizationPanel;

OrganizationPanel.propTypes = {};
