import styled from "styled-components";
import { default as ReactModal } from "react-modal";

const Modal = styled(ReactModal)`
  min-width: auto;
  @media (min-width: 992px) {
    min-width: 30rem;
  }
`;

Modal.setAppElement("#modal");

export default Modal;
