import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import useDebounceEffect from "@ROM-hooks/useDebounceEffect";
import IconPointer from "@ROM-ui/IconPointer";
import { cleanDecimals, ifPositive } from "@ROM-utils/format";
import { reducer } from "@ROM-utils/forms";

import BillOfLadingItemRow from "./BillOfLadingItemRow";
import CustomPalletRow from "./CustomPalletRow";
import PalletNumber from "./PalletNumber";

const Pallet = ({
  items,
  pallet,
  billOfLadingId,
  handlePalletAttributesChange,
  handlePalletDelete,
  quantities,
  handleChange,
  palletsLoading,
}) => {
  const {
    id: palletId,
    attributes: { isLocked, weightExceeded, quantity, customDescription },
  } = pallet;
  const PALLET_ID = `PALLET_${palletId}`;
  const showDragHandle = items?.length > 1;
  const showWeightWarning = !isLocked && weightExceeded;

  const [editPallet, setEditPallet] = useState(false);
  const [palletAttributes, setPalletsAttributes] = useReducer(reducer, {
    quantity,
    weight: pallet.attributes.weight,
    customDescription,
  });
  const [editWeight, setEditWeight] = useState(false);
  const [weight, setWeight] = useState(0);

  const changeWeight = ifPositive(setWeight);

  useDebounceEffect(
    () => {
      if (weight !== pallet.attributes.weight) {
        handlePalletAttributesChange(pallet.id, { weight });
      }
    },
    [weight],
    { runAfterMount: true }
  );

  useEffect(() => {
    setWeight(pallet.attributes.weight);
  }, [pallet.attributes.weight]);

  const editPalletClasses = editPallet ? "fas fa-check text-success" : "fas fa-edit text-warning";

  const togglePalletEdit = () => {
    if (editPallet) {
      handlePalletAttributesChange(palletId, palletAttributes);
    }
    setEditPallet(!editPallet);
  };

  const setPalletQuantity = (value) => setPalletsAttributes({ quantity: value });

  return (
    <>
      <Droppable droppableId={PALLET_ID} key={palletId} isDropDisabled={isLocked || palletsLoading}>
        {(provided, snapshot) => (
          <StyledPallet isDraggingOver={snapshot.isDraggingOver}>
            <div className="d-flex w-100">
              <PalletNumber
                palletId={palletId}
                className="mt-2"
                loading={palletsLoading}
                editing={editPallet}
                quantity={palletAttributes.quantity}
                setPalletQuantity={setPalletQuantity}
              />
              <div ref={provided.innerRef} {...provided.droppableProps} className="my-2 w-100">
                {palletsLoading && (
                  <div className="ms-2">
                    <Skeleton height={60} count={items?.length || 1} />
                  </div>
                )}
                {!palletsLoading &&
                  !pallet.attributes.isCustom &&
                  items.map((item, index) => (
                    <BillOfLadingItemRow
                      item={item}
                      index={index}
                      key={item.id}
                      palletId={palletId}
                      billOfLadingId={billOfLadingId}
                      showDragHandle={showDragHandle}
                      quantities={quantities}
                      handleChange={handleChange}
                      isLocked={isLocked}
                      showWeightWarning={showWeightWarning}
                    />
                  ))}
                {!palletsLoading && pallet.attributes.isCustom && (
                  <CustomPalletRow
                    editing={editPallet}
                    palletWeight={palletAttributes.weight}
                    palletId={palletId}
                    customDescription={palletAttributes.customDescription}
                    setPalletsAttributes={setPalletsAttributes}
                    showWeightWarning={showWeightWarning}
                  />
                )}
              </div>
              {handlePalletDelete && (
                <StyledTrash>
                  <IconPointer
                    id={`delete-pallet-${pallet?.id}`}
                    className="fas fa-trash text-danger ms-3"
                    onClick={() => handlePalletDelete(palletId)}
                  />
                  {pallet?.attributes?.isCustom && (
                    <IconPointer
                      id={`edit-pallet-${pallet?.id}`}
                      className={`${editPalletClasses} ms-3`}
                      onClick={togglePalletEdit}
                    />
                  )}
                </StyledTrash>
              )}
            </div>
            {provided.placeholder}
            <div className="d-flex w-100 text-white align-items-center justify-content-end me-5">
              <div className="font-bold me-2">Total Weight:</div>
              {editWeight ? (
                <input
                  className="w-25 form-control me-1"
                  id={`edit-pallet-weight-${palletId}`}
                  value={weight}
                  onChange={(e) => changeWeight(e.target.value)}
                  onBlur={() => setEditWeight(false)}
                  autoFocus
                />
              ) : (
                <div id={`edit-pallet-weight-${palletId}`} className="cursor-pointer me-1" onClick={() => setEditWeight(true)}>
                  {`${cleanDecimals(weight)} lbs`}
                </div>
              )}
            </div>
          </StyledPallet>
        )}
      </Droppable>
      {showWeightWarning && <StyledWarning>Weight is over limit.</StyledWarning>}
    </>
  );
};

const StyledPallet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: ${(props) => (props.isDraggingOver ? "2px" : "0")};
  border-radius: 4px;
  border-color: #049dff;
  color: #049dff;
  border-style: dashed;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
`;

const StyledTrash = styled.div`
  width: 50px;
  justify-content: start;
  margin-left: 0.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const StyledWarning = styled.div`
  color: #721c24;
  background-color: #f5c6cb;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 5px;
  margin-left: auto;
  margin-right: 43px;
`;

Pallet.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pallet: PropTypes.shape({
    id: PropTypes.number,
    attributes: { isLocked: PropTypes.bool, weightExceeded: PropTypes.bool, quantity: PropTypes.number },
  }).isRequired,
  billOfLadingId: PropTypes.number.isRequired,
  handlePalletDelete: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handlePalletAttributesChange: PropTypes.func.isRequired,
  quantities: PropTypes.shape().isRequired,
  palletsLoading: PropTypes.bool.isRequired,
};

Pallet.defaultProps = {
  handlePalletDelete: null,
};

export default Pallet;
