import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Icon = styled.i`
  color: #000;
  font-size: 2rem;
`;

const getIconClass = (brand) => {
  switch (brand) {
    case "visa":
      return "fab fa-cc-visa";
    case "mastercard":
      return "fab fa-cc-mastercard";
    case "discover":
      return "fab fa-cc-discover";
    case "amex":
      return "fab fa-cc-amex";
    case "diners":
      return "fab fa-cc-diners-club";
    case "jcb":
      return "fab fa-cc-jcb";
    default:
      return "fa fa-credit-card";
  }
};

const CardIcon = ({ brand, ...props }) => {
  return <Icon className={`${props?.className ?? ""} ${getIconClass(brand)}`} />;
};

CardIcon.defaultProps = {
  brand: null,
};

CardIcon.propTypes = {
  brand: PropTypes.string,
};

export default CardIcon;
