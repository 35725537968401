import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canUpdateCustomersAllParams } from "@ROM/Customers/permissions";
import Form from "@ROM-ui/Form";
import ShadowContainer from "@ROM-ui/ShadowContainer";
import Button from "@ROM-ui/Button";

const Filters = ({ handleFilterChange, handleClearFilters, filters }) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <ShadowContainer className="mt-2 mb-4">
      <div className="d-flex flex-wrap flex-md-nowrap align-items-center">
        <div className="mb-2 w-100 me-md-3">
          <Form.Control
            type="text"
            name="name"
            placeholder="Name"
            onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
            value={filters.name || ""}
          />
        </div>
        <div className="mb-2 w-100">
          <Form.Control
            type="text"
            name="invoiceNumberPrefix"
            placeholder="Invoice No."
            onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
            value={filters.invoiceNumberPrefix || ""}
          />
        </div>
        {canUpdateCustomersAllParams(currentUser) && (
          <div className="mb-2 ms-md-3 text-nowrap">
            <Form.Check
              type="checkbox"
              checked={filters.isActive}
              label="Is Active"
              name="isActive"
              onChange={() => {
                handleFilterChange("isActive", !filters.isActive);
              }}
            />
          </div>
        )}
      </div>
      <div>
        <Button size="sm" variant="link" onClick={handleClearFilters} className="p-0">
          Clear Filters
        </Button>
      </div>
    </ShadowContainer>
  );
};

Filters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape.isRequired,
};

export default Filters;
