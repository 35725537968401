import { createSelector } from "reselect";

const deliveriesState = (state) => state.deliveries;

export const selectLoading = createSelector(deliveriesState, (deliveries) => deliveries.loading);

export const selectCurrentDelivery = createSelector(deliveriesState, (deliveries) => deliveries?.current?.delivery);

export const selectAllDeliveries = createSelector(deliveriesState, (deliveries) => deliveries?.all?.items);

export const selectAllIncluded = createSelector(deliveriesState, (deliveries) => deliveries?.all?.included);

const selectAllIncludedType = (type) =>
  createSelector(selectAllIncluded, (included) => included.filter((item) => item?.type === type));

export const selectAllDeliveryItems = selectAllIncludedType("deliveryItem");

export const selectAllDeliveryAssets = selectAllIncludedType("asset");

export const selectAllDeliveryOrders = selectAllIncludedType("order");

export const selectAllDeliveryCustomers = selectAllIncludedType("customer");

export const selectAllDeliveryWarehouses = selectAllIncludedType("warehouse");

export const selectIncludedBillOfLading = (bolId) =>
  createSelector(selectAllIncluded, (included) => included.find((item) => item?.type === "billOfLading" && item?.id === bolId));

export const selectIncludedDeliveryShipping = (dshippingId) =>
  createSelector(selectAllIncluded, (included) =>
    included.find((item) => item?.type === "deliveryShipping" && item?.id === dshippingId)
  );

export const selectPagination = createSelector(deliveriesState, (deliveries) => deliveries?.pagination);
