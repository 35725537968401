import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import { orderUrl, editDeliveryUrl } from "@ROM-utils/urlHelpers";

import PointerSpan from "@ROM-ui/PointerSpan";
import DeliveryStatusBadge from "@ROM/Deliveries/components/common/DeliveryStatusBadge";

import ActionSection from "@ROM/Deliveries/components/OrderDeliveries/ActionSection";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { isDelivered, showEditDeliveryButton } from "@ROM/Deliveries/utils";
import PdfActions from "@ROM/Deliveries/components/PdfActions";

const DeliveryListRow = ({
  delivery,
  deliveryItems,
  deliveryAssets,
  orders,
  customers,
  warehouses,
  showDetails,
  setShowDetails,
}) => {
  const {
    id: deliveryId,
    attributes,
    relationships: {
      order: {
        data: { id: orderId },
      },
    },
  } = delivery;
  const history = useHistory();
  const [error, setError] = useState(null);
  const currentUser = useSelector(selectCurrentUser);

  const ownDeliveryItems = deliveryItems.filter((item) => item.attributes.deliveryId.toString() === deliveryId);
  const ownDeliveryAssets = deliveryAssets.filter((asset) => asset.relationships.owner.data.id === deliveryId);
  const order = orders.find((each) => each.id === orderId);
  const customerId = order.relationships.customer.data.id;
  const customer = customers.find((item) => item.id === customerId);
  const warehouse = warehouses.find((item) => item.id === delivery.attributes.warehouseId?.toString());

  const canEdit = showEditDeliveryButton(order, delivery, currentUser);

  return (
    <>
      <tr onClick={() => setShowDetails()} className="text-nowrap fw-bold cursor-pointer">
        <StyledTd className="rounded-start ps-3">
          <DeliveryStatusBadge status={attributes.status} />
        </StyledTd>
        <StyledTd>
          <Link to={orderUrl(order.id)}>{order.attributes.poNumber}</Link>
        </StyledTd>

        <StyledTd>{attributes.deliveryNumber}</StyledTd>
        <StyledTd>{customer.attributes.name}</StyledTd>

        <StyledTd small className="text-center">
          {ownDeliveryItems?.length || 0}
        </StyledTd>
        <StyledTd small>{warehouse?.attributes.name || "Unset"}</StyledTd>

        <StyledTd className="rounded-end">
          <div className="d-flex">
            {canEdit && (
              <PointerSpan
                onClick={() => history.push(editDeliveryUrl(orderId))}
                className="fa fa-edit text-primary me-xl-2 fa-fw "
                title="Edit"
              />
            )}
            <PdfActions
              deliveryNumber={attributes.deliveryNumber}
              deliveryItems={ownDeliveryItems}
              orderId={orderId}
              setError={setError}
            />
          </div>
        </StyledTd>
      </tr>
      {showDetails && (
        <tr className="bg-light rounded-bottom">
          <td colSpan="8">
            {!isDelivered(delivery) && (
              <div className="mx-2 mt-3">
                <ActionSection order={order} delivery={delivery} />
              </div>
            )}

            {delivery.attributes.notes && (
              <div className="mx-3 mt-3">
                <strong>Notes: </strong>
                <p>{delivery.attributes.notes}</p>
              </div>
            )}

            <div className="mx-3 mt-3">
              <strong>Delivery items:</strong>
              {ownDeliveryItems.map((item) => (
                <div key={item.id} className="d-flex">
                  <QuantityDiv className="text-end">{`${item.attributes.quantity}x`}</QuantityDiv>
                  <div>{item.attributes.productName}</div>
                </div>
              ))}
            </div>

            {ownDeliveryAssets.length > 0 && (
              <div className="mx-3 mt-3">
                <strong>Deliver confirmation photos:</strong>
                <div className="mt-2">
                  {ownDeliveryAssets.map((asset, index) => {
                    const assetName = `Delivery confirmation ${index}`;
                    const assetUrl = asset.attributes.file.url;

                    return (
                      <a
                        key={asset.id}
                        data-type="image"
                        href={assetUrl}
                        data-fancybox={`galery-${assetName}`}
                        data-caption={assetName}
                        onClick={(e) => e.stopPropagation()}
                        className="me-2"
                      >
                        <ThumbImage src={assetUrl} />
                      </a>
                    );
                  })}
                </div>
              </div>
            )}

            {delivery.attributes.receiverSignature.url && (
              <div className="mx-3 mt-3 overflow-hidden">
                <strong>Deliver confirmation signature:</strong>
                <div className="mt-2">
                  <a
                    key={delivery.id}
                    data-type="image"
                    href={delivery.attributes.receiverSignature.url}
                    data-fancybox="galery-delivery-signature"
                    data-caption="Delivery Signature"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ThumbImage src={delivery.attributes.receiverSignature.url} />
                  </a>
                  <div>{delivery.attributes.receiverName}</div>
                </div>
              </div>
            )}
          </td>
        </tr>
      )}
      {error && <p className="text-danger mt-2 text-end">Something went wrong, please try again later.</p>}
    </>
  );
};

DeliveryListRow.propTypes = {
  delivery: PropTypes.shape().isRequired,
  deliveryItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  deliveryAssets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  warehouses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showDetails: PropTypes.bool.isRequired,
  setShowDetails: PropTypes.func.isRequired,
};

const StyledTd = styled.td`
  vertical-align: middle !important;
  background-color: #f5f5f5 !important;
  height: 50px;
`;

const QuantityDiv = styled.div`
  width: 3rem;
  margin-right: 0.5rem;
`;

const ThumbImage = styled.img`
  width: auto;
  height: 50px;
`;

export default DeliveryListRow;
