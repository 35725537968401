import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";

import { selectCurrentUser, selectSessionCustomer } from "@ROM/Auth/selectors";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { isSuperAdmin } from "@ROM/Auth/utils";
import { canAccessCustomerAdmin } from "@ROM/CustomerAdmin/permissions";
import { canAccessCompanyAdmin } from "@ROM/CompanyAdmin/permissions";
import { canAccessCompanyInCompanyAdmin } from "@ROM/Company/permissions";
import { canSeeDeliveries } from "@ROM/Deliveries/permissions";
import { canSeeWarehouseInventories } from "@ROM/WarehouseInventories/permissions";
import { logout } from "@ROM/Auth/actions";
import Nav from "@ROM-ui/Nav";
import Navbar from "@ROM-ui/Navbar";
import {
  inventoriesUrl,
  ordersUrl,
  deliveriesUrl,
  newOrderUrl,
  accountUrl,
  companyAdminUrl,
  companyAdminCustomersUrl,
  adminUrl,
} from "@ROM-utils/urlHelpers";
import Brand from "./Brand";
import { customerAdminUrlForUser } from "./utils";
import StyledIcon from "./StyledIcon";

const MobileNavbar = ({ children, companyAdmin = false }) => {
  const currentUser = useSelector(selectCurrentUser);
  const currentCompany = useSelector(selectCurrentCompany);
  const selectedCustomer = useSelector(selectSessionCustomer);
  const dispatch = useDispatch();

  const onLogout = () => dispatch(logout());

  return (
    <Navbar bg="light" expand="lg" fixed="top" className="d-md-none px-3">
      <Brand currentCompany={currentCompany} />
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <StyledNavbarCollapse className="justify-content-end">
        <Nav>
          {isSuperAdmin(currentUser) && (
            <LinkContainer to={adminUrl()} className="mt-2">
              <Nav.Link className="text-secondary">
                <StyledIcon className="fas fa-user-shield fa-fw me-1" />
                Admin Dashboard
              </Nav.Link>
            </LinkContainer>
          )}
          {canAccessCompanyAdmin(currentUser) && !companyAdmin && (
            <LinkContainer to={canAccessCompanyInCompanyAdmin(currentUser) ? companyAdminUrl() : companyAdminCustomersUrl()}>
              <Nav.Link className="text-secondary">
                <StyledIcon className="fas fa-users-cog fa-fw me-1" />
                Company Admin
              </Nav.Link>
            </LinkContainer>
          )}
          {!canAccessCompanyAdmin(currentUser) && canAccessCustomerAdmin(currentUser) && (
            <LinkContainer to={customerAdminUrlForUser(currentUser)}>
              <Nav.Link className="text-secondary">
                <StyledIcon className="fas fa-users-cog fa-fw me-1" />
                Customer Admin
              </Nav.Link>
            </LinkContainer>
          )}
          {canSeeWarehouseInventories(currentUser) && (
            <LinkContainer to={inventoriesUrl()}>
              <Nav.Link className="text-secondary">
                <StyledIcon className="fas fa-th fa-fw me-1" />
                Inventory
              </Nav.Link>
            </LinkContainer>
          )}
          <LinkContainer to={ordersUrl()}>
            <Nav.Link className="text-secondary">
              <StyledIcon className="fas fa-file-invoice-dollar fa-fw me-1" />
              Orders
            </Nav.Link>
          </LinkContainer>
          {canSeeDeliveries(currentUser) && (
            <LinkContainer to={deliveriesUrl()}>
              <Nav.Link className="text-secondary">
                <StyledIcon className="fas fa-truck fa-fw me-1" />
                Deliveries
              </Nav.Link>
            </LinkContainer>
          )}
          {selectedCustomer && (
            <LinkContainer to={newOrderUrl()}>
              <Nav.Link className="text-secondary">
                <StyledIcon className="fas fa-shopping-cart fa-fw me-1" />
                Cart
              </Nav.Link>
            </LinkContainer>
          )}
          <LinkContainer to={accountUrl()}>
            <Nav.Link className="text-secondary">
              <StyledIcon className="fas fa-user-edit me-1 " />
              Account
            </Nav.Link>
          </LinkContainer>
          {children}
          <Nav.Link className="text-secondary" onClick={onLogout}>
            <StyledIcon className="fas fa-sign-out-alt fa-fw me-1" />
            Logout
          </Nav.Link>
        </Nav>
      </StyledNavbarCollapse>
    </Navbar>
  );
};

const StyledNavbarCollapse = styled(Navbar.Collapse)`
  max-height: 80vh;
  overflow-y: auto;
`;

MobileNavbar.propTypes = {
  children: PropTypes.node,
  companyAdmin: PropTypes.bool,
};

MobileNavbar.defaultProps = {
  children: null,
  companyAdmin: false,
};

export default MobileNavbar;
