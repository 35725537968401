import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { getVariant } from "@ROM/PriceLists/utils";
import Badge from "@ROM-ui/Badge";
import OverlayTrigger from "@ROM-ui/OverlayTrigger";
import Tooltip from "@ROM-ui/Tooltip";
import IconPointer from "@ROM-ui/IconPointer";
import styled from "styled-components";
import Button from "@ROM-ui/Button";
import { canEditPriceList, canDeletePriceList } from "@ROM/PriceLists/permissions";

const Detail = ({
  priceList,
  itemsLength,
  handleEdit,
  handleDelete,
  showPriceEdits,
  canSave,
  onEditPricesClick,
  onSavePricesClick,
  onCancelPricesClick,
}) => {
  moment.locale("en-us");

  const currentUser = useSelector(selectCurrentUser);

  const dateFrom = priceList && priceList.attributes.dateFrom;
  const dateTo = priceList && priceList.attributes.dateTo;

  return (
    priceList && (
      <div className="d-flex flex-wrap align-items-center">
        <div className="mt-4">
          <p>{`Price List #${priceList.id}`}</p>
          <StyledDiv>
            <h4 className="m-0 me-4">{priceList.attributes.name}</h4>
            <div>
              <Badge bg={getVariant(priceList.attributes.status)} className="me-2 px-2">
                {priceList.attributes.status.toUpperCase()}
              </Badge>
              {priceList.attributes.isDefaultPriceList && (
                <Badge className="px-2" bg="primary">
                  DEFAULT
                </Badge>
              )}
            </div>
            <div className="text-nowrap ms-auto">
              {canEditPriceList(currentUser) && (
                <IconPointer className="fas fa-edit text-warning me-1 ms-3" onClick={handleEdit} />
              )}
              {canDeletePriceList(currentUser) && (
                <IconPointer className="fas fa-trash text-danger ms-1" onClick={handleDelete} />
              )}
            </div>
          </StyledDiv>
          <div className="d-flex">
            <StyledDates className="text-secondary">
              {dateFrom ? `From date: ${moment(dateFrom).format("MMM DD, YYYY")}` : ""}
            </StyledDates>
            <StyledDates className="text-secondary">
              {dateTo ? `, To date: ${moment(dateTo).format("MMM DD, YYYY")}` : ""}
            </StyledDates>
          </div>
          <h6 className="text-secondary">{`${itemsLength} ${itemsLength === 1 ? "item" : "items"}`}</h6>
        </div>
        <div className="d-flex justify-content-end ms-auto mt-4">
          {showPriceEdits ? (
            <div className="flex">
              <Button variant="outline-primary me-3" onClick={onCancelPricesClick}>
                Cancel
              </Button>
              <Button variant="success" disabled={!canSave} onClick={onSavePricesClick}>
                Save
              </Button>
            </div>
          ) : (
            canEditPriceList(currentUser) && (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Price list can&apos;t be edited because is from Intacct</Tooltip>}
              >
                <div className="d-inline-block">
                  <Button variant="warning" onClick={onEditPricesClick} disabled={priceList.attributes.isFromIntacct}>
                    Edit prices
                  </Button>
                </div>
              </OverlayTrigger>
            )
          )}
        </div>
      </div>
    )
  );
};

const StyledDates = styled.p`
  font-size: smaller;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem 0px;
`;

Detail.propTypes = {
  priceList: PropTypes.shape().isRequired,
  itemsLength: PropTypes.number.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  showPriceEdits: PropTypes.bool.isRequired,
  canSave: PropTypes.bool.isRequired,
  onEditPricesClick: PropTypes.func.isRequired,
  onSavePricesClick: PropTypes.func.isRequired,
  onCancelPricesClick: PropTypes.func.isRequired,
};

export default Detail;
