import { createGlobalStyle } from "styled-components/macro";

export default createGlobalStyle`
  html {
    font-size: 16px;
  }
  body {
    margin: 0;
    font-size: 100%;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;

    .dashboard {
      padding-top: 4rem;
    }
  }
`;
