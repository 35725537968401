/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "@ROM-ui/DatePicker";
import FileUploader from "@ROM-common/FileUploader";
import Form from "@ROM-ui/Form";

const GenericField = ({ value, onChange, type, required, isInvalid, ...props }) => (
  <Form.Control
    required={required}
    isInvalid={isInvalid}
    type={type}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    {...props}
  />
);

GenericField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
};

export const StringRowField = ({ ...props }) => <GenericField type="text" {...props} />;

export const NumberRowField = ({ ...props }) => <GenericField type="number" step="any" {...props} />;

const onDateChange = (value, callback) => {
  callback(value ? value.toISOString() : null);
};

export const DateRowField = ({ value, onChange, ...props }) => {
  return (
    <DatePicker
      selected={new Date(value)}
      onChange={(v) => onDateChange(v, onChange)}
      dateFormat="MM/dd/yyyy"
      showYearDropdown
      showMonthDropdown
      {...props}
    />
  );
};

DateRowField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const DateTimeRowField = ({ value, onChange, ...props }) => {
  return (
    <DatePicker
      selected={new Date(value)}
      onChange={(v) => onDateChange(v, onChange)}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={1}
      timeCaption="time"
      dateFormat="MM/dd/yyyy HH:mm"
      showYearDropdown
      showMonthDropdown
      {...props}
    />
  );
};

DateTimeRowField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const AttachmentRowField = ({ value, onChange }) => {
  const [asset, setAsset] = useState(value ? [value] : []);
  return (
    <FileUploader
      currentAssets={asset}
      disableSubmitButton={() => {}}
      afterAssetUpload={(assets, setUploadingFiles, disableSubmitButton) => {
        setAsset(assets);
        onChange(assets.length > 0 ? { name: assets[0].name, file: assets[0].data } : null);
        setUploadingFiles(false);
        disableSubmitButton(false);
      }}
      deleteAsset={(newAsset, setUploadingFiles, disableSubmitButton) => {
        const newAssets = asset.filter((currentAsset) => currentAsset.name !== newAsset.name);
        setAsset(newAssets);
        onChange(newAssets.length > 0 ? { name: newAssets[0].name, file: newAssets[0].data } : null);
        setUploadingFiles(false);
        disableSubmitButton(false);
      }}
    />
  );
};

AttachmentRowField.defaultProps = {
  value: null,
};

AttachmentRowField.propTypes = {
  value: PropTypes.shape({ name: PropTypes.string, file: PropTypes.string }),
  onChange: PropTypes.func.isRequired,
};
