import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "@ROM-ui/Button";
import Form from "@ROM-ui/Form";
import Col from "@ROM-ui/Col";
import Row from "@ROM-ui/Row";
import StickyBottomRow from "@ROM-ui/StickyRow/StickyBottomRow";
import StickyTopRow from "@ROM-ui/StickyRow/StickyTopRow";
import ProductsModal from "@ROM/Products/components/Selector/ProductsModal";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { canSetDiscounts, canSetTrial } from "@ROM/Orders/permissions";

import { updateCartItemsTrial } from "@ROM/Cart/actions";
import { destroy as destroyCartItem, update as updateCartItem, reSort } from "@ROM/Cart/OrderItems/actions";
import { selectOrderItems } from "@ROM/Cart/OrderItems/selectors";
import { selectCartOrder } from "@ROM/Cart/selectors";

import Amount from "./Amount";
import DiscountModal from "../DiscountModal";
import { OrderItemRow, OrderItemRowHeader } from "../OrderItemRow";

/**
 * Cart items table for New Order
 *
 */

const List = ({ loadingPrice, setLoadingPrice, onChangeOrderDiscount, onChangeOrderItemDiscount, customer, isMobile }) => {
  const dispatch = useDispatch();
  const cartOrder = useSelector(selectCartOrder);
  const items = useSelector(selectOrderItems);
  const currentUser = useSelector(selectCurrentUser);
  const currentCompany = useSelector(selectCurrentCompany);

  const isEmpty = items.length === 0;

  const [show, setShow] = useState(false);

  const [loadingItem, setLoadingItem] = useState(null);
  const isLoading = (item) => loadingItem === -1 || loadingItem === item.id;

  const [discountType, setDiscountType] = useState("dollar");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [showDiscountModalId, setShowDiscountModalId] = useState(null);

  const handleAddOrderItemDiscount = (item) => () => {
    setDiscountType(item.attributes.discountType || "dollar");
    setDiscountAmount(item.attributes.discountAmount || 0);
    setShowDiscountModalId(item.id);
  };

  const handleAddOrderDiscount = () => {
    setDiscountType(cartOrder.attributes.discountType || "dollar");
    setDiscountAmount(cartOrder.attributes.discountAmount || 0);
    setShowDiscountModalId("order");
  };

  const handleRemoveItem = (item) => {
    setLoadingPrice(true);
    dispatch(destroyCartItem(item.attributes.orderId, item.id)).then(() => {
      setLoadingPrice(false);
    });
  };

  const handleNotesChange = async (item, text) => {
    setLoadingPrice(true);
    dispatch(updateCartItem({ ...item, attributes: { ...item.attributes, notes: text } }));
    setLoadingPrice(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const itemToUpdate = list[startIndex];
    const [movedItem] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, movedItem);

    itemToUpdate.attributes.position = endIndex;
    dispatch(reSort(itemToUpdate));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    reorder(items, result.source.index, result.destination.index);
  };

  const showTrial = items.length > 0 && items.some((item) => item.attributes?.isTrial || false);
  const handleTrialProducts = async (event) => {
    setLoadingPrice(true);
    setLoadingItem(-1);
    await dispatch(updateCartItemsTrial(cartOrder.id, event.target.checked));
    setLoadingItem(null);
    setLoadingPrice(false);
  };

  const handleTrialProduct = async (item, isTrial) => {
    setLoadingPrice(true);
    setLoadingItem(item.id);
    await dispatch(updateCartItem({ ...item, attributes: { ...item.attributes, isTrial } }));
    setLoadingItem(null);
    setLoadingPrice(false);
  };

  const setDiscounts = canSetDiscounts(currentUser, currentCompany);
  const setTrial = canSetTrial(currentUser);
  const showDiscountColumn = setDiscounts || items.some((currentItem) => currentItem.attributes.discountAmount !== 0);

  return (
    <>
      <StickyTopRow isMobile={isMobile} className="m-0">
        <Col className={`align-self-end px-4 mb-3 ${isMobile ? "" : "rounded-top bg-white"}`}>
          <div className="d-flex flex-col justify-content-between align-items-center my-3">
            <Button
              className="my-2"
              size="sm"
              variant={`outline-${isMobile ? "light" : "primary"}`}
              onClick={() => setShow(true)}
              id="add-products"
            >
              Add Products
            </Button>
            {(showTrial || setTrial) && (
              <Form.Check className="d-flex flex-col justify-content-end align-items-center">
                <Form.Check.Label className={`${isMobile ? "text-white" : "fw-bold"}`}>Trial Products</Form.Check.Label>
                <Form.Check.Input
                  className="p-0 my-0 ms-2 me-3"
                  type="checkbox"
                  onChange={handleTrialProducts}
                  checked={showTrial}
                  disabled={!setTrial}
                />
              </Form.Check>
            )}
          </div>
          {!isEmpty && <OrderItemRowHeader showTrial={showTrial} showDiscountColumn={showDiscountColumn} />}
        </Col>
      </StickyTopRow>
      <Row className="m-0">
        <Col className={`px-4 mb-3 ${isMobile ? "" : "bg-white"}`}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((item, index) => {
                    return (
                      <Draggable draggableId={item.id} index={index} key={item.id}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <OrderItemRow
                              item={item}
                              handleRemoveItem={handleRemoveItem}
                              handleNotesChange={handleNotesChange}
                              key={item.id}
                              index={index}
                              dragHandleProps={provided.dragHandleProps}
                              handleAddDiscount={handleAddOrderItemDiscount(item)}
                              showDiscountColumn={showDiscountColumn}
                              loadingPrice={isLoading(item)}
                              showTrial={showTrial}
                              handleTrialProduct={handleTrialProduct}
                              canSetDiscounts={setDiscounts}
                              canSetTrial={setTrial}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
      <StickyBottomRow className="m-0">
        <Amount
          order={cartOrder}
          items={items}
          customer={customer}
          company={currentCompany}
          loading={loadingPrice}
          isMobile={isMobile}
          onChangeDiscount={handleAddOrderDiscount}
          canSetDiscounts={setDiscounts}
        />
      </StickyBottomRow>
      <ProductsModal show={show} customer={customer} onCancel={() => setShow(false)} handleRemoveItem={handleRemoveItem} />
      <DiscountModal
        amount={discountAmount}
        type={discountType}
        isShowing={showDiscountModalId !== null}
        isMobile={isMobile}
        onUpdate={(type, amount) => {
          if (showDiscountModalId === "order") {
            onChangeOrderDiscount(type, amount);
          } else {
            setLoadingItem(showDiscountModalId);
            const clearLoadingItem = () => setLoadingItem(null);
            onChangeOrderItemDiscount(showDiscountModalId, type, amount).then(clearLoadingItem).catch(clearLoadingItem);
          }
          setShowDiscountModalId(null);
        }}
        onCancel={() => setShowDiscountModalId(null)}
      />
    </>
  );
};

List.propTypes = {
  setIsTrial: PropTypes.func.isRequired,
  loadingPrice: PropTypes.bool.isRequired,
  setLoadingPrice: PropTypes.func.isRequired,
  onChangeOrderDiscount: PropTypes.func.isRequired,
  onChangeOrderItemDiscount: PropTypes.func.isRequired,
  customer: PropTypes.shape().isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default List;
