import React from "react";
import { Route } from "react-router-dom";
import Page from "@ROM-pages/CompanyAdmin/Products/Edit";
import Layout from "@ROM-pages/Layouts/CompanyAdmin";

class RouteHandler extends Route {
  render() {
    return (
      <Layout>
        <Page productId={this.props.computedMatch.params.productId} />
      </Layout>
    );
  }
}

export default RouteHandler;
