import React from "react";
import PropTypes from "prop-types";
import ListOrders from "@ROM/CustomerAdmin/components/Orders/List";

const OrdersPanel = ({ orders, loading, pagination, handlePageClick }) => {
  return <ListOrders orders={orders} loading={loading} pagination={pagination} handlePageClick={handlePageClick} />;
};

OrdersPanel.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape().isRequired,
  handlePageClick: PropTypes.func.isRequired,
};

export default OrdersPanel;
