import React from "react";
import { useSelector } from "react-redux";

import Section from "@ROM-components/common/Section";
import CompanyList from "@ROM/Auth/Account/components/CompanyList";
import { selectCurrentUserId } from "@ROM/Auth/selectors";

import UserForm from "@ROM/CompanyAdmin/Users/Edit";
import UserNotifications from "@ROM/CompanyAdmin/Users/Edit/UserNotifications";

const Index = () => {
  const userId = useSelector(selectCurrentUserId);

  return (
    <>
      <Section title="User Account" className="bg-white px-3">
        <UserForm userId={userId} readOnlyRegions readOnlyRoles readOnlyCustomers />
      </Section>
      <Section title="My Companies" className="bg-white px-3">
        <CompanyList />
      </Section>
      <Section title="Notification Settings" className="bg-white px-3">
        <UserNotifications userId={userId} />
      </Section>
    </>
  );
};

export default Index;
