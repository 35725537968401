import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection(billOfLadingId) {
    return `/bill_of_ladings/${billOfLadingId}/bill_of_lading_items`;
  },

  resource(billOfLadingId, id) {
    return `${urls.collection(billOfLadingId)}/${id}`;
  },
};

export default {
  create(attributes) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(attributes.billOfLadingId), attributes);
  },

  update(id, attributes) {
    const client = getApiClient(API_URL);
    return client.patch(urls.resource(attributes.billOfLadingId, id), attributes);
  },

  destroy(billOfLadingId, id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(billOfLadingId, id), { billOfLadingId: billOfLadingId });
  },

  reSort(id, attributes) {
    const client = getApiClient(API_URL);
    return client.patch(`${urls.resource(attributes.billOfLadingId, id)}/re_sort`, attributes);
  },
};
