export const OPEN = "open";
export const APPROVE = "approve";
export const REJECT = "reject";
export const CONFIRM = "confirm";
export const IN_PROGRESS = "in_progress";
export const SHIP = "ship";
export const COMPLETE = "complete";
export const INVOICE = "invoice";
export const CANCEL = "cancel";

export const COMPLETED = "completed";
export const CANCELLED = "cancelled";
export const ABANDONED = "abandoned";
export const REJECTED = "rejected";
export const CONFIRMED = "confirmed";
export const PROGRESS = "inProgress";
export const SHIPPED = "shipped";
export const INVOICED = "invoiced";
export const OPENED = "opened";
export const CART = "cart";
export const APROVED = "approved";
