import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { selectCurrentUser, selectSessionCustomer } from "@ROM/Auth/selectors";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { logout } from "@ROM/Auth/actions";
import StyledContainer from "@ROM-ui/StyledContainer";
import Nav from "@ROM-ui/Nav";
import NavDropdown from "@ROM-ui/NavDropdown";
import Navbar from "@ROM-ui/Navbar";
import {
  inventoriesUrl,
  ordersUrl,
  newOrderUrl,
  deliveriesUrl,
  accountUrl,
  companyAdminUrl,
  companyAdminCustomersUrl,
  adminUrl,
} from "@ROM-utils/urlHelpers";
import { LinkContainer } from "react-router-bootstrap";
import { isSuperAdmin } from "@ROM/Auth/utils";
import { canAccessCustomerAdmin } from "@ROM/CustomerAdmin/permissions";
import { canAccessCompanyAdmin } from "@ROM/CompanyAdmin/permissions";
import { canAccessCompanyInCompanyAdmin } from "@ROM/Company/permissions";
import { canSeeDeliveries } from "@ROM/Deliveries/permissions";
import { canSeeWarehouseInventories } from "@ROM/WarehouseInventories/permissions";
import Brand from "./Brand";
import { customerAdminUrlForUser } from "./utils";
import StyledIcon from "./StyledIcon";

const DesktopNavbar = ({ children, companyAdmin, fullWidth }) => {
  const currentUser = useSelector(selectCurrentUser);
  const currentCompany = useSelector(selectCurrentCompany);
  const selectedCustomer = useSelector(selectSessionCustomer);
  const dispatch = useDispatch();

  const history = useHistory();
  const navToCustomerAdmin = () => {
    history.push(customerAdminUrlForUser(currentUser));
  };

  return (
    <StyledNavbar
      bg="light"
      expand
      fixed="top"
      className="d-none d-md-inline px-0"
      id="desktop-navbar"
      $accentColor={currentCompany.attributes.accentColor}
    >
      <NavbarContainer fullWidth={fullWidth}>
        <Brand currentCompany={currentCompany} />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {children}
            {children && <Divider />}
            {canSeeWarehouseInventories(currentUser) && (
              <LinkContainer to={inventoriesUrl()}>
                <Nav.Link className="text-secondary">
                  <StyledIcon className="fas fa-th fa-fw me-1" />
                  Inventory
                </Nav.Link>
              </LinkContainer>
            )}
            <LinkContainer to={ordersUrl()}>
              <Nav.Link className="text-secondary">
                <StyledIcon className="fas fa-file-invoice-dollar fa-fw me-1" />
                Orders
              </Nav.Link>
            </LinkContainer>
            {canSeeDeliveries(currentUser) && (
              <LinkContainer to={deliveriesUrl()}>
                <Nav.Link className="text-secondary">
                  <StyledIcon className="fas fa-truck fa-fw me-1" />
                  Deliveries
                </Nav.Link>
              </LinkContainer>
            )}
            {selectedCustomer && (
              <LinkContainer to={newOrderUrl()}>
                <Nav.Link className="text-secondary">
                  <StyledIcon className="fas fa-shopping-cart fa-fw me-1" />
                  Cart
                </Nav.Link>
              </LinkContainer>
            )}
            <NavDropdown
              id="navbar-dropdown-menu-icon"
              title={<StyledIcon className="fas fa-user text-secondary" />}
              className="d-md-inline d-none me-n2"
            >
              <NavDropdown.Item>{currentUser.attributes.email}</NavDropdown.Item>
              <hr />
              {isSuperAdmin(currentUser) && (
                <NavDropdown.Item
                  onClick={() => {
                    window.location.href = adminUrl();
                  }}
                >
                  <StyledIcon className="fas fa-user-shield me-1" />
                  Admin Dashboard
                </NavDropdown.Item>
              )}
              {canAccessCompanyAdmin(currentUser) && !companyAdmin && (
                <NavDropdown.Item
                  id="company-admin-dropdown-element"
                  onClick={() => {
                    if (canAccessCompanyInCompanyAdmin(currentUser)) {
                      history.push(companyAdminUrl());
                    } else {
                      history.push(companyAdminCustomersUrl());
                    }
                  }}
                >
                  <StyledIcon className="fas fa-users-cog me-1" />
                  Company Admin
                </NavDropdown.Item>
              )}
              {!canAccessCompanyAdmin(currentUser) && canAccessCustomerAdmin(currentUser) && (
                <NavDropdown.Item id="customer-admin-dropdown-element" onClick={navToCustomerAdmin}>
                  <StyledIcon className="fas fa-users-cog me-1" />
                  Customer Admin
                </NavDropdown.Item>
              )}
              <NavDropdown.Item onClick={() => history.push(accountUrl())}>
                <StyledIcon className="fas fa-user-edit me-1" />
                Account
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  dispatch(logout());
                }}
              >
                <StyledIcon className="fas fa-sign-out-alt me-1" />
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </NavbarContainer>
    </StyledNavbar>
  );
};

const NavbarContainer = styled(StyledContainer)`
  display: flex;
  align-items: center;

  @media (min-width: 1200px) {
    max-width: ${(props) => {
      if (props.fullWidth) return "100%";
      return "80%";
    }};
  }
`;

const StyledNavbar = styled(Navbar)`
  border-top: ${(props) => `6px solid ${props.$accentColor}`};
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.27);
`;

const Divider = styled.div`
  padding-right: 1px;
  background-color: #d1d8de;
`;

DesktopNavbar.defaultProps = {
  children: null,
  companyAdmin: false,
  fullWidth: false,
};

DesktopNavbar.propTypes = {
  children: PropTypes.node,
  companyAdmin: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

DesktopNavbar.defaultProps = {
  children: null,
};

export default DesktopNavbar;
