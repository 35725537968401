import { apiAction, standardAction } from "@ROM-utils/actions";
import store from "@ROM/App/store";

import Api from "./api";

export const FETCH_USER = "user/api/FETCH_USER";
export const FETCH_ALL_ROLES = "user/api/FETCH_ALL_ROLES";
export const LOGOUT = "user/api/LOGIN";
export const UPDATE_USER_ORDERS = "UPDATE_USER_ORDERS";

export function fetchUser() {
  return apiAction(FETCH_USER, Api.fetchUser());
}

export function fetchAllRoles() {
  return apiAction(FETCH_ALL_ROLES, Api.fetchAllRoles());
}

export function logout() {
  return apiAction(LOGOUT, Api.logout());
}

export function updateUserOrders(id) {
  if (!store.getState().auth.currentUser.record.attributes.ordersIds.includes(id)) {
    return standardAction(UPDATE_USER_ORDERS, { data: id });
  }
}
