import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetch as fetchEmailGroups, destroy } from "@ROM/EmailGroups/actions";
import { selectEmailGroups, selectEmailGroupUsers } from "@ROM/EmailGroups/selectors";

import AdminTable from "@ROM-components/common/AdminTable";
import Modal from "@ROM-components/common/Modal";
import Button from "@ROM-ui/Button";
import IconPointer from "@ROM-ui/IconPointer";

import { groupUsers } from "@ROM/EmailGroups/utils";
import EmailGroupCreateModal from "@ROM/EmailGroups/components/EmailGroupCreateModal";

const EmailGroupsTableHeader = () => (
  <tr>
    <th>Name</th>
    <th>Users</th>
    <th />
  </tr>
);

const EmailGroupsTable = () => {
  const dispatch = useDispatch();
  const groups = useSelector(selectEmailGroups);
  const users = useSelector(selectEmailGroupUsers);

  const [editGroupData, setEditGroupData] = useState(null);
  const [deleteGroup, setDeleteGroup] = useState(null);

  useEffect(() => {
    dispatch(fetchEmailGroups());
  }, []);

  const onCreateClick = () => {
    setEditGroupData({});
  };

  const onEditClick = (group) => {
    setEditGroupData(group);
  };

  const onCancel = () => setEditGroupData(null);

  const onDelete = (event, group) => {
    event.stopPropagation();
    setDeleteGroup(group);
  };

  const onConfirmDelete = () => {
    dispatch(destroy(deleteGroup.id));
    setDeleteGroup(null);
  };

  return (
    <>
      <div className="pt-2 d-flex justify-content-end">
        <Button id="company-admin-create-email-group" onClick={onCreateClick}>
          Create Email Group
        </Button>
      </div>
      <div className="pt-2">
        <AdminTable header={<EmailGroupsTableHeader />}>
          {groups.map((group) => {
            const userNames = groupUsers(group, users)
              .map((user) => `${user.attributes.fullName} <${user.attributes.email}>`)
              .join(", ");

            return (
              <tr
                key={group.id}
                className="cursor-pointer"
                id={`edit-email-group-${group.id}`}
                onClick={() => {
                  onEditClick(group);
                }}
              >
                <td>{group.attributes.name}</td>
                <td>{userNames}</td>
                <td>
                  <IconPointer
                    id={`delete-email-group-${group.id}`}
                    onClick={(event) => onDelete(event, group)}
                    className="fa fa-trash text-danger"
                  />
                </td>
              </tr>
            );
          })}
          {groups.length === 0 && (
            <tr>
              <td colSpan={2} className="text-center">
                <i>There are no Email groups created yet.</i>
              </td>
            </tr>
          )}
        </AdminTable>
      </div>

      {editGroupData !== null && <EmailGroupCreateModal initialData={editGroupData} initialUsers={users} onCancel={onCancel} />}

      {deleteGroup !== null && (
        <Modal
          isShowing
          confirmText="Yes, Delete"
          onConfirm={onConfirmDelete}
          onCancel={() => setDeleteGroup(null)}
          showCloseIcon={false}
        >
          {`Are you sure to delete the "${deleteGroup.attributes.name}" Email group?`}
        </Modal>
      )}
    </>
  );
};

export default EmailGroupsTable;
