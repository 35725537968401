import React from "react";
import PropTypes from "prop-types";

import LoadingDots from "@ROM-ui/LoadingDots";
import Pagination from "@ROM-components/common/Pagination";
import AdminTable from "@ROM-components/common/AdminTable";
import ProductRow from "./ProductRow";
import ProductHeader from "./ProductHeader";

const ProductTable = ({ products, onProductClick, handleFilterChange, filters, pagination, handlePageClick, loading }) => (
  <div className="pt-2">
    {loading ? (
      <LoadingDots />
    ) : (
      <>
        {products.length === 0 ? (
          <div className="text-center py-4">Products not found for the selected filters.</div>
        ) : (
          <>
            <AdminTable header={<ProductHeader handleFilterChange={handleFilterChange} filters={filters} />}>
              {products.map((product) => (
                <ProductRow key={product.id} product={product} onClick={onProductClick} />
              ))}
            </AdminTable>
            <Pagination pagination={pagination} handlePageClick={handlePageClick} />
          </>
        )}
      </>
    )}
  </div>
);

ProductTable.propTypes = {
  products: PropTypes.array.isRequired,
  onProductClick: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ProductTable;
