import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { confirm } from "@ROM/OrderStatuses/actions";
import Button from "@ROM-ui/Button";
import ActionModal from "./ActionModal";

const Confirm = ({ order, reloadOrder, setError }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");

  const handleConfirm = () => {
    dispatch(confirm({ orderId: order.id, message })).then((response) => {
      setShowModal(false);
      if (response.payload.status === 201) {
        reloadOrder();
      } else {
        setError(true);
      }
    });
  };

  return (
    <>
      <Button variant="outline-success" className="ms-3 me-2 px-4" size="sm" onClick={() => setShowModal(true)}>
        Mark as Confirm
      </Button>
      <ActionModal
        onConfirm={handleConfirm}
        onCancel={() => setShowModal(false)}
        showModal={showModal}
        value={message}
        setValue={setMessage}
      />
    </>
  );
};

Confirm.propTypes = {
  order: PropTypes.shape().isRequired,
  reloadOrder: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default Confirm;
