import React, { useState } from "react";
import PropTypes from "prop-types";

import Form from "@ROM-ui/Form";
import NumberFormater from "@ROM-ui/NumberFormater";
import Button from "@ROM-ui/Button";
import Modal from "@ROM-components/common/Modal";

const NewPaymentBtn = ({ chargeOrder, className, size }) => {
  const [amountCents, setAmountCents] = useState(NaN);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const handleChargeOrder = async () => {
    if (amountCents > 0) {
      await chargeOrder(amountCents);
      setShowModal(false);
    } else {
      setError("The amount should be greater than 0");
    }
  };

  const onModalClose = () => {
    setShowModal(false);
    setError(null);
    setAmountCents(NaN);
  };

  return (
    <div className={className}>
      <Button onClick={() => setShowModal(true)} size={size}>
        New Payment
      </Button>
      <Modal
        isShowing={showModal}
        onConfirm={handleChargeOrder}
        onCancel={onModalClose}
        header="Create New Payment"
        showCloseIcon={false}
      >
        <Form.Label>Amount</Form.Label>
        <NumberFormater
          value={amountCents}
          displayType="input"
          className="d-block w-100 rounded border p-2 bg-light"
          onValueChange={({ floatValue }) => setAmountCents(floatValue * 100)}
        />
        {error && <div className="text-danger mt-2">{error}</div>}
      </Modal>
    </div>
  );
};

NewPaymentBtn.defaultProps = {
  className: "",
  size: null,
};

NewPaymentBtn.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  chargeOrder: PropTypes.func.isRequired,
};

export default NewPaymentBtn;
