import { apiAction, standardAction } from "@ROM-utils/actions";
import PriceListApi from "./api";

export const LIST = "price_lists/api/LIST";
export const FIND = "price_lists/api/FIND";
export const DESTROY = "price_lists/api/DESTROY";
export const CREATE = "price_lists/api/CREATE";
export const UPDATE = "price_lists/api/UPDATE";
export const SET_PAGE = "price_lists/api/SET_PAGE";
export const LIST_WITH_SCROLL = "price_lists/api/LIST_WITH_SCROLL";

export function list(options = {}) {
  return apiAction(LIST, PriceListApi.list(options));
}

export function find(id) {
  return apiAction(FIND, PriceListApi.find(id));
}

export function destroy(id) {
  return apiAction(DESTROY, PriceListApi.destroy(id), { priceListId: id });
}

export function create(attributes = {}) {
  return apiAction(CREATE, PriceListApi.create(attributes));
}

export function update(id, attributes = {}) {
  return apiAction(UPDATE, PriceListApi.update(id, attributes));
}

export function setPage(page) {
  return standardAction(SET_PAGE, { page });
}

export function getFilteredPriceLists(filters, page) {
  return list({
    ...filters,
    page,
  });
}

export function getPriceListsWithScroll(page) {
  return apiAction(LIST_WITH_SCROLL, PriceListApi.list({ page }));
}
