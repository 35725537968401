import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ListItem } from "@ROM-components/common/AdminList";
import Spinner from "@ROM-ui/Spinner";
import CheckBoxSquare from "@ROM-ui/CheckBoxSquare";

import { fetchNotifications } from "@ROM/Notifications/actions";
import { selectCurrentUser } from "@ROM/Users/selectors";
import { selectNotifications } from "@ROM/Notifications/selectors";
import { find, updateDisabledNotifications } from "@ROM/Users/actions";

const UserNotifications = ({ userId }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(null);

  const allNotifications = useSelector(selectNotifications);
  const userNotifications = user?.attributes.notifications || [];

  const notifications = userNotifications.map((userNotification) => {
    const notification = allNotifications.find((item) => item.id === userNotification.id.toString());
    return {
      id: userNotification.id,
      disabled: userNotification.disabled,
      name: notification ? notification.attributes.displayName || notification.attributes.name : "",
      group: notification?.attributes.group ?? "",
      description: notification?.attributes.description,
    };
  });

  const handleNotificationChanged = async (notificationId, disabled) => {
    const disableds = userNotifications
      .filter((notification) => (notification.id !== notificationId ? notification.disabled : disabled))
      .map((notification) => notification.id);
    setLoading(notificationId);
    await dispatch(updateDisabledNotifications(user.id, { disabled_notification_ids: disableds }));
    setLoading(null);
  };

  useEffect(() => {
    if (!user || userId !== user.id) {
      dispatch(find(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);

  if (notifications.length === 0) {
    return <div className="mw-40rem p-3">No notifications</div>;
  }
  return (
    <div className="mw-40rem">
      <p>Select which notifications to receive (*)</p>
      {notifications.map((notification) => (
        <ListItem
          key={notification.id}
          hint={notification.description}
          hintPlacement="right"
          className="d-flex w-50 align-items-center justify-content-between mb-2"
          isActive={!notification.disabled}
          onClick={() => handleNotificationChanged(notification.id, !notification.disabled)}
          role="button"
          tabIndex={0}
        >
          {`${notification.group}: ${notification.name}`}
          {loading === notification.id ? (
            <Spinner animation="border" variant="primary" size="sm" />
          ) : (
            <CheckBoxSquare check={!notification.disabled} />
          )}
        </ListItem>
      ))}
      <p className="small text-secondary my-3">
        * Settings apply to other user&apos;s orders. Notifications for orders created by you will be sent even if disabled.
      </p>
    </div>
  );
};

UserNotifications.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserNotifications;
