import update from "immutability-helper";
import { handle } from "redux-pack";
import { LIST, LIST_TOP, DESTROY, SET_PAGE, LIST_WITH_SCROLL, LIST_FEATURED, FIND, UNSET_CURRENT } from "./actions";

const initialState = {
  all: {
    items: [],
    included: [],
  },
  top: {
    items: [],
    relationships: [],
  },
  featured: {
    items: [],
  },
  current: { product: null, included: [] },
  loading: false,
  pagination: {
    currentPage: 1,
    perPage: null,
    totalPages: null,
    totalRecords: null,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {}; // redux-pack puts API response in its own 'data' attribute, renaming to 'json'

  switch (type) {
    case LIST_WITH_SCROLL:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $push: json.data },
              included: { $set: json.included },
            },
            pagination: { $set: json.meta.pagination },
          });
        },
      });

    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              included: { $set: json.included },
            },
            pagination: { $set: json.meta.pagination },
          });
        },
      });

    case FIND:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            current: { product: { $set: json.data }, included: { $set: json.included } },
          });
        },
      });

    case LIST_TOP:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            top: {
              items: { $set: json.data },
              relationships: { $set: json.included },
            },
          });
        },
      });

    case LIST_FEATURED:
      return handle(state, action, {
        success: (prevState) => {
          return update(prevState, {
            featured: {
              items: { $set: json.data },
            },
          });
        },
      });

    case DESTROY:
      return handle(state, action, {
        success: (prevState) => {
          const {
            meta: { productId },
          } = action;
          const products = { ...prevState.all };
          products.items = products.items.filter((product) => product.id !== productId);
          return update(prevState, {
            all: { $set: products },
          });
        },
      });

    case SET_PAGE:
      return update(state, {
        pagination: {
          currentPage: { $set: payload.page },
        },
      });

    case UNSET_CURRENT:
      return update(state, {
        current: { product: { $set: null }, included: { $set: [] } },
      });

    default:
      return state;
  }
}

export default reducer;
