import { useReducer, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

const filtersReducer = (state, values) => ({ ...state, ...values });

const setQueryString = (history, filters) => {
  const currentValues = queryString.parse(window.location.search);

  history.replace({
    pathname: window.location.pathname,
    search: queryString.stringify({ ...currentValues, ...filters }, { skipNull: true, skipEmptyString: true }),
  });
};

const useQueryStringFilters = (initialFilters, { setInitialFiltersToQueryString } = {}) => {
  const history = useHistory();
  const firstTimeRef = useRef(true);

  const [filters, setFilter] = useReducer(filtersReducer, {
    ...initialFilters,
    ...queryString.parse(window.location.search, { parseBooleans: true }),
  });

  if (setInitialFiltersToQueryString && firstTimeRef.current) {
    firstTimeRef.current = false;
    setQueryString(history, filters);
  }

  const onSetFilter = useCallback(
    (newFilter) => {
      setFilter(newFilter);
      setQueryString(history, filtersReducer(filters, newFilter));
    },
    [filters]
  );

  return [filters, onSetFilter];
};

export default useQueryStringFilters;
