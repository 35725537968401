import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  addCompanyRoleNotification,
  removeCompanyRoleNotification,
  updateCompanyRoleNotification,
} from "@ROM/CompanyRoles/actions";
import { selectNotificationsForCompanyRole } from "@ROM/CompanyRoles/selectors";
import ItemsGroupsList from "@ROM/CompanyAdmin/Company/Roles/ItemsGroupsList";

const NotificationsList = ({ notifications, activeRoleId, className }) => {
  const dispatch = useDispatch();
  const selectedNotifications = useSelector(selectNotificationsForCompanyRole(activeRoleId));

  const addNotification = async (notification) => {
    await dispatch(addCompanyRoleNotification(activeRoleId, { notificationId: notification.id }));
  };

  const removeNotification = async (notification) => {
    await dispatch(removeCompanyRoleNotification(activeRoleId, { notificationId: notification.id }));
  };

  const updateNotificationScope = async (notification, scope) => {
    await dispatch(updateCompanyRoleNotification(activeRoleId, { notificationId: notification.id, scope }));
  };

  return (
    <ItemsGroupsList
      className={className}
      itemsName="Notifications"
      items={notifications}
      selectedItems={selectedNotifications}
      add={addNotification}
      remove={removeNotification}
      updateScope={updateNotificationScope}
    />
  );
};

NotificationsList.defaultProps = {
  className: "",
};

NotificationsList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.arrayOf(PropTypes.shape).isRequired,
  activeRoleId: PropTypes.string.isRequired,
};

export default NotificationsList;
