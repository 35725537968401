import React from "react";
import PropTypes from "prop-types";

import AdminTable from "@ROM-components/common/AdminTable";
import { orderPriceLists } from "@ROM/PriceListItems/utils";
import PriceListItemRow from "./Row";

const List = ({ priceListItems, priceLists }) => {
  const orderedPriceListItems = orderPriceLists(priceListItems, priceLists);
  const header = (
    <tr>
      <th>Name</th>
      <th>Status</th>
      <th>From Date</th>
      <th>End Date</th>
      <th>Price</th>
    </tr>
  );
  return (
    <div>
      <AdminTable header={header}>
        {orderedPriceListItems.map((item) => (
          <PriceListItemRow priceListItem={item} priceLists={priceLists} key={item.id} />
        ))}
      </AdminTable>
    </div>
  );
};

List.propTypes = {
  priceListItems: PropTypes.arrayOf().isRequired,
  priceLists: PropTypes.arrayOf().isRequired,
};

export default List;
