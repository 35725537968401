import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "@ROM/App";
import store from "@ROM/App/store";

function ready(fxn) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
  if (typeof fxn !== "function") {
    return;
  }
  if (document.readyState === "complete") {
    return fxn();
  }
  document.addEventListener("DOMContentLoaded", fxn, false);
}

ready(() => {
  const container = document.getElementById("AppEntryPoint");

  if (container) {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      container
    );
  }
});
