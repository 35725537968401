import React from "react";
import PropTypes from "prop-types";
import productPlaceholder from "@ROM-images/productPlaceholder";
import linkPlaceholder from "@ROM-images/linkPlaceholder";
import pdfPlaceholder from "@ROM-images/pdfPlaceholder";
import {
  selectCurrentProductAssets,
  selectCurrentProductLinks,
  selectCurrentProductPdfs,
  selectCurrentProductImages,
} from "@ROM/Products/selectors";
import { connect } from "react-redux";
import styled from "styled-components";

const GALLERY = "gallery";

const Gallery = ({ product, assets, links, pdfs, images }) => {
  const primaryImage = product.attributes.primaryImage;

  return (
    <React.Fragment>
      {primaryImage ? (
        <a
          key={primaryImage.id}
          href={primaryImage.id}
          data-src={primaryImage.file.large.url}
          data-fancybox={`${GALLERY}-${product.id}`}
          data-caption={product.attributes.name}
        >
          <StyledImg src={primaryImage.file.thumb.url} width="200px" alt={product.attributes.name} />
        </a>
      ) : (
        <img src={productPlaceholder} width="200px" alt={product.attributes.name} />
      )}
      {assets.length > 0 && (
        <div className="d-flex mt-1 justify-content-center">
          {images
            .filter((image) => image.id.toString() !== primaryImage.id.toString())
            .map((asset) => (
              <a
                key={asset.id}
                href={asset.id}
                data-src={asset.attributes.file.large.url}
                data-fancybox={`${GALLERY}-${product.id}`}
                data-caption={product.attributes.name}
                className="mt-1"
              >
                <StyledImg
                  src={asset.attributes.file.icon.url}
                  height="50px"
                  alt={product.attributes.name}
                  className="me-1 border"
                />
              </a>
            ))}
          {pdfs
            .filter((pdf) => pdf.id.toString() !== primaryImage.id.toString())
            .map((pdf) => (
              <a
                key={pdf.id}
                href={pdf.id}
                data-type="iframe"
                data-src={pdf.attributes.file.url}
                data-fancybox={`${GALLERY}-${product.id}`}
                data-caption={product.attributes.name}
                className="mt-1"
              >
                <StyledImg src={pdfPlaceholder} height="50px" alt={product.attributes.name} className="me-1 border p-3" />
              </a>
            ))}
          {links.map((link) => (
            <a
              key={link.id}
              href={link.attributes.url}
              data-fancybox={`${GALLERY}-${product.id}`}
              data-caption={product.attributes.name}
              className="mt-1"
            >
              <StyledImg src={linkPlaceholder} height="50px" alt={product.attributes.name} className="me-1 border p-3" />
            </a>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

Gallery.propTypes = {
  assets: PropTypes.array.isRequired,
  product: PropTypes.object,
  links: PropTypes.array,
  pdfs: PropTypes.array,
  images: PropTypes.array,
};

const StyledImg = styled.img`
  cursor: pointer;
`;

const stateToProps = (state, props) => {
  return {
    assets: selectCurrentProductAssets(state, props.product.id),
    links: selectCurrentProductLinks(state, props.product.id),
    pdfs: selectCurrentProductPdfs(state, props.product.id),
    images: selectCurrentProductImages(state, props.product.id),
  };
};

export default connect(stateToProps)(Gallery);
