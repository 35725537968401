import { apiAction, standardAction } from "@ROM-utils/actions";
import humps from "humps";
import OrdersApi from "./api";

/* CRUD Actions */

export const LIST = "orders/api/LIST";
export const CREATE = "orders/api/CREATE";
export const UPDATE = "orders/api/UPDATE";
export const DESTROY = "orders/api/DESTROY";
export const FIND = "orders/api/FIND";

/*
  @param [Object]           - options
  @param [Number|String]    - options.companyId (required)
  @oaram [Number]           - options.page
*/

export function list(options = {}) {
  return apiAction(LIST, OrdersApi.list(humps.decamelizeKeys(options)));
}

export function find(id) {
  return apiAction(FIND, OrdersApi.find(id));
}

export function create(attributes = {}) {
  return apiAction(CREATE, OrdersApi.create(attributes));
}

export function update(id, attributes = {}) {
  return apiAction(UPDATE, OrdersApi.update(id, attributes));
}

export function destroy(id) {
  return apiAction(DESTROY, OrdersApi.destroy(id));
}

export const SET_SORT = "orders/SET_SORT";
export const SET_PAGE = "orders/SET_PAGE";

export function setSort(field, direction = "desc") {
  return standardAction(SET_SORT, { field, direction });
}

export function setPage(page) {
  return standardAction(SET_PAGE, { page });
}

export function getFilteredOrders(filters) {
  return list(filters);
}

function validateOrder(attributes = {}) {
  const errors = {};
  if (!attributes.billingAddressId) {
    errors.billingAddressId = "Billing Address cannot be blank";
  }
  if (!attributes.shippingAddressId) {
    errors.shippingAddressId = "Shipping Address cannot be blank";
  }
}

export function submitOrder(orderId, attributes = {}) {
  validateOrder(attributes);
  return update(orderId, attributes);
}
