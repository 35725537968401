import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";

import useIsMobile from "@ROM-components/utils/Responsive";
import NumberFormater from "@ROM-ui/NumberFormater";
import productPlaceholder from "@ROM-images/productPlaceholder";
import IconImage from "@ROM-ui/IconImage";
import IconPointer from "@ROM-ui/IconPointer";
import QuantityInput from "@ROM/Cart/OrderItems/components/QuantityInput";
import Spinner from "@ROM-ui/Spinner";

const DesktopProductRow = ({ product, item, handleClick, onOrderItemDelete, deleteLoading }) => {
  return product ? (
    <StyledRow item={item} className="mx-0 my-3 p-1 align-items-center rounded" onClick={handleClick}>
      <Col xs={6} md={1}>
        {product.attributes.primaryImage ? (
          <img src={product.attributes.primaryImage.file.icon.url} alt={product.attributes.name} />
        ) : (
          <IconImage src={productPlaceholder} />
        )}
      </Col>
      <Col xs={6} md={4}>
        <strong>{product.attributes.name}</strong>
      </Col>
      <Col xs={6} md={2} className="p-0">
        <QuantityInput productId={product.id} packagesPerPallet={product.attributes.packagesPerPallet} />
      </Col>
      <Col xs={6} md={2}>
        <strong>
          <NumberFormater value={product.attributes.price} unit={product.attributes.unit} />
        </strong>
      </Col>
      <Col xs={6} md={2}>
        <strong>
          <NumberFormater value={product.attributes.packagePrice} unit={product.attributes.packageUnit} />
        </strong>
      </Col>
      <Col xs={6} md={1}>
        {item &&
          (deleteLoading ? (
            <Spinner animation="border" variant="secondary" size="sm" />
          ) : (
            <IconPointer className="fas fa-trash text-danger" onClick={(e) => onOrderItemDelete(e, item)} />
          ))}
      </Col>
    </StyledRow>
  ) : (
    <Row className="mx-0 my-3 py-3">
      <Col xs={6} md={5}>
        <strong>Product</strong>
      </Col>
      <Col xs={6} md={2}>
        <strong>Quantity</strong>
      </Col>
      <Col xs={6} md={2}>
        <strong>Unit Price</strong>
      </Col>
      <Col xs={6} md={2}>
        <strong>Package Price</strong>
      </Col>
    </Row>
  );
};

const MobileProductRow = ({ product, selected, item, handleClick, onOrderItemDelete, deleteLoading }) => {
  return product ? (
    <>
      <HighlightedTop highlighted={item} onClick={handleClick} className="rounded-top bg-light text-dark mx-2 mt-2 py-2">
        <div className="d-flex align-items-center">
          {product.attributes.primaryImage && (
            <img src={product.attributes.primaryImage.file.icon.url} alt={product.attributes.name} className="me-2" />
          )}
          <strong>{product.attributes.name}</strong>
        </div>
        {item && deleteLoading ? (
          <Spinner animation="border" variant="secondary" size="sm" />
        ) : (
          <IconPointer className="fas fa-trash text-danger" onClick={(e) => onOrderItemDelete(e, item)} />
        )}
      </HighlightedTop>
      <HighlightedBottom highlighted={item} onClick={handleClick} className="rounded-bottom bg-custom-green text-white mx-2 mb-2">
        <Col xs={8}>
          <Row className="mt-3">
            <Col xs={4} className="fw-bold small">
              Unit
            </Col>
            <Col xs={8} className="text-end">
              <NumberFormater className="me-2 fw-bold" value={product.attributes.price} unit={product.attributes.unit} />
            </Col>
          </Row>
          <Row>
            <Col xs={4} className="fw-bold small">
              Package
            </Col>
            <Col xs={8} className="text-end">
              <NumberFormater
                className="me-2 fw-bold"
                value={product.attributes.packagePrice}
                unit={product.attributes.packageUnit}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={4} md={2} className="p-0">
          <QuantityInput
            isMobile
            productId={product.id}
            packagesPerPallet={product.attributes.packagesPerPallet}
            className="mx-2"
          />
        </Col>
        {selected && (
          <>
            <Col xs={7} className="fw-bold small">
              Units per Package
            </Col>
            <Col xs={5} className="text-end fw-bold">
              {product.attributes.unitsPerPackage}
            </Col>
            <Col xs={7} className="fw-bold small">
              Packages per pallet
            </Col>
            <Col xs={5} className="text-end fw-bold">
              {product.attributes.packagesPerPallet}
            </Col>
            <Col xs={12}>
              <p className="mt-3">{product.attributes.description}</p>
            </Col>
          </>
        )}
      </HighlightedBottom>
    </>
  ) : null;
};

const ProductPropType = PropTypes.shape({
  id: PropTypes.number,
  attributes: PropTypes.shape({
    name: PropTypes.string,
    primaryImage: PropTypes.shape({
      file: PropTypes.shape({
        icon: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    price: PropTypes.number,
    unit: PropTypes.number,
    packagePrice: PropTypes.number,
    packageUnit: PropTypes.number,
    packagesPerPallet: PropTypes.number,
    unitsPerPackage: PropTypes.number,
    description: PropTypes.string,
  }),
});

MobileProductRow.propTypes = {
  product: ProductPropType,
  item: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  deleteLoading: PropTypes.bool.isRequired,
  onOrderItemDelete: PropTypes.func.isRequired,
};

DesktopProductRow.propTypes = {
  product: ProductPropType,
  item: PropTypes.object,
  handleClick: PropTypes.func.isRequired,
  deleteLoading: PropTypes.bool.isRequired,
  onOrderItemDelete: PropTypes.func.isRequired,
};

MobileProductRow.defaultProps = {
  product: null,
  item: null,
};

DesktopProductRow.defaultProps = {
  product: null,
  item: null,
};

const ProductRow = ({ product, productItemsInCurrentOrder, selected, handleClick, handleRemoveItem }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);

  const isMobile = useIsMobile();
  const currentItem = productItemsInCurrentOrder?.find((item) => item.attributes.productId.toString() === product?.id);

  const onOrderItemDelete = (e, item) => {
    e.stopPropagation();
    setDeleteLoading(true);
    handleRemoveItem(item);
  };

  return isMobile ? (
    <MobileProductRow
      selected={selected}
      product={product}
      item={currentItem}
      handleClick={handleClick}
      onOrderItemDelete={onOrderItemDelete}
      deleteLoading={deleteLoading}
    />
  ) : (
    <DesktopProductRow
      selected={selected}
      product={product}
      item={currentItem}
      handleClick={handleClick}
      onOrderItemDelete={onOrderItemDelete}
      deleteLoading={deleteLoading}
    />
  );
};

ProductRow.propTypes = {
  product: PropTypes.shape({ id: PropTypes.number.isRequired }),
  handleClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  productItemsInCurrentOrder: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
};

ProductRow.defaultProps = {
  product: null,
};

export default ProductRow;

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledRow = styled(({ item, ...props }) => <Row {...props} />)`
  background-color: ${(props) => (props.item ? "#CFEDC6" : "#F5F5F5")};
  cursor: pointer;
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const HighlightedTop = styled.div`
  border-top: ${(props) => (props.highlighted ? "4px #9DCB75 solid" : "")};
  border-left: ${(props) => (props.highlighted ? "4px #9DCB75 solid" : "")};
  border-right: ${(props) => (props.highlighted ? "4px #9DCB75 solid" : "")};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.875rem;
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const HighlightedBottom = styled(({ highlighted, ...props }) => <Row {...props} />)`
  border-bottom: ${(props) => (props.highlighted ? "4px #9DCB75 solid" : "")};
  border-left: ${(props) => (props.highlighted ? "4px #9DCB75 solid" : "")};
  border-right: ${(props) => (props.highlighted ? "4px #9DCB75 solid" : "")};
  cursor: pointer;
`;
