import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledButton = styled.button`
  margin: 0.2rem;
  border-radius: 0.6rem;
  font-weight: 500;
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => `2px solid ${props.borderColor}`};
  padding: 0.2rem 0.8rem;
  color: ${(props) => props.color};
`;

const Button = ({ children, disabled, selected, onClick, color, borderColor, backgroundColor }) => {
  let defaultColor = "#8a8a8a";
  if (!disabled) {
    defaultColor = selected ? "white" : "#4a4238";
  }
  const defaultBackgroundColor = selected ? "rgba(25,60,100,0.5)" : "#e0e0e0";
  const defaultBorderColor = selected ? "#3a8de2" : "#e0e0e0";

  return (
    <StyledButton
      type="button"
      disabled={disabled}
      color={color || defaultColor}
      borderColor={borderColor || defaultBorderColor}
      backgroundColor={backgroundColor || defaultBackgroundColor}
      onClick={onClick}
      $selected={selected}
    >
      {children}
    </StyledButton>
  );
};

Button.defaultProps = {
  color: null,
  backgroundColor: null,
  borderColor: null,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
};

export default Button;
