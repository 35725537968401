import React, { useState, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { customerAdminCreateCustomerUrl, customerAdminManageCustomerUrl } from "@ROM-utils/urlHelpers";
import { canCreateCustomers } from "@ROM/Customers/permissions";
import { reducer } from "@ROM/App/utils/forms";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectAllCustomers, selectCustomersPagination } from "@ROM/Customers/selectors";
import { getFilteredCustomers, setPage } from "@ROM/Customers/actions";
import Button from "@ROM-ui/Button";
import Section from "@ROM-components/common/Section";
import CustomersLists from "@ROM/Customers/components/List";
import Filters from "@ROM/Customers/components/List/Filters";
import Pagination from "@ROM-components/common/Pagination";
import { customersBreadcrumbs } from "@ROM/CustomerAdmin/utils";

const initialFilters = {
  name: null,
  invoiceNumberPrefix: null,
  isActive: true,
  customerAdminSection: true,
};

const Page = () => {
  const currentUser = useSelector(selectCurrentUser);
  const customers = useSelector(selectAllCustomers);
  const pagination = useSelector(selectCustomersPagination);

  const dispatch = useDispatch();
  const updateCustomers = (filters) => dispatch(getFilteredCustomers(filters));

  const history = useHistory();

  const [filters, setFilter] = useReducer(reducer, initialFilters);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const timeOutId = setTimeout(() => updateCustomers(filters).then(setLoading(false)), 500);
    return () => clearTimeout(timeOutId);
  }, [filters]);

  const handleFilterChange = (name, value) => {
    setFilter({ [name]: value });
  };

  const handleClearFilters = () => {
    setLoading(true);
    setFilter(initialFilters);
    updateCustomers(initialFilters).then(setLoading(false));
  };

  const handlePageClick = (page) => {
    setLoading(true);
    setPage(page);
    updateCustomers(filters, page).then(setLoading(false));
  };

  const onEditClick = (id) => {
    history.push(customerAdminManageCustomerUrl(id));
  };

  const handleColumnSort = (sortField) => {
    handleFilterChange("sort", `${filters.sort === sortField ? `-${sortField}` : sortField}`);
  };

  const createAction = canCreateCustomers(currentUser) ? (
    <Button
      id="customer-admin-create-customer-btn"
      onClick={() => {
        history.push(customerAdminCreateCustomerUrl());
      }}
    >
      Create Customer
    </Button>
  ) : null;

  return (
    <Section title="Customers" breadcrumbs={customersBreadcrumbs()} actions={canCreateCustomers(currentUser) && createAction}>
      <Filters handleFilterChange={handleFilterChange} handleClearFilters={handleClearFilters} filters={filters} />
      <div className="pt-2">
        <CustomersLists
          customers={customers}
          loading={loading}
          handleColumnSort={handleColumnSort}
          filters={filters}
          showPriceList={false}
          showCreate={false}
          onCustomerEdit={onEditClick}
        />
      </div>
      <Pagination pagination={pagination} handlePageClick={handlePageClick} />
    </Section>
  );
};

export default Page;
