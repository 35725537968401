import { useMediaQuery } from "react-responsive";

const useIsMobile = () => useMediaQuery({ query: "(max-width: 768px)" });

export const useNavBarHeight = () => {
  const height = document.getElementById("desktop-navbar")?.clientHeight ?? 0;
  return height;
};

export default useIsMobile;
