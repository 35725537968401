/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ModalUi from "@ROM-ui/Modal";
import Button from "@ROM-ui/Button";
import Spinner from "@ROM-ui/Spinner";

const ModalSecondary = ({
  title,
  confirmText,
  isShowing,
  onConfirm,
  onCancel,
  showConfirmButton,
  showCloseIcon,
  children,
  bodyClass,
  ...restProps
}) => {
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = async () => {
    setLoading(true);

    await onConfirm();

    setLoading(false);
  };

  return (
    <ModalUi className="text-secondary rounded" isOpen={isShowing} onRequestClose={handleCancel} {...restProps}>
      <div onClick={(e) => e.stopPropagation()}>
        {(title || showCloseIcon) && (
          <Header>
            {title && <h1 className="title">{title}</h1>}
            {showCloseIcon && <CloseIcon id="modal-cancel-icon" className="fas fa-times" onClick={handleCancel} />}
          </Header>
        )}

        <Body className={bodyClass}>{children}</Body>

        {showConfirmButton && (
          <Footer>
            <ConfirmButton onClick={handleConfirm} id="modal-confirm-btn">
              {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : confirmText}
            </ConfirmButton>
          </Footer>
        )}
      </div>
    </ModalUi>
  );
};

const Header = styled.div`
  position: relative;
  padding: 1.5rem 1.25rem;
  background-color: #036db2;
  color: white;

  .title {
    font-size: 1.25rem;
    line-height: 1;
    margin: 0;
  }
`;

const CloseIcon = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 9999px;
  background-color: white;
  color: #036db2;
  width: 1.75rem;
  height: 1.75rem;
`;

const Body = styled.div`
  padding: 1.25rem;
`;

const ConfirmButton = styled(Button)`
  background-color: #036db2;
  border: none;
  border-radius: 15px;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.75rem 0.5rem;

  &:hover,
  &:active {
    background-color: #035e99 !important;
  }
`;

const Footer = styled.div`
  padding: 1.5rem 1.25rem;
  background-color: #2c2c2c;
`;

ModalSecondary.defaultProps = {
  title: null,
  showConfirmButton: true,
  confirmText: "Confirm",
  isShowing: false,
  showCloseIcon: true,
  bodyClass: "",
  onConfirm: () => null,
  onCancel: () => null,
};

ModalSecondary.propTypes = {
  title: PropTypes.string,
  showConfirmButton: PropTypes.bool,
  confirmText: PropTypes.string,
  isShowing: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  bodyClass: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default ModalSecondary;
