import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Badge from "@ROM-ui/Badge";
import Col from "@ROM-ui/Col";
import productPlaceholder from "@ROM-images/productPlaceholder";
import IconImage from "@ROM-ui/IconImage";
import styled from "styled-components";
import NumberFormater from "@ROM-ui/NumberFormater";

const PriceListItemsRow = ({ item, index, itemPrice, showPricesEdits, onPriceChanged }) => {
  moment.locale("en-us");

  return (
    <tr index={index}>
      <td>
        {item.attributes.primaryImage ? (
          <img alt={item.attributes.name} src={item.attributes.primaryImage.file.icon.url} />
        ) : (
          <IconImage src={productPlaceholder} />
        )}
      </td>
      <td className="align-middle">{item.attributes.name}</td>
      <td className="align-middle">
        {item.attributes.isActive ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
      </td>
      <td className="align-middle">
        <div className="d-flex align-items-center">
          {item.attributes.unitsPerPackage && (
            <>
              <StyledQuantity>{item.attributes.unitsPerPackage}</StyledQuantity>
              <StyledIcon className="fa fa-box" />
              <Description firstParagraph={"units per"} secondParagraph={"package"} />
            </>
          )}
          {item.attributes.packagesPerPallet && (
            <>
              <StyledQuantity>{item.attributes.packagesPerPallet}</StyledQuantity>
              <StyledIcon className="fa fa-pallet" />
              <Description firstParagraph={"packages per"} secondParagraph={"pallet"} />
            </>
          )}
        </div>
      </td>
      <StyledTd className="text-end align-middle">
        <PriceEditor editable={showPricesEdits} value={itemPrice} onValueChange={onPriceChanged} />
      </StyledTd>
    </tr>
  );
};

const Description = ({ firstParagraph, secondParagraph }) => (
  <div className="d-flex flex-column mx-2">
    <StyledDescription>{firstParagraph}</StyledDescription>
    <StyledDescription>{secondParagraph}</StyledDescription>
  </div>
);

const PriceEditor = ({ editable, value, onValueChange }) => {
  if (!editable) {
    return <StyledPrice value={value} />;
  }
  const valid = value !== undefined && value > 0.0;
  const classes = valid ? "w-100 priceinput" : "w-100 priceinput-invalid";
  return (
    <StyledPriceInput
      displayType="input"
      className={classes}
      value={value}
      onValueChange={({ floatValue }) => onValueChange(floatValue)}
    />
  );
};

const StyledQuantity = styled.p`
  font-size: x-large;
  font-weight: 300;
  margin: 0;
  margin-right: 0.5rem;
  width: 40px;
  text-align: right;
`;

const StyledDescription = styled.p`
  margin: 0;
  font-size: x-small;
  font-weight: 400;
  text-transform: uppercase;
`;

const StyledIcon = styled.i`
  font-size: x-large;
`;

const StyledPrice = styled(NumberFormater)`
  font-weight: 400;
  font-size: large;
`;

const StyledPriceInput = styled(NumberFormater)`
  padding: 2px 5px;
  text-align: right;
  border-radius: 2px;
`;

const StyledTd = styled.td`
  min-width: 10rem;
`;

PriceListItemsRow.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  filters: PropTypes.object,
  handleFilterChange: PropTypes.func,
};

Description.propTypes = {
  firstParagraph: PropTypes.string,
  secondParagraph: PropTypes.string,
};

export default PriceListItemsRow;
