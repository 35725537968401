import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Button from "@ROM-ui/Button";
import { inProgress } from "@ROM/DeliveryStatuses/actions";

const InProgress = ({ delivery, setError }) => {
  const dispatch = useDispatch();
  const handleInProgress = (e) => {
    e.stopPropagation();
    dispatch(inProgress({ deliveryId: delivery.id })).then((response) => {
      if (response.payload.status !== 201) {
        setError(true);
      }
    });
  };

  return (
    <>
      <Button variant="outline-success" className="ms-2 px-2" size="sm" onClick={handleInProgress}>
        Mark as In Progress
      </Button>
    </>
  );
};

InProgress.propTypes = {
  delivery: PropTypes.shape({ id: PropTypes.number }).isRequired,
  setError: PropTypes.func.isRequired,
};

export default InProgress;
