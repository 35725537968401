import React from "react";
import PropTypes from "prop-types";

import LoadingDots from "@ROM-ui/LoadingDots";
import AdminTable from "@ROM-components/common/AdminTable";
import CustomerHeader from "./CustomerHeader";
import CustomerRow from "./CustomerRow";

const CustomerTable = ({ customers, priceList, filters, handleColumnSort, showPriceList, loading, onCustomerEdit }) => {
  return loading ? (
    <LoadingDots />
  ) : (
    <>
      {customers.length === 0 ? (
        <div className="text-center py-4">No customers found for the selected filters.</div>
      ) : (
        <AdminTable
          header={<CustomerHeader filters={filters} handleColumnSort={handleColumnSort} showPriceList={showPriceList} />}
        >
          {customers.map((customer) => (
            <CustomerRow
              onCustomerEdit={onCustomerEdit}
              key={customer.id}
              customer={customer}
              priceList={priceList ? priceList(customer) : null}
              showPriceList={showPriceList}
            />
          ))}
        </AdminTable>
      )}
    </>
  );
};

CustomerTable.defaultProps = {
  showPriceList: true,
  priceList: null,
};

CustomerTable.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  priceList: PropTypes.func,
  handleColumnSort: PropTypes.func.isRequired,
  showPriceList: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  filters: PropTypes.shape().isRequired,
  onCustomerEdit: PropTypes.func.isRequired,
};

export default CustomerTable;
