import React from "react";
import PropTypes from "prop-types";
import StyledContainer from "@ROM-ui/StyledContainer";
import DesktopNavbar from "./Navbars/DesktopNavbar";
import MobileNavbar from "./Navbars/MobileNavbar";

const Authenticated = ({ children, fullWidth, expanded, dontPadMobile }) => (
  <>
    <DesktopNavbar fullWidth={fullWidth} />
    <MobileNavbar />
    <StyledContainer
      as="main"
      role="main"
      className="dashboard"
      dontPadMobile={dontPadMobile}
      fullWidth={fullWidth}
      expanded={expanded}
    >
      {children}
    </StyledContainer>
  </>
);

Authenticated.defaultProps = {
  expanded: false,
  fullWidth: false,
  dontPadMobile: false,
};

Authenticated.propTypes = {
  expanded: PropTypes.bool,
  fullWidth: PropTypes.bool,
  dontPadMobile: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Authenticated;
