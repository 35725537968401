import update from "immutability-helper";
import { handle } from "redux-pack";
import { CART, UPDATE_CART, UPDATE_CART_ITEMS_TRIAL, DELETE_ASSET } from "@ROM/Cart/actions";

import { CREATE_ORDER_ITEM, UPDATE_ORDER_ITEM, DESTROY_ORDER_ITEM, RE_SORT_ORDER_ITEMS } from "@ROM/Cart/OrderItems/actions";

const initialState = {
  orderId: null,
  order: {},
  relationships: [],
  loaded: false,
  error: false,
  errorMessage: [],
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {}; // redux-pack puts API response in its own 'data' attribute, renaming to 'json'

  switch (type) {
    case CART:
      return handle(state, action, {
        success: (prevState) => {
          return update(prevState, {
            orderId: { $set: json.data.id },
            order: { $set: json.data },
            relationships: { $set: json.included },
            loaded: { $set: true },
            error: { $set: false },
            errorMessage: { $set: [] },
          });
        },
        failure: (prevState) => {
          return update(prevState, {
            error: { $set: true },
            errorMessage: { $set: json },
          });
        },
      });

    case UPDATE_CART:
      return handle(state, action, {
        success: (prevState) => {
          json.data.attributes.updatedAt = Date.now();
          return update(prevState, {
            order: { $set: json.data },
            relationships: { $set: json.included },
            error: { $set: false },
            errorMessage: { $set: [] },
          });
        },
        failure: (prevState) => {
          return update(prevState, {
            error: { $set: true },
            errorMessage: { $set: json },
          });
        },
      });

    case UPDATE_CART_ITEMS_TRIAL:
      return handle(state, action, {
        success: (prevState) => {
          json.data.attributes.updatedAt = Date.now();
          return update(prevState, {
            order: { $set: json.data },
            relationships: { $set: json.included },
            error: { $set: false },
            errorMessage: { $set: [] },
          });
        },
        failure: (prevState) => {
          return update(prevState, {
            error: { $set: true },
            errorMessage: { $set: json },
          });
        },
      });

    case DELETE_ASSET:
      return handle(state, action, {
        success: (prevState) => {
          return update(prevState, {
            relationships: { $set: json.included },
            order: {
              attributes: {
                updatedAt: {
                  $set: Date.now(),
                },
              },
            },
            error: { $set: false },
            errorMessage: { $set: [] },
          });
        },
        failure: (prevState) => {
          return update(prevState, {
            error: { $set: true },
            errorMessage: { $set: json },
          });
        },
      });

    case CREATE_ORDER_ITEM:
      return handle(state, action, {
        success: (prevState) => {
          const attributes = json.data.attributes;
          return update(prevState, {
            relationships: { $push: [json.data] },
            order: {
              attributes: {
                subtotalCents: {
                  $set: attributes.totals.subtotalCents,
                },
                totalCents: {
                  $set: attributes.totals.totalCents,
                },
                taxCents: {
                  $set: attributes.totals.taxCents,
                },
                itemsWeight: {
                  $set: attributes.totals.itemsWeight,
                },
                updatedAt: {
                  $set: Date.now(),
                },
              },
            },
            orderItemError: { $set: false },
            orderItemErrorMessage: { $set: [] },
          });
        },
        failure: (prevState) => {
          return update(prevState, {
            orderItemError: { $set: true },
            orderItemErrorMessage: { $set: json },
          });
        },
      });

    case UPDATE_ORDER_ITEM:
      return handle(state, action, {
        success: (prevState) => {
          const attributes = json.data.attributes;
          const itemId = json.data.id;
          const index = prevState.relationships.findIndex((item) => item.type === "orderItem" && item.id === itemId);
          return update(prevState, {
            relationships: { [index]: { attributes: { $set: attributes } } },
            order: {
              attributes: {
                subtotalCents: {
                  $set: attributes.totals.subtotalCents,
                },
                totalCents: {
                  $set: attributes.totals.totalCents,
                },
                taxCents: {
                  $set: attributes.totals.taxCents,
                },
                discountCents: {
                  $set: attributes.totals.discountCents,
                },
                itemsWeight: {
                  $set: attributes.totals.itemsWeight,
                },
                updatedAt: {
                  $set: Date.now(),
                },
              },
            },
            error: { $set: false },
            errorMessage: { $set: [] },
          });
        },
        failure: (prevState) => {
          return update(prevState, {
            error: { $set: true },
            errorMessage: { $set: json },
          });
        },
      });

    case DESTROY_ORDER_ITEM:
      return handle(state, action, {
        success: (prevState) => {
          const attributes = json.data.attributes;
          const itemId = action.meta.itemId;
          const index = prevState.relationships.findIndex((item) => item.type === "orderItem" && item.id === itemId);

          return update(prevState, {
            relationships: { $splice: [[index, 1]] },
            order: {
              attributes: {
                subtotalCents: {
                  $set: attributes.totals.subtotalCents,
                },
                totalCents: {
                  $set: attributes.totals.totalCents,
                },
                taxCents: {
                  $set: attributes.totals.taxCents,
                },
                itemsWeight: {
                  $set: attributes.totals.itemsWeight,
                },
                updatedAt: {
                  $set: Date.now(),
                },
              },
            },
            error: { $set: false },
            errorMessage: { $set: [] },
          });
        },
        failure: (prevState) => {
          return update(prevState, {
            error: { $set: true },
            errorMessage: { $set: json },
          });
        },
      });

    case RE_SORT_ORDER_ITEMS:
      return handle(state, action, {
        success: (prevState) => {
          let relationships = prevState.relationships.filter((item) => item.type !== "orderItem");
          relationships = [...relationships, ...json.data];

          return update(prevState, {
            relationships: { $set: relationships },
          });
        },
        failure: (prevState) => {
          return update(prevState, {
            error: { $set: true },
            errorMessage: { $set: json },
          });
        },
      });

    default:
      return state;
  }
}

export default reducer;
