import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import Pagination from "@ROM-components/common/Pagination";
import { reducer } from "@ROM/App/utils/forms";
import AdminTable from "@ROM-components/common/AdminTable";
import PriceListItemsTableHeader from "./PriceListItemsTableHeader";
import PriceListItemsRow from "./PriceListItemsRow";

const PriceListItemsTable = ({
  priceListId,
  pagination,
  priceListItems,
  setPage,
  getFilteredPriceListItems,
  prices,
  showPricesEdits,
  onPriceChanged,
}) => {
  const initialFilters = {
    sort: "name",
  };

  const [filters, setFilter] = useReducer(reducer, initialFilters);

  useEffect(() => {
    const timeOutId = setTimeout(() => getFilteredPriceListItems(priceListId, filters), 500);
    return () => clearTimeout(timeOutId);
  }, [filters]);

  const handleFilterChange = (name, value) => {
    setFilter({ [name]: value });
  };

  const handlePageClick = (page) => {
    setPage(page);
    getFilteredPriceListItems(priceListId, filters, { page });
  };

  const onItemPriceChanged = (itemId) => (value) => onPriceChanged(itemId, value);

  return (
    <>
      <AdminTable header={<PriceListItemsTableHeader filters={filters} handleFilterChange={handleFilterChange} />}>
        {priceListItems.map((item, index) => (
          <PriceListItemsRow
            key={item.id}
            item={item}
            index={index}
            itemPrice={prices[item.id]}
            showPricesEdits={showPricesEdits}
            onPriceChanged={onItemPriceChanged(item.id)}
          />
        ))}
      </AdminTable>
      <Pagination className="mt-4 px-2" pagination={pagination} handlePageClick={handlePageClick} />
    </>
  );
};

PriceListItemsTable.propTypes = {
  priceListId: PropTypes.string.isRequired,
  pagination: PropTypes.shape().isRequired,
  priceListItems: PropTypes.arrayOf().isRequired,
  setPage: PropTypes.func.isRequired,
  getFilteredPriceListItems: PropTypes.func.isRequired,
  showPricesEdits: PropTypes.bool,
  prices: PropTypes.shape().isRequired,
  onPriceChanged: PropTypes.func.isRequired,
};

PriceListItemsTable.defaultProps = {
  showPricesEdits: false,
};

export default PriceListItemsTable;
