import React from "react";
import PropTypes from "prop-types";

const FormattedAmount = ({ children, colored }) => {
  const colorStyle = children < 0 ? "text-danger" : "text-success";
  const numberText = (+children).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 2 });

  return <span className={`${colored ? colorStyle : ""} px-2`}>{numberText}</span>;
};

FormattedAmount.propTypes = {
  children: PropTypes.number.isRequired,
  colored: PropTypes.bool.isRequired,
};

export default FormattedAmount;
