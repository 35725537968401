import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "@ROM-ui/Button";
import Col from "@ROM-ui/Col";
import NumberFormater from "@ROM-ui/NumberFormater";
import Spinner from "@ROM-ui/Spinner";
import { estimateOrderPallets } from "@ROM/Orders/utils";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { prefix, suffix } from "../../utils";

const AmountDetail = ({ id, title, type, amount, text, isMobile, onClick }) => {
  const content = text || <NumberFormater value={amount} prefix={prefix(type)} suffix={suffix(type)} />;
  const field = onClick ? (
    <Button type="button" className="p-0 text-white" variant="link" onClick={onClick}>
      {content}
    </Button>
  ) : (
    content
  );

  return (
    <div
      id={id}
      className={`d-flex ${isMobile ? "flex-row justify-content-between w-100" : "flex-column justify-content-end me-3"}`}
    >
      <div className="fw-light small text-end">{title}</div>
      <div className="text-end">{field}</div>
    </div>
  );
};

AmountDetail.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  amount: PropTypes.number,
  text: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

AmountDetail.defaultProps = {
  id: null,
  type: "dollar",
  amount: 0,
  text: null,
  onClick: null,
};

const Amount = ({ order, items, customer, company, isShow, loading, isMobile, onChangeDiscount, canSetDiscounts }) => {
  const { attributes } = order;
  const taxExempt = customer?.attributes?.taxExempt ?? false;

  const itemsDiscount = items.reduce((acc, item) => acc + item.attributes.discountCents, 0);
  const orderDiscount = attributes.discountCents;
  const freeShippingEnabled = company.attributes.freeShippingPallets > 0;
  const freeShippingText = estimateOrderPallets(items) >= company.attributes.freeShippingPallets ? "Free" : "Market Price";

  return (
    <Col className={isMobile ? "bg-custom-brown" : "bg-custom-green p-0"}>
      <div className={`d-flex ${isMobile ? "flex-column " : "flex-row"} align-items-center justify-content-end text-white`}>
        <AmountDetail id="order-subtotal-cents" title="Subtotal" amount={attributes.subtotalCents} isMobile={isMobile} />
        {!isShow && canSetDiscounts && (
          <AmountDetail
            id="order-discounts"
            title="Discounts"
            onClick={canSetDiscounts ? onChangeDiscount : null}
            text={attributes.discountAmount === 0 ? "Add" : null}
            type={attributes.discountType}
            amount={attributes.discountAmount}
            isMobile={isMobile}
          />
        )}
        {itemsDiscount !== 0 && (
          <AmountDetail
            id="order-items-discounts-cents"
            title="Items Discount"
            type="dollar"
            amount={itemsDiscount}
            isMobile={isMobile}
          />
        )}
        {orderDiscount !== 0 && (
          <AmountDetail
            id="order-discounts-cents"
            title="Order Discount"
            type="dollar"
            amount={orderDiscount}
            isMobile={isMobile}
          />
        )}

        {freeShippingEnabled && <AmountDetail title="Shipping Charge" text={freeShippingText} isMobile={isMobile} />}

        <AmountDetail title="Est. Taxes" amount={attributes.taxCents} text={taxExempt ? "Exempt" : null} isMobile={isMobile} />

        <AmountDetail title="Total Weight" text={`${attributes.itemsWeight} lbs.`} isMobile={isMobile} />

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>Total weight includes pallets and extra weights. So, it may differ from the order items sum</Tooltip>}
        >
          <StyledIcon className="fa fa-question-circle me-3" />
        </OverlayTrigger>

        <StyledTotalPrice
          id="order-total-cents"
          $isMobile={isMobile}
          className="justify-content-end my-0 py-3 px-md-3 align-self-stretch h4 d-flex align-items-center "
        >
          <NumberFormater value={attributes.totalCents} />
          {loading && <Spinner className="ms-2" as="span" size="sm" animation="border" />}
        </StyledTotalPrice>
      </div>
    </Col>
  );
};

const StyledTotalPrice = styled.div`
  background-color: ${(props) => (props.$isMobile ? "inherit" : "#5f8122")};
`;

const StyledIcon = styled.i`
  font-size: 24px;
`;

Amount.propTypes = {
  order: PropTypes.shape({
    attributes: PropTypes.shape({
      isTrial: PropTypes.bool,
      subtotalCents: PropTypes.number,
      taxCents: PropTypes.number,
      totalCents: PropTypes.number,
      discountAmount: PropTypes.number,
      discountCents: PropTypes.number,
      discountType: PropTypes.string,
      itemsWeight: PropTypes.number,
    }),
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        totalPriceCents: PropTypes.number,
        discountType: PropTypes.string,
        discountAmount: PropTypes.number,
      }),
    })
  ).isRequired,
  customer: PropTypes.shape({
    attributes: PropTypes.shape({
      taxExempt: PropTypes.bool,
    }),
  }).isRequired,
  company: PropTypes.shape({
    attributes: PropTypes.shape({
      freeShippingPallets: PropTypes.number,
    }),
  }).isRequired,
  isShow: PropTypes.bool.isRequired,
  loading: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired.isRequired,
  onChangeDiscount: PropTypes.func.isRequired,
  canSetDiscounts: PropTypes.bool.isRequired,
};

export default Amount;
