export const reducedByGroup = (items) =>
  items.reduce((accumulator, item) => {
    const {
      attributes: { group },
    } = item;

    accumulator[group] = [...(accumulator[group] || []), item];

    return accumulator;
  }, {});

export default { reducedByGroup };
