/* eslint-disable react/no-array-index-key */
import React from "react";
import styled from "styled-components";
import moment from "moment";
import PropTypes from "prop-types";

import Badge from "@ROM-ui/Badge";
import NumberFormater from "@ROM-ui/NumberFormater";
import Refund from "@ROM/Orders/components/Payments/Refund";

const FORMAT_DATETIME = "MM/DD/YYYY HH:mm:ss";

const List = ({ transactions, onChargeRefund, className, customer }) => {
  const handleChargeRefund = (paymentIntentId) => (amount) => onChargeRefund(paymentIntentId, amount);

  return (
    <div className={className}>
      {transactions.map((transaction) => (
        <Container key={transaction.id}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Badge className="me-3" bg="success">
                Charge
              </Badge>
              <NumberFormater value={transaction.attributes.amount} className="me-3" />
              <span className="transaction-date">{moment(transaction.attributes.created).format(FORMAT_DATETIME)}</span>
            </div>
            {!transaction.attributes.refunded && onChargeRefund && (
              <Refund size="sm" onRefund={handleChargeRefund(transaction.id)} transaction={transaction} customer={customer} />
            )}
          </div>
          <div>
            {transaction.attributes.refunds.map((refund, index) => (
              <div className="refund-container" key={index}>
                <Badge className="me-3 warning-badge" bg="warning">
                  Refund
                </Badge>
                <NumberFormater value={refund.amount} className="me-3" />
                <span className="transaction-date">{moment(refund.created).format(FORMAT_DATETIME)}</span>
              </div>
            ))}
          </div>
        </Container>
      ))}
    </div>
  );
};

const Container = styled.div`
  background-color: #e5e7eb;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 0.75rem;

  .transaction-date {
    font-size: 0.85rem;
    font-style: italic;
  }

  .refund-container {
    background-color: #d1d5db;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    margin-left: 2.5rem;
    display: flex;
    align-items: center;

    .warning-badge {
      font-size: 0.5rem;
    }
  }
`;

List.defaultProps = {
  className: "",
};

List.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.shape().isRequired,
  transactions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChargeRefund: PropTypes.func.isRequired,
};

export default List;
