import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection(deliveryId) {
    return `/deliveries/${deliveryId}/delivery_items`;
  },

  resource(deliveryId, id) {
    return `${urls.collection(deliveryId)}/${id}`;
  },
};

export default {
  create(attributes) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(attributes.deliveryId), attributes);
  },

  update(id, attributes) {
    const client = getApiClient(API_URL);
    return client.patch(urls.resource(attributes.deliveryId, id), attributes);
  },

  destroy(deliveryId, id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(deliveryId, id), { deliveryId: deliveryId });
  },
};
