import React from "react";
import PropTypes from "prop-types";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import styled from "styled-components";
import productPlaceholder from "@ROM-images/productPlaceholder";
import NumberFormater from "@ROM-ui/NumberFormater";
import QuantityInput from "@ROM/Cart/OrderItems/components/QuantityInput";
import Skeleton from "react-loading-skeleton";

const Grid = ({ products, setSelectedProductId, setDetailsTabActive, initialized }) => {
  const handleInfoClick = (ev, product) => {
    ev.preventDefault();
    setSelectedProductId(product.id);
    setDetailsTabActive(true);
  };

  let productPairs = [];
  for (let i = 0; i < products.length; i += 2) {
    productPairs.push(products.slice(i, i + 2));
  }

  const customStyles = {
    width: "60%",
    left: "70px",
    bottom: "-100px",
  };

  return (
    <SrollableDiv>
      {productPairs.map((pairs, index) => {
        return (
          <Row key={index} className="px-2">
            {pairs.map((product) => {
              return (
                <Col md={6} className="p-0" key={product.id}>
                  <StyledCard className="d-flex flex-column m-3" onClick={(ev) => handleInfoClick(ev, product)}>
                    {initialized ? (
                      <>
                        <StyledImageDiv>
                          <StyledImg
                            src={
                              product.attributes.primaryImage
                                ? product.attributes.primaryImage.file.thumb.url
                                : productPlaceholder
                            }
                          />
                        </StyledImageDiv>
                        <DivWithColor className="px-2">
                          <Name>{product.attributes.name}</Name>
                          <div className="d-flex justify-content-between">
                            <PriceTitle>Unit Price</PriceTitle>
                            <PriceTitle>Package Price</PriceTitle>
                          </div>
                          <div className="d-flex justify-content-between">
                            <Price value={product.attributes.price} />
                            <Price value={product.attributes.price * product.attributes.unitsPerPackage} />
                          </div>
                        </DivWithColor>
                        <Quantity className="px-2 pb-2">
                          <StyledQuantity>Quantity</StyledQuantity>
                          <QuantityInput
                            productId={product.id}
                            packagesPerPallet={product.attributes.packagesPerPallet}
                            popoverStyles={customStyles}
                          />
                        </Quantity>
                      </>
                    ) : (
                      <Skeleton height="310px" />
                    )}
                  </StyledCard>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </SrollableDiv>
  );
};

Grid.displayName = "Products/Selector/Grid";
Grid.propTypes = {
  products: PropTypes.array.isRequired,
  setSelectedProductId: PropTypes.func,
  setDetailsTabActive: PropTypes.func,
  initialized: PropTypes.bool,
};

const StyledImg = styled.img`
  width: auto;
  height: 85%;
  margin: 1rem;
`;

const StyledCard = styled.div`
  background-color: #3c363e;
  border-radius: 10px;
  height: 310px;
  cursor: pointer;
`;

const Name = styled.p`
  color: white;
  font-size: 15px;
  margin: 0;
`;

const PriceTitle = styled.p`
  color: white;
  font-size: 12px;
  margin: 0;
`;

const Price = styled(NumberFormater)`
  color: white;
  font-size: 14px;
  margin: 0;
`;

const DivWithColor = styled.div`
  background-color: ${(props) => (props.color ? color : "#2E282F")};
  height: 20%;
`;

const Quantity = styled.div`
  background-color: #ededed;
  display: flex;
  align-items: center;
  height: 20%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const StyledImageDiv = styled.div`
  height: 60%;
  align-self: center;
`;

const StyledQuantity = styled.p`
  margin: 0;
  font-size: 12px;
  padding-top: 0.5rem;
`;

const SrollableDiv = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 690px;
`;

export default Grid;
