import React, { useState } from "react";
import PointerSpan from "@ROM-ui/PointerSpan";
import { useSelector } from "react-redux";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import OrdersApi from "@ROM/Orders/api";
import Spinner from "@ROM-ui/Spinner";
import PropTypes from "prop-types";
import Popover from "@ROM-ui/Popover";
import Button from "@ROM-ui/Button";
import OverlayTrigger from "@ROM-ui/OverlayTrigger";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canAddOrderNotes } from "@ROM/OrderStatuses/permissions";
import { createPdf } from "../../utils";

const DOWNLOAD = "download";
const PRINT = "print";

const PdfActions = ({ orderId, setError }) => {
  const currentCompany = useSelector(selectCurrentCompany);
  const currentUser = useSelector(selectCurrentUser);

  const [printing, setPrinting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const showNotes = canAddOrderNotes(currentUser);

  const printOrder = (ev, includeNotes) => {
    const print = true;
    setPrinting(true);
    ev.stopPropagation();
    OrdersApi.find(orderId)
      .then((json) =>
        createPdf(json.data.data, json.data.included, currentCompany, print, includeNotes).then(() => setPrinting(false))
      )
      .catch(() => {
        setError(true);
        setPrinting(false);
      });
  };

  const downloadOrder = (ev, includeNotes = false) => {
    const print = false;
    setDownloading(true);
    ev.stopPropagation();
    OrdersApi.find(orderId)
      .then((json) =>
        createPdf(json.data.data, json.data.included, currentCompany, print, includeNotes).then(() => setDownloading(false))
      )
      .catch(() => {
        setError(true);
        setDownloading(false);
      });
  };

  const popover = (props) => {
    // eslint-disable-next-line react/prop-types
    const action = props?.popper?.state?.options?.action;

    const handleClick = (e, includeNotes) => {
      e.stopPropagation();
      if (action === DOWNLOAD) {
        downloadOrder(e, includeNotes);
      } else {
        printOrder(e, includeNotes);
      }
    };

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Popover className="text-secondary" style={{ zIndex: 10000 }} {...props}>
        <Popover.Header as="h5">Include notes?</Popover.Header>
        <Popover.Body className="p-1 d-flex justify-content-between">
          <Button onClick={(e) => handleClick(e, true)} variant="link" className="text-success">
            Yes
          </Button>
          <Button onClick={(e) => handleClick(e, false)} variant="link" className="text-danger">
            No
          </Button>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {downloading ? (
        <Spinner className="me-2" animation="border" variant="primary" size="sm" />
      ) : (
        <OverlayTrigger
          trigger={showNotes ? "click" : ""}
          placement="bottom"
          overlay={popover}
          rootClose
          popperConfig={{ action: DOWNLOAD }}
        >
          <PointerSpan
            className="fa fa-download text-primary me-xl-2 fa-fw"
            title="Download"
            onClick={showNotes ? () => {} : (e) => downloadOrder(e)}
          />
        </OverlayTrigger>
      )}
      {printing ? (
        <Spinner className="" animation="border" variant="primary" size="sm" />
      ) : (
        <OverlayTrigger
          trigger={showNotes ? "click" : ""}
          placement="bottom"
          overlay={popover}
          rootClose
          popperConfig={{ action: PRINT }}
        >
          <PointerSpan
            className="fa fa-print text-primary fa-fw"
            title="Print"
            onClick={showNotes ? () => {} : (e) => printOrder(e)}
          />
        </OverlayTrigger>
      )}
    </div>
  );
};

PdfActions.propTypes = {
  orderId: PropTypes.number.isRequired,
  setError: PropTypes.func.isRequired,
};

export default PdfActions;
