import React from "react";
import PropTypes from "prop-types";

import { isBeingSort } from "@ROM-utils/filters";

const WarehouseTableHeader = ({ handleColumnSort, filters }) => {
  return (
    <tr>
      <th className="cursor-pointer text-nowrap" onClick={() => handleColumnSort("name")}>
        Name
        <i className={isBeingSort(filters, "name")} />
      </th>
      <th className="cursor-pointer text-nowrap" onClick={() => handleColumnSort("displayName")}>
        Display Name
        <i className={isBeingSort(filters, "displayName")} />
      </th>
      <th>Address</th>
      <th>Region</th>
    </tr>
  );
};

WarehouseTableHeader.propTypes = {
  filters: PropTypes.shape().isRequired,
  handleColumnSort: PropTypes.func.isRequired,
};

export default WarehouseTableHeader;
