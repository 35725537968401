import { apiAction } from "@ROM-utils/actions";
import OrdersApi from "@ROM/Orders/api";

export const CART = "orders/api/CART";
export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_CART_ITEMS_TRIAL = "UPDATE_CART_ITEMS_TRIAL";
export const DELETE_ASSET = "DELETE_ASSET";

export function fetchCart(customer) {
  return apiAction(CART, OrdersApi.cart(customer));
}

export function fetchOrder(id) {
  return apiAction(CART, OrdersApi.find(id));
}

export function updateCartItemsTrial(id, isTrial) {
  return apiAction(UPDATE_CART_ITEMS_TRIAL, OrdersApi.trial(id, isTrial));
}

export function updateCart(id, attributes) {
  return apiAction(UPDATE_CART, OrdersApi.update(id, attributes));
}

export function deleteAsset(id, assetId) {
  return apiAction(DELETE_ASSET, OrdersApi.deleteAsset(id, assetId));
}
