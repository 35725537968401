import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { selectIsLoading, selectAllCustomTables } from "@ROM/CustomTables/selectors";
import { companyAdminUrl, companyAdminCustomTablesEditUrl, companyAdminCustomTablesShowUrl } from "@ROM-utils/urlHelpers";
import { destroy } from "@ROM/CustomTables/actions";
import Section from "@ROM-components/common/Section";
import CustomTableShow from "@ROM/CustomTables/components/Show";

const Page = ({ customTableId, message }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const customTables = useSelector(selectAllCustomTables);
  const table = customTables.find((item) => item.id === customTableId);

  const onEditTable = () => {
    history.push(companyAdminCustomTablesEditUrl(table.id));
  };

  const onDeleteTable = async () => {
    const tableId = customTableId;
    const response = await dispatch(destroy(customTableId));

    if (!response.error) {
      history.push(companyAdminCustomTablesShowUrl(tableId), { message: "Table deleted successfully" });
    }
  };

  if (isLoading) {
    return null;
  }

  if (!table && !message) {
    return <Redirect to={companyAdminUrl()} />;
  }

  return (
    <Section>
      <CustomTableShow table={table} message={message} onEditTable={onEditTable} onDeleteTable={onDeleteTable} />
    </Section>
  );
};

Page.defaultProps = {
  message: null,
};

Page.propTypes = {
  customTableId: PropTypes.number.isRequired,
  message: PropTypes.string,
};

export default Page;
