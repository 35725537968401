import React, { useReducer, useEffect } from "react";
import { useDispatch } from "react-redux";
import { reducer } from "@ROM/App/utils/forms";
import PropTypes from "prop-types";

import Modal from "@ROM-components/common/Modal";
import PriceListForm from "@ROM/CompanyAdmin/PriceLists/Form";
import { update } from "@ROM/PriceLists/actions";

const EditModal = ({ show, onCancel, currentPriceList }) => {
  const dispatch = useDispatch();

  const [priceList, setPriceList] = useReducer(reducer, currentPriceList?.attributes);

  useEffect(() => {
    if (currentPriceList) setPriceList(currentPriceList.attributes);
  }, [currentPriceList]);

  const handleUserInput = (name, value) => setPriceList({ [name]: value });

  const handleUpdatePriceList = async () => {
    const response = await dispatch(update(currentPriceList.id, priceList));

    if (response.payload.status === 201 || response.payload.status === 200) {
      onCancel();
    }
  };

  return (
    <Modal isShowing={show} confirmText="Update" onConfirm={handleUpdatePriceList} onCancel={onCancel} header="Edit Price List">
      <PriceListForm priceList={priceList} handleUserInput={handleUserInput} />
    </Modal>
  );
};

EditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  currentPriceList: PropTypes.shape.isRequired,
};

export default EditModal;
