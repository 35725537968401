import React, { useState } from "react";
import PropTypes from "prop-types";

import IconPointer from "@ROM-ui/IconPointer";
import Modal from "@ROM-components/common/Modal";
import { BooleanBadge } from "@ROM-ui/Badge";

const TableDetails = ({ table, onEditTable, onDeleteTable }) => {
  const [showTableDetails, setShowTableDetails] = useState(false);
  const [showDestroyConfirmationModal, setShowDestroyConfirmationModal] = useState(false);

  return (
    <>
      <div className="d-flex align-items-center mb-1">
        <h3>{table.attributes.name}</h3>
        <IconPointer
          className={`ms-2 fa ${showTableDetails ? "fa-angle-down" : "fa-angle-right"} text-primary`}
          onClick={() => setShowTableDetails(!showTableDetails)}
        />
        <IconPointer className="ms-auto fa fa-edit text-success" onClick={onEditTable} />
        <IconPointer className="ms-3 fa fa-trash text-danger" onClick={() => setShowDestroyConfirmationModal(true)} />
      </div>

      <div className="text-secondary" style={{ maxWidth: "500px" }}>
        {table.attributes.description}
      </div>

      {showTableDetails && (
        <div className="mt-2">
          <div>
            Notify on row creation?
            <BooleanBadge className="ms-1" value={table.attributes.notifyOnRowCreation} />
          </div>
          <div>
            Notification recipients:
            {table.attributes.notifyRecipients.length === 0 && " None"}
            <ul>
              {table.attributes.notifyRecipients.map((email) => (
                <li key={email}>{email}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <Modal
        isShowing={showDestroyConfirmationModal}
        onConfirm={() => onDeleteTable()}
        onCancel={() => setShowDestroyConfirmationModal(false)}
        header="Are you sure you want to delete the table?"
        showCloseIcon={false}
      />
    </>
  );
};

TableDetails.propTypes = {
  table: PropTypes.shape({
    attributes: PropTypes.shape({
      notifyOnRowCreation: PropTypes.bool,
      notifyRecipients: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
  onEditTable: PropTypes.func.isRequired,
  onDeleteTable: PropTypes.func.isRequired,
};

export default TableDetails;
