/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Button from "@ROM-ui/Button";
import Spinner from "@ROM-ui/Spinner";
import PointerSpan from "@ROM-ui/PointerSpan";
import useIsMobile from "@ROM-components/utils/Responsive";
import { showEditDeliveryButton, showUpdateDeliveryShipping, createDeliveryPdf, createAddressPdf } from "@ROM/Deliveries/utils";
import { selectAllDeliveryWarehouses } from "@ROM/Deliveries/selectors";
import DeliveryStatusBadge from "@ROM/Deliveries/components/common/DeliveryStatusBadge";
import DeliveryWeightWarning from "@ROM/Deliveries/components/common/DeliveryWeightWarning";
import TrackingInformation from "@ROM/Deliveries/components/common/TrackingInformation";
import BillOfLadingDownloadButton from "@ROM/BillOfLadings/components/Form/DownloadButton";
import EmailBillOfLadingModal from "@ROM/BillOfLadings/components/EmailModal";
import { showDownloadBillOfLadings, showCreateBillOfLadings, showEditBillOfLadings } from "@ROM/BillOfLadings/utils";
import { create as createBillOfLading } from "@ROM/BillOfLadings/actions";
import { newBillOfLadingUrl, editBillOfLadingUrl } from "@ROM-utils/urlHelpers";
import { isCompleted, isCancelled } from "@ROM/Orders/utils";
import { canCreateDeliveries } from "@ROM/Deliveries/permissions";
import ActionSection from "./ActionSection";

const IconTextButton = ({ id, isMobile, title, faIcon, clickHandler, busy }) => {
  if (isMobile) {
    return busy ? (
      <Spinner animation="border" size="sm" />
    ) : (
      <PointerSpan id={id} onClick={clickHandler} className={`text-primary fas ${faIcon} ms-2`} />
    );
  }
  return (
    <StyledButton id={id} className="mx-100" size="sm" variant="outline-primary" onClick={clickHandler}>
      {busy ? <Spinner animation="border" size="sm" /> : title}
    </StyledButton>
  );
};

IconTextButton.defaultProps = {
  id: null,
};

IconTextButton.propTypes = {
  id: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  faIcon: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
};

const OrderDeliveries = ({
  deliveries,
  createDeliveryAndEdit,
  error,
  setError,
  deliveryItems,
  deliveryAssets,
  order,
  currentCompany,
  orderIncluded,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const [openDeliveryId, setOpenDeliveryId] = useState(null);
  const [downloadingDeliveryId, setDownloadingDeliveryId] = useState(null);
  const [downloadingAddressId, setDownloadingAddressId] = useState(null);
  const [emailingBillOfLadingId, setEmailingBillOfLadingId] = useState(null);
  const warehouses = useSelector(selectAllDeliveryWarehouses);
  const history = useHistory();
  const isMobile = useIsMobile();

  const deliveryWarehouse = (delivery) =>
    warehouses.find((warehouse) => warehouse.id === delivery.attributes.warehouseId?.toString());

  const showBillsOfLading = currentCompany?.attributes?.showBillsOfLading;

  const handleOnClick = (deliveryId) => {
    if (deliveryId === openDeliveryId) {
      setOpenDeliveryId(null);
    } else {
      setOpenDeliveryId(deliveryId);
    }
  };

  const handleEditBillOfLading = (event, deliveryId, billOfLadingId) => {
    event.stopPropagation();
    history.push(editBillOfLadingUrl(order.id, deliveryId, billOfLadingId));
  };

  const handleCreateBillOfLading = async (event, deliveryId) => {
    event.stopPropagation();
    const response = await dispatch(createBillOfLading({ deliveryId }));
    if (response.payload.status === 200) {
      history.push(newBillOfLadingUrl(order.id, deliveryId));
    } else {
      setError(true);
    }
  };

  const handleDocumentDownload = async (event, currentItems, deliveryId, deliveryNumber) => {
    setDownloadingDeliveryId(deliveryId);
    event.stopPropagation();
    await createDeliveryPdf(deliveryNumber, currentItems, order, currentCompany, orderIncluded);
    setDownloadingDeliveryId(null);
  };

  const handleAddresDownload = async (event, deliveryId) => {
    setDownloadingAddressId(deliveryId);
    event.stopPropagation();
    await createAddressPdf(order, orderIncluded);
    setDownloadingAddressId(null);
  };

  const handleEmailBillOfLading = (event, billOfLadingId) => {
    event.stopPropagation();
    setEmailingBillOfLadingId(billOfLadingId);
  };

  const deliveryZeroState = () =>
    isCancelled(order) || isCompleted(order) ? (
      <div>No deliveries created for the order.</div>
    ) : (
      canCreateDeliveries(currentUser) && (
        <Button
          onClick={(e) => createDeliveryAndEdit(e)}
          variant="outline-primary"
          className="w-100 m-0 p-2 d-flex align-items-center justify-content-between px-3"
        >
          No deliveries created yet. Let&apos;s make some!
          <i className="fa fa-box-open" />
        </Button>
      )
    );

  return (
    <>
      <Row className={isMobile ? "bg-white" : "bg-white rounded m-0"}>
        <Col className="p-3 px-4 text-secondary" md={12}>
          {deliveries.map((delivery) => {
            const currentItems = deliveryItems.filter((item) => item.attributes.deliveryId.toString() === delivery.id);
            const currentAssets = deliveryAssets.filter((asset) => asset.relationships.owner.data.id === delivery.id);
            const billOfLading = delivery?.relationships?.billOfLading;
            const hasBillOfLading = billOfLading?.data !== null;
            const showDownloadBOL = showDownloadBillOfLadings(currentUser);
            const showCreateBOL = showCreateBillOfLadings(currentUser, order);
            const showEditBOL = showEditBillOfLadings(currentUser, order);

            return (
              <StyledDiv key={delivery.id} onClick={() => handleOnClick(delivery.id)}>
                <div className="w-100 d-flex flex-wrap justify-content-between">
                  <StyledDeliveryPanel className="m-3 d-flex align-items-start">
                    <StyledI className={`fas ${openDeliveryId === delivery.id ? "fa-caret-up" : "fa-caret-down"} mx-2`} />
                    <span className="text-nowrap">{`Delivery ${delivery.attributes.position}`}</span>
                    <DeliveryStatusBadge status={delivery.attributes.status} className="ms-2" />
                    {showEditDeliveryButton(order, delivery, currentUser) && (
                      <IconTextButton
                        isMobile={isMobile}
                        title="Edit"
                        faIcon="fa-edit"
                        clickHandler={(e) => createDeliveryAndEdit(e)}
                      />
                    )}
                    <IconTextButton
                      isMobile={isMobile}
                      title="Download"
                      faIcon="fa-download"
                      clickHandler={(e) => handleDocumentDownload(e, currentItems, delivery.id, delivery.attributes.position)}
                      busy={downloadingDeliveryId === delivery.id}
                    />
                    <ActionSection order={order} delivery={delivery} dontShowStatus />
                  </StyledDeliveryPanel>
                  {showBillsOfLading && (
                    <div className="m-3">
                      {((hasBillOfLading && showDownloadBOL) || showCreateBOL) && <span>Bill of Lading</span>}
                      {!hasBillOfLading && showCreateBOL && (
                        <IconTextButton
                          isMobile={isMobile}
                          title="Create"
                          faIcon="fa-plus"
                          clickHandler={(e) => handleCreateBillOfLading(e, delivery.id)}
                        />
                      )}
                      {hasBillOfLading && showEditBOL && (
                        <IconTextButton
                          id={`edit-bill-of-lading-${billOfLading?.data?.id}`}
                          isMobile={isMobile}
                          title="Edit"
                          faIcon="fa-edit"
                          clickHandler={(e) => handleEditBillOfLading(e, delivery.id, billOfLading?.data?.id)}
                        />
                      )}
                      {hasBillOfLading && showDownloadBOL && (
                        <>
                          <BillOfLadingDownloadButton orderId={order.id} billOfLadingId={billOfLading?.data?.id}>
                            <div className="d-inline-block" onClick={(e) => e.stopPropagation()}>
                              <IconTextButton isMobile={isMobile} title="Download" faIcon="fa-download" />
                            </div>
                          </BillOfLadingDownloadButton>

                          <div className="d-inline-block" onClick={(e) => handleEmailBillOfLading(e, billOfLading?.data?.id)}>
                            <IconTextButton
                              isMobile={isMobile}
                              title="Email"
                              faIcon="fa-envelope"
                              busy={emailingBillOfLadingId === billOfLading?.data?.id}
                            />
                          </div>
                          
                        </>
                      )}
                      {hasBillOfLading && showDownloadBOL && (
                        <div className="mt-3 d-flex flex-row-reverse">
                          <IconTextButton
                            isMobile={isMobile}
                            title="Print Address Label"
                            faIcon="fa-download"
                            clickHandler={(e) => handleAddresDownload(e, currentItems, delivery.id, delivery.attributes.position)}
                            busy={downloadingAddressId === delivery.id}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <DeliveryWeightWarning deliveryItems={currentItems} className="mx-3 mb-3" />
                <div className="mx-3 mb-3">
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="mx-2">
                      <strong>Warehouse: </strong>
                      {deliveryWarehouse(delivery)?.attributes.name || "None selected"}
                    </div>
                    {showUpdateDeliveryShipping(currentUser, order) && (
                      <TrackingInformation className="mx-2 ms-md-auto d-flex align-items-center" delivery={delivery} />
                    )}
                  </div>
                </div>
                <div className="m-3">
                  {openDeliveryId === delivery.id && delivery.attributes.notes && (
                    <div className="ms-2 mt-3">
                      <strong>Notes: </strong>
                      {delivery.attributes.notes}
                    </div>
                  )}

                  {openDeliveryId === delivery.id && (
                    <div className="ms-2 mt-3">
                      <strong>Delivery items:</strong>
                      {currentItems.map((item) => (
                        <div key={item.id} className="d-flex">
                          <QuantityDiv className="text-end"> {item.attributes.quantity}x</QuantityDiv>
                          <div>{item.attributes.productName}</div>
                        </div>
                      ))}
                    </div>
                  )}

                  {openDeliveryId === delivery.id && currentAssets.length > 0 && (
                    <div className="ms-2 mt-3">
                      <strong>Deliver confirmation photos:</strong>
                      <div className="mt-2">
                        {currentAssets.map((asset, index) => {
                          const assetName = `Delivery confirmation ${index}`;
                          const assetUrl = asset.attributes.file.url;

                          return (
                            <a
                              key={asset.id}
                              data-type="image"
                              href={assetUrl}
                              data-fancybox={`galery-${assetName}`}
                              data-caption={assetName}
                              onClick={(e) => e.stopPropagation()}
                              className="me-2"
                            >
                              <ThumbImage src={assetUrl} />
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {openDeliveryId === delivery.id && delivery.attributes.receiverSignature.url && (
                    <div className="ms-2 mt-3 overflow-hidden">
                      <strong>Deliver confirmation signature:</strong>
                      <div className="mt-2">
                        <a
                          key={delivery.id}
                          data-type="image"
                          href={delivery.attributes.receiverSignature.url}
                          data-fancybox="galery-delivery-signature"
                          data-caption="Delivery Signature"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <ThumbImage src={delivery.attributes.receiverSignature.url} />
                        </a>
                        <div>{delivery.attributes.receiverName}</div>
                      </div>
                    </div>
                  )}
                </div>
              </StyledDiv>
            );
          })}
          {deliveries.length === 0 && deliveryZeroState()}
        </Col>
      </Row>
      {error && (
        <Row className="m-0">
          <Col md={12}>
            <StyledP>Something went wrong</StyledP>
          </Col>
        </Row>
      )}
      {emailingBillOfLadingId && (
        <EmailBillOfLadingModal
          orderId={order.id}
          billOfLadingId={emailingBillOfLadingId}
          onCancel={() => setEmailingBillOfLadingId(null)}
        />
      )}
    </>
  );
};

const StyledDiv = styled.div`
  border: 2px solid #c89393 !important;
  border-radius: 5px;
  background-color: #e3f4e4;
  margin-bottom: 15px;
  :last-child {
    margin-bottom: 0px;
  }
  cursor: pointer;
`;

const StyledDeliveryPanel = styled.div`
  gap: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledI = styled.i`
  font-size: x-large;
`;

const StyledP = styled.p`
  color: red;
  margin: 0;
  margin-left: 0.6rem;
  margin-bottom: 0.5rem;
`;

const StyledButton = styled(Button)`
  background-color: white;
  margin-left: 0.5rem;
  min-width: ${(props) => (props.isMobile ? "" : "100px")};
  max-width: ${(props) => (props.isMobile ? "" : "fit-content")};
`;

const QuantityDiv = styled.div`
  width: 3rem;
  margin-right: 0.5rem;
`;

const ThumbImage = styled.img`
  width: auto;
  height: 50px;
`;

OrderDeliveries.propTypes = {
  order: PropTypes.shape().isRequired,
  deliveries: PropTypes.arrayOf({}).isRequired,
  deliveryItems: PropTypes.arrayOf({}).isRequired,
  deliveryAssets: PropTypes.arrayOf({}).isRequired,
  currentCompany: PropTypes.shape({
    attributes: {
      showBillsOfLading: PropTypes.bool,
    },
  }).isRequired,
  orderIncluded: PropTypes.shape().isRequired,
  error: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  createDeliveryAndEdit: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
};
export default OrderDeliveries;
