import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import { reducer } from "@ROM/App/utils/forms";
import Select from "@ROM-components/common/Select";
import Form from "@ROM-ui/Form";
import InputGroup from "@ROM-ui/InputGroup";
import Button from "@ROM-ui/Button";
import CalendarInput from "@ROM-components/common/CalendarInput";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { parseDate } from "react-day-picker/moment";
import { list as fetchWarehouses, create as createShipper } from "@ROM/Warehouses/actions";
import { selectPermanentWarehouses, selectAllWarehouses } from "@ROM/Warehouses/selectors";
import { list as fetchCarriers } from "@ROM/Carriers/actions";
import { selectAllCarriers } from "@ROM/Carriers/selectors";
import { update as updateDelivery, updateShipping } from "@ROM/Deliveries/actions";
import { update as updateBillOfLading } from "@ROM/BillOfLadings/actions";
import { selectCurrentBillOfLadingDeliveries } from "@ROM/BillOfLadings/selectors";
import { selectIncludedDeliveryShipping } from "@ROM/Deliveries/selectors";
import { CARRIER_OTHER } from "@ROM/BillOfLadings/constants";
const ShippingInformation = ({
  currentBillOfLading,
  deliveryId,
  order,
  canEditAllParams,
  showRefrigerationWarning,
  onChange,
}) => {
  const deliveries = useSelector(selectCurrentBillOfLadingDeliveries);
  const delivery = deliveries.find((item) => item.id === deliveryId);
  const deliveryShipping = useSelector(selectIncludedDeliveryShipping(delivery?.relationships.deliveryShipping.data?.id));
  const carriers = useSelector(selectAllCarriers);
  const warehouses = useSelector(selectAllWarehouses);
  const filterWarehouses = useSelector(selectPermanentWarehouses);

  const dispatch = useDispatch();

  const warehouseOptions = [... filterWarehouses.map((warehouse) => ({ value: warehouse.id, label: warehouse.attributes.name })), {value:"other", label: "Other"}];
  /*
  If all deliveries warehouses are set to the same value, choose that.
  Otherwise choose none.
  */

  const allWarehouseIds = [...new Set(deliveries.map((item) => item.attributes.warehouseId?.toString()))];

  const currentWarehouseId = allWarehouseIds.length === 1 ? allWarehouseIds[0] : null;

  const paymentMethodOptions = [
    { value: "prepaid", label: "Prepaid" },
    { value: "needs_collect", label: "Collect" },
  ];

  const initialBillOfLading = {
    notes: currentBillOfLading.attributes.notes,
    rqNumber: currentBillOfLading.attributes.rqNumber,
    pickUpNumber: currentBillOfLading.attributes.pickUpNumber,
    requiresRefrigeration: currentBillOfLading.attributes.requiresRefrigeration,
    amount: currentBillOfLading.attributes.amount,
    paymentMethod: currentBillOfLading.attributes.paymentMethod,
    shippingDate: currentBillOfLading.attributes.shippingDate ? currentBillOfLading.attributes.shippingDate : null,
    carrierCustomProperties: currentBillOfLading.attributes.customPropertyValues,
    classDescription: currentBillOfLading.attributes.classDescription,
  };

  const initialShipping = {
    trackingNumber: deliveryShipping?.attributes.trackingNumber,
    carrierId: deliveryShipping?.attributes.carrierId,
    carrierOtherName: deliveryShipping?.attributes.carrierName,
  };

  const [billOfLading, setBillOfLading] = useState(initialBillOfLading);
  const [shipping, setShipping] = useState(initialShipping);
  const [shipperName, setShipperName] = useState("");
  const [shipperAddress, setShipperAddress] = useState("");
  const [shipperCity, setShipperCity] = useState("");
  const [shipperState, setShipperState] = useState("");
  const [shipperZip, setShipperZip] = useState("");
  const [shipperPhone, setShipperPhone] = useState("");

  /* Sort by name, but "Other" goes last */
  const carrierSort = (carrierA, carrierB) => {
    const aName = carrierA.attributes.name;
    const bName = carrierB.attributes.name;
    if (aName === "Other") return 1;
    if (bName === "Other") return -1;
    return aName.localeCompare(bName);
  };

  const carriersOptions = carriers.sort(carrierSort).map((carrier) => ({ value: carrier.id, label: carrier.attributes.name }));
  const selectedCarrier = carriers.find((c) => c.id === shipping.carrierId?.toString());
  const carrierHasCustomAttributes = selectedCarrier?.attributes?.customFields?.length > 0;
  const carrierName = selectedCarrier?.attributes?.name;
  const updateRequest = useCallback(
    _.debounce((bolId, attributes) => dispatch(updateBillOfLading(bolId, attributes)), 2000),
    []
  );

  const update = (stateDiff) => {
    const next = reducer(billOfLading, stateDiff);
    setBillOfLading(next);
    onChange();
    updateRequest(currentBillOfLading.id, next);
  };

  const updateShippingRequest = useCallback(
    _.debounce((aDeliveryId, attributes) => dispatch(updateShipping(aDeliveryId, attributes)), 2000),
    []
  );

  const updateShippingState = (stateDiff) => {
    const next = reducer(shipping, stateDiff);
    setShipping(next);
    onChange();
    updateShippingRequest(deliveryId, next);
  };

  useEffect(() => {
    const regionId = order?.relationships?.region?.data?.id;

    dispatch(fetchWarehouses({ regionId }));
    dispatch(fetchCarriers());
  }, [dispatch]);

  useEffect(() => {
    setBillOfLading(initialBillOfLading);
  }, [dispatch, currentBillOfLading]);

  const valueChanged = (event, customBillOfLandingProp = false) => {
    const {
      target: {
        dataset: { field },
        value,
      },
    } = event;

    if (customBillOfLandingProp) {
      return update({
        carrierCustomProperties: {
          ...billOfLading.carrierCustomProperties,
          [field]: event.target.type === "checkbox" ? event.target.checked : value,
        },
      });
    }

    update({ [field]: value });
  };

  const handleShippingDateChange = (date) => {
    update({ shippingDate: date });
  };

  const handleCarrierChange = (id) => {
    updateShippingState({ carrierId: id });
  };

  const handleCarrierNameChange = (event) => {
    updateShippingState({ carrierOtherName: event.target.value });
  };

  const handlePaymentMethodChange = (paymentMethod) => {
    update({ paymentMethod });
  };

  const handleRefrigerationChange = () => {
    update({ requiresRefrigeration: !billOfLading.requiresRefrigeration });
  };

  const handleWarehouseChange = (warehouse) => {
    const promises = deliveries.map((deliveryItem) =>
      dispatch(updateDelivery(deliveryItem.id, { ...deliveryItem.attributes, warehouseId: warehouse }))
    );
    Promise.all(promises);
  };

  const handleNewShipper = async (event) => {
    event.preventDefault()
    const response = await dispatch(createShipper({
      name: shipperName,
      temporary: true,
      address1: shipperAddress,
      city: shipperCity,
      state: shipperState,
      zip: shipperZip,
      phone: shipperPhone
    }))
    const newShipperId = response.payload.data.data.id
    const promises = deliveries.map((deliveryItem) =>
      dispatch(updateDelivery(deliveryItem.id, {
        warehouseId: newShipperId,
      })
    ))
    Promise.all(promises);
  }
  const formatDate = (date) => moment(date).format("YYYY-MM-DD");

  return (
    <>
      {showRefrigerationWarning && (
        <div className="alert alert-danger">
          <i className="fa fa-exclamation-circle me-1" />
          Refrigeration is strongly recommended for the calculated cargo weight.
        </div>
      )}
      <div className="d-flex">
        <Form.Label className="w-50">Warehouse</Form.Label>
        <Form.Label className="w-50 ps-2">Shipping Date</Form.Label>
      </div>
      <div className="d-flex align-items-center mb-4">
        <Select
          id="deliveries-warehouse"
          className="w-50 pe-2"
          options={warehouseOptions}
          value={warehouseOptions.find((warehouse) => warehouse.value === currentWarehouseId)}
          onChange={(e) => handleWarehouseChange(e.value)}
        />
        <div className="w-50 ps-2 DayPickerPopRight">
          <DayPickerInput
            value={billOfLading.shippingDate}
            onDayChange={handleShippingDateChange}
            component={CalendarInput}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder=""
            inputProps={{ disabled: !canEditAllParams }}
          />
        </div>
      </div>
      {/*Other fields*/}
      {currentWarehouseId == 0 && (
        <div>
          <div className="d-flex">
            <Form.Label className="w-50">Shipper Name</Form.Label>
            <Form.Label className="w-50 ps-2">Address 1</Form.Label>
          </div>
          <div className="d-flex mb-4">
            <InputGroup className="w-50">
              <InputGroup.Text className="bg-white"></InputGroup.Text>
              <Form.Control
                className="border-start-0"
                type="text"
                data-field="name"
                value={shipperName}
                onChange={(e)=>setShipperName(e.target.value)}
                disabled={!canEditAllParams}
              />
            </InputGroup>
            <InputGroup className="w-50 ps-2">
              <InputGroup.Text className="bg-white"></InputGroup.Text>
              <Form.Control
                className="border-start-0"
                type="text"
                data-field="address_1"
                value={shipperAddress}
                onChange={(e)=>setShipperAddress(e.target.value)}
                disabled={!canEditAllParams}
              />
            </InputGroup>
          </div>
          <div className="d-flex">
            <Form.Label className="w-50">City</Form.Label>
            <Form.Label className="w-50 ps-2">State</Form.Label>
          </div>
          <div className="d-flex mb-4">
            <InputGroup className="w-50">
              <InputGroup.Text className="bg-white"></InputGroup.Text>
              <Form.Control
                className="border-start-0"
                type="text"
                data-field="city"
                value={shipperCity}
                onChange={(e)=>setShipperCity(e.target.value)}
                disabled={!canEditAllParams}
              />
            </InputGroup>
            <InputGroup className="w-50 ps-2">
              <InputGroup.Text className="bg-white"></InputGroup.Text>
              <Form.Control
                className="border-start-0"
                type="text"
                data-field="state"
                value={shipperState}
                onChange={(e)=>setShipperState(e.target.value)}
                disabled={!canEditAllParams}
              />
            </InputGroup>
          </div>
          <div className="d-flex">
            <Form.Label className="w-50">Zip</Form.Label>
            <Form.Label className="w-50 ps-2">Phone</Form.Label>
          </div>
          <div className="d-flex mb-4">
            <InputGroup className="w-50">
              <InputGroup.Text className="bg-white"></InputGroup.Text>
              <Form.Control
                className="border-start-0"
                type="text"
                data-field="zip"
                value={shipperZip}
                onChange={(e)=>setShipperZip(e.target.value)}
                disabled={!canEditAllParams}
              />
            </InputGroup>
            <InputGroup className="w-50 ps-2">
              <InputGroup.Text className="bg-white"></InputGroup.Text>
              <Form.Control
                className="border-start-0"
                type="text"
                data-field="phone"
                value={shipperPhone}
                onChange={(e)=>setShipperPhone(e.target.value)}
                disabled={!canEditAllParams}
              />
            </InputGroup>
          </div>
          <Button onClick={handleNewShipper}>
            Create Shipper
          </Button>
        </div>
      )}
      <div className="d-flex">
        <Form.Label className="w-50">Paid</Form.Label>
      </div>
      <div className="d-flex align-items-center mb-4">
        <Select
          id="paid-select"
          className="w-50 pe-2"
          options={paymentMethodOptions}
          onChange={(e) => handlePaymentMethodChange(e.value)}
          value={paymentMethodOptions.find((option) => option.value === billOfLading.paymentMethod)}
          disabled={!canEditAllParams}
        />
        <Form.Check
          id="requires-refrigeration"
          className="ms-2 w-50"
          checked={billOfLading.requiresRefrigeration || false}
          onChange={handleRefrigerationChange}
          label="Requires Refrigeration"
          disabled={!canEditAllParams}
        />
      </div>
      <div className="d-flex">
        <Form.Label className="w-50">Carrier</Form.Label>
        <Form.Label className="w-50 ps-2">Dollar Amount</Form.Label>
      </div>
      <div className="d-flex mb-4">
        <Select
          className="w-50 pe-2"
          id="carrier-select"
          options={carriersOptions}
          onChange={(e) => handleCarrierChange(e.value)}
          value={carriersOptions.find((option) => option.value === shipping.carrierId?.toString())}
        />
        <InputGroup className="w-50 ps-2">
          <InputGroup.Text className="bg-white">$</InputGroup.Text>
          <Form.Control
            className="border-start-0"
            type="text"
            data-field="amount"
            value={billOfLading.amount || ""}
            onChange={valueChanged}
            disabled={!canEditAllParams}
          />
        </InputGroup>
      </div>
      {carrierName === CARRIER_OTHER && (
        <>
          <div className="d-flex">
            <Form.Label className="w-50">Carrier name</Form.Label>
          </div>
          <div>
            <Form.Control
              className="mb-4"
              type="text"
              data-field="carrierOtherName"
              value={shipping.carrierOtherName || ""}
              onChange={handleCarrierNameChange}
            />
          </div>
        </>
      )}
      {carrierHasCustomAttributes && (
        <Outlined className="p-2 mb-4">
          <Form.Label className="mb-4">
            <b>
              {selectedCarrier.attributes.name}
              &nbsp;properties
            </b>
          </Form.Label>
          {selectedCarrier.attributes.customFields.map((field) => (
            <div className="d-flex mb-3" key={field.name}>
              <Form.Label className="w-50">{field.name}</Form.Label>
              {field.kind === "checkbox" ? (
                <Form.Check
                  className="w-50"
                  data-field={field.id}
                  checked={billOfLading.carrierCustomProperties[field.id]}
                  onChange={(e) => valueChanged(e, true)}
                />
              ) : (
                <Form.Control
                  className="w-50"
                  type={field.kind}
                  data-field={field.id}
                  value={billOfLading.carrierCustomProperties[field.id]}
                  onChange={(e) => valueChanged(e, true)}
                />
              )}
            </div>
          ))}
        </Outlined>
      )}
      <Form.Label>Class</Form.Label>
      <Form.Control
        id="class-description"
        as="textarea"
        rows="1"
        data-field="classDescription"
        className="form-control w-100 mb-4"
        value={billOfLading.classDescription || ""}
        onChange={valueChanged}
        disabled={!canEditAllParams}
      />
      <Form.Label>Notes</Form.Label>
      <Form.Control
        as="textarea"
        rows="3"
        data-field="notes"
        className="form-control w-100"
        value={billOfLading.notes || ""}
        onChange={valueChanged}
        disabled={!canEditAllParams}
      />
    </>
  );
};

ShippingInformation.propTypes = {
  currentBillOfLading: PropTypes.shape({
    id: PropTypes.number,
    attributes: {
      notes: PropTypes.string,
      rqNumber: PropTypes.string,
      pickUpNumber: PropTypes.string,
      carrierId: PropTypes.number,
      requiresRefrigeration: PropTypes.bool,
      amount: PropTypes.number,
      paymentMethod: PropTypes.string,
    },
  }).isRequired,
  order: PropTypes.shape({ relationships: { region: { data: { id: PropTypes.string } } } }).isRequired,
  deliveryId: PropTypes.number.isRequired,
  canEditAllParams: PropTypes.bool.isRequired,
  showRefrigerationWarning: PropTypes.bool.isRequired,
};

export default ShippingInformation;

const Outlined = styled.div`
  outline-color: white;
  outline-style: solid;
`;
