import React from "react";
import PropTypes from "prop-types";
import ContentHeader from "@ROM-components/common/ContentHeader";
import Button from "@ROM-ui/Button";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const TitledHeader = ({ title, backLinkTitle, backUrl, titleClasses = "", ...props }) => {
  const history = useHistory();
  const handleBackClick = () => (backUrl ? history.push(backUrl) : history.goBack());
  return (
    <ContentHeader {...props}>
      <ContentHeader.Left>
        <div className="text-white">
          {backLinkTitle && (
            <StyledLink variant="link" className="p-0" onClick={handleBackClick}>
              <i className="fa fa-chevron-left me-2" />
              {backLinkTitle}
            </StyledLink>
          )}
          {title && (
            <h1 className={titleClasses}>
              <strong>{title}</strong>
            </h1>
          )}
        </div>
      </ContentHeader.Left>
    </ContentHeader>
  );
};

TitledHeader.propTypes = {
  title: PropTypes.string.isRequired,
  backLinkTitle: PropTypes.string,
  backUrl: PropTypes.string,
};

TitledHeader.defaultProps = {
  backLinkTitle: "Back",
  backUrl: null,
};

export default TitledHeader;

const StyledLink = styled(Button)`
  color: #049dff;
  font-size: large;
  &:hover {
    color: #049dff;
  }
`;
