import React from "react";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";
import Button from "@ROM-ui/Button";
import ShadowContainer from "@ROM-ui/ShadowContainer";
import PropTypes from "prop-types";

const Filters = ({ handleFilterChange, handleClearFilters, filters }) => {
  return (
    <ShadowContainer className="mt-2 mb-4">
      <Row className="align-items-center">
        <Col md={4} className="mb-2 mb-md-0">
          <Form.Control
            type="text"
            name="name"
            placeholder="Name"
            onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
            value={filters.name || ""}
          />
        </Col>
        <Col md={2}>
          <Button className="p-0" size="sm" variant="link" onClick={handleClearFilters}>
            Clear Filters
          </Button>
        </Col>
      </Row>
    </ShadowContainer>
  );
};

Filters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default Filters;
