import React from "react";
import PropTypes from "prop-types";
import InputGroup from "@ROM-ui/InputGroup";
import Form from "@ROM-ui/Form";

const Input = ({ value, placeholder, disabled, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <InputGroup disabled={disabled} {...props}>
    <Form.Control disabled={disabled} placeholder={placeholder} value={value} readOnly className={disabled ? "" : "bg-white"} />
    <InputGroup.Text>
      <i className="fa fa-calendar" />
    </InputGroup.Text>
  </InputGroup>
);

Input.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  disabled: false,
  placeholder: "",
  value: "",
};

export default Input;
