import React, { useReducer, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Section from "@ROM-components/common/Section";
import ProductForm from "@ROM/CompanyAdmin/Products/Form";
import ProductsApi from "@ROM/Products/api";
import Message from "@ROM-common/Message";
import FileUploader from "@ROM-common/FileUploader";
import { reducer } from "@ROM/App/utils/forms";
import { companyAdminProductsUrl } from "@ROM-utils/urlHelpers";
import { productsBreadcrumbs } from "@ROM/CompanyAdmin/utils";
import { list as listCategories } from "@ROM/Categories/actions";
import { list as listUnits } from "@ROM/Units/actions";
import { list as listWarehouses } from "@ROM/Warehouses/actions";
import { clear as clearWarehouseInventories, create as createWarehouseInventory } from "@ROM/WarehouseInventories/actions";

import { selectAllCategories, selectAllSubcategories } from "@ROM/Categories/selectors";
import { selectAllProductUnits, selectAllPackageUnits } from "@ROM/Units/selectors";
import { selectAllWarehouseInventories } from "@ROM/WarehouseInventories/selectors";

const initialProduct = {
  name: "",
  description: "",
  isActive: true,
  featured: false,
  unitsPerPackage: 1,
  packagesPerPallet: 1,
  packageWeight: 0,
  palletWeightOverride: null,
  unit: "",
  packageUnit: "",
  assets: [],
  private: false,
  customerProductVisibilitiesAttributes: [],
  subcategoryIds: [],
};

const Page = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const [primaryAssetId, setPrimaryAssetId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAssetError, setShowAssetError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [product, updateState] = useReducer(reducer, initialProduct);
  const [warehouseIds, setWarehouseIds] = useState([]);

  const categories = useSelector(selectAllCategories);
  const subcategories = useSelector(selectAllSubcategories);
  const productUnits = useSelector(selectAllProductUnits);
  const packageUnits = useSelector(selectAllPackageUnits);
  const warehouseInventories = useSelector(selectAllWarehouseInventories);

  const updateWarehouseIds = (items) => {
    setWarehouseIds(items ? items.map((item) => item.value) : []);
  };

  useEffect(() => {
    setWarehouseIds(warehouseInventories.map((inventory) => `${inventory.attributes.warehouseId}`));
  }, [warehouseInventories]);

  useEffect(() => {
    dispatch(listCategories());
    dispatch(listUnits());
    dispatch(listWarehouses());
    dispatch(clearWarehouseInventories());
  }, []);

  const handleCancel = () => {
    history.goBack();
  };

  const handleSuccess = () => {
    setShowSuccess(true);
    setLoading(false);
    setShowAssetError(false);
    setErrorMsg(null);

    window.scroll(0, 0);

    setTimeout(() => history.push(companyAdminProductsUrl()), 1250);
  };

  const handleCreate = async () => {
    const { assets } = product;
    setLoading(true);
    const transformedCustomers = product.customerProductVisibilitiesAttributes.map(({ value }) => ({
      customerId: value,
    }));
    const finalProduct = { ...product, customerProductVisibilitiesAttributes: transformedCustomers };

    setErrorMsg(null);

    try {
      const response = await ProductsApi.create(finalProduct);
      const productId = response.data.data.id;
      let assetError = false;

      await Promise.all(warehouseIds.map((warehouseId) => dispatch(createWarehouseInventory({ productId, warehouseId }))));

      try {
        if (assets.length > 0) {
          await ProductsApi.createAsset(response.data.data.id, { assets });
        }
      } catch {
        assetError = true;
        setShowSuccess(false);
        setLoading(false);
        setShowAssetError(true);
        window.scroll(0, 0);
      }

      if (!assetError) {
        handleSuccess();
      }
    } catch (error) {
      setShowSuccess(false);
      setErrorMsg(error.data.detail || error.data[0].detail);
      setLoading(false);
      setShowAssetError(false);
      window.scroll(0, 0);
    }
  };
  const afterAssetUpload = (assets, setUploadingFiles) => {
    const currentAssets = [...product.assets, ...assets];
    updateState({ assets: currentAssets });
    setUploadingFiles(false);
  };

  const onDeleteAsset = (currentAsset, setRemovingFiles) => {
    const currentAssets = product.assets.filter((asset) => asset.id !== currentAsset.id);
    updateState({ assets: currentAssets });
    setRemovingFiles(false);
  };

  const makePrimary = (assetId) => {
    const primaryId = primaryAssetId;
    /* eslint-disable no-param-reassign */
    product.assets.forEach((asset) => {
      if (asset.id === assetId) {
        asset.isPrimary = true;
        setPrimaryAssetId(asset.id);
      } else if (asset.id === primaryId) {
        asset.isPrimary = false;
      }
    });
    /* eslint-enable no-param-reassign */
  };

  const breadcrumbs = productsBreadcrumbs([{ label: "Create", active: true }]);

  const AlertMessage = () => {
    if (showSuccess) {
      return <Message type="success" message="The product was successfully created." />;
    }
    if (showAssetError) {
      return <Message type="danger" message="The product has been created, but there was a problem creating the assets." />;
    }
    if (errorMsg) {
      return <Message type="danger" message={errorMsg} />;
    }
    return null;
  };

  return (
    <Section title="Create Product" breadcrumbs={breadcrumbs} containerStyle={{ maxWidth: "650px" }}>
      <AlertMessage />
      <ProductForm
        product={product}
        updateState={updateState}
        handleSubmit={handleCreate}
        handleCancel={handleCancel}
        loading={loading}
        selectedCustomers={product.customerProductVisibilitiesAttributes}
        handleCustomerSelect={(value) => updateState({ customerProductVisibilitiesAttributes: value })}
        categories={categories}
        subcategories={subcategories}
        productUnits={productUnits}
        packageUnits={packageUnits}
        warehouseIds={warehouseIds}
        setWarehouseIds={updateWarehouseIds}
      >
        <FileUploader
          afterAssetUpload={afterAssetUpload}
          deleteAsset={onDeleteAsset}
          currentAssets={product.assets}
          makePrimary={makePrimary}
          primaryAssetId={primaryAssetId}
        />
      </ProductForm>
    </Section>
  );
};

export default Page;
