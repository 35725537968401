import update from "immutability-helper";
import humps from "humps";
import { handle } from "redux-pack";
import {
  FIND,
  UPDATE,
  LIST,
  UPDATE_COMPANY_ROLES,
  ADD_TO_CUSTOMER,
  REMOVE_FROM_CUSTOMER,
  SET_PAGE,
  UPDATE_CUSTOMER_ADMIN_VALUE,
  UPDATE_DISABLED_NOTIFICATIONS,
} from "./actions";

const initialState = {
  all: {
    loading: false,
    items: [],
    included: [],
  },
  current: {
    user: null,
    included: [],
    loading: false,
  },
  updateError: false,
  pagination: {
    currentPage: 1,
    perPage: null,
    totalPages: null,
    totalRecords: null,
  },
};

const setLoadingCurrent = (prevState) => update(prevState, { current: { loading: { $set: true } } });
const setFailureCurrent = (json) => (prevState) =>
  update(prevState, { current: { loading: { $set: false } }, updateError: { $set: json.detail } });

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = humps.camelizeKeys(payload) || {};

  switch (type) {
    case FIND:
      return handle(state, action, {
        start: setLoadingCurrent,
        failure: setFailureCurrent(json),
        success: (prevState) => {
          return update(prevState, {
            current: {
              user: { $set: json.data },
              included: { $set: json.included },
              loading: { $set: false },
            },
            updateError: { $set: false },
          });
        },
      });

    case UPDATE:
      return handle(state, action, {
        start: setLoadingCurrent,
        failure: setFailureCurrent(json),
        success: (prevState) => {
          return update(prevState, {
            current: {
              user: { $set: json.data },
              included: { $set: json.included },
              loading: { $set: false },
            },
            updateError: { $set: false },
          });
        },
      });
    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              included: { $set: json.included },
              loading: { $set: false },
            },
            pagination: { $set: json.meta.pagination },
          });
        },
      });

    case UPDATE_COMPANY_ROLES:
    case UPDATE_DISABLED_NOTIFICATIONS:
      return handle(state, action, {
        start: setLoadingCurrent,
        failure: setFailureCurrent(json),
        success: (prevState) => {
          return update(prevState, {
            current: {
              user: { $set: json.data },
              loading: { $set: false },
            },
          });
        },
      });

    case ADD_TO_CUSTOMER:
      return handle(state, action, {
        start: setLoadingCurrent,
        failure: setFailureCurrent(json),
        success: (prevState) => {
          return update(prevState, {
            current: {
              user: { $set: json.data },
              included: { $set: json.included },
              loading: { $set: false },
            },
          });
        },
      });

    case REMOVE_FROM_CUSTOMER:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
            },
            current: {
              loading: { $set: true },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
            },
            current: {
              loading: { $set: false },
            },
            updateError: { $set: json.detail },
          });
        },
        success: (prevState) => {
          const {
            meta: { id: userId, customerId },
          } = action;
          const users = { ...prevState.all };
          users.items = users.items.filter((user) => user.id !== userId);
          let {
            current: { user, included },
          } = prevState;
          if (user?.id === userId) {
            included = included.filter((item) => item.type !== "customer" || item.id !== customerId);
            const filteredCustomers = user.relationships.customers.data.filter((item) => item.id !== customerId);
            user = {
              ...user,
              relationships: {
                ...user.relationships,
                customers: { data: filteredCustomers },
              },
            };
          }
          return update(prevState, {
            all: { $set: users },
            current: { user: { $set: user }, included: { $set: included }, loading: { $set: false } },
          });
        },
      });

    case SET_PAGE:
      return update(state, {
        pagination: {
          currentPage: { $set: payload.page },
        },
      });

    case UPDATE_CUSTOMER_ADMIN_VALUE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
            },
            current: {
              loading: { $set: true },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
            },
            current: {
              loading: { $set: false },
            },
            updateError: { $set: json.detail },
          });
        },

        success: (prevState) => {
          const userId = json.data.id;
          const updatedUsers = state.all.items.slice();
          const index = updatedUsers.findIndex((element) => element.id === userId);
          updatedUsers[index] = json.data;

          let {
            current: { user },
          } = prevState;
          if (user?.id === userId) {
            user = {
              ...user,
              attributes: {
                ...user.attributes,
                roles: json.data.attributes.roles,
              },
            };
          }

          return update(prevState, {
            all: {
              items: { $set: updatedUsers },
              updateError: { $set: false },
            },
            current: { user: { $set: user }, loading: { $set: false } },
          });
        },
      });

    default:
      return state;
  }
}

export default reducer;
