import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import Skeleton from "react-loading-skeleton";

import { canManageCategories, canCreateCategory } from "@ROM/Categories/permissions";
import { list } from "@ROM/Categories/actions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { isLoading, selectAllCategories, selectAllSubcategories } from "@ROM/Categories/selectors";
import { productsBreadcrumbs } from "@ROM/CompanyAdmin/utils";
import { homeUrl } from "@ROM-utils/urlHelpers";
import Section from "@ROM-components/common/Section";
import Category from "@ROM/Categories/components/Category";
import AddNewCategory from "@ROM/Categories/components/AddNewCategory";
import CategoriesEmpty from "@ROM/Categories/components/CategoriesEmpty";

const CompanyAdminCategories = () => {
  const dispatch = useDispatch();

  const [showSubcategories, setShowSubcategories] = useState(null);

  const currentUser = useSelector(selectCurrentUser);
  const loading = useSelector(isLoading);
  const categories = useSelector(selectAllCategories);
  const subcategories = useSelector(selectAllSubcategories);

  const breadcrumbs = productsBreadcrumbs([{ label: "Categories", active: true }]);

  useEffect(() => {
    dispatch(list());
  }, []);

  const currentSubcategories = (category) => {
    return subcategories.filter((subcat) => subcat.relationships.category.data.id === category.id);
  };

  const showSubcategoriesHandler = (categoryId) => {
    if (showSubcategories === categoryId) {
      setShowSubcategories(null);
    } else {
      setShowSubcategories(categoryId);
    }
  };

  if (!canManageCategories(currentUser)) {
    return <Redirect to={homeUrl()} />;
  }

  return (
    <Section title="Product Categories" breadcrumbs={breadcrumbs}>
      {loading ? (
        <Skeleton count={3} height={60} />
      ) : (
        <>
          {categories.length === 0 ? (
            <CategoriesEmpty />
          ) : (
            categories.map((category) => (
              <Category
                key={category.id}
                category={category}
                subcategories={currentSubcategories(category)}
                showSubcategories={showSubcategories === category.id}
                showSubcategoriesHandler={showSubcategoriesHandler}
              />
            ))
          )}
          {canCreateCategory(currentUser) && <AddNewCategory />}
        </>
      )}
    </Section>
  );
};

export default CompanyAdminCategories;
