import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Button from "@ROM-ui/Button";
import Modal from "@ROM-components/common/Modal";
import { update, list } from "@ROM/Deliveries/actions";
import { list as fetchOrders } from "@ROM/Orders/actions";
import { selectOrders } from "@ROM/Orders/selectors";
import { selectAllDeliveries, selectAllDeliveryItems } from "@ROM/Deliveries/selectors";
import OrderRow from "./OrderRow";
import DeliveryItemRow from "./DeliveryItemRow";

const IN_PROGRESS = "in_progress";

const AddOrderModal = ({ show, onCancel, currentBillOfLading }) => {
  const dispatch = useDispatch();
  const actions = {
    updateDelivery: (id, attributes) => dispatch(update(id, attributes)),
    fetchDeliveries: (options) => dispatch(list(options)),
    fetchOrders: (options) => dispatch(fetchOrders(options)),
  };

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [selectedOrderDeliveries, setSelectedOrderDeliveries] = useState([]);
  const [selectedOrderDeliveryItems, setSelectedOrderDeliveryItems] = useState([]);
  const selectedOrderId = selectedOrder?.id;

  const allOrders = useSelector(selectOrders);
  const deliveries = useSelector(selectAllDeliveries);
  const deliveryItems = useSelector(selectAllDeliveryItems);

  // Use only different orders from the same customers.
  const orderId = currentBillOfLading?.relationships.orders.data[0].id;
  const customerId = currentBillOfLading?.attributes.customerIds[0].toString();
  const orders = allOrders.filter((order) => order.id !== orderId && order.relationships.customer.data.id === customerId);

  useEffect(() => {
    if (!initialized && currentBillOfLading) {
      actions.fetchOrders({ customerId: currentBillOfLading.attributes.customerIds, status: IN_PROGRESS }).then(() => {
        setInitialized(true);
      });
    }
  }, [currentBillOfLading]);

  useEffect(() => {
    if (orders.length && show) {
      const orderIds = orders.map((order) => order.id);
      actions.fetchDeliveries({ orderId: orderIds });
    }
  }, [allOrders, show]);

  useEffect(() => {
    if (selectedOrder) {
      const selectedDeliveries = deliveries.filter((delivery) => delivery.attributes.orderId.toString() === selectedOrder.id);
      const selectedDeliveryItems = deliveryItems.filter((item) =>
        selectedDeliveries.map((delivery) => delivery.id).includes(item.attributes.deliveryId.toString())
      );

      setSelectedOrderDeliveries(selectedDeliveries);
      setSelectedOrderDeliveryItems(selectedDeliveryItems);
    }
  }, [selectedOrder]);

  const handleAddDelivery = (delivery) => {
    const updatedDelivery = { ...delivery };
    updatedDelivery.attributes.billOfLadingId = currentBillOfLading?.id;
    actions.updateDelivery(updatedDelivery.id, updatedDelivery.attributes);
  };

  return (
    <Modal
      isShowing={show}
      onCancel={() => onCancel()}
      showCancelButton={false}
      showConfirmButton={false}
      showCloseIcon={false}
      bodyClasses="p-0 h-100"
      size="x-large-y-large"
    >
      <Row className="m-0 p-0 h-100">
        <LeftCol xs={12} md={6} className="rounded pe-0 ps-0">
          <OrderRow />
          <StyledOrdersList>
            {!initialized ? (
              <Skeleton count={5} height={60} />
            ) : (
              orders.map((order) => (
                <OrderRow
                  key={order.id}
                  order={order}
                  setSelectedOrder={setSelectedOrder}
                  selectedOrderId={selectedOrderId}
                  deliveries={deliveries}
                />
              ))
            )}
          </StyledOrdersList>

          <StyledFooter>
            <Button variant="outline-primary" className="w-100" onClick={onCancel}>
              Done adding to Bill of Lading
            </Button>
          </StyledFooter>
        </LeftCol>
        <RightCol xs={12} md={6}>
          {selectedOrder ? (
            <div>
              {selectedOrderDeliveries.map((delivery, index) => {
                const currentItems = selectedOrderDeliveryItems.filter(
                  (item) => item.attributes.deliveryId.toString() === delivery.id
                );
                const hasBillOfLading = delivery.attributes.billOfLadingId !== null;
                return (
                  <div key={delivery.id}>
                    <DeliveryItemRow
                      index={index}
                      hasBillOfLading={hasBillOfLading}
                      delivery={delivery}
                      handleAddDelivery={handleAddDelivery}
                    />
                    {currentItems.map((deliveryItem) => (
                      <DeliveryItemRow key={deliveryItem.id} deliveryItem={deliveryItem} />
                    ))}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="d-flex justify-content-center h-100">
              <div className="text-center align-self-center">
                <h1>Select an order</h1>
                <h4 className="fw-light">To view delivery details</h4>
              </div>
            </div>
          )}
        </RightCol>
      </Row>
    </Modal>
  );
};

AddOrderModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  currentBillOfLading: PropTypes.shape().isRequired,
};

export default AddOrderModal;

const LeftCol = styled(Col)`
  max-height: 75vh;
`;

const RightCol = styled(Col)`
  background-color: #353036;
  color: white;
  max-height: 75vh;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

const StyledOrdersList = styled.div`
  overflow-y: auto;
  scroll-behavior: smooth;
  height: 57vh;
`;

const StyledFooter = styled.div`
  box-shadow: 0 2px 13px -8px grey;
  padding: 1rem;
  background-color: #f8f8f8;
  width: 100%;
`;
