import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/orders";
  },

  resource(id) {
    return `/orders/${id}`;
  },

  charge(id) {
    return `/orders/${id}/charge`;
  },

  transactions(id) {
    return `/orders/${id}/transactions`;
  },

  refund(id) {
    return `/orders/${id}/refund`;
  },

  trial(id) {
    return `/orders/${id}/trial`;
  },
};

export default {
  cart(customer) {
    const client = getApiClient(API_URL);
    return client.get(`${urls.collection()}/cart`, {
      params: {
        customerId: customer.id,
      },
    });
  },

  list(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(), {
      params: {
        ...options,
      },
    });
  },

  find(id) {
    const client = getApiClient(API_URL);
    return client.get(urls.resource(id));
  },

  create(attributes) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(), attributes);
  },

  update(id, attributes) {
    const client = getApiClient(API_URL);
    return client.patch(urls.resource(id), attributes);
  },

  destroy(id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(id));
  },

  deleteAsset(id, assetId) {
    const client = getApiClient(API_URL);
    return client.delete(`${urls.resource(id)}/assets/${assetId}`);
  },

  charge(id, attributes = {}) {
    const client = getApiClient(API_URL);
    return client.post(urls.charge(id), attributes);
  },

  transactions(id) {
    const client = getApiClient(API_URL);
    return client.get(urls.transactions(id));
  },

  refund(id, attributes) {
    const client = getApiClient(API_URL);
    return client.post(urls.refund(id), attributes);
  },

  trial(id, isTrial) {
    const client = getApiClient(API_URL);
    return client.put(urls.trial(id), { isTrial });
  },
};
