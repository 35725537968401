import { apiAction } from "@ROM-utils/actions";
import StatusesApi from "./api";

// API method types to select which API endpoint to post to
export const OPEN = "order_statuses/types/OPEN";
export const APPROVE = "order_statuses/types/APPROVE";
export const REJECT = "order_statuses/types/REJECT";
export const CONFIRM = "order_statuses/types/CONFIRM";
export const IN_PROGRESS = "order_statuses/types/IN_PROGRESS";
export const SHIP = "order_statuses/types/SHIP";
export const COMPLETE = "order_statuses/types/COMPLETE";
export const INVOICE = "order_statuses/types/INVOICE";
export const CANCEL = "order_statuses/types/CANCEL";
export const ADD_NOTE = "order_statuses/types/ADD_NOTE";

export function open(attributes = {}) {
  return apiAction(OPEN, StatusesApi.open(attributes));
}

export function approve(attributes = {}) {
  return apiAction(APPROVE, StatusesApi.approve(attributes));
}

export function reject(attributes = {}) {
  return apiAction(REJECT, StatusesApi.reject(attributes));
}

export function confirm(attributes = {}) {
  return apiAction(CONFIRM, StatusesApi.confirm(attributes));
}

export function inProgress(attributes = {}) {
  return apiAction(IN_PROGRESS, StatusesApi.inProgress(attributes));
}

export function ship(attributes = {}) {
  return apiAction(SHIP, StatusesApi.ship(attributes));
}

export function complete(attributes = {}) {
  return apiAction(COMPLETE, StatusesApi.complete(attributes));
}

export function invoice(attributes = {}) {
  return apiAction(INVOICE, StatusesApi.invoice(attributes));
}

export function cancel(attributes = {}) {
  return apiAction(CANCEL, StatusesApi.cancel(attributes));
}

export function addNote(name, attributes = {}) {
  return apiAction(ADD_NOTE, StatusesApi.addNote(name, attributes));
}
