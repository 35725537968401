import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CheckBoxSquare = ({ check, className }) => {
  const iconClass = check ? "far fa-check-square" : "far fa-square";

  return <Icon className={`${iconClass} ${className}`} $check={check} />;
};

const Icon = styled.div`
  color: ${(props) => (props.$check ? "#70B6EB" : "inherit")};
  background-color: white;
`;

CheckBoxSquare.defaultProps = {
  check: false,
  className: "",
};

CheckBoxSquare.propTypes = {
  check: PropTypes.bool,
  className: PropTypes.string,
};

export default CheckBoxSquare;
