import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { ActiveBadge, DefaultBadge } from "@ROM-ui/Badge";
import { companyAdminPriceListShowUrl } from "@ROM-utils/urlHelpers";
import { canViewPriceList } from "@ROM/PriceLists/permissions";

const PriceListRow = ({ priceList }) => {
  moment.locale("en-us");

  const history = useHistory();

  const currentUser = useSelector(selectCurrentUser);

  const dateFrom = priceList && priceList.attributes.dateFrom;
  const dateTo = priceList && priceList.attributes.dateTo;

  const handleOnRowClick = () => {
    if (canViewPriceList(currentUser)) {
      history.push(companyAdminPriceListShowUrl(priceList.id));
    }
  };

  return (
    <tr className={canViewPriceList(currentUser) ? "cursor-pointer" : ""} onClick={handleOnRowClick}>
      <td>{priceList.attributes.name}</td>
      <td>
        <ActiveBadge label={priceList.attributes.status} isActive={priceList.attributes.status === "active"} className="me-2" />
        {priceList.attributes.isDefaultPriceList && <DefaultBadge />}
      </td>
      <td>{dateFrom ? moment(priceList.attributes.dateFrom).format("MMM DD, YYYY") : ""}</td>
      <td>{dateTo ? moment(priceList.attributes.dateTo).format("MMM DD, YYYY") : ""}</td>
    </tr>
  );
};

PriceListRow.propTypes = {
  priceList: PropTypes.shape().isRequired,
};

export default PriceListRow;
