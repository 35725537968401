import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import Badge from "@ROM-ui/Badge";
import Button from "@ROM-ui/Button";

const InvitationRow = ({ invitation, onDeleteClick, onResendClick }) => {
  return (
    <tr>
      <td>{invitation?.attributes?.accepted ? <Badge bg="success">Accepted</Badge> : <Badge bg="primary">Pending</Badge>}</td>
      <td>{invitation?.attributes?.fullName}</td>
      <td>{invitation?.attributes?.email}</td>
      <td>{invitation?.attributes?.role}</td>
      <td>{invitation?.attributes?.customerName}</td>
      <td>{moment(invitation?.attributes?.createdAt).format("MM/DD/YYYY")}</td>
      <td className="text-end">
        {!invitation?.attributes?.accepted && (
          <div className="text-nowrap">
            {onResendClick && (
              <Button variant="link" onClick={() => onResendClick(invitation?.id)}>
                Resend
              </Button>
            )}
            {onDeleteClick && (
              <Button
                variant="link"
                id={`customer-edit-delete-invitation-${invitation?.id}`}
                onClick={() => onDeleteClick(invitation?.id)}
              >
                <i className="fas fa-trash text-danger m-1" />
              </Button>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

InvitationRow.defaultProps = {
  onDeleteClick: null,
  onResendClick: null,
};

InvitationRow.propTypes = {
  invitation: PropTypes.shape({
    id: PropTypes.string,
    attributes: {
      fullName: PropTypes.string,
      createdAt: PropTypes.string,
      email: PropTypes.string,
      rule: PropTypes.string,
      accepted: PropTypes.bool,
    },
  }).isRequired,
  onDeleteClick: PropTypes.func,
  onResendClick: PropTypes.func,
};

export default InvitationRow;
