import update from "immutability-helper";
import humps from "humps";
import { handle } from "redux-pack";
import {
  LIST,
  UPDATE,
  CREATE,
  DESTROY,
  CLEAR_ERRORS,
  LIST_WITH_SCROLL,
  SET_PAGE,
  BILLING_TYPE,
  FETCH_COUNTRIES,
} from "./actions";

const initialState = {
  loading: false,
  createErrors: [],
  shipping: {
    all: [],
    pagination: {
      currentPage: 1,
      perPage: null,
      totalPages: null,
      totalRecords: null,
    },
  },
  billing: {
    all: [],
    pagination: {
      currentPage: 1,
      perPage: null,
      totalPages: null,
      totalRecords: null,
    },
  },
  countries: [],
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};

  switch (type) {
    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const addresses = json.data;
          const addressType = action.meta.type === BILLING_TYPE ? "billing" : "shipping";

          return update(prevState, {
            loading: { $set: false },
            [addressType]: {
              all: { $set: addresses },
              pagination: { $set: { ...prevState[addressType].pagination, ...json.meta.pagination[addressType] } },
            },
          });
        },
      });

    case SET_PAGE: {
      const addressType = action.meta.type === BILLING_TYPE ? "billing" : "shipping";
      return update(state, {
        [addressType]: {
          pagination: {
            currentPage: { $set: payload.page },
          },
        },
      });
    }

    case LIST_WITH_SCROLL:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const addressType = action.meta.type === BILLING_TYPE ? "billing" : "shipping";
          let addresses = prevState[addressType].all;
          const ids = new Set(addresses.map((address) => address.id));

          addresses = [
            ...addresses.filter((address) => address.relationships.customer.data.id === action.meta.customerId),
            ...json.data.filter((address) => !ids.has(address.id)),
          ];

          return update(prevState, {
            [addressType]: {
              all: { $set: addresses },
              pagination: { $set: { ...prevState[addressType].pagination, ...json.meta.pagination[addressType] } },
            },
          });
        },
      });

    case CREATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            createErrors: { $set: [] },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            createErrors: { $set: json },
          });
        },

        success: (prevState) => {
          const addressType = action.meta.type === BILLING_TYPE ? "billing" : "shipping";
          if (action.meta.insert) {
            const addressArray = prevState[addressType].all.slice();
            addressArray.push(json.data);
            addressArray.sort((element1, element2) => element1.attributes.contact.localeCompare(element2.attributes.contact));

            return update(prevState, {
              [addressType]: {
                all: { $set: addressArray },
                pagination: { totalRecords: { $set: prevState[addressType].pagination.totalRecords + 1 } },
              },
            });
          }
          return update(prevState, { createErrors: { $set: [] } });
        },
      });

    case UPDATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            createErrors: { $set: [] },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            createErrors: { $set: json },
          });
        },

        success: (prevState) => {
          const addressType = json.data.attributes.type === BILLING_TYPE ? "billing" : "shipping";
          const addresses = prevState[addressType].all;
          const index = addresses.findIndex((address) => json.data.id === address.id);

          return update(prevState, {
            [addressType]: {
              all: {
                [index]: { $set: json.data },
              },
            },
          });
        },
      });

    case DESTROY:
      return handle(state, action, {
        success: (prevState) => {
          const addressType = action.meta.type === BILLING_TYPE ? "billing" : "shipping";
          const addresses = prevState[addressType].all;
          const index = addresses.findIndex((address) => action.meta.addressId === address.id);

          return update(prevState, {
            [addressType]: {
              all: { $splice: [[index, 1]] },
              pagination: { totalRecords: { $set: prevState[addressType].pagination.totalRecords - 1 } },
            },
          });
        },
      });

    case CLEAR_ERRORS: {
      return update(state, {
        createErrors: { $set: [] },
      });
    }

    case FETCH_COUNTRIES: {
      return handle(state, action, {
        success: (prevState) => {
          return update(prevState, {
            countries: { $set: humps.camelizeKeys(json.data) },
          });
        },
      });
    }

    default:
      return state;
  }
}

export default reducer;
