export const calculateInitialState = (warehouseInventories, products) => {
  return warehouseInventories
    .map((inventory) => ({
      id: inventory.id,
      ...inventory.attributes,
      product: products[inventory.attributes.productId],
    }))
    .sort((a, b) => a.product.name.localeCompare(b.product.name));
};

export const unitsPerPallet = (product) => +(product.packagesPerPallet || 0) * +(product.unitsPerPackage || 0);

export const prepareToSend = (warehouseInventories) => {
  const keys = ["warehouseId", "productId", "pallets", "palletsLeft", "units", "unitsLeft"];
  const minimum = (obj) => Object.assign({}, ...keys.map((key) => ({ [key]: obj[key] })));
  const fixFloats = (inventory) => ({
    ...inventory,
    pallets: inventory.pallets.toString() || "0",
    palletsLeft: inventory.palletsLeft.toString() || "0",
    units: inventory.units.toString() || "0",
    unitsLeft: inventory.unitsLeft.toString() || "0",
  });

  return warehouseInventories.map((item) => fixFloats(minimum(item)));
};

export const warehouseInventoriesReducer = (state, action) => {
  const fieldTransform = (inventory, valueStr, isPallet) => {
    if (valueStr && valueStr.match(/^((-{0,1})|(-{0,1}[0-9][0-9]*\.{0,1}[0-9]*))$/) === null) {
      return inventory;
    }
    const valueFloat = Number.parseFloat(valueStr);
    const value = Number.isNaN(valueFloat) ? 0 : valueFloat;

    if (isPallet) {
      return {
        ...inventory,
        pallets: valueStr,
        palletsLeft: value.toString(),
        units: (value * unitsPerPallet(inventory.product)).toString(),
        unitsLeft: (value * unitsPerPallet(inventory.product)).toString(),
      };
    }
    return {
      ...inventory,
      units: valueStr,
      unitsLeft: value,
    };
  };

  if (action.type === "reset") {
    return action.initialState;
  }
  if (action.type === "palletsUpdate") {
    return state.map((inventory) =>
      inventory.id === action.inventoryId ? fieldTransform(inventory, action.pallets, true) : inventory
    );
  }
  if (action.type === "unitsUpdate") {
    return state.map((inventory) =>
      inventory.id === action.inventoryId ? fieldTransform(inventory, action.units, false) : inventory
    );
  }
  return state;
};
