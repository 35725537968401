import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchCompanyRoles } from "@ROM/CompanyRoles/actions";
import { fetchNotifications } from "@ROM/Notifications/actions";
import { fetchPermissions } from "@ROM/Permissions/actions";
import { selectCompanyRoles } from "@ROM/CompanyRoles/selectors";
import { selectNotifications } from "@ROM/Notifications/selectors";
import { selectPermissions } from "@ROM/Permissions/selectors";
import CompanyRolesList from "@ROM/CompanyAdmin/Company/Roles/CompanyRolesList";
import PermissionsList from "@ROM/CompanyAdmin/Company/Roles/PermissionsList";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import NotificationsList from "./NotificationsList";

const CompanyRoles = () => {
  const dispatch = useDispatch();
  const companyRoles = useSelector(selectCompanyRoles);
  const notifications = useSelector(selectNotifications);
  const permissions = useSelector(selectPermissions);

  const [activeRoleId, setActiveRoleId] = useState(null);

  useEffect(() => {
    dispatch(fetchCompanyRoles());
    dispatch(fetchNotifications());
    dispatch(fetchPermissions());
  }, []);

  return (
    <Row>
      <Col xs={12} lg={4}>
        <CompanyRolesList companyRoles={companyRoles} activeRoleId={activeRoleId} setActiveRoleId={setActiveRoleId} />
      </Col>
      {activeRoleId ? (
        <>
          <Col xs={12} lg={4}>
            <PermissionsList permissions={permissions} activeRoleId={activeRoleId} />
          </Col>
          <Col xs={12} lg={4}>
            <NotificationsList notifications={notifications} activeRoleId={activeRoleId} />
          </Col>
        </>
      ) : (
        <Col xs={12} lg={8}>
          <h3 className="mb-4">No role selected</h3>
          <p>Select a role to manage permissions and notifications</p>
        </Col>
      )}
    </Row>
  );
};

export default CompanyRoles;
