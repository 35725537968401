import React, { useContext } from "react";
import PropTypes from "prop-types";
import TitledHeader from "@ROM-components/common/TitledHeader";

import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import NumberFormater from "@ROM-ui/NumberFormater";
import Spinner from "@ROM-ui/Spinner";
import useIsMobile from "@ROM-components/utils/Responsive";

import styled from "styled-components";

const Header = ({ orders, loading, pagination }) => {
  const customer = useContext(CustomerContext);
  const isMobile = useIsMobile();

  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const totalAmount = orders.map((order) => order.attributes.totalCents).reduce(reducer, 0);

  return (
    <StyledDiv isMobile={isMobile}>
      <TitledHeader titleClasses="text-dark" title={customer?.attributes?.name} backLinkTitle="Go back" className="w-40" />
      <div className="d-flex">
        <div className="my-4 text-dark me-3">
          <h6>Current orders</h6>
          {loading ? <Spinner animation="grow" variant="secondary" size="sm" /> : <h1>{pagination.totalRecords || "0"}</h1>}
        </div>
        <div className="my-4 text-dark ms-3">
          <h6>Current total</h6>
          {loading ? (
            <Spinner animation="grow" variant="secondary" size="sm" />
          ) : (
            <h1>
              <NumberFormater value={totalAmount} />
            </h1>
          )}
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  margin-top: 4rem;
  flex-direction: ${(props) => (props.isMobile ? "column" : "")};
`;

Header.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape().isRequired,
};

export default Header;
