import React from "react";
import humps from "humps";
import PropTypes from "prop-types";
import styled from "styled-components";

import UIBadge from "@ROM-ui/Badge";
import {
  COMPLETED,
  CANCELLED,
  ABANDONED,
  REJECTED,
  PROGRESS,
  OPENED,
  CART,
  APROVED,
  CONFIRMED,
  SHIPPED,
  INVOICED,
} from "../constants";

const Badge = styled(UIBadge)`
  color: white;
  background-color: ${(props) => props.$bgColor} !important;
`;

const getBgColor = (status) => {
  switch (humps.camelize(status)) {
    case CART:
      return "#94a3b8";
    case OPENED:
      return "#2dd4bf";
    case PROGRESS:
      return "#f4a261";
    case CONFIRMED:
      return "#e9c46a";
    case SHIPPED:
      return "#e76f51";
    case APROVED:
      return "#264653";
    case INVOICED:
      return "#2a9d8f";
    case COMPLETED:
      return "#8ab17d";
    case CANCELLED:
    case ABANDONED:
    case REJECTED:
      return "#991b1b";
    default:
      return "#475569";
  }
};

const OrderStatusBadge = ({ status, className }) => {
  return (
    <Badge className={`text-uppercase ${className}`} $bgColor={getBgColor(status)}>
      {humps.camelize(status) === PROGRESS ? "in progress" : status}
    </Badge>
  );
};

OrderStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

OrderStatusBadge.defaultProps = {
  className: "",
};

export default OrderStatusBadge;
