import { standardAction, apiAction } from "@ROM-utils/actions";
import CustomersApi from "./api";

export const LIST = "customers/api/LIST";
export const SESSION_SET_CUSTOMER = "customers/app/SESSION_SET_CUSTOMER";
export const FETCH_CUSTOMER = "customers/api/FETCH_CUSTOMER";
export const SET_PAGE = "customers/api/SET_PAGE";
export const DESTROY = "customers/api/DESTROY";
export const FIND = "customers/api/FIND";
export const UPDATE = "customers/api/UPDATE";
export const CREATE = "customers/api/CREATE";
export const CREATE_ASSETS = "customers/api/CREATE_ASSETS";
export const DELETE_ASSET = "customers/api/DELETE_ASSET";
export const EDIT_ASSET = "customers/api/EDIT_ASSET";

export function list(options = {}) {
  return apiAction(LIST, CustomersApi.list(options));
}

export function setCustomerForOrder(customer) {
  return standardAction(SESSION_SET_CUSTOMER, customer);
}

export function fetchCustomer() {
  return apiAction(FETCH_CUSTOMER, CustomersApi.fetchCustomer());
}

export function find(id) {
  return apiAction(FIND, CustomersApi.find(id));
}

export function update(id, attributes = {}) {
  return apiAction(UPDATE, CustomersApi.update(id, attributes));
}

export function create(attributes = {}) {
  return apiAction(CREATE, CustomersApi.create(attributes));
}

export function getFilteredCustomers(filters, page) {
  return list({
    ...filters,
    page,
  });
}

export function setPage(page) {
  return standardAction(SET_PAGE, { page });
}

export function destroy(id) {
  return apiAction(DESTROY, CustomersApi.destroy(id), { customerId: id });
}

export function createAssets(id, { assets }) {
  return apiAction(CREATE_ASSETS, CustomersApi.createAssets(id, assets));
}

export function deleteAsset(id, assetId) {
  return apiAction(DELETE_ASSET, CustomersApi.deleteAsset(id, assetId));
}

export function editAsset(id, assetId, attributes = {}) {
  return apiAction(EDIT_ASSET, CustomersApi.editAsset(id, assetId, attributes));
}
