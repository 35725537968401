import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection(customTableId) {
    return `/custom_tables/${customTableId}/custom_table_rows`;
  },
  resource(customTableId, id) {
    return `/custom_tables/${customTableId}/custom_table_rows/${id}`;
  },
};

export default {
  listRows(customTableId) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(customTableId));
  },

  createRow(customTableId, rowData) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(customTableId), rowData);
  },

  updateRow(customTableId, id, rowData) {
    const client = getApiClient(API_URL);
    return client.patch(urls.resource(customTableId, id), rowData);
  },

  destroyRow(customTableId, id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(customTableId, id));
  },
};
