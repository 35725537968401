/* eslint-disable import/prefer-default-export */
import { createSelector } from "reselect";

const companyRolesState = (state) => state.companyRoles;

export const selectCompanyRoles = createSelector(companyRolesState, (companyRoles) => companyRoles.all.items);

export const selectIncludedCompanyRoles = createSelector(companyRolesState, (companyRoles) => companyRoles.all.included);

export const selectPermissionsForCompanyRole = (roleId) =>
  createSelector(selectCompanyRoles, selectIncludedCompanyRoles, (companyRoles, included) => {
    const role = companyRoles.find((companyRole) => companyRole.id === roleId);
    const companyRolesPermissionsIds = role.relationships.companyRolesPermissions.data.map((e) => e.id);
    const companyRolesPermissions = included.filter(
      (e) => e.type === "companyRolesPermission" && companyRolesPermissionsIds.includes(e.id)
    );

    return companyRolesPermissions.map((e) => ({ id: e.relationships.permission.data.id, scope: e.attributes.scope }));
  });

export const selectNotificationsForCompanyRole = (roleId) =>
  createSelector(selectCompanyRoles, selectIncludedCompanyRoles, (companyRoles, included) => {
    const role = companyRoles.find((companyRole) => companyRole.id === roleId);
    const companyRolesNotificationsIds = role.relationships.companyRolesNotifications.data.map((e) => e.id);
    const companyRolesNotifications = included.filter(
      (e) => e.type === "companyRolesNotification" && companyRolesNotificationsIds.includes(e.id)
    );

    return companyRolesNotifications.map((e) => ({
      id: e.relationships.notification.data.id,
      scope: e.attributes.scope,
    }));
  });
