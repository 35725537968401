import React from "react";
import PropTypes from "prop-types";

import { deliveryItemsWeight } from "@ROM/Deliveries/utils";
import { TRUCK_CARGO_LIMIT_LBS } from "@ROM/App/constants";
import { numberFormat } from "@ROM/App/utils/format";

const DeliveryWeightWarning = ({ deliveryItems, className }) => {
  const deliveryWeight = deliveryItemsWeight(deliveryItems);
  const weightExceeded = deliveryWeight > TRUCK_CARGO_LIMIT_LBS;

  return weightExceeded ? (
    <div className={`alert alert-danger ${className}`}>
      <i className="fa fa-exclamation-circle me-2" />
      {`Estimated delivery weight (${numberFormat(deliveryWeight)} lbs)`}
      {` might exceed Maximum Cargo Limit of ${numberFormat(TRUCK_CARGO_LIMIT_LBS)} lbs.`}
    </div>
  ) : null;
};

DeliveryWeightWarning.propTypes = {
  deliveryItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  className: PropTypes.string,
};

DeliveryWeightWarning.defaultProps = {
  className: "",
};

export default DeliveryWeightWarning;
