/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import CustomersApi from "@ROM/Customers/api";
import AddCard from "@ROM/Payments/components/AddCard";
import Spinner from "@ROM-ui/Spinner";
import Modal from "@ROM-components/common/Modal";
import Button from "@ROM-ui/Button";

const ListCards = ({ addCard, deleteCard, customer }) => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDestroyConfirmationModal, setShowDestroyConfirmationModal] = useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);
  const [deletingCardId, setDeletingCardId] = useState(null);
  const [newCardAdded, setNewCardAdded] = useState(null);

  const getCustomerPaymentMethods = async () => {
    setLoading(true);

    try {
      const response = await CustomersApi.listPaymentMethods(customer.id);
      setCards(response.data.data);
    } finally {
      setLoading(false);
    }
  };

  const deleteCardHandler = async (cardId) => {
    setShowDestroyConfirmationModal(false);
    setDeletingCardId(cardId);

    try {
      await CustomersApi.deletePaymentMethod(customer.id, { paymentMethodId: cardId });
      getCustomerPaymentMethods();
    } finally {
      setDeletingCardId(null);
    }
  };

  const onClickDelete = (cardId) => {
    setCardToDelete(cardId);
    setShowDestroyConfirmationModal(true);
  };

  useEffect(() => {
    getCustomerPaymentMethods();
    setShowAddCardModal(false);
  }, [newCardAdded]);

  return (
    <>
      <h4 className="mb-3">Billing information</h4>
      <PaymentMethodsContainer hasCards={cards.length > 0}>
        {loading ? (
          <Skeleton count={3} height={45} />
        ) : (
          <div className="payment-methods-container">
            {cards.length === 0 ? (
              <div className="empty-message">There are no payment methods added yet.</div>
            ) : (
              cards.map((card) => {
                const isDeleting = deletingCardId === card.id;
                return (
                  <div className="credit-card-container" key={card.id}>
                    <div>{`•••• •••• •••• ${card.attributes.lastFourDigits}`}</div>
                    <div className="d-flex align-items-center">
                      <div className="card-expiration">{`${card.attributes.expMonth}/${card.attributes.expYear}`}</div>
                      {deleteCard && (
                        <div>
                          <div className="ms-2">
                            {isDeleting ? (
                              <Spinner animation="border" variant="secondary" size="sm" />
                            ) : (
                              <i
                                role="button"
                                aria-label="Delete card"
                                tabIndex={0}
                                className="fas fa-trash cursor-pointer text-danger"
                                onClick={() => onClickDelete(card.id)}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            )}
            <Modal
              isShowing={showDestroyConfirmationModal}
              onConfirm={() => deleteCardHandler(cardToDelete)}
              onCancel={() => setShowDestroyConfirmationModal(false)}
              header="Are you sure you want to remove the credit card?"
              showCloseIcon={false}
            />
          </div>
        )}
        {addCard && (
          <>
            <Button className="w-100 mt-2" variant="outline-dark" onClick={() => setShowAddCardModal(true)}>
              Add new payment method
            </Button>
            <Modal isShowing={showAddCardModal} showConfirmButton={false} showCancelButton={false} showCloseIcon={false}>
              <AddCard
                onCancel={() => setShowAddCardModal(false)}
                customer={customer}
                classNameContainer="py-3"
                setNewCardId={setNewCardAdded}
              />
            </Modal>
          </>
        )}
      </PaymentMethodsContainer>
    </>
  );
};

const PaymentMethodsContainer = styled.div`
  .payment-methods-container {
    width: 100%;
    display: ${(props) => (props.hasCards ? "inline-block" : "block")};

    .empty-message {
      min-height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      text-align: center;
      font-size: 1.1rem;
      background-color: #f3f4f6;
      border-radius: 5px;
    }

    .credit-card-container {
      background-color: #f3f4f6;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 1rem;
      border-radius: 5px;
      margin-bottom: 0.5rem;
      width: 100%;

      .card-expiration {
        font-size: 0.875rem;
      }
    }
  }
`;

ListCards.defaultProps = {
  addCard: true,
  deleteCard: true,
};

ListCards.propTypes = {
  addCard: PropTypes.bool,
  deleteCard: PropTypes.bool,
  customer: PropTypes.shape().isRequired,
};

export default ListCards;
