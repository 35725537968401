const TEMPERATURE_CONTROLLED_MESSAGE = "Temperature Controlled at 65 degrees F";
const BOL_HEADER = [
  "No. Shipping Units",
  "Kind of packaging, Description of Articles, Special Marks and Exceptions (List Hazardous Materials first)",
  "Weight (Subject to Correction)",
];

const BOL_REGULATION =
  "This is to certify that the above-named articles are properly classified, described, packaged, marked, and labeled, and are in proper condition for transportation, according to the applicable regulations of the Department of Transportation.";

const TRUCK_CARGO_LIMIT_LBS = 42500;

export { TEMPERATURE_CONTROLLED_MESSAGE, BOL_HEADER, BOL_REGULATION, TRUCK_CARGO_LIMIT_LBS };

const constants = {
  date: {
    MMDDYYYY: "MM/DD/YYYY",
  },
};

export default constants;
