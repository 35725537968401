import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { companyAdminCustomersCreateUrl, companyAdminCustomersManageUrl } from "@ROM-utils/urlHelpers";
import { canCreateCustomers } from "@ROM/Customers/permissions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectAllCustomers, selectCustomersPagination, selectIncludedData } from "@ROM/Customers/selectors";
import { getFilteredCustomers, setPage } from "@ROM/Customers/actions";
import Section from "@ROM-components/common/Section";
import CustomersLists from "@ROM/Customers/components/List";
import Filters from "@ROM/Customers/components/List/Filters";
import Pagination from "@ROM-components/common/Pagination";
import Button from "@ROM-ui/Button";
import { useFilters } from "@ROM/App/utils/filters";

const CompanyAdminCustomers = () => {
  const currentUser = useSelector(selectCurrentUser);
  const customers = useSelector(selectAllCustomers);
  const pagination = useSelector(selectCustomersPagination);
  const includedData = useSelector(selectIncludedData);

  const dispatch = useDispatch();
  const getCustomers = (filters, page) => dispatch(getFilteredCustomers(filters, page));

  const initialFilters = {
    name: null,
    invoiceNumberPrefix: null,
    sort: null,
    isActive: true,
  };

  const history = useHistory();

  const callback = useCallback(async (page = 1) => {
    getCustomers(filters, page), [filters];
  });
  const [loading, filters, handleFilterChange, handleClearFilters, handlePageClick] = useFilters(
    callback,
    initialFilters,
    setPage
  );

  const onCreateClick = () => {
    history.push(companyAdminCustomersCreateUrl());
  };

  const onEditClick = (id) => {
    history.push(companyAdminCustomersManageUrl(id));
  };

  const handleColumnSort = (sortField) => {
    handleFilterChange("sort", `${filters.sort === sortField ? `-${sortField}` : sortField}`);
  };

  const priceList = (customer) => {
    const priceListId = customer.relationships.priceList.data && customer.relationships.priceList.data.id;
    const priceListType = customer.relationships.priceList.data && customer.relationships.priceList.data.type;

    if (priceListId && priceListType) {
      return includedData.find((data) => data.id === priceListId && data.type === priceListType);
    }

    return {};
  };

  const createAction = (
    <Button id="company-admin-create-customer" onClick={onCreateClick}>
      Create Customer
    </Button>
  );

  return (
    <Section title="Customers" actions={canCreateCustomers(currentUser) && createAction}>
      <Filters handleFilterChange={handleFilterChange} handleClearFilters={handleClearFilters} filters={filters} />
      <CustomersLists
        customers={!loading ? customers : []}
        loading={loading}
        filters={filters}
        priceList={priceList}
        handleColumnSort={handleColumnSort}
        onCustomerEdit={onEditClick}
      />
      <Pagination pagination={pagination} handlePageClick={handlePageClick} />
    </Section>
  );
};

export default CompanyAdminCustomers;
