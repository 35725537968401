import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import styled from "styled-components";

import Table from "@ROM-ui/Table";
import { useNavBarHeight } from "@ROM-components/utils/Responsive";

const AdminTable = ({ header, isLoading, children }) => {
  const navbarHeight = useNavBarHeight();

  return (
    <div className="mb-2">
      <StyledTable borderless responsive="md" $stickyTop={navbarHeight}>
        {header && <thead>{header}</thead>}
        {!isLoading && <tbody>{children}</tbody>}
      </StyledTable>
      {isLoading && <Skeleton count={4} height={45} />}
    </div>
  );
};

const StyledTable = styled(Table)`
  overflow-y: hidden;
  overflow-x: auto;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  margin-bottom: 0;

  td {
    padding: 1.25rem;
  }
  thead tr th {
    color: rgb(121, 138, 163);
    font-weight: normal;
    background-color: white;
    border-bottom: 1px solid rgb(121, 138, 163);
    position: sticky;
    top: ${(props) => `${props.$stickyTop}px`};
  }
  @media (max-width: 768px) {
    thead tr th {
      top: 0;
    }
  }
  tbody {
    overflow-y: auto;
  }
  tbody tr:nth-of-type(even) td {
    background-color: rgb(248, 248, 248) !important;
  }
`;

AdminTable.defaultProps = {
  header: null,
  isLoading: false,
  children: null,
};

AdminTable.propTypes = {
  header: PropTypes.node,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default AdminTable;
