import React from "react";
import PropTypes from "prop-types";

import { isBeingSort } from "@ROM-utils/filters";

const ProductTableHeader = ({ handleFilterChange, filters }) => {
  return (
    <tr>
      <th onClick={() => handleFilterChange("sort", `${filters.sort === "name" ? "-name" : "name"}`)} className="cursor-pointer">
        Product
        <i className={isBeingSort(filters, "name")} />
      </th>
      <th md={8}>Status</th>
    </tr>
  );
};

ProductTableHeader.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};

export default ProductTableHeader;
