import styled from "styled-components";

const Panel = styled.div`
  background: #fff;
  border-radius: 7px;
  padding: 1rem;
  -webkit-box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15);
  position: relative;
`;

export default Panel;
