import { checkPermission } from "@ROM/Permissions/utils";
import { customerRelatedUser } from "@ROM/Customers/utils";

export const canAccessCustomersInCompanyAdmin = (user) => checkPermission(user, "company:admin:customers");

export const canCreateCustomers = (user) => checkPermission(user, "customers:create");

export const canUpdateCustomers = (user, customerId) => checkPermission(user, "customers:update", customerId);

export const canUpdateCustomersAllParams = (user) => checkPermission(user, "customers:update:all-params");

export const canDeleteCustomers = (user, customerId) => checkPermission(user, "customers:delete", customerId);

export const canAddPaymentMethod = (user, customerId) =>
  checkPermission(user, "customers:payment-methods:add", customerId) || customerRelatedUser(user, customerId);

export const canListPaymentMethods = (user, customerId) =>
  checkPermission(user, "customers:payment-methods:list", customerId) || customerRelatedUser(user, customerId);

export const canDeletePaymentMethod = (user, customerId) => checkPermission(user, "customers:payment-methods:delete", customerId);

export const canManageCustomerAttachments = (user, customerId) => checkPermission(user, "customers:attachments", customerId);
