export const paginationParts = (pages, currentPage) => {
  const start = Math.max(1, currentPage - 3);
  const end = Math.min(pages, currentPage + 3);

  const middle = Array.from({ length: end - start + 1 }, (_, i) => (i + start).toString());

  const pagesS = pages.toString();
  let head = [];
  if (start === 2) head = ["1"];
  else if (start > 2) head = ["1", "..."];
  let tail = [];
  if (end === pages - 1) tail = [pagesS];
  else if (end < pages - 1) tail = ["...", pagesS];
  return [...head, ...middle, ...tail];
};

export { paginationParts as default };
