import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection(priceListId) {
    return `/price_lists/${priceListId}/price_list_items`;
  },
  resource(priceListId, id) {
    return `/price_lists/${priceListId}/price_list_items/${id}`;
  },
};

export default {
  list(priceListId, options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(priceListId), {
      params: {
        ...options,
      },
    });
  },

  update(priceListId, id, attributes) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(priceListId, id), attributes);
  },
};
