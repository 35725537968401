import PropTypes from "prop-types";

const productType = PropTypes.shape({
  name: PropTypes.string,
  description: PropTypes.string,
  featured: PropTypes.bool,
  isActive: PropTypes.bool,
  packagePrice: PropTypes.number,
  packageUnit: PropTypes.string,
  packageWeight: PropTypes.number,
  packagesPerPallet: PropTypes.number,
  price: PropTypes.number,
  primaryImage: PropTypes.string,
  private: PropTypes.bool,
  unit: PropTypes.string,
  unitsPerPackage: PropTypes.number,
});

export default productType;
