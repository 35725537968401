import React, { useReducer, useState } from "react";
import PropTypes from "prop-types";

import { reducer as formReducer } from "@ROM/App/utils/forms";
import Form from "@ROM-ui/Form";
import Select from "@ROM-components/common/Select";
import Button from "@ROM-ui/Button";

const FieldForm = ({ field, submitText, handleSubmit }) => {
  const FIELD_TYPES_OPTIONS = [
    { label: "string", value: "string" },
    { label: "number", value: "number" },
    { label: "date", value: "date" },
    { label: "datetime", value: "datetime" },
    { label: "attachment", value: "attachment" },
  ];

  const initialField = {
    name: field.name ?? "",
    displayName: field.displayName ?? "",
    type: field.type ?? "",
    required: field.required ?? false,
    unique: field.unique ?? false,
    hint: field.hint ?? null,
    order: field.order ?? null,
  };

  const [fieldForm, updateFieldForm] = useReducer(formReducer, initialField);
  const [errors, setErrors] = useState({});

  const onSubmit = () => {
    const fieldErrors = handleSubmit(fieldForm);

    setErrors(fieldErrors);

    if (Object.keys(fieldErrors).length === 0) {
      updateFieldForm(initialField);
    }
  };

  return (
    <div>
      <div className="d-sm-flex mb-2">
        <Form.Group className="w-100">
          <Form.Control
            type="text"
            placeholder="Name"
            value={fieldForm.name}
            isInvalid={!!errors.name}
            onChange={(e) => updateFieldForm({ name: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="ms-sm-3 w-100">
          <Form.Control
            type="text"
            placeholder="Display Name"
            value={fieldForm.displayName}
            isInvalid={!!errors.displayName}
            onChange={(e) => updateFieldForm({ displayName: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">{errors.displayName}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="ms-sm-3 w-100">
          <Select
            placeholder="Type"
            options={FIELD_TYPES_OPTIONS}
            isInvalid={!!errors.type}
            value={FIELD_TYPES_OPTIONS.find(({ value }) => value === fieldForm.type) || ""}
            onChange={(e) => updateFieldForm({ type: e.value })}
          />
          {!!errors.type && <Form.Text className="small text-danger">{errors.type}</Form.Text>}
        </Form.Group>
      </div>
      <div className="d-flex flex-column align-items-start flex-md-row align-items-md-center">
        <Form.Control
          type="text"
          className="me-3"
          placeholder="Hint"
          value={fieldForm.hint || ""}
          onChange={(e) => updateFieldForm({ hint: e.target.value })}
        />
        <Form.Check
          label="Required"
          className="me-3 my-2"
          checked={fieldForm.required}
          onChange={() => updateFieldForm({ required: !fieldForm.required })}
          type="checkbox"
        />
        <Form.Check
          label="Unique"
          className="me-3 my-2"
          checked={fieldForm.unique}
          onChange={() => updateFieldForm({ unique: !fieldForm.unique })}
          type="checkbox"
        />
        <Button onClick={onSubmit} className="text-nowrap my-1">
          {submitText}
        </Button>
      </div>
    </div>
  );
};

FieldForm.defaultProps = {
  field: {},
  submitText: "Add Field",
};

FieldForm.propTypes = {
  field: PropTypes.shape,
  submitText: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

export default FieldForm;
