import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/price_lists";
  },

  resource(id) {
    return `/price_lists/${id}`;
  },
};

export default {
  list(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(), {
      params: {
        ...options,
      },
    });
  },

  find(id) {
    const client = getApiClient(API_URL);
    return client.get(urls.resource(id));
  },

  update(id, price_list) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(id), price_list);
  },

  create(price_list) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(), price_list);
  },

  destroy(id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(id));
  },
};
