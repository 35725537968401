import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canEditCustomerRolesFromUsers, canAddCustomerToUsers, canRemoveCustomerFromUsers } from "@ROM/Users/permissions";
import Badge, { BooleanBadge } from "@ROM-ui/Badge";
import Button from "@ROM-ui/Button";
import Fieldset from "@ROM-ui/Fieldset";
import IconPointer from "@ROM-ui/IconPointer";
import AdminTable from "@ROM-components/common/AdminTable";
import Modal from "@ROM-components/common/Modal";
import AddCustomerModal from "./AddCustomerModal";
import EditCustomerModal from "./EditCustomerModal";

const UserCustomersForm = ({ userCustomers, onAddAssociation, onRemoveAssociation, onEditAssociation }) => {
  const currentUser = useSelector(selectCurrentUser);

  const tableHeader = (
    <tr>
      <th>Customer</th>
      <th className="text-center">Admin</th>
      <th />
      <th />
    </tr>
  );
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [editCustomer, setEditCustomer] = useState(null);
  const [deleteCustomer, setDeleteCustomer] = useState(null);

  const onAddCustomerAssociation = (customerId, isAdmin) => {
    onAddAssociation(customerId, isAdmin);
    setShowAddCustomer(false);
  };
  const onEditCustomer = (isAdmin) => {
    onEditAssociation(editCustomer.id, isAdmin);
    setEditCustomer(null);
  };

  const onDeleteCustomer = () => {
    onRemoveAssociation(deleteCustomer.id);
    setDeleteCustomer(null);
  };

  return (
    <div className="mt-4">
      <Fieldset legend="Related Customers">
        {userCustomers.length > 0 && (
          <AdminTable header={tableHeader}>
            {userCustomers.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.attributes.name}</td>
                <td className="text-center">
                  <BooleanBadge value={customer.isAdmin} />
                </td>
                <td>{customer.isCropAdvisor && <Badge bg="info">Crop Advisor</Badge>}</td>
                <td className="text-end">
                  {canEditCustomerRolesFromUsers(currentUser) && (
                    <IconPointer className="fas fa-edit text-warning me-1" onClick={() => setEditCustomer(customer)} />
                  )}
                  {canRemoveCustomerFromUsers(currentUser) && (
                    <IconPointer className="fas fa-trash text-danger" onClick={() => setDeleteCustomer(customer)} />
                  )}
                </td>
              </tr>
            ))}
          </AdminTable>
        )}
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-100">{userCustomers.length === 0 && <EmptyContainer>No customers associated</EmptyContainer>}</div>
          {canAddCustomerToUsers(currentUser) && (
            <Button variant="primary" className="ms-3" onClick={() => setShowAddCustomer(true)}>
              Add
            </Button>
          )}
        </div>
      </Fieldset>
      {editCustomer && (
        <EditCustomerModal
          visible={editCustomer !== null}
          name={editCustomer.name}
          isAdmin={!!editCustomer.isAdmin}
          onEditCustomer={onEditCustomer}
          onCancel={() => setEditCustomer(null)}
        />
      )}
      {deleteCustomer && (
        <Modal
          confirmText="Confirm"
          isShowing={deleteCustomer !== null}
          onConfirm={onDeleteCustomer}
          onCancel={() => setDeleteCustomer(null)}
          showCloseIcon={false}
        >
          Remove association to this customer?
        </Modal>
      )}
      <AddCustomerModal
        visible={showAddCustomer}
        onAddCustomer={onAddCustomerAssociation}
        onCancel={() => setShowAddCustomer(false)}
        skipCustomers={userCustomers.map((customer) => customer.id)}
      />
    </div>
  );
};

const EmptyContainer = styled.div`
  padding: 0.5rem 1rem;
  background-color: #f3f4f6;
  border-radius: 5px;
`;

UserCustomersForm.propTypes = {
  userCustomers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({ name: PropTypes.string }),
    })
  ).isRequired,
  onAddAssociation: PropTypes.func.isRequired,
  onRemoveAssociation: PropTypes.func.isRequired,
  onEditAssociation: PropTypes.func.isRequired,
};

export default UserCustomersForm;
