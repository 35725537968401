import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Button from "@ROM-ui/Button";
import { complete } from "@ROM/OrderStatuses/actions";
import { ordersUrl } from "@ROM-utils/urlHelpers";

const Complete = ({ order, setError }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleComplete = () => {
    dispatch(complete({ orderId: order.id })).then((response) => {
      if (response.payload.status === 201) {
        history.push(ordersUrl());
      } else {
        setError(true);
      }
    });
  };

  return (
    <Button variant="outline-success" className="ms-3 me-2 px-4" size="sm" onClick={() => handleComplete()}>
      Mark as Complete
    </Button>
  );
};

Complete.propTypes = {
  order: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
};

export default Complete;
