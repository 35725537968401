import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectCurrentCompany } from "@ROM/Company/selectors";

const StyledIcon = ({ ...props }) => {
  const currentCompany = useSelector(selectCurrentCompany);
  const {
    attributes: { accentColor },
  } = currentCompany;

  return <StyledI color={accentColor} {...props} />;
};

const StyledI = styled.i`
  color: ${(props) => props.color} !important;
`;

export default StyledIcon;
