import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { companyAdminWarehousesEditUrl } from "@ROM-utils/urlHelpers";

const WarehouseRow = ({ warehouse, region, showEditLink }) => {
  const history = useHistory();
  const className = showEditLink ? "cursor-pointer" : "";
  const onEditClick = showEditLink ? () => history.push(companyAdminWarehousesEditUrl(warehouse.id)) : null;

  return (
    <tr className={className} onClick={onEditClick}>
      <td>{warehouse?.attributes?.name}</td>
      <td>{warehouse?.attributes?.displayName ?? "N/A"}</td>
      <td>{warehouse?.attributes?.fullAddress ?? "N/A"}</td>
      <td>{region?.attributes?.name ?? "N/A"}</td>
    </tr>
  );
};

WarehouseRow.propTypes = {
  warehouse: PropTypes.shape().isRequired,
  region: PropTypes.shape().isRequired,
  showEditLink: PropTypes.bool.isRequired,
};

export default WarehouseRow;
