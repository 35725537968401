import React from "react";
import Button from "@ROM-ui/Button";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import { ship } from "@ROM/OrderStatuses/actions";

const Ship = ({ order, reloadOrder, setError }) => {
  const dispatch = useDispatch();

  const handleShip = () => {
    dispatch(ship({ orderId: order.id })).then((response) => {
      if (response.payload.status === 201) {
        reloadOrder();
      } else {
        setError(true);
      }
    });
  };

  return (
    <Button variant="outline-success" className="ms-3 me-2 px-4" size="sm" onClick={() => handleShip()}>
      Mark as Shipped
    </Button>
  );
};

Ship.propTypes = {
  order: PropTypes.shape().isRequired,
  reloadOrder: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default Ship;
