import { checkPermission } from "@ROM/Permissions/utils";

export const canCreateCategory = (user) => checkPermission(user, "categories:create");

export const canDeleteCategory = (user) => checkPermission(user, "categories:delete");

export const canCreateSubcategory = (user) => checkPermission(user, "categories:create");

export const canDeleteSubategory = (user) => checkPermission(user, "categories:delete");

export const canManageCategories = (user) => canCreateCategory(user) || canDeleteCategory(user);
