import React, { useState, useEffect } from "react";
import Row from "@ROM-ui/Row";
import InvationsRow from "./invitationsRow";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import Button from "@ROM-ui/Button";
import api from "@ROM/Invitations/api";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { homeUrl } from "@ROM-utils/urlHelpers";
import Message from "@ROM-common/Message";
import { Link } from "react-router-dom";
import { emailValid } from "@ROM-utils/email";

const InvationsTable = ({ customer }) => {
  const [users, setUsers] = useState([
    {
      fullName: "",
      email: "",
      role: "User",
      id: uuidv4(),
    },
    {
      fullName: "",
      email: "",
      role: "User",
      id: uuidv4(),
    },
    {
      fullName: "",
      email: "",
      role: "User",
      id: uuidv4(),
    },
  ]);
  const [error, setError] = useState(null);

  let history = useHistory();

  useEffect(() => {
    document.body.className += "bg-white";
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  const addNewUser = () => {
    setUsers(
      users.concat({
        fullName: "",
        email: "",
        role: "User",
        id: uuidv4(),
      })
    );
  };

  const removeUser = (id) => {
    setUsers(users.filter((user) => user.id !== id));
  };

  const updateElement = (user) => {
    const newUsers = users.slice();
    const index = users.findIndex((element) => element.id === user.id);
    newUsers[index] = user;
    setUsers(newUsers);
    if (error && !haveErrors() && !haveEmailDuplicate()) setError(false);
  };

  const haveInvalidEmail = (user) => user.email !== "" && !emailValid(user.email);

  const isIncomplete = (user) =>
    (user.email !== "" && user.fullName == "") || (user.fullName !== "" && user.email == "");

  const haveErrors = () => {
    return users.some((element) => haveInvalidEmail(element) || isIncomplete(element));
  };

  const haveEmailDuplicate = () => {
    const emails = [];
    users.map((element) => {
      if (element.email !== "") emails.push(element.email);
    });
    return new Set(emails).size !== emails.length;
  };

  const sendInvitations = () => {
    const error = haveErrors();
    const duplicatedEmails = haveEmailDuplicate();
    if (error || duplicatedEmails) {
      const errorMsg = error ? "There have been some errors, check the infromation below" : "All emails must be unique";
      setError(errorMsg);
    } else {
      api
        .create({ users: completedUsers(), customerId: customer.id })
        .then(() => {
          history.push(homeUrl());
        })
        .catch((response) => {
          setError(response.data.detail);
        });
    }
  };

  const completedUsers = () => {
    return users.filter((user) => user.email !== "" && user.fullName !== "");
  };

  return (
    <React.Fragment>
      {error && <Message message={error} type={"message"} />}

      <StyledTable className="p-4">
        {users.map((user, index) => (
          <InvationsRow
            key={user.id}
            user={user}
            lastElement={index === users.length - 1}
            onIconClick={index === users.length - 1 ? addNewUser : () => removeUser(user.id)}
            updateElement={updateElement}
            error={error}
            haveInvalidEmail={haveInvalidEmail}
            isIncomplete={isIncomplete}
          />
        ))}
      </StyledTable>
      <StyledRow className="pt-5">
        <StyledLink to={homeUrl()}>
          <strong>Skip</strong>
        </StyledLink>
        <StyledButton variant="default" disabled={completedUsers().length === 0} onClick={sendInvitations}>
          Send
        </StyledButton>
      </StyledRow>
    </React.Fragment>
  );
};

InvationsTable.propTypes = {
  customer: PropTypes.object,
};

const StyledLink = styled(Link)`
  color: #94d60a;
`;

const StyledButton = styled(Button)`
  background-color: #94d60a;
  color: white;
`;

const StyledTable = styled.div``;

const StyledRow = styled(Row)`
  justify-content: space-evenly;
`;

export default InvationsTable;
