import update from "immutability-helper";
import { handle } from "redux-pack";
import {
  FETCH_COMPANY_ROLES,
  CREATE_COMPANY_ROLE,
  UPDATE_COMPANY_ROLE,
  DESTROY_COMPANY_ROLE,
  ADD_COMPANY_ROLE_NOTIFICATION,
  REMOVE_COMPANY_ROLE_NOTIFICATION,
  UPDATE_COMPANY_ROLE_NOTIFICATION,
  ADD_COMPANY_ROLE_PERMISSION,
  UPDATE_COMPANY_ROLE_PERMISSION,
  REMOVE_COMPANY_ROLE_PERMISSION,
} from "./actions";

const initialState = {
  all: {
    items: [],
    included: [],
    loading: false,
    error: false,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};

  switch (type) {
    case FETCH_COMPANY_ROLES:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              included: { $set: json.included },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case CREATE_COMPANY_ROLE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: payload.data[0].detail },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $push: [json.data] },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case UPDATE_COMPANY_ROLE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: payload.data[0].detail },
            },
          });
        },

        success: (prevState) => {
          const companyRoleId = json.data.id;
          const index = prevState.all.items.findIndex((item) => item.id === companyRoleId);

          return update(prevState, {
            all: {
              items: { [index]: { $set: json.data } },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case DESTROY_COMPANY_ROLE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          const { companyRoleId } = action.meta;
          const index = prevState.all.items.findIndex((item) => item.id === companyRoleId);
          return update(prevState, {
            all: {
              items: { $splice: [[index, 1]] },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case ADD_COMPANY_ROLE_PERMISSION:
    case UPDATE_COMPANY_ROLE_PERMISSION:
    case REMOVE_COMPANY_ROLE_PERMISSION:
    case ADD_COMPANY_ROLE_NOTIFICATION:
    case UPDATE_COMPANY_ROLE_NOTIFICATION:
    case REMOVE_COMPANY_ROLE_NOTIFICATION:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: payload.data[0].detail },
            },
          });
        },

        success: (prevState) => {
          const companyRoleId = json.data.id;
          const index = prevState.all.items.findIndex((item) => item.id === companyRoleId);
          const filterIncluded = prevState.all.included.filter(
            (item) => item.relationships.companyRole.data.id !== companyRoleId
          );

          return update(prevState, {
            all: {
              items: { [index]: { $set: json.data } },
              included: { $set: [...filterIncluded, ...json.included] },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    default:
      return state;
  }
}

export default reducer;
