import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Badge from "@ROM-ui/Badge";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import NumberFormater from "@ROM-ui/NumberFormater";
import Form from "@ROM-ui/Form";
import Spinner from "@ROM-ui/Spinner";
import IconPointer from "@ROM-ui/IconPointer";

import { numberFormat } from "@ROM/App/utils/format";
import OrderItemModal from "@ROM/Orders/components/OrderItems/OrderItemModal";
import DiscountAmount from "./DiscountAmount";
import QuantityInput from "./QuantityInput";

/* eslint-disable react/jsx-props-no-spreading */

export const OrderItemRowMobile = ({
  item,
  isShow,
  note,
  setNote,
  showTrial,
  loadingPrice,
  handleRemoveItem,
  handleAddDiscount,
  handleTrialProduct,
  dragHangleProps,
  canSetDiscounts,
  canSetTrial,
}) => {
  const [removing, setRemoving] = useState(false);
  const hasNotes = item?.attributes?.notes;
  const [showNotes, setShowNotes] = useState(isShow ? false : hasNotes);

  const [showDetailModal, setShowDetailModal] = useState(false);
  const isTrial = item.attributes.isTrial || false;
  const totalPrice = item.attributes.totalPriceCents;
  const finalPrice = item.attributes.finalPriceCents;

  return (
    <div className="px-2 text-center mt-3 text-secondary">
      <Row className="align-items-center bg-white rounded-top py-2">
        <Col className="d-flex align-items-center">
          {!isShow && (
            <>
              <i className="fas fa-ellipsis-v me-3" {...dragHangleProps} />
              <IconPointer onClick={() => setShowNotes(!showNotes)} className="fas fa-sticky-note text-info me-2" />
            </>
          )}
          <IconPointer onClick={() => setShowDetailModal(true)} className="text-dark fas fa-info-circle ms-auto me-2" />
          <span className="text-dark fw-bold">{item.attributes.productName}</span>

          {!isShow && (
            <>
              {removing ? (
                <Spinner animation="border" variant="secondary" size="sm" />
              ) : (
                <IconPointer
                  className="fas fa-trash-alt text-danger ms-2"
                  onClick={() => {
                    setRemoving(true);
                    handleRemoveItem(item);
                  }}
                />
              )}
            </>
          )}
        </Col>
      </Row>
      <Row className="bg-custom-green align-items-center text-white rounded-bottom">
        <Col xs={8}>
          <Row className="mt-3">
            <Col xs={4} className="fw-bold small text-start">
              Unit
            </Col>
            <Col xs={8} className="text-end">
              <NumberFormater className="text-nowrap" value={item.attributes.productPriceCents} />
              {item.attributes.unit && <div className="small text-secondary">{`per ${item.attributes.unit}`}</div>}
            </Col>
          </Row>
          <Row className="mt-1">
            <Col xs={4} className="fw-bold small text-start">
              Package
            </Col>
            <Col xs={8} className="text-end">
              <NumberFormater className="text-nowrap" value={item.attributes.productPackageCents} />
              {item.attributes.packageUnit && <div className="small text-secondary">{`per ${item.attributes.packageUnit}`}</div>}
            </Col>
          </Row>
          <Row className="mt-1">
            <Col xs={4} className="fw-bold small text-start">
              Weight
            </Col>
            <Col xs={8} className="text-end">
              {numberFormat(item.attributes.productPackageWeight * item.attributes.quantity)}
              {` lbs.`}
            </Col>
          </Row>
          {(item.attributes.discountAmount || 0) !== 0 && (
            <Row className="mt-1">
              <Col xs={4} className="fw-bold small text-start">
                Discount
              </Col>
              <Col xs={8} className="text-end">
                <DiscountAmount
                  isShow={isShow}
                  item={item}
                  handleAddDiscount={handleAddDiscount}
                  buttonVariant="info"
                  buttonClassName="px-2 py-0"
                  canSetDiscounts={canSetDiscounts}
                />
              </Col>
            </Row>
          )}

          <Row className="mt-1">
            <Col xs={4} className="fw-bold small text-start">
              Total
            </Col>
            <Col xs={8} className="text-end">
              {isShow && isTrial && (
                <Badge bg="primary" className="me-2">
                  Trial
                </Badge>
              )}
              <NumberFormater className="text-nowrap" value={finalPrice} />
              {loadingPrice && <Spinner className="ms-1" size="sm" animation="border" variant="white" />}
              {finalPrice !== totalPrice && (
                <div className="small text-secondary">
                  <NumberFormater value={totalPrice} className="text-nowrap" />
                </div>
              )}
            </Col>
          </Row>
          {showTrial && (
            <Row>
              <Col xs={8} className="offset-4 text-end">
                {isShow ? (
                  <>{isTrial && <div className="fw-bold">Trial</div>}</>
                ) : (
                  <Form.Check
                    checked={isTrial}
                    className="my-1"
                    type="checkbox"
                    label="Trial"
                    onChange={(evt) => {
                      handleTrialProduct(item, evt.target.checked);
                    }}
                    disabled={!canSetTrial}
                  />
                )}
              </Col>
            </Row>
          )}
        </Col>
        <StyledCol xs={4} className="align-self-start">
          {!isShow ? (
            <QuantityInput
              productId={item?.attributes?.productId}
              packagesPerPallet={item?.attributes?.productPackagesPerPallet}
              isMobile
            />
          ) : (
            <>
              <div className="mt-3 fw-bold small">Quantity</div>
              <div className="mt-2 fw-bold">{item?.attributes?.quantity}</div>
            </>
          )}
        </StyledCol>
        {showNotes && (
          <Col xs={12}>
            <Form.Control
              className="my-3"
              as="input"
              type="text"
              placeholder="Add a note for this item"
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </Col>
        )}
        {isShow && item?.attributes?.notes && (
          <Col xs={12}>
            <p className="m-0 text-md-left mt-1 font-italic fw-light">{item?.attributes?.notes}</p>
          </Col>
        )}
      </Row>

      <OrderItemModal item={item} onCancel={() => setShowDetailModal(false)} show={showDetailModal} />
    </div>
  );
};

OrderItemRowMobile.propTypes = {
  item: PropTypes.shape({
    attributes: PropTypes.shape({
      discountAmount: PropTypes.number,
      discountType: PropTypes.string,
      notes: PropTypes.string,
      productId: PropTypes.number,
      productName: PropTypes.string,
      productPackagesPerPallet: PropTypes.number,
      productPackageCents: PropTypes.number,
      productPackageWeight: PropTypes.number,
      productPriceCents: PropTypes.number,
      unit: PropTypes.string,
      packageUnit: PropTypes.string,
      quantity: PropTypes.number,
      totalPriceCents: PropTypes.number,
      finalPriceCents: PropTypes.number,
      isTrial: PropTypes.bool,
    }),
  }).isRequired,
  handleAddDiscount: PropTypes.func.isRequired,
  handleTrialProduct: PropTypes.func.isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.shape({}).isRequired,
  loadingPrice: PropTypes.bool.isRequired,
  isShow: PropTypes.bool.isRequired,
  note: PropTypes.string.isRequired,
  setNote: PropTypes.func.isRequired,
  showTrial: PropTypes.bool.isRequired,
  dragHangleProps: PropTypes.shape({}).isRequired,
  canSetDiscounts: PropTypes.bool.isRequired,
  canSetTrial: PropTypes.bool.isRequired,
};

const StyledCol = styled(Col)`
  position: relative;
  padding: 0;
`;

export default OrderItemRowMobile;
