import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import PointerSpan from "@ROM-ui/PointerSpan";
import PdfActions from "@ROM/Orders/components/PdfActions";
import { orderEditUrl } from "@ROM-utils/urlHelpers";
import { useHistory } from "react-router-dom";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { canUpdateOrder } from "@ROM/Orders/permissions";

const IconMenu = ({ order, setError, setShowStatusesModal }) => {
  const history = useHistory();

  const currentCompany = useSelector(selectCurrentCompany);
  const currentUser = useSelector(selectCurrentUser);

  const canUpdate = canUpdateOrder(currentUser, order, currentCompany);

  return (
    <>
      <PointerSpan
        onClick={() => setShowStatusesModal(true)}
        className="text-primary fas fa-history me-2 mt-1"
        title="Status history"
      />
      {canUpdate && (
        <PointerSpan
          onClick={() => history.push(orderEditUrl(order.id))}
          className="text-primary fas fa-edit me-2 mt-1"
          id="order-edit-icon"
          title="Edit"
        />
      )}
      <PdfActions orderId={order.id} setError={setError} />
    </>
  );
};

IconMenu.propTypes = {
  order: PropTypes.shape().isRequired,
  setError: PropTypes.func.isRequired,
  setShowStatusesModal: PropTypes.func.isRequired,
};

export default IconMenu;
