import React, { useState } from "react";
import PropTypes from "prop-types";

import FieldForm from "@ROM/CustomTables/components/FieldForm";
import OverlayTrigger from "@ROM-ui/OverlayTrigger";
import Modal from "@ROM-components/common/Modal";
import Popover from "@ROM-ui/Popover";
import Button from "@ROM-ui/Button";
import IconPointer from "@ROM-ui/IconPointer";
import { BooleanBadge } from "@ROM-ui/Badge";

const hintPopover = (hint) => (
  <Popover>
    <Popover.Body>{hint}</Popover.Body>
  </Popover>
);

export const FieldTableHeader = () => (
  <tr>
    <th>Name</th>
    <th className="text-center">Display name</th>
    <th className="text-center">Type</th>
    <th className="text-center">Required</th>
    <th className="text-center">Unique</th>
    <th className="text-center">Hint</th>
    <th className="text-end">Actions</th>
  </tr>
);

export const FieldTableRow = ({ index, field, fieldCount, updateField, deleteField, reorderField }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editing, setEditing] = useState(false);

  const handleUpdateField = (newField) => {
    const fieldErrors = updateField(index, newField);

    if (Object.keys(fieldErrors).length === 0) {
      setEditing(false);
    }

    return fieldErrors;
  };

  return confirmDelete ? (
    <tr>
      <td colSpan="12" className="text-center">
        Delete this field?
        <Button variant="success" className="ms-3" onClick={() => setConfirmDelete(false)}>
          Cancel
        </Button>
        <Button variant="danger" className="ms-3" onClick={() => deleteField(index)}>
          Yes, Delete
        </Button>
      </td>
    </tr>
  ) : (
    <>
      <tr>
        <td>{field.name}</td>
        <td className="text-center">{field.displayName || "N/A"}</td>
        <td className="text-center">{field.type}</td>
        <td className="text-center">
          <BooleanBadge value={field.required} />
        </td>
        <td className="text-center">
          <BooleanBadge value={field.unique} />
        </td>
        <td className="text-center">
          {field.hint ? (
            <OverlayTrigger placement="top" overlay={hintPopover(field.hint)}>
              <i className="fas fa-info-circle" />
            </OverlayTrigger>
          ) : (
            "N/A"
          )}
        </td>
        <td className="d-flex justify-content-end">
          {index > 0 && (
            <IconPointer className="fa fa-caret-up text-secondary me-2" onClick={() => reorderField(index, index - 1)} />
          )}
          {index < fieldCount - 1 && (
            <IconPointer className="fa fa-caret-down text-secondary me-2" onClick={() => reorderField(index, index + 1)} />
          )}
          <IconPointer className="fa fa-edit text-success me-2" onClick={() => setEditing(true)} />
          <IconPointer className="fa fa-trash text-danger" onClick={() => setConfirmDelete(true)} />
        </td>
      </tr>
      <Modal
        isShowing={editing}
        showConfirmButton={false}
        showCancelButton={false}
        onCancel={() => setEditing(false)}
        header="Edit Field"
      >
        <FieldForm field={field} submitText="Update Field" handleSubmit={handleUpdateField} />
      </Modal>
    </>
  );
};

FieldTableRow.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    hint: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    unique: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
  updateField: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  reorderField: PropTypes.func.isRequired,
  fieldCount: PropTypes.number.isRequired,
};
