import update from "immutability-helper";
import humps from "humps";
import { handle } from "redux-pack";
import { FETCH_EMAIL_GROUPS, CREATE_EMAIL_GROUP, DESTROY_EMAIL_GROUP, UPDATE_EMAIL_GROUP } from "./actions";

const initialState = {
  all: {
    items: [],
    included: [],
    loading: false,
    error: false,
  },
};

const includedWithAddedUsers = (prevState, included) => {
  const prevIncluded = prevState.all.included;
  const existingUsers = prevIncluded.filter((item) => item.type === "user").map((item) => item.id);

  const addUsers = included.filter((item) => item.type === "user" && !existingUsers.includes(item.id));
  return [...prevIncluded, ...addUsers];
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};

  const startFunc = (prevState) => {
    return update(prevState, {
      all: {
        loading: { $set: true },
        error: { $set: false },
      },
    });
  };

  const finishFunc = (prevState) => {
    return update(prevState, {
      all: {
        loading: { $set: false },
      },
    });
  };

  const failureFunc = (prevState) => {
    return update(prevState, {
      all: {
        error: { $set: true },
      },
    });
  };

  switch (type) {
    case FETCH_EMAIL_GROUPS: {
      return handle(state, action, {
        start: startFunc,
        finish: finishFunc,
        failure: failureFunc,
        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: humps.camelizeKeys(json.data) },
              included: { $set: humps.camelizeKeys(json.included) },
            },
          });
        },
      });
    }

    case CREATE_EMAIL_GROUP: {
      return handle(state, action, {
        start: startFunc,
        finish: finishFunc,
        failure: failureFunc,
        success: (prevState) => {
          const included = includedWithAddedUsers(prevState, json.included);
          return update(prevState, {
            all: {
              items: { $push: [humps.camelizeKeys(json.data)] },
              included: { $set: humps.camelizeKeys(included) },
            },
          });
        },
      });
    }

    case UPDATE_EMAIL_GROUP: {
      return handle(state, action, {
        start: startFunc,
        finish: finishFunc,
        failure: failureFunc,
        success: (prevState) => {
          const included = includedWithAddedUsers(prevState, json.included);
          const index = prevState.all.items.findIndex((item) => item.id === json.data.id);

          return update(prevState, {
            all: {
              items: { [index]: { $set: humps.camelizeKeys(json.data) } },
              included: { $set: humps.camelizeKeys(included) },
            },
          });
        },
      });
    }

    case DESTROY_EMAIL_GROUP: {
      return handle(state, action, {
        start: startFunc,
        finish: finishFunc,
        failure: failureFunc,
        success: (prevState) => {
          const { emailGroupId } = action.meta;
          const index = prevState.all.items.findIndex((item) => item.id === emailGroupId);
          return update(prevState, {
            all: {
              items: { $splice: [[index, 1]] },
              loading: { $set: false },
            },
          });
        },
      });
    }

    default:
      return state;
  }
}

export default reducer;
