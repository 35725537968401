import { standardAction, apiAction } from "@ROM-utils/actions";
import DeliveriesApi from "./api";

export const SET_PAGE = "deliveries/api/SET_PAGE";
export const LIST = "deliveries/api/LIST";
export const FIND = "deliveries/api/FIND";
export const UPDATE = "deliveries/api/UPDATE";
export const UPDATE_SHIPPING = "deliveries/api/UPDATE_SHIPPING";
export const CREATE = "deliveries/api/CREATE";
export const DESTROY = "deliveries/api/DESTROY";

export function list(options = {}) {
  return apiAction(LIST, DeliveriesApi.list(options));
}

export function find(id) {
  return apiAction(FIND, DeliveriesApi.find(id));
}

export function create(attributes = {}) {
  return apiAction(CREATE, DeliveriesApi.create(attributes));
}

export function update(id, attributes = {}) {
  return apiAction(UPDATE, DeliveriesApi.update(id, attributes));
}

export function updateShipping(deliveryId, shippingAttributes = {}) {
  return apiAction(UPDATE_SHIPPING, DeliveriesApi.updateShipping(deliveryId, shippingAttributes));
}

export function destroy(id) {
  return apiAction(DESTROY, DeliveriesApi.destroy(id), { deliveryId: id });
}

export function setPage(page) {
  return standardAction(SET_PAGE, { page });
}
