import { standardAction, apiAction } from "@ROM-utils/actions";
import InvitationApi from "./api";

export const LIST = "invitations/api/LIST";
export const SET_PAGE = "invitations/api/SET_PAGE";
export const DESTROY = "invitations/api/DESTROY";

export function list(options = {}) {
  return apiAction(LIST, InvitationApi.list(options));
}

export function setPage(page) {
  return standardAction(SET_PAGE, { page });
}

export function destroy(id) {
  return apiAction(DESTROY, InvitationApi.destroy(id), { invitationId: id });
}
