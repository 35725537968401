import React, { useState, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { canCreateInvitations, canResendInvitations, canDeleteInvitations } from "@ROM/Invitations/permissions";
import Modal from "@ROM-components/common/Modal";
import Pagination from "@ROM-components/common/Pagination";
import CreateInvitation from "@ROM/Users/components/Invitations/CreateInvitation";
import AdminTable from "@ROM-components/common/AdminTable";
import ShadowContainer from "@ROM-ui/ShadowContainer";
import api from "@ROM/Invitations/api";
import { list as listInvitations, setPage, destroy as deleteInvitation } from "@ROM/Invitations/actions";
import { selectAllInvitations, selectInvitationsPagination } from "@ROM/Invitations/selectors";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Button from "@ROM-ui/Button";
import { list as fetchUsers } from "@ROM/Users/actions";
import { selectUsersPagination } from "@ROM/Users/selectors";
import { selectCustomerRoles, selectCompanyRoles, selectCurrentUser } from "@ROM/Auth/selectors";
import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import { useFilters } from "@ROM/App/utils/filters";
import InvitationRow from "./InvitationRow";
import Filters from "./Filters";
import InvitationHeader from "./InvitationHeader";

const initialFilters = {
  accepted: null,
};

const InvitationTable = () => {
  const currentUser = useSelector(selectCurrentUser);
  const invitations = useSelector(selectAllInvitations);
  const pagination = useSelector(selectInvitationsPagination);
  const userPagination = useSelector(selectUsersPagination);
  const companyRoles = useSelector(selectCompanyRoles);
  const customerRoles = useSelector(selectCustomerRoles);
  const customer = useContext(CustomerContext);

  const dispatch = useDispatch();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const customerId = customer?.id ?? null;

  const getFilteredInvitations = (filters, page, customerId) => dispatch(listInvitations({ ...filters, page, customerId }));

  const callback = useCallback(async (page = 1) => {
    getFilteredInvitations(filters, page, customerId), [filters];
  });

  const [loading, filters, handleFilterChange, handleClearFilters, handlePageClick] = useFilters(
    callback,
    initialFilters,
    setPage
  );

  const onDeleteClick = (id) => {
    dispatch(deleteInvitation(id));
  };

  const onResendClick = (id) => {
    api.resend({ id }).then(() => {
      setShowSuccess(true);
    });
  };

  const canCreate = canCreateInvitations(currentUser);
  const canDelete = canDeleteInvitations(currentUser);
  const canResend = canResendInvitations(currentUser);

  return (
    <>
      <ShadowContainer className="mt-2 mb-4">
        <Row className="align-items-center">
          <Col md={10}>
            <Filters handleFilterChange={handleFilterChange} handleClearFilters={handleClearFilters} filters={filters} />
          </Col>
          {canCreate && (
            <Col className="d-flex justify-content-end">
              <Button onClick={() => setShowCreate(true)} id="customer-edit-add-invitation-btn">
                Add New
              </Button>
            </Col>
          )}
        </Row>
      </ShadowContainer>

      <AdminTable header={<InvitationHeader />}>
        {!loading &&
          invitations.map((invitation) => (
            <InvitationRow
              key={invitation.id}
              invitation={invitation}
              onDeleteClick={canDelete && onDeleteClick}
              onResendClick={canResend && onResendClick}
            />
          ))}
      </AdminTable>

      <Pagination pagination={pagination} handlePageClick={handlePageClick} />

      <CreateInvitation
        customerId={customerId}
        updateInvitations={() => getFilteredInvitations({ ...initialFilters, customerId })}
        show={showCreate}
        onCancel={() => setShowCreate(false)}
        callback={() => {
          dispatch(fetchUsers({ page: userPagination.currentPage, customerId }));
          setShowSuccess(true);
        }}
        companyRoles={companyRoles}
        customerRoles={customerRoles}
        fromCompanyAdmin
      />

      <Modal
        isShowing={showSuccess}
        onCancel={() => setShowSuccess(false)}
        showCancelButton={false}
        showConfirmButton={false}
        header="Success!"
      >
        <p>The invitation was sent successfully</p>
      </Modal>
    </>
  );
};

export default InvitationTable;
