import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100%;
  z-index: 1;

  padding-right: ${(props) => (props.dontPadMobile ? "0" : "1rem")};
  padding-left: ${(props) => (props.dontPadMobile ? "0" : "1rem")};
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (min-width: 992px) {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  @media (min-width: 1200px) {
    padding-right: 1rem;
    padding-left: 1rem;
    max-width: ${(props) => {
      if (props.fullWidth) return "100%";
      if (props.expanded) return "80%";
      return "1140px";
    }};
  }
`;

export default StyledContainer;
