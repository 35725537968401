import { apiAction, standardAction } from "@ROM-utils/actions";
import PriceListItemsApi from "./api";

export const LIST = "price_list_items/api/LIST";
export const UPDATE = "price_list_items/api/UPDATE";
export const SET_PAGE = "price_list_items/api/SET_PAGE";

export function list(priceListId, options = {}) {
  return apiAction(LIST, PriceListItemsApi.list(priceListId, options));
}

/**
 * Returns the items in items that changed from the original items.
 */
function filterChanged(items, originals) {
  const minId = ([id1, price1], [id2, price2]) => id1 - id2;
  const sItems = [...items].sort(minId);
  const sOriginals = [...originals].sort(minId);
  const samePrice = (item1, item2) => item1[1].unitPriceCents === item2[1].unitPriceCents;
  const hasChanged = (item, index) => !samePrice(item, sOriginals[index]);
  return sItems.filter(hasChanged);
}

export function updateMany(priceListId, priceListItems, originalItems) {
  const dirtyItems = filterChanged(priceListItems, originalItems);
  const promises = dirtyItems.map(([itemId, attributes]) => PriceListItemsApi.update(priceListId, itemId, attributes));
  return apiAction(UPDATE, Promise.all(promises));
}

export function setPage(page) {
  return standardAction(SET_PAGE, { page });
}

export function getFilteredPriceListItems(priceListId, filters = {}, options = {}) {
  return list(priceListId, {
    ...filters,
    ...options,
  });
}
