import { createSelector } from "reselect";

const cartState = (state) => state.cart;

export const selectCustomer = createSelector(cartState, (cartState) =>
  cartState.relationships.find((incl) => incl.type === "customer")
);
export const selectCartOrderId = createSelector(cartState, (cartState) => cartState.order.id);

export const selectCartOrder = createSelector(cartState, (cartState) => cartState.order);

export const selectCartLoaded = createSelector(cartState, (cartState) => cartState.loaded);

export const selectErrors = createSelector(cartState, (cartState) => cartState.error);

export const selectErrorMessage = createSelector(cartState, (cartState) => cartState.errorMessage);

export const selectCartAssets = createSelector(cartState, (cartState) =>
  Object.entries(cartState.relationships).length === 0 && cartState.relationships.constructor === Object
    ? []
    : cartState.relationships.filter((item) => item.type === "asset")
);

export const selectCartOrderItemByProductId = createSelector(
  [cartState, (_state, productId) => productId],
  (cartState, productId) =>
    cartState?.relationships.find((item) => item.type === "orderItem" && item?.attributes.productId == productId)
);
