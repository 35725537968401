import update from "immutability-helper";
import { handle } from "redux-pack";

import { CREATE_ROW, DESTROY_ROW, LIST_ROWS, UPDATE_ROW } from "./actions";

const initialState = {
  all: {
    loading: true,
    error: false,
    items: [],
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};

  switch (type) {
    case LIST_ROWS:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case CREATE_ROW:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: payload.data[0].detail },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $push: [json.data] },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case UPDATE_ROW:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: payload.data[0].detail },
            },
          });
        },

        success: (prevState) => {
          const rowId = json.data.id;
          const index = prevState.all.items.findIndex((item) => item.id === rowId);

          return update(prevState, {
            all: {
              items: {
                [index]: {
                  attributes: { $set: json.data.attributes },
                },
              },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });
    case DESTROY_ROW:
      return handle(state, action, {
        failure: (prevState) => {
          return update(prevState, {
            all: {
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          const { id } = action.meta;
          const index = prevState.all.items.findIndex((item) => item.id === id);
          return update(prevState, {
            all: {
              items: { $splice: [[index, 1]] },
              error: { $set: false },
            },
          });
        },
      });

    default:
      return state;
  }
}

export default reducer;
