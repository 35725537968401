import { useState, useEffect, useReducer } from "react";
import { reducer } from "@ROM/App/utils/forms";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

export const isBeingSort = (filters, name) => {
  const downSort = "fa fa-sort-down ms-1 pb-1";
  const upSort = "fa fa-sort-up ms-1 pt-1";
  const sortable = "fa fa-sort ms-1";

  if (filters.sort === name) {
    return upSort;
  }
  if (filters.sort === `-${name}`) {
    return downSort;
  }
  return sortable;
};

const syncUrlFilters = (history, filters) => {
  history.replace({ path: window.location.pathname, search: queryString.stringify(filters) });
};

const initialFiltersFromUrl = (initialFilters, booleanFilters = []) => {
  // Replace strings of boolean fields with the boolean values.
  const fixFilters = (filters) => {
    const boolValue = (fname) => (typeof filters[fname] === "string" ? filters[fname] !== "false" : filters[fname]);
    const fixedBooleans = booleanFilters.reduce((acc, fname) => ({ ...acc, [fname]: boolValue(fname) }), {});
    return { ...filters, ...fixedBooleans };
  };

  return fixFilters({ ...initialFilters, ...queryString.parse(window.location.search) });
};

export const useFilters = (callback, initialFilters, setPage) => {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);

  const booleanFilters = Object.entries(initialFilters)
    .filter(([k, v]) => v !== null && typeof v === "boolean")
    .map(([k, _v]) => k);

  const urlFilters = initialFiltersFromUrl(initialFilters, booleanFilters);

  const [filters, setFilters] = useReducer(reducer, urlFilters);
  const history = useHistory();

  const handleFilterChange = (name, value) => {
    setFilters({ [name]: value });
  };

  const handleClearFilters = () => {
    setFilters(initialFilters);
  };

  const handlePageClick = (page) => {
    setLoading(true);
    setPage(page);
    callback(page).then(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);

    if (!initialized) {
      callback().then(() => {
        setLoading(false);
        setInitialized(true);
      });
    } else {
      const timeOutId = setTimeout(() => {
        setLoading(true);
        syncUrlFilters(history, filters);
        callback().then(() => setLoading(false));
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [filters]);

  return [loading, filters, handleFilterChange, handleClearFilters, handlePageClick];
};
