import update from "immutability-helper";
import { handle } from "redux-pack";
import {
  CREATE_BILL_OF_LADING_ITEM,
  RE_SORT_BILL_OF_LADING_ITEMS,
  UPDATE_BILL_OF_LADING_ITEM,
} from "@ROM/BillOfLadings/BillOfLadingItems/actions";
import { LIST, DESTROY, CREATE, UPDATE } from "./actions";

const initialState = {
  loading: false,
  all: {
    items: [],
    included: [],
  },
  pagination: {
    currentPage: 1,
    perPage: null,
    totalPages: null,
    totalRecords: null,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};
  switch (type) {
    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            loading: { $set: true },
          });
        },

        finish: (prevState) => {
          return update(prevState, {
            loading: { $set: false },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              included: { $set: json.included },
            },
          });
        },
      });

    case CREATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            all: { items: { $push: [json.data] } },
          });
        },
      });

    case UPDATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const pallet = json.data;
          const index = prevState.all.items.findIndex((item) => item.id === pallet.id);

          return update(prevState, {
            all: { items: { [index]: { attributes: { $set: pallet.attributes } } } },
          });
        },
      });
    case DESTROY:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const palletId = action.meta.palletId;
          const pallets = { ...prevState.all };
          pallets.items = pallets.items.filter((pallet) => pallet.id !== palletId);
          pallets.included = pallets.included.filter(
            (included) => included.type === "billOfLadingItem" && included.attributes.palletId.toString() !== palletId
          );
          return update(prevState, {
            all: { $set: pallets },
          });
        },
      });

    case RE_SORT_BILL_OF_LADING_ITEMS:
      return handle(state, action, {
        success: (prevState) => {
          const bolItems = json.data;

          const newItems = prevState.all.items.map((pallet) => ({
            ...pallet,
            relationships: {
              ...pallet.relationships,
              billOfLadingItems: {
                data: bolItems
                  .filter((bolItem) => bolItem.attributes.palletId.toString() === pallet.id)
                  .map((bolItem) => ({ id: bolItem.id, type: bolItem.type })),
              },
            },
          }));
          const newIncludeds = [...prevState.all.included.filter((item) => item.type !== "billOfLadingItem"), ...bolItems];
          return update(prevState, {
            all: {
              items: { $set: newItems },
              included: { $set: newIncludeds },
            },
          });
        },
      });
    case UPDATE_BILL_OF_LADING_ITEM:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const attributes = json.data.attributes;
          const billOfLadingItemId = json.data.id;

          const index = prevState.all.included.findIndex(
            (item) => item.type === "billOfLadingItem" && item.id == billOfLadingItemId
          );

          const pallet = json.included.find((included) => included.type === "pallet");
          const palletIndex = prevState.all.items.findIndex((item) => item.id === pallet.id);

          return update(prevState, {
            all: {
              included: { [index]: { attributes: { $set: attributes } } },
              items: { [palletIndex]: { attributes: { $set: pallet.attributes } } },
            },
          });
        },
      });

    case CREATE_BILL_OF_LADING_ITEM:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const pallet = json.included.find((included) => included.type === "pallet");
          const index = prevState.all.items.findIndex((item) => item.id === pallet.id);

          return update(prevState, {
            all: { included: { $push: [json.data] }, items: { [index]: { attributes: { $set: pallet.attributes } } } },
          });
        },
      });

    default:
      return state;
  }
}

export default reducer;
