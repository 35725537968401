import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";
import { OPEN, APPROVE, REJECT, CONFIRM, IN_PROGRESS, COMPLETE, CANCEL, SHIP, INVOICE } from "./constants";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/order_statuses";
  },
};

const status = (name, attributes = {}) => {
  const client = getApiClient(API_URL);
  return client.post(`${urls.collection()}/${name}`, attributes);
};

export default {
  open(attributes = {}) {
    return status(OPEN, attributes);
  },

  approve(attributes = {}) {
    return status(APPROVE, attributes);
  },

  reject(attributes = {}) {
    return status(REJECT, attributes);
  },

  confirm(attributes = {}) {
    return status(CONFIRM, attributes);
  },

  inProgress(attributes = {}) {
    return status(IN_PROGRESS, attributes);
  },

  ship(attributes = {}) {
    return status(SHIP, attributes);
  },

  complete(attributes = {}) {
    return status(COMPLETE, attributes);
  },

  invoice(attributes = {}) {
    return status(INVOICE, attributes);
  },

  cancel(attributes = {}) {
    return status(CANCEL, attributes);
  },

  addNote(name, attributes) {
    return status(name, attributes);
  },
};
