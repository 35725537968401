import { createSelector } from "reselect";

const customerState = (state) => state.customers;

export const selectAllCustomers = createSelector(customerState, (customers) => customers.all.items);

export const selectCurrentCustomer = createSelector(customerState, (customers) => customers.current.customer);

export const selectCurrentCustomerIncluded = createSelector(customerState, (customers) => customers.current.included);

export const selectCurrentCustomerMemberships = createSelector(customerState, (customers) =>
  customers.current.included.filter((rel) => rel.type === "membership")
);

export const selectCurrentCustomerPriceList = createSelector(selectCurrentCustomerIncluded, (customers) =>
  customers.find((rel) => rel.type === "priceList")
);

export const selectCurrentCustomerRegion = createSelector(selectCurrentCustomerIncluded, (included) =>
  included.find((rel) => rel.type === "region")
);

export const selectCurrentCustomerAssets = createSelector(selectCurrentCustomerIncluded, (included) =>
  included.filter((rel) => rel.type === "asset")
);

export const selectCurrentCustomerAssetError = createSelector(customerState, (customers) => customers.errors.assets);

export const selectAllCustomersLoading = createSelector(customerState, (customers) => customers.all.loading);

export const selectCustomersPagination = createSelector(customerState, (customers) => customers.pagination);

export const selectIncludedData = createSelector(customerState, (customers) => customers.all.included);
