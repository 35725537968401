import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection(orderId) {
    return `/orders/${orderId}/order_items`;
  },

  resource(orderId, id) {
    return `${urls.collection(orderId)}/${id}`;
  },
};

export default {
  cart(customer) {
    const client = getApiClient(API_URL);
    return client.get(`/orders/cart`, {
      params: {
        customerId: customer.id,
      },
    });
  },

  list(orderId) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(orderId));
  },

  // attributes should have order_id param
  create(attributes) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(attributes.orderId), attributes);
  },

  // attributes should have order_id param
  update(id, attributes) {
    const client = getApiClient(API_URL);
    return client.patch(urls.resource(attributes.orderId, id), attributes);
  },

  // should have order_id param
  destroy(orderId, id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(orderId, id), { orderId });
  },

  reSort(id, attributes) {
    const client = getApiClient(API_URL);
    return client.patch(`${urls.resource(attributes.orderId, id)}/re_sort`, attributes);
  },
};
