import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import Badge from "@ROM-ui/Badge";
import Button from "@ROM-ui/Button";
import StyledRow from "@ROM/CustomerAdmin/components/common/StyledRow";

const InvitationRow = ({ invitation, onDeleteClick, onResendClick }) => {
  return (
    <StyledRow>
      {invitation?.attributes?.accepted ? <Badge bg="success">Accepted</Badge> : <Badge bg="primary">Pending</Badge>}
      <p className="m-0">{invitation?.attributes?.fullName}</p>
      <p className="m-0">{invitation?.attributes?.email}</p>
      <p className="m-0">{invitation?.attributes?.role}</p>
      <p className="m-0">{moment(invitation?.attributes?.createdAt).format("MM/DD/YYYY")}</p>
      {!invitation?.attributes?.accepted && (onResendClick || onDeleteClick) && (
        <div className="text-nowrap">
          {onResendClick && (
            <Button variant="outline-primary" className="p-0 px-1 me-2" onClick={() => onResendClick(invitation?.id)}>
              Resend
            </Button>
          )}
          {onDeleteClick && (
            <Button
              variant="link"
              id={`customer-edit-delete-invitation-${invitation?.id}`}
              onClick={() => onDeleteClick(invitation?.id)}
            >
              <i className="fas fa-trash text-danger m-1" />
            </Button>
          )}
        </div>
      )}
    </StyledRow>
  );
};

InvitationRow.defaultProps = {
  onDeleteClick: null,
  onResendClick: null,
};

InvitationRow.propTypes = {
  invitation: PropTypes.shape({
    id: PropTypes.string,
    attributes: {
      fullName: PropTypes.string,
      createdAt: PropTypes.string,
      email: PropTypes.string,
      rule: PropTypes.string,
      accepted: PropTypes.bool,
    },
  }).isRequired,
  onDeleteClick: PropTypes.func,
  onResendClick: PropTypes.func,
};

export default InvitationRow;
