import React from "react";
import PropTypes from "prop-types";

import { groupUserIds, groupUsers } from "@ROM/EmailGroups/utils";

const EmailGroupLinks = ({ groups, users, onClick }) => (
  <div className="mt-3">
    <i>Hint: Click the following users groups to automatically add all users in that group.</i>

    <div>
      {groups.map((group) => {
        const userIds = groupUserIds(group);
        const userDetails = groupUsers(group, users)
          .map((user) => `${user.attributes.fullName} <${user.attributes.email}>`)
          .join(", ");
        return (
          <button key={group.id} className="btn btn-link" type="button" onClick={() => onClick(userIds)} title={userDetails}>
            {group.attributes.name}
          </button>
        );
      })}
    </div>
  </div>
);

EmailGroupLinks.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EmailGroupLinks;
