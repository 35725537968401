import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Button from "@ROM-ui/Button";
import { approve, reject } from "@ROM/OrderStatuses/actions";
import { ordersUrl } from "@ROM-utils/urlHelpers";
import ActionModal from "./ActionModal";

const Approver = ({ order, setError, canApprove, canReject }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [action, setAction] = useState("");

  const handleApprove = () => {
    dispatch(approve({ orderId: order.id, message })).then((response) => {
      if (response.payload.status === 201) {
        history.push(ordersUrl());
      } else {
        setError(true);
      }
    });
  };

  const handleReject = () => {
    dispatch(reject({ orderId: order.id, message })).then((response) => {
      if (response.payload.status === 201) {
        history.push(ordersUrl());
      } else {
        setError(true);
      }
    });
  };

  return (
    <>
      <ActionModal
        onConfirm={action === "approve" ? handleApprove : handleReject}
        onCancel={() => setShowModal(false)}
        showModal={showModal}
        value={message}
        setValue={setMessage}
      />
      {canApprove && (
        <Button
          variant="outline-success"
          className="ms-3 me-2 px-4"
          size="sm"
          id="approve-order-btn"
          onClick={() => {
            setAction("approve");
            setShowModal(true);
          }}
        >
          Accept
        </Button>
      )}
      {canReject && (
        <Button
          variant="outline-danger"
          className="ms-2 px-4"
          size="sm"
          id="reject-order-btn"
          onClick={() => {
            setAction("reject");
            setShowModal(true);
          }}
        >
          Reject
        </Button>
      )}
    </>
  );
};

Approver.propTypes = {
  order: PropTypes.shape().isRequired,
  setError: PropTypes.func.isRequired,
  canApprove: PropTypes.bool.isRequired,
  canReject: PropTypes.bool.isRequired,
};

export default Approver;
