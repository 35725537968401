import React from "react";
import { Route } from "react-router-dom";

import Page from "@ROM-pages/CompanyAdmin/PriceLists/Index";
import Layout from "@ROM-pages/Layouts/CompanyAdmin";
import Authorize from "@ROM/Auth/components/Authorize";
import { canListPriceList } from "@ROM/PriceLists/permissions";

class RouteHandler extends Route {
  render() {
    return (
      <Layout>
        <Authorize permission={canListPriceList}>
          <Page />
        </Authorize>
      </Layout>
    );
  }
}

export default RouteHandler;
