import update from "immutability-helper";
import { handle } from "redux-pack";
import { CURRENT_COMPANY, UPDATE_COMPANY, CLEAR_MESSAGES } from "./actions";
import { FETCH_USER } from "@ROM/Auth/actions";
import humps from "humps";

const initialState = {
  loading: false,
  successfulUpdate: false,
  errorUpdate: false,
  loadingUpdate: false,
  currentCompany: { id: null, attributes: {} },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};

  switch (type) {
    case CLEAR_MESSAGES:
      return update(state, {
        successfulUpdate: { $set: false },
        errorUpdate: { $set: false },
      });

    case UPDATE_COMPANY:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            loadingUpdate: { $set: true },
            errorUpdate: { $set: false },
            successfulUpdate: { $set: false },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            loadingUpdate: { $set: false },
            successfulUpdate: { $set: false },
            errorUpdate: { $set: json },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            loadingUpdate: { $set: false },
            currentCompany: { $set: json.data },
            errorUpdate: { $set: false },
            successfulUpdate: { $set: true },
          });
        },
      });

    case CURRENT_COMPANY:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            loading: { $set: true },
          });
        },

        finish: (prevState) => {
          return update(prevState, {
            loading: { $set: false },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            loading: { $set: false },
            currentCompany: { $set: json.data },
          });
        },
      });

    case FETCH_USER:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            loading: { $set: true },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            loading: { $set: false },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            loading: { $set: false },
            currentCompany: {
              attributes: { $set: humps.camelizeKeys(json.data.attributes.currentCompany.attributes) },
              id: { $set: json.data.attributes.companies[0].id },
            },
          });
        },
      });

    default:
      return state;
  }
}

export default reducer;
