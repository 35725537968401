import React from "react";
import PropTypes from "prop-types";

import { isBeingSort } from "@ROM-utils/filters";

const PriceListItemsTableHeader = ({ handleFilterChange, filters }) => {
  return (
    <tr>
      <th
        className="cursor-pointer text-nowrap"
        onClick={() => handleFilterChange("sort", `${filters.sort === "name" ? "-name" : "name"}`)}
      >
        Product
        <i className={isBeingSort(filters, "name")} />
      </th>
      <th>&nbsp;</th>
      <th>Status</th>
      <th>&nbsp;</th>
      <th className="text-end">Price</th>
    </tr>
  );
};

PriceListItemsTableHeader.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};

export default PriceListItemsTableHeader;
