import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/users";
  },

  resource(id) {
    return `/users/${id}`;
  },

  fetchAllRoles() {
    return "roles/all";
  },
};

export default {
  fetchUser() {
    const client = getApiClient(API_URL);
    return client.get(`${urls.collection()}/is_signed_in`);
  },

  logout() {
    const client = getApiClient();
    return client.delete(`${urls.collection()}/sign_out`);
  },

  getUsers() {
    const client = getApiClient(API_URL);
    return client.get(urls.collection());
  },

  fetchAllRoles() {
    const client = getApiClient(API_URL);
    return client.get(urls.fetchAllRoles());
  },
};
