import React from "react";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Button from "@ROM-ui/Button";
import PropTypes from "prop-types";
import styled from "styled-components";
import { cleanDecimals } from "@ROM/App/utils/format";

const DeliveryItemRow = ({ deliveryItem, index, hasBillOfLading, handleAddDelivery, delivery }) => {
  return (
    <>
      {deliveryItem ? (
        <StyledRow className="m-0 align-items-center p-2 text-secondary mb-3 rounded-2">
          <Col md={8} className="text-md-left">
            {deliveryItem?.attributes?.productName}
          </Col>
          <Col md={2} className="text-md-left">
            {deliveryItem?.attributes?.quantity}
          </Col>
          <Col md={2} className="text-md-center">
            {`${cleanDecimals(deliveryItem?.attributes?.productPackageWeight)} lbs`}
          </Col>
        </StyledRow>
      ) : (
        <Row className="m-0 p-3 align-items-center">
          <Col md={8} className="d-flex justify-content-between">
            {`Delivery ${index + 1}`}
            {hasBillOfLading ? (
              <p className="m-0">Already allocated</p>
            ) : (
              <Button onClick={() => handleAddDelivery(delivery)} className="px-3 py-0 bg-white text-primary">
                Add Delivery
              </Button>
            )}
          </Col>
          <Col md={2} className="text-md-left">
            Qty
          </Col>
          <Col md={2} className="text-md-center">
            Weight
          </Col>
        </Row>
      )}
    </>
  );
};

DeliveryItemRow.defaultProps = {
  deliveryItem: null,
  hasBillOfLading: null,
  index: null,
  handleAddDelivery: null,
  delivery: null,
};

DeliveryItemRow.propTypes = {
  deliveryItem: PropTypes.shape({
    attributes: PropTypes.shape({
      productPackageWeight: PropTypes.number,
      quantity: PropTypes.number,
      productName: PropTypes.string,
    }),
  }),
  hasBillOfLading: PropTypes.bool,
  index: PropTypes.number,
  handleAddDelivery: PropTypes.func,
  delivery: PropTypes.shape(),
};

export default DeliveryItemRow;

const StyledRow = styled(Row)`
  background-color: #f5f5f5;
`;
