import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import styled from "styled-components";

import { loadOptions as customerLoadOptions } from "@ROM/Customers/utils";
import { loadOptions as warehouseLoadOptions } from "@ROM/Warehouses/utils";
import { list, setPage } from "@ROM/Deliveries/actions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import {
  selectAllDeliveryOrders,
  selectLoading,
  selectAllDeliveries,
  selectAllDeliveryItems,
  selectAllDeliveryAssets,
  selectAllDeliveryCustomers,
  selectAllDeliveryWarehouses,
  selectPagination,
} from "@ROM/Deliveries/selectors";
import CustomersApi from "@ROM/Customers/api";
import WarehousesApi from "@ROM/Warehouses/api";
import useDebounceEffect from "@ROM-hooks/useDebounceEffect";
import useQueryStringFilters from "@ROM-hooks/useQueryStringFilters";
import Pagination from "@ROM-components/common/Pagination";
import ContentHeader from "@ROM-components/common/ContentHeader";
import DeliveriesList from "@ROM/Deliveries/components/List";
import Filters from "@ROM/Deliveries/components/common/Filters";

const Index = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const loading = useSelector(selectLoading);
  const deliveries = useSelector(selectAllDeliveries);
  const items = useSelector(selectAllDeliveryItems);
  const assets = useSelector(selectAllDeliveryAssets);
  const orders = useSelector(selectAllDeliveryOrders);
  const customers = useSelector(selectAllDeliveryCustomers);
  const warehouses = useSelector(selectAllDeliveryWarehouses);
  const pagination = useSelector(selectPagination);

  const initialFilters = {
    status: "all_active",
    poNumber: null,
    deliveryNumber: null,
    openOrders: true,
    customerId: null,
    warehouseId: null,
    dateFrom: null,
    dateTo: null,
    page: 1,
  };

  const [filters, setFilter] = useQueryStringFilters(initialFilters, { setInitialFiltersToQueryString: true });

  const [customerInitialValue, setCustomerInitialValue] = useState({ value: null, label: "All Customers" });
  const [warehouseInitialValue, setWarehouseInitialValue] = useState({ value: null, label: "All Warehouses" });
  const [showDeliveryDetailsForId, setShowDeliveryDetailsForId] = useState(
    queryString.parse(window.location.search).showDeliveryDetails
  );

  const handleFilterChange = (name, value) => {
    setFilter({ [name]: value });
  };

  const handleClearFilters = () => {
    setShowDeliveryDetailsForId(null);
    setFilter(initialFilters);
  };

  const handlePageClick = (page) => {
    dispatch(setPage(page));
    handleFilterChange("page", page);
  };

  useDebounceEffect(() => {
    dispatch(list({ ...filters, sort: "deliverDate" }));
  }, [filters]);

  useEffect(() => {
    if (filters.customerId) {
      CustomersApi.find(filters.customerId)
        .then((customer) => {
          setCustomerInitialValue({
            value: filters.customerId,
            label: customer.data.data.attributes.name,
          });
        })
        .catch(() => {
          handleFilterChange("customerId", null);
        });
    }

    if (filters.warehouseId) {
      WarehousesApi.find(filters.warehouseId)
        .then((warehouse) => {
          setWarehouseInitialValue({
            value: filters.warehouseId,
            label: warehouse.data.data.attributes.name,
          });
        })
        .catch(() => {
          handleFilterChange("warehouseId", null);
        });
    }
  }, []);

  return (
    <>
      <ContentHeader>
        <ContentHeader.Left>
          <h3 className="fw-bolder text-white">Deliveries</h3>
        </ContentHeader.Left>
      </ContentHeader>

      <Filters
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleClearFilters={handleClearFilters}
        currentUser={currentUser}
        customerLoadOptions={customerLoadOptions}
        warehouseLoadOptions={warehouseLoadOptions}
        customerInitialValue={customerInitialValue}
        warehouseInitialValue={warehouseInitialValue}
      />
      <ScrolableDiv className="shadow-sm p-2 bg-white rounded-2 mb-2">
        {loading ? (
          <Skeleton width="100%" height="35px" count="10" />
        ) : (
          <DeliveriesList
            deliveries={deliveries}
            showDeliveryDetailsForId={showDeliveryDetailsForId}
            deliveryItems={items}
            deliveryAssets={assets}
            orders={orders}
            customers={customers}
            warehouses={warehouses}
          />
        )}
      </ScrolableDiv>
      <Pagination pagination={pagination} handlePageClick={handlePageClick} align="center" />
    </>
  );
};

const ScrolableDiv = styled.div`
  overflow-x: auto;
  .row {
    flex-wrap: nowrap;
  }
  @media (min-width: 768px) {
    .row:not(:first-child) {
      background-color: #f8f9fa !important;
    }
  }
`;

export default Index;
