import { createSelector } from "reselect";

const addressState = (state) => state.addresses;

export const selectBillingAddresses = createSelector(addressState, (state) => {
  return state.billing.all;
});

export const selectBillingPagination = createSelector(addressState, (state) => state.billing.pagination);

export const selectShippingAddresses = createSelector(addressState, (state) => {
  return state.shipping.all;
});

export const selectShippingPagination = createSelector(addressState, (state) => state.shipping.pagination);

export const selectCreateErrors = createSelector(addressState, (state) => {
  return state.createErrors;
});

export const selectAllAddresses = createSelector(addressState, (state) => {
  return state.billing.all.concat(state.shipping.all);
});

export const selectAddressesPagination = createSelector(addressState, (state) => state.pagination);

export const selectCountries = createSelector(addressState, (state) => state.countries);
