import React from "react";
import PropTypes from "prop-types";

import { isBeingSort } from "@ROM-utils/filters";

const CustomerHeader = ({ handleColumnSort, filters, showPriceList }) => {
  return (
    <tr>
      <th onClick={() => handleColumnSort("customers.name")} className="cursor-pointer">
        Name
        <i className={isBeingSort(filters, "customers.name")} />
      </th>
      <th onClick={() => handleColumnSort("invoiceNumberPrefix")} className="text-nowrap cursor-pointer">
        Invoice Number
        <i className={isBeingSort(filters, "invoiceNumberPrefix")} />
      </th>
      {showPriceList && <th className="text-nowrap">Price List</th>}
      <th>&nbsp;</th>
    </tr>
  );
};

CustomerHeader.propTypes = {
  handleColumnSort: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
  showPriceList: PropTypes.bool.isRequired,
};

export default CustomerHeader;
