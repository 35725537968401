import {
  companyAdminCustomersUrl,
  companyAdminWarehousesUrl,
  companyAdminProductsUrl,
  companyAdminPriceListsUrl,
  companyAdminCustomTablesShowUrl,
  companyAdminUsersUrl,
} from "@ROM-utils/urlHelpers";

export const customersBreadcrumbs = (breadcrumbs = []) => [
  {
    label: "Customers",
    href: companyAdminCustomersUrl(),
    active: breadcrumbs.length === 0,
  },
  ...breadcrumbs,
];

export const warehousesBreadcrumbs = (breadcrumbs = []) => [
  {
    label: "Warehouses",
    href: companyAdminWarehousesUrl(),
    active: breadcrumbs.length === 0,
  },
  ...breadcrumbs,
];

export const productsBreadcrumbs = (breadcrumbs = []) => [
  { label: "Products", href: companyAdminProductsUrl(), active: breadcrumbs.length === 0 },
  ...breadcrumbs,
];

export const priceListsBreadcrumbs = (breadcrumbs = []) => [
  {
    label: "Price Lists",
    href: companyAdminPriceListsUrl(),
    active: breadcrumbs.length === 0,
  },
  ...breadcrumbs,
];

export const usersBreadcrumbs = (breadcrumbs = []) => [
  { label: "Users", href: companyAdminUsersUrl(), active: breadcrumbs.length === 0 },
  ...breadcrumbs,
];

export const customTablesShowBreadcrumbs = (table, breadcrumbs = []) => [
  {
    label: table?.attributes?.name || "Table",
    href: companyAdminCustomTablesShowUrl(table?.id),
    active: breadcrumbs.length === 0,
  },
  ...breadcrumbs,
];
