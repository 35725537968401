import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { canFilterProductsByActiveStatus } from "@ROM/Products/permissions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import Form from "@ROM-ui/Form";
import ShadowContainer from "@ROM-ui/ShadowContainer";
import Button from "@ROM-ui/Button";

const Filters = ({ handleFilterChange, handleClearFilters, filters }) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <ShadowContainer className="mt-2 mb-4">
      <div className="d-md-flex align-items-center mx-md-n2">
        <Form.Control
          type="text"
          name="name"
          className="mb-2 mx-md-2"
          style={{ maxWidth: "600px" }}
          placeholder="Name"
          onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
          value={filters.name || ""}
        />
        {canFilterProductsByActiveStatus(currentUser) && (
          <Form.Check
            type="checkbox"
            checked={filters.isActive}
            label="Is Active"
            name="isActive"
            className="mb-2 mx-md-2"
            onChange={() => {
              handleFilterChange("isActive", !filters.isActive);
            }}
          />
        )}
        <Form.Check
          type="checkbox"
          checked={filters.priced}
          label="Priced"
          name="priced"
          className="mb-2 mx-md-2"
          onChange={() => {
            handleFilterChange("priced", !filters.priced);
          }}
        />
        <Form.Check
          type="checkbox"
          checked={filters.featured}
          label="Featured"
          name="featured"
          className="mb-2 mx-md-2"
          onChange={() => {
            handleFilterChange("featured", !filters.featured);
          }}
        />
      </div>
      <Button className="p-0" size="sm" variant="link" onClick={handleClearFilters}>
        Clear Filters
      </Button>
    </ShadowContainer>
  );
};

Filters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape.isRequired,
};

export default Filters;
