import React, { useState } from "react";
import Button from "@ROM-ui/Button";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { inProgress } from "@ROM/OrderStatuses/actions";
import ActionModal from "./ActionModal";

const InProgress = ({ order, reloadOrder, setError }) => {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const handleInProgress = () => {
    dispatch(inProgress({ orderId: order.id, message })).then((response) => {
      setShowModal(false);
      if (response.payload.status === 201) {
        reloadOrder();
      } else {
        setError(true);
      }
    });
  };

  return (
    <>
      <Button variant="outline-success" className="ms-3 me-2 px-4" size="sm" onClick={() => setShowModal(true)}>
        Mark as In Progress
      </Button>
      <ActionModal
        onConfirm={handleInProgress}
        onCancel={() => setShowModal(false)}
        showModal={showModal}
        value={message}
        setValue={setMessage}
      />
    </>
  );
};

InProgress.propTypes = {
  order: PropTypes.shape().isRequired,
  reloadOrder: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default InProgress;
