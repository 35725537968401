import { apiAction, standardAction } from "@ROM-utils/actions";
import Api from "./api";

export const FIND = "users/api/FIND";
export const UPDATE = "users/api/UPDATE";
export const LIST = "users/api/LIST";
export const SET_PAGE = "users/api/SET_PAGE";
export const UPDATE_COMPANY_ROLES = "users/api/UPDATE_COMPANY_ROLES";
export const ADD_TO_CUSTOMER = "users/api/ADD_TO_CUSTOMER";
export const REMOVE_FROM_CUSTOMER = "users/api/REMOVE_FROM_CUSTOMER";
export const UPDATE_CUSTOMER_ADMIN_VALUE = "users/UPDATE_CUSTOMER_ADMIN_VALUE";
export const UPDATE_DISABLED_NOTIFICATIONS = "users/UPDATE_DISABLED_NOTIFICATIONS";

export function find(id) {
  return apiAction(FIND, Api.find(id));
}

export function update(id, attributes = {}) {
  return apiAction(UPDATE, Api.update(id, attributes));
}

export function list(options = {}) {
  return apiAction(LIST, Api.list(options));
}

export function setPage(page) {
  return standardAction(SET_PAGE, { page });
}

export function updateCompanyRoles(id, roles) {
  return apiAction(UPDATE_COMPANY_ROLES, Api.updateCompanyRoles(id, roles));
}

export function addToCustomer(id, params) {
  return apiAction(ADD_TO_CUSTOMER, Api.addToCustomer(id, params));
}

export function removeFromCustomer(id, customerId) {
  return apiAction(REMOVE_FROM_CUSTOMER, Api.removeFromCustomer(id, customerId), { id, customerId });
}

export function updateCustomerAdminValue(id, params) {
  return apiAction(UPDATE_CUSTOMER_ADMIN_VALUE, Api.updateCustomerAdminValue(id, params));
}

export function updateDisabledNotifications(id, params) {
  return apiAction(UPDATE_DISABLED_NOTIFICATIONS, Api.updateDisabledNotifications(id, params));
}
