import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";

import ItemForm from "./ItemForm";

const itemCount = (quantities, palletId, deliveryItemId) => quantities?.selected?.[palletId]?.[deliveryItemId] ?? 0;

const BillOfLadingItemRow = ({
  item,
  index,
  showDragHandle,
  quantities,
  handleChange,
  palletId,
  isLocked,
  showWeightWarning,
}) => (
  <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!showDragHandle}>
    {(provided, snapshot) => (
      <ItemForm
        isDragging={snapshot.isDragging}
        showWeightWarning={showWeightWarning}
        dragProvided={provided}
        name={item.attributes.productName}
        count={itemCount(quantities, palletId, item.attributes.deliveryItemId)}
        onCountChange={(value) => handleChange(item.attributes.deliveryItemId, value, palletId)}
        weight={item.attributes.productPackageWeight * item.attributes.quantity}
        isLocked={isLocked}
        showDragHandle={showDragHandle}
        notes={item.attributes.notes}
      />
    )}
  </Draggable>
);

BillOfLadingItemRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    attributes: {
      deliveryItemId: PropTypes.number,
      productName: PropTypes.string,
      productPackageWeight: PropTypes.number,
    },
  }).isRequired,
  index: PropTypes.number.isRequired,
  showDragHandle: PropTypes.bool.isRequired,
  quantities: PropTypes.shape({
    selected: PropTypes.shape(),
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  palletId: PropTypes.number.isRequired,
  isLocked: PropTypes.bool.isRequired,
  showWeightWarning: PropTypes.bool.isRequired,
};

export default BillOfLadingItemRow;
