import React, { useReducer, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { reducer } from "@ROM/App/utils/forms";
import Form from "@ROM-ui/Form";
import Col from "@ROM-ui/Col";
import Row from "@ROM-ui/Row";
import Button from "@ROM-ui/Button";
import { create, update } from "@ROM/Warehouses/actions";
import { selectWarehousesLoading } from "@ROM/Warehouses/selectors";
import Message from "@ROM-common/Message/index";
import { emailValid } from "@ROM-utils/email";
import { selectRegions } from "@ROM/Regions/selectors";
import { regionsForSelect } from "@ROM/Regions/utils";
import Select from "@ROM-components/common/Select";
import Skeleton from "react-loading-skeleton";

const WarehouseForm = ({ isEdit, currentWarehouse }) => {
  const loading = useSelector(selectWarehousesLoading);
  const regions = useSelector(selectRegions);
  const dispatch = useDispatch();
  const history = useHistory();

  const initialState = {
    name: currentWarehouse?.attributes?.name ?? "",
    displayName: currentWarehouse?.attributes?.displayName ?? "",
    address1: currentWarehouse?.attributes?.address1 ?? "",
    address2: currentWarehouse?.attributes?.address2 ?? "",
    city: currentWarehouse?.attributes?.city ?? "",
    state: currentWarehouse?.attributes?.state ?? "",
    zip: currentWarehouse?.attributes?.zip ?? "",
    phone: currentWarehouse?.attributes?.phone ?? "",
    fax: currentWarehouse?.attributes?.fax ?? "",
    email: currentWarehouse?.attributes?.email ?? "",
    regionId: isEdit ? currentWarehouse?.attributes?.regionId ?? null : regions?.[0]?.id ?? null,
  };

  const [warehouse, setWarehouse] = useReducer(reducer, initialState);
  const regionsOptions = regionsForSelect(regions);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (currentWarehouse && isEdit) {
      setWarehouse(currentWarehouse.attributes);
    }
  }, [currentWarehouse]);

  const hasInvalidEmail = warehouse.email !== "" && !emailValid(warehouse.email);

  const handleInputChange = (event) => {
    setWarehouse({ [event.target.name]: event.target.value });
  };

  const handleOperation = async (action) => {
    const { error, payload } = await dispatch(action);

    if (error) {
      setErrorMessage(payload.data.detail);
    } else {
      setSuccess(true);
    }

    window.scroll(0, 0);
  };

  const handleCreateWarehouse = async () => {
    handleOperation(create(warehouse));
  };

  const handleUpdateWarehouse = async () => {
    handleOperation(update(currentWarehouse.id, warehouse));
  };

  return (
    <>
      {success && <Message message={`Warehouse ${isEdit ? "updated" : "created"} successfully`} type="success" />}
      {errorMessage && <Message message={errorMessage} type="danger" />}
      <Row className="mb-3">
        <Col>
          <Form.Label>{loading ? <Skeleton width={100} /> : "Name"}</Form.Label>
          {loading ? (
            <Skeleton />
          ) : (
            <Form.Control
              as="input"
              type="text"
              name="name"
              id="warehouse-form-name"
              value={warehouse.name || ""}
              onChange={handleInputChange}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>{loading ? <Skeleton width={100} /> : "Display Name"}</Form.Label>
          {loading ? (
            <Skeleton />
          ) : (
            <Form.Control
              as="input"
              type="text"
              name="displayName"
              id="warehouse-form-display-name"
              value={warehouse.displayName || ""}
              onChange={handleInputChange}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>{loading ? <Skeleton width={100} /> : "Address 1"}</Form.Label>
          {loading ? (
            <Skeleton />
          ) : (
            <Form.Control
              as="input"
              type="text"
              name="address1"
              id="warehouse-form-address-1"
              value={warehouse.address1 || ""}
              onChange={handleInputChange}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>{loading ? <Skeleton width={100} /> : "Address 2"}</Form.Label>
          {loading ? (
            <Skeleton />
          ) : (
            <Form.Control
              as="input"
              type="text"
              name="address2"
              id="warehouse-form-address-2"
              value={warehouse.address2 || ""}
              onChange={handleInputChange}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>{loading ? <Skeleton width={100} /> : "City"}</Form.Label>
          {loading ? (
            <Skeleton />
          ) : (
            <Form.Control
              as="input"
              type="text"
              name="city"
              id="warehouse-form-city"
              value={warehouse.city || ""}
              onChange={handleInputChange}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>{loading ? <Skeleton width={100} /> : "State"}</Form.Label>
          {loading ? (
            <Skeleton />
          ) : (
            <Form.Control
              as="input"
              type="text"
              name="state"
              id="warehouse-form-state"
              value={warehouse.state || ""}
              onChange={handleInputChange}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>{loading ? <Skeleton width={100} /> : "Zip"}</Form.Label>
          {loading ? (
            <Skeleton />
          ) : (
            <Form.Control
              as="input"
              type="text"
              name="zip"
              value={warehouse.zip || ""}
              onChange={handleInputChange}
              id="warehouse-form-zip"
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>{loading ? <Skeleton width={100} /> : "Phone"}</Form.Label>
          {loading ? (
            <Skeleton />
          ) : (
            <Form.Control
              as="input"
              type="text"
              name="phone"
              id="warehouse-form-phone"
              value={warehouse.phone || ""}
              onChange={handleInputChange}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>{loading ? <Skeleton width={100} /> : "Fax"}</Form.Label>
          {loading ? (
            <Skeleton />
          ) : (
            <Form.Control
              as="input"
              type="text"
              name="fax"
              id="warehouse-form-fax"
              value={warehouse.fax || ""}
              onChange={handleInputChange}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>{loading ? <Skeleton width={100} /> : "Email"}</Form.Label>
          {loading ? (
            <Skeleton />
          ) : (
            <Form.Control
              as="input"
              type="text"
              name="email"
              id="warehouse-form-email"
              value={warehouse.email || ""}
              onChange={handleInputChange}
            />
          )}
          {hasInvalidEmail && <Message message="Invalid Email" type="danger" className="mt-2" />}
        </Col>
      </Row>
      {regions.length > 0 && (
        <Row className="mb-3">
          <Col>
            <Form.Label>
              {!loading ? (
                !isEdit && regions.length === 1 ? (
                  `Region: ${regions[0].attributes.name}`
                ) : (
                  "Region"
                )
              ) : (
                <Skeleton width={100} />
              )}
            </Form.Label>
            {(isEdit || regions.length > 1) && (
              <>
                {!loading ? (
                  <Select
                    placeholder="Region"
                    options={regionsOptions}
                    className="mb-2"
                    onChange={(e) => setWarehouse({ regionId: e.value })}
                    value={regionsOptions.find((option) => option.value === warehouse?.regionId?.toString())}
                  />
                ) : (
                  <Skeleton />
                )}
              </>
            )}
          </Col>
        </Row>
      )}
      <div className="text-end">
        <Button className="me-3" onClick={() => history.goBack()} variant="danger" id="create-warehouse-back-btn">
          Back
        </Button>
        <Button
          onClick={isEdit ? handleUpdateWarehouse : handleCreateWarehouse}
          variant="success"
          disabled={loading}
          id="create-warehouse-confirm-btn"
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </>
  );
};

WarehouseForm.defaultProps = {
  currentWarehouse: null,
};

WarehouseForm.propTypes = {
  currentWarehouse: PropTypes.shape(),
  isEdit: PropTypes.bool.isRequired,
};

export default WarehouseForm;
