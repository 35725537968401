/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";

const Message = ({ message, className, fluid, type }) => {
  let messages = message;

  if (!messages || messages.length === 0) {
    return null;
  }

  if (typeof message === "string") {
    messages = [message];
  }

  return (
    <div className={`alert alert-${type} ${className} ${fluid ? "w-100" : ""}`}>
      {messages.map((currentMessage, index) => (
        <div key={index}>{currentMessage}</div>
      ))}
    </div>
  );
};

Message.defaultProps = {
  fluid: false,
  type: "danger",
  className: "",
};

Message.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  fluid: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default Message;
