import { createSelector } from "reselect";

const orderState = (state) => state.orders;

export const selectLoading = createSelector(orderState, (state) => state.loading);

export const selectOrders = createSelector(orderState, (state) => state.all.orders || []);

export const selectCurrentOrder = createSelector(orderState, (state) => state?.current?.order);

export const selectCurrentIncluded = createSelector(orderState, (state) => state?.current?.included);

export const selectCurrentOrderItems = createSelector(selectCurrentIncluded, (included) =>
  included.filter((item) => item.type === "orderItem")
);

export const selectCurrentOrderCropAdvisor = createSelector(orderState, (state) => {
  const cropAdvisorId = state?.current?.order?.relationships?.cropAdvisor?.data?.id;
  return state?.current?.included.find((item) => item.type === "user" && item.id === cropAdvisorId);
});

export const selectOrdersAssets = createSelector(orderState, (state) =>
  state.all.relationships.filter((rel) => rel.type === "asset")
);

export const selectOrdersCustomers = createSelector(orderState, (state) =>
  state.all.relationships.filter((rel) => rel.type === "customer")
);

export const selectOrdersRelationships = createSelector(orderState, (state) => state.all.relationships);

export const selectOrdersPagination = createSelector(orderState, (state) => state.pagination);

export const selectOrdersFilters = createSelector(orderState, (state) => state.filters.filter);
