import React, { useEffect, useState } from "react";
import NumberFormater from "@ROM-ui/NumberFormater";
import PropTypes from "prop-types";
import styled from "styled-components";
import Gallery from "./Gallery";
import { find } from "@ROM/Products/actions";
import { selectCurrentProduct, selectRelatedProductsOfCurrentProduct } from "@ROM/Products/selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Skeleton from "react-loading-skeleton";

const Detail = ({ selectedProduct, productId, find, setRelatedProducts, relatedProducts }) => {
  const changed = selectedProduct === null || selectedProduct.id.toString() !== productId;
  const [initialized, setInitialized] = useState(!changed);

  useEffect(() => {
    if (changed) find(productId).then(() => setInitialized(true));
  }, [productId]);

  useEffect(() => {
    setRelatedProducts(relatedProducts);
  }, [relatedProducts]);

  return (
    <SrollableDiv>
      <div className="my-4 text-center">
        {initialized ? <Gallery product={selectedProduct} /> : <Skeleton height={"200px"} width={"200px"} />}
      </div>
      <div className="d-flex justify-content-between">
        <StyledTitle>Name</StyledTitle>
        <StyledTitle>Unit Price</StyledTitle>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <StyledP className="m-0">
          {initialized ? selectedProduct.attributes.name : <Skeleton width={"100px"} />}
        </StyledP>
        {initialized ? (
          <StyledP>
            <NumberFormater className="mx-0 d-block" value={selectedProduct.attributes.price} />
          </StyledP>
        ) : (
          <Skeleton width={"100px"} />
        )}
      </div>
      <StyledTitle>Units Per Package</StyledTitle>
      <StyledP className="m-0 mb-3">{initialized ? selectedProduct.attributes.unitsPerPackage : <Skeleton />}</StyledP>
      <StyledTitle>Description</StyledTitle>
      <StyledDescription>{initialized ? selectedProduct.attributes.description : <Skeleton />}</StyledDescription>
    </SrollableDiv>
  );
};

const stateToProps = (state) => {
  return {
    selectedProduct: selectCurrentProduct(state),
    relatedProducts: selectRelatedProductsOfCurrentProduct(state),
  };
};

const dispatchToProps = (dispatch) => {
  return {
    find: bindActionCreators(find, dispatch),
  };
};

Detail.displayName = "Products/Selector/Detail";
Detail.propTypes = {
  selectedProduct: PropTypes.object.isRequired,
  productId: PropTypes.number.isRequired,
  find: PropTypes.func,
  setRelatedProducts: PropTypes.func,
  relatedProducts: PropTypes.array,
};

const StyledTitle = styled.p`
  font-size: 18px;
  color: white;
  font-weight: 400;
  margin: 0;
`;

const StyledP = styled.p`
  font-size: 18px;
  color: white;
  font-weight: 300;
  margin: 0;
`;

const StyledDescription = styled.p`
  font-size: 15px;
  color: white;
  font-weight: 300;
  margin: 0;
`;

const SrollableDiv = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 690px;
`;

export default connect(stateToProps, dispatchToProps)(Detail);
