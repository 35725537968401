import PriceListsApi from "@ROM/PriceLists/api";

export const getVariant = (status) => {
  switch (status) {
    case "active":
      return "success";
    case "old":
      return "info";
    case "inactive":
      return "danger";
    default:
      return "secondary";
  }
};

export async function loadOptions(searchInput, _loadedOptions, { page }) {
  const result = await PriceListsApi.list({
    name: searchInput,
    page,
  });

  const options = result.data.data.map((option) => {
    const { name } = option.attributes;
    return {
      value: option.id,
      label: name,
    };
  });

  options.unshift({ value: null, label: "--- Use Default Price List ---" });

  return {
    options,
    hasMore: result.data.meta.pagination.currentPage < result.data.meta.pagination.totalPages,
    additional: { page: page + 1 },
  };
}
