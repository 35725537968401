import React, { useReducer, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { canUpdateCustomersAllParams } from "@ROM/Customers/permissions";
import { selectRegions } from "@ROM/Regions/selectors";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectCurrentCustomerPriceList, selectCurrentCustomerRegion } from "@ROM/Customers/selectors";
import { selectAllCustomerCropAdvisorUsers } from "@ROM/Users/selectors";
import { update } from "@ROM/Customers/actions";
import Button from "@ROM-ui/Button";
import Message from "@ROM-common/Message";
import { reducer } from "@ROM/App/utils/forms";
import { loadOptions as loadPriceListsOptions } from "@ROM/PriceLists/utils";
import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import Body from "@ROM/CustomerAdmin/components/Customers/Edit/Body";
import { loadCropAdvisorOptions } from "@ROM/CustomerAdmin/utils";

const Edit = () => {
  const regions = useSelector(selectRegions);
  const customer = useContext(CustomerContext);
  const currentUser = useSelector(selectCurrentUser);
  const customerPriceList = useSelector(selectCurrentCustomerPriceList);
  const customerRegion = useSelector(selectCurrentCustomerRegion);
  const customerCropAdvisors = useSelector(selectAllCustomerCropAdvisorUsers);

  const dispatch = useDispatch();
  const history = useHistory();
  const initialAttributes = {
    name: "",
    invoiceNumberPrefix: "",
    isActive: false,
    taxExempt: false,
    primaryContact: "",
    regionId: null,
    needsApproval: false,
    cropAdvisors: [],
  };

  const [attributes, setAttributes] = useReducer(reducer, initialAttributes);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [priceList, setPriceList] = useState(null);
  const [cropAdvisors, setCropAdvisors] = useState([]);

  const handleEdit = (newAttributes) => {
    if (!customer.id) return;

    setLoading(true);
    setShowSuccess(false);
    setErrorMsg(null);

    dispatch(update(customer.id, newAttributes))
      .then((response) => {
        if (response.error) {
          setErrorMsg(response.payload.data.detail || response.payload.data[0].detail);
        } else {
          setShowSuccess(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCustomerChange = (attributesToChange = {}) => {
    setAttributes({ ...attributes, ...attributesToChange });
  };

  useEffect(() => {
    setCropAdvisors(
      customerCropAdvisors.map((cropAdvisor) => ({
        value: cropAdvisor.id.toString(),
        label: cropAdvisor.attributes.fullName,
      }))
    );
    setAttributes({ ...attributes, cropAdvisors: customerCropAdvisors.map((cropAdvisor) => cropAdvisor.id) });
  }, [customerCropAdvisors]);

  useEffect(() => {
    if (customer) {
      setAttributes(customer.attributes);

      let currentPriceList;
      if (customerPriceList) {
        currentPriceList = {
          label: customerPriceList.attributes.name,
          value: customerPriceList.attributes.value,
        };
      }

      setPriceList(currentPriceList);
      setInitialized(true);
    }
  }, [customer]);

  const updatePriceList = (newPriceList) => {
    setPriceList(newPriceList);
    setAttributes({ ...attributes, priceListId: newPriceList.value });
  };

  const updateCropAdvisors = (event) => {
    const newCropAdvisors = event ? event.map((cropAdvisor) => cropAdvisor.value) : [];
    setCropAdvisors(event);
    setAttributes({ ...attributes, cropAdvisors: newCropAdvisors });
  };

  return (
    <div>
      {showSuccess ? (
        <Message message="The customer was successfully edited." type="success" />
      ) : (
        errorMsg && <Message message={errorMsg} type="danger" />
      )}
      <h4>Details</h4>
      <Body
        initialized={initialized}
        customer={attributes}
        handleCustomerChange={handleCustomerChange}
        currentUser={currentUser}
        regions={regions}
        customerRegion={customerRegion}
        priceList={priceList}
        priceListsOptions={loadPriceListsOptions}
        setPriceList={updatePriceList}
        cropAdvisors={cropAdvisors}
        loadCropAdvisorOptions={loadCropAdvisorOptions}
        setCropAdvisors={updateCropAdvisors}
      />
      <div className="text-end">
        <Button onClick={() => history.goBack()} variant="danger" id="edit-customer-back-btn" className="me-3">
          Back
        </Button>
        <Button onClick={() => handleEdit(attributes)} variant="success" disabled={loading} id="edit-customer-confirm-btn">
          Save
        </Button>
      </div>
    </div>
  );
};

export default Edit;

Edit.propTypes = {};
