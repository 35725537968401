import React from "react";
import PropTypes from "prop-types";

import OverlayTrigger from "@ROM-ui/OverlayTrigger";
import Popover from "@ROM-ui/Popover";

import BillOfLadingsApi from "@ROM/BillOfLadings/api";

const DownloadButton = ({ orderId, billOfLadingId, overlayPlacement, children }) => {
  const link = (withDollarAmount) => BillOfLadingsApi.downloadPdfUrl(orderId, billOfLadingId, withDollarAmount);
  const handleOnClick = (e) => {
    document.body.click(); // This will close the popover
    e.stopPropagation();
  };

  const popover = () => (
    <Popover className="text-secondary">
      <Popover.Header>Include Dollar Amount?</Popover.Header>
      <Popover.Body className="text-center">
        <a className="btn btn-link text-success" onClick={handleOnClick} href={link(true)}>
          Yes
        </a>
        <a className="btn btn-link text-danger" onClick={handleOnClick} href={link(false)}>
          No
        </a>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement={overlayPlacement} overlay={popover()} rootClose>
      {children}
    </OverlayTrigger>
  );
};

DownloadButton.defaultProps = {
  overlayPlacement: "bottom",
};

DownloadButton.propTypes = {
  overlayPlacement: PropTypes.string,
  orderId: PropTypes.number.isRequired,
  billOfLadingId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default DownloadButton;
