/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { listRows } from "@ROM/CustomTableRows/actions";
import { selectIsLoading, selectAllCustomTableRows } from "@ROM/CustomTableRows/selectors";
import AdminTable from "@ROM-components/common/AdminTable";
import TableHeader from "@ROM/CustomTableRows/components/TableHeader";
import TableRow from "@ROM/CustomTableRows/components/TableRow";

const Table = ({ table, className }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const rows = useSelector(selectAllCustomTableRows);

  const { fields, relations } = table.attributes;

  useEffect(() => {
    dispatch(listRows(table.id));
  }, [table]);

  return (
    <div className={className}>
      <AdminTable header={<TableHeader fields={fields} relations={relations} />} isLoading={isLoading}>
        {rows.length > 0 && rows.map((row) => <TableRow key={row.id} table={table} row={row} />)}
      </AdminTable>
      {!isLoading && rows.length === 0 && <ZeroState>No rows created yet</ZeroState>}
    </div>
  );
};

const ZeroState = styled.div`
  color: #6b7280;
  background-color: #e5e7eb;
  text-align: center;
  padding: 2rem;
  border-radius: 2px;
`;

Table.defaultProps = {
  className: "",
};

Table.propTypes = {
  className: PropTypes.string,
  table: PropTypes.shape.isRequired,
};

export default Table;
