import { customerAdminUrl, customerAdminManageCustomerUrl } from "@ROM-utils/urlHelpers";
import { customerAdminRoleIds } from "@ROM/Orders/permissions";
import { canAccessCustomerAdmin } from "@ROM/CustomerAdmin/permissions";

export const customerAdminUrlForUser = (user) => {
  if (canAccessCustomerAdmin(user)) {
    const roleIds = customerAdminRoleIds(user);
    if (roleIds.length === 1) {
      return customerAdminManageCustomerUrl(roleIds[0]);
    }
  }
  return customerAdminUrl();
};

export default { customerAdminUrlForUser };
