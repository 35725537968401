import React, { useState, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";

import Button from "@ROM-ui/Button";
import IconPointer from "@ROM-ui/IconPointer";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import ListGroup from "@ROM-ui/ListGroup";

import { canCreateAddresses, canEditAddresses, canDeleteAddresses } from "@ROM/Addresses/permissions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectBillingPagination, selectShippingPagination, selectAllAddresses } from "@ROM/Addresses/selectors";
import { setPage, getAddressesWithScroll, list as getAddresses, BILLING_TYPE, SHIPPING_TYPE } from "@ROM/Addresses/actions";
import { DefaultItem, AddressData } from "@ROM/Addresses/components/AddressSelector/AddressItem";
import { reducer } from "@ROM/App/utils/forms";

import FormModal from "./FormModal";
import DeleteModal from "./DeleteModal";
import AddressItem from "./AddressItem";

const AddressSelector = ({
  type,
  onSelect,
  selectedId,
  billingAddressId = null,
  handleDeleteAddress,
  customer,
  editing,
  shipAddressSameAsBilling,
  handleShipSameAsBilling,
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const billingPagination = useSelector(selectBillingPagination);
  const shippingPagination = useSelector(selectShippingPagination);
  const pagination = type === BILLING_TYPE ? billingPagination : shippingPagination;
  const allAddresses = useSelector(selectAllAddresses);

  const dispatch = useDispatch();

  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [editingAddress, setEditAddress] = useState({});
  const [deletingAddress, setDeleteAddress] = useState({});

  const [isDone, setIsDone] = useState(selectedId !== null);

  const initialFilters = {
    contact: "",
  };

  const [filters, setFilter] = useReducer(reducer, initialFilters);

  useEffect(() => {
    const timeOutId = setTimeout(() => dispatch(getAddresses({ ...filters, customerId: customer.id, type }, { type })), 500);
    return () => clearTimeout(timeOutId);
  }, [filters]);

  /* Local vars */
  const isSearching = Boolean(filters.contact !== "");

  const handleFormModalCancel = () => {
    setEditAddress({});
    setShowFormModal(false);
  };

  const handleEdit = (address) => {
    setEditAddress(address);
    setShowFormModal(true);
  };

  const handleDeleteModalCancel = () => {
    setDeleteAddress({});
    setShowDeleteModal(false);
  };

  const handleDelete = (address) => {
    setDeleteAddress(address);
    setShowDeleteModal(true);
  };

  const canEdit = canEditAddresses(currentUser, customer.id);
  const canDelete = canDeleteAddresses(currentUser, customer.id);

  const handleSearchInput = (event) => {
    setFilter({ contact: event.target.value });
  };

  const fetchMoreAddresses = () => {
    const page = pagination.currentPage + 1;
    dispatch(setPage(page, { type }));
    dispatch(getAddressesWithScroll({ page, customerId: customer.id, type }));
  };

  const defaultItem = (
    <DefaultItem type={SHIPPING_TYPE} onSelect={handleShipSameAsBilling} selected={shipAddressSameAsBilling}>
      <Row className="align-items-center">
        <Col xs={12} sm={8}>
          <p className="m-0">
            <strong>Same as Billing Address</strong>
          </p>
          <p className="m-0 text-secondary">Select this option to use the Billing Address as the Shipping Address</p>
        </Col>
        <Col xs={12} md={4} className="d-flex justify-content-start">
          {shipAddressSameAsBilling && (
            <Button onClick={() => setIsDone(true)} variant="outline-primary">
              Done
            </Button>
          )}
        </Col>
      </Row>
    </DefaultItem>
  );

  let addresses = allAddresses.filter((address) => address.attributes.type === type);
  if (!editing) addresses = addresses.slice().sort((element) => (element.id !== selectedId ? 1 : -1));
  const { totalRecords } = pagination;
  const selectedAddressAttributes = allAddresses.find((address) => address.id === selectedId)?.attributes ?? null;

  const scrollLoader = (
    <Row className="justify-content-center">
      <h4 className="text-secondary">Loading...</h4>
    </Row>
  );

  return (
    <div>
      {isDone ? (
        <Row className="align-items-center px-1">
          <Col xs={10} sm={7} lg={9}>
            {selectedAddressAttributes && <AddressData attributes={selectedAddressAttributes} />}
          </Col>
          <Col xs={2} sm={5} lg={3} className="text-center">
            <Button className="pt-1 d-none d-sm-inline-block" variant="outline-success" onClick={() => setIsDone(false)}>
              Edit Address
              <i className="fa fa-edit ms-2" />
            </Button>
            <IconPointer onClick={() => setIsDone(false)} className="d-sm-none text-success fas fa-edit me-2" />
          </Col>
        </Row>
      ) : (
        <>
          <Row className="mb-1 mb-lg-2 px-2">
            <Col xs={12} lg={8}>
              <input
                type="text"
                value={filters.contact || ""}
                onChange={handleSearchInput}
                placeholder="Search addresses"
                className="form-control"
              />
            </Col>

            <Col xs={12} lg={4} className="mb-2 mb-sm-0 mt-3 mt-md-0">
              {canCreateAddresses(currentUser, customer.id) && (
                <Button onClick={() => setShowFormModal(true)} variant="outline-primary" className="btn-block" id={`add-${type}`}>
                  Add Address
                </Button>
              )}
            </Col>
          </Row>

          <InfiniteScroll
            dataLength={addresses.length}
            height={400}
            next={fetchMoreAddresses}
            hasMore={totalRecords > addresses.length}
            loader={scrollLoader}
          >
            <ListGroupWithScroll id={type}>
              {addresses.length === 0 && <p className="px-2">No addresses</p>}

              {billingAddressId && defaultItem && !isSearching && <ListGroup>{defaultItem}</ListGroup>}

              {addresses.map((address) => (
                <AddressItem
                  type={type}
                  key={address.id}
                  selected={selectedId && address.id === selectedId}
                  address={address}
                  onSelect={onSelect}
                  onEdit={canEdit && handleEdit}
                  onDelete={canDelete && handleDelete}
                  editing={editing}
                  setIsDone={setIsDone}
                />
              ))}
            </ListGroupWithScroll>
          </InfiniteScroll>
        </>
      )}

      {showFormModal && (
        <FormModal
          show={showFormModal}
          address={editingAddress || null}
          type={type}
          onCancel={handleFormModalCancel}
          callback={onSelect}
          customerId={customer?.id}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          address={deletingAddress || null}
          callback={handleDeleteAddress}
          onCancel={handleDeleteModalCancel}
        />
      )}
    </div>
  );
};

AddressSelector.propTypes = {
  type: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired,
  billingAddressId: PropTypes.number,
  handleDeleteAddress: PropTypes.func.isRequired,
  customer: PropTypes.shape().isRequired,
  editing: PropTypes.bool.isRequired,
  shipAddressSameAsBilling: PropTypes.bool.isRequired,
  handleShipSameAsBilling: PropTypes.func.isRequired,
};

AddressSelector.defaultProps = {
  billingAddressId: null,
};

const ListGroupWithScroll = styled(ListGroup)`
  max-height: 600px;
`;

export default AddressSelector;
