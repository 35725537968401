import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useIsMobile from "@ROM-components/utils/Responsive";
import { selectAllPalletsBillOfLadingItems } from "@ROM/Pallets/selectors";
import { selectAllDeliveryOrders } from "@ROM/Deliveries/selectors";
import BillOfLadingApi from "@ROM/BillOfLadings/api";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Spinner from "@ROM-ui/Spinner";
import PropTypes from "prop-types";

const ADD = "ADD";
const SUBSTRACT = "SUBSTRACT";

const PdfRender = ({ currentBillOfLading, orderId, onRenderStateChange }) => {
  const isMobile = useIsMobile();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pdfData, setPdfData] = useState("");

  const billOfLadingItems = useSelector(selectAllPalletsBillOfLadingItems);
  const orders = useSelector(selectAllDeliveryOrders);

  useEffect(() => {
    setLoading(true);
    const handler = setTimeout(async () => {
      if (currentBillOfLading && orders.length > 0) {
        onRenderStateChange(true);
        const data = await BillOfLadingApi.downloadPdf(orderId, currentBillOfLading.id, true);
        setPdfData(data);
        onRenderStateChange(false);
        setLoading(false);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [currentBillOfLading, orders, billOfLadingItems]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleClick = (operation) => {
    if (operation === ADD) {
      if (pageNumber === numPages) return;
      setPageNumber(pageNumber + 1);
    } else {
      if (pageNumber === 1) return;
      setPageNumber(pageNumber - 1);
    }
  };

  const renderDocumentControls = () => {
    const indicatorText = `Page ${pageNumber} of ${numPages}`;
    return (
      <div className="d-flex text-secondary justify-content-center align-items-center">
        <i className="fas fa-chevron-circle-left me-1 cursor-pointer" onClick={() => handleClick(SUBSTRACT)} />
        {indicatorText}
        <i className="fas fa-chevron-circle-right ms-1 cursor-pointer" onClick={() => handleClick(ADD)} />
      </div>
    );
  };

  return (
    !isMobile &&
    (loading ? (
      <Spinner animation="border" variant="secondary" size="lg" className="mt-5" />
    ) : (
      <div>
        <Document file={{ data: pdfData.data }} onLoadSuccess={onDocumentLoadSuccess} noData="">
          <Page pageNumber={pageNumber} />
        </Document>
        {renderDocumentControls()}
      </div>
    ))
  );
};

PdfRender.propTypes = {
  currentBillOfLading: PropTypes.shape().isRequired,
  orderId: PropTypes.number.isRequired,
  onRenderStateChange: PropTypes.func.isRequired,
};

export default PdfRender;
