import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { cleanDecimals } from "@ROM/App/utils/format";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Popover from "@ROM-ui/Popover";
import OverlayTrigger from "@ROM-ui/OverlayTrigger";

const DeliveryItemRow = ({
  item,
  index,
  quantities,
  billOfLadingItems,
  pallets,
  addDeliveryToExistingPallet,
  addDeliveryToNewPallet,
}) => {
  const dragDisabled = quantities?.remaining[item.id] === 0;
  const selectedDeliveryItemIds = billOfLadingItems?.map((bolItem) => bolItem?.attributes?.deliveryItemId?.toString()) ?? [];
  const showRemainingWarning = selectedDeliveryItemIds.includes(item?.id) && quantities?.remaining?.[item.id] > 0;

  const dismissPopOver = () => {
    document.body.click();
  };
  const addToNew = (deliveryItem) => {
    dismissPopOver();
    addDeliveryToNewPallet(deliveryItem);
  };
  const addToExisting = (deliveryItem, palletId) => {
    dismissPopOver();
    addDeliveryToExistingPallet(deliveryItem, palletId);
  };
  const popover = (deliveryItem) => (
    <Popover className="text-secondary" style={{ zIndex: 10 }}>
      <Popover.Header as="h5">Add to pallet...</Popover.Header>
      <Popover.Body className="p-1">
        {pallets.map((item) =>
          item.isLocked ? (
            <div key={item.palletId} className="bg-light rounded mb-1 px-3 py-1 m-1 border text-secondary border-secondary">
              <strong>{item.title}</strong>
              {item.items.map((line) => (
                <div key={line} className="ps-3">
                  {line}
                </div>
              ))}
            </div>
          ) : (
            <button
              key={item.palletId}
              type="button"
              onClick={() => addToExisting(deliveryItem, item.palletId)}
              className="text-start bg-light rounded mb-1 px-3 py-1 w-100 border border-primary"
            >
              <strong>{item.title}</strong>
              {item.items.map((line) => (
                <div key={line} className="ps-3">
                  {line}
                </div>
              ))}
            </button>
          )
        )}
        <button
          type="button"
          onClick={() => addToNew(deliveryItem)}
          className="bg-light rounded px-3 py-1 w-100 border border-primary"
        >
          <strong>... create a new pallet</strong>
        </button>
      </Popover.Body>
    </Popover>
  );

  return (
    <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={dragDisabled}>
      {(provided) => {
        return (
          <>
            <StyledRow
              className="mt-2 mb-2 text-secondary p-md-3 d-flex justify-content-between align-items-center"
              $showRemainingWarning={showRemainingWarning}
              {...provided.draggableProps}
              ref={provided.innerRef}
            >
              <div className="w-100">
                <Row className="m-0 align-items-center">
                  <Col xs={10} md={5} className="my-3 my-md-0">
                    {item.attributes.productName}
                  </Col>
                  <Col xs={2} md={1} className="d-md-none text-end">
                    {!dragDisabled && (
                      <OverlayTrigger trigger="click" placement="bottom" overlay={popover(item)} rootClose>
                        <i className="fas fa-plus" />
                      </OverlayTrigger>
                    )}
                  </Col>
                  <Col xs={3} md={2} className="text-center">
                    <div className="d-md-none small">Units</div>
                    {item.attributes.quantity}
                  </Col>
                  <Col xs={3} md={2} className="text-center">
                    <div className="d-md-none small">Remaining</div>
                    {quantities?.remaining[item.id]}
                  </Col>
                  <Col xs={4} md={2} className="text-center pe-md-0">
                    <div className="d-md-none small">Weight</div>
                    {`${cleanDecimals(item.attributes.productPackageWeight * item.attributes.quantity)} lbs`}
                  </Col>
                  <Col xs={2} md={1} className="p-md-0 text-end text-nowrap">
                    {dragDisabled ? (
                      <i className="fas fa-check" />
                    ) : (
                      <>
                        <i className="fas fa-ellipsis-v me-md-2" {...provided.dragHandleProps} />
                        <div className="cursor-pointer d-none d-md-inline">
                          <OverlayTrigger trigger="click" placement="bottom" overlay={popover(item)} rootClose>
                            <i className="fas fa-plus" />
                          </OverlayTrigger>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </StyledRow>
            {showRemainingWarning && <StyledWarning>There are remaining units.</StyledWarning>}
          </>
        );
      }}
    </Draggable>
  );
};

DeliveryItemRow.propTypes = {
  item: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  quantities: PropTypes.shape().isRequired,
  billOfLadingItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pallets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  addDeliveryToExistingPallet: PropTypes.func.isRequired,
  addDeliveryToNewPallet: PropTypes.func.isRequired,
};

const StyledRow = styled.div`
  border-radius: 5px;
  min-height: 50px;
  background-color: ${(props) => (props.$showRemainingWarning ? "#f9caca" : "#f5f5f5")};
  border: ${(props) => (props.$showRemainingWarning ? "3px solid red" : "")};
`;

const StyledWarning = styled.div`
  color: red;
  background-color: #ffeaea;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 5px;
  margin-left: auto;
`;

export default DeliveryItemRow;
