import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import PropTypes from "prop-types";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { homeUrl } from "@ROM-utils/urlHelpers";

const Authorize = ({ children, permission, redirectTo }) => {
  const currentUser = useSelector(selectCurrentUser);

  if (!permission(currentUser)) {
    return <Redirect to={redirectTo} />;
  }

  return children;
};

Authorize.defaultProps = {
  redirectTo: homeUrl(),
};

Authorize.propTypes = {
  redirectTo: PropTypes.string,
  permission: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Authorize;
