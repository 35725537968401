import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { cartItems } from "@ROM/Cart/OrderItems/selectors";
import InfiniteScroll from "react-infinite-scroll-component";
import { selectProductsPagination } from "@ROM/Products/selectors";
import { getProductsWithScroll, setPage, unsetCurrentProduct } from "@ROM/Products/actions";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import ProductRow from "./ProductRow";
import { selectCartOrder } from "@ROM/Cart/selectors";
import Skeleton from "react-loading-skeleton";

const ProductList = ({
  products,
  productItemsInCurrentOrder,
  nameFilter,
  pagination,
  getProductsWithScroll,
  customer,
  selectedProductId,
  setSelectedProductId,
  setPage,
  setDetailsTabActive,
  handleRemoveItem,
}) => {
  const fetchMoreProducts = () => {
    const page = pagination.currentPage + 1;
    if (page <= pagination.totalPages) {
      setPage(page);
      getProductsWithScroll({ name: nameFilter, page, customerId: customer.id });
    }
  };

  const handleClick = (productId) => {
    setSelectedProductId(productId);
    setDetailsTabActive(true);
  };

  const Loader = (
    <>
      <Skeleton width="100%" height="13%" />
      <Skeleton width="100%" height="13%" />
      <Skeleton width="100%" height="13%" />
      <Skeleton width="100%" height="13%" />
      <Skeleton width="100%" height="13%" />
      <Skeleton width="100%" height="13%" />
    </>
  );

  return (
    <StyledInfiniteScroll
      className="px-2 bg-white"
      dataLength={products.length}
      height={600}
      next={fetchMoreProducts}
      hasMore={products.length !== pagination.totalRecords}
      loader={Loader}
    >
      <>
        <ProductRow />
        {products.map((product) => (
          <ProductRow
            product={product}
            selected={selectedProductId === product.id}
            productItemsInCurrentOrder={productItemsInCurrentOrder}
            key={product.id}
            handleClick={() => handleClick(product.id)}
            handleRemoveItem={handleRemoveItem}
          />
        ))}
      </>
    </StyledInfiniteScroll>
  );
};

ProductList.defaultProps = {
  nameFilter: "",
};

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  productItemsInCurrentOrder: PropTypes.array.isRequired,
  nameFilter: PropTypes.string,
  setPage: PropTypes.func,
  pagination: PropTypes.object,
  getProductsWithScroll: PropTypes.func,
  customer: PropTypes.object,
  setSelectedProductId: PropTypes.func,
  setDetailsTabActive: PropTypes.func,
  handleRemoveItem: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
  return {
    pagination: selectProductsPagination(state),
    productItemsInCurrentOrder: cartItems(state),
    cartOrder: selectCartOrder(state),
  };
};

const dispatchToProps = (dispatch) => {
  return {
    setPage: bindActionCreators(setPage, dispatch),
    getProductsWithScroll: bindActionCreators(getProductsWithScroll, dispatch),
    unsetCurrentProduct: bindActionCreators(unsetCurrentProduct, dispatch),
  };
};

const StyledInfiniteScroll = styled(InfiniteScroll)`
  overflow-y: auto !important;
  overflow-x: hidden !important;
`;

export default connect(stateToProps, dispatchToProps)(ProductList);
