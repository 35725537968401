import React from "react";
import PropTypes from "prop-types";
import Form from "@ROM-ui/Form";
import Col from "@ROM-ui/Col";
import Row from "@ROM-ui/Row";
import Modal from "@ROM-components/common/Modal";

const ActionModal = ({ value, setValue, showModal, onConfirm, onCancel }) => (
  <Modal
    isShowing={showModal}
    confirmText="Continue"
    showCancelButton={false}
    onConfirm={onConfirm}
    onCancel={onCancel}
    header="Do you want to add a message?"
  >
    <Row>
      <Col xs={12} md={12}>
        <Form.Control type="text" as="textarea" rows="3" value={value} onChange={(e) => setValue(e.target.value)} />
      </Col>
    </Row>
  </Modal>
);

ActionModal.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ActionModal;
