import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import useIsMobile from "@ROM-components/utils/Responsive";
import { selectCurrentUser } from "@ROM/Auth/selectors";

import { canChargeOrders } from "@ROM/Orders/permissions";
import {
  canApproveOrders,
  canRejectOrders,
  canConfirmOrders,
  canInProgressOrders,
  canShipOrders,
  canCompleteOrders,
  canInvoiceOrders,
} from "@ROM/OrderStatuses/permissions";

import { selectCurrentCompany } from "@ROM/Company/selectors";
import { selectAllDeliveries } from "@ROM/Deliveries/selectors";
import { isOpened, isApproved, isConfirmed, isInProgress, canBeCharged, isShipped, isInvoiced } from "../../utils";
import Approve from "./ActionSections/Approve";
import Confirm from "./ActionSections/Confirm";
import InProgress from "./ActionSections/InProgress";
import Ship from "./ActionSections/Ship";
import Complete from "./ActionSections/Complete";
import Invoice from "./ActionSections/Invoice";
import Charge from "./ActionSections/Charge";

const ActionSection = ({ order, reloadOrder }) => {
  const isMobile = useIsMobile();
  const [error, setError] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const currentCompany = useSelector(selectCurrentCompany);
  const deliveries = useSelector(selectAllDeliveries);
  const customerId = order?.attributes.customerId || null;

  const hasDeliveries = deliveries.length > 0;
  const forceDeliveryCreation = currentCompany?.attributes?.forceDeliveryCreation;
  const allowComplete = forceDeliveryCreation ? hasDeliveries : true;

  const canApprove = canApproveOrders(currentUser, customerId);
  const canReject = canRejectOrders(currentUser, customerId);

  const approveReject = isOpened(order) && (canApprove || canReject);
  const confirm = isApproved(order) && canConfirmOrders(currentUser, customerId);
  const inProgress = isConfirmed(order) && canInProgressOrders(currentUser, customerId);
  const ship = isInProgress(order) && canShipOrders(currentUser, customerId);
  const invoice = isShipped(order) && canInvoiceOrders(currentUser, customerId);
  const complete = allowComplete && isInvoiced(order) && canCompleteOrders(currentUser, customerId);

  const charge = canBeCharged(order) && canChargeOrders(currentUser, customerId);

  const showActions = approveReject || confirm || inProgress || ship || complete || invoice;

  return (
    <div>
      {showActions && (
        <div className={`bg-white p-2 ${isMobile ? "" : "d-inline-block rounded-2"}`}>
          <div className="d-flex align-items-center">
            <p className="m-0 text-secondary">Update status:</p>
            {approveReject && <Approve order={order} setError={setError} canApprove={canApprove} canReject={canReject} />}
            {confirm && <Confirm order={order} reloadOrder={reloadOrder} setError={setError} />}
            {inProgress && <InProgress order={order} reloadOrder={reloadOrder} setError={setError} />}
            {ship && <Ship order={order} reloadOrder={reloadOrder} setError={setError} />}
            {complete && <Complete order={order} setError={setError} />}
            {invoice && <Invoice order={order} reloadOrder={reloadOrder} setError={setError} />}
          </div>
          {error && <p className="text-danger m-0 mt-2">Something went wrong, please try again later</p>}
        </div>
      )}
      {charge && (
        <div>
          <div className={`bg-white mt-3 p-2 ${isMobile ? "" : "d-inline-block rounded-2"}`}>
            <Charge order={order} reloadOrder={reloadOrder} />
          </div>
        </div>
      )}
    </div>
  );
};

ActionSection.propTypes = {
  order: PropTypes.shape().isRequired,
  reloadOrder: PropTypes.func.isRequired,
};

export default ActionSection;
