import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import AccountRoute from "@ROM-pages/Auth/Account/Index/Route";
import InvitationsTable from "@ROM-pages/Auth/Invitations/Route";
import SuccessRegistration from "@ROM-pages/Auth/Success/Route";
import CompanyAdminRoute from "@ROM-pages/CompanyAdmin/Index/Route";
import CompanyAdminProductsRoute from "@ROM-pages/CompanyAdmin/Products/Index/Route";
import CompanyAdminProductsShowRoute from "@ROM-pages/CompanyAdmin/Products/Show/Route";
import CompanyAdminProductsEditRoute from "@ROM-pages/CompanyAdmin/Products/Edit/Route";
import CompanyAdminCategoriesRoute from "@ROM-pages/CompanyAdmin/Categories/Route";
import CompanyAdminCustomTablesCreateRoute from "@ROM-pages/CompanyAdmin/CustomTables/New/Route";
import CompanyAdminCustomTablesShowRoute from "@ROM-pages/CompanyAdmin/CustomTables/Show/Route";
import CompanyAdminCustomTablesEditRoute from "@ROM-pages/CompanyAdmin/CustomTables/Edit/Route";
import CompanyAdminCustomTablesCreateRowRouter from "@ROM-pages/CompanyAdmin/CustomTableRows/New/Route";
import CompanyAdminCustomTablesEditRowRouter from "@ROM-pages/CompanyAdmin/CustomTableRows/Edit/Route";
import CompanyAdminProductsCreateRoute from "@ROM-pages/CompanyAdmin/Products/New/Route";
import CompanyAdminCustomersRoute from "@ROM-pages/CompanyAdmin/Customers/Index/Route";
import CompanyAdminCustomersCreateRoute from "@ROM-pages/CompanyAdmin/Customers/New/Route";
import CompanyAdminCustomersManageRoute from "@ROM-pages/CompanyAdmin/Customers/Edit/Route";
import CompanyAdminPriceListsRoute from "@ROM-pages/CompanyAdmin/PriceLists/Index/Route";
import CompanyAdminUsersRoute from "@ROM-pages/CompanyAdmin/Users/Index/Route";
import CompanyAdminUsersEditRoute from "@ROM-pages/CompanyAdmin/Users/Edit/Route";
import CompanyAdminWarehousesRoute from "@ROM-pages/CompanyAdmin/Warehouses/Index/Route";
import CompanyAdminWarehousesCreateRoute from "@ROM-pages/CompanyAdmin/Warehouses/New/Route";
import CompanyAdminWarehousesEditRoute from "@ROM-pages/CompanyAdmin/Warehouses/Edit/Route";
import CompanyAdminPriceListShowRoute from "@ROM-pages/CompanyAdmin/PriceLists/Show/Route";
import CustomerAdminRoute from "@ROM-pages/CustomerAdmin/Index/Route";
import CustomerAdminCreateRoute from "@ROM-pages/CustomerAdmin/New/Route";
import CustomerAdminManageRoute from "@ROM-pages/CustomerAdmin/EditCustomer/Route";
import InventoriesIndexRoute from "@ROM-pages/Inventories/Index/Route";
import OrdersNewRoute from "@ROM-pages/Orders/New/Route";
import OrdersEditRoute from "@ROM-pages/Orders/Edit/Route";
import OrdersIndexRoute from "@ROM-pages/Orders/Index/Route";
import OrdersShowRoute from "@ROM-pages/Orders/Show/Route";
import DeliveriesIndexRoute from "@ROM-pages/Deliveries/Index/Route";
import DeliveriesEditRoute from "@ROM-pages/Deliveries/Edit/Route";
import BillsOfLadingNewRoute from "@ROM-pages/BillOfLadings/New/Route";
import BillsOfLadingEditRoute from "@ROM-pages/BillOfLadings/Edit/Route";
import { fetchCustomer } from "@ROM/Customers/actions";
import { bindActionCreators } from "redux";
import { selectCurrentUserId } from "@ROM/Auth/selectors";
import { fetchUser, fetchAllRoles } from "@ROM/Auth/actions";
import { list } from "@ROM/Regions/actions";
import LoadingSplash from "@ROM-components/LoadingSplash/index";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Config from "Config";

import PropTypes from "prop-types";
import "react-day-picker/lib/style.css";

import {
  homeUrl,
  inventoriesUrl,
  ordersUrl,
  newOrderUrl,
  accountUrl,
  loginUrl,
  companyAdminUrl,
  companyAdminProductsUrl,
  companyAdminProductsShowUrl,
  companyAdminProductsEditUrl,
  companyAdminProductsCreateUrl,
  companyAdminCategoriesUrl,
  companyAdminCustomTablesCreateUrl,
  companyAdminCustomTablesShowUrl,
  companyAdminCustomTablesEditUrl,
  companyAdminCustomTablesAddRowUrl,
  companyAdminCustomTablesEditRowUrl,
  companyAdminCustomersUrl,
  companyAdminCustomersManageUrl,
  companyAdminCustomersCreateUrl,
  companyAdminUsersUrl,
  companyAdminUsersManageUrl,
  orderShowUrl,
  ordersEditUrl,
  deliveriesUrl,
  invitationsUrl,
  successUrl,
  customerAdminUrl,
  customerAdminCreateCustomerUrl,
  customerAdminManageCustomerUrl,
  companyAdminPriceListsUrl,
  companyAdminWarehousesUrl,
  companyAdminWarehousesCreateUrl,
  companyAdminWarehousesEditUrl,
  editDeliveryUrl,
  newBillOfLadingUrl,
  editBillOfLadingUrl,
  companyAdminPriceListShowUrl,
} from "@ROM-utils/urlHelpers";

import GlobalStyles from "./globalStyles";

const STRIPE_PUBLIC_KEY = Config.STRIPE_PUBLIC_KEY;
const promise = loadStripe(STRIPE_PUBLIC_KEY);

const App = ({ currentUser, actions }) => {
  if (currentUser) {
    actions.fetchCustomer();
    actions.fetchRegions();
    actions.fetchAllRoles();

    if (localStorage.getItem("token") !== null) {
      localStorage.removeItem("token");
    }
    if (localStorage.getItem("companyName") !== null) {
      localStorage.removeItem("companyName");
    }
  } else {
    const promise = actions.fetchUser();
    promise
      .then((response) => {
        if (response.payload.status === 401) {
          window.location.href = loginUrl();
        }
      })
      .catch(() => {
        window.location.href = loginUrl();
      });
  }

  return (
    <>
      <GlobalStyles />
      {!currentUser ? (
        <LoadingSplash />
      ) : (
        <Elements stripe={promise}>
          <Router>
            <Switch>
              <InventoriesIndexRoute exact path={inventoriesUrl()} />
              <OrdersIndexRoute exact path={homeUrl()} />
              <OrdersIndexRoute exact path={ordersUrl()} />
              <OrdersNewRoute exact path={newOrderUrl()} />
              <OrdersShowRoute exact path={orderShowUrl()} />
              <DeliveriesIndexRoute exact path={deliveriesUrl()} />
              <AccountRoute exact path={accountUrl()} />
              <CompanyAdminRoute exact path={companyAdminUrl()} />
              <CompanyAdminProductsRoute exact path={companyAdminProductsUrl()} />
              <CompanyAdminProductsCreateRoute exact path={companyAdminProductsCreateUrl()} />
              <CompanyAdminProductsShowRoute exact path={companyAdminProductsShowUrl()} />
              <CompanyAdminProductsEditRoute exact path={companyAdminProductsEditUrl()} />
              <CompanyAdminCategoriesRoute exact path={companyAdminCategoriesUrl()} />
              <CompanyAdminCustomTablesCreateRoute exact path={companyAdminCustomTablesCreateUrl()} />
              <CompanyAdminCustomTablesShowRoute exact path={companyAdminCustomTablesShowUrl()} />
              <CompanyAdminCustomTablesEditRoute exact path={companyAdminCustomTablesEditUrl()} />
              <CompanyAdminCustomTablesCreateRowRouter exact path={companyAdminCustomTablesAddRowUrl()} />
              <CompanyAdminCustomTablesEditRowRouter exact path={companyAdminCustomTablesEditRowUrl()} />
              <CompanyAdminCustomersRoute exact path={companyAdminCustomersUrl()} />
              <CompanyAdminCustomersCreateRoute exact path={companyAdminCustomersCreateUrl()} />
              <CompanyAdminCustomersManageRoute exact path={companyAdminCustomersManageUrl()} />
              <CompanyAdminPriceListsRoute exact path={companyAdminPriceListsUrl()} />
              <CompanyAdminUsersRoute exact path={companyAdminUsersUrl()} />
              <CompanyAdminUsersEditRoute exact path={companyAdminUsersManageUrl()} />
              <CompanyAdminPriceListShowRoute exact path={companyAdminPriceListShowUrl()} />
              <OrdersEditRoute exact path={ordersEditUrl()} />
              <InvitationsTable exact path={invitationsUrl()} />
              <SuccessRegistration exact path={successUrl()} />
              <CustomerAdminRoute exact path={customerAdminUrl()} />
              <CustomerAdminCreateRoute exact path={customerAdminCreateCustomerUrl()} />
              <CustomerAdminManageRoute exact path={customerAdminManageCustomerUrl()} />
              <CompanyAdminWarehousesRoute exact path={companyAdminWarehousesUrl()} />
              <CompanyAdminWarehousesCreateRoute exact path={companyAdminWarehousesCreateUrl()} />
              <CompanyAdminWarehousesEditRoute exact path={companyAdminWarehousesEditUrl()} />
              <DeliveriesEditRoute exact path={editDeliveryUrl()} />
              <BillsOfLadingNewRoute exact path={newBillOfLadingUrl()} />
              <BillsOfLadingEditRoute exact path={editBillOfLadingUrl()} />
            </Switch>
          </Router>
        </Elements>
      )}
    </>
  );
};

const stateToProps = (state) => {
  return {
    currentUser: selectCurrentUserId(state),
  };
};

const dispatchToProps = (dispatch) => {
  return {
    actions: {
      fetchCustomer: bindActionCreators(fetchCustomer, dispatch),
      fetchUser: bindActionCreators(fetchUser, dispatch),
      fetchRegions: bindActionCreators(list, dispatch),
      fetchAllRoles: bindActionCreators(fetchAllRoles, dispatch),
    },
  };
};

export default connect(stateToProps, dispatchToProps)(App);

App.propTypes = {
  isVerifyingToken: PropTypes.bool,
  currentUser: PropTypes.string,
};
