import React, { useReducer } from "react";
import PropTypes from "prop-types";

import { reducer as formReducer } from "@ROM/App/utils/forms";
import Form from "@ROM-ui/Form";
import Button from "@ROM-ui/Button";
import Fieldset from "@ROM-ui/Fieldset";

const PasswordResetForm = ({ updatePassword }) => {
  const initialValidation = { password: null, passwordConfirmation: null };
  const [validationErrors, setValidationErrors] = useReducer(formReducer, initialValidation);
  const initialData = { password: "", passwordConfirmation: "" };
  const [formData, updateFormData] = useReducer(formReducer, initialData);

  const onSubmit = () => {
    if (formData.password.length < 7) {
      setValidationErrors({
        password: "Password must be at least 7 characters long",
        passwordConfirmation: null,
      });
    } else if (formData.password !== formData.passwordConfirmation) {
      setValidationErrors({ password: null, passwordConfirmation: "Passwords do not match" });
    } else {
      setValidationErrors(initialValidation);
      updatePassword(formData.password);
    }
  };

  return (
    <Form className="mt-4">
      <Fieldset legend="Change Password">
        <Form.Group className="mb-3">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            required
            isInvalid={!!validationErrors.password}
            type="password"
            value={formData.password}
            onChange={(e) => updateFormData({ password: e.target.value })}
            autoComplete="new-password"
          />
          <Form.Control.Feedback type="invalid">{validationErrors.password}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control
            required
            isInvalid={!!validationErrors.passwordConfirmation}
            type="password"
            autoComplete="new-password"
            value={formData.passwordConfirmation}
            onChange={(e) => updateFormData({ passwordConfirmation: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">{validationErrors.passwordConfirmation}</Form.Control.Feedback>
        </Form.Group>
        <Button variant="primary" onClick={onSubmit}>
          Update
        </Button>
      </Fieldset>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  updatePassword: PropTypes.func.isRequired,
};

export default PasswordResetForm;
