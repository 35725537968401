import React from "react";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { create as createDeliveryItem, update as updateDeliveryItem } from "@ROM/Deliveries/DeliveryItems/actions";
import Popover from "@ROM-ui/Popover";
import OverlayTrigger from "@ROM-ui/OverlayTrigger";
import { create as createDelivery } from "@ROM/Deliveries/actions";

const ItemRow = ({ item, quantities, setQuantities, deliveries = [], orderId, deliveryItems }) => {
  const dispatch = useDispatch();

  const newDeliveryItem = async (deliveryId) => {
    if (!(deliveryId in quantities.selected)) quantities.selected[deliveryId] = {};
    if (quantities.selected[deliveryId][item.id] === undefined) {
      quantities.selected[deliveryId][item.id] = 0;
      await dispatch(
        createDeliveryItem({
          orderItemId: item.id,
          deliveryId: deliveryId,
          quantity: quantities.remaining[item.id],
        })
      );
      quantities.selected[deliveryId][item.id] = quantities.remaining[item.id];
    } else {
      let deliveryItem = deliveryItems.find(
        (deliveryItem) =>
          deliveryItem.attributes.orderItemId.toString() === item.id &&
          deliveryItem.attributes.deliveryId.toString() === deliveryId
      );
      quantities.selected[deliveryId][item.id] += quantities.remaining[item.id];
      deliveryItem = {
        ...deliveryItem,
        attributes: { ...deliveryItem.attributes, quantity: quantities.selected[deliveryId][item.id] },
      };
      await dispatch(updateDeliveryItem(deliveryItem));
    }
    quantities.remaining[item.id] = 0;
    setQuantities({ ...quantities });
  };

  const handleAdd = async (deliveryId) => {
    if (!deliveryId) {
      const response = await dispatch(createDelivery({ orderId }));
      newDeliveryItem(response.payload.data.data.id);
    } else {
      newDeliveryItem(deliveryId);
    }
  };

  const popover = (
    <Popover className="text-secondary" style={{ zIndex: 10000 }}>
      <Popover.Header as="h5">Allocate to...</Popover.Header>
      <Popover.Body className="p-1">
        {deliveries?.map((delivery, index) => (
          <StyledDeliverySelector onClick={() => handleAdd(delivery?.id ?? null)} key={delivery.id}>
            <p className="m-0 p-2">{`Delivery ${index + 1}`}</p>
          </StyledDeliverySelector>
        ))}
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="d-flex w-100 bg-light mt-2 mb-2 rounded text-secondary p-3 justify-content-between align-items-center">
      <div className="w-100">
        <Row className="m-0 align-items-center">
          <Col xs={5}>
            <strong>{item.attributes.productName}</strong>
          </Col>
          <Col xs={3} className="text-md-center">
            <strong>{item.attributes.quantity}</strong>
          </Col>
          <Col xs={3} className="text-md-center">
            <strong>{quantities.remaining[item.id]}</strong>
          </Col>
        </Row>
        {item.attributes.notes && (
          <Row className="m-0">
            <Col>
              <p className="m-0 mt-1 font-italic fw-light">{item.attributes.notes}</p>
            </Col>
          </Row>
        )}
      </div>
      {quantities.remaining[item.id] === 0 ? (
        <StyledCheck className="fas fa-check me-1" />
      ) : (
        <OverlayTrigger trigger={deliveries?.length > 1 ? "click" : ""} placement="right" overlay={popover} rootClose>
          <StyledPlus
            className="fas fa-plus-circle cursor-pointer cursor-pointer"
            onClick={deliveries?.length > 1 ? null : () => handleAdd(deliveries[0]?.id ?? null)}
          />
        </OverlayTrigger>
      )}
    </div>
  );
};

ItemRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    attributes: {
      productName: PropTypes.string,
      notes: PropTypes.string,
    },
  }).isRequired,
  quantities: PropTypes.shape.isRequired,
  setQuantities: PropTypes.func.isRequired,
  deliveries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  orderId: PropTypes.number.isRequired,
  deliveryItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const StyledPlus = styled.i`
  font-size: x-large;
`;

const StyledCheck = styled.i`
  font-size: large;
`;

const StyledDeliverySelector = styled.div`
  cursor: pointer;
  padding-right: 5rem;
  text-align: left;
  border-radius: 8px;
  :hover {
    background-color: #d5e7f8;
  }
`;

export default ItemRow;
