import React from "react";
import PropTypes from "prop-types";

import { reducedByGroup } from "@ROM/CompanyRoles/utils";
import ItemsGroup from "@ROM/CompanyAdmin/Company/Roles/ItemsGroup";

const ItemsGroupsList = ({ className, itemsName, items, selectedItems, add, remove, updateScope }) => {
  const groupedItems = reducedByGroup(items);

  return (
    <div className={className}>
      <h3 className="mb-4">{itemsName}</h3>
      {Object.entries(groupedItems).map(([group, groupItems]) => (
        <ItemsGroup
          key={group}
          groupName={group}
          items={groupItems}
          selectedItems={selectedItems}
          add={add}
          remove={remove}
          updateScope={updateScope}
          className="mb-4"
        />
      ))}
    </div>
  );
};

ItemsGroupsList.defaultProps = {
  className: "",
};

ItemsGroupsList.propTypes = {
  className: PropTypes.string,
  itemsName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  updateScope: PropTypes.func.isRequired,
};

export default ItemsGroupsList;
