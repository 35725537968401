import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router";
import BillOfLadingForm from "@ROM/BillOfLadings/components/Form";
import TitledHeader from "@ROM-components/common/TitledHeader";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { find } from "@ROM/BillOfLadings/actions";
import { canEditBillOfLadings, canEditBillOfLadingsAllParams } from "@ROM/BillOfLadings/permissions";
import { list } from "@ROM/Deliveries/actions";
import { selectCurrentBillOfLading, selectCurrentBillOfLadingOrders } from "@ROM/BillOfLadings/selectors";
import { homeUrl, orderUrl } from "@ROM-utils/urlHelpers";

const Edit = ({ orderId, deliveryId, billOfLadingId }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const billOfLading = useSelector(selectCurrentBillOfLading);
  const bolOrders = useSelector(selectCurrentBillOfLadingOrders);
  const currentUser = useSelector(selectCurrentUser);
  const canEdit = canEditBillOfLadings(currentUser);
  const canEditAllParams = !!canEditBillOfLadingsAllParams(currentUser);

  useEffect(() => {
    if (canEdit) {
      setLoading(true);
      dispatch(find(billOfLadingId));
    }
  }, [dispatch, billOfLadingId]);

  useEffect(() => {
    if (bolOrders.length > 0) {
      const orderIds = bolOrders.map((each) => each.id);

      const fetchBillOfLadingData = async () => {
        await dispatch(list({ orderId: orderIds }));
        setLoading(false);
      };

      fetchBillOfLadingData();
    }
  }, [dispatch, bolOrders]);

  if (!canEdit) {
    return <Redirect to={homeUrl()} />;
  }

  return (
    <>
      <TitledHeader title="Bill of Lading" backLinkTitle="Back to Order" backUrl={orderUrl(orderId)} />
      <BillOfLadingForm
        user={currentUser}
        deliveryId={deliveryId}
        orderId={orderId}
        currentBillOfLading={billOfLading}
        loading={loading}
        canEditAllParams={canEditAllParams}
      />
    </>
  );
};

Edit.propTypes = {
  orderId: PropTypes.number.isRequired,
  deliveryId: PropTypes.number.isRequired,
  billOfLadingId: PropTypes.number.isRequired,
};

export default Edit;
