import { createSelector } from "reselect";

const palletsState = (state) => state.pallets;

export const selectAllPallets = createSelector(palletsState, (pallets) => pallets?.all?.items);

export const selectAllPalletsBillOfLadingItems = createSelector(palletsState, (pallets) =>
  pallets?.all?.included?.filter((included) => included?.type === "billOfLadingItem")
);

export const selectPalletsLoading = createSelector(palletsState, (pallets) => pallets?.loading);
