import React from "react";
import { Route } from "react-router-dom";

import Page from "@ROM-pages/CompanyAdmin/CustomTableRows/Edit";
import Layout from "@ROM-pages/Layouts/CompanyAdmin";
import Authorize from "@ROM/Auth/components/Authorize";
import { canManageCustomTables } from "@ROM/CustomTables/permissions";

class RouteHandler extends Route {
  render() {
    return (
      <Layout>
        <Authorize permission={canManageCustomTables}>
          <Page
            customTableId={this.props.computedMatch.params.customTableId}
            customTableRowId={this.props.computedMatch.params.customTableRowId}
          />
        </Authorize>
      </Layout>
    );
  }
}

export default RouteHandler;
