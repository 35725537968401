import React from "react";
import { Route } from "react-router-dom";
import Page from "@ROM-pages/Deliveries/Index";
import Layout from "@ROM-pages/Layouts/Authenticated";

class RouteHandler extends Route {
  static displayName = "Route/Deliveries/Index";

  render() {
    return (
      <Layout expanded>
        <Page />
      </Layout>
    );
  }
}

export default RouteHandler;
