import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/notifications";
  },
};

export default {
  fetchNotifications() {
    const client = getApiClient(API_URL);
    return client.get(urls.collection());
  },
};
