import update from "immutability-helper";
import { handle } from "redux-pack";
import { SHIP, DELIVER } from "@ROM/DeliveryStatuses/actions";
import { LIST, SET_PAGE, FIND } from "./actions";

const initialState = {
  loading: false,
  current: {
    order: null,
    included: [],
  },
  all: {
    orders: [],
    orderStatuses: [],
    customers: [],
    relationships: [],
  },
  /* Pagination data returned from an API response */
  pagination: {
    currentPage: 1,
    perPage: null,
    totalPages: null,
    totalRecords: null,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {}; // redux-pack puts API response in its own 'data' attribute, renaming to 'json'

  switch (type) {
    case SET_PAGE:
      return update(state, {
        pagination: {
          currentPage: { $set: payload.page },
        },
      });

    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              orders: { $set: [] },
              relationships: { $set: [] },
            },
            loading: { $set: true },
          });
        },

        finish: (prevState) => {
          return update(prevState, {
            loading: { $set: false },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              orders: { $set: json.data },
              relationships: { $set: json.included },
            },
            pagination: { $set: json.meta.pagination },
          });
        },
      });

    case FIND:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            loading: { $set: true },
          });
        },

        finish: (prevState) => {
          return update(prevState, {
            loading: { $set: false },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            current: {
              order: { $set: json.data },
              included: { $set: json.included },
            },
          });
        },
      });

    case SHIP:
    case DELIVER:
      return handle(state, action, {
        success: (prevState) => {
          const order = json.included.find((inc) => inc.type === "order");
          return update(prevState, {
            current: {
              order: { $set: order },
            },
          });
        },
      });

    default:
      return state;
  }
}

export default reducer;
