/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import OverlayTrigger from "@ROM-ui/OverlayTrigger";
import Popover from "@ROM-ui/Popover";

import { selectCurrentCompany } from "@ROM/Company/selectors";

const ListItem = ({ children, hint, hintPlacement, ...props }) => {
  const item = <ListItemContainer {...props}>{children}</ListItemContainer>;
  const hintPopover = () => (
    <Popover>
      <Popover.Body>{hint}</Popover.Body>
    </Popover>
  );
  return hint ? (
    <OverlayTrigger placement={hintPlacement} overlay={hintPopover()} delay={{ show: 500, hide: 50 }}>
      {item}
    </OverlayTrigger>
  ) : (
    item
  );
};

const ListItemCreate = ({ text, ...props }) => {
  const currentCompany = useSelector(selectCurrentCompany);

  return (
    <ListItemCreateContainer $accentColor={currentCompany.attributes.accentColor} role="button" tabIndex={0} {...props}>
      <span>{text}</span>
      <i className="fas fa-plus-circle fa-lg" />
    </ListItemCreateContainer>
  );
};

const ListItemContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0.5rem 1rem;
  align-items: center;
  background-color: ${(props) => (props.isActive ? "#EBF7FF" : "#fafafa")};
  border: ${(props) => (props.isActive ? "1px solid #70B6EB" : "1px solid #e5e3e3")};
  border-radius: 10px;
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    background-color: ${(props) => (props.isActive ? "" : "#f5f5f5")};
  }
`;

const ListItemCreateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.$accentColor};
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 1px solid ${(props) => props.$accentColor};
  border-radius: 10px;
  transition: all 0.35s;

  &:hover {
    box-shadow: 6px 6px 30px 3px rgba(0, 0, 0, 0.07);
  }
`;

ListItem.defaultProps = {
  hint: null,
  hintPlacement: "bottom",
};

ListItem.propTypes = {
  hint: PropTypes.string,
  hintPlacement: PropTypes.string,
  children: PropTypes.element.isRequired,
};

ListItemCreate.propTypes = {
  text: PropTypes.string.isRequired,
};

export { ListItem, ListItemCreate };
