import { apiAction } from "@ROM-utils/actions";

import BillOfLadingPalletsApi from "./api";

export const DESTROY = "bill_of_lading_pallets/api/DESTROY";
export const CREATE = "bill_of_lading_pallets/api/CREATE";
export const LIST = "bill_of_lading_pallets/api/LIST";
export const UPDATE = "bill_of_lading_pallets/api/UPDATE";

export const destroy = (billOfLadingId, palletId, meta = {}) => {
  return apiAction(DESTROY, BillOfLadingPalletsApi.destroy(billOfLadingId, palletId), {
    ...meta,
    palletId,
  });
};

export const create = (attributes = {}) => {
  return apiAction(CREATE, BillOfLadingPalletsApi.create(attributes.billOfLadingId, attributes));
};

export function list(billOfLadingId, options = {}) {
  return apiAction(LIST, BillOfLadingPalletsApi.list(billOfLadingId, options));
}

export function update(billOfLadingId, palletId, attributes, options = {}) {
  return apiAction(UPDATE, BillOfLadingPalletsApi.update(billOfLadingId, palletId, attributes), options);
}
