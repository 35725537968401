import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectProductInventories } from "@ROM/Products/selectors";
import { ActiveBadge } from "@ROM-ui/Badge";
import ProductUnitsLabel from "@ROM/CompanyAdmin/Products/components/ProductUnitsLabel";
import productPlaceholder from "@ROM-images/productPlaceholder";
import Inventory from "@ROM-images/inventory";

const ProductRow = ({ product, onClick }) => {
  const inventories = useSelector(selectProductInventories(product.id));

  return (
    <tr className="cursor-pointer" id={`company-admin-product-${product.id}`} onClick={() => onClick(product)}>
      <td className="text-nowrap mb-4 mb-md-0">
        {product.attributes.primaryImage ? (
          <img alt={product.attributes.name} src={product.attributes.primaryImage.file.icon.url} />
        ) : (
          <img alt={product.attributes.name} src={productPlaceholder} width="30px" height="40px" style={{ margin: "0 5px" }} />
        )}
        <span className="ms-2">{product.attributes.name}</span>
      </td>
      <td className="d-flex align-items-start flex-column flex-md-row">
        <ActiveBadge isActive={product.attributes.isActive} className="me-0 me-md-4 mb-4 mb-md-0" />
        <div className="d-flex flex-wrap gap-3" style={{ maxWidth: "750px" }}>
          {inventories.map((inventory) => (
            <ProductUnitsLabel
              text={`Warehouse\n${inventory.attributes.sageWarehouseId}`}
              unit={Number(inventory.attributes.quantityLeft)}
              icon={Inventory}
            />
          ))}
        </div>
      </td>
    </tr>
  );
};

ProductRow.propTypes = {
  product: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProductRow;
