import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/users";
  },

  resource(id) {
    return `/users/${id}`;
  },
};

export default {
  find(id) {
    const client = getApiClient(API_URL);
    return client.get(urls.resource(id));
  },

  update(id, user) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(id), user);
  },

  list(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(), {
      params: {
        ...options,
      },
    });
  },

  updateCompanyRoles(id, companyRoleNames) {
    const client = getApiClient(API_URL);
    return client.put(`${urls.resource(id)}/update_company_roles`, { id, roles: companyRoleNames });
  },

  addToCustomer(id, params) {
    const client = getApiClient(API_URL);
    return client.post(`${urls.resource(id)}/add_customer`, params);
  },

  removeFromCustomer(id, customerId) {
    const client = getApiClient(API_URL);
    return client.post(`${urls.resource(id)}/remove_customer`, { customerId });
  },

  updateCustomerAdminValue(id, params) {
    const client = getApiClient(API_URL);
    return client.post(`${urls.resource(id)}/update_customer_role`, params);
  },

  updateDisabledNotifications(id, params) {
    const client = getApiClient(API_URL);
    return client.put(`${urls.resource(id)}/update_disabled_notifications`, params);
  },
};
