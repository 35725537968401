import { checkPermission } from "@ROM/Permissions/utils";

export const canAddOrderNotes = (user, customerId) => checkPermission(user, "orders:statuses:add-notes", customerId);
export const canApproveOrders = (user, customerId) => checkPermission(user, "orders:statuses:approve", customerId);
export const canRejectOrders = (user, customerId) => checkPermission(user, "orders:statuses:reject", customerId);
export const canCancelUnapprovedOrders = (user, customerId) =>
  checkPermission(user, "orders:statuses:cancel-unapproved", customerId);
export const canCancelOrders = (user, customerId) => checkPermission(user, "orders:statuses:cancel", customerId);
export const canConfirmOrders = (user, customerId) => checkPermission(user, "orders:statuses:confirm", customerId);
export const canInProgressOrders = (user, customerId) => checkPermission(user, "orders:statuses:in-progress", customerId);
export const canShipOrders = (user, customerId) => checkPermission(user, "orders:statuses:ship", customerId);
export const canCompleteOrders = (user, customerId) => checkPermission(user, "orders:statuses:complete", customerId);
export const canInvoiceOrders = (user, customerId) => checkPermission(user, "orders:statuses:invoice", customerId);
