import React, { useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import { OrganizationPanel, BillingPanel, OrdersPanel, AttachmentsPanel } from "@ROM/CustomerAdmin/components/Panels";
import { Tabs, TabItem } from "@ROM-components/common/AdminTabs";
import { canManageCustomerAttachments } from "@ROM/Customers/permissions";
import { selectCurrentUser } from "@ROM/Auth/selectors";

const ORGANIZATION = "organization";
const ORDERS = "orders";
const BILLING = "billing";
const ATTACHMENTS = "attachments";

const Edit = ({ tab, setTab, orders, ordersLoading, pagination, handlePageClick }) => {
  const customer = useContext(CustomerContext);
  const currentUser = useSelector(selectCurrentUser);

  const activeName = [ORGANIZATION, ORDERS, BILLING, ATTACHMENTS].find((name) => name === tab) || ORDERS;

  const getContent = (contentFor) => {
    // eslint-disable-next-line default-case
    switch (contentFor) {
      case ORGANIZATION:
        return <OrganizationPanel />;
      case ORDERS:
        return <OrdersPanel orders={orders} loading={ordersLoading} pagination={pagination} handlePageClick={handlePageClick} />;
      case BILLING:
        return <BillingPanel orders={orders} pagination={pagination} handlePageClick={handlePageClick} />;
      case ATTACHMENTS:
        return <AttachmentsPanel />;
    }
    return null;
  };

  return (
    <>
      <Tabs>
        <TabItem value="Orders" iconClasses="fa fa-box-open" name={ORDERS} activeName={activeName} setActiveName={setTab} />
        <TabItem
          value="Organization"
          iconClasses="fa fa-user-friends"
          name={ORGANIZATION}
          activeName={activeName}
          setActiveName={setTab}
          id="organization-sidebar-item"
        />
        {canManageCustomerAttachments(currentUser, customer?.id) && (
          <TabItem
            value="Attachments"
            iconClasses="fa fa-paperclip"
            name={ATTACHMENTS}
            activeName={activeName}
            setActiveName={setTab}
          />
        )}
        <TabItem
          value="Billing"
          iconClasses="fa fa-file-invoice-dollar"
          name={BILLING}
          activeName={activeName}
          setActiveName={setTab}
        />
      </Tabs>

      {getContent(activeName)}
    </>
  );
};

Edit.propTypes = {
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  ordersLoading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape().isRequired,
  handlePageClick: PropTypes.func.isRequired,
};

export default Edit;
