import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import useIsMobile from "@ROM-components/utils/Responsive";

import Col from "@ROM-ui/Col";
import Row from "@ROM-ui/Row";
import Panel from "@ROM-ui/Panel";
import ContentHeader from "@ROM-components/common/ContentHeader";
import OrderStatusBadge from "@ROM/OrderStatuses/components/OrderStatusBadge";

const PageHeader = ({ customerName, orderInvoice, orderStatus, orderCreator, orderCreatedAt, actionSection, iconsMenu }) => {
  const isMobile = useIsMobile();

  const createdDate = orderCreatedAt && moment(orderCreatedAt).format("MM/DD/YYYY");

  return (
    <>
      <ContentHeader>
        <ContentHeader.Left>
          {isMobile ? (
            <div className="text-white">
              <div>{`For: ${customerName || ""}`}</div>
              {orderStatus && <OrderStatusBadge status={orderStatus} />}
            </div>
          ) : (
            <div className="text-white">
              <h1>
                <strong>My Order</strong>
              </h1>
              <h3>
                <strong>{`For: ${customerName || ""}`}</strong>
              </h3>
              {orderStatus && (
                <h5>
                  <OrderStatusBadge status={orderStatus} />
                </h5>
              )}
              {actionSection && <div>{actionSection}</div>}
            </div>
          )}
        </ContentHeader.Left>
        <ContentHeader.Right>
          {isMobile ? (
            <div className="text-white">
              <div>{orderInvoice || ""}</div>
              {createdDate && <div className="small">{createdDate}</div>}
              {orderCreator && <div className="small">{`Created By: ${orderCreator}`}</div>}
            </div>
          ) : (
            <div className="text-white d-none d-sm-block">
              <h3>
                <strong>Sales Order</strong>
              </h3>
              <h5>{orderInvoice || ""}</h5>
              {createdDate && <h5>{createdDate}</h5>}
              {orderCreator && <h5>{`Created By: ${orderCreator}`}</h5>}
              {iconsMenu && <Panel className="d-inline-flex mt-1">{iconsMenu}</Panel>}
            </div>
          )}
        </ContentHeader.Right>
      </ContentHeader>
      {isMobile && iconsMenu && (
        <Row className="bg-white">
          <Col className="d-inline-flex my-2 justify-content-end">{iconsMenu}</Col>
        </Row>
      )}
      {isMobile && actionSection && (
        <Row className="bg-white">
          <Col>{actionSection}</Col>
        </Row>
      )}
    </>
  );
};

PageHeader.propTypes = {
  customerName: PropTypes.string,
  orderInvoice: PropTypes.string,
  orderStatus: PropTypes.string,
  orderCreator: PropTypes.string,
  orderCreatedAt: PropTypes.string,
  actionSection: PropTypes.node,
  iconsMenu: PropTypes.node,
};

PageHeader.defaultProps = {
  customerName: null,
  orderInvoice: null,
  orderStatus: null,
  orderCreator: null,
  orderCreatedAt: null,
  actionSection: null,
  iconsMenu: null,
};

export default PageHeader;
