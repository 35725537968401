import { createStore, applyMiddleware, compose } from 'redux'
import { middleware as reduxPackMiddleware } from 'redux-pack'
import reducers from '@ROM/App/reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;
const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(reduxPackMiddleware)
  )
);

export default store;