import React from "react";
import StyledRow from "@ROM/CustomerAdmin/components/common/StyledRow";
import NumberFormater from "@ROM-ui/NumberFormater";
import Button from "@ROM-ui/Button";
import Badge from "@ROM-ui/Badge";
import { orderUrl } from "@ROM-utils/urlHelpers";
import { useHistory } from "react-router-dom";
import Pagination from "@ROM-components/common/Pagination";

const List = ({ orders, pagination, handlePageClick }) => {
  const history = useHistory();

  return (
    <>
      <h4 className="mb-3">Payment history</h4>
      <div>
        {orders.length > 0 ? (
          <>
            {orders.map((order) => {
              const { attributes } = order;
              const createdAt = new Date(attributes.createdAt);

              return (
                <StyledRow key={order.id}>
                  <p className="m-0 item">{attributes.paymentMethodIsCard ? "CC" : "Invoice"}</p>
                  {attributes.hasBeenPaid ? (
                    <Badge className="item" bg="success">
                      Paid
                    </Badge>
                  ) : (
                    <Badge className="item" bg="primary">
                      Unpaid
                    </Badge>
                  )}
                  <p className="m-0 item">{attributes.poNumber}</p>
                  <p className="m-0 item">{createdAt.toLocaleDateString()}</p>
                  <p className="m-0 item">{attributes.creator}</p>
                  <NumberFormater value={attributes.totalCents} />
                  <Button onClick={() => history.push(orderUrl(order.id))} variant="outline-primary p-0 px-1 item">
                    View Order
                  </Button>
                </StyledRow>
              );
            })}
            <Pagination pagination={pagination} handlePageClick={handlePageClick} />
          </>
        ) : (
          <h5 className="text-center">No payments made yet</h5>
        )}
      </div>
    </>
  );
};

export default List;
