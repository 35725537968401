import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

import Button from "@ROM-ui/Button";
import PaymentsApi from "@ROM/Payments/api";
import Form from "@ROM-ui/Form";
import styled from "styled-components";
import CardIcon from "@ROM-ui/CardIcon";
import Spinner from "@ROM-ui/Spinner";

const AddCard = ({ customer, setNewCardId, classNameContainer, onCancel }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [secret, setSecret] = useState("");
  const [brand, setBrand] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const { id } = customer;

  const handleConfirm = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setError(null);
    setLoading(true);

    if (nameOnCard === "") {
      setError({ code: "empty_name", message: "Your card name is incomplete." });
      setLoading(false);
      return;
    }

    const result = await stripe.confirmCardSetup(secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: nameOnCard,
        },
      },
    });

    if (result.error) {
      setError(result.error);
    } else {
      setNewCardId(result.setupIntent.payment_method);
    }
    setLoading(false);
  };

  useEffect(() => {
    const generateSetupIntent = async () => {
      if (!id) return;
      const response = await PaymentsApi.createSetupIntent({ customerId: id });
      const {
        data: { clientSecret },
      } = response;
      if (!clientSecret) return;

      setSecret(clientSecret);
    };

    generateSetupIntent();

    return () => setSecret("");
  }, [id]);

  const handleBrandIcon = (e) => {
    const brand = e?.brand ?? "";
    setBrand(brand);
  };

  return (
    <NewCardContainer className={classNameContainer || "bg-light p-4 rounded-bottom"}>
      <Form.Label className="ms-2 mb-0">Name on card</Form.Label>
      <Form.Control type="text" className="p-2 h-25 mb-2" onChange={(e) => setNameOnCard(e.target.value)} />
      {error?.code === "empty_name" && <StyledError>{error?.message}</StyledError>}
      <Form.Label className="ms-2 mb-0">Card number</Form.Label>
      <div className="d-flex justify-content-center align-items-center">
        <CardIcon brand={brand} className="mb-2 me-2" />
        <CardNumberElement className="card-number mb-2" onChange={handleBrandIcon} />
      </div>
      {error?.code === "incomplete_number" && <StyledError>{error?.message}</StyledError>}
      <div className="d-flex mb-2">
        <div className="w-50 me-2">
          <Form.Label className="ms-2 mb-0">Expiration date</Form.Label>
          <CardExpiryElement className="card-expiry" />
          {error?.code === "incomplete_expiry" && <StyledError>{error?.message}</StyledError>}
        </div>
        <div className="w-50 ms-2">
          <Form.Label className="ms-1 mb-0">Security code</Form.Label>
          <CardCvcElement className="card-cvc" />
          {error?.code === "incomplete_cvc" && <StyledError className="ms-3">{error?.message}</StyledError>}
        </div>
      </div>
      {!["empty_name", "incomplete_number", "incomplete_expiry", "incomplete_cvc"].includes(error?.code) && (
        <StyledError>{error?.message}</StyledError>
      )}
      <div className="text-end">
        {onCancel && (
          <Button className="mt-2 py-1 me-2" variant="danger" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>
        )}
        <Button className="mt-2 py-1" onClick={handleConfirm} disabled={loading}>
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Save"}
        </Button>
      </div>
    </NewCardContainer>
  );
};

const NewCardContainer = styled.div`
  .card-number,
  .card-expiry,
  .card-cvc {
    width: 100%;
    padding: 0.65rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .StripeElement--focus {
    color: #495057;
    background-color: #fff;

    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const StyledError = styled.p`
  color: red;
  font-size: 12px;
  margin-bottom: 2px;
  margin-top: 2px;
`;

AddCard.defaultProps = {
  onCancel: null,
  setNewCardId: null,
  classNameContainer: null,
};

AddCard.propTypes = {
  onCancel: PropTypes.func,
  setNewCardId: PropTypes.func,
  classNameContainer: PropTypes.string,
  customer: PropTypes.object.isRequired,
};

export default AddCard;
