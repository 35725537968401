import React, { useReducer, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { ListContainer } from "@ROM-ui/List";
import { canCreateAddresses, canEditAddresses, canDeleteAddresses } from "@ROM/Addresses/permissions";
import { list as fetchAddresses, setPage, BILLING_TYPE } from "@ROM/Addresses/actions";
import { useSelector, useDispatch } from "react-redux";
import Button from "@ROM-ui/Button";
import Modal from "@ROM-components/common/Modal";
import Pagination from "@ROM-components/common/Pagination";
import { reducer } from "@ROM/App/utils/forms";
import FormModal from "@ROM/Addresses/components/AddressSelector/FormModal/FormModal";
import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import {
  selectBillingPagination,
  selectShippingPagination,
  selectShippingAddresses,
  selectBillingAddresses,
} from "@ROM/Addresses/selectors";
import Filters from "./Filters";
import AddressesRow from "./AddressesRow";

const AddressesTable = ({ type }) => {
  const currentUser = useSelector(selectCurrentUser);
  const shippingAddresses = useSelector(selectShippingAddresses);
  const billindAddresses = useSelector(selectBillingAddresses);
  const shippingAddressesPagination = useSelector(selectShippingPagination);
  const billingAddressesPagination = useSelector(selectBillingPagination);
  const dispatch = useDispatch();
  const customer = useContext(CustomerContext);

  const addresses = type === BILLING_TYPE ? billindAddresses : shippingAddresses;
  const pagination = type === BILLING_TYPE ? billingAddressesPagination : shippingAddressesPagination;
  const title = type === BILLING_TYPE ? "Billing" : "Shipping";

  const canCreate = canCreateAddresses(currentUser, customer?.id);
  const canEdit = canEditAddresses(currentUser, customer?.id);
  const canDelete = canDeleteAddresses(currentUser, customer?.id);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const customerId = customer?.id ?? null;

  const initialFilters = {
    contact: null,
  };

  const [filters, setFilter] = useReducer(reducer, initialFilters);

  const fetchAddressesCallback = () => {
    dispatch(fetchAddresses({ customerId, type }, { type }));
  };

  const afterCreate = () => {
    dispatch(fetchAddresses({ customerId, type }, { type }));
    setShowSuccess(true);
  };

  useEffect(() => {
    if (customerId) {
      const timeOutId = setTimeout(() => dispatch(fetchAddresses({ ...filters, customerId, type }, { type })), 500);
      return () => clearTimeout(timeOutId);
    }
    return undefined;
  }, [filters, customerId]);

  const handleFilterChange = (name, value) => {
    setFilter({ [name]: value });
  };

  const handleClearFilters = () => {
    dispatch(fetchAddresses({ ...initialFilters, customerId, type }, { type }));
    setFilter(initialFilters);
  };

  const handlePageClick = (page) => {
    dispatch(setPage(page, { type }));
    dispatch(fetchAddresses({ ...filters, page, customerId, type }, { type }));
  };

  return (
    <>
      <h4 className="mt-5">{`${title} Address`}</h4>
      <Filters handleFilterChange={handleFilterChange} handleClearFilters={handleClearFilters} filters={filters} />
      <ListContainer>
        {addresses.map((address) => (
          <AddressesRow
            key={address.id}
            address={address}
            customerId={customerId}
            fetchAddresses={fetchAddressesCallback}
            canEdit={canEdit}
            canDelete={canDelete}
          />
        ))}
      </ListContainer>
      {canCreate && (
        <Button
          onClick={() => setShowCreateModal(true)}
          id={`customer-edit-add-address-${type}`}
          variant="outline-primary"
          className="w-100 d-flex align-items-center justify-content-between"
        >
          <i className="fas fa-id-card" />
          Add new
        </Button>
      )}
      <Pagination pagination={pagination} handlePageClick={handlePageClick} />
      {showCreateModal && (
        <FormModal
          show
          type={type}
          onCancel={() => setShowCreateModal(false)}
          customerId={customerId}
          callback={afterCreate}
          insert={false}
        />
      )}
      {showSuccess && (
        <Modal
          isShowing
          onCancel={() => setShowSuccess(false)}
          showCancelButton={false}
          showConfirmButton={false}
          header="Success!"
        >
          <p>The address was created successfully</p>
        </Modal>
      )}
    </>
  );
};

AddressesTable.propTypes = {
  type: PropTypes.string.isRequired,
};

export default AddressesTable;
