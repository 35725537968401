import { apiAction } from "@ROM-utils/actions";
import CustomTableApi from "./api";

export const CREATE = "customTables/api/CREATE";
export const LIST = "customTables/api/LIST";
export const UPDATE = "customTables/api/UPDATE";
export const DESTROY = "customTables/api/DESTROY";

export function create(attributes) {
  return apiAction(CREATE, CustomTableApi.create(attributes));
}

export function list(options = {}) {
  return apiAction(LIST, CustomTableApi.list(options));
}

export function update(id, attributes = {}) {
  return apiAction(UPDATE, CustomTableApi.update(id, attributes));
}

export function destroy(id) {
  return apiAction(DESTROY, CustomTableApi.destroy(id), { tableId: id });
}
