import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import styled from "styled-components";

import { homeUrl } from "@ROM-utils/urlHelpers";
import { createAssets, deleteAsset, editAsset } from "@ROM/Customers/actions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectCurrentCustomerAssets, selectCurrentCustomerAssetError } from "@ROM/Customers/selectors";
import FileUploader from "@ROM-common/FileUploader";
import { canManageCustomerAttachments } from "@ROM/Customers/permissions";

const Attachments = ({ customer }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const currentAssets = useSelector(selectCurrentCustomerAssets);
  const assetsError = useSelector(selectCurrentCustomerAssetError);

  const afterAssetUpload = (assets, setUploadingFiles) => {
    dispatch(createAssets(customer.id, { assets })).then(() => {
      setUploadingFiles(false);
    });
  };

  const onDeleteAsset = (asset, setRemovingFiles) => {
    const assetId = asset.id;

    dispatch(deleteAsset(customer.id, assetId)).then(() => {
      setRemovingFiles(false);
    });
  };

  const onEditAssetName = (assetId, name) => {
    dispatch(editAsset(customer.id, assetId, { name }));
  };

  if (customer && !canManageCustomerAttachments(currentUser, customer.id)) {
    return <Redirect to={homeUrl()} />;
  }

  return (
    <div>
      <h4 className="mb-3">Attachments</h4>
      {currentAssets.length === 0 && (
        <EmptyAttachmentsContainer>Customer does not have any attachments yet</EmptyAttachmentsContainer>
      )}
      <FileUploader
        afterAssetUpload={afterAssetUpload}
        deleteAsset={onDeleteAsset}
        editAssetName={onEditAssetName}
        currentAssets={currentAssets}
        error={assetsError}
      />
    </div>
  );
};

const EmptyAttachmentsContainer = styled.div`
  color: #6b7280;
  background-color: #e5e7eb;
  text-align: center;
  padding: 2rem;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

Attachments.propTypes = {
  customer: PropTypes.shape().isRequired,
};

export default Attachments;
