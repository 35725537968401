import React from "react";

import Button from "@ROM-ui/Button";
import Select from "@ROM-components/common/Select";

import PropTypes from "prop-types";

const options = [
  { value: null, label: "All" },
  { value: "true", label: "Accepted" },
  { value: "false", label: "Pending" },
];

const Filters = ({ handleFilterChange, handleClearFilters, filters }) => {
  return (
    <div className="d-flex m-1 justify-content-start">
      <Select
        placeholder="Status"
        options={options}
        onChange={(event) => handleFilterChange("accepted", event.value)}
        value={options.find((option) => option.value === filters.accepted)}
        className="w-40"
      />
      <Button className="p-0 mx-4" size="sm" variant="link" onClick={handleClearFilters}>
        Clear Filters
      </Button>
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.shape({
    accepted: PropTypes.string,
  }).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
};

export default Filters;
