import styled from "styled-components";

const ShadowContainer = styled.div`
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background-color: #f8f8f9;
  border-radius: 0.3rem;
  padding: 1rem;
`;

export default ShadowContainer;
