import React, { useRef, forwardRef, useState, useEffect } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import humps from "humps";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";
import Select from "@ROM-components/common/Select";
import FilterBar from "@ROM-components/common/FilterBar";
import Button from "@ROM-ui/Button";
import InputGroup from "@ROM-ui/InputGroup";

import { canSeeExtraInformation, canSeeOrdersFromOthers } from "@ROM/Orders/permissions";
import AsyncPaginate from "@ROM-components/common/AsyncPaginate";

import { IN_PROGRESS, PENDING, SHIPPED, DELIVERED } from "@ROM/Deliveries/constants";

const OrderCalendar = forwardRef(({ handleClear, ...props }, ref) => {
  return (
    <InputGroup {...props}>
      <Form.Control
        placeholder={props.placeholder || "Select Date"}
        ref={ref}
        value={props.value || ""}
        onChange={() => {}} // Dismisses a React warning...
        className="bg-white"
      />
      {props.value ? (
        <InputGroup.Text
          {...props}
          onClick={(ev) => {
            ev.stopPropagation();
            handleClear();
          }}
        >
          <i className="fa fa-times" />
        </InputGroup.Text>
      ) : (
        <InputGroup.Text>
          <i className="fa fa-calendar" />
        </InputGroup.Text>
      )}
    </InputGroup>
  );
});

const Filters = ({
  filters,
  handleFilterChange,
  handleClearFilters,
  currentUser,
  customerLoadOptions,
  warehouseLoadOptions,
  customerInitialValue,
  warehouseInitialValue,
}) => {
  const toDayPicker = useRef(null);

  const allCustomersValue = { value: null, label: "All Customers" };
  const allWarehousesValue = { value: null, label: "All Warehouses" };

  const dateFrom = parseDate(filters.dateFrom);
  const dateTo = parseDate(filters.dateTo);

  const statusOptions = [
    { value: null, label: "All Statuses" },
    { value: "all_active", label: "All Active" },
    { value: PENDING, label: "Pending" },
    { value: humps.decamelize(IN_PROGRESS), label: "In Progress" },
    { value: SHIPPED, label: "Shipped" },
    { value: DELIVERED, label: "Delivered" },
  ];

  const modifiers = { start: dateFrom, end: dateTo };

  const [selectedCustomer, setSelectedCustomer] = useState(customerInitialValue);
  const [selectedWarehouse, setSelectedWarehouse] = useState(warehouseInitialValue);

  const focusToDayPicker = () => {
    toDayPicker.current?.getInput().focus();
  };

  useEffect(() => {
    if (!filters.customerId) {
      setSelectedCustomer(allCustomersValue);
    }
    if (!filters.warehouseId) {
      setSelectedWarehouse(allWarehousesValue);
    }
  }, [filters]);

  useEffect(() => {
    setSelectedCustomer(customerInitialValue);
    setSelectedWarehouse(warehouseInitialValue);
  }, [customerInitialValue, warehouseInitialValue]);

  return (
    <FilterBar className="mb-4">
      <Row className="mb-2">
        <Col md={2} className="mb-2 mb-md-0">
          <Select
            placeholder="Status"
            options={statusOptions}
            onChange={(event) => handleFilterChange("status", event.value)}
            value={statusOptions.find((option) => option.value === filters.status)}
          />
        </Col>
        <Col md={2} className="mb-2 mb-md-0">
          <Form.Control
            type="text"
            name="poNumber"
            id="order-number-filter"
            placeholder="Order No."
            onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
            value={filters.poNumber || ""}
          />
        </Col>
        <Col md={2} className="mb-2 mb-md-0">
          <Form.Control
            type="text"
            name="deliveryNumber"
            id="delivery-number-filter"
            placeholder="Delivery No."
            onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
            value={filters.deliveryNumber || ""}
          />
        </Col>
        <Col md={3} className="mb-2 mb-md-0">
          {canSeeExtraInformation(currentUser) && (
            <AsyncPaginate
              value={selectedCustomer}
              loadOptions={customerLoadOptions}
              onChange={(event) => {
                setSelectedCustomer(event);
                handleFilterChange("customerId", event.value);
              }}
              options={[allCustomersValue]}
              placeholder="Customer"
              additional={{
                page: 1,
              }}
            />
          )}
        </Col>
        <Col md={3} className="mb-2 mb-md-0">
          <AsyncPaginate
            value={selectedWarehouse}
            loadOptions={warehouseLoadOptions}
            onChange={(event) => {
              setSelectedWarehouse(event);
              handleFilterChange("warehouseId", event.value);
            }}
            options={[allWarehousesValue]}
            placeholder="Warehouse"
            additional={{
              page: 1,
            }}
          />
        </Col>
      </Row>
      <Row className="mb-n1">
        <Col md={2} className="align-self-center">
          Show deliveries between
        </Col>
        <Col md={2} className="DayPickerFromTo align-self-center mb-2 mb-md-0">
          <DayPickerInput
            value={dateFrom}
            onDayChange={(day) => handleFilterChange("dateFrom", day)}
            inputProps={{ handleClear: () => handleFilterChange("dateFrom", null) }}
            component={OrderCalendar}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder="from..."
            dayPickerProps={{
              selectedDays: [dateFrom, { from: dateFrom, to: dateTo }],
              disabledDays: { after: dateTo },
              modifiers,
              onDayClick: focusToDayPicker,
            }}
          />
        </Col>
        <Col md={2} className="DayPickerFromTo DayPickerFromTo-to align-self-center mb-3 mb-md-0">
          <DayPickerInput
            value={dateTo}
            ref={toDayPicker}
            onDayChange={(day) => handleFilterChange("dateTo", day)}
            inputProps={{ handleClear: () => handleFilterChange("dateTo", null) }}
            component={OrderCalendar}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder="...to"
            dayPickerProps={{
              selectedDays: [dateFrom, { from: dateFrom, to: dateTo }],
              disabledDays: { before: dateFrom },
              modifiers,
            }}
          />
        </Col>
        <Col md={6} className="d-flex align-self-center">
          {canSeeOrdersFromOthers(currentUser) && (
            <FormCheckSmall
              type="checkbox"
              label="Only on Active Orders"
              id="open-orders-filter"
              className="me-4"
              checked={filters.openOrders}
              onChange={() => handleFilterChange("openOrders", !filters.openOrders)}
            />
          )}
          <Button className="p-0" size="sm" variant="link" onClick={handleClearFilters}>
            Clear Filters
          </Button>
        </Col>
      </Row>
    </FilterBar>
  );
};

export default Filters;

const FormCheckSmall = styled(Form.Check)`
  font-size: 0.8rem;
`;

Filters.propTypes = {
  filters: PropTypes.shape().isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
};
