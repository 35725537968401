import React, { useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import ListCards from "@ROM/Payments/components/ListCards";
import ListTransactions from "@ROM/CustomerAdmin/components/Transactions/List";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import { canListPaymentMethods, canAddPaymentMethod, canDeletePaymentMethod } from "@ROM/Customers/permissions";
import { selectCurrentUser } from "@ROM/Auth/selectors";

const BillingPanel = ({ orders, pagination, handlePageClick }) => {
  const customer = useContext(CustomerContext);
  const currentUser = useSelector(selectCurrentUser);

  const canList = canListPaymentMethods(currentUser, customer?.id);
  const canAdd = canAddPaymentMethod(currentUser, customer?.id);
  const canDelete = canDeletePaymentMethod(currentUser, customer?.id);

  return (
    <Row>
      {canList && (
        <Col xs={12} lg={4} className="mb-3">
          {customer ? (
            <ListCards customer={customer} addCard={canAdd} deleteCard={canDelete} />
          ) : (
            <Skeleton count={3} height={45} />
          )}
        </Col>
      )}
      <Col xs={12} lg={8}>
        <ListTransactions orders={orders} pagination={pagination} handlePageClick={handlePageClick} />
      </Col>
    </Row>
  );
};

BillingPanel.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pagination: PropTypes.shape().isRequired,
  handlePageClick: PropTypes.func.isRequired,
};

export default BillingPanel;
