import { apiAction } from "@ROM-utils/actions";

import DeliveryItemsApi from "./api";

export const CREATE_DELIVERY_ITEM = "delivery_items/api/CREATE";
export const UPDATE_DELIVERY_ITEM = "delivery_items/api/UPDATE";
export const DESTROY_DELIVERY_ITEM = "delivery_items/api/DESTROY";

export function create(attributes = {}) {
  return apiAction(CREATE_DELIVERY_ITEM, DeliveryItemsApi.create(attributes));
}

export function update(deliveryItem = {}, meta = {}) {
  return apiAction(UPDATE_DELIVERY_ITEM, DeliveryItemsApi.update(deliveryItem.id, deliveryItem.attributes), {
    ...meta,
  });
}

export function destroy(deliveryId, id, meta = {}) {
  return apiAction(DESTROY_DELIVERY_ITEM, DeliveryItemsApi.destroy(deliveryId, id), { ...meta });
}
