import { checkPermission } from "@ROM/Permissions/utils";

export const canAccessProductsInCompanyAdmin = (user) => checkPermission(user, "company:admin:products");

export const canCreateProducts = (user) => checkPermission(user, "products:create");

export const canEditProducts = (user) => checkPermission(user, "products:update");

export const canDeleteProducts = (user) => checkPermission(user, "products:delete");

export const canFilterProductsByActiveStatus = (user) => checkPermission(user, "products:inactive:list");
