import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canViewUsers } from "@ROM/Users/permissions";
import { companyAdminUsersManageUrl } from "@ROM-utils/urlHelpers";
import Badge from "@ROM-ui/Badge";

const UsersTableRow = ({ user, included }) => {
  const history = useHistory();
  const currentUser = useSelector(selectCurrentUser);

  const currentUserCanViewUsers = canViewUsers(currentUser);

  const userRegionIds = user.relationships.regions.data.map((region) => region.id);
  const regions = included.filter((item) => item.type === "region" && userRegionIds.includes(item.id));

  const handleOnClick = () => {
    if (currentUserCanViewUsers) {
      history.push(companyAdminUsersManageUrl(user.id));
    }
  };

  return (
    <tr className={currentUserCanViewUsers ? "cursor-pointer" : ""} onClick={handleOnClick}>
      <td>{user.attributes.fullName}</td>
      <td>{user.attributes.email}</td>
      <td>
        {regions.map((region) => (
          <Badge bg="primary" className="me-1" key={region.id}>
            {region.attributes.name}
          </Badge>
        ))}
      </td>
      <td>
        {user.attributes.companyRoles.map((role, roleIdx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Badge bg="warning" className="me-1" key={roleIdx}>
            {role}
          </Badge>
        ))}
      </td>
      <td>{moment(user.attributes.createdAt).format("MM/DD/YYYY HH:mm")}</td>
    </tr>
  );
};

UsersTableRow.propTypes = {
  user: PropTypes.shape().isRequired,
  included: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default UsersTableRow;
