import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Row from "@ROM-ui/Row";

const StickyBottomRow = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledBottomRow {...props}>{children}</StyledBottomRow>
);

StickyBottomRow.propTypes = {
  children: PropTypes.node.isRequired,
};

const StyledBottomRow = styled(Row)`
  position: sticky;
  bottom: 0;
  z-index: 2;
`;

export default StickyBottomRow;
