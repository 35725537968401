import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canEditUsersRegions } from "@ROM/Users/permissions";
import ItemsPicker from "@ROM/CompanyAdmin/Users/Edit/ItemsPicker";

const UserRegionsForm = ({ userIncluded, regions, updateRegions }) => {
  const currentUser = useSelector(selectCurrentUser);

  const userRegions = userIncluded
    .filter((item) => item.type === "region")
    .map((item) => ({ value: item.id, label: item.attributes.name }));
  const userRegionIds = userRegions.map((item) => item.value);

  const nonUserRegions = regions.reduce(
    (acc, region) => (userRegionIds.includes(region.id) ? acc : [...acc, { value: region.id, label: region.attributes.name }]),
    []
  );

  const handleAddRegion = (option) => {
    updateRegions([...userRegionIds, option.value]);
  };

  const handleRemoveRegion = (option) => {
    updateRegions(userRegionIds.filter((regionId) => regionId !== option.value));
  };

  return (
    <ItemsPicker
      pickedItems={userRegions}
      nonPickedItems={nonUserRegions}
      onAddItem={handleAddRegion}
      onRemoveItem={handleRemoveRegion}
      title="Regions"
      emptyText="Doesn't belong to any region"
      selectText="Select New Region"
      removeText="Remove this user from this region?"
      readOnly={!canEditUsersRegions(currentUser)}
      className="mt-4"
    />
  );
};

UserRegionsForm.propTypes = {
  userIncluded: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  regions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateRegions: PropTypes.func.isRequired,
};

export default UserRegionsForm;
