/**
 * Format number adding thousand separators and decimal point.
 */
export const numberFormat = (number) => new Intl.NumberFormat("en-US").format(number);

/**
 * Remove decimal part of numbers when numbers are big.
 *
 * @param {number} range threshold from where numbers is considered big.
 */
export const cleanDecimals = (value, range = 10.0) => {
  if (Math.abs(value) > range) {
    return Math.ceil(value);
  }
  return Math.round(value * 100) / 100;
};

/**
 * Returns a USD currency representation of the given value.
 *
 * @param {number} value Dollar amount to be represented.
 */
export const numberToCurrency = (value) => value.toLocaleString("en", { style: "currency", currency: "USD" });

/**
 * Returns a number with up to _digits_ decimal places, only if those are
 * necesary. 10 -> 10, 10.01 -> 10.01, 10.000007 -> 10
 *
 * @param {number} n Input number
 * @param {number} digits An integer with the decimal places to consider.
 * @see https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Math/round
 */
export const roundDigits = (n, digits) => {
  const value = Math.round(+`${n.toString()}e${digits}`);
  return +`${value.toString()}e-${digits}`;
};

/**
 * Returns a text humanized by replacing _ and - with spaces, trimming,
 * lowercasing and setting the first letter to uppercase.
 */
export const humanize = (text) => {
  return text
    .replaceAll(/[_-]+/g, " ")
    .trim()
    .toLowerCase()
    .replace(/^[a-z]/, (letter) => letter.toUpperCase());
};

export const ifPositive = (next) => (value) => {
  const converted = Number.parseInt(value, 10);
  if (Number.isFinite(converted) && converted >= 0) {
    return next(value);
  }
  return undefined;
};
