import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import LoadingDots from "@ROM-ui/LoadingDots";
import Message from "@ROM-common/Message";

import BasicInformationForm from "@ROM/CompanyAdmin/Users/Edit/BasicInformationForm";
import PasswordChangeForm from "@ROM/CompanyAdmin/Users/Edit/PasswordChangeForm";
import UserRolesForm from "@ROM/CompanyAdmin/Users/Edit/UserRolesForm";
import UserRegionsForm from "@ROM/CompanyAdmin/Users/Edit/UserRegionsForm";
import UserCustomersForm from "@ROM/CompanyAdmin/Users/Edit/UserCustomersForm";

import { CUSTOMER_ADMIN_ROLE, CUSTOMER_CROP_ADVISOR_ROLE } from "@ROM/Customers/constants";
import { canEditUsers } from "@ROM/Users/permissions";
import {
  find,
  update,
  updateCompanyRoles,
  addToCustomer,
  removeFromCustomer,
  updateCustomerAdminValue,
} from "@ROM/Users/actions";
import {
  selectCurrentUser,
  selectCurrentUserLoading,
  selectCurrentUserIncluded,
  selectUsersUpdateErrors,
} from "@ROM/Users/selectors";
import { selectRegions } from "@ROM/Regions/selectors";
import { selectCurrentUser as selectSignedInUser, selectCompanyRoles } from "@ROM/Auth/selectors";

const UserForm = ({ userId }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const currentUser = useSelector(selectSignedInUser);
  const userIncluded = useSelector(selectCurrentUserIncluded);
  const regions = useSelector(selectRegions);
  const companyRoles = useSelector(selectCompanyRoles);
  const loading = useSelector(selectCurrentUserLoading);
  const updateErrors = useSelector(selectUsersUpdateErrors);
  const [updateMessage, setUpdateMessage] = useState(null);

  const canEditUser = canEditUsers(currentUser) || user?.id === currentUser.id;

  const includedCustomers = userIncluded?.filter((item) => item.type === "customer") || [];

  const customerAdminIds = user?.attributes.roles.customerRoles
    .filter((role) => role.name === CUSTOMER_ADMIN_ROLE)
    .map((role) => role.customerId.toString());

  const customerCropAdvisorIds = user?.attributes.roles.customerRoles
    .filter((role) => role.name === CUSTOMER_CROP_ADVISOR_ROLE)
    .map((role) => role.customerId.toString());

  const userCustomers = includedCustomers.map((customer) => ({
    ...customer,
    isAdmin: customerAdminIds.includes(customer.id),
    isCropAdvisor: customerCropAdvisorIds.includes(customer.id),
  }));

  useEffect(() => {
    if (userId) {
      dispatch(find(userId));
    }
  }, [userId]);

  if (loading) {
    return <LoadingDots />;
  }

  const pushUpdateMessage = (message) => {
    setUpdateMessage(message);
    setTimeout(() => {
      setUpdateMessage(null);
    }, 5000);
  };
  const updateBasicInformation = async (data) => {
    await dispatch(update(user.id, data));
    pushUpdateMessage("User data updated successfully");
  };

  const updatePassword = async (password) => {
    await dispatch(update(user.id, { password, passwordConfirmation: password }));
    pushUpdateMessage("Password updated successfully");
  };

  const handleUpdateCompanyRoles = (roles) => {
    dispatch(updateCompanyRoles(userId, roles));
  };

  const handleUpdateRegions = (regionIds) => {
    dispatch(update(user.id, { regionIds }));
  };

  const handleAddCustomerAssociation = (customerId, admin) => {
    dispatch(addToCustomer(userId, { admin, customerId }));
  };

  const handleRemoveCustomerAssociation = (customerId) => {
    dispatch(removeFromCustomer(userId, customerId));
  };

  const handleEditCustomerAssociation = (customerId, admin) => {
    dispatch(updateCustomerAdminValue(userId, { admin, customerId }));
  };

  return user ? (
    <div className="mw-40rem">
      {updateErrors && <Message type="danger" message={updateErrors} />}
      {!updateErrors && updateMessage && <Message type="success" message={updateMessage} />}
      <BasicInformationForm user={user} updateBasicInformation={updateBasicInformation} readOnly={!canEditUser} />
      {canEditUser && <PasswordChangeForm updatePassword={updatePassword} />}
      <UserRegionsForm userIncluded={userIncluded} regions={regions} updateRegions={handleUpdateRegions} />
      <UserRolesForm user={user} companyRoles={companyRoles} onUpdateRoles={handleUpdateCompanyRoles} />
      <UserCustomersForm
        user={user}
        userCustomers={userCustomers}
        onAddAssociation={handleAddCustomerAssociation}
        onRemoveAssociation={handleRemoveCustomerAssociation}
        onEditAssociation={handleEditCustomerAssociation}
      />
    </div>
  ) : null;
};

UserForm.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserForm;
