import { createSelector } from "reselect";

const companyState = (state) => state.company;

export const selectCurrentCompany = createSelector(companyState, (company) => company.currentCompany);

export const selectCompanyErrorUpdate = createSelector(companyState, (company) => company.errorUpdate);

export const selectCompanySuccessfulUpdate = createSelector(companyState, (company) => company.successfulUpdate);

export const selectCompanyLoading = createSelector(companyState, (company) => company.loading);
