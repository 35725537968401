import React, { useReducer } from "react";
import PropTypes from "prop-types";
import Modal from "@ROM-components/common/Modal";
import { getVariant } from "@ROM/PriceLists/utils";
import Badge from "@ROM-ui/Badge";
import moment from "moment";

const DeleteModal = ({ show, onCancel, onDeleteClick, currentPriceList }) => {
  moment.locale("en-us");

  const dateFrom = currentPriceList?.attributes?.dateFrom
    ? moment(currentPriceList?.attributes?.dateFrom).format("MMM DD, YYYY")
    : null;
  const dateTo = currentPriceList?.attributes?.dateTo
    ? moment(currentPriceList?.attributes?.dateTo).format("MMM DD, YYYY")
    : null;

  return (
    <Modal isShowing={show} confirmText="Delete" onConfirm={onDeleteClick} onCancel={onCancel} header="Delete Price List">
      <h6>{`Price List #${currentPriceList?.id}`}</h6>
      <div className="d-flex align-items-start text-black">
        <h4>
          <strong>{`${currentPriceList?.attributes?.name}`}</strong>
        </h4>
        <Badge bg={getVariant(currentPriceList?.attributes?.status)} className="ms-3 px-2">
          {currentPriceList?.attributes?.status.toUpperCase()}
        </Badge>
      </div>
      <p>{`${dateFrom ? `From date: ${dateFrom}` : ""}${dateTo ? ` End date: ${dateTo}` : ""}`}</p>
      <h5>
        <strong>{`${currentPriceList?.relationships?.priceListItems?.data?.length} items`}</strong>
      </h5>
      <p className="mt-5">Are you sure you want to delete this Price List?</p>
    </Modal>
  );
};

DeleteModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  create: PropTypes.func,
};

export default DeleteModal;
