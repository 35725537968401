import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import styled from "styled-components";
import { destroy as deleteDeliveryItem } from "@ROM/Deliveries/DeliveryItems/actions";

const DeliveryItemRow = ({ item, handleChange, quantities, currentDelivery = null }) => {
  const dispatch = useDispatch();

  const handleRemove = async (deliveryItem) => {
    await dispatch(deleteDeliveryItem(currentDelivery.id, deliveryItem.id, { deliveryItemId: deliveryItem.id }));
  };

  return (
    <div className="w-100 bg-light mt-2 mb-2 rounded text-secondary p-3 justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <div className="d-flex w-100 justify-content-between align-items-center">
          <div>
            <strong>{item.attributes.productName}</strong>
          </div>

          <div className="d-flex  justify-content-end align-items-center">
            <p className="m-0">Quantity</p>
            <StyledInput
              type="number"
              value={quantities?.selected?.[currentDelivery?.id]?.[item?.attributes?.orderItemId] ?? ""}
              onChange={(e) => handleChange(currentDelivery.id, item.attributes.orderItemId, e.target.value)}
              className="form-control"
              min="0"
              step="0.01"
            />
          </div>
        </div>
        <StyledTrash className="fas fa-trash cursor-pointer text-danger ms-2" onClick={() => handleRemove(item)} />
      </div>
      {item.attributes.notes && <div className="m-0 mt-1 font-italic fw-light">{item.attributes.notes}</div>}
    </div>
  );
};

DeliveryItemRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    attributes: {
      productName: PropTypes.string,
      notes: PropTypes.string,
    },
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  quantities: PropTypes.shape().isRequired,
  currentDelivery: PropTypes.shape({ id: PropTypes.string }),
};

DeliveryItemRow.defaultProps = {
  currentDelivery: null,
};

const StyledInput = styled.input`
  width: 40%;
  margin-left: 10px;
`;

const StyledTrash = styled.i`
  font-size: large;
`;

export default DeliveryItemRow;
