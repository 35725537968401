import React, { useReducer } from "react";
import PropTypes from "prop-types";

import { reducer as formReducer } from "@ROM/App/utils/forms";
import Form from "@ROM-ui/Form";
import Button from "@ROM-ui/Button";
import Fieldset from "@ROM-ui/Fieldset";
import { isEmail } from "@ROM-ui/MultiInputValue";

const BasicInformationForm = ({ user, updateBasicInformation, readOnly }) => {
  const initialValidationErrors = { fullName: false, email: false };
  const [validationErrors, setValidationErrors] = useReducer(formReducer, initialValidationErrors);
  const initialData = { fullName: user?.attributes.fullName || "", email: user?.attributes.email || "" };
  const [formData, updateFormData] = useReducer(formReducer, initialData);

  const onUpdate = () => {
    const errors = {};
    if (!formData.fullName) errors.fullName = "Full Name cannot be empty";
    if (!formData.email) errors.email = "Email cannot be empty";
    else if (!isEmail(formData.email)) errors.email = "Email format is invalid";

    if (Object.keys(errors).length === 0) updateBasicInformation(formData);
    else setValidationErrors(errors);
  };

  return (
    <Form>
      <Fieldset legend="Basic Information">
        <Form.Group className="mb-3">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            required
            isInvalid={!!validationErrors.fullName}
            type="text"
            value={formData.fullName}
            onChange={(e) => updateFormData({ fullName: e.target.value })}
            readOnly={readOnly}
          />
          <Form.Control.Feedback type="invalid">{validationErrors.fullName}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            isInvalid={!!validationErrors.email}
            type="email"
            value={formData.email}
            onChange={(e) => updateFormData({ email: e.target.value })}
            readOnly={readOnly}
          />
          <Form.Control.Feedback type="invalid">{validationErrors.email}</Form.Control.Feedback>
        </Form.Group>
        {!readOnly && (
          <Button variant="primary" onClick={onUpdate}>
            Update
          </Button>
        )}
      </Fieldset>
    </Form>
  );
};

BasicInformationForm.defaultProps = {
  readOnly: false,
};

BasicInformationForm.propTypes = {
  readOnly: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  updateBasicInformation: PropTypes.func.isRequired,
};

export default BasicInformationForm;
