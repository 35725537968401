import update from "immutability-helper";
import { handle } from "redux-pack";
import humps from "humps";

import { CREATE, DESTROY, LIST, UPDATE } from "./actions";

const initialState = {
  all: {
    error: false,
    loading: true,
    items: [],
    included: [],
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};

  switch (type) {
    case CREATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: payload.data[0].detail },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $push: [humps.camelizeKeys(json.data)] },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: humps.camelizeKeys(json.data) },
              included: { $set: json.included },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case UPDATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: payload.data[0].detail },
            },
          });
        },

        success: (prevState) => {
          const tableId = json.data.id;
          const index = prevState.all.items.findIndex((item) => item.id === tableId);

          return update(prevState, {
            all: {
              items: {
                [index]: {
                  attributes: { $set: humps.camelizeKeys(json.data.attributes) },
                },
              },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case DESTROY:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          const { tableId } = action.meta;
          const index = prevState.all.items.findIndex((item) => item.id === tableId);
          return update(prevState, {
            all: {
              items: { $splice: [[index, 1]] },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });
    default:
      return state;
  }
}

export default reducer;
