/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router";
import { list as listCustomTables } from "@ROM/CustomTables/actions";
import { selectAllCustomTables } from "@ROM/CustomTables/selectors";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import {
  companyAdminProductsUrl,
  companyAdminWarehousesUrl,
  homeUrl,
  companyAdminCustomersUrl,
  companyAdminUrl,
  companyAdminPriceListsUrl,
  companyAdminUsersUrl,
  companyAdminCustomTablesShowUrl,
  companyAdminCustomTablesCreateUrl,
} from "@ROM-utils/urlHelpers";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "@ROM-ui/Nav";
import Button from "@ROM-ui/Button";
import { canAccessCompanyAdmin } from "@ROM/CompanyAdmin/permissions";
import { canAccessCompanyInCompanyAdmin } from "@ROM/Company/permissions";
import { canAccessCustomersInCompanyAdmin } from "@ROM/Customers/permissions";
import { canAccessWarehousesInCompanyAdmin } from "@ROM/Warehouses/permissions";
import { canAccessProductsInCompanyAdmin } from "@ROM/Products/permissions";
import { canAccessPriceListsInCompanyAdmin } from "@ROM/PriceLists/permissions";
import { canAccessUsersInCompanyAdmin } from "@ROM/Users/permissions";
import { canAccessCustomTablesInCompanyAdmin } from "@ROM/CustomTables/permissions";
import { Sidebar, SidebarItem, SidebarSubitem } from "@ROM/CompanyAdmin/Sidebar";
import useIsMobile from "@ROM-components/utils/Responsive";
import MobileNavbar from "./Navbars/MobileNavbar";
import AdminContainer from "./AdminContainer";

const COMPANY = "company";
const CUSTOMERS = "customers";
const WAREHOUSES = "warehouses";
const PRODUCTS = "products";
const PRICE_LISTS = "price-lists";
const USERS = "users";

const CompanyAdmin = ({ children }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const customTables = useSelector(selectAllCustomTables);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (canAccessCustomTablesInCompanyAdmin(currentUser)) {
      dispatch(listCustomTables());
    }
  }, []);

  if (!canAccessCompanyAdmin(currentUser)) {
    return <Redirect to={{ pathname: homeUrl() }} />;
  }

  return (
    <div className="d-flex">
      {!isMobile && (
        <Sidebar>
          <SidebarItems currentUser={currentUser} customTables={customTables} />
        </Sidebar>
      )}
      <AdminContainer>
        <MobileNavbar companyAdmin>
          <NavbarItems currentUser={currentUser} customTables={customTables} />
        </MobileNavbar>
        {children}
      </AdminContainer>
    </div>
  );
};

const CompanyLink = ({ name }) => (
  <LinkContainer to={companyAdminUrl()} name={name}>
    <Nav.Link>
      <i className="fas fa-home text-secondary me-3" />
      Company
    </Nav.Link>
  </LinkContainer>
);

const CustomersLink = ({ ...props }) => (
  <LinkContainer to={companyAdminCustomersUrl()} id="company-admin-customer-index-icon" {...props}>
    <Nav.Link>
      <i className="fas fa-store-alt text-secondary me-3" />
      Customers
    </Nav.Link>
  </LinkContainer>
);

const WarehousesLink = ({ ...props }) => (
  <LinkContainer to={companyAdminWarehousesUrl()} id="company-admin-warehouses-index-icon" {...props}>
    <Nav.Link>
      <i className="fas fa-warehouse text-secondary me-3" />
      Warehouses
    </Nav.Link>
  </LinkContainer>
);

const ProductsLink = ({ ...props }) => (
  <LinkContainer to={companyAdminProductsUrl()} id="company-admin-product-index-icon" {...props}>
    <Nav.Link>
      <i className="fas fa-box-open text-secondary me-3" />
      Products
    </Nav.Link>
  </LinkContainer>
);

const PriceListsLink = ({ ...props }) => (
  <LinkContainer to={companyAdminPriceListsUrl()} {...props}>
    <Nav.Link>
      <i className="fas fa-coins text-secondary me-3" />
      Price Lists
    </Nav.Link>
  </LinkContainer>
);

const UsersLink = ({ ...props }) => (
  <LinkContainer to={companyAdminUsersUrl()} {...props}>
    <Nav.Link>
      <i className="fas fa-users text-secondary me-3" />
      Users
    </Nav.Link>
  </LinkContainer>
);

const CustomTableLink = ({ customTables }) => {
  const history = useHistory();

  const handleCreateTableClick = () => {
    history.push(companyAdminCustomTablesCreateUrl());
    window.scroll(0, 0);
  };

  return (
    <>
      <div className="nav-link">
        <i className="fas fa-database text-secondary me-3" />
        Custom Tables
      </div>
      <div>
        {customTables.map((customTable) => (
          <SidebarSubitem key={customTable.id} redirectTo={companyAdminCustomTablesShowUrl(customTable.id)}>
            {customTable.attributes.name}
          </SidebarSubitem>
        ))}
        <Button className="ms-4 ps-3" size="sm" variant="link" onClick={handleCreateTableClick}>
          Create New Table
        </Button>
      </div>
    </>
  );
};

const getItems = (currentUser, customTables) => {
  const items = [];

  if (canAccessCompanyInCompanyAdmin(currentUser)) {
    items.push(<CompanyLink name={COMPANY} />);
  }

  if (canAccessCustomersInCompanyAdmin(currentUser)) {
    items.push(<CustomersLink name={CUSTOMERS} />);
  }

  if (canAccessWarehousesInCompanyAdmin(currentUser)) {
    items.push(<WarehousesLink name={WAREHOUSES} />);
  }

  if (canAccessProductsInCompanyAdmin(currentUser)) {
    items.push(<ProductsLink name={PRODUCTS} />);
  }

  if (canAccessPriceListsInCompanyAdmin(currentUser)) {
    items.push(<PriceListsLink name={PRICE_LISTS} />);
  }

  if (canAccessUsersInCompanyAdmin(currentUser)) {
    items.push(<UsersLink name={USERS} />);
  }

  if (canAccessCustomTablesInCompanyAdmin(currentUser)) {
    items.push(<CustomTableLink customTables={customTables} />);
  }

  return items;
};

const NavbarItems = ({ currentUser, customTables }) => {
  const items = getItems(currentUser, customTables);

  return (
    <>
      {items.map((component, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>{component}</div>
      ))}
    </>
  );
};

const SidebarItems = ({ currentUser, customTables }) => {
  const items = getItems(currentUser, customTables);

  return (
    <>
      {items.map((component, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <SidebarItem key={index}>{component}</SidebarItem>;
      })}
    </>
  );
};

CompanyAdmin.propTypes = {
  children: PropTypes.node,
};

CompanyAdmin.defaultProps = {
  children: null,
};

CompanyLink.propTypes = {
  name: PropTypes.string.isRequired,
};

const customTablesProps = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  })
);

CustomTableLink.propTypes = {
  customTables: customTablesProps.isRequired,
};

NavbarItems.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  customTables: customTablesProps.isRequired,
};

SidebarItems.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  customTables: customTablesProps.isRequired,
};

export default CompanyAdmin;
