import styled from "styled-components";

const PdfAsset = styled.i`
  display: block;
  width: auto;
  height: 80px;
  border: 1px solid darkgray;
  padding: 10px;
  border-radius: 4px;
  color: darkgray;
`;

export default PdfAsset;
