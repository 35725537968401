import { standardAction, apiAction } from "@ROM-utils/actions";
import BillOfLadingApi from "./api";

export const SET_PAGE = "bill_of_lading/api/SET_PAGE";
export const LIST = "bill_of_lading/api/LIST";
export const FIND = "bill_of_lading/api/FIND";
export const UPDATE = "bill_of_lading/api/UPDATE";
export const CREATE = "bill_of_lading/api/CREATE";
export const DESTROY = "bill_of_lading/api/DESTROY";

export function list(options = {}) {
  return apiAction(LIST, BillOfLadingApi.list(options));
}

export function find(id) {
  return apiAction(FIND, BillOfLadingApi.find(id));
}

export function create(attributes = {}) {
  return apiAction(CREATE, BillOfLadingApi.create(attributes));
}

export function update(id, attributes = {}) {
  return apiAction(UPDATE, BillOfLadingApi.update(id, attributes));
}

export function destroy(id) {
  return apiAction(DESTROY, BillOfLadingApi.destroy(id), { billOfLadingId: id });
}

export function setPage(page) {
  return standardAction(SET_PAGE, { page });
}
