const findPermission = (permissionName, permissions) => permissions.find((permission) => permission.name === permissionName);

export const checkPermission = (user, permissionName, customerId = null) => {
  const { companyPermissions, customerPermissions } = user.attributes.permissions;

  const permission = findPermission(permissionName, companyPermissions);

  if (permission) {
    return permission;
  }

  if (!permission && customerId) {
    const permissionsForCustomer = customerPermissions[customerId];

    if (!permissionsForCustomer) {
      return false;
    }

    return findPermission(permissionName, permissionsForCustomer);
  }

  return false;
};

export const checkPermissionForAnyCustomer = (user, permissionName) => {
  const { companyPermissions, customerPermissions } = user.attributes.permissions;

  const permission = findPermission(permissionName, companyPermissions);

  if (permission) {
    return permission;
  }

  return Object.values(customerPermissions).find((permissions) => findPermission(permissionName, permissions));
};
