import { createSelector } from "reselect";

const authState = (state) => state.auth;

export const selectCurrentUser = createSelector(authState, (auth) => auth.currentUser && auth.currentUser.record);

/* Session selectors */

export const selectSessionCustomer = createSelector(authState, (auth) => auth.session.customer);

export const selectCompanyRoles = createSelector(authState, (auth) => auth.session.roles.companyRoles);

export const selectCustomerRoles = createSelector(authState, (auth) => [...auth.session.roles.customerRoles, "User"]);

export const selectAllRoles = createSelector(selectCompanyRoles, selectCustomerRoles, (companyRoles, customerRoles) => [
  ...companyRoles,
  ...customerRoles,
]);

export const selectUserUpdateErrors = createSelector(authState, (auth) => auth.currentUser.update.errors || false);

export const selectUserUpdateSuccess = createSelector(authState, (auth) => auth.currentUser.update.success || false);

export const selectCustomerIds = createSelector(authState, (auth) => auth.currentUser.record.attributes.customerIds);

export const selectUserCompanies = createSelector(authState, (auth) => auth.currentUser.record.attributes.companies || []);

export const selectCustomerLoading = createSelector(authState, (auth) => auth.session.loading);

export const selectCurrentUserId = createSelector(
  authState,
  (auth) => (auth.currentUser.record && auth.currentUser.record.id) || null
);
