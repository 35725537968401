import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";
import Button from "@ROM-ui/Button";
import Select from "@ROM-components/common/Select";
import AsyncPaginate from "@ROM-components/common/AsyncPaginate";
import { loadOptions } from "@ROM/Customers/utils";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { selectAllWarehouses } from "@ROM/Warehouses/selectors";

const ProductEdit = ({
  product,
  updateState,
  handleSubmit,
  handleCancel,
  children,
  loading,
  selectedCustomers,
  handleCustomerSelect,
  categories,
  subcategories,
  productUnits,
  packageUnits,
  warehouseIds,
  setWarehouseIds,
}) => {
  const company = useSelector(selectCurrentCompany);
  const warehouses = useSelector(selectAllWarehouses);

  const nameWithDN = (name, displayName) => {
    const dn = displayName && name !== displayName ? ` (${displayName})` : "";
    return `${name}${dn}`;
  };

  const warehouseOptions = warehouses.map((warehouse) => ({
    value: warehouse.id,
    label: nameWithDN(warehouse.attributes.name, warehouse.attributes.displayName),
  }));
  const selectedWarehouses = warehouseOptions.filter((item) => warehouseIds.includes(item.value));

  const buildSubcategoriesOptions = (category) =>
    subcategories
      .filter((subcat) => subcat.relationships.category.data.id === category.id)
      .map((subcat) => ({
        label: subcat.attributes.name,
        value: subcat.id,
      }));

  const buildCategoriesOptions = () =>
    categories.map((category) => ({
      label: category.attributes.name,
      options: buildSubcategoriesOptions(category),
    }));

  const buildProductUnitsOptions = productUnits.map((productUnit) => ({
    label: productUnit.attributes.name,
    value: productUnit.attributes.name,
  }));

  const buildPackageUnitsOptions = packageUnits.map((packageUnit) => ({
    label: packageUnit.attributes.name,
    value: packageUnit.attributes.name,
  }));

  const buildSubcategorySelectValue = () =>
    subcategories
      .filter((subcat) => product.subcategoryIds.includes(subcat.id))
      .map((subcat) => ({ label: subcat.attributes.name, value: subcat.id }));

  const handleSubcategorySelectChange = (event) => {
    let newValue = [];

    if (event) {
      newValue = event.map((subcat) => subcat.value);
    }

    updateState({ subcategoryIds: newValue });
  };

  const palletWeightOverrideEnabled = product.palletWeightOverride !== null;
  const defaultPalletWeight = company?.attributes?.palletInitialWeight;
  const toggleOverride = () => {
    updateState({
      palletWeightOverride: palletWeightOverrideEnabled ? null : defaultPalletWeight,
    });
  };

  return (
    <div>
      <Form className="mb-3">
        <Row className="mb-3">
          <Col>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={product.name}
              id="product-form-name"
              onChange={(e) => updateState({ name: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Units per Package</Form.Label>
            <Form.Control
              type="number"
              value={product.unitsPerPackage}
              id="product-form-units-per-package"
              onChange={(e) => updateState({ unitsPerPackage: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Packages per Pallet</Form.Label>
            <Form.Control
              type="number"
              value={product.packagesPerPallet}
              id="product-form-packages-per-pallet"
              onChange={(e) => updateState({ packagesPerPallet: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Package weight (lbs)</Form.Label>
            <Form.Control
              type="number"
              value={product.packageWeight}
              id="product-form-package-weight"
              onChange={(e) => updateState({ packageWeight: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Check
              checked={palletWeightOverrideEnabled}
              id="product-form-pallet-weight-override-check"
              onChange={toggleOverride}
              label="Override Company Default Pallet Weight"
              type="checkbox"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Custom Initial Pallet Weight (lbs)</Form.Label>
            <Form.Control
              type="number"
              disabled={!palletWeightOverrideEnabled}
              value={palletWeightOverrideEnabled ? product.palletWeightOverride : defaultPalletWeight}
              id="product-form-pallet-weight-override"
              onChange={(e) => updateState({ palletWeightOverride: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              rows={4}
              value={product.description}
              id="product-form-description"
              onChange={(e) => updateState({ description: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Form.Label>Unit</Form.Label>
            <Select
              options={buildProductUnitsOptions}
              onChange={(e) => updateState({ unit: e.value })}
              value={buildProductUnitsOptions.find((option) => option.value === product.unit)}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Form.Label>Package Unit</Form.Label>
            <Select
              options={buildPackageUnitsOptions}
              onChange={(e) => updateState({ packageUnit: e.value })}
              value={buildPackageUnitsOptions.find((option) => option.value === product.packageUnit)}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Form.Label>Warehouse Inventory Management</Form.Label>
            <Select
              isMulti
              classNamePrefix="inventory-tracking-warehouses-select"
              id="inventory-tracking-warehouses-select"
              options={warehouseOptions}
              onChange={setWarehouseIds}
              value={selectedWarehouses}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Form.Label>Categories</Form.Label>
            <Select
              isMulti
              options={buildCategoriesOptions()}
              onChange={handleSubcategorySelectChange}
              value={buildSubcategorySelectValue()}
            />
          </Col>
        </Row>
        <div className="d-sm-flex mx-n3 mb-4">
          <Form.Check
            checked={product.isActive}
            onChange={() => updateState({ isActive: !product.isActive })}
            type="checkbox"
            label="Is Active"
            className="mx-3"
          />
          <Form.Check
            checked={product.featured}
            onChange={() => updateState({ featured: !product.featured })}
            type="checkbox"
            label="Featured"
            className="mx-3"
          />
          <Form.Check
            checked={product.private}
            onChange={() => updateState({ private: !product.private })}
            type="checkbox"
            label="Is Private"
            className="mx-3"
          />
        </div>
        {product.private && (
          <Row className="mt-3 mb-4">
            <Col>
              <AsyncPaginate
                isMulti
                closeMenuOnSelect={false}
                value={selectedCustomers}
                loadOptions={loadOptions}
                onChange={handleCustomerSelect}
                placeholder="Allowed Customers"
                additional={{
                  page: 1,
                }}
              />
            </Col>
          </Row>
        )}
        {children && (
          <Row className="mb-3">
            <Col>{children}</Col>
          </Row>
        )}
        <div className="text-end">
          <Button onClick={handleCancel} variant="danger" id="company-admin-product-back-btn" className="me-3">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="success" disabled={loading} id="company-admin-product-save-btn">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

ProductEdit.propTypes = {
  product: PropTypes.shape().isRequired,
  updateState: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedCustomers: PropTypes.shape().isRequired,
  handleCustomerSelect: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  subcategories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  productUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  packageUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  warehouseIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setWarehouseIds: PropTypes.func.isRequired,
};

export default ProductEdit;
