import React from "react";
import styled from "styled-components";

const CategoriesEmpty = () => (
  <Container>
    There are no products categories created yet.
    <br />
    You can create one below.
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f3f4f6;
  text-align: center;
  border-radius: 4px;
`;

export default CategoriesEmpty;
