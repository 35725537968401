import React from "react";
import { Route } from "react-router-dom";

import Page from "@ROM-pages/CompanyAdmin/Index";
import Layout from "@ROM-pages/Layouts/CompanyAdmin";
import Authorize from "@ROM/Auth/components/Authorize";
import { canAccessCompanyInCompanyAdmin } from "@ROM/Company/permissions";

class RouteHandler extends Route {
  render() {
    return (
      <Layout>
        <Authorize permission={canAccessCompanyInCompanyAdmin}>
          <Page />
        </Authorize>
      </Layout>
    );
  }
}

export default RouteHandler;
