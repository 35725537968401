import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import Form from "@ROM-ui/Form";
import Button from "@ROM-ui/Button";

import { createSubcategory } from "@ROM/Categories/actions";

const AddNewCategory = ({ categoryId }) => {
  const dispatch = useDispatch();

  const [subcategoryName, setSubcategoryName] = useState("");
  const [error, setError] = useState(null);

  const addNewSubcategoryHandler = () => {
    if (subcategoryName.trim() === "") {
      setError("Name can't be empty");
    } else {
      setError(null);
      dispatch(createSubcategory(categoryId, { name: subcategoryName }));
    }
  };

  return (
    <Container>
      <div className="d-flex mt-2">
        <Form.Control
          type="text"
          name="subcategory-name"
          className="me-2"
          placeholder="Subcategory name"
          onChange={(event) => setSubcategoryName(event.target.value)}
          value={subcategoryName}
        />
        <Button onClick={addNewSubcategoryHandler} className="text-nowrap">
          Add Subcategory
        </Button>
      </div>
      {error && <div className="error text-danger mt-1">{error}</div>}
    </Container>
  );
};

const Container = styled.div`
  .error {
    font-size: 0.75rem;
  }
`;

AddNewCategory.propTypes = {
  categoryId: PropTypes.number.isRequired,
};

export default AddNewCategory;
