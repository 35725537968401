export const orderPriceLists = (priceListItems, priceLists) =>
  priceListItems.sort((a, b) => {
    const aPriceList = priceLists.find((priceList) => priceList.id == a.relationships.priceList.data.id);
    const bPriceList = priceLists.find((priceList) => priceList.id == b.relationships.priceList.data.id);

    if (
      (aPriceList.attributes.isDefaultPriceList || aPriceList.attributes.status == "active") &&
      !bPriceList.attributes.isDefaultPriceList
    ) {
      return -1;
    }

    if (bPriceList.attributes.isDefaultPriceList || bPriceList.attributes.status == "active") {
      return 1;
    }

    return 0;
  });
