import React from "react";
import styled from "styled-components";
import useIsMobile from "@ROM-components/utils/Responsive";

const StyledDiv = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: ${(props) => `${props.isMobile ? "column" : ""};`}
  background-color: #f5f5f5;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;

  .item {
    flex-basis: 100%;
  }
`;

const StyledRow = ({ children }) => {
  const isMobile = useIsMobile();

  return <StyledDiv isMobile={isMobile}>{children}</StyledDiv>;
};

export default StyledRow;
