import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ifPositive } from "@ROM-utils/format";

const PalletNumber = ({ loading, editing, palletId, quantity, setPalletQuantity, className }) => {
  if (editing) {
    return (
      <StyledPalletNumber className={className} $editing>
        <input
          type="number"
          id={`pallet-quantity-${palletId}`}
          className="form-control px-2"
          value={quantity}
          onChange={(e) => ifPositive(setPalletQuantity)(e.target.value)}
        />
      </StyledPalletNumber>
    );
  }

  return <StyledPalletNumber className={className}>{loading ? 0 : quantity}</StyledPalletNumber>;
};

PalletNumber.propTypes = {
  palletId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  setPalletQuantity: PropTypes.func.isRequired,
  className: PropTypes.string,
};

PalletNumber.defaultProps = {
  className: "",
};

export default PalletNumber;

const StyledPalletNumber = styled.div`
  background-color: ${(props) => (props.$editing ? "#ffffff" : "#049dff")};
  color: white;
  border-radius: 5px;
  width: ${(props) => (props.$editing ? "70px" : "50px")};
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: large;
`;
