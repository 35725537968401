import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import Page from "@ROM-pages/Orders/Show";
import Layout from "@ROM-pages/Layouts/Authenticated";

class RouteHandler extends Route {
  static displayName = "Route/Orders/Show";

  render() {
    return (
      <Layout>
        <Page orderId={this.props.computedMatch.params.orderId} />
      </Layout>
    );
  }
}

RouteHandler.propTypes = {
  computedMatch: PropTypes.object
};

export default RouteHandler;
