import update from "immutability-helper";
import { handle } from "redux-pack";
import { LIST, UPDATE, SET_PAGE } from "./actions";

const initialState = {
  all: {
    items: [],
  },
  loading: false,
  pagination: {
    currentPage: 1,
    perPage: null,
    totalPages: null,
    totalRecords: null,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};

  switch (type) {
    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
            },
            pagination: { $set: json.meta.pagination },
          });
        },
      });

    case UPDATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const updatedItems = payload.reduce((acc, value) => ({ ...acc, [value.data.data.id]: value.data.data.attributes }), {});
          const updateItems = (list) =>
            list.map((item) => (item.id in updatedItems ? { ...item, attributes: updatedItems[item.id] } : item));

          return update(prevState, { all: { items: { $apply: updateItems } } });
        },
      });

    case SET_PAGE:
      return update(state, {
        pagination: {
          currentPage: { $set: payload.page },
        },
      });

    default:
      return state;
  }
}

export default reducer;
