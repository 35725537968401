import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Form from "@ROM-ui/Form";
import Select from "@ROM-components/common/Select";
import { loadRowsOptions } from "@ROM/CustomTableRows/utils";

const RelationSelect = ({ relatedTable, values, update, className }) => {
  const [options, setOptions] = useState([]);

  const handleRelationSelectChange = (event) => {
    let newValue = [];

    if (event) {
      newValue = event.map((option) => option.value);
    }

    update(newValue);
  };

  useEffect(() => {
    const loadAndSetOptions = async () => {
      setOptions(await loadRowsOptions(relatedTable));
    };

    loadAndSetOptions();
  }, []);

  return (
    <div className={className}>
      <Form.Label>{relatedTable.attributes.name}</Form.Label>
      <Select
        isMulti
        options={options}
        value={options.filter((option) => values.includes(option.value))}
        onChange={handleRelationSelectChange}
      />
    </div>
  );
};

RelationSelect.defaultProps = {
  className: "",
};

RelationSelect.propTypes = {
  className: PropTypes.string,
  relatedTable: PropTypes.shape().isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  update: PropTypes.func.isRequired,
};

export default RelationSelect;
