import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canEditCompanyRolesFromUsers } from "@ROM/Users/permissions";
import ItemsPicker, { asOptions } from "@ROM/CompanyAdmin/Users/Edit/ItemsPicker";

const UserRolesForm = ({ user, companyRoles, onUpdateRoles }) => {
  const currentUser = useSelector(selectCurrentUser);

  const roles = user.attributes.roles.companyRoles.map((role) => role.name);
  const nonUserRoles = companyRoles.filter((item) => !roles.includes(item));

  const handleAdd = (option) => {
    onUpdateRoles([...roles, option.value]);
  };

  const handleRemove = (option) => {
    onUpdateRoles(roles.filter((role) => role !== option.value));
  };

  return (
    <ItemsPicker
      pickedItems={asOptions(roles)}
      nonPickedItems={asOptions(nonUserRoles)}
      onAddItem={handleAdd}
      onRemoveItem={handleRemove}
      title="Roles"
      emptyText="Doesn't have any roles"
      selectText="Select New Role"
      removeText="Remove role for this user?"
      readOnly={!canEditCompanyRolesFromUsers(currentUser)}
      className="mt-4"
    />
  );
};

UserRolesForm.propTypes = {
  user: PropTypes.shape().isRequired,
  companyRoles: PropTypes.shape().isRequired,
  onUpdateRoles: PropTypes.func.isRequired,
};

export default UserRolesForm;
