import React from "react";
import PropTypes from "prop-types";

import AdminTable from "@ROM-components/common/AdminTable";
import WarehouseRow from "./WarehouseRow";
import WarehouseTableHeader from "./WarehouseTableHeader";

const WarehousesTable = ({ warehouses, handleColumnSort, included, filters, showEdit }) => {
  return (
    <div className="pt-2">
      <AdminTable header={<WarehouseTableHeader handleColumnSort={handleColumnSort} filters={filters} />}>
        {warehouses.map((warehouse) => {
          const region = included.find((inc) => inc?.id === warehouse?.relationships?.region?.data?.id);
          return <WarehouseRow key={warehouse.id} warehouse={warehouse} region={region} showEditLink={showEdit} />;
        })}
      </AdminTable>
    </div>
  );
};

WarehousesTable.propTypes = {
  warehouses: PropTypes.arrayOf().isRequired,
  handleColumnSort: PropTypes.func.isRequired,
  included: PropTypes.arrayOf().isRequired,
  filters: PropTypes.shape().isRequired,
  showEdit: PropTypes.bool.isRequired,
};

export default WarehousesTable;
