import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canListPaymentMethods, canAddPaymentMethod } from "@ROM/Customers/permissions";
import Select from "@ROM-components/common/Select";
import CustomersApi from "@ROM/Customers/api";
import AddCard from "@ROM/Payments/components/AddCard";

const INVOICE = "invoice";
const ADD_NEW_CARD = "add_card";

const INVOICE_OPTION = [{ value: INVOICE, label: "Invoice" }];
const ADD_NEW_CARD_OPTION = [{ value: ADD_NEW_CARD, label: "Add new card" }];

const PaymentMethodSelector = ({ customer, initialPaymentMethod, handlePaymentMethodSelected }) => {
  const currentUser = useSelector(selectCurrentUser);
  const [paymentOptions, setPaymentOptions] = useState(INVOICE_OPTION);
  const [paymentOption, setPaymentOption] = useState(initialPaymentMethod || "invoice");
  const [newCardId, setNewCardId] = useState(null);

  const canAdd = canAddPaymentMethod(currentUser, customer?.id);
  const canList = canListPaymentMethods(currentUser, customer?.id);

  const getCustomerPaymentMethods = async () => {
    const options = INVOICE_OPTION;
    setPaymentOptions(options);
    const addOptions = canAdd ? ADD_NEW_CARD_OPTION : [];
    let cards = [];
    if (canList) {
      const response = await CustomersApi.listPaymentMethods(customer.id);
      const userCards = response?.data?.data ?? [];
      cards = userCards.map((card) => {
        const { attributes } = card;
        return {
          value: card.id,
          label: `${attributes.ownerName} - ${attributes.brand} - •••• •••• •••• ${attributes.lastFourDigits} - ${attributes.expMonth}/${attributes.expYear}`,
        };
      });
    }
    setPaymentOptions([...options, ...cards, ...addOptions]);
  };

  useEffect(() => {
    getCustomerPaymentMethods();
    if (newCardId) setPaymentOption(newCardId);
  }, [newCardId]);

  useEffect(() => {
    if (paymentOption === INVOICE || paymentOption === ADD_NEW_CARD) {
      handlePaymentMethodSelected(null);
    } else {
      handlePaymentMethodSelected(paymentOption);
    }
  }, [paymentOption]);

  return (
    <>
      <Select
        options={paymentOptions}
        onChange={(event) => setPaymentOption(event.value)}
        value={paymentOptions.find((option) => option.value === paymentOption)}
        className="p-4"
      />
      {paymentOption === "add_card" && <AddCard customer={customer} setNewCardId={setNewCardId} />}
    </>
  );
};

PaymentMethodSelector.defaultProps = {
  initialPaymentMethod: null,
};

PaymentMethodSelector.propTypes = {
  initialPaymentMethod: PropTypes.string,
  customer: PropTypes.shape().isRequired,
  handlePaymentMethodSelected: PropTypes.func.isRequired,
};

export default PaymentMethodSelector;
