import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Section from "@ROM-components/common/Section";
import { getFilteredPriceLists, setPage } from "@ROM/PriceLists/actions";
import { selectPriceLists, selectPriceListsPagination } from "@ROM/PriceLists/selectors";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canCreatePriceList } from "@ROM/PriceLists/permissions";
import PriceListsTable from "@ROM/CompanyAdmin/PriceLists/Index";
import Filters from "@ROM/CompanyAdmin/PriceLists/Filters";
import Pagination from "@ROM-components/common/Pagination";
import Button from "@ROM-ui/Button";
import CreateModal from "@ROM/CompanyAdmin/PriceLists/New";
import { useFilters } from "@ROM/App/utils/filters";

const CompanyAdminPriceLists = () => {
  const [show, setShow] = useState(false);

  const currentUser = useSelector(selectCurrentUser);
  const priceLists = useSelector(selectPriceLists);
  const pagination = useSelector(selectPriceListsPagination);
  const dispatch = useDispatch();

  const initialFilters = {
    name: "",
    status: "",
    sort: "",
  };

  const getPriceLists = (filters, page) => dispatch(getFilteredPriceLists(filters, page));

  const [_loading, filters, handleFilterChange, handleClearFilters, handlePageClick] = useFilters(
    (page = 1) => getPriceLists(filters, page),
    initialFilters,
    setPage
  );

  const actions = canCreatePriceList(currentUser) ? <Button onClick={() => setShow(true)}>Create Price List</Button> : null;

  return (
    <>
      <CreateModal show={show} onCancel={() => setShow(false)} />
      <Section title="Price Lists" actions={actions} className="pt-4">
        <Filters handleFilterChange={handleFilterChange} handleClearFilters={handleClearFilters} filters={filters} />
        <PriceListsTable priceLists={priceLists} handleFilterChange={handleFilterChange} filters={filters} />
        <Pagination className="mt-4 mx-2" pagination={pagination} handlePageClick={handlePageClick} />
      </Section>
    </>
  );
};

export default CompanyAdminPriceLists;
