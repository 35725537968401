import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Fieldset = ({ legend, children, className, legendClassName, bodyClassName, showError }) => (
  <StyledFieldset $error={showError} className={className}>
    <StyledLegend $error={showError} className={legendClassName}>
      {legend}
    </StyledLegend>
    <StyledDiv className={bodyClassName}>{children}</StyledDiv>
  </StyledFieldset>
);

const StyledFieldset = styled.fieldset`
  border: ${(props) => `1px solid ${props.$error ? "#dc3545" : "#dee2e6"}`};
  border-radius: 5px;
  width: 100%;
`;

const StyledLegend = styled.legend`
  width: auto;
  font-size: 1rem;
  padding: 0 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 0;
  float: none;
  color: ${(props) => (props.$error ? "#dc3545" : "inherit")};
`;

const StyledDiv = styled.div`
  padding: 0.5rem 1rem 1rem 1rem;
`;

Fieldset.defaultProps = {
  className: "",
  legendClassName: "",
  bodyClassName: "",
};

Fieldset.propTypes = {
  className: PropTypes.string,
  legendClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  legend: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showError: PropTypes.bool.isRequired,
};

export default Fieldset;
