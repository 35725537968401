import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { canManageCategories } from "@ROM/Categories/permissions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canCreateProducts } from "@ROM/Products/permissions";
import Section from "@ROM-components/common/Section";
import { getFilteredProducts, setPage } from "@ROM/Products/actions";
import { selectProducts, selectProductsPagination } from "@ROM/Products/selectors";
import { companyAdminProductsCreateUrl, companyAdminProductsShowUrl, companyAdminCategoriesUrl } from "@ROM-utils/urlHelpers";
import ProductTable from "@ROM/CompanyAdmin/Products/Index";
import Filters from "@ROM/CompanyAdmin/Products/Filters";
import Button from "@ROM-ui/Button";
import { useFilters } from "@ROM/App/utils/filters";

const CompanyAdminProducts = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const products = useSelector(selectProducts);
  const pagination = useSelector(selectProductsPagination);

  const setPageDipatcher = (page) => dispatch(setPage(page));

  const initialFilters = {
    name: null,
    isActive: true,
    priced: false,
    featured: false,
    sort: null,
  };

  const callback = useCallback(async (page = 1) => {
    dispatch(getFilteredProducts(filters, { page }));
  });

  const [loading, filters, handleFilterChange, handleClearFilters, handlePageClick] = useFilters(
    callback,
    initialFilters,
    setPageDipatcher
  );

  const showProduct = (product) => {
    history.push(companyAdminProductsShowUrl(product.id));
  };

  const onCreateClick = () => {
    history.push(companyAdminProductsCreateUrl());
  };

  const onManageCategoriesClick = () => {
    history.push(companyAdminCategoriesUrl());
  };

  const actions = (
    <div>
      {canManageCategories(currentUser) && (
        <Button onClick={onManageCategoriesClick} className="me-2" variant="secondary">
          Manage Categories
        </Button>
      )}
      {canCreateProducts(currentUser) && (
        <Button id="company-admin-create-product" onClick={onCreateClick}>
          Create Product
        </Button>
      )}
    </div>
  );

  return (
    <Section title="Products" actions={actions}>
      <Filters handleFilterChange={handleFilterChange} handleClearFilters={handleClearFilters} filters={filters} />
      <ProductTable
        products={products}
        loading={loading}
        onProductClick={showProduct}
        handleFilterChange={handleFilterChange}
        filters={filters}
        pagination={pagination}
        handlePageClick={handlePageClick}
      />
    </Section>
  );
};

export default CompanyAdminProducts;
