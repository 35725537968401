import React from "react";
import { Route } from "react-router-dom";

import Page from "@ROM-pages/CompanyAdmin/Users/Edit";
import Layout from "@ROM-pages/Layouts/CompanyAdmin";
import Authorize from "@ROM/Auth/components/Authorize";
import { canViewUsers } from "@ROM/Users/permissions";

class RouteHandler extends Route {
  render() {
    return (
      <Layout>
        <Authorize permission={canViewUsers}>
          <Page userId={this.props.computedMatch.params.userId} />
        </Authorize>
      </Layout>
    );
  }
}

export default RouteHandler;
