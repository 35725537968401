import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Button from "@ROM-ui/Button";
import Select from "@ROM-components/common/Select";
import Fieldset from "@ROM-ui/Fieldset";
import Modal from "@ROM-components/common/Modal";
import IconPointer from "@ROM-ui/IconPointer";
import Badge from "@ROM-ui/Badge";

export const asOptions = (items) => items.map((item) => ({ value: item, label: item }));

const AddItemModal = ({ items, selectText, visible, onAddItem, onCancel }) => {
  const [item, setItem] = useState(null);
  const confirm = () => {
    if (item !== null) {
      onAddItem(item);
    }
  };

  return (
    <Modal confirmText="Add" isShowing={visible} onConfirm={confirm} onCancel={onCancel} header={selectText}>
      <Select options={items} value={item} onChange={(e) => setItem(e)} />
    </Modal>
  );
};

AddItemModal.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectText: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const ItemsPicker = ({
  pickedItems,
  nonPickedItems,
  onAddItem,
  onRemoveItem,
  title,
  emptyText,
  selectText,
  removeText,
  className,
  readOnly,
}) => {
  const [showAddItem, setShowAddItem] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  const handleAddItem = (item) => {
    setShowAddItem(false);
    setDeleteItem(null);
    onAddItem(item);
  };

  const handleDeleteItem = () => {
    setDeleteItem(null);
    onRemoveItem(deleteItem);
  };

  return (
    <div className={className}>
      <Fieldset legend={title}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-100">
            {pickedItems.map((item) => (
              <Badge key={item.value} bg="warning" className="me-2 my-1 py-2">
                {item.label}
                {!readOnly && <IconPointer className="fa fa-times text-black ms-3" onClick={() => setDeleteItem(item)} />}
              </Badge>
            ))}
            {pickedItems.length === 0 && emptyText && <EmptyContainer>{emptyText}</EmptyContainer>}
          </div>
          {!readOnly && (
            <Button variant="primary" className="ms-3" onClick={() => setShowAddItem(true)}>
              Add
            </Button>
          )}
        </div>
      </Fieldset>
      <AddItemModal
        key={pickedItems}
        items={nonPickedItems}
        visible={showAddItem}
        onAddItem={handleAddItem}
        onCancel={() => setShowAddItem(false)}
        selectText={selectText}
      />
      <Modal
        isShowing={deleteItem !== null}
        confirmText="Yes, Delete"
        onConfirm={handleDeleteItem}
        onCancel={() => setDeleteItem(null)}
        showCloseIcon={false}
      >
        {removeText}
      </Modal>
    </div>
  );
};

const EmptyContainer = styled.div`
  padding: 0.5rem 1rem;
  background-color: #f3f4f6;
  border-radius: 5px;
`;

ItemsPicker.defaultProps = {
  emptyText: null,
  className: "",
};

ItemsPicker.propTypes = {
  pickedItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  nonPickedItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  onAddItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  selectText: PropTypes.string.isRequired,
  removeText: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  className: PropTypes.string,
  emptyText: PropTypes.string,
};

export default ItemsPicker;
