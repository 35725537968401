import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCustomerForOrder } from "@ROM/Customers/actions";
import Modal from "@ROM-components/common/Modal";
import NewCustomerForm from "@ROM/Customers/components/Form/NewCustomerForm";
import PropTypes from "prop-types";

const NewCustomerModal = ({ show = false, onCancel, setCustomer }) => {
  const afterCreateCallback = (customer) => setCustomer(customer);

  return (
    <Modal isShowing={show} onCancel={onCancel} header="Create new customer" showCancelButton={false} showConfirmButton={false}>
      <NewCustomerForm afterCreateCallback={afterCreateCallback} />
    </Modal>
  );
};

const dispatchToProps = (dispatch) => {
  return {
    setCustomer: bindActionCreators(setCustomerForOrder, dispatch),
  };
};

export default connect(null, dispatchToProps)(NewCustomerModal);

NewCustomerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  setCustomer: PropTypes.func.isRequired,
};
