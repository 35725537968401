import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Section from "@ROM-components/common/Section";
import EditCustomer from "@ROM/Customers/components/Edit";
import { customersBreadcrumbs } from "@ROM/CompanyAdmin/utils";
import { useDispatch, useSelector } from "react-redux";
import { find } from "@ROM/Customers/actions";
import { selectCurrentCustomer } from "@ROM/Customers/selectors";
import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import { selectLoading, selectOrders, selectOrdersPagination } from "@ROM/Orders/selectors";
import { setPage, getFilteredOrders } from "@ROM/Orders/actions";
import { companyAdminCustomersManageUrl } from "@ROM-utils/urlHelpers";

const Page = ({ customerId, tab }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customer = useSelector(selectCurrentCustomer);
  const orders = useSelector(selectOrders);
  const ordersLoading = useSelector(selectLoading);
  const pagination = useSelector(selectOrdersPagination);

  const breadcrumbs = customersBreadcrumbs([
    { label: customer?.attributes.name || "...", active: true },
    { label: "Manage", active: true },
  ]);

  useEffect(() => {
    dispatch(find(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    if (customerId) dispatch(getFilteredOrders({ customerId }));
  }, [customerId]);

  const handlePageClick = (page) => {
    dispatch(setPage(page));
    dispatch(getFilteredOrders({ customerId, page }));
  };

  const setTab = (tabName) => {
    history.replace(companyAdminCustomersManageUrl(customerId, tabName));
  };

  return (
    <CustomerContext.Provider value={customer}>
      <Section title={customer?.attributes?.name || "..."} breadcrumbs={breadcrumbs}>
        <EditCustomer
          handlePageClick={handlePageClick}
          orders={orders}
          ordersLoading={ordersLoading}
          pagination={pagination}
          tab={tab}
          setTab={setTab}
        />
      </Section>
    </CustomerContext.Provider>
  );
};

Page.propTypes = {
  customerId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

export default Page;
