import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection(billOfLadingId) {
    return `/bill_of_ladings/${billOfLadingId}/pallets`;
  },

  resource(billOfLadingId, id) {
    return `${urls.collection(billOfLadingId)}/${id}`;
  },
};

export default {
  list(billOfLadingId, options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(billOfLadingId), {
      params: {
        ...options,
      },
    });
  },

  update(billOfLadingId, palletId, attributes) {
    const client = getApiClient(API_URL);
    return client.patch(urls.resource(billOfLadingId, palletId), attributes);
  },

  create(billOfLadingId, attributes) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(billOfLadingId), attributes);
  },

  destroy(billOfLadingId, palletId, meta = {}) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(billOfLadingId, palletId), meta);
  },
};
