import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import IconPointer from "@ROM-ui/IconPointer";
import FormModal from "@ROM/Addresses/components/AddressSelector/FormModal/FormModal";
import DeleteModal from "@ROM/Addresses/components/AddressSelector/DeleteModal/DeleteModal";

const AddressesRow = ({ address, fetchAddresses, customerId, canEdit, canDelete }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <>
      <AddressCard>
        <address className="d-flex w-80 justify-content-between align-items-center m-0">
          <div className="d-flex flex-column">
            {address.attributes.customerCompanyName && <h5>{address.attributes.customerCompanyName}</h5>}
            <p>{address.attributes.contact}</p>
            <p>
              {address.attributes.address1}
              {address.attributes.address2 && `, ${address.attributes.address2}`}
            </p>
            <p>{`${address.attributes.city}, ${address.attributes.state}`}</p>
            {address.attributes.countryName && <p>{address.attributes.countryName}</p>}
          </div>

          <div className="d-flex flex-column">
            <p>{address.attributes.phone}</p>
            <a href={address.attributes.email}>{address.attributes.email}</a>
          </div>
        </address>

        <div className="d-flex flex-column">
          {canEdit && <IconPointer className="fas fa-edit text-warning m-1" onClick={() => setShowEditModal(true)} />}
          {canDelete && (
            <IconPointer
              className="fas fa-trash text-danger m-1"
              id={`customer-edit-delete-address-${address.id}`}
              onClick={() => setShowDeleteModal(true)}
            />
          )}
        </div>
      </AddressCard>
      {showEditModal && (
        <FormModal
          show
          type={address && address.attributes.type}
          onCancel={() => setShowEditModal(false)}
          customerId={customerId}
          address={address}
          callback={fetchAddresses}
        />
      )}
      {showDeleteModal && (
        <DeleteModal show onCancel={() => setShowDeleteModal(false)} address={address} callback={fetchAddresses} />
      )}
    </>
  );
};

const AddressCard = styled.div`
  display: flex;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 0.5rem;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  p {
    margin: 0;
    font-size: 14px;
  }
`;

AddressesRow.propTypes = {
  address: PropTypes.shape().isRequired,
  fetchAddresses: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
};

export default AddressesRow;
