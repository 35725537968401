import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@ROM-ui/Button";
import NumberFormater from "@ROM-ui/NumberFormater";
import Modal from "@ROM-components/common/Modal";
import Select from "@ROM-components/common/Select";

import { prefix, suffix } from "../utils";

const DiscountForm = ({ initialType, initialAmount, update }) => {
  const options = [
    { value: "dollar", label: "Dollar" },
    { value: "percent", label: "Percent" },
  ];
  const [type, setType] = useState(options.find((item) => item.value === initialType) || options[0]);
  const [amount, setAmount] = useState(initialAmount);
  const changeAmount = ({ floatValue }) => {
    if (floatValue < 0) {
      setAmount(0);
    } else if (type.value === "percent" && floatValue > 100.0) {
      setAmount(10000);
    } else {
      setAmount(floatValue * 100);
    }
  };

  return (
    <>
      <Select classNamePrefix="discount-modal-type-select" className="w-40" options={options} value={type} onChange={setType} />
      <NumberFormater
        id="discount-modal-amount"
        value={amount}
        displayType="input"
        className="w-40 d-block rounded border p-1 px-3 bg-light mx-2"
        onValueChange={changeAmount}
        prefix={prefix(type.value)}
        suffix={suffix(type.value)}
      />
      <Button id="discount-modal-apply" variant="outline-primary" onClick={() => update(type.value, amount)}>
        Apply
      </Button>
    </>
  );
};

DiscountForm.propTypes = {
  initialAmount: PropTypes.number.isRequired,
  initialType: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
};

const DiscountModal = ({ isShowing, onUpdate, onCancel, type, amount, isMobile }) => {
  const size = isMobile ? "xx-large" : "default";
  return (
    <Modal
      header="Add Discount"
      size={size}
      showConfirmButton={false}
      showCancelButton={false}
      isShowing={isShowing}
      onCancel={onCancel}
      bodyClasses="py-3 px-3 d-flex flex-row justify-content-center"
    >
      <DiscountForm initialType={type} initialAmount={amount} update={onUpdate} />
    </Modal>
  );
};

DiscountModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DiscountModal;
