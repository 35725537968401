import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return `/companies`;
  },
  resource(id) {
    return `${urls.collection()}/${id}`;
  },
};

export default {
  currentCompany() {
    const client = getApiClient(API_URL);
    return client.get(`${urls.collection()}/fetch_current_company`);
  },

  update(id, attributes) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(id), attributes);
  },
};
