import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  list() {
    return "/categories";
  },

  create() {
    return "/categories";
  },

  delete(categoryId) {
    return `/categories/${categoryId}`;
  },

  createSubcategory(categoryId) {
    return `/categories/${categoryId}/subcategories`;
  },

  deleteSubcategory(categoryId, subcategoryId) {
    return `/categories/${categoryId}/subcategories/${subcategoryId}`;
  },
};

export default {
  list(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.list(), {
      params: {
        ...options,
      },
    });
  },

  create(options = {}) {
    const client = getApiClient(API_URL);
    return client.post(urls.create(), options);
  },

  createSubcategory(categoryId, options = {}) {
    const client = getApiClient(API_URL);
    return client.post(urls.createSubcategory(categoryId), options);
  },

  delete(categoryId, options = {}) {
    const client = getApiClient(API_URL);
    return client.delete(urls.delete(categoryId), options);
  },

  deleteSubcategory(categoryId, subcategoryId, options = {}) {
    const client = getApiClient(API_URL);
    return client.delete(urls.deleteSubcategory(categoryId, subcategoryId), options);
  },
};
