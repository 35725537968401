import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canDeleteSubategory } from "@ROM/Categories/permissions";
import { deleteSubcategory } from "@ROM/Categories/actions";
import Modal from "@ROM-components/common/Modal";

const Category = ({ categoryId, subcategory }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const [showDestroyConfirmationModal, setShowDestroyConfirmationModal] = useState(false);

  const destroySubcategory = () => {
    dispatch(deleteSubcategory(categoryId, subcategory.id));
  };

  return (
    <>
      <Container>
        {subcategory.attributes.name}
        {canDeleteSubategory(currentUser) && (
          <i
            role="button"
            aria-label="Destroy subcategory"
            tabIndex={0}
            className="fas fa-trash cursor-pointer text-danger"
            onClick={() => setShowDestroyConfirmationModal(true)}
          />
        )}
      </Container>
      <Modal
        isShowing={showDestroyConfirmationModal}
        onConfirm={destroySubcategory}
        onCancel={() => setShowDestroyConfirmationModal(false)}
        header="Are you sure you want to delete this category and subcategories?"
        showCloseIcon={false}
      />
    </>
  );
};

const Container = styled.div`
  background-color: #f9fafb;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin: 0.125rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

Category.propTypes = {
  categoryId: PropTypes.number.isRequired,
  subcategory: PropTypes.shape.isRequired,
};

export default Category;
