import { checkPermission } from "@ROM/Permissions/utils";

const isOrderCreator = (user, orderId) => user?.attributes.ordersIds.includes(orderId);

const isOrderCropAdvisor = (user, order) => order?.attributes.cropAdvisorId === user.id;

export const customerAdminRoleIds = (user) => user?.attributes.customerAdminIds || [];

const isAdminOfAnyCustomer = (user) => customerAdminRoleIds(user).length > 0;

const isAdminOfMoreThanOneCustomer = (user) => customerAdminRoleIds(user).length > 1;

const discountsEnabled = (company) => !!company?.attributes.allowOrderDiscounts;

export const canUpdateOrder = (user, order, company) => {
  if (!order || !order.attributes || !order.id) {
    return false;
  }
  const autoApprove = company.attributes.autoApproveOrders;
  const inStatus = (statusList) => statusList.includes(order?.attributes.status);
  const customerId = order?.attributes.customerId;

  if (inStatus(["cart", "opened"]) || (inStatus(["approved"]) && autoApprove)) {
    if (
      isOrderCreator(user, order.id) ||
      isOrderCropAdvisor(user, order) ||
      checkPermission(user, "orders:update-unprocessed", customerId)
    ) {
      return true;
    }
  }

  if (inStatus(["cart", "opened", "approved", "confirmed", "in_progress"])) {
    if (checkPermission(user, "orders:update", customerId)) {
      return true;
    }
  }
  return false;
};

export const canEditOrderCropAdvisor = (user, customerId) => checkPermission(user, "orders:update:crop-advisor", customerId);

export const canSetTrial = (user, order, customerId) =>
  isOrderCropAdvisor(user, order) || checkPermission(user, "orders:update:trial", customerId);
export const canSetDiscounts = (user, company, customerId) =>
  discountsEnabled(company) && checkPermission(user, "orders:update:discounts", customerId);

export const canSeeExtraInformation = (user) => isAdminOfAnyCustomer(user) || checkPermission(user, "company:employee");
export const canSeeOrdersFromOthers = (user) => isAdminOfAnyCustomer(user) || checkPermission(user, "company:employee");
export const canSeeOrdersFromAllRegions = (user) => checkPermission(user, "company:employee");
export const canSeeOrdersFromOtherCustomers = (user) =>
  isAdminOfMoreThanOneCustomer(user) || checkPermission(user, "company:employee");

export const canListOrderTransactions = (user, customerId) => checkPermission(user, "orders:transactions:list", customerId);
export const canChargeOrders = (user, customerId) => checkPermission(user, "orders:transactions:charge", customerId);
export const canRefundOrders = (user, customerId) => checkPermission(user, "orders:transactions:refund", customerId);
