import React from "react";
import { Route } from "react-router-dom";
import Page from "@ROM-pages/Inventories/Index";
import Layout from "@ROM-pages/Layouts/Authenticated";
import Authorize from "@ROM/Auth/components/Authorize";
import { canSeeWarehouseInventories } from "@ROM/WarehouseInventories/permissions";

class RouteHandler extends Route {
  static displayName = "Route/Inventories/Index";

  render() {
    return (
      <Layout expanded>
        <Authorize permission={canSeeWarehouseInventories}>
          <Page />
        </Authorize>
      </Layout>
    );
  }
}

export default RouteHandler;
