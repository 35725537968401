import { apiAction } from "@ROM-utils/actions";
import CustomTableRowsApi from "./api";

export const CREATE_ROW = "customTableRows/api/CREATE_ROW";
export const LIST_ROWS = "customTableRows/api/LIST_ROWS";
export const UPDATE_ROW = "customTableRows/api/UPDATE_ROW";
export const DESTROY_ROW = "customTableRows/api/DESTROY_ROW";

export function listRows(customTableId) {
  return apiAction(LIST_ROWS, CustomTableRowsApi.listRows(customTableId));
}

export function createRow(customTableId, rowData) {
  return apiAction(CREATE_ROW, CustomTableRowsApi.createRow(customTableId, rowData));
}

export function updateRow(customTableId, customTableRowId, rowData) {
  return apiAction(UPDATE_ROW, CustomTableRowsApi.updateRow(customTableId, customTableRowId, rowData));
}

export function destroyRow({ customTableId, id }) {
  return apiAction(DESTROY_ROW, CustomTableRowsApi.destroyRow(customTableId, id), { id });
}
