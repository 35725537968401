import React from "react";
import PropTypes from "prop-types";
import { humanize } from "@ROM/App/utils/format";

import { getRowClassName } from "@ROM/CustomTableRows/utils";

const TableHeader = ({ fields, relations }) => (
  <tr>
    {fields.map(({ name, displayName }, index) => (
      <th key={name} className={`text-nowrap ${getRowClassName(index)}`}>
        {displayName || humanize(name)}
      </th>
    ))}
    {relations.map(({ withTable }, index) => (
      <th key={withTable} className={`text-nowrap ${getRowClassName(index)}`}>
        {humanize(withTable)}
      </th>
    ))}
    <th className="text-end">Actions</th>
  </tr>
);

TableHeader.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape).isRequired,
  relations: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default TableHeader;
