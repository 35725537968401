import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Section from "@ROM-components/common/Section";
import WarehousesTable from "@ROM/CompanyAdmin/Warehouses/Index";
import { selectAllWarehouses, selectWarehousesPagination, selectAllIncluded } from "@ROM/Warehouses/selectors";
import { getFilteredWarehouses, setPage } from "@ROM/Warehouses/actions";
import Filters from "@ROM/CompanyAdmin/Warehouses/Filters";
import Pagination from "@ROM-components/common/Pagination";
import { companyAdminWarehousesCreateUrl } from "@ROM-utils/urlHelpers";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canCreateWarehouses, canEditWarehouses } from "@ROM/Warehouses/permissions";
import Button from "@ROM-ui/Button";
import { useFilters } from "@ROM/App/utils/filters";

const CompanyAdminWarehouses = () => {
  const warehouses = useSelector(selectAllWarehouses);
  const currentUser = useSelector(selectCurrentUser);
  const included = useSelector(selectAllIncluded);
  const pagination = useSelector(selectWarehousesPagination);
  const dispatch = useDispatch();

  const boundGetFilteredWarehouses = (filters, page) => dispatch(getFilteredWarehouses(filters, page));

  const initialFilters = {
    name: null,
    sort: null,
  };

  const history = useHistory();

  const callback = useCallback(async (page = 1) => {
    boundGetFilteredWarehouses(filters, page), [filters];
  });
  const [loading, filters, handleFilterChange, handleClearFilters, handlePageClick] = useFilters(
    callback,
    initialFilters,
    setPage
  );

  const onCreateClick = () => {
    history.push(companyAdminWarehousesCreateUrl());
  };

  const handleColumnSort = (sortField) => {
    handleFilterChange("sort", `${filters.sort === sortField ? `-${sortField}` : sortField}`);
  };

  const createAction = canCreateWarehouses(currentUser) ? <Button onClick={onCreateClick}>Create Warehouse</Button> : null;

  return (
    <Section title="Warehouses" actions={createAction}>
      <Filters handleFilterChange={handleFilterChange} handleClearFilters={handleClearFilters} filters={filters} />
      <WarehousesTable
        warehouses={warehouses}
        handleColumnSort={handleColumnSort}
        included={included}
        filters={filters}
        showEdit={canEditWarehouses(currentUser)}
      />
      <Pagination pagination={pagination} handlePageClick={handlePageClick} />
    </Section>
  );
};

export default CompanyAdminWarehouses;
