import React from "react";
import { useHistory } from "react-router-dom";

import Section from "@ROM-components/common/Section";
import NewCustomerForm from "@ROM/Customers/components/Form/NewCustomerForm";
import { customersBreadcrumbs } from "@ROM/CustomerAdmin/utils";
import { customerAdminUrl } from "@ROM-utils/urlHelpers";

const Page = () => {
  const history = useHistory();

  const afterCreateCallback = () => setTimeout(() => history.push(customerAdminUrl()), 1250);
  const breadcrumbs = customersBreadcrumbs([{ label: "Create", active: true }]);

  return (
    <Section title="Create Customer" breadcrumbs={breadcrumbs} containerStyle={{ maxWidth: "650px" }}>
      <NewCustomerForm afterCreateCallback={afterCreateCallback} />
    </Section>
  );
};

export default Page;
