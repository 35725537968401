/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import ReactSelect from "react-select";
import PropTypes from "prop-types";

const selectCustomStyles = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused || !state.selectProps.isInvalid ? "#ddd" : "red",
    "&:hover": {
      borderColor: state.isFocused || !state.selectProps.isInvalid ? "#ddd" : "red",
    },
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 99999,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isFocused || !state.selectProps.isInvalid ? "#ddd" : "red",
    "&:hover": {
      color: state.isFocused || !state.selectProps.isInvalid ? "#ddd" : "red",
    },
  }),
  zIndex: 10000,
};

const Select = ({ disabled: isDisabled, ...props }) => {
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <ReactSelect
      menuPortalTarget={document.body}
      styles={{ ...props.styles, ...selectCustomStyles }}
      formatGroupLabel={formatGroupLabel}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

Select.defaultProps = {
  disabled: false,
  props: {},
};

Select.propTypes = {
  disabled: PropTypes.bool,
  props: PropTypes.shape(),
};

export default Select;
