import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Company = ({ company }) => {
  const regex = new RegExp(/^([a-z]+\:\/{2})?(.*)/);
  const [extension, domainC] = window.location.href.split(".").reverse();
  const domain = domainC.match(regex)[2];
  const href = company.domain ? `//${company.domain}.${extension}` : `//${company.subdomain}.${domain}.${extension}`;

  return (
    <Container className="card">
      <div className="card-body">
        <h5 className="card-title">{company.name}</h5>
        <a className="btn btn-primary" href={href}>
          Visit
        </a>
      </div>
    </Container>
  );
};

const Container = styled.div`
  min-width: 200px;
  margin: 4px;
  display: inline-block;
`;

Company.propTypes = {
  company: PropTypes.shape({
    domain: PropTypes.string,
    subdomain: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default Company;
