import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  selectCurrentProduct,
  selectCurrentProductPriceLists,
  selectCurrentProductPriceListItems,
} from "@ROM/Products/selectors";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canEditProducts, canDeleteProducts } from "@ROM/Products/permissions";
import { destroy as destroyProduct, find } from "@ROM/Products/actions";
import { companyAdminUrl, companyAdminProductsUrl, companyAdminProductEditUrl } from "@ROM-utils/urlHelpers";
import { productsBreadcrumbs } from "@ROM/CompanyAdmin/utils";
import LoadingSplash from "@ROM-components/LoadingSplash/index";
import Alert from "@ROM-ui/Alert";
import Section from "@ROM-components/common/Section";
import ProductDetails from "@ROM/CompanyAdmin/Products/Show";
import PriceListItemsList from "@ROM/PriceListItems/components/List";

const Page = ({ productId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector(selectCurrentUser);
  const product = useSelector(selectCurrentProduct);
  const priceLists = useSelector(selectCurrentProductPriceLists);
  const priceListItems = useSelector(selectCurrentProductPriceListItems);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(find(productId)).then(() => setLoading(false));
  }, []);

  const onDeleteProduct = (prodId) => {
    dispatch(destroyProduct(prodId)).then(() => history.push(companyAdminProductsUrl()));
  };

  const onEditProduct = (prodId) => {
    history.push(companyAdminProductEditUrl(prodId));
  };

  const breadcrumbs = productsBreadcrumbs([{ label: product?.attributes?.name, active: true }]);

  if (loading) {
    return <LoadingSplash />;
  }

  if (!product) {
    return <Redirect to={companyAdminUrl()} />;
  }

  return (
    <Section breadcrumbs={breadcrumbs}>
      <ProductDetails
        product={product}
        canEditProduct={canEditProducts(currentUser)}
        canDeleteProduct={canDeleteProducts(currentUser)}
        onDeleteProduct={onDeleteProduct}
        onEditProduct={onEditProduct}
        className="mt-2 mb-4"
      />

      {priceListItems.length === 0 ? (
        <div className="pb-4">
          <Alert variant="info">This product does not have any price list</Alert>
        </div>
      ) : (
        <>
          <h4 className="text-muted mb-4 pt-1">Related Price Lists</h4>
          <PriceListItemsList priceListItems={priceListItems} priceLists={priceLists} />
        </>
      )}
    </Section>
  );
};

Page.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default Page;
