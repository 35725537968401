import React from "react";

import Section from "@ROM-components/common/Section";
import CustomTableForm from "@ROM/CustomTables/components/Form";

const Page = () => {
  return (
    <Section title="Create Table">
      <CustomTableForm />
    </Section>
  );
};

Page.propTypes = {};

export default Page;
