import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useNavBarHeight } from "@ROM-components/utils/Responsive";

const AdminContainer = ({ children }) => {
  const navbarHeight = useNavBarHeight();
  return <StyledDiv $navbarHeight={navbarHeight}>{children}</StyledDiv>;
};

const StyledDiv = styled.div`
  background-color: white;
  width: 100%;
  min-height: ${(props) => `calc(100vh - ${props.$navbarHeight}px)`};

  padding-right: 1rem;
  padding-left: 1rem;

  @media (min-width: 768px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  @media (min-width: 992px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

AdminContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminContainer;
