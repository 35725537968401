import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import useIsMobile from "@ROM-components/utils/Responsive";
import Button from "@ROM-ui/Button";
import Form from "@ROM-ui/Form";
import SignatureCanvas from "@ROM-ui/SignatureCanvas";
import Modal from "@ROM-components/common/Modal";
import FileUploader from "@ROM-common/FileUploader";
import { deliver } from "@ROM/DeliveryStatuses/actions";

const Deliver = ({ delivery, setError }) => {
  const dispatch = useDispatch();
  const sigCanvasContainer = useRef();
  const sigCanvas = useRef({});

  const isMobile = useIsMobile();

  const [showModal, setShowModal] = useState(false);
  const [deliveryAssets, setDeliveryAssets] = useState([]);
  const [hasSignature, setHasSignature] = useState(false);
  const [deliveryReceiverName, setDeliveryReceiverName] = useState("");
  const [errors, setErrors] = useState({});

  const handleDeliver = async () => {
    setErrors({});

    let deliveryReceiverSignature = null;

    if (hasSignature) {
      if (deliveryReceiverName.length === 0) {
        setErrors({ deliveryReceiverName: "Signature clarification can't be empty" });
        return;
      }

      deliveryReceiverSignature = {
        name: `signature-${delivery.id}`,
        data: sigCanvas.current.toDataURL("image/png").split(",")[1],
        type: "image/png",
      };
    }

    const response = await dispatch(
      deliver({ deliveryId: delivery.id, deliveryAssets, deliveryReceiverSignature, deliveryReceiverName })
    );

    setShowModal(false);

    if (response.payload.status !== 201) {
      setError(true);
    }
  };

  const afterDeliveryAssetUpload = (assets, setUploadingFiles) => {
    setDeliveryAssets([...deliveryAssets, ...assets]);
    setUploadingFiles(false);
  };

  const deleteDeliveryAsset = (assetToDelete, setRemovingFile) => {
    setDeliveryAssets(deliveryAssets.filter((asset) => asset.id !== assetToDelete.id));
    setRemovingFile(false);
  };

  const resizeSignatureCanvas = () => {
    sigCanvas.current.getCanvas().width = sigCanvasContainer.current.clientWidth - 40;
    sigCanvas.current.getCanvas().height = 85;
    sigCanvas.current.clear();
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setHasSignature(false);
    setErrors({ deliveryReceiverName: null });
  };

  return (
    <>
      <Button variant="outline-success" className="ms-2 px-2" size="sm" onClick={() => setShowModal(true)}>
        Mark as Delivered
      </Button>
      <Modal
        isShowing={showModal}
        title="Deliver confirmation"
        confirmText="Confirm Delivery"
        onConfirm={handleDeliver}
        onCancel={() => setShowModal(false)}
        onAfterOpen={resizeSignatureCanvas}
        bodyClass="pb-0"
        secondary
      >
        <ImagesContainer>
          <FileUploader
            currentAssets={deliveryAssets}
            afterAssetUpload={afterDeliveryAssetUpload}
            deleteAsset={deleteDeliveryAsset}
            textUpload="Add photo(s) of Delivery"
            secondary
          />
        </ImagesContainer>
        <SignatureContainer $isMobile={isMobile} ref={sigCanvasContainer}>
          <Form.Group className="w-100">
            <Form.Control
              type="text"
              placeholder="Signature clarification"
              value={deliveryReceiverName}
              isInvalid={!!errors.deliveryReceiverName}
              onChange={(e) => setDeliveryReceiverName(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">{errors.deliveryReceiverName}</Form.Control.Feedback>
          </Form.Group>
          <SignatureCanvas backgroundColor="#2c2c2c" penColor="white" ref={sigCanvas} onEnd={() => setHasSignature(true)} />
          <div className="bg-white" style={{ height: "2px" }} />
          <ClearSignature onClick={clearSignature}>&times;</ClearSignature>
        </SignatureContainer>
      </Modal>
    </>
  );
};

const ImagesContainer = styled.div`
  overflow-y: auto;
  max-height: 400px;
  padding: 0.5rem 0.3rem;
`;

const SignatureContainer = styled.div`
  position: relative;
  background-color: #2c2c2c;
  padding: 1.25rem 1.25rem 0 1.25rem;
  margin-top: ${(props) => (props.$isMobile ? "0.5rem" : "4.5rem")};
  margin-left: -1.25rem;
  margin-right: -1.25rem;

  .clear-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0.25rem;
  }
`;

const ClearSignature = styled.i`
  position: absolute;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  left: 1.25rem;
  bottom: 0.25rem;
`;

Deliver.propTypes = {
  delivery: PropTypes.shape({ id: PropTypes.number }).isRequired,
  setError: PropTypes.func.isRequired,
};

export default Deliver;
