import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Button from "@ROM-ui/Button";
import Spinner from "@ROM-ui/Spinner";
import styled from "styled-components";
import useIsMobile from "@ROM-components/utils/Responsive";
import { selectAllPallets, selectAllPalletsBillOfLadingItems, selectPalletsLoading } from "@ROM/Pallets/selectors";
import Skeleton from "react-loading-skeleton";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { create, update, reSort } from "@ROM/BillOfLadings/BillOfLadingItems/actions";
import { canEditBillOfLadings, canReorderBillOfLadingsPallets } from "@ROM/BillOfLadings/permissions";
import { find } from "@ROM/BillOfLadings/actions";
import {
  destroy as deletePallet,
  list as fetchPallets,
  create as createPallet,
  update as updatePallet,
} from "@ROM/Pallets/actions";
import { selectAllDeliveries, selectAllDeliveryOrders } from "@ROM/Deliveries/selectors";
import { selectCurrentBillOfLadingDeliveryItems } from "@ROM/BillOfLadings/selectors";
import { getPalletsWeight, getPalletsOverweight } from "@ROM/BillOfLadings/utils";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import BillOfLadingDownloadButton from "@ROM/BillOfLadings/components/Form/DownloadButton";

import { TRUCK_CARGO_LIMIT_LBS } from "@ROM/App/constants";

import { orderUrl } from "@ROM-utils/urlHelpers";

import AddOrderModal from "../AddOrderModal";
import Pallet from "./Pallet";
import DeliveryItemRow from "./DeliveryItemRow";
import DeliveryItemRowHeader from "./DeliveryItemRowHeader";
import PalletItemHeaders from "./PalletItemHeaders";
import BillingInformation from "./BillingInformation";
import ShippingInformation from "./ShippingInformation";

import PdfRender from "./PdfRender";

const DELIVERY_ITEMS = "deliveryItems";
const CREATE_NEW_PALLET = "CREATE_NEW_PALLET";
const PALLET = "PALLET";

const getGroupedItems = (pallets, billOfLadingItems) => {
  const samePalletId = (palletId) => (item) => item?.attributes?.palletId.toString() === palletId;
  return pallets.reduce(
    (acc, pallet) => ({
      ...acc,
      [pallet.id]: billOfLadingItems.filter(samePalletId(pallet.id)),
    }),
    {}
  );
};

const getQuantities = (deliveryItems, billOfLadingItems, pallets) => {
  const remaining = {};
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  deliveryItems.forEach((deliveryItem) => {
    remaining[deliveryItem.id] = deliveryItem.attributes.quantity;
    const selectedSum = billOfLadingItems
      .filter((billOfLadingItem) => billOfLadingItem.attributes.deliveryItemId.toString() === deliveryItem.id)
      .map((billOfLadingItem) => billOfLadingItem.attributes.quantity)
      .reduce(reducer, 0);
    remaining[deliveryItem.id] -= selectedSum;
  });
  const selected = {};
  pallets.forEach((pallet) => {
    const palletId = pallet.id;
    const itemsInPallet = billOfLadingItems.filter(
      (billOfLadingItem) => billOfLadingItem.attributes.palletId.toString() === palletId
    );
    if (!(palletId in selected)) selected[palletId] = {};
    itemsInPallet.forEach(({ attributes }) => {
      selected[palletId][attributes.deliveryItemId] = attributes.quantity;
    });
  });
  return { remaining, selected };
};

const BillOfLadingForm = ({ user, orderId, deliveryId, currentBillOfLading, loading, canEditAllParams }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [creatingPallet, setCreatingPallet] = useState(false);
  const [palletsFetched, setPalletsFetched] = useState(false);
  const [palletChanging, setPalletChanging] = useState(null);
  const [error, setError] = useState(false);
  const [showAddOrderModal, setShowAddOrderModal] = useState(false);
  const [isBuildingPDF, setIsBuildingPDF] = useState(true);

  const deliveryItems = useSelector(selectCurrentBillOfLadingDeliveryItems);
  const billOfLadingItems = useSelector(selectAllPalletsBillOfLadingItems);
  const pallets = useSelector(selectAllPallets);
  const palletsLoading = useSelector(selectPalletsLoading);
  const orders = useSelector(selectAllDeliveryOrders);
  const order = orders.find((item) => item.id === orderId);
  const deliveries = useSelector(selectAllDeliveries);
  const currentCompany = useSelector(selectCurrentCompany);

  const billOfLadingItemsGrouped = getGroupedItems(pallets, billOfLadingItems);
  const quantities = getQuantities(deliveryItems, billOfLadingItems, pallets);

  const canDeletePallets = canEditBillOfLadings(user);
  const canReorderPallets = canReorderBillOfLadingsPallets(user);

  const refreshBillOfLading = () => dispatch(find(currentBillOfLading.id));
  const findPallet = (palletId) => pallets.find((each) => each.id.toString() === palletId);

  const handlePalletDelete = async (palletId) => {
    const pallet = pallets.find((each) => each.id.toString() === palletId);
    const bolItems = billOfLadingItemsGrouped[palletId];
    if (pallet.attributes.quantity > 1 && bolItems.length === 1) {
      const bolItem = bolItems[0];
      const packPerPallet = bolItem.attributes.productPackagesPerPallet;

      if (bolItem.attributes.quantity > packPerPallet) {
        pallet.attributes.quantity -= 1;
        await dispatch(updatePallet(currentBillOfLading.id, palletId, pallet.attributes));
        bolItem.attributes.quantity -= packPerPallet;
        await dispatch(update(bolItem));
        refreshBillOfLading();
        return;
      }
    }
    await dispatch(deletePallet(currentBillOfLading.id, palletId));
    refreshBillOfLading();
  };

  const handlePalletAttributesChange = async (palletId, newAttributes) => {
    const { attributes } = findPallet(palletId);
    await dispatch(updatePallet(currentBillOfLading.id, palletId, { ...attributes, ...newAttributes }));
    await refreshBillOfLading();
  };

  const handleChange = (id, value, palletId) => {
    const newStock = quantities.remaining[id] + quantities.selected[palletId][id] - value;
    if (value <= 0 || newStock < 0) {
      return;
    }

    const billOfLadingItem = billOfLadingItems.find(
      (item) => item.attributes.deliveryItemId === id && item.attributes.palletId.toString() === palletId
    );
    billOfLadingItem.attributes.quantity = value;

    dispatch(update(billOfLadingItem));
    refreshBillOfLading();
  };

  const newBillOfLadingItem = async (attributes) => {
    const { palletId, deliveryItemId } = attributes;

    if (!(palletId in quantities.selected)) quantities.selected[palletId] = {};
    if (!quantities.selected[palletId][deliveryItemId]) {
      quantities.selected[palletId][deliveryItemId] = 0;
      const response = await dispatch(create(attributes));
      if (response.payload.status !== 201) setError(true);
    } else {
      const billOfLadingItem = billOfLadingItems.find(
        (item) => item.attributes.deliveryItemId.toString() === deliveryItemId && item.attributes.palletId.toString() === palletId
      );
      quantities.selected[palletId][deliveryItemId] += quantities.remaining[deliveryItemId];
      billOfLadingItem.attributes.quantity = quantities.selected[palletId][deliveryItemId];
      const response = await dispatch(update(billOfLadingItem));
      if (response.payload.status !== 200) setError(true);
      quantities.remaining[deliveryItemId] = 0;
    }
    refreshBillOfLading();
  };

  const hideAddOrderModal = async () => {
    setShowAddOrderModal(false);
    refreshBillOfLading();
  };

  useEffect(() => {
    if (currentBillOfLading && !loading) {
      setPalletsFetched(false);
      dispatch(fetchPallets(currentBillOfLading.id)).then(() => setPalletsFetched(true));
    }
  }, [dispatch, currentBillOfLading?.id, loading]);

  const addItemToNewPallet = async (billOfLadingId, quantity, isLocked, bolItemAttributes) => {
    const customAttributes = bolItemAttributes ? {} : { customDescription: "New Item", customQuantity: 1.0 };
    const attributes = {
      billOfLadingId,
      quantity,
      isLocked,
      isCustom: !bolItemAttributes,
      weight: 0,
      ...customAttributes,
    };
    const response = await dispatch(createPallet(attributes));
    if (response.payload.status !== 201) {
      setError(true);
    } else if (bolItemAttributes) {
      const palletId = response.payload.data.data.id;
      await newBillOfLadingItem({ ...bolItemAttributes, palletId });
    }
  };

  const addItemsToNewPallets = async (bolId, palletDetails) => {
    setCreatingPallet(true);

    // eslint-disable-next-line no-restricted-syntax
    for (const { quantity, isLocked, bolItemAttributes } of palletDetails) {
      // Queries performed serially on purpose.
      // eslint-disable-next-line no-await-in-loop
      await addItemToNewPallet(bolId, quantity, isLocked, bolItemAttributes);
    }
    refreshBillOfLading();
    setCreatingPallet(false);
  };

  const addDeliveryToExistingPallet = async (deliveryItem, palletId, position = -1) => {
    const pos = position === -1 ? Object.keys(quantities.selected[palletId]).length : position;
    const billOfLadingItem = {
      billOfLadingId: currentBillOfLading.id,
      deliveryItemId: deliveryItem.id,
      quantity: quantities.remaining[deliveryItem.id],
      palletId,
      position: pos,
    };

    await newBillOfLadingItem(billOfLadingItem);
    refreshBillOfLading();
  };

  const getPalletDetail = (deliveryItem) => {
    const remainingQuantity = quantities.remaining[deliveryItem.id];
    let palletQuantities = 1;
    let quantity = 0;
    let isLocked = false;

    if (remainingQuantity >= deliveryItem.attributes.productPackagesPerPallet) {
      palletQuantities = Math.floor(remainingQuantity / deliveryItem.attributes.productPackagesPerPallet);
      quantity = palletQuantities * deliveryItem.attributes.productPackagesPerPallet;
      isLocked = palletQuantities > 1;
    } else {
      quantity = remainingQuantity;
    }

    const bolItemAttributes = {
      billOfLadingId: currentBillOfLading.id,
      deliveryItemId: deliveryItem.id,
      quantity,
    };
    return { quantity: palletQuantities, isLocked, bolItemAttributes };
  };

  const addDeliveryToNewPallet = (deliveryItem) => {
    const palletDetail = getPalletDetail(deliveryItem);

    addItemsToNewPallets(currentBillOfLading.id, [palletDetail]);
  };

  const autoPalletize = () => {
    const palletDetails = deliveryItems.map((item) => getPalletDetail(item));
    addItemsToNewPallets(currentBillOfLading.id, palletDetails);
  };

  const createCustomPallet = () => {
    addItemToNewPallet(currentBillOfLading.id, 1, true);
  };

  const moveDeliveryToNewPallet = async (palletId, itemIndex) => {
    const palletQuantities = 1;
    const isLocked = false;

    const itemToUpdate = billOfLadingItemsGrouped[palletId][itemIndex];

    setCreatingPallet(true);
    const response = await dispatch(
      createPallet({
        billOfLadingId: currentBillOfLading.id,
        palletQuantities,
        isLocked,
      })
    );

    if (response.payload.status !== 201) {
      setError(true);
    } else {
      const newPalletId = response.payload.data.data.id;
      itemToUpdate.attributes.palletId = newPalletId;
      itemToUpdate.attributes.position = 0;

      const updateResponse = await dispatch(update(itemToUpdate));
      if (updateResponse.payload.status !== 200) {
        setError(true);
      }
    }
    refreshBillOfLading();
    setCreatingPallet(false);
  };

  const onDragEnd = async ({ source, destination }) => {
    if (!destination) return;

    const sourceIsPallet = source.droppableId.split("_")[0] === PALLET;

    if (source.droppableId === destination.droppableId) {
      if (sourceIsPallet && source.index !== destination.index && canReorderPallets) {
        const palletId = destination.droppableId.split("_").pop();
        const itemToUpdate = billOfLadingItemsGrouped[palletId][source.index];
        itemToUpdate.attributes.position = destination.index;
        setPalletChanging(palletId);
        await dispatch(reSort(itemToUpdate));
        setPalletChanging(null);
      }
    } else if (destination.droppableId === CREATE_NEW_PALLET) {
      if (sourceIsPallet) {
        const sourcePalletId = source.droppableId.split("_").pop();
        moveDeliveryToNewPallet(sourcePalletId, source.index);
      } else {
        const deliveryItem = deliveryItems[source.index];
        addDeliveryToNewPallet(deliveryItem);
      }
    } else if (source.droppableId === DELIVERY_ITEMS) {
      const palletId = destination.droppableId.split("_").pop();
      const deliveryItem = deliveryItems[source.index];
      addDeliveryToExistingPallet(deliveryItem, palletId, source.index);
    } else {
      // moving from one pallet to other
      const sourcePalletId = source.droppableId.split("_").pop();
      const destinationPalletId = destination.droppableId.split("_").pop();

      const [itemToUpdate] = billOfLadingItemsGrouped[sourcePalletId].splice(source.index, 1);

      if (itemToUpdate) {
        itemToUpdate.attributes.palletId = destinationPalletId;
        itemToUpdate.attributes.position = destination.index;

        const response = await dispatch(update(itemToUpdate));
        if (response.payload.status !== 200) setError(true);
        refreshBillOfLading();
      }
    }
  };

  const getFilteredOrders = () => {
    const deliveriesOfBoL = deliveries.filter(
      (delivery) => delivery.attributes.billOfLadingId?.toString() === currentBillOfLading?.id
    );
    const orderIds = deliveriesOfBoL.map((delivery) => delivery.attributes.orderId.toString());
    return orders.filter((item) => orderIds.includes(item?.id));
  };
  const filteredOrders = getFilteredOrders();

  const palletItems = pallets.map((pallet, index) => {
    const palletId = pallet.id;
    const items = billOfLadingItemsGrouped[palletId] ?? [];

    const itemLines = items.map((item) => {
      const multiplier = quantities?.selected?.[palletId]?.[item.attributes.deliveryItemId] ?? 0;
      return `${multiplier}x ${item.attributes.productName}`;
    });
    const palletsQty = `${pallet.attributes.quantity} pallet ${pallet.attributes.quantity !== 1 ? "s" : ""}`;
    return {
      palletId,
      title: `${index + 1} - ${palletsQty}`,
      isLocked: pallet.attributes.isLocked,
      items: itemLines,
    };
  });

  const palletsWeight = getPalletsWeight(pallets);
  const showRefrigerationWarning =
    palletsWeight > currentCompany.attributes.autoSelectRefrigerationForWeight &&
    !currentBillOfLading.attributes.requiresRefrigeration;
  const palletsOverweight = getPalletsOverweight(palletsWeight);
  const downloadDisabled = isBuildingPDF;

  return (
    <>
      <StyledRow>
        <Row className="m-0 w-100">
          <DragDropContext onDragEnd={onDragEnd}>
            <DeliveryItemsCol xs={12} xl={4}>
              {loading || !palletsFetched ? (
                <Skeleton count={8} height={40} />
              ) : (
                filteredOrders.map((order) => {
                  return (
                    <div key={order.id}>
                      <DeliveryItemRowHeader order={order} billOfLading={currentBillOfLading} />
                      <Droppable droppableId={DELIVERY_ITEMS} key={order.id} isDropDisabled>
                        {(provided) => (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            {deliveryItems.map(
                              (deliveryItem, index) =>
                                deliveryItem.attributes.orderId.toString() === order.id && (
                                  <DeliveryItemRow
                                    key={deliveryItem.id}
                                    item={deliveryItem}
                                    index={index}
                                    quantities={quantities}
                                    billOfLadingItems={billOfLadingItems}
                                    pallets={palletItems}
                                    addDeliveryToExistingPallet={addDeliveryToExistingPallet}
                                    addDeliveryToNewPallet={addDeliveryToNewPallet}
                                  />
                                )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  );
                })
              )}
              <Button variant="outline-primary mt-3" className="w-100" onClick={() => setShowAddOrderModal(true)}>
                Add products from another order
              </Button>
            </DeliveryItemsCol>

            <ShippingInfoCol xs={12} xl={4} $isMobile={isMobile}>
              {billOfLadingItems.length > 0 && <PalletItemHeaders />}
              {loading || !palletsFetched ? (
                <Skeleton count={4} height={40} />
              ) : (
                <>
                  {pallets.map((pallet, index) => {
                    const palletId = pallet.id;
                    const items = billOfLadingItemsGrouped[palletId] ?? [];
                    return creatingPallet ? (
                      <Skeleton key={palletId} height={60} />
                    ) : (
                      <Pallet
                        index={index}
                        items={items}
                        key={palletId}
                        pallet={pallet}
                        billOfLadingId={currentBillOfLading.id}
                        handlePalletDelete={canDeletePallets ? handlePalletDelete : null}
                        handlePalletAttributesChange={handlePalletAttributesChange}
                        quantities={quantities}
                        handleChange={handleChange}
                        palletsLoading={palletsLoading || palletChanging === palletId}
                      />
                    );
                  })}
                  {pallets.length > 0 && (
                    <StyledAllWeight className="text-end my-4">
                      {`All Pallets Weight: ${palletsWeight} lbs`}
                      {palletsOverweight && (
                        <div className="alert alert-danger">
                          <i className="fa fa-exclamation-circle me-1" />
                          {`Total cargo weight is over ${TRUCK_CARGO_LIMIT_LBS} lbs.`}
                        </div>
                      )}
                    </StyledAllWeight>
                  )}
                </>
              )}
              <Droppable droppableId={CREATE_NEW_PALLET} isDropDisabled={palletsLoading}>
                {(provided) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <>
                      {loading || creatingPallet ? (
                        <Skeleton height={60} />
                      ) : (
                        <DropZone>
                          Drop Products for New Pallet
                          {provided.placeholder}
                        </DropZone>
                      )}
                      {error && <p className="my-2 text-danger">Something went wrong, try again later</p>}
                    </>
                  </div>
                )}
              </Droppable>
              {!loading && palletsFetched && billOfLadingItems.length === 0 && (
                <StyledButton className="w-100 mt-2" onClick={autoPalletize}>
                  Auto assign to pallets
                </StyledButton>
              )}
              {!loading && palletsFetched && (
                <StyledButton className="w-100 mt-2" onClick={createCustomPallet}>
                  Add Custom Pallet
                </StyledButton>
              )}
              <div className="d-flex justify-content-between align-items-center my-4">
                <h5>Shipping Information</h5>
                <Line />
              </div>
              {loading || !currentBillOfLading || !palletsFetched ? (
                <>
                  <Skeleton count={5} height={40} />
                  <Skeleton height={80} />
                </>
              ) : (
                <ShippingInformation
                  deliveryId={deliveryId}
                  currentBillOfLading={currentBillOfLading}
                  order={order}
                  canEditAllParams={canEditAllParams}
                  showRefrigerationWarning={showRefrigerationWarning}
                  onChange={() => setIsBuildingPDF(true)}
                />
              )}

              <div className="d-flex justify-content-between align-items-center my-4">
                <h5>Billing Information</h5>
                <Line />
              </div>
              {loading || !currentBillOfLading || !palletsFetched || !currentCompany ? (
                <>
                  <Skeleton count={5} height={40} />
                  <Skeleton height={80} />
                </>
              ) : (
                <BillingInformation
                  currentBillOfLading={currentBillOfLading}
                  company={currentCompany}
                  canEditAllParams={canEditAllParams}
                />
              )}
            </ShippingInfoCol>
          </DragDropContext>
          <PdfRenderCol xl={4} className="p-0 text-center bg-white d-none d-lg-block">
            <PdfRender
              currentBillOfLading={currentBillOfLading}
              deliveryId={deliveryId}
              orderId={orderId}
              onRenderStateChange={(isRendering) => setIsBuildingPDF(isRendering)}
            />
          </PdfRenderCol>
        </Row>
      </StyledRow>
      <div
        className={` ${
          isMobile ? "p-3 pe-4 fixed-bottom" : "fixed-bottom rounded-bottom p-2"
        } m-0 w-100 d-flex justify-content-end bg-light-gray`}
      >
        <BillOfLadingDownloadButton overlayPlacement="top" orderId={orderId} billOfLadingId={currentBillOfLading?.id}>
          <Button className="me-2" variant="primary" disabled={downloadDisabled} id="bol-download">
            {downloadDisabled ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                &nbsp; Wait...
              </>
            ) : (
              "Download"
            )}
          </Button>
        </BillOfLadingDownloadButton>
        <Button onClick={() => history.push(orderUrl(orderId))} variant="success" id="bol-confirm">
          Done
        </Button>
      </div>
      <AddOrderModal show={showAddOrderModal} onCancel={hideAddOrderModal} currentBillOfLading={currentBillOfLading} />
    </>
  );
};

BillOfLadingForm.propTypes = {
  user: PropTypes.shape.isRequired,
  orderId: PropTypes.number.isRequired,
  deliveryId: PropTypes.number.isRequired,
  currentBillOfLading: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
  canEditAllParams: PropTypes.bool.isRequired,
};

BillOfLadingForm.defaultProps = {
  currentBillOfLading: null,
};

export default BillOfLadingForm;

const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-width: 2px;
  border-radius: 4px;
  border-color: #049dff;
  color: #049dff;
  border-style: dashed;
  margin-top: 0.5rem;
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  border-color: #049dff;
  color: #049dff;
`;

const StyledRow = styled(Row)`
  -webkit-box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15);
  position: relative;
`;

const ShippingInfoCol = styled(Col)`
  background-color: #252629;
  padding: 1.5rem !important;
  padding-bottom: ${(props) => (props.$isMobile ? "5rem !important" : "1.5rem !important")};
  color: white;
  overflow-y: auto;
  height: calc(100vh - 16.5rem);
`;

const DeliveryItemsCol = styled(Col)`
  background-color: white;
  padding: 1.5rem !important;
  overflow-y: auto;
  height: calc(100vh - 16.5rem);
`;

const PdfRenderCol = styled(Col)`
  overflow-y: auto;
  height: calc(100vh - 16.5rem);
`;

const StyledAllWeight = styled.div`
  padding-right: 28px;
`;

const Line = styled.div`
  background-color: white;
  height: 2px;
  width: 70%;
`;
