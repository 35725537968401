import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Form from "@ROM-ui/Form";
import AsyncPaginate from "@ROM-components/common/AsyncPaginate";
import Modal from "@ROM-components/common/Modal";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { fetch as fetchEmailGroups } from "@ROM/EmailGroups/actions";
import { selectEmailGroups, selectEmailGroupUsers } from "@ROM/EmailGroups/selectors";
import BillOfLadingsApi from "@ROM/BillOfLadings/api";
import { fetchUsers as loadUsers } from "@ROM/Customers/utils";
import EmailGroupLinks from "./EmailGroupLinks";

const EmailModal = ({ orderId, billOfLadingId, onCancel }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const emailGroups = useSelector(selectEmailGroups);
  const emailGroupUsers = useSelector(selectEmailGroupUsers);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [withDollarAmount, setWithDollarAmount] = useState(true);

  const [status, setStatus] = useState("initial");

  useEffect(() => {
    dispatch(fetchEmailGroups());
  }, []);

  const handleSelectUser = (values) => {
    setSelectedUsers(values || []);
  };

  const handleSend = async () => {
    const response = await BillOfLadingsApi.emailPdf({
      orderId,
      billOfLadingId,
      userIds: selectedUsers.map((option) => option.value),
      senderId: currentUser.id,
      withDollarAmount,
    });
    if (response.status === 204) {
      setStatus("succeeded");
    } else {
      setStatus("failed");
    }
  };

  const sendButtonFunc = status === "initial" ? handleSend : onCancel;
  const sendButtonText = status === "initial" ? "Send" : "Close";

  const filteredGroups = emailGroups.filter((group) => group.relationships.users.data.length > 0);

  const preselectUsers = (userIds) => {
    const selectedIds = selectedUsers.map((option) => option.value);
    const groupOptions = emailGroupUsers
      .filter((user) => userIds.includes(user.id) && !selectedIds.includes(user.id))
      .map((user) => ({ value: user.id, label: user.attributes.fullName }));

    setSelectedUsers([...selectedUsers, ...groupOptions]);
  };

  return (
    <Modal
      header="Email Bill Of Lading"
      fullscreen="md-down"
      size="lg"
      isShowing
      confirmText={sendButtonText}
      onConfirm={sendButtonFunc}
      confirmEnabled={status !== "initial" || selectedUsers.length > 0}
      onCancel={onCancel}
    >
      {status === "initial" && (
        <>
          <p>Select the recipients to send the bill of lading to.</p>
          <AsyncPaginate
            isMulti
            id="users-select"
            placeholder="Select eMail recipients"
            loadOptions={loadUsers}
            value={selectedUsers}
            onChange={handleSelectUser}
            options={[]}
            additional={{ page: 1 }}
          />

          {filteredGroups.length > 0 && (
            <EmailGroupLinks groups={filteredGroups} users={emailGroupUsers} onClick={preselectUsers} />
          )}

          <Form.Check
            checked={withDollarAmount}
            label="Include dollar amount"
            className="mt-3"
            onChange={() => setWithDollarAmount(!withDollarAmount)}
          />
        </>
      )}

      {status === "succeeded" && <p>The bill of lading PDF was queued to be sent by email</p>}

      {status === "failed" && <p>Sorry, there was an error. Try again later.</p>}
    </Modal>
  );
};

EmailModal.propTypes = {
  orderId: PropTypes.string.isRequired,
  billOfLadingId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EmailModal;
