import { standardAction, apiAction } from "@ROM-utils/actions";
import CarriersApi from "./api";

export const LIST = "carriers/api/LIST";
export const SET_PAGE = "carriers/api/SET_PAGE";

export function list(options = {}) {
  return apiAction(LIST, CarriersApi.list(options));
}

export function setPage(page) {
  return standardAction(SET_PAGE, { page });
}

export function getFilteredCarriers(filters, page) {
  return list({
    ...filters,
    page,
  });
}
