import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "@ROM-components/common/Modal";
import PriceListForm from "../Form/PriceListForm";
import { create } from "@ROM/PriceLists/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reducer } from "@ROM/App/utils/forms";

const CreateModal = ({ show, onCancel, create }) => {
  const initialPriceList = {
    name: "",
    dateFrom: null,
    dateTo: null,
    status: "",
    isDefaultPriceList: false,
  };

  useEffect(() => {
    if (!show) setPriceList(initialPriceList);
  }, [show]);

  const [priceList, setPriceList] = useReducer(reducer, initialPriceList);

  const handleUserInput = (name, value) => setPriceList({ [name]: value });

  const handleCreatePriceList = async () => {
    const response = await create(priceList);
    if (response.payload.status === 201 || response.payload.status === 200) {
      onCancel();
    }
  };
  return (
    <Modal isShowing={show} confirmText="Create" onConfirm={handleCreatePriceList} onCancel={onCancel} header="New Price List">
      <PriceListForm priceList={priceList} handleUserInput={handleUserInput} />
    </Modal>
  );
};

CreateModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  create: PropTypes.func,
};

const dispatchToProps = (dispatch) => {
  return {
    create: bindActionCreators(create, dispatch),
  };
};

export default connect(null, dispatchToProps)(CreateModal);
