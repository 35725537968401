import React, { useEffect, useReducer } from "react";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Select from "@ROM-components/common/Select";
import { bindActionCreators } from "redux";
import { list } from "@ROM/Warehouses/actions";
import { update } from "@ROM/Deliveries/actions";
import { connect } from "react-redux";
import { selectAllWarehouses } from "@ROM/Warehouses/selectors";
import { reducer } from "@ROM/App/utils/forms";
import PropTypes from "prop-types";

const DeliveryForm = ({ getWarehouses, warehouses, order, currentDelivery, updateDelivery, showNotesInputs, children }) => {
  const regionId = order?.relationships?.region?.data?.id;
  const options = warehouses.map((warehouse) => ({ value: warehouse.id, label: warehouse.attributes.name }));
  const defaultWarehouseId = warehouses.find((warehouse) => warehouse?.attributes?.isDefault)?.id;

  const initialDelivery = {
    orderId: order.id,
    deliverDate: currentDelivery?.attributes?.deliverDate ?? new Date(),
    warehouseId: currentDelivery?.attributes?.warehouseId ?? null,
    notes: currentDelivery?.attributes?.notes ?? undefined,
  };

  const [delivery, setDelivery] = useReducer(reducer, initialDelivery);

  useEffect(() => {
    getWarehouses({ regionId });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => updateDelivery(currentDelivery.id, delivery), 500);
    return () => clearTimeout(timeOutId);
  }, [delivery]);

  useEffect(() => {
    if (!delivery.warehouseId) {
      setDelivery({ warehouseId: defaultWarehouseId });
    }
  }, [defaultWarehouseId]);

  const handleWarehouseChange = (id) => {
    setDelivery({ warehouseId: id });
  };

  return (
    <>
      {((showNotesInputs && showNotesInputs[currentDelivery.id]) || delivery.notes) && (
        <>
          <label className="mt-2">Notes</label>
          <textarea
            className="form-control w-100"
            value={delivery.notes}
            onChange={(e) => setDelivery({ notes: e.target.value })}
          />
        </>
      )}
      {children}
      <Row>
        <Col>
          <label>Warehouse</label>
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            id="location-select"
            placeholder="Select Location"
            options={options}
            onChange={(e) => handleWarehouseChange(e.value)}
            value={options.find((option) => option.value == delivery.warehouseId)}
          />
        </Col>
      </Row>
    </>
  );
};

const dispatchToProps = (dispatch) => {
  return {
    getWarehouses: bindActionCreators(list, dispatch),
    updateDelivery: bindActionCreators(update, dispatch),
  };
};

const stateToProps = (state) => {
  return {
    warehouses: selectAllWarehouses(state),
  };
};

DeliveryForm.propTypes = {
  getWarehouses: PropTypes.func,
  warehouses: PropTypes.array,
  order: PropTypes.object,
  currentDelivery: PropTypes.object,
  updateDelivery: PropTypes.func,
  showNotesInputs: PropTypes.array,
  children: PropTypes.node,
};

export default connect(stateToProps, dispatchToProps)(DeliveryForm);
