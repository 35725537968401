/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Badge from "@ROM-ui/Badge";

const ActiveBadge = ({ isActive, label, ...rest }) => {
  const text = label || (isActive ? "Active" : "Inactive");

  return (
    <StyledBadge bg={isActive ? "success" : "secondary"} {...rest}>
      {text}
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge)`
  text-transform: uppercase;
  font-size: 0.7em;
  padding: 0.3rem 0.5rem;
  line-height: 1.25;
`;

ActiveBadge.defaultProps = {
  label: null,
};

ActiveBadge.propTypes = {
  label: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
};

export default ActiveBadge;
