import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Tabs = ({ children }) => {
  return <StyledTabs>{children}</StyledTabs>;
};

const TabItem = ({ value, name, iconClasses, activeName, setActiveName, ...props }) => {
  const isActive = activeName === name;

  const handleTabClick = () => {
    setActiveName(name);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledItem onClick={handleTabClick} isActive={isActive} id={name} {...props}>
      <div className="me-1">
        <i className={iconClasses} />
      </div>
      <strong>{value}</strong>
    </StyledItem>
  );
};

const StyledItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0.5rem 1rem;
  align-items: center;
  background-color: ${(props) => (props.isActive ? "#EBF7FF" : "#fafafa")};
  border: ${(props) => (props.isActive ? "1px solid #70B6EB" : "1px solid #e5e3e3")};

  &:hover {
    background-color: ${(props) => (props.isActive ? "" : "#f5f5f5")};
  }

  :first-child {
    border-top-left-radius: 5px;
  }

  :last-child {
    border-top-right-radius: 5px;
  }
`;

const StyledTabs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

TabItem.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  iconClasses: PropTypes.string.isRequired,
  activeName: PropTypes.string.isRequired,
  setActiveName: PropTypes.string.isRequired,
};

export { Tabs, TabItem };
