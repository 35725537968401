import update from "immutability-helper";
import { handle } from "redux-pack";
import { DESTROY_BILL_OF_LADING_ITEM } from "@ROM/BillOfLadings/BillOfLadingItems/actions";
import { UPDATE as DELIVERY_UPDATE, UPDATE_SHIPPING } from "@ROM/Deliveries/actions";
import { LIST, DESTROY, FIND, UPDATE, CREATE } from "./actions";

const initialState = {
  loading: false,
  all: {
    items: [],
    included: [],
  },
  current: {
    billOfLading: null,
    included: [],
  },
  pagination: {
    currentPage: 1,
    perPage: null,
    totalPages: null,
    totalRecords: null,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};
  switch (type) {
    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            loading: { $set: true },
          });
        },

        finish: (prevState) => {
          return update(prevState, {
            loading: { $set: false },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              included: { $set: json.included },
            },
            pagination: { $set: json.meta.pagination },
          });
        },
      });

    case FIND:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            current: { billOfLading: { $set: json.data }, included: { $set: json.included } },
          });
        },
      });

    case UPDATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            current: { billOfLading: { $set: json.data }, included: { $set: json.included } },
          });
        },
      });

    case CREATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            current: { billOfLading: { $set: json.data }, included: { $set: json.included } },
            all: { items: { $push: [json.data] }, included: { $push: json.included } },
          });
        },
      });

    case DESTROY:
      return handle(state, action, {
        success: (prevState) => {
          const billOfLadingId = action.meta.billOfLadingId;
          const billOfLadings = { ...prevState.all };
          billOfLadings.items = billOfLadings.items.filter((billOfLading) => billOfLading.id !== billOfLadingId);
          billOfLadings.included = billOfLadings.included.filter(
            (included) => included.attributes.billOfLadingId.toString() !== billOfLadingId
          );
          return update(prevState, {
            all: { $set: billOfLadings },
          });
        },
      });

    case DESTROY_BILL_OF_LADING_ITEM:
      return handle(state, action, {
        success: (prevState) => {
          const billOfLadingItemId = action.meta.billOfLadingItemId;
          const allIndex = prevState.all.included.findIndex(
            (item) => item.type === "billOfLadingItem" && item.id === billOfLadingItemId
          );

          return update(prevState, {
            all: { included: { $splice: [[allIndex, 1]] } },
          });
        },
      });

    case DELIVERY_UPDATE:
    case UPDATE_SHIPPING:
      return handle(state, action, {
        success: (prevState) => {
          const {
            data: { attributes: itemAttributes, relationships: itemRelationships, id: itemId, type: itemType },
          } = json;
          const included = prevState.current.included.map((item) =>
            item.id === itemId && item.type === itemType
              ? { ...item, attributes: itemAttributes, relationships: itemRelationships }
              : item
          );
          return update(prevState, { current: { included: { $set: included } } });
        },
      });
    default:
      return state;
  }
}

export default reducer;
