import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/invitations";
  },
  resource(id) {
    return `/invitations/${id}`;
  },
};

export default {
  create(attributes) {
    const client = getApiClient(API_URL);
    return client.post(`${urls.collection()}`, attributes);
  },
  list(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(), {
      params: {
        ...options,
      },
    });
  },
  destroy(id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(id));
  },
  resend(attributes) {
    const client = getApiClient(API_URL);
    return client.post(`${urls.collection()}/resend`, attributes);
  },
};
