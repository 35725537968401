import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Form from "@ROM-ui/Form";

import FormattedAmount from "./FormattedAmount";

const StyledTd = styled.td`
  vertical-align: middle !important;
  background-color: #f5f5f5 !important;
  height: 50px;
`;

const ListRow = ({ warehouseInventory, onPalletsUpdate, onUnitsUpdate, editing }) => {
  return (
    <tr className="text-nowrap fw-bold">
      <StyledTd className="rounded-start ps-3">{warehouseInventory.product.name}</StyledTd>
      <StyledTd>{warehouseInventory.product.packageUnit}</StyledTd>
      <StyledTd className="text-end">
        {editing ? (
          <Form.Control
            type="text"
            id={`pallets-inventory-product-${warehouseInventory.productId}`}
            value={warehouseInventory.pallets}
            onChange={(e) => onPalletsUpdate(e.target.value)}
          />
        ) : (
          <FormattedAmount>{warehouseInventory.pallets}</FormattedAmount>
        )}
      </StyledTd>
      <StyledTd className="text-end">
        {editing ? (
          <Form.Control
            type="text"
            id={`units-inventory-product-${warehouseInventory.productId}`}
            value={warehouseInventory.units}
            onChange={(e) => onUnitsUpdate(e.target.value)}
          />
        ) : (
          <FormattedAmount>{warehouseInventory.units}</FormattedAmount>
        )}
      </StyledTd>
      <StyledTd className="text-end">
        <FormattedAmount colored>{warehouseInventory.palletsLeft}</FormattedAmount>
      </StyledTd>
      <StyledTd className="text-end rounded-end pe-3">
        <FormattedAmount colored>{warehouseInventory.unitsLeft}</FormattedAmount>
      </StyledTd>
    </tr>
  );
};

ListRow.propTypes = {
  warehouseInventory: PropTypes.shape().isRequired,
  editing: PropTypes.bool.isRequired,
  onPalletsUpdate: PropTypes.func.isRequired,
  onUnitsUpdate: PropTypes.func.isRequired,
};

export default ListRow;
