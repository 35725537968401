import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { createCategory } from "@ROM/Categories/actions";
import Form from "@ROM-ui/Form";
import Button from "@ROM-ui/Button";

const AddNewCategory = () => {
  const dispatch = useDispatch();

  const [categoryName, setCategoryName] = useState("");
  const [error, setError] = useState(null);

  const addNewCategoryHandler = () => {
    if (categoryName.trim() === "") {
      setError("Name can't be empty");
    } else {
      setError(null);
      dispatch(createCategory({ name: categoryName }));
    }
  };

  return (
    <Container>
      <div className="d-flex mt-2">
        <Form.Control
          type="text"
          name="category-name"
          className="me-2"
          placeholder="Category name"
          onChange={(event) => setCategoryName(event.target.value)}
          value={categoryName}
        />
        <Button onClick={addNewCategoryHandler} className="text-nowrap">
          New Category
        </Button>
      </div>
      {error && <div className="error text-danger mt-1">{error}</div>}
    </Container>
  );
};

const Container = styled.div`
  .new-category {
    display: inline-flex;
    align-items: center;
    padding: 0 0.125rem;
    font-size: 1.25rem;
    cursor: pointer;
  }

  .error {
    font-size: 0.75rem;
  }
`;

export default AddNewCategory;
