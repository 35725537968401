/*
  JSON API Error payload example:

  [
    {
      "status":"422",
      "detail":"Email can't be blank",
      "source":{"pointer":"email"},
    },
    {
      "status":"422",
      "detail":"Password can't be blank",
      "source":{"pointer":"password"},
    }
  ]

*/

export function getError(key, jsonApiErrors = []) {
  const found = jsonApiErrors.find((errorJson) => errorJson.source.pointer === key);
  return found ? found.detail : null;
}

export const reducer = (state, values) => ({ ...state, ...values });
