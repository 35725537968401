import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import StyledContainer from "@ROM-ui/StyledContainer";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import ShadowContainer from "@ROM-ui/ShadowContainer";
import Section from "@ROM-components/common/Section";
import Logo from "@ROM-components/common/Logo";
import Message from "@ROM-common/Message";
import InvitationsTable from "@ROM/Auth/Invitations/components/InvitationsTable";
import customersApi from "@ROM/Customers/api";
import { connect } from "react-redux";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { canCreateInvitations } from "@ROM/Invitations/permissions";

const Invitations = ({ customerId, currentUser, currentCompany }) => {
  const [customer, setCustomer] = useState("");

  useEffect(() => {
    customersApi.find(customerId).then((response) => {
      setCustomer(response.data.data);
    });
  }, []);

  return (
    <Section>
      {canCreateInvitations(currentUser, customerId) ? (
        <>
          <StyledContainer>
            <StyledRow className="justify-content-center align-items-center">
              <Logo currentCompany={currentCompany} height="150" />
            </StyledRow>
            <Row className="justify-content-center align-items-center mt-4">
              <Col className="text-center">
                <h4>{`Hello ${currentUser.attributes.fullName}`}</h4>
                <p className="py-4">Do you want to invite some more users to your account?</p>
              </Col>
            </Row>
            <ShadowContainer>
              <InvitationsTable customer={customer} />
            </ShadowContainer>
          </StyledContainer>
          <BackgroundCover />
        </>
      ) : (
        <Message
          message="You don't have permissions to acces to this page. If you think this is an error please contact the adminstrator"
          type="danger"
        />
      )}
    </Section>
  );
};

Invitations.propTypes = {
  customerId: PropTypes.string.isRequired,
  currentUser: PropTypes.shape.isRequired,
  currentCompany: PropTypes.shape.isRequired,
};

const stateToProps = (state) => {
  return {
    currentUser: selectCurrentUser(state),
    currentCompany: selectCurrentCompany(state),
  };
};

export default connect(stateToProps)(Invitations);

const StyledRow = styled(Row)`
  margin-top: -4rem !important;
`;

const BackgroundCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background-color: white;
  z-index: -1;
`;
