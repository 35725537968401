/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import PropTypes from "prop-types";

import Form from "@ROM-ui/Form";
import useIsMobile from "@ROM-components/utils/Responsive";
import Spinner from "@ROM-ui/Spinner";

const FileUploader = ({
  afterAssetUpload,
  deleteAsset,
  editAssetName,
  currentAssets,
  disableSubmitButton,
  error,
  makePrimary,
  primaryAssetId,
  textUpload,
  textDescription,
  secondary,
}) => {
  const [files, setFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [removingFiles, setRemovingFiles] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [assetNewName, setAssetNewName] = useState("");

  const filesRef = useRef();
  const isMobile = useIsMobile();

  const removeAsset = (asset) => {
    setRemovingFiles(true);

    if (disableSubmitButton) {
      disableSubmitButton(true);
    }

    setIdToDelete(asset.id);
    deleteAsset(asset, setRemovingFiles, disableSubmitButton);

    URL.revokeObjectURL(asset.id);
  };

  const editAssetNameClick = (assetId, assetName) => {
    setAssetNewName(assetName);
    setIdToEdit(assetId);
  };

  const editAssetNameHandler = (assetId) => {
    editAssetName(assetId, assetNewName);
    setIdToEdit(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*", "application/pdf"],
    multiple: true,
    onDrop: (acceptedFiles) => {
      setUploadingFiles(true);
      setFiles([
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    onDropAccepted: (files) => {
      const assets = new Array();
      const totalFiles = files.length;

      files.forEach((file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64File = reader.result.split(",")[1];

          const fileToUpload = {
            id: file.preview,
            name: file.name,
            data: base64File,
            type: file.type,
            isPrimary: false,
            preview: file.preview,
          };

          assets.push(fileToUpload);

          if (disableSubmitButton) {
            disableSubmitButton(true);
          }
          if (index === totalFiles - 1) {
            afterAssetUpload(assets, setUploadingFiles, disableSubmitButton);
          }
        };
      });
    },
  });

  const thumbs = currentAssets.map((asset, index) => {
    const isPdf = asset.attributes?.file?.url?.search(".pdf") > 0 || asset.type?.search("pdf") > 0;
    const assetUrl = asset.attributes?.file?.url || asset.preview;
    const thumbUrl = asset.attributes?.file?.thumb?.url || asset.preview;
    const assetName = asset.attributes?.name.split("?")[0];

    return secondary ? (
      <div className={`position-relative ${isMobile ? "w-100" : ""}`} key={asset.id}>
        <Box
          data-type={isPdf ? "iframe" : "image"}
          href={assetUrl}
          data-fancybox={`galery-${assetName}`}
          data-caption={assetName}
          $isMobile={isMobile}
        >
          {isPdf && (
            <div className="text-center">
              <ThumbPdf className="fas fa-file-pdf mb-2" />
              <div>{assetName}</div>
            </div>
          )}
        </Box>
        <TrashContainer>
          {removingFiles && idToDelete === asset.id ? (
            <Spinner animation="border" variant="secondary" size="sm" />
          ) : (
            <i onClick={() => removeAsset(asset)} className="fas fa-trash text-danger cursor-pointer" />
          )}
        </TrashContainer>
      </div>
    ) : (
      <React.Fragment key={asset.id}>
        <ThumbItem includeBorder={currentAssets.length !== index + 1}>
          <div className="d-flex align-items-center w-75">
            <a
              key={asset.id}
              data-type={isPdf ? "iframe" : "image"}
              href={assetUrl}
              data-fancybox={`galery-${assetName}`}
              data-caption={assetName}
            >
              {isPdf ? <ThumbPdf className="fas fa-file-pdf" /> : <ThumbImage src={thumbUrl} />}
            </a>
            {idToEdit === asset.id ? (
              <Form.Control
                type="text"
                value={assetNewName}
                onChange={(e) => setAssetNewName(e.target.value)}
                className="ms-3 w-100"
              />
            ) : (
              <ProductName className="ms-3">{assetName}</ProductName>
            )}
            {makePrimary && (
              <div>
                <span className="mx-3 d-none d-sm-inline">-</span>
                <span className="ms-3 ms-sm-0">Is Primary</span>
                <input
                  type="checkbox"
                  className="ms-2"
                  checked={asset.id == primaryAssetId}
                  onChange={() => {
                    makePrimary(asset.id);
                  }}
                />
              </div>
            )}
          </div>
          <div className="text-nowrap ms-4">
            {editAssetName &&
              (idToEdit === asset.id ? (
                <i onClick={() => editAssetNameHandler(asset.id)} className="fas fa-check text-success cursor-pointer me-2" />
              ) : (
                <i
                  onClick={() => editAssetNameClick(asset.id, assetName)}
                  className="fas fa-edit text-warning cursor-pointer me-2"
                />
              ))}
            {removingFiles && idToDelete === asset.id ? (
              <Spinner animation="border" variant="secondary" size="sm" />
            ) : (
              <i onClick={() => removeAsset(asset)} className="fas fa-trash text-danger cursor-pointer" />
            )}
          </div>
        </ThumbItem>
      </React.Fragment>
    );
  });

  useEffect(() => {
    filesRef.current = files;
  }, [files]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      filesRef.current.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    []
  );

  return secondary ? (
    <>
      <BoxesContainer>
        {thumbs}
        <Box $isMobile={isMobile} {...getRootProps()}>
          <input type="file" name="files[]" multiple {...getInputProps()} />
          <div className="text-center">
            <i className="fas fa-camera mb-2" />
            <div>{uploadingFiles ? "Uploading..." : textUpload}</div>
          </div>
        </Box>
      </BoxesContainer>
      {error && <p className="text-danger">{error}</p>}
    </>
  ) : (
    <>
      {thumbs.length > 0 && <ThumbContainer>{thumbs}</ThumbContainer>}
      {error && <ErrorContainer>{error}</ErrorContainer>}
      <DropZoneContainer>
        <DropZone uploadingFiles={uploadingFiles} removingFiles={removingFiles} {...getRootProps()}>
          <input type="file" name="files[]" multiple {...getInputProps()} />

          {uploadingFiles ? (
            <div className="d-flex align-items-center">
              <DropZoneSpinner animation="border" size="sm" />
              Uploading...
            </div>
          ) : (
            <div>
              <i className="fas fa-cloud-upload-alt me-2" />
              {textUpload}
            </div>
          )}
        </DropZone>
        {textDescription}
      </DropZoneContainer>
    </>
  );
};

FileUploader.defaultProps = {
  editAssetName: null,
  currentAssets: [],
  disableSubmitButton: null,
  error: null,
  makePrimary: null,
  primaryAssetId: null,
  textUpload: "Upload file",
  textDescription: "Select images or PDFs, or drop them here",
  secondary: false,
};

FileUploader.propTypes = {
  afterAssetUpload: PropTypes.func.isRequired,
  deleteAsset: PropTypes.func.isRequired,
  editAssetName: PropTypes.func,
  currentAssets: PropTypes.arrayOf(PropTypes.shape()),
  disableSubmitButton: PropTypes.func,
  error: PropTypes.string,
  makePrimary: PropTypes.func,
  primaryAssetId: PropTypes.string,
  textUpload: PropTypes.string,
  textDescription: PropTypes.string,
  secondary: PropTypes.bool,
};

export default FileUploader;

const DropZoneContainer = styled.div`
  text-align: center;
  color: #00aef7;
  width: 100%;
`;

const DropZoneSpinner = styled(Spinner)`
  width: 1.25rem;
  height: 1.25rem;
  color: #00aef7;
  margin-right: 0.5rem;
`;

const DropZone = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-width: 2px;
  border-radius: 2px;
  border-color: #55beff;
  border-style: dashed;
  background-color: #e0f5fe;
  color: #00aef7;
  margin-bottom: 0.75rem;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  pointer-events: ${(props) => (props.uploadingFiles || props.removingFiles ? "none" : "")};
`;

const TrashContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-5px, -10px);
`;

const BoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem 1rem;
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 8%);
  padding: 1rem;
  color: #000;
  font-size: 0.75rem;
  transition: all 0.5s;

  width: ${(props) => (props.$isMobile ? "100%" : "165px")};
  height: ${(props) => (props.$isMobile ? "200px" : "150px")};

  background-image: ${(props) => `url(${props.href})`};
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;

  i {
    color: #036db2;
    font-size: 2.25rem;
  }

  &:hover {
    background-color: ${(props) => (props.href ? "white" : "#e5e7eb")};
  }

  &:focus {
    outline: none;
  }
`;

const ThumbContainer = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`;

const ThumbItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: ${(props) => (props.includeBorder ? "1px solid #cbd5e0" : null)};
`;

const ThumbPdf = styled.i`
  font-size: 3.5rem;
  color: #cbd5e0;
`;

const ThumbImage = styled.img`
  width: auto;
  height: 50px;
`;

const ProductName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
`;

const ErrorContainer = styled.div`
  background-color: #fecaca;
  color: #b91c1c;
  border-radius: 5px;
  padding: 0.75rem 1rem;
  margin-bottom: 0.75rem;
`;
