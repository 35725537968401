import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "@ROM-ui/Button";
import Modal from "@ROM-components/common/Modal";

import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import { destroy } from "@ROM/Customers/actions";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canDeleteCustomers } from "@ROM/Customers/permissions";
import { companyAdminCustomersUrl } from "@ROM-utils/urlHelpers";

const DeleteCustomer = () => {
  const currentUser = useSelector(selectCurrentUser);
  const [showDestroyConfirmationModal, setShowDestroyConfirmationModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const customer = useContext(CustomerContext);
  const customerId = customer?.id || "";
  const onDeleteClick = async () => {
    await dispatch(destroy(customerId));
    history.push(companyAdminCustomersUrl());
  };

  if (!canDeleteCustomers(currentUser, customerId)) {
    return null;
  }

  return (
    <>
      <h4 className="mt-5">Delete Customer</h4>
      <Button
        onClick={() => setShowDestroyConfirmationModal(true)}
        id={`delete-customer-${customerId}`}
        variant="outline-danger"
        className="w-100 d-flex align-items-center justify-content-between"
      >
        <i className="fas fa-delete" />
        Delete Customer
      </Button>

      <Modal
        isShowing={showDestroyConfirmationModal}
        onConfirm={onDeleteClick}
        onCancel={() => setShowDestroyConfirmationModal(false)}
        header="Are you sure you want to delete this customer?"
        showCloseIcon={false}
      />
    </>
  );
};

export default DeleteCustomer;
