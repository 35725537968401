import { apiAction } from "@ROM-utils/actions";
import CompanyRolesApi from "./api";

export const FETCH_COMPANY_ROLES = "companyRoles/api/FETCH_COMPANY_ROLES";
export const CREATE_COMPANY_ROLE = "companyRoles/api/CREATE_COMPANY_ROLE";
export const UPDATE_COMPANY_ROLE = "companyRoles/api/UPDATE_COMPANY_ROLE";
export const DESTROY_COMPANY_ROLE = "companyRoles/api/DESTROY_COMPANY_ROLE";
export const ADD_COMPANY_ROLE_NOTIFICATION = "companyRoles/api/ADD_COMPANY_ROLE_NOTIFICATION";
export const REMOVE_COMPANY_ROLE_NOTIFICATION = "companyRoles/api/REMOVE_COMPANY_ROLE_NOTIFICATION";
export const UPDATE_COMPANY_ROLE_NOTIFICATION = "companyRoles/api/UPDATE_COMPANY_ROLE_NOTIFICATION";
export const ADD_COMPANY_ROLE_PERMISSION = "companyRoles/api/ADD_COMPANY_ROLE_PERMISSION";
export const UPDATE_COMPANY_ROLE_PERMISSION = "companyRoles/api/UPDATE_COMPANY_ROLE_PERMISSION";
export const REMOVE_COMPANY_ROLE_PERMISSION = "companyRoles/api/REMOVE_COMPANY_ROLE_PERMISSION";

export function fetchCompanyRoles() {
  return apiAction(FETCH_COMPANY_ROLES, CompanyRolesApi.fetchCompanyRoles());
}

export function createCompanyRole(params) {
  return apiAction(CREATE_COMPANY_ROLE, CompanyRolesApi.createCompanyRole(params));
}

export function updateCompanyRole(id, params) {
  return apiAction(UPDATE_COMPANY_ROLE, CompanyRolesApi.updateCompanyRole(id, params));
}

export function destroyCompanyRole(id) {
  return apiAction(DESTROY_COMPANY_ROLE, CompanyRolesApi.destroyCompanyRole(id), { companyRoleId: id });
}

export function addCompanyRoleNotification(id, params) {
  return apiAction(ADD_COMPANY_ROLE_NOTIFICATION, CompanyRolesApi.addCompanyRoleNotification(id, params));
}

export function updateCompanyRoleNotification(id, params) {
  return apiAction(REMOVE_COMPANY_ROLE_NOTIFICATION, CompanyRolesApi.updateCompanyRoleNotification(id, params));
}

export function removeCompanyRoleNotification(id, params) {
  return apiAction(UPDATE_COMPANY_ROLE_NOTIFICATION, CompanyRolesApi.removeCompanyRoleNotification(id, params));
}

export function addCompanyRolePermission(id, params) {
  return apiAction(ADD_COMPANY_ROLE_PERMISSION, CompanyRolesApi.addCompanyRolePermission(id, params));
}

export function updateCompanyRolePermission(id, params) {
  return apiAction(UPDATE_COMPANY_ROLE_PERMISSION, CompanyRolesApi.updateCompanyRolePermission(id, params));
}

export function removeCompanyRolePermission(id, params) {
  return apiAction(REMOVE_COMPANY_ROLE_PERMISSION, CompanyRolesApi.removeCompanyRolePermission(id, params));
}
