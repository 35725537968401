/* Get sizing classes used specifically for Modals */

export function classesForSize(size) {
  switch (size) {
    case "standard":
      return "";

    case "small":
      return "w-25 mw-100";

    case "large":
      return "w-75 mw-100";

    case "x-large":
      return "w-80 mw-100";

    case "x-large-y-large":
      return "w-80 mw-100 h-75";

    case "xx-large":
      return "w-95 mw-95";

    default:
      return "";
  }
}
