import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectCurrentProductInventories } from "@ROM/Products/selectors";
import { ActiveBadge } from "@ROM-ui/Badge";
import ProductUnitsLabel from "@ROM/CompanyAdmin/Products/components/ProductUnitsLabel";
import productPlaceholder from "@ROM-images/productPlaceholder";
import Package from "@ROM-images/package";
import Pallet from "@ROM-images/pallet";
import Inventory from "@ROM-images/inventory";
import Modal from "@ROM-components/common/Modal";
import { productType } from "@ROM/Products/types";

const ProductDetails = ({
  product: productObject,
  canEditProduct,
  canDeleteProduct,
  onEditProduct,
  onDeleteProduct,
  className,
}) => {
  const [showDestroyConfirmationModal, setShowDestroyConfirmationModal] = useState(false);
  const inventories = useSelector(selectCurrentProductInventories);
  const productId = productObject.id;
  const product = productObject.attributes || productObject;

  return (
    <>
      <div className={`py-4 ${className}`}>
        <div className="d-md-flex mb-4">
          <div className="d-flex justify-content-center align-items-center p-4 border me-0 me-md-4 bg-light mb-4 mb-md-0">
            <img src={product.primaryImage?.file?.url || productPlaceholder} alt="productImage" width="100px" />
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex align-items-center">
              <h3 className="mb-0">{product.name}</h3>
              <div className={canEditProduct || canDeleteProduct ? "ms-5" : ""}>
                {canEditProduct && onEditProduct ? (
                  <i
                    role="button"
                    aria-label="Edit product"
                    tabIndex={0}
                    className="fas fa-edit me-3 cursor-pointer text-warning"
                    onClick={() => onEditProduct(productId)}
                    onKeyDown={() => onEditProduct(productId)}
                  />
                ) : null}
                {canDeleteProduct && onDeleteProduct ? (
                  <i
                    role="button"
                    aria-label="Destroy product"
                    tabIndex={0}
                    className="fas fa-trash cursor-pointer text-danger"
                    onClick={() => setShowDestroyConfirmationModal(true)}
                    onKeyDown={() => setShowDestroyConfirmationModal(true)}
                  />
                ) : null}
              </div>
            </div>
            <p className="mb-0 pt-3 pb-4 text-justify" style={{ maxWidth: "800px" }}>
              {product.description}
            </p>
            <div>
              <ActiveBadge isActive={product.isActive} />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center gap-3 pt-3 mb-3 mb-md-4">
          <ProductUnitsLabel text={`Units per\npackage`} unit={product.unitsPerPackage} icon={Package} />
          <ProductUnitsLabel text={`Package per\npallet`} unit={product.packagesPerPallet} icon={Pallet} />
          <ProductUnitsLabel text={`Package\nweight`} unit={`${product.packageWeight} lbs`} />
          {product.palletWeightOverride !== null && (
            <ProductUnitsLabel text={`Initial Pallet\nweight`} unit={`${product.palletWeightOverride} lbs`} />
          )}
        </div>
        <div className="d-flex flex-column flex-md-row flex-wrap align-items-md-center gap-3">
          {inventories.map((inventory) => (
            <ProductUnitsLabel
              text={`Warehouse\n${inventory.attributes.sageWarehouseId}`}
              unit={Number(inventory.attributes.quantityLeft)}
              icon={Inventory}
            />
          ))}
        </div>
      </div>
      {canDeleteProduct && onDeleteProduct && (
        <Modal
          isShowing={showDestroyConfirmationModal}
          onConfirm={() => onDeleteProduct(productId)}
          onCancel={() => setShowDestroyConfirmationModal(false)}
          header="Are you sure you want to delete this product?"
          showCloseIcon={false}
        />
      )}
    </>
  );
};

ProductDetails.defaultProps = {
  className: "",
  onDeleteProduct: null,
  onEditProduct: null,
  canEditProduct: false,
  canDeleteProduct: false,
};

ProductDetails.propTypes = {
  className: PropTypes.string,
  product: productType.isRequired,
  canEditProduct: PropTypes.bool,
  canDeleteProduct: PropTypes.bool,
  onDeleteProduct: PropTypes.func,
  onEditProduct: PropTypes.func,
};

export default ProductDetails;
