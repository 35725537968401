function standardAction(type, payload = {}, meta = {}) {
  const isError = (obj) => obj instanceof Error
  const action = {type, payload, meta}

  return isError(payload) ? {...action, error: true } : action
}

function apiAction(type, promise, meta = {}) {
  return {
    type,
    promise,
    meta,
  }
}

export { standardAction, apiAction }