import React from "react";
import PropTypes from "prop-types";

import AdminTable from "@ROM-components/common/AdminTable";
import Modal from "@ROM-components/common/Modal";

const CompanyRoleUsersModal = ({ isShowing, onDismiss, users }) => {
  const header = (
    <tr>
      <th>Name</th>
      <th>Email</th>
    </tr>
  );
  return (
    <Modal
      isShowing={isShowing}
      onConfirm={onDismiss}
      header="Can't delete Company Role with associated users"
      confirmText="Done"
      showCancelButton={false}
      showCloseIcon={false}
    >
      <p>These users are associated to the company role</p>
      <AdminTable header={header}>
        {users &&
          users.map((user) => (
            <tr key={user.id}>
              <td>{user.attributes.fullName}</td>
              <td>{user.attributes.email}</td>
            </tr>
          ))}
      </AdminTable>
    </Modal>
  );
};

CompanyRoleUsersModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CompanyRoleUsersModal;
