import React from "react";
import PropTypes from "prop-types";
import Card from "@ROM-ui/Card";
import Breadcrumb from "@ROM-ui/Breadcrumb";
import styled from "styled-components";
/**
 * A component for creating Sections in the UI
 *
 * May contain titles, header, footer and body content
 *
 * Any props not specified in propTypes will be passed on
 * to the main container element
 */

const Section = ({
  title,
  header,
  footer,
  actions,
  children,
  breadcrumbs,
  containerClassName,
  containerStyle,
  className: customStyles,
  titleClassName: customTitleStyles,
  ...restProps
}) => {
  return (
    <div {...restProps} as="section" className={`py-4 ${customStyles || ""}`}>
      {breadcrumbs && <Breadcrumb className="mt-n3" items={breadcrumbs} />}
      {(actions || title) && (
        <div className={`d-flex justify-content-between align-items-center py-4 ${customTitleStyles || ""}`}>
          {title && <h3>{title}</h3>}
          {actions}
        </div>
      )}
      <div className={containerClassName} style={containerStyle}>
        {header && <Card.Header>{header}</Card.Header>}
        {children}
        {footer && <Card.Footer>{footer}</Card.Footer>}
      </div>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actions: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.string,
};

export default Section;
