import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "@ROM-ui/Button";
import Form from "@ROM-ui/Form";
import Modal from "@ROM-components/common/Modal";
import Select from "@ROM-components/common/Select";

import { selectAllCarriers } from "@ROM/Carriers/selectors";
import { list as fetchCarriers } from "@ROM/Carriers/actions";

const TrackingInformationForm = ({ deliveryShipping, onUpdate }) => {
  const dispatch = useDispatch();
  const carriers = useSelector(selectAllCarriers);
  const carrierOptions = carriers.map((carrier) => ({ value: carrier.id, label: carrier.attributes.name }));

  const [trackingNumber, setTrackingNumber] = useState(deliveryShipping?.attributes.trackingNumber || "");
  const [carrier, setCarrier] = useState(null);
  const selectedCarrier = carrierOptions.find((item) => item.value === carrier);

  useEffect(() => {
    dispatch(fetchCarriers());
  }, [dispatch]);

  useEffect(() => {
    setCarrier(deliveryShipping?.attributes.carrierId.toString());
  }, [carriers, deliveryShipping]);

  const handleUpdate = () => {
    onUpdate({ trackingNumber, carrierId: carrier });
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Tracking Number</Form.Label>
        <Form.Control required type="text" value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Carrier</Form.Label>
        <Select
          className="w-100"
          id="carrier-select"
          options={carrierOptions}
          onChange={(e) => setCarrier(e.value)}
          value={selectedCarrier}
        />
      </Form.Group>
      <Form.Group>
        <Button variant="primary" disabled={!selectedCarrier} onClick={handleUpdate}>
          Update
        </Button>
      </Form.Group>
    </>
  );
};

TrackingInformationForm.propTypes = {
  deliveryShipping: PropTypes.shape({
    attributes: PropTypes.shape({
      trackingNumber: PropTypes.string,
      carrierId: PropTypes.string,
    }),
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const TrackingInformationModal = ({ isShowing, onUpdate, onCancel, deliveryShipping, isMobile }) => {
  const size = isMobile ? "xx-large" : "default";
  return (
    <Modal
      header="Tracking Details"
      size={size}
      showConfirmButton={false}
      showCancelButton={false}
      isShowing={isShowing}
      onCancel={onCancel}
      bodyClasses="p-3"
    >
      <TrackingInformationForm deliveryShipping={deliveryShipping} onUpdate={onUpdate} />
    </Modal>
  );
};

TrackingInformationModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  deliveryShipping: PropTypes.shape().isRequired,
};

export default TrackingInformationModal;
