import React from "react";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";

const ItemRowHeader = () => {
  return (
    <Row>
      <Col xs={5} className="text-md-left ps-5">
        <strong>Product</strong>
      </Col>
      <Col xs={3} className="text-md-center">
        <strong>Quantity</strong>
      </Col>
      <Col xs={3} className="text-md-center">
        <strong>Remaining Quantity</strong>
      </Col>
    </Row>
  );
};

export default ItemRowHeader;
