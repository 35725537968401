import React, { useState } from "react";
import PropTypes from "prop-types";

import Form from "@ROM-ui/Form";
import Modal from "@ROM-components/common/Modal";

const EditCustomerModal = ({ visible, name, isAdmin, onEditCustomer, onCancel }) => {
  const [isAdminSt, setIsAdminSt] = useState(isAdmin);
  return (
    <Modal
      confirmText="Update"
      isShowing={visible}
      onConfirm={() => onEditCustomer(isAdminSt)}
      onCancel={onCancel}
      header={`Update Customer ${name}`}
    >
      <Form.Check className="mt-3" checked={isAdminSt} onChange={() => setIsAdminSt(!isAdminSt)} label="Customer Admin?" />
    </Modal>
  );
};

EditCustomerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onEditCustomer: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditCustomerModal;
