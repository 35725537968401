import { apiAction, standardAction } from "@ROM-utils/actions";

import WarehouseInventoriesApi from "./api";

export const CLEAR = "warehouse_inventories/api/CLEAR";
export const CLEAR_ERROR = "warehouse_inventories/api/CLEAR_ERROR";
export const LIST = "warehouse_inventories/api/LIST";
export const CREATE = "warehouse_inventories/api/CREATE";
export const UPDATE = "warehouse_inventories/api/UPDATE";
export const BULK_UPDATE = "warehouse_inventories/api/BULK_UPDATE";
export const DESTROY = "warehouse_inventories/api/DESTROY";
export const LIST_WAREHOUSES = "warehouse_inventories/api/LIST_WAREHOUSES";

export function clear() {
  return standardAction(CLEAR);
}

export function clearError() {
  return standardAction(CLEAR_ERROR);
}

export function list(options = {}) {
  return apiAction(LIST, WarehouseInventoriesApi.list(options));
}

export function listWarehouses(options = {}) {
  return apiAction(LIST_WAREHOUSES, WarehouseInventoriesApi.listWarehouses(options));
}

export function create(attributes = {}) {
  return apiAction(CREATE, WarehouseInventoriesApi.create(attributes));
}

export function update(id, attributes = {}) {
  return apiAction(UPDATE, WarehouseInventoriesApi.update(id, attributes));
}

export function bulkUpdate(attributes = {}) {
  return apiAction(BULK_UPDATE, WarehouseInventoriesApi.bulkUpdate(attributes));
}

export function destroy(id) {
  return apiAction(DESTROY, WarehouseInventoriesApi.destroy(id), { warehouseInventoryId: id });
}
