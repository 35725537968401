import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

import Button from "@ROM-ui/Button";
import TableDetails from "@ROM/CustomTables/components/Show/TableDetails";
import TableRows from "@ROM/CustomTableRows/components/Table";
import Message from "@ROM-common/Message";

import { companyAdminCustomTablesAddRowUrl } from "@ROM-utils/urlHelpers";

const CustomTableForm = ({ table, message, onEditTable, onDeleteTable }) => {
  const history = useHistory();
  const handleAddRow = () => {
    history.push(companyAdminCustomTablesAddRowUrl(table.id));
  };

  return (
    <>
      {message && <Message className="text-center" message={message} type="success" />}
      {table && (
        <>
          <div className="my-3 pb-3">
            <TableDetails table={table} onEditTable={onEditTable} onDeleteTable={onDeleteTable} />
          </div>
          <div className="d-flex align-items-center mb-2">
            <h5>Table Content</h5>
            <Button variant="primary" className="ms-auto" onClick={handleAddRow}>
              Add Row
            </Button>
          </div>
          <TableRows table={table} />
        </>
      )}
    </>
  );
};

CustomTableForm.defaultProps = {
  message: null,
  table: null,
};

CustomTableForm.propTypes = {
  message: PropTypes.string,
  table: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape(),
  }),
  onEditTable: PropTypes.func.isRequired,
  onDeleteTable: PropTypes.func.isRequired,
};

export default CustomTableForm;
