export const accountUrl = () => "/account";
export const loginUrl = () => "/users/sign_in";
export const homeUrl = () => "/";
export const adminUrl = () => "/admin";
export const invitationsUrl = () => "/invitations/:customerId";
export const successUrl = () => "/success/:name";

// Inventories
export const inventoriesUrl = () => "/inventories";

// Orders
export const ordersUrl = () => "/orders";
export const orderUrl = (id) => `${ordersUrl()}/${id}`;
export const newOrderUrl = () => "/orders/new";
export const orderShowUrl = () => "/orders/:orderId";
export const ordersEditUrl = () => "/orders/:orderId/edit";
export const orderEditUrl = (id) => `/orders/${id}/edit`;

// Deliveries
export const deliveriesUrl = () => "/deliveries";
export const editDeliveryUrl = (orderId) => (orderId ? `/orders/${orderId}/deliveries` : "/orders/:orderId/deliveries");

// Bills of Lading
export const newBillOfLadingUrl = (orderId, deliveryId) =>
  orderId
    ? `/orders/${orderId}/deliveries/${deliveryId}/bills-of-lading/new`
    : "/orders/:orderId/deliveries/:deliveryId/bills-of-lading/new";

export const editBillOfLadingUrl = (orderId, deliveryId, billOfLadingId) =>
  orderId && deliveryId && billOfLadingId
    ? `/orders/${orderId}/deliveries/${deliveryId}/bills-of-lading/${billOfLadingId}/edit`
    : `/orders/:orderId/deliveries/:deliveryId/bills-of-lading/:billOfLadingId/edit`;

// ============ Customer Admin ============

export const customerAdminUrl = () => "/customer-admin/customers";
export const customerAdminCreateCustomerUrl = () => "/customer-admin/customers/new";
export const customerAdminManageCustomerUrl = (id, tab) =>
  id ? `/customer-admin/customers/${id}/${tab || "orders"}` : "/customer-admin/customers/:customerId/:tab";

// ============ Company Admin ============

export const companyAdminUrl = () => "/company-admin/company";

export const companyAdminProductsUrl = () => "/company-admin/products";
export const companyAdminProductsShowUrl = (id) => `/company-admin/products/${id || ":productId"}`;
export const companyAdminProductsEditUrl = () => "/company-admin/products/:productId/edit";
export const companyAdminProductsCreateUrl = () => "/company-admin/products/new";
export const companyAdminProductEditUrl = (id) => `/company-admin/products/${id}/edit`;

export const companyAdminCategoriesUrl = () => "/company-admin/categories";

export const companyAdminCustomTablesCreateUrl = () => "/company-admin/custom-tables/new";
export const companyAdminCustomTablesShowUrl = (id) => `/company-admin/custom-tables/${id || ":customTableId"}`;
export const companyAdminCustomTablesEditUrl = (id) => `/company-admin/custom-tables/${id || ":customTableId"}/edit`;
export const companyAdminCustomTablesAddRowUrl = (id) => `/company-admin/custom-tables/${id || ":customTableId"}/rows/new`;
export const companyAdminCustomTablesEditRowUrl = (id, rowId) =>
  `/company-admin/custom-tables/${id || ":customTableId"}/rows/${rowId || ":customTableRowId"}`;

export const companyAdminCustomersUrl = () => "/company-admin/customers";
export const companyAdminCustomersManageUrl = (id, tab) =>
  id ? `/company-admin/customers/${id}/${tab || "orders"}` : "/company-admin/customers/:customerId/:tab";

export const companyAdminCustomersCreateUrl = () => "/company-admin/customers/new";

export const companyAdminPriceListsUrl = () => "/company-admin/price-lists";
export const companyAdminPriceListEditUrl = (id) => `/company-admin/price-lists/${id}/edit`;

export function companyAdminPriceListShowUrl(id) {
  return id ? `/company-admin/price-lists/${id}` : "/company-admin/price-lists/:priceListid";
}

export const companyAdminUsersUrl = () => "/company-admin/users";
export const companyAdminUsersManageUrl = (id) => `/company-admin/users/${id || ":userId"}`;

export const companyAdminWarehousesUrl = () => "/company-admin/warehouses";
export const companyAdminWarehousesCreateUrl = () => "/company-admin/warehouses/new";
export const companyAdminWarehousesEditUrl = (id) =>
  id ? `/company-admin/warehouses/${id}/edit` : "/company-admin/warehouses/:warehouseId/edit";
