import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "@ROM-components/common/Modal";
import Form from "@ROM-ui/Form";

const CompanyRoleCreateModal = ({ isShowing, onConfirm, onCancel }) => {
  const [roleName, setRoleName] = useState("");
  const [error, setError] = useState(false);

  const onConfirmHandler = async () => {
    if (roleName.length === 0) {
      setError(true);
    } else {
      setError(false);
      const newRoleName = roleName;
      setRoleName("");
      await onConfirm(newRoleName);
    }
  };

  const onCancelHandler = () => {
    setRoleName("");
    onCancel();
  };

  return (
    <Modal
      isShowing={isShowing}
      onConfirm={onConfirmHandler}
      onCancel={onCancelHandler}
      header="Create New Role"
      confirmText="Create"
      showCloseIcon={false}
    >
      <Form.Group>
        <Form.Label>Role Name</Form.Label>
        <Form.Control as="input" type="text" value={roleName} onChange={(e) => setRoleName(e.target.value)} isInvalid={error} />
        <Form.Control.Feedback type="invalid">Role name cannot be empty</Form.Control.Feedback>
      </Form.Group>
    </Modal>
  );
};

CompanyRoleCreateModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CompanyRoleCreateModal;
