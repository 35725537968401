import React from "react";
import PropTypes from "prop-types";

import Spinner from "@ROM-ui/Spinner";

const LoadingDots = ({ count, className }) => (
  <div className={`d-flex justify-content-center py-4 -mx-1 ${className}`}>
    {[...Array(count)].map((_, index) => (
      <Spinner key={index} animation="grow" variant="secondary" className="mx-1" />
    ))}
  </div>
);

LoadingDots.defaultProps = {
  count: 3,
  className: "",
};

LoadingDots.propTypes = {
  count: PropTypes.number,
  className: PropTypes.string,
};

export default LoadingDots;
