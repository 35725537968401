import React, { useReducer, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Button from "@ROM-ui/Button";
import Message from "@ROM-common/Message";
import { reducer } from "@ROM/App/utils/forms";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectRegions } from "@ROM/Regions/selectors";
import CustomersApi from "@ROM/Customers/api";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { fetchUsers } from "@ROM/Customers/utils";
import FormBody from "@ROM/Customers/components/Form/FormBody";

const NewCustomerForm = ({ afterCreateCallback = null }) => {
  const regions = useSelector(selectRegions);
  const currentCompany = useSelector(selectCurrentCompany);
  const currentUser = useSelector(selectCurrentUser);

  const initialAttributes = {
    name: "",
    membershipsAttributes: [],
    isActive: true,
    taxExempt: false,
    primaryContact: "",
    regionId: regions.length === 1 ? regions[0].id : null,
  };

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [attributes, setAttributes] = useReducer(reducer, initialAttributes);

  const handleCreate = async () => {
    setLoading(true);
    const transformedMemberships = attributes.membershipsAttributes.map(({ value }) => ({
      userId: value,
      companyId: currentCompany.id,
    }));
    const finalCustomer = { ...attributes, membershipsAttributes: transformedMemberships };
    const response = await CustomersApi.create(finalCustomer).catch((error) => error);

    if (response.status === 201) {
      setShowSuccess(true);
      setErrorMsg(null);
      setLoading(false);
      if (afterCreateCallback) afterCreateCallback(response.data.data);
    } else {
      setShowSuccess(false);
      let errorMessage = response?.data?.detail?.name?.[0] || response.data.detail || response.data[0].detail;
      errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
      setErrorMsg(errorMessage);
      setLoading(false);
    }
    window.scroll(0, 0);
  };

  const handleCustomerChange = (attributesToChange = {}) => {
    setAttributes({ ...attributes, ...attributesToChange });
  };

  useEffect(() => {
    setInitialized(true);
  }, []);

  return (
    <>
      {showSuccess ? (
        <Message message="The customer was successfully created." type="success" />
      ) : (
        errorMsg && <Message message={errorMsg} type="danger" />
      )}
      <FormBody
        initialized={initialized}
        customer={attributes}
        handleCustomerChange={handleCustomerChange}
        setUsers={(value) => handleCustomerChange({ membershipsAttributes: value })}
        fetchUsers={fetchUsers}
        memberships={attributes.membershipsAttributes}
        currentUser={currentUser}
        regions={regions}
      />
      <div className="text-end">
        <Button onClick={() => history.goBack()} variant="danger" id="create-customer-back-btn" className="me-3">
          Back
        </Button>
        <Button onClick={handleCreate} variant="success" disabled={loading} id="create-customer-confirm-btn">
          Create
        </Button>
      </div>
    </>
  );
};

NewCustomerForm.propTypes = {
  afterCreateCallback: PropTypes.func,
};

NewCustomerForm.defaultProps = {
  afterCreateCallback: null,
};

export default NewCustomerForm;
