import React, { useContext } from "react";

import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import Attachments from "@ROM/CustomerAdmin/components/Attachments";

const AttachmentsPanel = () => {
  const customer = useContext(CustomerContext);

  return <Attachments customer={customer} />;
};

export default AttachmentsPanel;
