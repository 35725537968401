import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import styled from "styled-components";

import { canUpdateCustomersAllParams } from "@ROM/Customers/permissions";
import { canListPriceList } from "@ROM/PriceLists/permissions";
import customerType from "@ROM/Customers/types";
import { asyncSelectType } from "@ROM/App/types";
import { regionsForSelect } from "@ROM/Regions/utils";
import AsyncPaginate from "@ROM-components/common/AsyncPaginate";
import Select from "@ROM-components/common/Select";
import Badge from "@ROM-ui/Badge";
import Form from "@ROM-ui/Form";
import Fieldset from "@ROM-ui/Fieldset";

const unstyledSelectorStyles = {
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
    border: "none",
  }),
  menu: (provided) => ({
    ...provided,
    border: "none",
  }),
};

const UnstyledInput = styled.input`
  border: 0;
  padding: 0;
  margin: 0;
  height: auto;

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: -webkit-focus-ring-color auto 0px;
    background-color: white;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const Body = ({
  initialized,
  customer,
  handleCustomerChange,
  currentUser,
  regions,
  customerRegion,
  priceList,
  priceListsOptions,
  setPriceList,
  cropAdvisors,
  loadCropAdvisorOptions,
  setCropAdvisors,
}) => {
  const canUpdateAllParams = canUpdateCustomersAllParams(currentUser);
  const selectRegions = regionsForSelect(regions);
  const regionValue = selectRegions.find((option) => option.value === customer?.regionId?.toString());

  return (
    <Form className="mb-3">
      {initialized ? (
        <div className="d-flex mb-2">
          <Fieldset legend="Customer Name" legendClassName="text-secondary">
            <UnstyledInput
              className="form-control"
              value={customer.name || ""}
              id="customer-form-name"
              placeholder="eg. Acme Farms LLC"
              onChange={(e) => {
                handleCustomerChange({ name: e.target.value });
              }}
            />
          </Fieldset>
        </div>
      ) : (
        <Skeleton />
      )}

      {initialized ? (
        <div className="d-flex mb-2">
          <Fieldset legend="Primary Contact" legendClassName="text-secondary">
            <UnstyledInput
              className="form-control"
              value={customer.primaryContact || ""}
              id="customer-form-primary-contact"
              onChange={(e) => {
                handleCustomerChange({ primaryContact: e.target.value });
              }}
              placeholder="eg. John Smith"
            />
          </Fieldset>
        </div>
      ) : (
        <Skeleton />
      )}

      {initialized ? (
        <div className="d-flex mb-3">
          <Fieldset legend="Invoice Prefix" className="me-1" legendClassName="text-secondary">
            {canUpdateAllParams ? (
              <UnstyledInput
                className="form-control"
                maxLength="4"
                id="customer-form-invoice-number-prefix"
                value={customer.invoiceNumberPrefix || ""}
                onChange={(e) => {
                  handleCustomerChange({ invoiceNumberPrefix: e.target.value });
                }}
              />
            ) : (
              <div>{customer.invoiceNumberPrefix}</div>
            )}
          </Fieldset>
          <Fieldset
            legend="Region"
            className="ms-1"
            legendClassName="text-secondary"
            bodyClassName={canUpdateAllParams ? "px-2 py-0" : ""}
          >
            {canUpdateAllParams ? (
              <Select
                styles={unstyledSelectorStyles}
                options={selectRegions}
                onChange={(e) => handleCustomerChange({ regionId: e.value })}
                value={regionValue}
              />
            ) : (
              <div>{customerRegion?.attributes.name || "No region"}</div>
            )}
          </Fieldset>
        </div>
      ) : (
        <Skeleton />
      )}

      {canUpdateAllParams && canListPriceList(currentUser) && (
        <>
          {initialized ? (
            <div className="mb-3">
              <Fieldset legend="Price List" legendClassName="text-secondary" bodyClassName="px-2 pb-2 pt-1">
                <AsyncPaginate
                  styles={unstyledSelectorStyles}
                  value={priceList}
                  loadOptions={priceListsOptions}
                  onChange={setPriceList}
                  placeholder="Use Default Price List"
                  additional={{ page: 1 }}
                />
              </Fieldset>
            </div>
          ) : (
            <Skeleton />
          )}
        </>
      )}

      {initialized ? (
        <div className="mb-3">
          <Fieldset
            legend="Crop Advisors"
            legendClassName="text-secondary"
            bodyClassName={canUpdateAllParams ? "px-2 pb-2 pt-1" : ""}
          >
            {canUpdateAllParams ? (
              <>
                <AsyncPaginate
                  isMulti
                  styles={unstyledSelectorStyles}
                  value={cropAdvisors}
                  loadOptions={loadCropAdvisorOptions}
                  onChange={setCropAdvisors}
                  placeholder="Select crop advisors"
                  additional={{ page: 1 }}
                />
              </>
            ) : (
              cropAdvisors.map((cropAdvisor) => (
                <Badge key={cropAdvisor.value} bg="light" className="me-2">
                  {cropAdvisor.label}
                </Badge>
              ))
            )}
          </Fieldset>
        </div>
      ) : (
        <Skeleton />
      )}

      {canUpdateAllParams && (
        <>
          {initialized ? (
            <div className="mb-3">
              <Form.Check
                checked={customer.isActive}
                onChange={() => handleCustomerChange({ isActive: !customer.isActive })}
                type="checkbox"
                label="Is Active"
              />
              <Form.Check
                checked={customer.taxExempt}
                onChange={() => handleCustomerChange({ taxExempt: !customer.taxExempt })}
                type="checkbox"
                label="Tax Exempt?"
                id="tax-exempt"
              />
              <Form.Check
                checked={customer.needsApproval}
                onChange={() => handleCustomerChange({ needsApproval: !customer.needsApproval })}
                type="checkbox"
                label="Needs Approval"
              />
            </div>
          ) : (
            <div className="w-50">
              <Skeleton count={3} />
            </div>
          )}
        </>
      )}
    </Form>
  );
};

Body.propTypes = {
  initialized: PropTypes.bool.isRequired,
  customer: customerType.isRequired,
  handleCustomerChange: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  regions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  customerRegion: PropTypes.shape().isRequired,
  priceList: asyncSelectType.isRequired,
  priceListsOptions: PropTypes.arrayOf(asyncSelectType).isRequired,
  setPriceList: PropTypes.func.isRequired,
  cropAdvisors: PropTypes.arrayOf(PropTypes.shape).isRequired,
  loadCropAdvisorOptions: PropTypes.arrayOf(PropTypes.shape).isRequired,
  setCropAdvisors: PropTypes.func.isRequired,
};

Body.defaultProps = {};

export default Body;
