import React, { useState } from "react";
import PropTypes from "prop-types";
import ModalUi from "@ROM-ui/Modal";
import Button from "@ROM-ui/Button";
import styled from "styled-components";
import PointerSpan from "@ROM-ui/PointerSpan";
import Spinner from "@ROM-ui/Spinner";

import { classesForSize } from "./utils";
/**
 * A modal component
 *
 * Add content to the modal via nested children.
 * Optional props allow for customization of the confirm modal
 * @see proptypes
 *
 * @example
 * <Modal
 *   isShowing={true}
 *   header="Confirm your Order"
 *   onConfirm={() => console.log("CONFIRMED!")}
 *   onCancel={() => console.log("FIRED BEFORE HIDING on Cancel click")}>
 *
 *    <p>This is body text inside the modal</p>
 * </Modal>
 */

const Modal = ({
  header,
  confirmText,
  cancelText,
  confirmEnabled,
  isShowing,
  size,
  onConfirm,
  onCancel,
  showCancelButton,
  showConfirmButton,
  showCloseIcon,
  children,
  bodyClasses,
  ...restProps
}) => {
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const handleConfirm = async () => {
    setLoading(true);
    if (onConfirm) await onConfirm();
    setLoading(false);
  };

  return (
    <ModalUi
      className={`${classesForSize(size)} text-secondary rounded`}
      isOpen={isShowing}
      onRequestClose={handleCancel}
      {...restProps}
    >
      {(header !== "" || showCloseIcon) && (
        <div className="d-flex py-4 px-4 border-bottom justify-content-between align-items-center">
          <Header>{header}</Header>
          {showCloseIcon && <PointerSpan id="modal-cancel-icon" className="fas fa-times" onClick={handleCancel} />}
        </div>
      )}

      <div className={`${bodyClasses ? bodyClasses : "py-3 px-3 px-md-5"}`}>{children}</div>

      {(showConfirmButton || showCancelButton) && (
        <Footer className={`px-5 pb-3 ${children ? "border-top" : ""}`}>
          {showCancelButton && (
            <Button className="p-1 me-auto w-25" variant="danger" onClick={handleCancel}>
              {cancelText}
            </Button>
          )}
          {showConfirmButton && (
            <Button
              className="p-1 ms-auto w-25"
              variant="success"
              onClick={handleConfirm}
              disabled={!confirmEnabled || loading}
              id="modal-confirm-btn"
            >
              {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : confirmText}
            </Button>
          )}
        </Footer>
      )}
    </ModalUi>
  );
};

Modal.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  isShowing: PropTypes.bool,
  size: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool,
  showConfirmButton: PropTypes.bool,
  confirmEnabled: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  children: PropTypes.node,
  bodyClasses: PropTypes.string,
};

Modal.defaultProps = {
  header: "",
  confirmText: "Confirm",
  cancelText: "Cancel",
  isShowing: false,
  size: "standard",
  showConfirmButton: true,
  showCancelButton: true,
  showCloseIcon: true,
  confirmEnabled: true,
  bodyClasses: null,
  children: null,
};

export default Modal;

const Header = styled.h6`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;
