import { apiAction } from "@ROM-utils/actions";

import BillOfLadingItemsApi from "./api";

export const CREATE_BILL_OF_LADING_ITEM = "bill_of_lading_items/api/CREATE";
export const UPDATE_BILL_OF_LADING_ITEM = "bill_of_lading_items/api/UPDATE";
export const DESTROY_BILL_OF_LADING_ITEM = "bill_of_lading_items/api/DESTROY";
export const RE_SORT_BILL_OF_LADING_ITEMS = "bill_of_lading_items/api/RE_SORT";

export function create(attributes = {}) {
  return apiAction(CREATE_BILL_OF_LADING_ITEM, BillOfLadingItemsApi.create(attributes), { isNewPallet: !attributes?.palletId });
}

export function update(billOfLadingItem = {}, meta = {}) {
  return apiAction(UPDATE_BILL_OF_LADING_ITEM, BillOfLadingItemsApi.update(billOfLadingItem.id, billOfLadingItem.attributes), {
    ...meta,
  });
}

export function destroy(billOfLadingId, id, meta = {}) {
  return apiAction(DESTROY_BILL_OF_LADING_ITEM, BillOfLadingItemsApi.destroy(billOfLadingId, id), { ...meta });
}

export function reSort(item = {}) {
  return apiAction(RE_SORT_BILL_OF_LADING_ITEMS, BillOfLadingItemsApi.reSort(item.id, item.attributes));
}
