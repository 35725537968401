import React from "react";
import { Route } from "react-router-dom";
import DeliveriesEdit from "@ROM-pages/Deliveries/Edit";
import Layout from "@ROM-pages/Layouts/Authenticated";

class RouteHandler extends Route {
  render() {
    const { orderId } = this.props.computedMatch.params;
    return (
      <Layout>
        <DeliveriesEdit orderId={orderId} />
      </Layout>
    );
  }
}

export default RouteHandler;
