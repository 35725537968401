import React from "react";
import PropTypes from "prop-types";
import Alert from "@ROM-ui/Alert";

const MessageAlert = ({ children, error }) => {
  if (!children) {
    return null;
  }
  return <Alert variant={error ? "danger" : "success"}>{children}</Alert>;
};

MessageAlert.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.bool.isRequired,
};

export default MessageAlert;
