import update from "immutability-helper";
import { handle } from "redux-pack";
import { FETCH_NOTIFICATIONS } from "./actions";

const initialState = {
  all: {
    items: [],
    loading: false,
    error: false,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};

  switch (type) {
    case FETCH_NOTIFICATIONS:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        finish: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              error: { $set: false },
            },
          });
        },
      });

    default:
      return state;
  }
}

export default reducer;
