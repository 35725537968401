import React from "react";

const InvitationHeader = () => {
  return (
    <tr>
      <th>Status</th>
      <th>Receiver</th>
      <th>Email</th>
      <th>Role</th>
      <th>Customer</th>
      <th>Created at</th>
      <th className="text-end">Actions</th>
    </tr>
  );
};

export default InvitationHeader;
