import { apiAction } from "@ROM-utils/actions";

import ItemsApi from "./api";

export const LIST_ORDER_ITEMS = "cart_items/api/LIST";
export const CREATE_ORDER_ITEM = "cart_items/api/CREATE";
export const UPDATE_ORDER_ITEM = "cart_items/api/UPDATE";
export const DESTROY_ORDER_ITEM = "cart_items/api/DESTROY";
export const RE_SORT_ORDER_ITEMS = "cart_items/api/RE_SORT";

export function list(orderId) {
  return apiAction(LIST_ORDER_ITEMS, ItemsApi.list(orderId));
}

export function create(attributes = {}) {
  return apiAction(CREATE_ORDER_ITEM, ItemsApi.create(attributes));
}

export function update(item = {}, meta) {
  return apiAction(UPDATE_ORDER_ITEM, ItemsApi.update(item.id, item.attributes), { ...meta });
}

export function destroy(orderId, id) {
  return apiAction(DESTROY_ORDER_ITEM, ItemsApi.destroy(orderId, id), {
    itemId: id,
  });
}

export function reSort(item = {}) {
  return apiAction(RE_SORT_ORDER_ITEMS, ItemsApi.reSort(item.id, item.attributes));
}
