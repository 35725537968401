import React from "react";
import styled from "styled-components";
import Spinner  from "@ROM-ui/Spinner";

const LoadingSplash = ({}) => {
  return (
    <div className="container">
      <StyledDiv className="text-center container-panel">
        <Spinner size="sm" variant="secondary" role="status" animation="grow" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <Spinner size="sm" variant="secondary" animation="grow" className="me-2" />
        <Spinner size="sm" variant="secondary" animation="grow" />
      </StyledDiv>
    </div>
  );
};

export default LoadingSplash;


const StyledDiv = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 15px;
`;
