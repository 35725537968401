import React, { useState } from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canEditCompany, canManageCompanyRoles } from "@ROM/Company/permissions";
import { Tabs, TabItem } from "@ROM-components/common/AdminTabs";
import Section from "@ROM-components/common/Section";
import CompanyForm from "@ROM/CompanyAdmin/Company/Form";
import CompanyRoles from "@ROM/CompanyAdmin/Company/Roles";

const COMPANY_SETTINGS = "company-settings";
const COMPANY_ROLES = "company-roles";

const CompanyAdmin = () => {
  const currentUser = useSelector(selectCurrentUser);
  const currentUserCanEditCompany = canEditCompany(currentUser);
  const currentUserCanManageCompanyRoles = canManageCompanyRoles(currentUser);

  const initialTab = () => {
    if (currentUserCanEditCompany) {
      return COMPANY_SETTINGS;
    }

    if (currentUserCanManageCompanyRoles) {
      return COMPANY_ROLES;
    }

    return null;
  };

  const [activeTab, setActiveTab] = useState(initialTab());

  const getContent = (contentFor) => {
    switch (contentFor) {
      case COMPANY_SETTINGS:
        return (
          <div style={{ maxWidth: "650px" }}>
            <CompanyForm />
          </div>
        );
      case COMPANY_ROLES:
        return <CompanyRoles />;
      default:
        return null;
    }
  };

  return (
    <Section title="Company Information">
      <Tabs>
        {currentUserCanEditCompany && (
          <TabItem
            value="Overview"
            iconClasses="fa fa-home"
            name={COMPANY_SETTINGS}
            activeName={activeTab}
            setActiveName={setActiveTab}
          />
        )}
        {currentUserCanManageCompanyRoles && (
          <TabItem
            value="Roles"
            iconClasses="fa fa-user"
            name={COMPANY_ROLES}
            activeName={activeTab}
            setActiveName={setActiveTab}
          />
        )}
      </Tabs>

      <div className="pt-3">{getContent(activeTab)}</div>
    </Section>
  );
};

export default CompanyAdmin;
