import CustomTableRowApi from "@ROM/CustomTableRows/api";

export const getRowClassName = (index) => (index === 0 ? "text-start" : "text-center");

export const loadRowsOptions = async (customTable) => {
  const result = await CustomTableRowApi.listRows(customTable.id);

  const options = result.data.data.map((row) => {
    const { data: dataRow } = row.attributes;

    const label =
      dataRow[customTable.attributes.displayColumn] ||
      Object.keys(dataRow)
        .map((key) => `${key}: ${dataRow[key]}`)
        .join(" | ");

    return {
      value: parseInt(row.id, 10),
      label,
    };
  });

  return options;
};
