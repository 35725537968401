import React from "react";
import { useSelector } from "react-redux";
import Section from "@ROM-components/common/Section";
import NewWarehouseForm from "@ROM/Warehouses/components/Form";
import { warehousesBreadcrumbs } from "@ROM/CompanyAdmin/utils";

import { Redirect } from "react-router";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canCreateWarehouses } from "@ROM/Warehouses/permissions";
import { homeUrl } from "@ROM-utils/urlHelpers";

const Page = () => {
  const currentUser = useSelector(selectCurrentUser);
  const breadcrumbs = warehousesBreadcrumbs([{ label: "Create", active: true }]);

  if (!canCreateWarehouses(currentUser)) {
    return <Redirect to={homeUrl()} />;
  }

  return (
    <Section title="Create Warehouse" breadcrumbs={breadcrumbs} containerStyle={{ maxWidth: "650px" }}>
      <NewWarehouseForm />
    </Section>
  );
};

export default Page;
