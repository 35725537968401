import update from "immutability-helper";
import { handle } from "redux-pack";

import { LIST, CREATE, DELETE, CREATE_SUBCATEGORY, DELETE_SUBCATEGORY } from "./actions";

const initialState = {
  all: {
    error: false,
    loading: true,
    items: [],
    included: [],
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};

  switch (type) {
    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              included: { $set: json.included },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case CREATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: [...prevState.all.items, json.data] },
              included: { $set: [...prevState.all.included, ...json.included] },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case DELETE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          const deletedCategoryId = action.meta.categoryId;
          let items = [...prevState.all.items];
          let included = [...prevState.all.included];

          items = items.filter((cat) => cat.id !== deletedCategoryId);
          included = included.filter(
            (inc) => inc.type === "subcategory" && inc.relationships.category.data.id !== deletedCategoryId
          );

          return update(prevState, {
            all: {
              items: { $set: items },
              included: { $set: included },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case DELETE_SUBCATEGORY:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          const deletedSubcategoryId = action.meta.subcategoryId;
          const included = [...prevState.all.included];
          const indexToDelete = included.findIndex((inc) => inc.type === "subcategory" && inc.id === deletedSubcategoryId);

          included.splice(indexToDelete, 1);

          return update(prevState, {
            all: {
              included: { $set: included },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    case CREATE_SUBCATEGORY:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
              error: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
              error: { $set: true },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              included: { $set: [...prevState.all.included, json.data] },
              loading: { $set: false },
              error: { $set: false },
            },
          });
        },
      });

    default:
      return state;
  }
}

export default reducer;
