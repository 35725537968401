import styled from "styled-components";

const SortableStrongPointer = styled.strong`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify || "left"};
`;

export default SortableStrongPointer;
