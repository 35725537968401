import React, { useEffect, useReducer, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import api from "@ROM/Invitations/api";
import { canCreateInvitations, canResendInvitations, canDeleteInvitations } from "@ROM/Invitations/permissions";
import { list as listInvitations, setPage, destroy as deleteInvitation } from "@ROM/Invitations/actions";
import { list as fetchUsers } from "@ROM/Users/actions";
import { selectCustomerRoles, selectCompanyRoles, selectCurrentUser } from "@ROM/Auth/selectors";
import { selectAllInvitations, selectInvitationsPagination } from "@ROM/Invitations/selectors";
import { selectUsersPagination } from "@ROM/Users/selectors";
import { reducer } from "@ROM/App/utils/forms";
import Button from "@ROM-ui/Button";
import Modal from "@ROM-components/common/Modal";
import Pagination from "@ROM-components/common/Pagination";
import CreateInvitation from "@ROM/Users/components/Invitations/CreateInvitation";
import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import Filters from "@ROM/Users/components/Invitations/Filters";
import InvitationRow from "./InvitationRow";

const initialFilters = {
  accepted: null,
};

const InvitationTable = () => {
  const currentUser = useSelector(selectCurrentUser);
  const invitations = useSelector(selectAllInvitations);
  const pagination = useSelector(selectInvitationsPagination);
  const userPagination = useSelector(selectUsersPagination);
  const companyRoles = useSelector(selectCompanyRoles);
  const customerRoles = useSelector(selectCustomerRoles);
  const customer = useContext(CustomerContext);

  const dispatch = useDispatch();
  const getFilteredInvitations = (params) => dispatch(listInvitations(params));

  const [filters, setFilter] = useReducer(reducer, initialFilters);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const customerId = customer?.id ?? null;

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        getFilteredInvitations({ ...filters, customerId }).then(() => {
          if (!initialized) setInitialized(true);
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [filters, customerId]);

  const handleFilterChange = (name, value) => {
    setFilter({ [name]: value });
  };

  const handleClearFilters = () => {
    getFilteredInvitations({ ...initialFilters, customerId });
    setFilter(initialFilters);
  };

  const handlePageClick = (page) => {
    dispatch(setPage(page));
    getFilteredInvitations({ ...filters, page, customerId });
  };

  const onDeleteClick = (id) => {
    dispatch(deleteInvitation(id));
  };

  const onResendClick = (id) => {
    api.resend({ id }).then(() => {
      setShowSuccess(true);
    });
  };

  const canCreate = canCreateInvitations(currentUser, customerId);
  const canDelete = canDeleteInvitations(currentUser, customerId);
  const canResend = canResendInvitations(currentUser, customerId);

  return (
    <>
      <h4>Invitations</h4>
      <div className="bg-light p-1 mb-2 rounded">
        <Filters handleFilterChange={handleFilterChange} handleClearFilters={handleClearFilters} filters={filters} />
      </div>
      {initialized &&
        invitations.map((invitation) => (
          <InvitationRow
            key={invitation.id}
            invitation={invitation}
            onDeleteClick={canDelete && onDeleteClick}
            onResendClick={canResend && onResendClick}
          />
        ))}

      {canCreate && (
        <Button
          onClick={() => setShowCreate(true)}
          id="customer-edit-add-invitation-btn"
          variant="outline-primary"
          className="w-100"
        >
          Invite new members
        </Button>
      )}

      <Pagination pagination={pagination} handlePageClick={handlePageClick} />

      <CreateInvitation
        customerId={customerId}
        updateInvitations={() => getFilteredInvitations({ ...initialFilters, customerId })}
        show={showCreate}
        onCancel={() => setShowCreate(false)}
        callback={() => {
          dispatch(fetchUsers({ page: userPagination.currentPage, customerId }));
          setShowSuccess(true);
        }}
        companyRoles={companyRoles}
        customerRoles={customerRoles}
      />

      <Modal
        isShowing={showSuccess}
        onCancel={() => setShowSuccess(false)}
        showCancelButton={false}
        showConfirmButton={false}
        header="Success!"
      >
        <p>The invitation was sent successfully</p>
      </Modal>
    </>
  );
};

export default InvitationTable;
