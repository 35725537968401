import React from "react";
import Navbar from "@ROM-ui/Navbar";
import { homeUrl } from "@ROM-utils/urlHelpers";
import Logo from "@ROM-components/common/Logo";

const Brand = ({ currentCompany }) => (
  <Navbar.Brand href={homeUrl()}>
    <Logo currentCompany={currentCompany} />
  </Navbar.Brand>
);

export default Brand;
