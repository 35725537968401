import React from "react";
import PropTypes from "prop-types";
import AdminTable from "@ROM-components/common/AdminTable";
import PriceListRow from "./PriceListRow";
import PriceListHeader from "./PriceListHeader";

const PriceListTable = ({ priceLists, handleFilterChange, filters }) => {
  return (
    <div className="pt-2">
      <AdminTable header={<PriceListHeader handleFilterChange={handleFilterChange} filters={filters} />}>
        {priceLists.map((priceList) => (
          <PriceListRow key={priceList.id} priceList={priceList} />
        ))}
      </AdminTable>
    </div>
  );
};

PriceListTable.propTypes = {
  priceLists: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};

export default PriceListTable;
