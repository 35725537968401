/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";

import Badge from "@ROM-ui/Badge";

const DefaultBadge = (props) => (
  <StyledBadge bg="primary" {...props}>
    Default
  </StyledBadge>
);

const StyledBadge = styled(Badge)`
  text-transform: uppercase;
  font-size: 0.7em;
  padding: 0.3rem 0.5rem;
  line-height: 1.25;
`;

export default DefaultBadge;
