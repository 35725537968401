import { apiAction, standardAction } from "@ROM-utils/actions";
import RegionsApi from "./api";

export const LIST = "regions/api/LIST";
export const SET_PAGE = "products/api/SET_PAGE";

export function list(options = {}) {
  return apiAction(LIST, RegionsApi.list(options));
}

export function setPage(page) {
  return standardAction(SET_PAGE, { page });
}
