import React, { useState, useEffect } from "react";
import Form from "@ROM-ui/Form";
import DayPickerInput from "react-day-picker/DayPickerInput";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Select from "@ROM-components/common/Select";
import Panel from "@ROM-ui/Panel";
import PropTypes from "prop-types";
import styled from "styled-components";
import { formatDate, parseDate } from "react-day-picker/moment";
import Message from "@ROM-common/Message/index";
import "moment/locale/it";
import { MISSING_PO_NUMBER, MISSING_SHIP_DATE } from "@ROM-utils/errors";

const OrderExtraInformation = ({
  valueChanged,
  handleShipDate,
  stateOrder,
  company,
  isMobile,
  currentUser,
  canEditCropAdvisor,
  cropAdvisorsForCustomer,
  errors,
}) => {
  const [selectedCropAdvisor, setSelectedCropAdvisor] = useState(null);

  const handleCropAdvisorChanged = (event) => {
    setSelectedCropAdvisor(event);
    valueChanged({ target: { value: event.value, dataset: { field: "cropAdvisorId" } } });
  };

  useEffect(() => {
    const defaultCropAdvisor = cropAdvisorsForCustomer.find((employee) => employee.value === currentUser.id);
    const cropAdvisor = cropAdvisorsForCustomer.find((employee) => employee.value === stateOrder.cropAdvisorId?.toString());

    setSelectedCropAdvisor(cropAdvisor || defaultCropAdvisor);

    if (!stateOrder.cropAdvisorId && defaultCropAdvisor) {
      handleCropAdvisorChanged(defaultCropAdvisor);
    }
  }, [stateOrder.cropAdvisorId, cropAdvisorsForCustomer]);

  const cropAdvisorPlaceholder = cropAdvisorsForCustomer.length > 0 ? "Select..." : "No employee in region";

  const dateIn30Days = new Date();
  dateIn30Days.setDate(dateIn30Days.getDate() + 30);

  const poNumberError = errors && !stateOrder.poNumber;

  const formContent = (
    <>
      <Form>
        <Row className="mb-3">
          <Col xs={12} md={6} className="mb-3">
            <Form.Label>Purchase Order Number</Form.Label>
            <Form.Control
              type="text"
              data-field="poNumber"
              value={stateOrder.poNumber || ""}
              onChange={valueChanged}
              id="new-order-po-number"
              isInvalid={poNumberError}
            />
            {poNumberError && <Message className="mt-2" message={MISSING_PO_NUMBER} type="danger" />}
          </Col>

          {company.attributes.showOrderShipDate && (
            <Col xs={12} md={6} className="mb-3">
              <ShipDate>
                <span>Ship Date*</span>
                <DayPickerInput
                  value={stateOrder.shipDate}
                  onDayChange={handleShipDate}
                  component={Form.Control}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  inputProps={{ className: `${!stateOrder.shipDate ? "border-danger not-outline" : null}` }}
                />
                {!stateOrder.shipDate && <Message className="mt-2" message={MISSING_SHIP_DATE} type="danger" />}
                {stateOrder.shipDate > dateIn30Days && company.attributes.shippingWarning && (
                  <Message message={company.attributes.shippingWarning} type="warning" />
                )}
              </ShipDate>
            </Col>
          )}
          {canEditCropAdvisor && (
            <Col xs={12} md={6}>
              <Form.Label>Associated Crop Advisor</Form.Label>
              <Select
                name="cropAdvisorId"
                value={selectedCropAdvisor}
                options={cropAdvisorsForCustomer}
                placeholder={cropAdvisorPlaceholder}
                onChange={handleCropAdvisorChanged}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              type="text"
              data-field="notes"
              as="textarea"
              id="new-order-notes"
              rows="3"
              value={stateOrder.notes || ""}
              onChange={valueChanged}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
  return isMobile ? (
    <Row className="bg-white">
      <Col>
        <div className="small fw-bold ms-4 my-3">Other Information</div>
        <div className="p-3 m-2 shadow">{formContent}</div>
      </Col>
    </Row>
  ) : (
    <>
      <Row className="mt-4">
        <Col>
          <h5 className="text-white mb-3">Other Information</h5>
        </Col>
      </Row>
      <Panel>{formContent}</Panel>
    </>
  );
};

OrderExtraInformation.propTypes = {
  valueChanged: PropTypes.func.isRequired,
  handleShipDate: PropTypes.func.isRequired,
  stateOrder: PropTypes.shape().isRequired,
  company: PropTypes.shape().isRequired,
  isMobile: PropTypes.bool.isRequired,
  cropAdvisorsForCustomer: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentUser: PropTypes.shape().isRequired,
  canEditCropAdvisor: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape),
};

OrderExtraInformation.defaultProps = {
  errors: null,
};

const ShipDate = styled.div`
  span {
    display: block;
    margin-bottom: 8px;
  }
  .DayPickerInput {
    width: 100%;
  }
`;

export default OrderExtraInformation;
