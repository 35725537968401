import { createSelector } from "reselect";

const billOfLadingsState = (state) => state.billOfLadings;

export const selectCurrentBillOfLading = createSelector(
  billOfLadingsState,
  (billOfLadings) => billOfLadings?.current?.billOfLading
);

export const selectCurrentBillOfLadingIncluded = createSelector(
  billOfLadingsState,
  (billOfLadings) => billOfLadings?.current?.included
);

export const selectCurrentBillOfLadingOrders = createSelector(selectCurrentBillOfLadingIncluded, (included) =>
  included?.filter((inc) => inc.type === "order")
);

export const selectCurrentBillOfLadingDeliveries = createSelector(selectCurrentBillOfLadingIncluded, (included) =>
  included?.filter((inc) => inc.type === "delivery")
);

export const selectCurrentBillOfLadingDeliveryItems = createSelector(selectCurrentBillOfLadingIncluded, (included) =>
  included?.filter((inc) => inc.type === "deliveryItem")
);

export const selectCurrentBillOfLadingBillOfLadingItems = createSelector(selectCurrentBillOfLadingIncluded, (included) =>
  included?.filter((inc) => inc.type === "billOfLadingItem")
);

export const selectBillOfLadingLoading = createSelector(billOfLadingsState, (billOfLadings) => billOfLadings?.loading);

export const selectAllBillOfLadingItems = createSelector(billOfLadingsState, (billOfLadings) =>
  billOfLadings?.all?.included.filter((included) => included?.type === "billOfLadingItem")
);

export const selectAllBillOfLadings = createSelector(billOfLadingsState, (billOfLadings) => billOfLadings?.all?.items);
