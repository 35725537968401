import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Panel from "@ROM-ui/Panel";
import Button from "@ROM-ui/Button";
import LoadingSplash from "@ROM-components/LoadingSplash/index";

import PageHeader from "@ROM/Orders/components/common/PageHeader";
import OrderForm from "@ROM/Orders/components/Form";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { fetchOrder } from "@ROM/Cart/actions";
import { selectCartOrder, selectCustomer } from "@ROM/Cart/selectors";
import { canUpdateOrder } from "@ROM/Orders/permissions";
import { list as fetchProducts, top as fetchTopProducts } from "@ROM/Products/actions";

const Edit = ({ orderId }) => {
  const [initialized, setInitialized] = useState(false);
  const [orderFetched, setOrderFetched] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const order = useSelector(selectCartOrder);
  const orderCustomer = useSelector(selectCustomer);
  const currentCompany = useSelector(selectCurrentCompany);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    dispatch(fetchOrder(orderId)).then(() => {
      setOrderFetched(true);
    });
  }, [dispatch, orderId]);

  useEffect(() => {
    if (orderCustomer && orderFetched && !initialized) {
      dispatch(fetchProducts());
      dispatch(fetchTopProducts(orderCustomer));
      setInitialized(true);
    }
  }, [dispatch, initialized, orderCustomer, orderFetched]);

  const canUpdate = canUpdateOrder(currentUser, order, currentCompany);

  return (
    <div>
      {initialized && orderCustomer ? (
        <>
          <PageHeader
            customerName={orderCustomer && orderCustomer.attributes && orderCustomer.attributes.name}
            orderInvoice={order.attributes && order.attributes.invoiceNumber}
            orderStatus={order.attributes.status}
            orderCreator={order.attributes.creator}
            orderCreatedAt={order.attributes.createdAt}
          />

          {canUpdate ? (
            <OrderForm order={order} customer={orderCustomer} editing />
          ) : (
            <Panel className="pb-4">
              <Row>
                <Col>
                  <p className="text-secondary">
                    <strong>Error:</strong>
                    This order can&apos;t be edited because it is
                    <strong>{order.attributes.status}</strong>
                  </p>
                  <Button onClick={() => history.goBack()} variant="danger">
                    Go Back
                  </Button>
                </Col>
              </Row>
            </Panel>
          )}
        </>
      ) : (
        <LoadingSplash />
      )}
    </div>
  );
};

Edit.propTypes = {
  orderId: PropTypes.number.isRequired,
};

export default Edit;
