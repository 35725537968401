import { combineReducers } from "redux";
import auth from "@ROM/Auth/reducers";
import addresses from "@ROM/Addresses/reducers";
import customers from "@ROM/Customers/reducers";
import products from "@ROM/Products/reducers";
import categories from "@ROM/Categories/reducers";
import customTables from "@ROM/CustomTables/reducers";
import customTableRows from "@ROM/CustomTableRows/reducers";
import cart from "@ROM/Cart/reducers";
import emailGroups from "@ROM/EmailGroups/reducers";
import orders from "@ROM/Orders/reducers";
import invitations from "@ROM/Invitations/reducers";
import company from "@ROM/Company/reducers";
import companyRoles from "@ROM/CompanyRoles/reducers";
import priceLists from "@ROM/PriceLists/reducers";
import users from "@ROM/Users/reducers";
import regions from "@ROM/Regions/reducers";
import warehouses from "@ROM/Warehouses/reducers";
import warehouseInventories from "@ROM/WarehouseInventories/reducers";
import deliveries from "@ROM/Deliveries/reducers";
import carriers from "@ROM/Carriers/reducers";
import billOfLadings from "@ROM/BillOfLadings/reducers";
import pallets from "@ROM/Pallets/reducers";
import notifications from "@ROM/Notifications/reducers";
import permissions from "@ROM/Permissions/reducers";
import priceListItems from "@ROM/PriceListItems/reducers";
import units from "@ROM/Units/reducers";

export default combineReducers({
  auth,
  addresses,
  customers,
  products,
  cart,
  categories,
  customTables,
  customTableRows,
  orders,
  invitations,
  company,
  companyRoles,
  priceLists,
  users,
  regions,
  warehouses,
  warehouseInventories,
  deliveries,
  carriers,
  billOfLadings,
  pallets,
  emailGroups,
  notifications,
  permissions,
  priceListItems,
  units,
});
