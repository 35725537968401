import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CheckBoxCircle = ({ check, className }) => {
  const iconClass = check ? "fas fa-dot-circle" : "far fa-circle";

  return <Icon className={`${iconClass} ${className}`} $check={check} />;
};

const Icon = styled.div`
  color: ${(props) => (props.$check ? "#70B6EB" : "inherit")};
`;

CheckBoxCircle.defaultProps = {
  check: false,
  className: "",
};

CheckBoxCircle.propTypes = {
  check: PropTypes.bool,
  className: PropTypes.string,
};

export default CheckBoxCircle;
