import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import OrdersApi from "@ROM/Orders/api";
import Balance from "@ROM/Orders/components/Payments/Balance";
import List from "@ROM/Orders/components/Payments/List";
import NewPaymentBtn from "@ROM/Orders/components/Payments/NewPaymentBtn";
import Skeleton from "react-loading-skeleton";

const Payments = ({ order, className, customer, canCharge, canRefund }) => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const fetchPayments = async () => {
    setLoading(true);

    const response = await OrdersApi.transactions(order.id);

    setTransactions(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  const handleChargeRefund = canRefund
    ? async (paymentIntentId, amount) => {
        await OrdersApi.refund(order.id, { paymentIntentId, amount });
        fetchPayments();
      }
    : null;

  const handleChargeOrder = async (amount) => {
    await OrdersApi.charge(order.id, { amount });
    fetchPayments();
  };

  const totalCharged = transactions.reduce((total, transaction) => total + transaction.attributes.amount, 0);
  const totalRefunded = transactions.reduce(
    (total, transaction) => total + transaction.attributes.refunds.reduce((totalRef, refund) => totalRef + refund.amount, 0),
    0
  );

  return (
    <div className={`bg-white rounded p-3 ${className}`}>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h5 className="mb-3">Payments</h5>
        {canCharge && <NewPaymentBtn chargeOrder={handleChargeOrder} size="sm" />}
      </div>
      {loading ? (
        <Skeleton count={3} height={40} />
      ) : (
        <>
          {transactions.length === 0 ? (
            <div>No payments yet for this order.</div>
          ) : (
            <>
              <List transactions={transactions} onChargeRefund={handleChargeRefund} className="mb-3" customer={customer} />
              <Balance
                totalCharged={totalCharged}
                totalRefunded={totalRefunded}
                orderTotal={order.attributes.totalCents}
                className="text-end"
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

Payments.defaultProps = {
  className: "",
};

Payments.propTypes = {
  order: PropTypes.shape().isRequired,
  customer: PropTypes.shape().isRequired,
  canCharge: PropTypes.bool.isRequired,
  canRefund: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Payments;
