import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/bill_of_ladings";
  },

  resource(id) {
    return `/bill_of_ladings/${id}`;
  },

  sendPdf() {
    return "/bill_of_ladings/send_pdf";
  },

  downloadPdf(orderId, bolId, withDollarAmount) {
    return `/bill_of_ladings/${bolId}/pdf?order_id=${orderId}&with_dollar_amount=${withDollarAmount}`;
  },
};

export default {
  list(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(), {
      params: {
        ...options,
      },
    });
  },

  find(id) {
    const client = getApiClient(API_URL);
    return client.get(urls.resource(id));
  },

  create(billOfLading) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(), billOfLading);
  },

  update(id, billOfLading) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(id), billOfLading);
  },

  destroy(id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(id));
  },

  emailPdf(orderId, deliveryId, billOfLadingId, userIds, senderId, withDollarAmount) {
    const client = getApiClient(API_URL);
    return client.post(urls.sendPdf(), orderId, deliveryId, billOfLadingId, userIds, senderId, withDollarAmount);
  },

  downloadPdfUrl(orderId, billOfLadingId, withDollarAmount) {
    return `${API_URL}${urls.downloadPdf(orderId, billOfLadingId, withDollarAmount)}`;
  },

  downloadPdfLabel(orderId, billOfLadingId, withDollarAmount) {
    return `${API_URL}${urls.downloadPdf(orderId, billOfLadingId, withDollarAmount)}`;
  },

  downloadPdf(orderId, billOfLadingId, withDollarAmount) {
    const client = getApiClient(API_URL);
    return client.get(urls.downloadPdf(orderId, billOfLadingId, withDollarAmount));
  },
};
