import { createSelector } from "reselect";

const priceListItemsState = (state) => state.priceListItems;

export const selectPriceListItems = createSelector(priceListItemsState, (priceListItems) => priceListItems.all.items);

export const selectPriceListItemsPagination = createSelector(
  priceListItemsState,
  (priceListItems) => priceListItems.pagination
);
