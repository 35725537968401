import { checkPermission } from "@ROM/Permissions/utils";
import { isOwner } from "@ROM/Orders/utils";

export const canSeeDeliveries = (user) => checkPermission(user, "company:employee");

export const canSeeOrderDeliveries = (user, order) =>
  isOwner(order, user) || checkPermission(user, "deliveries:index", order?.attributes.customerId);

export const canCreateDeliveries = (user) => checkPermission(user, "deliveries:create");

export const canEditDelivery = (user, customerId) => checkPermission(user, "deliveries:update", customerId);

export const canUpdateDeliveryShipping = (user, customerId) => checkPermission(user, "deliveries:update-shipping", customerId);
