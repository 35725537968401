import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import Modal from "@ROM-components/common/Modal";
import Message from "@ROM-common/Message/index";
import Button from "@ROM-ui/Button";
import { useHistory } from "react-router-dom";

import { selectCurrentWarehouse } from "@ROM/Warehouses/selectors";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canEditWarehouses, canDeleteWarehouses } from "@ROM/Warehouses/permissions";
import { find, destroy } from "@ROM/Warehouses/actions";
import Section from "@ROM-components/common/Section";
import WarehouseForm from "@ROM/Warehouses/components/Form";
import { warehousesBreadcrumbs } from "@ROM/CompanyAdmin/utils";
import { companyAdminWarehousesUrl, homeUrl } from "@ROM-utils/urlHelpers";

const Page = ({ warehouseId }) => {
  const currentWarehouse = useSelector(selectCurrentWarehouse);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (warehouseId) {
      dispatch(find(warehouseId));
    }
  }, [warehouseId]);
  const [error, setError] = useState(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const handleDeleteWarehouse = async () => {
    try {
      await dispatch(destroy(currentWarehouse.id));
      history.push(companyAdminWarehousesUrl());
    } catch (failure) {
      setError(failure);
    } finally {
      window.scroll(0, 0);
    }
  };

  const breadcrumbs = warehousesBreadcrumbs([
    { label: currentWarehouse?.attributes.name || "...", active: true },
    { label: "Edit", active: true },
  ]);

  if (!canEditWarehouses(currentUser)) {
    return <Redirect to={homeUrl()} />;
  }

  return (
    <>
      {error && <Message message={error.detail} type="danger" />}
      <Section title="Edit Warehouse" breadcrumbs={breadcrumbs} containerStyle={{ maxWidth: "650px" }}>
        <WarehouseForm currentWarehouse={currentWarehouse} isEdit />
      </Section>

      {canDeleteWarehouses(currentUser) && (
        <Section title="Delete" containerStyle={{ maxWidth: "650px" }}>
          <div className="text-end mt-3">
            <Button onClick={() => setShowDeleteConfirmationModal(true)} variant="danger" id="delete-warehouse-btn">
              Delete Warehouse
            </Button>
          </div>
          <Modal
            isShowing={showDeleteConfirmationModal}
            onConfirm={handleDeleteWarehouse}
            onCancel={() => setShowDeleteConfirmationModal(false)}
            header="Are you sure you want to delete this warehouse?"
            showCloseIcon={false}
          />
        </Section>
      )}
    </>
  );
};

Page.propTypes = {
  warehouseId: PropTypes.number.isRequired,
};

export default Page;
