/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

import ModalPrimary from "./ModalPrimary";
import ModalSecondary from "./ModalSecondary";

const Modal = ({ secondary, ...restProps }) => {
  return secondary ? <ModalSecondary {...restProps} /> : <ModalPrimary {...restProps} />;
};

Modal.defaultProps = {
  secondary: false,
};

Modal.propTypes = {
  secondary: PropTypes.bool,
};

export default Modal;
