import { apiAction, standardAction } from "@ROM-utils/actions";
import ProductApi from "./api";

export const LIST = "products/api/LIST";
export const LIST_TOP = "products/api/LIST_TOP";
export const LIST_FEATURED = "products/api/LIST_FEATURED";
export const FIND = "products/api/FIND";
export const DESTROY = "products/api/DESTROY";
export const SET_PAGE = "products/api/SET_PAGE";
export const LIST_WITH_SCROLL = "products/api/LIST_WITH_SCROLL";
export const UNSET_CURRENT = "products/api/UNSET_CURRENT";

export function list(options = {}) {
  return apiAction(LIST, ProductApi.list(options));
}

export function top(customer) {
  return apiAction(
    LIST_TOP,
    ProductApi.top({
      customerId: customer.id,
    })
  );
}

export function featured(options = {}) {
  return apiAction(LIST_FEATURED, ProductApi.featured(options));
}

export function find(id) {
  return apiAction(FIND, ProductApi.find(id));
}

export function destroy(id) {
  return apiAction(DESTROY, ProductApi.destroy(id), { productId: id });
}

export function setPage(page) {
  return standardAction(SET_PAGE, { page });
}

export function unsetCurrentProduct() {
  return standardAction(UNSET_CURRENT);
}

export function getFilteredProducts(filters, options = {}) {
  return list({
    ...filters,
    ...options,
  });
}

export function getProductsWithScroll(options = {}) {
  return apiAction(LIST_WITH_SCROLL, ProductApi.list(options));
}
