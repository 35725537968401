import WarehousesApi from "@ROM/Warehouses/api";

export async function loadOptions(name, _loadedOptions, { page }) {
  const result = await WarehousesApi.list({ name, page });
  const options = result.data.data.map((option) => {
    return {
      value: option.id,
      label: option.attributes.name,
    };
  });
  return {
    options,
    hasMore: result.data.meta.pagination.currentPage < result.data.meta.pagination.totalPages,
    additional: {
      page: page + 1,
    },
  };
}
