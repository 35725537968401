import React from "react";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";

const PalletItemHeaders = () => (
  <>
    <Row className="m-0 align-items-center w-100 d-none d-md-flex">
      <Col md={6}>
        <h5 className="m-0">Pallets</h5>
        <p className="m-0">Qty</p>
      </Col>
      <Col md={2} className="text-md-center">
        Product Units
      </Col>
      <Col md={2} className="text-md-center">
        Shipping Weight
      </Col>
      <Col md={2} />
    </Row>
    <Row className="d-md-none m-0">
      <Col xs={6}>Pallets</Col>
    </Row>
  </>
);

export default PalletItemHeaders;
