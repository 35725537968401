import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection(id) {
    return `/users/${id}`;
  },
};

export default {
  update(attributes, id) {
    const client = getApiClient(API_URL);
    return client.put(urls.collection(id), attributes);
  },
};
