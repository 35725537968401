import React from "react";

import { useSelector } from "react-redux";
import Button from "@ROM-ui/Button";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import Logo from "@ROM-components/common/Logo";
import { useHistory } from "react-router-dom";
import { homeUrl } from "@ROM-utils/urlHelpers";

import styled from "styled-components";

const Sidebar = ({ children }) => {
  const currentCompany = useSelector(selectCurrentCompany);
  const history = useHistory();

  return (
    <StyledSidebar>
      <Logo currentCompany={currentCompany} className="my-3" />
      <Button variant="link" className="p-0" onClick={() => history.push(homeUrl())}>
        <i className="fa fa-chevron-left me-2" />
        Back to Dashboard
      </Button>
      {children}
    </StyledSidebar>
  );
};

const SidebarItem = ({ children, ...props }) => {
  const currentCompany = useSelector(selectCurrentCompany);
  const {
    props: { name },
  } = children;

  return (
    <StyledItem
      isActive={window.location.href.includes(`company-admin/${name}`)}
      $accentColor={currentCompany.attributes.accentColor}
      {...props}
    >
      {children}
    </StyledItem>
  );
};

const SidebarSubitem = ({ children, redirectTo }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(redirectTo);
    window.scroll(0, 0);
  };

  return (
    <StyledSubitem onClick={handleClick}>
      <i className="fas fa-circle" />
      {children}
    </StyledSubitem>
  );
};

export { Sidebar, SidebarItem, SidebarSubitem };

const StyledItem = styled.div`
  cursor: pointer;
  margin: 1rem;
  .nav-link {
    color: #6c757d !important;
    padding: 0.5rem;
    color: ${(props) => (props.isActive ? "white !important" : "")};
    i {
      color: ${(props) => (props.isActive ? "white !important" : "")};
    }
  }
  border-radius: 0.5rem;
  background-color: ${(props) => (props.isActive ? props.$accentColor : "")};
`;

const StyledSubitem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 2rem;
  padding: 0 0.5rem;
  color: #6c757d;
  border-radius: 5px;

  &:hover {
    background-color: #dcdcdc;
    transition: all 0.5s;
  }

  i {
    margin-top: 1px;
    font-size: 6px;
    color: #6c757d;
    margin-right: 0.5rem;
  }
`;

const StyledSidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  background-color: #f5f5f5;
`;
