import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { cleanDecimals } from "@ROM/App/utils/format";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";

import { ifPositive } from "@ROM-utils/format";

/* eslint-disable react/jsx-props-no-spreading */

const ItemForm = ({
  palletId,
  name,
  onNameChange,
  count,
  onCountChange,
  weight,
  onWeightChange,
  isLocked,
  isDragging,
  showWeightWarning,
  showDragHandle,
  dragProvided,
  notes,
}) => (
  <StyledRow
    $isDragging={isDragging}
    $showWeightWarning={showWeightWarning}
    className="ms-2 text-secondary p-3 align-items-center"
    ref={dragProvided?.innerRef}
    {...(dragProvided?.draggableProps || {})}
  >
    <Col xs={10} md={5}>
      {onNameChange ? (
        <input
          id={`pallet-item-name-${palletId}`}
          value={name}
          className="form-control"
          onChange={(e) => onNameChange(e.target.value)}
          readOnly={isLocked}
        />
      ) : (
        <strong>{name}</strong>
      )}
    </Col>
    <Col xs={6} md={3} className="text-md-right m-0 p-0 order-3 order-md-2">
      {count && (
        <StyledInput
          type="number"
          id={`pallet-item-count-${palletId}`}
          value={count}
          className="form-control"
          onChange={(e) => onCountChange(e.target.value)}
          min="0"
          readOnly={isLocked || !onCountChange}
        />
      )}
    </Col>
    <Col xs={6} md={3} className="text-md-center pe-0 order-4 order-md-3">
      {onWeightChange ? (
        <input
          type="number"
          id={`pallet-item-weight-${palletId}`}
          value={weight}
          className="form-control"
          onChange={(e) => ifPositive(onWeightChange)(e.target.value)}
          min="0"
          readOnly={isLocked}
        />
      ) : (
        `${cleanDecimals(weight)} lbs`
      )}
    </Col>
    <Col xs={2} md={1} className="p-0 text-end order-2 order-md-4">
      {showDragHandle && <i className="fas fa-ellipsis-v me-3" {...dragProvided.dragHandleProps} />}
    </Col>
    {notes && (
      <Col xs={12} className="pt-2 order-5">
        {`Notes: ${notes}`}
      </Col>
    )}
  </StyledRow>
);

ItemForm.propTypes = {
  name: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  count: PropTypes.number,
  onCountChange: PropTypes.func.isRequired,
  weight: PropTypes.number.isRequired,
  onWeightChange: PropTypes.func.isRequired,
  showDragHandle: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  palletId: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired,
  showWeightWarning: PropTypes.bool.isRequired,
  dragProvided: PropTypes.shape().isRequired,
  notes: PropTypes.string,
};

ItemForm.defaultProps = {
  notes: null,
  count: null,
};

export default ItemForm;

const StyledRow = styled(Row)`
  min-height: 50px;
  background-color: ${(props) => (props.$showWeightWarning ? "#f9caca" : "#f5f5f5")};
  border-radius: ${(props) => (props.$isDragging ? "5px" : "0")};
  border-right: ${(props) => (props.$showWeightWarning ? "3px solid red" : "")};
  border-left: ${(props) => (props.$showWeightWarning ? "3px solid red" : "")};
  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: ${(props) => (props.$showWeightWarning ? "3px solid red" : "")};
  }
  :first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: ${(props) => (props.$showWeightWarning ? "3px solid red" : "")};
  }
`;

const StyledInput = styled.input`
  width: 80%;
  margin-left: 10px;
`;
