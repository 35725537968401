/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { deleteCategory } from "@ROM/Categories/actions";
import { canDeleteCategory, canCreateSubcategory } from "@ROM/Categories/permissions";
import Subcategory from "@ROM/Categories/components/Subcategory";
import AddNewSubcategory from "@ROM/Categories/components/AddNewSubcategory";
import SubcategoriesEmpty from "@ROM/Categories/components/SubcategoriesEmpty";
import Modal from "@ROM-components/common/Modal";

const Category = ({ category, subcategories, showSubcategories, showSubcategoriesHandler }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  const [showDestroyConfirmationModal, setShowDestroyConfirmationModal] = useState(false);

  const destroyCategory = () => {
    dispatch(deleteCategory(category.id));
  };

  return (
    <>
      <Container>
        <div className="category">
          <div className="title-container" onClick={() => showSubcategoriesHandler(category.id)}>
            <h4 className="title">{category.attributes.name}</h4>
            {showSubcategories ? <i className="fas fa-sort-up mt-1" /> : <i className="fas fa-sort-down mt-n2" />}
          </div>
          {canDeleteCategory(currentUser) && (
            <i
              role="button"
              aria-label="Destroy category"
              tabIndex={0}
              className="fas fa-trash cursor-pointer text-danger"
              onClick={() => setShowDestroyConfirmationModal(true)}
            />
          )}
        </div>

        {showSubcategories && (
          <div className="subcategories">
            <h5 className="title">Subcategories</h5>
            <div className="list">
              {subcategories.length === 0 ? (
                <SubcategoriesEmpty />
              ) : (
                subcategories.map((subcategory) => (
                  <Subcategory key={subcategory.id} categoryId={category.id} subcategory={subcategory} />
                ))
              )}
              {canCreateSubcategory(currentUser) && <AddNewSubcategory categoryId={category.id} />}
            </div>
          </div>
        )}
      </Container>
      <Modal
        isShowing={showDestroyConfirmationModal}
        onConfirm={destroyCategory}
        onCancel={() => setShowDestroyConfirmationModal(false)}
        header="Are you sure you want to delete this category and subcategories?"
        showCloseIcon={false}
      />
    </>
  );
};

const Container = styled.div`
  background-color: #f3f4f6;
  color: #1f2937;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;

  .category {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-container {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .title {
      font-size: 1.25rem;
      margin-right: 0.25rem;
    }
  }

  .subcategories {
    padding-left: 1rem;

    .title {
      font-size: 1rem;
      color: #9ca3af;
    }

    .list {
      margin: 0rem 1rem;
    }
  }
`;

Category.propTypes = {
  category: PropTypes.shape.isRequired,
  subcategories: PropTypes.arrayOf(PropTypes.shape).isRequired,
  showSubcategories: PropTypes.bool.isRequired,
  showSubcategoriesHandler: PropTypes.func.isRequired,
};

export default Category;
