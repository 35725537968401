import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/email_groups";
  },

  resource(id) {
    return `/email_groups/${id}`;
  },
};

export default {
  fetch() {
    const client = getApiClient(API_URL);
    return client.get(urls.collection());
  },

  create(attributes) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(), attributes);
  },

  update(groupId, attributes) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(groupId), attributes);
  },

  destroy(groupId) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(groupId));
  },
};
