import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { ActiveBadge, DefaultBadge } from "@ROM-ui/Badge";
import NumberFormater from "@ROM-ui/NumberFormater";

const PriceListItemRow = ({ priceListItem, priceLists }) => {
  moment.locale("en-us");

  const currentPriceList = priceLists.find((priceList) => priceList.id === priceListItem.relationships.priceList.data.id);

  return (
    <tr>
      <td>{currentPriceList.attributes.name}</td>
      <td>
        <ActiveBadge
          label={currentPriceList.attributes.status}
          isActive={currentPriceList.attributes.status === "active"}
          className="me-2"
        />
        {currentPriceList.attributes.isDefaultPriceList && <DefaultBadge />}
      </td>
      <td className="text-muted">
        {currentPriceList.attributes.dateFrom ? moment(currentPriceList.attributes.dateFrom).format("MMM DD, YYYY") : "N/A"}
      </td>
      <td className="text-muted">
        {currentPriceList.attributes.dateTo ? moment(currentPriceList.attributes.dateTo).format("MMM DD, YYYY") : "N/A"}
      </td>
      <td>
        <NumberFormater value={priceListItem.attributes.unitPriceCents} />
      </td>
    </tr>
  );
};

PriceListItemRow.propTypes = {
  priceListItem: PropTypes.shape().isRequired,
  priceLists: PropTypes.arrayOf().isRequired,
};

export default PriceListItemRow;
