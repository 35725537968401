import React from "react";
import NumberFormater from "@ROM-ui/NumberFormater";
import PropTypes from "prop-types";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import productPlaceholder from "@ROM-images/productPlaceholder";

const OrderItemDetail = ({ item }) => {
  const getProductImage = () => {
    return item.attributes.primaryImage ? item.attributes.primaryImage : productPlaceholder;
  };
  return (
    <Row className="mt-3 mb-3">
      <Col className="col-md-6">
        <img src={getProductImage()} width="200px" alt={item.attributes.productName} />
      </Col>
      <Col className="col-md-6">
        <Row>
          <Col>
            <strong>Product Name</strong>
            <p className="m-0">{item.attributes.productName}</p>
          </Col>
          <Col>
            <strong>Unit Price</strong>
            <NumberFormater className="mx-0 d-block" value={item.attributes.productPriceCents} />
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Units Per Package</strong>
            <p className="m-0">{item.attributes.productUnitsPerPackage}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Description</strong>
            <p>{item.attributes.description}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

OrderItemDetail.propTypes = {
  item: PropTypes.object.isRequired,
};

export default OrderItemDetail;
