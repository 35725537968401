import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import useIsMobile from "@ROM-components/utils/Responsive";
import OrderDetail from "@ROM/Orders/components/Detail";
import ActionSection from "@ROM/Orders/components/Detail/ActionSection";
import IconMenu from "@ROM/Orders/components/Detail/IconMenu";

import Row from "@ROM-ui/Row";
import Button from "@ROM-ui/Button";
import LoadingSplash from "@ROM-components/LoadingSplash/index";
import PageHeader from "@ROM/Orders/components/common/PageHeader";
import StatusesModal from "@ROM/Orders/components/StatusesModal";

import { canSeeOrderDeliveries } from "@ROM/Deliveries/permissions";
import { currentCompany } from "@ROM/Company/actions";
import { list } from "@ROM/Deliveries/actions";
import { find } from "@ROM/Orders/actions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectCurrentOrder, selectCurrentIncluded, selectCurrentOrderCropAdvisor } from "@ROM/Orders/selectors";
import { ordersUrl } from "@ROM-utils/urlHelpers";

const Show = ({ orderId }) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const order = useSelector(selectCurrentOrder);
  const included = useSelector(selectCurrentIncluded);
  const associatedCropAdvisor = useSelector(selectCurrentOrderCropAdvisor);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const openedByLink = window.location.search.split("?open=")[1];
  const [showStatusesModal, setShowStatusesModal] = useState(!!openedByLink);

  const loadOrder = useCallback(() => {
    setLoading(true);
    dispatch(find(orderId)).then(() => {
      dispatch(currentCompany()).then(() => {
        setLoading(false);
      });
    });
  }, [dispatch, orderId]);

  useEffect(() => {
    loadOrder();
  }, []);

  useEffect(() => {
    if (order && canSeeOrderDeliveries(currentUser, order)) {
      dispatch(list({ orderId: order.id }));
    }
  }, [dispatch, order]);

  const customer = included.find((incl) => incl.type === "customer");

  const orderItems = included.filter((incl) => incl.type === "orderItem");

  const currentOrderAssets = included.filter((incl) => incl.type === "asset");

  const orderStatuses = included.filter((incl) => incl.type === "orderStatus");

  return (
    <>
      {loading ? (
        <LoadingSplash />
      ) : (
        <>
          <PageHeader
            customerName={customer?.attributes?.name}
            orderInvoice={order?.attributes?.invoiceNumber}
            orderStatus={order?.attributes?.status}
            orderCreator={order?.attributes?.creator}
            orderCreatedAt={order?.attributes?.createdAt}
            actionSection={<ActionSection order={order} reloadOrder={loadOrder} />}
            iconsMenu={<IconMenu order={order} setError={setError} setShowStatusesModal={setShowStatusesModal} />}
          />

          <StatusesModal
            show={showStatusesModal}
            onCancel={() => setShowStatusesModal(false)}
            orderStatuses={orderStatuses}
            orderId={order.id}
          />

          {error && <p className="text-danger mt-2">Something went wrong, please try again later.</p>}

          <OrderDetail
            order={order}
            orderItems={orderItems}
            orderAssets={currentOrderAssets}
            customer={customer}
            orderIncluded={included}
            associatedCropAdvisor={associatedCropAdvisor}
            isMobile={isMobile}
          />

          <Row className={isMobile ? "bg-white py-5" : "py-5"}>
            <div className="px-3 my-2 w-100 d-flex justify-content-end">
              <Button variant="danger" onClick={() => history.push(ordersUrl())}>
                Back
              </Button>
            </div>
          </Row>
        </>
      )}
    </>
  );
};

Show.propTypes = {
  orderId: PropTypes.number.isRequired,
};

export default Show;
