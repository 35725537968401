import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { numberFormat } from "@ROM/App/utils/format";
import NumberFormater from "@ROM-ui/NumberFormater";

const ValueFormat = ({ value, isCurrency }) => {
  return isCurrency ? <NumberFormater value={value} className="text-nowrap" /> : numberFormat(value);
};

ValueFormat.propTypes = {
  value: PropTypes.number.isRequired,
  isCurrency: PropTypes.bool.isRequired,
};

const NumberExtraInfo = ({ value, extra, extraValue, isCurrency }) => (
  <div className="position-relative d-flex justify-content-end align-items-center py-3">
    <div className="position-relative">
      <ValueFormat value={value} isCurrency={isCurrency} />
    </div>
    {extra && <StyledBottomDiv className="position-absolute text-nowrap text-secondary small">{extra}</StyledBottomDiv>}
    {extraValue !== null && (
      <StyledBottomDiv className="position-absolute text-secondary small">
        <ValueFormat value={extraValue} isCurrency={isCurrency} />
      </StyledBottomDiv>
    )}
  </div>
);

NumberExtraInfo.propTypes = {
  value: PropTypes.number.isRequired,
  extra: PropTypes.string,
  extraValue: PropTypes.number,
  isCurrency: PropTypes.bool,
};

NumberExtraInfo.defaultProps = {
  extra: null,
  extraValue: null,
  isCurrency: true,
};

const StyledBottomDiv = styled.div`
  bottom: 0;
`;

export default NumberExtraInfo;
