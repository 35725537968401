import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import SortableStrongPointer from "@ROM-ui/SortableStrongPointer";

import DeliveryListRow from "./DeliveryListRow";

const List = ({ deliveries, showDeliveryDetailsForId, deliveryItems, deliveryAssets, orders, customers, warehouses }) => {
  const CellHeader = ({ children }) => (
    <th className="text-nowrap">
      <SortableStrongPointer>{children}</SortableStrongPointer>
    </th>
  );
  CellHeader.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const [showDetailsForId, setShowDetailsForId] = useState(showDeliveryDetailsForId);
  const toggler = (deliveryId) => setShowDetailsForId(deliveryId === showDetailsForId ? null : deliveryId);

  return (
    <div className="table-responsive p-3 ">
      <StyledTable className="table table-borderless table-sm text-black-50 small">
        <thead>
          <tr>
            <CellHeader>Status</CellHeader>
            <CellHeader>PO #</CellHeader>
            <CellHeader>Delivery #</CellHeader>
            <CellHeader>Customer</CellHeader>
            <CellHeader className="justify-content-center">Items</CellHeader>
            <CellHeader>Warehouse</CellHeader>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {deliveries.map((delivery) => (
            <DeliveryListRow
              delivery={delivery}
              deliveryItems={deliveryItems}
              deliveryAssets={deliveryAssets}
              orders={orders}
              customers={customers}
              warehouses={warehouses}
              key={delivery.id}
              showDetails={showDetailsForId === delivery.id}
              setShowDetails={() => toggler(delivery.id)}
            />
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 5px;
`;

List.defaultProps = {
  showDeliveryDetailsForId: null,
};

List.propTypes = {
  showDeliveryDetailsForId: PropTypes.string,
  deliveries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  deliveryItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  deliveryAssets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  warehouses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default List;
