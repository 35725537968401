import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import LoadingSplash from "@ROM-components/LoadingSplash/index";
import PageHeader from "@ROM/Orders/components/common/PageHeader";
import OrderForm from "@ROM/Orders/components/Form";

import { updateUserOrders } from "@ROM/Auth/actions";
import { getAddressesWithScroll as fetchAddresses, BILLING_TYPE, SHIPPING_TYPE } from "@ROM/Addresses/actions";
import { fetchCart } from "@ROM/Cart/actions";
import { selectCartOrder } from "@ROM/Cart/selectors";
import { currentCompany as fetchCurrentCompany } from "@ROM/Company/actions";
import { top as fetchTopProducts } from "@ROM/Products/actions";

const New = ({ customer }) => {
  const [initialized, setInitialized] = useState(false);

  const dispatch = useDispatch();
  const order = useSelector(selectCartOrder);

  useEffect(() => {
    dispatch(fetchCart(customer)).then((response) => {
      if (response.payload.status === 200) {
        dispatch(updateUserOrders(response.payload.data.data.id));
      }
      dispatch(fetchAddresses({ customerId: customer.id, type: BILLING_TYPE }));
      dispatch(fetchAddresses({ customerId: customer.id, type: SHIPPING_TYPE }));
      dispatch(fetchTopProducts(customer));
      dispatch(fetchCurrentCompany());
      setInitialized(true);
    });
  }, [dispatch, customer]);

  return (
    <div>
      {initialized ? (
        <>
          <PageHeader
            customerName={customer.attributes.name}
            orderStatus={order.attributes.status}
            orderInvoice={order.attributes && order.attributes.invoiceNumber}
            orderCreatedAt={order.attributes.createdAt}
          />
          <OrderForm order={order} customer={customer} />
        </>
      ) : (
        <LoadingSplash />
      )}
    </div>
  );
};

New.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
export default New;
