import React, { useState } from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canListInvitations } from "@ROM/Invitations/permissions";
import { canListUsers } from "@ROM/Users/permissions";
import Section from "@ROM-components/common/Section";
import { Tabs, TabItem } from "@ROM-components/common/AdminTabs";
import InvitationsTable from "@ROM/Users/components/Invitations/InvitationsTable";
import UsersTable from "@ROM/CompanyAdmin/Users/Index";
import EmailGroupsTable from "@ROM/CompanyAdmin/EmailGroups/Index";

const USERS = "users";
const INVITATIONS = "invitations";
const EMAIL_GROUPS = "emailGroups";

const CompanyAdminUsers = () => {
  const currentUser = useSelector(selectCurrentUser);
  const currentUserCanListUsers = canListUsers(currentUser);
  const currentUserCanListInvitations = canListInvitations(currentUser);
  const currentUserCanListEmailGroups = true;

  const initialTab = () => {
    if (currentUserCanListUsers) {
      return USERS;
    }

    if (currentUserCanListInvitations) {
      return INVITATIONS;
    }

    if (currentUserCanListEmailGroups) {
      return EMAIL_GROUPS;
    }

    return null;
  };

  const [tab, setTab] = useState(initialTab());

  const getContent = (contentFor) => {
    switch (contentFor) {
      case USERS:
        return <UsersTable />;
      case INVITATIONS:
        return <InvitationsTable />;
      case EMAIL_GROUPS:
        return <EmailGroupsTable />;
      default:
        return null;
    }
  };

  return (
    <Section title="Users">
      <Tabs>
        {currentUserCanListUsers && (
          <TabItem value="Users" iconClasses="fa fa-users" name={USERS} activeName={tab} setActiveName={setTab} />
        )}
        {currentUserCanListInvitations && (
          <TabItem
            value="Invitations"
            iconClasses="fa fa-user-friends"
            name={INVITATIONS}
            activeName={tab}
            setActiveName={setTab}
          />
        )}
        {currentUserCanListEmailGroups && (
          <TabItem value="Email Groups" iconClasses="fa fa-users" name={EMAIL_GROUPS} activeName={tab} setActiveName={setTab} />
        )}
      </Tabs>

      {getContent(tab)}
    </Section>
  );
};

export default CompanyAdminUsers;
