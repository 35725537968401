import React, { useState, useReducer } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";
import Select from "@ROM-components/common/Select";
import Message from "@ROM-common/Message";
import api from "@ROM/Invitations/api";
import { emailValid } from "@ROM-utils/email";
import { reducer } from "@ROM/App/utils/forms";
import Modal from "@ROM-components/common/Modal";
import { loadOptions } from "@ROM/Customers/utils";
import { canEditCompanyRolesFromUsers } from "@ROM/Users/permissions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import AsyncPaginate from "@ROM-components/common/AsyncPaginate";

const initialInvitation = {
  fullName: "",
  email: "",
  role: "",
};

const CreateInvitation = ({
  customerId,
  updateInvitations,
  show,
  onCancel,
  callback,
  customerRoles,
  companyRoles,
  fromCompanyAdmin,
}) => {
  const currentUser = useSelector(selectCurrentUser);

  const [user, setUser] = useReducer(reducer, initialInvitation);

  const [error, setError] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState({ value: null, label: null });

  const companyRolesOption = companyRoles.map((role) => ({ label: role, value: role }));
  const customerRolesOption = customerRoles.map((role) => ({ label: role, value: role }));

  const canSeeCompanyRoles = fromCompanyAdmin && canEditCompanyRolesFromUsers(currentUser);

  let options = customerRolesOption;

  if (canSeeCompanyRoles) {
    options = [
      {
        label: "By Company",
        options: companyRolesOption,
      },
      {
        label: "By Customer",
        options: customerRolesOption,
      },
    ];
  }

  const haveInvalidEmail = () => {
    if (user.email !== "" && !emailValid(user.email)) {
      setError("Invalid Email");
      return true;
    }
    return false;
  };

  const isIncomplete = () => {
    if (user.email === "" || user.fullName === "" || user.role === null) {
      setError("All fields are required");
      return true;
    }
    return false;
  };

  const sendInvitation = () => {
    const invalid = haveInvalidEmail() || isIncomplete();
    if (!invalid) {
      api
        .create({ users: [user], customerId: customerId || selectedCustomer?.value })
        .then(() => {
          setError(null);
          updateInvitations();
          setUser(initialInvitation);
          if (callback) callback();
          onCancel();
        })
        .catch((response) => {
          setError(response.data.detail);
        });
    }
  };

  return (
    <Modal isShowing={show} onCancel={onCancel} header="Add new Invitation" onConfirm={sendInvitation}>
      <Row className="p-2 align-items-center">
        <Col>
          <Select
            placeholder="Role"
            options={options}
            onChange={(event) => setUser({ role: event.value })}
            value={options.find((option) => option.value === user.role)}
          />
        </Col>
      </Row>
      {!customerId && (!canSeeCompanyRoles || customerRoles.includes(user.role)) && (
        <Row className="p-2 align-items-center">
          <Col>
            <AsyncPaginate
              value={selectedCustomer.value ? selectedCustomer : null}
              loadOptions={loadOptions}
              onChange={(event) => setSelectedCustomer(event)}
              placeholder="Customer"
              additional={{
                page: 1,
              }}
            />
          </Col>
        </Row>
      )}
      <Row className="p-2 align-items-center">
        <Col>
          <Form.Control
            type="text"
            value={user.fullName || ""}
            id="customer-edit-invitation-name"
            onChange={(event) => setUser({ fullName: event.target.value })}
            placeholder="Full Name"
          />
        </Col>
      </Row>
      <Row className="p-2 align-items-center">
        <Col>
          <Form.Control
            type="email"
            value={user.email || ""}
            id="customer-edit-invitation-email"
            placeholder="Email"
            onChange={(event) => setUser({ email: event.target.value })}
          />
        </Col>
      </Row>
      {error && (
        <Row>
          <Col>
            <Message message={error} type="danger" />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

CreateInvitation.defaultProps = {
  fromCompanyAdmin: false,
};

CreateInvitation.propTypes = {
  customerId: PropTypes.string.isRequired,
  updateInvitations: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  customerRoles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companyRoles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fromCompanyAdmin: PropTypes.bool,
};

export default CreateInvitation;
