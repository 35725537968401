import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { canFilterUsersByRegion } from "@ROM/Users/permissions";
import { selectCurrentUser, selectCompanyRoles } from "@ROM/Auth/selectors";
import { selectRegions as selectRegionsSelector } from "@ROM/Regions/selectors";
import { regionsForSelect } from "@ROM/Regions/utils";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";
import Select from "@ROM-components/common/Select";
import Button from "@ROM-ui/Button";

const Filters = ({ handleFilterChange, handleClearFilters, filters }) => {
  const currentUser = useSelector(selectCurrentUser);
  const regions = useSelector(selectRegionsSelector);
  const companyRoles = useSelector(selectCompanyRoles);

  const selectRegions = [{ value: undefined, label: "All regions" }, ...regionsForSelect(regions)];
  const selectRoles = companyRoles.map((role) => ({ value: role, label: role }));

  const handleRoleSelectChange = (event) => {
    let newValue = [];

    if (event) {
      newValue = event.map((role) => role.value);
    }

    handleFilterChange("roles", newValue);
  };

  return (
    <div>
      <Row className="mx-n1 align-items-center">
        <Col className="px-1" xs={12} md={4}>
          <Form.Control
            type="text"
            name="fullName"
            placeholder="Full Name"
            onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
            value={filters.fullName || ""}
            className="mb-2"
          />
        </Col>
        <Col className="px-1" xs={12} md={4}>
          <Form.Control
            type="text"
            name="email"
            placeholder="Email"
            onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
            value={filters.email || ""}
            className="mb-2"
          />
        </Col>
        {canFilterUsersByRegion(currentUser) && (
          <Col className="px-1" xs={12} md={4}>
            <Select
              placeholder="Region"
              options={selectRegions}
              onChange={(e) => handleFilterChange("regionId", e.value)}
              value={selectRegions.find((option) => option.value === filters.regionId)}
              className="mb-2"
            />
          </Col>
        )}
        <Col className="px-1" xs={12} md={4}>
          <Select
            isMulti
            placeholder="Roles"
            options={selectRoles}
            onChange={handleRoleSelectChange}
            value={selectRoles.filter((role) => filters.roles.includes(role.value))}
            className="mb-2"
          />
        </Col>
        <Col className="px-1" xs={12} md={4}>
          <Button className="text-nowrap p-0" size="sm" variant="link" onClick={handleClearFilters}>
            Clear Filters
          </Button>
        </Col>
      </Row>
    </div>
  );
};

Filters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};

export default Filters;
