import React from "react";
import { Route } from "react-router-dom";

import Page from "@ROM-pages/Auth/Invitations";

class RouteHandler extends Route {
  render() {
    return <Page customerId={this.props.computedMatch.params.customerId} />;
  }
}

export default RouteHandler;
