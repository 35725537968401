/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Badge from "@ROM-ui/Badge";
import Col from "@ROM-ui/Col";
import Row from "@ROM-ui/Row";
import Form from "@ROM-ui/Form";
import Spinner from "@ROM-ui/Spinner";
import IconPointer from "@ROM-ui/IconPointer";

import OrderItemModal from "@ROM/Orders/components/OrderItems/OrderItemModal";
import NumberExtraInfo from "./NumberExtraInfo";
import QuantityInput from "./QuantityInput";
import DiscountAmount from "./DiscountAmount";

/* eslint-disable react/jsx-props-no-spreading */

export const OrderItemRowHeaderDesktop = ({ isShow, showDiscountColumn, showTrial }) => (
  <div className="text-center rounded text-black">
    <Row className="p-2 bg-white">
      <Col xs={showTrial ? 11 : 12}>
        <StyledHeaderLeftRow $header>
          <Col md={showDiscountColumn ? 3 : isShow ? 5 : 4} className="text-md-left">
            <strong>Product</strong>
          </Col>
          <Col md={isShow ? 1 : 2}>
            <strong>Quantity</strong>
          </Col>
          <Col md={1} className="text-md-right">
            <strong>Unit</strong>
          </Col>
          <Col md={isShow ? 2 : 1} className="text-md-right">
            <strong>Package</strong>
          </Col>
          {showDiscountColumn && (
            <Col md={isShow ? 2 : 1} className="text-md-right">
              <strong className="block">Discount</strong>
            </Col>
          )}
          <Col md={1} className="text-md-right">
            <strong>Weight</strong>
          </Col>
          <Col md={2} className="text-md-right">
            <strong className="block">Total</strong>
          </Col>
          {!isShow && <Col md={1} className="ps-md-4 text-end" />}
        </StyledHeaderLeftRow>
      </Col>
      {showTrial && (
        <Col xs={1}>
          <StyledHeaderRightRow $header>
            <Col md={1} className="text-end">
              {!isShow && <strong>Trial</strong>}
            </Col>
          </StyledHeaderRightRow>
        </Col>
      )}
    </Row>
  </div>
);

OrderItemRowHeaderDesktop.propTypes = {
  isShow: PropTypes.bool.isRequired,
  showDiscountColumn: PropTypes.bool.isRequired,
  showTrial: PropTypes.bool.isRequired,
};

export const OrderItemRowDesktop = ({
  item,
  isShow,
  note,
  setNote,
  loadingPrice,
  showDiscountColumn,
  showTrial,
  handleRemoveItem,
  handleAddDiscount,
  handleTrialProduct,
  dragHangleProps,
  canSetDiscounts,
  canSetTrial,
}) => {
  const [removing, setRemoving] = useState(false);
  const hasNotes = item?.attributes?.notes;
  const [showNotes, setShowNotes] = useState(isShow ? false : hasNotes);

  const [showDetailModal, setShowDetailModal] = useState(false);

  const isTrial = item.attributes.isTrial || false;

  const totalPrice = item.attributes.totalPriceCents;
  const finalPrice = item.attributes.finalPriceCents;

  return (
    <div className="pt-2 text-center text-black">
      <Row className="align-items-stretch">
        <Col xs={showTrial ? 11 : 12}>
          <StyledLeftRow className="rounded-1 py-2" $isTrial={isTrial}>
            <StyledCol md={showDiscountColumn ? 3 : isShow ? 5 : 4} className="justify-content-start">
              <div>
                {!isShow && (
                  <>
                    <i className="fas fa-ellipsis-v me-3" {...dragHangleProps} />
                  </>
                )}
                <IconPointer onClick={() => setShowDetailModal(true)} className="fas fa-info-circle me-2" />
                {item.attributes.productName}
              </div>
            </StyledCol>
            <Col md={isShow ? 1 : 2} className="d-flex flex-column justify-content-center">
              {!isShow ? (
                <div>
                  <QuantityInput
                    productId={item?.attributes?.productId}
                    packagesPerPallet={item?.attributes?.productPackagesPerPallet}
                  />
                </div>
              ) : (
                <strong>{item?.attributes?.quantity}</strong>
              )}
            </Col>
            <StyledCol md={1} className="justify-content-end position-relative">
              <NumberExtraInfo
                value={item.attributes.productPriceCents}
                extra={item.attributes.unit ? `per ${item.attributes.unit}` : null}
              />
            </StyledCol>
            <StyledCol md={isShow ? 2 : 1} className="justify-content-end">
              <NumberExtraInfo
                value={item.attributes.productPackageCents}
                extra={item.attributes.packageUnit ? `per ${item.attributes.packageUnit}` : null}
              />
            </StyledCol>
            {showDiscountColumn && (
              <StyledCol md={isShow ? 2 : 1} className="justify-content-end">
                <DiscountAmount
                  isShow={isShow}
                  item={item}
                  handleAddDiscount={handleAddDiscount}
                  canSetDiscounts={canSetDiscounts}
                />
              </StyledCol>
            )}
            <StyledCol md={1} className="justify-content-end">
              <NumberExtraInfo
                value={Math.ceil(item.attributes.productPackageWeight * item.attributes.quantity)}
                extra="lbs."
                isCurrency={false}
              />
            </StyledCol>
            <StyledCol md={2} className="justify-content-end">
              {isShow && isTrial && (
                <Badge bg="primary" className="me-3">
                  Trial
                </Badge>
              )}
              <NumberExtraInfo value={finalPrice} extraValue={finalPrice !== totalPrice ? totalPrice : null} />
              {loadingPrice && <Spinner className="ms-2" size="sm" animation="border" variant="secondary" />}
            </StyledCol>
            {!isShow && (
              <Col md={1} className="ps-md-4 d-flex flex-column align-items-center">
                <>
                  <IconPointer onClick={() => setShowNotes(!showNotes)} className="fas fa-sticky-note text-info mb-2 mt-1" />
                  {removing ? (
                    <Spinner animation="border" variant="secondary" size="sm" />
                  ) : (
                    <IconPointer
                      className="fas fa-trash text-danger"
                      onClick={() => {
                        setRemoving(true);
                        handleRemoveItem(item);
                      }}
                    />
                  )}
                </>
              </Col>
            )}
            {isShow && item?.attributes?.notes && (
              <>
                <Col md={11} className="my-2 ms-3 text-md-left mt-1 font-italic fw-light">
                  {item?.attributes?.notes}
                </Col>
              </>
            )}
            {showNotes && (
              <>
                <StyledCol md={11} className="border-top-0 pt-0 mb-3">
                  <Form.Control
                    as="input"
                    type="text"
                    placeholder="Add a note for this item"
                    value={note}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                  />
                </StyledCol>
                <Col md={1} />
              </>
            )}
          </StyledLeftRow>
        </Col>
        {showTrial && (
          <Col xs={1}>
            <StyledRightRow className="py-2 rounded-1 h-100" $isTrial={isTrial}>
              <Col className="text-center">
                {isShow ? (
                  <>{isTrial && <div className="fw-bold">Trial</div>}</>
                ) : (
                  <Form.Check
                    checked={isTrial}
                    className="mt-3"
                    type="checkbox"
                    onChange={(evt) => {
                      handleTrialProduct(item, evt.target.checked);
                    }}
                    disabled={!canSetTrial}
                  />
                )}
              </Col>
            </StyledRightRow>
          </Col>
        )}
      </Row>

      <OrderItemModal item={item} onCancel={() => setShowDetailModal(false)} show={showDetailModal} />
    </div>
  );
};

OrderItemRowDesktop.propTypes = {
  item: PropTypes.shape().isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  dragHangleProps: PropTypes.shape().isRequired,
  isShow: PropTypes.bool.isRequired,
  showDiscountColumn: PropTypes.bool.isRequired,
  showTrial: PropTypes.bool.isRequired,
  note: PropTypes.string.isRequired,
  setNote: PropTypes.func.isRequired,
  loadingPrice: PropTypes.bool.isRequired,
  handleAddDiscount: PropTypes.func.isRequired,
  handleTrialProduct: PropTypes.func.isRequired,
  canSetDiscounts: PropTypes.bool.isRequired,
  canSetTrial: PropTypes.bool.isRequired,
};

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const StyledHeaderLeftRow = styled(Row)`
  margin-right: -13px;
`;

const StyledHeaderRightRow = styled(Row)`
  margin-left: -13px;
`;

const StyledLeftRow = styled(Row)`
  background-color: ${(props) => (props.$isTrial ? "#ddf0fe" : "#f5f5f5")};
  margin-right: -13px;
`;

const StyledRightRow = styled(Row)`
  background-color: ${(props) => (props.$isTrial ? "#ddf0fe" : "#f5f5f5")};
  margin-left: -13px;
`;
