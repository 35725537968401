import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { reducer } from "@ROM/App/utils/forms";

import { update as updateAddress, create as createAddress, clearErrors, fetchCountries } from "@ROM/Addresses/actions";
import { selectCountries, selectCreateErrors } from "@ROM/Addresses/selectors";
import Modal from "@ROM-components/common/Modal";
import AddressForm from "@ROM/Addresses/components/AddressForm";

const FormModal = ({ show = false, address = {}, type, onCancel, customerId, callback, insert = true }) => {
  const dispatch = useDispatch();
  const errors = useSelector(selectCreateErrors);
  const countries = useSelector(selectCountries);

  const isEditing = Boolean(address.id);

  const formInitialValues = isEditing ? address.attributes : { country: "US", customerId, type };

  const [addressAttributes, updateAddressAttributes] = useReducer(reducer, formInitialValues);

  const handleConfirm = () => {
    const attributes = { ...addressAttributes, customerId };

    const promise = dispatch(
      isEditing ? updateAddress(address.id, attributes, { type }) : createAddress(attributes, { insert, type })
    );

    promise.then((response) => {
      if (response.payload.status === 201 || response.payload.status === 200) {
        const addressId = response.payload.data.data.id;
        if (callback) callback(addressId);
        onCancel();
      }
    });
  };

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  return (
    <Modal
      isShowing={show}
      header={isEditing ? "Edit Address" : "Add a New Address"}
      confirmText="Save"
      onConfirm={handleConfirm}
      showCloseIcon
      onCancel={() => {
        dispatch(clearErrors());
        onCancel();
      }}
    >
      <AddressForm address={addressAttributes} countries={countries} onUpdate={updateAddressAttributes} errors={errors} />
    </Modal>
  );
};

FormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  address: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape(),
  }),
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  callback: PropTypes.func,
  insert: PropTypes.bool,
};

FormModal.defaultProps = {
  address: {},
  callback: null,
  insert: true,
};

export default FormModal;
