import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import { homeUrl } from "@ROM-utils/urlHelpers";
import StyledContainer from "@ROM-ui/StyledContainer";
import { canAccessCustomerAdmin } from "@ROM/CustomerAdmin/permissions";

import DesktopNavbar from "./Navbars/DesktopNavbar";
import MobileNavbar from "./Navbars/MobileNavbar";
import AdminContainer from "./AdminContainer";

const CustomerAdmin = ({ children }) => {
  const currentUser = useSelector(selectCurrentUser);
  if (!canAccessCustomerAdmin(currentUser)) {
    return <Redirect to={{ pathname: homeUrl() }} />;
  }
  return (
    <AdminContainer>
      <StyledContainer as="main" role="main" width="90%">
        <DesktopNavbar />
        <MobileNavbar />
        {children}
      </StyledContainer>
    </AdminContainer>
  );
};

CustomerAdmin.propTypes = {
  children: PropTypes.node,
};

CustomerAdmin.defaultProps = {
  children: null,
};

export default CustomerAdmin;
