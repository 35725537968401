import React from "react";
import PropTypes from "prop-types";

import { ListItem } from "@ROM-components/common/AdminList";
import CheckBoxSquare from "@ROM-ui/CheckBoxSquare";
import CheckBoxCircle from "@ROM-ui/CheckBoxCircle";

const Item = ({ item, isSelected, selectedScope, add, remove, updateScope, className }) => {
  const handleClick = () => {
    if (isSelected) {
      remove(item);
    } else {
      add(item);
    }
  };

  const handleScopeClick = (scope) => {
    updateScope(item, scope);
  };

  return (
    <div className={className}>
      <ListItem
        className="d-flex align-items-center justify-content-between"
        hint={item.attributes.description}
        hintPlacement="bottom"
        isActive={isSelected}
        onClick={handleClick}
        role="button"
        tabIndex={0}
      >
        {item.attributes.displayName || item.attributes.name}
        <CheckBoxSquare check={isSelected} />
      </ListItem>
      {item.attributes.withScope && (
        <div className="ms-5 ps-3 mt-2">
          <ListItem
            className="d-flex align-items-center justify-content-between mb-2"
            isActive={selectedScope === null}
            onClick={() => handleScopeClick(null)}
            disabled={!isSelected}
            role="button"
            tabIndex={0}
          >
            {`All ${item.attributes.group}`}
            <CheckBoxCircle check={selectedScope === null} />
          </ListItem>
          <ListItem
            className="d-flex align-items-center justify-content-between"
            isActive={selectedScope === "region"}
            onClick={() => handleScopeClick("region")}
            disabled={!isSelected}
            role="button"
            tabIndex={0}
          >
            {`Regional ${item.attributes.group}`}
            <CheckBoxCircle check={selectedScope === "region"} />
          </ListItem>
        </div>
      )}
    </div>
  );
};

Item.defaultProps = {
  className: "",
  isSelected: false,
  selectedScope: null,
};

Item.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape.isRequired,
  isSelected: PropTypes.bool,
  selectedScope: PropTypes.string,
  add: PropTypes.func.isRequired,
  updateScope: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

export default Item;
