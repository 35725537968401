import React from "react";
import { Route } from "react-router-dom";
import Page from "./Page";

class RouteHandler extends Route {
  render() {
    return <Page name={this.props.computedMatch.params.name} />;
  }
}

export default RouteHandler;
