import React from "react";
import { Route } from "react-router-dom";

import Page from "@ROM-pages/CompanyAdmin/Customers/New";
import Layout from "@ROM-pages/Layouts/CompanyAdmin";
import Authorize from "@ROM/Auth/components/Authorize";
import { canCreateCustomers } from "@ROM/Customers/permissions";

class RouteHandler extends Route {
  render() {
    return (
      <Layout>
        <Authorize permission={canCreateCustomers}>
          <Page />
        </Authorize>
      </Layout>
    );
  }
}

export default RouteHandler;
