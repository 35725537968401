import React from "react";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Button from "@ROM-ui/Button";
import Form from "@ROM-ui/Form";
import PropTypes from "prop-types";

const Filters = ({ handleFilterChange, handleClearFilters, filters }) => (
  <Row className="align-items-center my-2">
    <Col md={8}>
      <Form.Control
        type="text"
        name="contact"
        placeholder="Contact"
        onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
        value={filters.contact || ""}
      />
    </Col>
    <Col md={4}>
      <Button className="p-0" size="sm" variant="link" onClick={handleClearFilters}>
        Clear Filters
      </Button>
    </Col>
    <Col></Col>
  </Row>
);

Filters.propTypes = {
  handleFilterChange: PropTypes.func,
  handleClearFilters: PropTypes.func,
  filter: PropTypes.object,
};

export default Filters;
