import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Skeleton from "react-loading-skeleton";
import TitledHeader from "@ROM-components/common/TitledHeader";

import DeliveriesForm from "@ROM/Deliveries/components/Form";
import { selectCurrentOrder, selectCurrentIncluded, selectCurrentOrderItems } from "@ROM/Orders/selectors";
import { find } from "@ROM/Orders/actions";

export const DeliveriesEdit = ({ orderId }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const order = useSelector(selectCurrentOrder);
  const orderIncluded = useSelector(selectCurrentIncluded);
  const orderItems = useSelector(selectCurrentOrderItems);

  useEffect(() => {
    if (order?.orderId !== orderId) {
      setLoading(true);
      dispatch(find(orderId)).then(() => {
        setLoading(false);
      });
    }
  }, [orderId]);

  return (
    <>
      <TitledHeader title="Edit order deliveries" />
      {loading ? (
        <Skeleton width="100%" />
      ) : (
        <DeliveriesForm order={order} orderIncluded={orderIncluded} orderItems={orderItems} />
      )}
    </>
  );
};

DeliveriesEdit.propTypes = {
  orderId: PropTypes.number.isRequired,
};

export default DeliveriesEdit;
