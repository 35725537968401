import React from "react";
import PropTypes from "prop-types";
import PdfAsset from "@ROM-ui/PdfAsset";
import ImageAsset from "@ROM-ui/ImageAsset";
import styled from "styled-components";

const trimTitle = (path) => (path.length > 15 ? `${path.substring(0, 10)}...${path.substring(path.length - 4)}` : path);

const Attachments = ({ orderAssets }) => {
  const thumbs = orderAssets.map((asset) => {
    const thumbUrl = asset.attributes.file ? asset.attributes.file.url : null;
    const assetName = asset.attributes.name.split("?")[0];

    const isPdf = asset.attributes.file ? asset.attributes.file.url.search(".pdf") : null;
    const orderId = asset.relationships.owner.data.id;

    return (
      <div className="bg-light p-1 d-flex flex-column align-items-center justify-content-between border rounded" key={asset.id}>
        {isPdf > 0 ? (
          <a
            key={asset.id}
            href={asset.id}
            data-type="iframe"
            data-src={thumbUrl}
            data-fancybox={`gallery-${orderId}`}
            data-caption={assetName}
          >
            <PdfAsset className="fas fa-4x fa-file-pdf" />
          </a>
        ) : (
          <a key={asset.id} href={asset.id} data-src={thumbUrl} data-fancybox={`gallery-${orderId}`} data-caption={assetName}>
            <ImageAsset src={thumbUrl} />
          </a>
        )}
        <small>{trimTitle(assetName)}</small>
      </div>
    );
  });

  return <StyledDiv className="d-flex flex-wrap py-3">{thumbs}</StyledDiv>;
};

const StyledDiv = styled.div`
  gap: 0.5rem;
`;

Attachments.propTypes = {
  orderAssets: PropTypes.arrayOf().isRequired,
};
export default Attachments;
