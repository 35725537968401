import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { list as fetchCustomers, setCustomerForOrder, fetchCustomer, find as findCustomer } from "@ROM/Customers/actions";
import { selectSessionCustomer, selectCurrentUser } from "@ROM/Auth/selectors";
import { selectAllCustomers, selectAllCustomersLoading, selectCurrentCustomer } from "@ROM/Customers/selectors";
import { selectCustomerLoading } from "@ROM/Auth/selectors";
import { canCreateCustomers } from "@ROM/Customers/permissions";
import NewPage from "@ROM-pages/Orders/New";
import SelectCustomerPage from "@ROM-pages/Orders/New/SelectCustomer";
import Layout from "@ROM-pages/Layouts/Authenticated";
import LoadingSplash from "@ROM-components/LoadingSplash/index";

import PropTypes from "prop-types";

class RouteHandler extends Route {
  constructor(props) {
    super(props);
    this.state = {
      settingCustomer: false,
      initialized: false,
    };
  }

  componentDidMount() {
    const { customer, customers, customersLoading, setCustomer, fetchCustomers, customerLoading, fetchCustomer } = this.props;

    if (!canCreateCustomers(this.props.currentUser) && customers.length === 1 && !customer) {
      setCustomer(customers[0]);
    } else {
      if (customers.length === 0 && !customersLoading && !customer) {
        fetchCustomers();
      }
      if (!customer && !customerLoading && customers.length > 1) {
        fetchCustomer();
      }
    }
    this.setState({ initialized: true });
  }

  componentDidUpdate(prevProps) {
    const { customer, customers, setCustomer, currentUser } = this.props;
    if (
      !canCreateCustomers(currentUser) &&
      prevProps.customers.length !== customers.length &&
      customers.length === 1 &&
      !customer
    ) {
      setCustomer(customers[0]);
    }
  }

  render() {
    const {
      customer,
      customers,
      setCustomer,
      customersLoading,
      customerLoading,
      currentUser,
      findCustomer,
      currentCustomer,
    } = this.props;
    return (
      <Layout>
        {!customerLoading &&
        !customersLoading &&
        this.state.initialized &&
        (customer || customers.length !== 1 || canCreateCustomers(currentUser)) ? (
          <>
            {customer ? (
              <NewPage customer={customer} />
            ) : (
              <SelectCustomerPage
                findCustomer={findCustomer}
                currentCustomer={currentCustomer}
                onSelectCustomer={setCustomer}
                currentUser={currentUser}
              />
            )}
          </>
        ) : (
          <LoadingSplash />
        )}
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  return {
    customer: selectSessionCustomer(state),
    customers: selectAllCustomers(state),
    customersLoading: selectAllCustomersLoading(state),
    customerLoading: selectCustomerLoading(state),
    currentUser: selectCurrentUser(state),
    currentCustomer: selectCurrentCustomer(state),
  };
};

const dispatchToProps = (dispatch) => {
  return {
    fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
    setCustomer: bindActionCreators(setCustomerForOrder, dispatch),
    fetchCustomer: bindActionCreators(fetchCustomer, dispatch),
    findCustomer: bindActionCreators(findCustomer, dispatch),
  };
};

RouteHandler.propTypes = {
  customer: PropTypes.shape(),
  customers: PropTypes.arrayOf(PropTypes.shape()),
  customerLoading: PropTypes.bool,
  fetchCustomers: PropTypes.func,
  setCustomer: PropTypes.func,
  fetchCustomer: PropTypes.func,
  findCustomer: PropTypes.func,
  currentCustomer: PropTypes.shape(),
};

export default connect(stateToProps, dispatchToProps)(RouteHandler);
