import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Form from "@ROM-ui/Form";
import Modal from "@ROM-components/common/Modal";
import Button from "@ROM-ui/Button";
import Spinner from "@ROM-ui/Spinner";
import { ListItem } from "@ROM-components/common/AdminList";
import CompanyRoleUsersModal from "@ROM/CompanyAdmin/Company/Roles/CompanyRoleUsersModal";

const CompanyRole = ({ roleName, isActive, setActiveRole, onUpdate, onDestroy, fetchRoleUsers, className }) => {
  const [updating, setUpdating] = useState(false);
  const [newRoleName, setNewRoleName] = useState(roleName);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [disabledUpdate, setDisabledUpdate] = useState(false);
  const [showDestroyConfirmationModal, setShowDestroyConfirmationModal] = useState(false);
  const [showCompanyRoleUsersModal, setShowCompanyRoleUsersModal] = useState(false);
  const [companyRoleUsers, setCompanyRoleUsers] = useState([]);

  const updateCompanyRole = async (e) => {
    e.stopPropagation();

    setLoadingUpdate(true);

    await onUpdate(newRoleName);

    setLoadingUpdate(false);
    setUpdating(false);
  };

  const destroyCompanyRole = async () => {
    await onDestroy();
    setShowDestroyConfirmationModal(false);
  };

  const onClickUpdate = (e) => {
    e.stopPropagation();
    setUpdating(true);
  };

  const onClickDestroy = async (e) => {
    e.stopPropagation();
    const {
      data: { data: users },
    } = await fetchRoleUsers();
    if (users.length === 0) {
      setShowDestroyConfirmationModal(true);
    } else {
      setCompanyRoleUsers(users);
      setShowCompanyRoleUsersModal(true);
    }
  };

  useEffect(() => {
    if (newRoleName.length === 0) {
      setDisabledUpdate(true);
    } else {
      setDisabledUpdate(false);
    }
  }, [newRoleName]);

  return (
    <div className={className}>
      <ListItem
        className="d-flex align-items-center justify-content-between"
        isActive={isActive}
        onClick={() => setActiveRole()}
        role="button"
        tabIndex={0}
      >
        {updating ? (
          <>
            <Form.Control
              as="input"
              type="text"
              className="w-auto"
              value={newRoleName}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setNewRoleName(e.target.value)}
            />
            <Button
              variant="secondary"
              disabled={loadingUpdate || disabledUpdate}
              size="sm"
              onClick={updateCompanyRole}
              style={{ width: "75px" }}
            >
              {loadingUpdate ? <Spinner as="span" animation="border" size="sm" /> : "Update"}
            </Button>
          </>
        ) : (
          <>
            <span className="text-capitalize">{roleName}</span>
            <div>
              <i role="button" tabIndex={0} className="fas fa-edit cursor-pointer text-warning me-1" onClick={onClickUpdate} />
              <i role="button" tabIndex={0} className="fas fa-trash cursor-pointer text-danger" onClick={onClickDestroy} />
            </div>
          </>
        )}
      </ListItem>
      <Modal
        isShowing={showDestroyConfirmationModal}
        onConfirm={destroyCompanyRole}
        onCancel={() => setShowDestroyConfirmationModal(false)}
        header="Are you sure you want to delete this role?"
        showCloseIcon={false}
      />
      <CompanyRoleUsersModal
        isShowing={showCompanyRoleUsersModal}
        onDismiss={() => setShowCompanyRoleUsersModal(false)}
        users={companyRoleUsers}
      />
    </div>
  );
};

CompanyRole.defaultProps = {
  className: "",
};

CompanyRole.propTypes = {
  className: PropTypes.string,
  roleName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  setActiveRole: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  fetchRoleUsers: PropTypes.func.isRequired,
};

export default CompanyRole;
