import update from "immutability-helper";
import { handle } from "redux-pack";
import humps from "humps";
import { SESSION_SET_CUSTOMER, FETCH_CUSTOMER } from "@ROM/Customers/actions";

import { FETCH_USER, LOGOUT, UPDATE_USER_ORDERS, FETCH_ALL_ROLES } from "./actions";

import { UPDATE as UPDATE_USER } from "./Account/actions";

const initialState = {
  session: {
    customer: null,
    loading: false,
    roles: {
      generalRoles: [],
      companyRoles: [],
      customerRoles: [],
    },
  },
  currentUser: {
    record: null,
    included: [],
    update: {
      success: null,
      errors: null,
    },
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {}; // redux-pack puts API response in its own 'data' attribute, renaming to 'json'
  switch (type) {
    case FETCH_USER:
      return handle(state, action, {
        success: (prevState) => {
          return update(prevState, {
            currentUser: {
              record: { $set: humps.camelizeKeys(json.data) },
              included: { $set: json.included },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            $set: initialState,
          });
        },
      });

    case SESSION_SET_CUSTOMER:
      return update(state, {
        session: {
          customer: { $set: payload },
        },
      });

    case FETCH_ALL_ROLES:
      return handle(state, action, {
        success: (prevState) => {
          return update(prevState, {
            session: {
              roles: { $set: humps.camelizeKeys(json) },
            },
          });
        },
      });

    case UPDATE_USER_ORDERS:
      return update(state, {
        currentUser: {
          record: {
            attributes: {
              ordersIds: {
                $push: [json],
              },
            },
          },
        },
      });

    case FETCH_CUSTOMER:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            session: {
              loading: { $set: true },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            session: {
              customer: { $set: json.data },
              loading: { $set: false },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            session: {
              customer: { $set: null },
              loading: { $set: false },
            },
          });
        },
      });

    case LOGOUT:
      return handle(state, action, {
        success: (prevState) => {
          return update(prevState, {
            $set: initialState,
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            $set: initialState,
          });
        },
      });

    case UPDATE_USER:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            currentUser: {
              update: {
                success: { $set: false },
                errors: { $set: false },
              },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            currentUser: {
              record: { $set: humps.camelizeKeys(json.data) },
              update: {
                success: { $set: true },
                errors: { $set: false },
              },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            currentUser: {
              update: {
                success: { $set: false },
                errors: { $set: true },
              },
            },
          });
        },
      });

    default:
      return state;
  }
}

export default reducer;
