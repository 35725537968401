import React from "react";
import { Route } from "react-router-dom";
import Edit from "@ROM-pages/BillOfLadings/Edit";
import Layout from "@ROM-pages/Layouts/Authenticated";

class RouteHandler extends Route {
  render() {
    const { billOfLadingId, deliveryId, orderId } = this.props.computedMatch.params;
    return (
      <Layout fullWidth>
        <Edit billOfLadingId={billOfLadingId} deliveryId={deliveryId} orderId={orderId} />
      </Layout>
    );
  }
}

export default RouteHandler;
