/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import PropTypes from "prop-types";
import styled from "styled-components";

import "react-multi-email/style.css";
import "./style.css";

const MultiInputValue = ({ values, onChange, validate, validateMessage, placeholder, className }) => {
  const [error, setError] = useState(null);

  const validateInput = (value) => {
    if (validate(value)) {
      setError(null);
      return true;
    }

    setError(validateMessage(value));

    return false;
  };

  return (
    <div className={className}>
      <ReactMultiEmail
        placeholder={placeholder}
        emails={values}
        onChange={onChange}
        validateEmail={validateInput}
        getLabel={(value, index, removeValue) => (
          <div data-tag key={index}>
            {value}
            <span data-tag-handle onClick={() => removeValue(index)}>
              ×
            </span>
          </div>
        )}
      />
      {error && <ErrorMsg>{error}</ErrorMsg>}
    </div>
  );
};

const ErrorMsg = styled.div`
  padding-top: 0.25rem;
  font-size: 0.9rem;
  color: #b91c1c;
`;

MultiInputValue.defaultProps = {
  validate: () => true,
  validateMessage: () => "Value is not valid",
  placeholder: "",
  className: "",
};

MultiInputValue.propTypes = {
  values: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func,
  validateMessage: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default MultiInputValue;

export { isEmail };
