import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const PalletPopover = ({
  creatingOrderItem,
  handleAddPallet,
  handleRemovePallet,
  packagesPerPallet,
  setShow,
  inputRef,
  style = {},
}) => {
  /**
   * Hook that alerts clicks outside of the passed ref
   */
  const useOutsideAlerter = (ref, inputRef) => {
    useEffect(() => {
      /**
       * If clicked on outside of element
       */
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && inputRef.current && !inputRef.current.contains(event.target)) {
          setShow(false);
        }
      };

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, inputRef);

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <StyledDiv style={style} className="text-secondary text-center" ref={wrapperRef} onClick={handleClick}>
      <StyledHeader className="p-0 m-0">Add by pallet</StyledHeader>
      <StyledIcon className="fas fa-minus-circle me-3" onClick={handleRemovePallet} creatingOrderItem={creatingOrderItem} />
      <StyledIcon className="fas fa-plus-circle ms-3" onClick={handleAddPallet} creatingOrderItem={creatingOrderItem} />
      <StyledPalletDescription>{`Pallet is ${packagesPerPallet} Units`}</StyledPalletDescription>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  position: absolute;
  top: -0.5rem;
  width: 100%;
  z-index: 3;
  padding-bottom: 1rem;
  padding-top: 3rem;
  border-radius: 0.25rem;
  background-color: #e1f3fc;
  cursor: default;
`;

const StyledIcon = styled.i`
  cursor: pointer;
  font-size: xx-large;
  color: ${(props) => (props.creatingOrderItem ? "lightblue" : "")};
`;

const StyledPalletDescription = styled.p`
  font-size: small;
  margin: 0;
  padding: 0;
  font-style: italic;
`;

const StyledHeader = styled.p`
  margin-top: 2rem !important;
`;

export default PalletPopover;

PalletPopover.propTypes = {
  creatingOrderItem: PropTypes.bool,
  handleAddPallet: PropTypes.func,
  handleRemovePallet: PropTypes.func,
  packagesPerPallet: PropTypes.number,
  setShow: PropTypes.func,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.elementType })]),
  style: PropTypes.object,
};
