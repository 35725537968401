import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import ListGroup from "@ROM-ui/ListGroup";
import Button from "@ROM-ui/Button";

/* Base Item
 *
 * used in AddressItem and DefaultItem
 */
const Item = ({ selected, onSelect, children, type }) => {
  const handleSelect = () => {
    onSelect();
    document.querySelector(`#${type}`).scrollTop = 0;
  };

  return (
    <>
      {selected ? (
        <ListGroupItem selected={selected}>{children}</ListGroupItem>
      ) : (
        <ListGroupItem onClick={handleSelect} active={false}>
          {children}
        </ListGroupItem>
      )}
    </>
  );
};

Item.propTypes = {
  /*
   * Display selected state for item
   */
  selected: PropTypes.bool.isRequired,

  /*
   * Function handler when item is selected (clicked)
   */
  onSelect: PropTypes.func.isRequired,

  type: PropTypes.string.isRequired,

  children: PropTypes.node.isRequired,
};

/* AddressItem
 *
 * Individual address item for AddressSelector component
 */

const AddressItem = ({ address, onSelect, onEdit, onDelete, selected, type, editing, setIsDone }) => {
  const { attributes } = address;

  const handleSelectAddress = () => {
    onSelect(address.id);
  };

  const handleEditAddress = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onEdit(address);
  };

  const handleDeleteAddress = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onDelete(address);
  };

  return (
    <Item selected={selected} onSelect={handleSelectAddress} addressId={address.id} type={type}>
      <Row className="align-items-center">
        <Col xs={12} md={8}>
          <AddressData attributes={attributes} />
        </Col>
        <Col xs={12} md={4} className="d-flex justify-content-between pt-1">
          {selected && (
            <>
              <Button onClick={() => setIsDone(true)} variant="outline-primary">
                Done
              </Button>
              {onEdit && <Icon onClick={handleEditAddress} className="fas fa-edit text-success d-flex align-items-center" />}
              {onDelete && !editing && (
                <Icon onClick={handleDeleteAddress} className="fas fa-trash ps-md-2 text-success d-flex align-items-center" />
              )}
            </>
          )}
        </Col>
      </Row>
    </Item>
  );
};

AddressItem.propTypes = {
  /**
   * Address to render
   */
  address: PropTypes.shape().isRequired,

  /**
   * onSelect(address)
   *
   * Function handler called when address is selected
   * Address object is passed to handler
   */
  onSelect: PropTypes.func.isRequired,

  /**
   * onEdit(address)
   *
   * Function handler called when edit icon is clicked
   * Address object is passed to handler
   */
  onEdit: PropTypes.func,

  /**
   * onDelete(address)
   *
   * Function handler called when delete icon is clicked
   * Address object is passed to handler
   */
  onDelete: PropTypes.func,

  /**
   * Set selected state of item
   */
  selected: PropTypes.bool.isRequired,

  type: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  setIsDone: PropTypes.func.isRequired,
};

AddressItem.defaultProps = {
  onEdit: null,
  onDelete: null,
};

/* Default Item
 *
 * provide AddressSelector a DefaultItem to render a
 * default option in the list of addresses
 *
 * Used for rendering a 'Same as Billing' option
 */

const DefaultItem = ({ selected, onSelect, children, type }) => (
  <Item selected={selected} onSelect={onSelect} type={type}>
    {children}
  </Item>
);

DefaultItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
};

const AddressData = ({ attributes }) => (
  <>
    <p className="m-0">
      <strong>{`${attributes.customerCompanyName ? `${attributes.customerCompanyName} - ` : ""}${attributes.contact}`}</strong>
    </p>
    <p className="m-0">{`${attributes.address1}${attributes.address2 ? `, ${attributes.address2}` : ""}`}</p>
    <p className="m-0">
      {`${attributes.city ? `${attributes.city},` : ""} ${attributes.state ? `${attributes.state}` : ""} ${
        attributes.zip ? `${attributes.zip}` : ""
      }`}
    </p>
    {attributes.countryName && <p>{attributes.countryName}</p>}
  </>
);

AddressData.propTypes = {
  attributes: PropTypes.shape().isRequired,
};

export default AddressItem;
export { DefaultItem, AddressData };

const ListGroupItem = styled(ListGroup.Item)`
  border: ${(props) => (props.selected ? "4px solid #9DCB75" : "0")};
  border-radius: 7px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#EDF8E1" : "#F2F2F2")};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  :last-child {
    border-radius: 7px;
  }
  :first-child {
    border-radius: 7px;
  }
`;

const Icon = styled.i`
  cursor: pointer;
`;
