/* eslint-disable react/jsx-indent */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

import { selectAllCustomTables } from "@ROM/CustomTables/selectors";
import { companyAdminCustomTablesEditRowUrl } from "@ROM-utils/urlHelpers";
import { destroyRow } from "@ROM/CustomTableRows/actions";
import { getRowClassName } from "@ROM/CustomTableRows/utils";
import IconPointer from "@ROM-ui/IconPointer";
import Modal from "@ROM-components/common/Modal";
import Button from "@ROM-ui/Button";

const TableRow = ({ table, row }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customTables = useSelector(selectAllCustomTables);
  const [showDestroyConfirmationModal, setShowDestroyConfirmationModal] = useState(false);

  const { data: rowData, relations: rowRelations } = row.attributes;
  const { fields, relations } = table.attributes;

  const parseDataValue = (value, type) => {
    if (!value) {
      return "N/A";
    }

    switch (type) {
      case "attachment":
        return <Attachment src={value.url} />;
      default:
        return value;
    }
  };

  const parseRelationValue = (value) => {
    const valueCustomTable = customTables.find((customTable) => customTable.id === value.table_id.toString());

    return (
      value[valueCustomTable.attributes.displayColumn] ||
      Object.keys(value)
        .map((key) => `${key}: ${value[key]}`)
        .join(" | ")
    );
  };

  const handleEdit = (tableId, rowId) => {
    history.push(companyAdminCustomTablesEditRowUrl(tableId || table.id, rowId || row.id));
  };

  const destroyCustomTableRow = () => {
    dispatch(destroyRow({ customTableId: table.id, id: row.id }));
    setShowDestroyConfirmationModal(false);
  };

  return (
    <>
      <tr>
        {fields.map(({ name, type }, index) => (
          <td key={name} className={getRowClassName(index)}>
            {parseDataValue(rowData[name], type)}
          </td>
        ))}
        {relations.map(({ withTable }, index) => (
          <td key={withTable} className={getRowClassName(index)}>
            {rowRelations[withTable] && rowRelations[withTable].length > 0
              ? rowRelations[withTable].map((relatedRow) => (
                  <Button
                    variant="link"
                    key={relatedRow.id}
                    onClick={() => handleEdit(relatedRow.table_id, relatedRow.id)}
                    className="p-0 text-truncate d-block"
                    style={{ maxWidth: "300px" }}
                  >
                    {parseRelationValue(relatedRow)}
                  </Button>
                ))
              : "N/A"}
          </td>
        ))}
        <td className="text-end text-nowrap">
          <IconPointer className="me-2 fa fa-edit text-success" onClick={() => handleEdit()} />
          <IconPointer className="fa fa-trash text-danger" onClick={() => setShowDestroyConfirmationModal(true)} />
        </td>
      </tr>
      <Modal
        isShowing={showDestroyConfirmationModal}
        onConfirm={() => destroyCustomTableRow()}
        onCancel={() => setShowDestroyConfirmationModal(false)}
        header="Are you sure you want to delete the row?"
        showCloseIcon={false}
      />
    </>
  );
};

const Attachment = styled.img`
  width: 100px;
`;

TableRow.propTypes = {
  table: PropTypes.shape.isRequired,
  row: PropTypes.shape.isRequired,
};

export default TableRow;
