import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Spinner from "@ROM-ui/Spinner";
import Message from "@ROM-common/Message/index";

import { selectIncludedDeliveryShipping } from "@ROM/Deliveries/selectors";
import { updateShipping } from "@ROM/Deliveries/actions";
import TrackingDetail from "./TrackingDetail";
import TrackingInformationModal from "./TrackingInformationModal";

const TrackingInformation = ({ delivery, className }) => {
  const dispatch = useDispatch();
  const deliveryShippingId = delivery?.relationships.deliveryShipping.data?.id || null;
  const deliveryShipping = useSelector(selectIncludedDeliveryShipping(deliveryShippingId));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [showTrackingModal, setShowTrackingModal] = useState(false);

  const handleUpdateTracking = async (data) => {
    setShowTrackingModal(false);
    setLoading(true);
    try {
      await dispatch(updateShipping(delivery.id, data));
    } catch {
      setError("Failed to add error information");
    }
    setLoading(false);
  };

  return (
    /* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div
      className={className}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* eslint-enable */}
      {loading ? (
        <Spinner animation="border" variant="secondary" size="sm" />
      ) : (
        <TrackingDetail
          deliveryShipping={deliveryShipping}
          onEdit={() => {
            setShowTrackingModal(true);
          }}
        />
      )}

      {error && <Message message={error} className="text-center px-1 py-0 m-0 small" />}
      <TrackingInformationModal
        deliveryShipping={deliveryShipping}
        isShowing={showTrackingModal}
        onUpdate={(data) => handleUpdateTracking(data)}
        onCancel={() => setShowTrackingModal(false)}
      />
    </div>
  );
};

TrackingInformation.defaultProps = {
  className: null,
};

TrackingInformation.propTypes = {
  delivery: PropTypes.shape({
    id: PropTypes.string,
    relationships: PropTypes.shape({
      deliveryShipping: PropTypes.shape({
        data: PropTypes.shape({
          id: PropTypes.number,
          trackingNumber: PropTypes.string,
          trackingUrl: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  className: PropTypes.string,
};

export default TrackingInformation;
