import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { usersBreadcrumbs } from "@ROM/CompanyAdmin/utils";
import { selectCurrentUser } from "@ROM/Users/selectors";
import Section from "@ROM-components/common/Section";
import UserForm from "@ROM/CompanyAdmin/Users/Edit";

const Page = ({ userId }) => {
  const breadcrumbs = usersBreadcrumbs([{ label: "User", active: true }]);
  const user = useSelector(selectCurrentUser);

  return (
    <Section title={user?.attributes.fullName || "User"} breadcrumbs={breadcrumbs}>
      <UserForm userId={userId} />
    </Section>
  );
};

Page.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default Page;
