import React, { useState, useReducer, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import useIsMobile from "@ROM-components/utils/Responsive";
import { submitOrder } from "@ROM/Orders/actions";
import { setCustomerForOrder } from "@ROM/Customers/actions";
import Button from "@ROM-ui/Button";
import Col from "@ROM-ui/Col";
import Row from "@ROM-ui/Row";
import OrderItemsList from "@ROM/Cart/OrderItems/components/List";
import { open, cancel } from "@ROM/OrderStatuses/actions";
import { ordersUrl, orderUrl } from "@ROM-utils/urlHelpers";
import FileUploader from "@ROM-common/FileUploader";
import Panel from "@ROM-ui/Panel";
import LoadingSplash from "@ROM-components/LoadingSplash/index";
import { updateCart, deleteAsset } from "@ROM/Cart/actions";
import { update as updateCartItem } from "@ROM/Cart/OrderItems/actions";
import { selectCartAssets } from "@ROM/Cart/selectors";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { reducer } from "@ROM/App/utils/forms";
import { MISSING_SHIP_DATE, MISSING_BILLING_ADDRESS, MISSING_SHIPPING_ADDRESS, MISSING_PO_NUMBER } from "@ROM-utils/errors";
import { canEditOrderCropAdvisor } from "@ROM/Orders/permissions";
import { canBeCanceled } from "@ROM/Orders/utils";
import { BILLING_TYPE, SHIPPING_TYPE } from "@ROM/Addresses/actions";
import { loadAvailableCropAdvisorsForCustomer } from "@ROM/Users/utils";
import PaymentMethodSelector from "@ROM/Payments/components/PaymentMethodSelector";

import AddressList from "./AddressList";
import FormErrorsModal from "./FormErrorsModal";
import OrderExtraInformation from "./OrderExtraInformation";
import SubmitModal from "../SubmitModal";

const OrderForm = ({ order, editing, customer }) => {
  const dispatch = useDispatch();

  const cartAssets = useSelector(selectCartAssets);
  const currentCompany = useSelector(selectCurrentCompany);
  const currentUser = useSelector(selectCurrentUser);
  const canEditCropAdvisor = canEditOrderCropAdvisor(currentUser, customer.id);
  const canCancel = canBeCanceled(order, currentUser, customer.id);

  const actions = {
    submitOrder: bindActionCreators(submitOrder, dispatch),
    setCustomerForOrder: bindActionCreators(setCustomerForOrder, dispatch),
    onConfirm: bindActionCreators(open, dispatch),
    onCancel: bindActionCreators(cancel, dispatch),
    updateCart: bindActionCreators(updateCart, dispatch),
    deleteAsset: bindActionCreators(deleteAsset, dispatch),
  };

  const history = useHistory();
  const isMobile = useIsMobile();

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const allowCreditCardUsage = currentCompany?.attributes?.allowCreditCardUsage ?? false;

  const initialized = order?.attributes ? true : false;
  const [shipAddressSameAsBilling, setShipAddressSameAsBilling] = useState(
    order?.attributes?.shippingAddressId === order?.attributes?.billingAddressId
  );
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(order?.attributes ? false : true);
  const [initializedBillingAddress, setInitializedBillingAddress] = useState(false);
  const [initializedShippingAddress, setInitializedShippingAddress] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [cropAdvisorsForCustomer, setCropAdvisorsForCustomer] = useState([]);

  const PoNumberFromInvoiceNumber = currentCompany?.attributes.autoPopulatePoNumber ? order?.attributes.invoiceNumber : "";
  const initialOrder = {
    id: order.id,
    notes: order?.attributes?.notes ?? null,
    poNumber: order?.attributes?.poNumber ? order?.attributes?.poNumber : PoNumberFromInvoiceNumber,
    shipDate: order?.attributes?.shipDate ? new Date(order.attributes.shipDate) : tomorrowDate,
    billingAddressId: order?.attributes?.billingAddressId ?? null,
    shippingAddressId: order?.attributes?.shippingAddressId ?? null,
    cropAdvisorId: order?.attributes?.cropAdvisorId ?? null,
    discountAmount: order?.attributes?.discountAmount || 0,
    discountType: order?.attributes?.discountType || "dollar",
    stripePaymentMethodId: order?.attributes?.stripePaymentMethodId ?? null,
  };

  const [state, setState] = useReducer(reducer, initialOrder);

  const validateOrder = () => {
    if (currentCompany.attributes.allowAttachmentOrders && cartAssets.length > 0) return true;

    const errors = [];
    if (!state.billingAddressId) {
      errors.push({ source: { pointer: "billingAddressError" }, detail: MISSING_BILLING_ADDRESS });
    }
    if (!state.shippingAddressId) {
      errors.push({ source: { pointer: "shippingAddressError" }, detail: MISSING_SHIPPING_ADDRESS });
    }
    if (!state.shipDate) {
      errors.push({ source: { pointer: "shipDateError" }, detail: MISSING_SHIP_DATE });
    }
    if (!state.poNumber) {
      errors.push({ source: { pointer: "poNumberError" }, detail: MISSING_PO_NUMBER });
    }
    if (errors.length > 0) {
      setErrorMessage(errors);
      setShowErrorModal(true);
      return false;
    }
    return true;
  };

  const handleDeleteAddress = (address) => {
    if (address.attributes.type === BILLING_TYPE && address.id === order.billingAddressId) {
      if (shipAddressSameAsBilling) {
        actions.updateCart(order.id, { ...order, billingAddressId: null, shippingAddressId: null });
        setState({ billingAddressId: null, shippingAddressId: null });
        setShipAddressSameAsBilling(false);
      } else {
        actions.updateCart(order.id, { ...order, billingAddressId: null });
        setState({ billingAddressId: null });
      }
    } else if (address.attributes.type === SHIPPING_TYPE && address.id === order.shippingAddressId) {
      actions.updateCart(order.id, { ...order, shippingAddressId: null });
      setState({ shippingAddressId: null });
    }
  };

  const handleBillingAddressSelected = (selectedAddressId) => {
    setState({ billingAddressId: selectedAddressId });

    if (shipAddressSameAsBilling) {
      setState({ shippingAddressId: selectedAddressId });
    }
    if (!initializedBillingAddress) {
      setInitializedBillingAddress(true);
    }

    actions.updateCart(order.id, state);
  };

  const disableSubmitButton = (uploading) => {
    setDisableSubmit(uploading);
  };

  const handleShipDate = (day) => {
    setState({ shipDate: day });
    actions.updateCart(order.id, state);
  };

  const handleShippingAddressSelected = (selectedAddressId) => {
    if (initializedShippingAddress) {
      setState({ shippingAddressId: selectedAddressId });
      setShipAddressSameAsBilling(false);
    } else {
      setState({ shippingAddressId: selectedAddressId });
      setInitializedShippingAddress(true);
      setShipAddressSameAsBilling(false);
    }

    actions.updateCart(order.id, state);
  };

  const handleShipSameAsBilling = () => {
    if (initializedShippingAddress) {
      setState({ shippingAddressId: state.billingAddressId });
      setShipAddressSameAsBilling(true);
    } else {
      setState({ shippingAddressId: state.billingAddressId });
      setShipAddressSameAsBilling(true);
      setInitializedShippingAddress(true);
    }

    actions.updateCart(order.id, state);
  };

  const handleOrderPaymentMethod = (paymentMethodId) => {
    setState({ stripePaymentMethodId: paymentMethodId });
  };

  const handleSubmitOrder = () => {
    if (validateOrder()) {
      actions.submitOrder(order.id, state).then((response) => {
        if (response.payload.status === 200) {
          if (editing) {
            history.goBack();
          } else {
            setShowStatusModal(true);
          }
        } else {
          setShowErrorModal(true);
          setErrorMessage(response.payload.data);
        }
      });
    }
  };

  const onDeleteAsset = (asset, setUploadingFiles, disableSubmitButton) => {
    actions
      .deleteAsset(order.id, asset.id)
      .then(() => {
        setUploadError("");
        disableSubmitButton(false);
        setUploadingFiles(false);
      })
      .catch(() => {
        setUploadError("There was an error uploading the asset, please try again later.");
        disableSubmitButton(false);
        setUploadingFiles(false);
      });
  };

  const afterAssetUpload = (assets, setUploadingFiles, disableSubmitButton) => {
    actions
      .updateCart(order.id, { ...state, assets })
      .then(() => {
        setUploadError("");
        disableSubmitButton(false);
        setUploadingFiles(false);
      })
      .catch(() => {
        setUploadError("There was an error uploading the asset, please try again later.");
        disableSubmitButton(false);
        setUploadingFiles(false);
      });
  };

  const handleConfirm = () => {
    actions.onConfirm({ orderId: order.id }).then((response) => {
      if (response.payload.status === 201) {
        actions.setCustomerForOrder(null);
        history.push(ordersUrl());
      } else {
        setErrorMessage(response.payload.data);
        setShowStatusModal(false);
        setShowErrorModal(true);
        window.scrollTo(0, document.body.scrollHeight);
      }
    });
  };

  const handleCancelOrder = () => {
    const promise = actions.onCancel({ orderId: order.id });
    promise.then(() => {
      if (editing) {
        history.push(orderUrl(order.id));
      } else {
        actions.setCustomerForOrder(null);
        history.push(ordersUrl());
      }
    });
  };

  const valueChanged = (event) => {
    const {
      target: {
        value,
        dataset: { field },
      },
    } = event;

    setState({ [field]: value });
  };

  const handleChangeDiscount = (type, amount) => {
    setState({ discountType: type, discountAmount: amount });
  };

  const handleChangeOrderItemDiscount = async (itemId, type, amount) => {
    setLoadingPrice(true);
    const payload = {
      id: itemId,
      attributes: { orderId: order.id, discountAmount: amount, discountType: type },
    };
    await dispatch(updateCartItem(payload));
    setLoadingPrice(false);
  };

  useEffect(() => {
    const fetchCropAdvisors = async () => {
      if (canEditCropAdvisor) {
        const cropAdvisors = await loadAvailableCropAdvisorsForCustomer({ customer, currentUser });
        setCropAdvisorsForCustomer(cropAdvisors);
      }
    };

    fetchCropAdvisors();
  }, []);

  useEffect(() => {
    setLoadingPrice(true);
    setDisableSubmit(true);
    const timer = setTimeout(() => {
      actions.updateCart(order.id, state).then(() => {
        setDisableSubmit(false);
        setLoadingPrice(false);
      });
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [state]);

  const ordersList = (
    <>
      <OrderItemsList
        loadingPrice={loadingPrice}
        setLoadingPrice={setLoadingPrice}
        onChangeOrderDiscount={handleChangeDiscount}
        onChangeOrderItemDiscount={handleChangeOrderItemDiscount}
        isMobile={isMobile}
        customer={customer}
      />
      {currentCompany.attributes.customMessage && (
        <div
          className={`${
            isMobile ? "small text-white bg-custom-brown" : "text-secondary bg-light rounded-bottom"
          } px-3 py-4 text-center `}
        >
          <p className="m-0 ">{currentCompany.attributes.customMessage}</p>
        </div>
      )}
    </>
  );

  return initialized === false ? (
    <LoadingSplash />
  ) : (
    <>
      <AddressList
        handleBillingAddressSelected={handleBillingAddressSelected}
        stateOrder={state}
        handleShippingAddressSelected={handleShippingAddressSelected}
        handleShipSameAsBilling={handleShipSameAsBilling}
        shipAddressSameAsBilling={shipAddressSameAsBilling}
        handleDeleteAddress={handleDeleteAddress}
        customer={customer}
        error={errorMessage}
        editing={editing}
        initializedBillingAddress={initializedBillingAddress}
        initializedShippingAddress={initializedShippingAddress}
        isMobile={isMobile}
      />

      {isMobile ? (
        <Row className="bg-custom-brown">
          <Col>
            <div className="text-white small fw-bold ms-4 my-3">Products*</div>
            {ordersList}
          </Col>
        </Row>
      ) : (
        <>
          <h5 className="text-white mt-5">Products*</h5>
          <Panel className={`mb-0 p-0 ${currentCompany.attributes.customMessage ? "py-0" : "pb-4"}`}>{ordersList}</Panel>
        </>
      )}

      <OrderExtraInformation
        valueChanged={valueChanged}
        handleShipDate={handleShipDate}
        stateOrder={state}
        company={currentCompany}
        isMobile={isMobile}
        currentUser={currentUser}
        canEditCropAdvisor={canEditCropAdvisor}
        cropAdvisorsForCustomer={cropAdvisorsForCustomer}
        errors={errorMessage}
      />

      <Row>
        {allowCreditCardUsage && (
          <Col xs={`${isMobile ? 12 : 6}`}>
            <div className="bg-white mt-5 rounded">
              <PaymentMethodSelector
                customer={customer}
                initialPaymentMethod={order.attributes.stripePaymentMethodId}
                handlePaymentMethodSelected={handleOrderPaymentMethod}
              />
            </div>
          </Col>
        )}

        <Col xs={`${isMobile || !allowCreditCardUsage ? 12 : 6}`}>
          <div className="bg-white p-4 my-5 rounded">
            <FileUploader
              disableSubmitButton={disableSubmitButton}
              afterAssetUpload={afterAssetUpload}
              deleteAsset={onDeleteAsset}
              currentAssets={cartAssets}
              error={uploadError}
            />
          </div>
        </Col>
      </Row>

      <Row className={isMobile ? "bg-white" : ""}>
        <Col className="d-flex justify-content-between py-3 px-3">
          {canCancel && (
            <Button onClick={() => setShowCancelModal(true)} variant="danger" id="cancel-order-btn">
              Cancel Order
            </Button>
          )}
          <Button
            onClick={handleSubmitOrder}
            className="ms-auto"
            variant="success"
            disabled={disableSubmit}
            id="order-form-confirm-btn"
          >
            {editing ? "Done" : "Submit Order"}
          </Button>
        </Col>
      </Row>

      <SubmitModal
        show={showStatusModal}
        onCancel={() => setShowStatusModal(false)}
        handleConfirm={handleConfirm}
        header="Are you ready to submit your order?"
      />
      <SubmitModal
        show={showCancelModal}
        onCancel={() => setShowCancelModal(false)}
        handleConfirm={handleCancelOrder}
        header="Are you sure you want to cancel the order?"
      />
      <FormErrorsModal show={showErrorModal} onCancel={() => setShowErrorModal(false)} errorMessage={errorMessage} />
    </>
  );
};

export default withRouter(OrderForm);

OrderForm.defaultProps = {
  editing: false,
};

OrderForm.propTypes = {
  editing: PropTypes.bool,
  order: PropTypes.shape().isRequired,
  customer: PropTypes.shape().isRequired,
};
