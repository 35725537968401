import React from "react";
import Button from "@ROM-ui/Button";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { invoice } from "@ROM/OrderStatuses/actions";

const Invoice = ({ order, reloadOrder, setError }) => {
  const dispatch = useDispatch();

  const handleInvoice = () => {
    dispatch(invoice({ orderId: order.id })).then((response) => {
      if (response.payload.status === 201) {
        reloadOrder();
      } else {
        setError(true);
      }
    });
  };

  return (
    <Button variant="outline-success" className="ms-3 me-2 px-4" size="sm" onClick={() => handleInvoice()}>
      Mark as Invoiced
    </Button>
  );
};

Invoice.propTypes = {
  order: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  reloadOrder: PropTypes.func.isRequired,
};

export default Invoice;
