import { customerAdminUrl } from "@ROM-utils/urlHelpers";
import { loadCropAdvisors } from "@ROM/Users/utils";

export const customersBreadcrumbs = (breadcrumbs = []) => [
  {
    label: "Customers",
    href: customerAdminUrl(),
    active: breadcrumbs.length === 0,
  },
  ...breadcrumbs,
];

export const loadCropAdvisorOptions = async (search, _loadedOptions, { page }) => {
  const { options, meta } = await loadCropAdvisors({ fullName: search, page });

  return {
    options,
    hasMore: meta.pagination.currentPage < meta.pagination.totalPages,
    additional: { page: page + 1 },
  };
};

export default { customersBreadcrumbs };
