import { cleanDecimals } from "@ROM/App/utils/format";
import { TRUCK_CARGO_LIMIT_LBS } from "@ROM/App/constants";
import { canCreateBillOfLadings, canEditBillOfLadings, canSeeBillOfLadings } from "@ROM/BillOfLadings/permissions";
import { isCancelled as isOrderCancelled } from "@ROM/Orders/utils";

/**
 * Returns billing information from bill of lading if available, or
 * the company's otherwise.
 */
export const bolBillingInformation = (billOfLading, company) => {
  const ba = billOfLading.attributes;
  const ca = company.attributes;
  const hasBilling =
    ba.billingName !== null ||
    ba.billingAddress1 !== null ||
    ba.billingAddress2 !== null ||
    ba.billingCity !== null ||
    ba.billingState !== null ||
    ba.billingZip !== null;
  if (!hasBilling) {
    return {
      billingName: ca.name,
      billingAddress1: ca.address1,
      billingAddress2: ca.address2,
      billingCity: ca.city,
      billingState: ca.state,
      billingZip: ca.zip,
      useCompanyBillingInformation: true,
    };
  }
  return {
    billingName: ba.billingName,
    billingAddress1: ba.billingAddress1,
    billingAddress2: ba.billingAddress2,
    billingCity: ba.billingCity,
    billingState: ba.billingState,
    billingZip: ba.billingZip,
    useCompanyBillingInformation: false,
  };
};

export const showDownloadBillOfLadings = (user) => canSeeBillOfLadings(user);

export const showEditBillOfLadings = (user, order) => !isOrderCancelled(order) && canEditBillOfLadings(user);

export const showCreateBillOfLadings = (user, order) => !isOrderCancelled(order) && canCreateBillOfLadings(user);

export const getPalletsWeight = (pallets) => cleanDecimals(pallets.reduce((acc, pallet) => acc + pallet.attributes.weight, 0));

export const getPalletsOverweight = (palletsWeight) => palletsWeight > TRUCK_CARGO_LIMIT_LBS;
