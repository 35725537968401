import React, { useState, useEffect } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import PropTypes from "prop-types";
import styled from "styled-components";

import { canSeeExtraInformation, canSeeOrdersFromOthers, canSeeOrdersFromOtherCustomers } from "@ROM/Orders/permissions";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";
import Select from "@ROM-components/common/Select";
import FilterBar from "@ROM-components/common/FilterBar";
import Button from "@ROM-ui/Button";
import AsyncPaginate from "@ROM-components/common/AsyncPaginate";
import CalendarInput from "@ROM-components/common/CalendarInput";

const Filters = ({
  filters,
  handleFilterChange,
  handleClearFilters,
  currentUser,
  creatorsLoadOptions,
  customerLoadOptions,
  customerInitialValue,
}) => {
  const allCreatorsValue = { value: null, label: "All Creators" };
  const allCustomersValue = { value: null, label: "All Customers" };

  const statusOptions = [
    { value: "all_active", label: "All Active" },
    { value: "all_statuses", label: "All Statuses" },
    { value: "cart", label: "Cart" },
    { value: "opened", label: "Opened" },
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
    { value: "confirmed", label: "Confirmed" },
    { value: "in_progress", label: "In Progress" },
    { value: "shipped", label: "Shipped" },
    { value: "invoiced", label: "Invoiced" },
    { value: "completed", label: "Completed" },
    { value: "cancelled", label: "Cancelled" },
    { value: "abandoned", label: "Abandoned" },
  ];

  const [selectedCustomer, setSelectedCustomer] = useState(customerInitialValue);
  const [selectedCreator, setSelectedCreator] = useState(allCreatorsValue);

  const handleDateChange = (date) => {
    handleFilterChange("beginDate", formatDate(date));
  };

  useEffect(() => {
    if (!filters.customerId) {
      setSelectedCustomer(allCustomersValue);
    }
    if (!filters.creator) {
      setSelectedCreator(allCreatorsValue);
    }
  }, [filters]);

  useEffect(() => {
    setSelectedCustomer(customerInitialValue);
  }, [customerInitialValue]);

  return (
    <FilterBar className="mb-4">
      <Row className="mb-3">
        <Col xs={12} md className="mb-2 mb-md-0">
          <Select
            placeholder="Status"
            options={statusOptions}
            onChange={(event) => handleFilterChange("status", event.value)}
            value={statusOptions.find((option) => option.value === filters.status)}
          />
        </Col>
        {canSeeOrdersFromOtherCustomers(currentUser) && (
          <Col xs={12} md className="mb-2 mb-md-0">
            <AsyncPaginate
              value={selectedCustomer}
              loadOptions={customerLoadOptions}
              onChange={(event) => {
                setSelectedCustomer(event);
                handleFilterChange("customerId", event.value);
              }}
              options={[allCustomersValue]}
              placeholder="Customer"
              additional={{
                page: 1,
              }}
            />
          </Col>
        )}
        <Col xs={12} md className="mb-2 mb-md-0">
          <Form.Control
            type="text"
            name="invoiceNumber"
            id="order-invoice-filter"
            placeholder="Invoice"
            onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
            value={filters.invoiceNumber || ""}
          />
        </Col>
        <Col xs={12} md className="mb-2 mb-md-0">
          <Form.Control
            type="text"
            name="poNumber"
            id="order-number-filter"
            placeholder="Order No."
            onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
            value={filters.poNumber || ""}
          />
        </Col>
        <Col xs={12} md className="mb-2 mb-md-0">
          <DayPickerInput
            value={filters.beginDate}
            onDayChange={handleDateChange}
            component={CalendarInput}
            formatDate={formatDate}
            parseDate={parseDate}
          />
        </Col>
        {canSeeExtraInformation(currentUser) && (
          <Col xs={12} md className="mb-2 mb-md-0">
            <AsyncPaginate
              value={selectedCreator}
              loadOptions={creatorsLoadOptions}
              onChange={(event) => {
                setSelectedCreator(event);
                handleFilterChange("creator", event.value ? event.label : null);
              }}
              options={[allCreatorsValue]}
              placeholder="Creator"
              additional={{
                page: 1,
                onlyOrderCreators: true,
              }}
            />
          </Col>
        )}
      </Row>
      <div className="d-flex align-items-center mb-n1">
        {canSeeOrdersFromOthers(currentUser) && (
          <FormCheckSmall
            type="checkbox"
            label="Show My Orders"
            id="order-only-my-orders-filter"
            className="text-nowrap me-4"
            checked={filters.onlyMyOrders}
            onChange={() => handleFilterChange("onlyMyOrders", !filters.onlyMyOrders)}
          />
        )}
        <Button className="p-0" size="sm" variant="link" onClick={handleClearFilters}>
          Clear Filters
        </Button>
      </div>
    </FilterBar>
  );
};

export default Filters;

const FormCheckSmall = styled(Form.Check)`
  font-size: 0.8rem;
`;

Filters.propTypes = {
  filters: PropTypes.shape().isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  customerInitialValue: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  creatorsLoadOptions: PropTypes.func.isRequired,
  customerLoadOptions: PropTypes.func.isRequired,
};
