import PropTypes from "prop-types";

const customerType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  isActive: PropTypes.bool,
  membershipsAttributes: PropTypes.arrayOf(PropTypes.shape()),
  name: PropTypes.string,
  needsApproval: PropTypes.bool,
  primaryContact: PropTypes.string,
  regionId: PropTypes.number,
  stripeId: PropTypes.string,
  taxExempt: PropTypes.bool,
});

export default customerType;
