import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "@ROM-ui/Button";
import OrdersApi from "@ROM/Orders/api";
import Modal from "@ROM-components/common/Modal";

const Charge = ({ order, reloadOrder }) => {
  const [showDestroyConfirmationModal, setShowDestroyConfirmationModal] = useState(false);
  const [error, setError] = useState(null);

  const handleCharge = async () => {
    try {
      await OrdersApi.charge(order.id);
      reloadOrder();
    } catch (e) {
      setError(e.data.detail || "Unexpected error occured. Please check if the payment has been made.");
      setShowDestroyConfirmationModal(false);
    }
  };

  return (
    <>
      <div>
        <Button variant="info" className="px-4" size="sm" onClick={() => setShowDestroyConfirmationModal(true)}>
          Charge the Order
        </Button>
        {error && <p className="text-danger mt-2">{error}</p>}
      </div>
      <Modal
        isShowing={showDestroyConfirmationModal}
        onConfirm={handleCharge}
        onCancel={() => setShowDestroyConfirmationModal(false)}
        header="Are you sure you to charge the order?"
        showCloseIcon={false}
      />
    </>
  );
};

Charge.propTypes = {
  order: PropTypes.object.isRequired,
  reloadOrder: PropTypes.func.isRequired,
};

export default Charge;
