import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import StickyBottomRow from "@ROM-ui/StickyRow/StickyBottomRow";
import StickyTopRow from "@ROM-ui/StickyRow/StickyTopRow";

import Amount from "@ROM/Cart/OrderItems/components/List/Amount";
import { OrderItemRow, OrderItemRowHeader } from "@ROM/Cart/OrderItems/components/OrderItemRow";
import OrderPayments from "@ROM/Orders/components/Payments";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { canSeeOrderDeliveries } from "@ROM/Deliveries/permissions";
import { canListOrderTransactions, canChargeOrders, canRefundOrders } from "@ROM/Orders/permissions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { showDeliveries, showPayments } from "@ROM/Orders/utils";
import { create } from "@ROM/Deliveries/actions";
import { selectAllDeliveries, selectAllDeliveryItems, selectAllDeliveryAssets } from "@ROM/Deliveries/selectors";
import OrderDeliveries from "@ROM/Deliveries/components/OrderDeliveries";
import { editDeliveryUrl } from "@ROM-utils/urlHelpers";

import Address from "./Address";
import Attachments from "./Attachments";
import ExtraInformation from "./ExtraInformation";

const Detail = ({ order, orderItems, orderAssets, customer, orderIncluded, associatedCropAdvisor, isMobile }) => {
  const history = useHistory();

  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const deliveries = useSelector(selectAllDeliveries);
  const deliveryItems = useSelector(selectAllDeliveryItems);
  const deliveryAssets = useSelector(selectAllDeliveryAssets);
  const currentCompany = useSelector(selectCurrentCompany);
  const currentUser = useSelector(selectCurrentUser);

  const handleCreateDelivery = async () => {
    const createDelivery = (options) => dispatch(create(options));
    const response = await createDelivery({ orderId: order.id });
    if (response.payload.status !== 200) {
      setError(true);
    }
  };

  const createDeliveryAndEdit = (event) => {
    event.stopPropagation();
    if (!deliveries?.length) {
      handleCreateDelivery(true);
    }
    history.push(editDeliveryUrl(order.id));
  };

  const billingAddress = <Address order={order} billing />;
  const shippingAddress = <Address order={order} />;

  const showDiscountColumn = orderItems.some((item) => item.attributes.discountAmount !== 0);

  const canListPayments = canListOrderTransactions(currentUser, customer?.id);
  const canCharge = canChargeOrders(currentUser, customer?.id);
  const canRefund = canRefundOrders(currentUser, customer?.id);

  return (
    <>
      {order && showPayments(order) && canListPayments && (
        <OrderPayments order={order} customer={customer} canCharge={canCharge} canRefund={canRefund} className="mb-3" />
      )}
      {order && order.attributes && (
        <>
          {canSeeOrderDeliveries(currentUser, order) && showDeliveries(order) && (
            <OrderDeliveries
              deliveries={deliveries}
              createDeliveryAndEdit={createDeliveryAndEdit}
              deliveryItems={deliveryItems}
              deliveryAssets={deliveryAssets}
              error={error}
              setError={setError}
              order={order}
              currentCompany={currentCompany}
              orderIncluded={orderIncluded}
              currentUser={currentUser}
            />
          )}
          {isMobile ? (
            <>
              <Row className="px-2 bg-white small" id="billing-address">
                <Col className="fw-bold ms-4 mt-3">Billing Address</Col>
              </Row>
              <Row className="px-2 bg-white small">
                <Col className="rounded shadow m-3 p-2">{billingAddress}</Col>
              </Row>
              <Row className="px-2 bg-white small">
                <Col className="fw-bold ms-4 mt-3">Shipping Address</Col>
              </Row>
              <Row className="px-2 bg-white small">
                <Col className="rounded shadow m-3 p-2">{shippingAddress}</Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="mt-5 mx-md-3 pb-md-3 text-center text-white text-md-left d-flex">
                <Col>
                  <h5 className="m-0 ">Billing Address</h5>
                </Col>
                <Col md={1} xs={0} />
                <Col>
                  <h5 className="ps-4 m-0 text-white">Shipping Address</h5>
                </Col>
              </Row>
              <Row className="mw-100 m-0 flex-column flex-md-row">
                <Col className="bg-white rounded shadow mt-3 mt-md-0 md-md-2 p-4 mw-100 mx-0">{billingAddress}</Col>
                <Col md={1} xs={0} />
                <Col className="bg-white rounded shadow mt-3 mt-md-0 md-md-2 p-4 mw-100 mx-0">{shippingAddress}</Col>
              </Row>
            </>
          )}

          {isMobile && (
            <Row className="bg-custom-brown">
              <Col className="text-white small fw-bold ms-4 my-3">Products</Col>
            </Row>
          )}
          <div>
            <StickyTopRow isMobile={isMobile} className={isMobile ? "" : "m-0 mt-5"}>
              <Col className={`px-4 ${isMobile ? "bg-custom-brown" : "rounded-top bg-white"} pb-3`}>
                <OrderItemRowHeader showDiscountColumn={showDiscountColumn} isShow />
              </Col>
            </StickyTopRow>
            <Row className={isMobile ? "" : "m-0"}>
              <Col className={`px-4 ${isMobile ? "bg-custom-brown" : "bg-white"} pb-3`}>
                {orderItems.map((item) => (
                  <OrderItemRow
                    key={item.id}
                    item={item}
                    isShow
                    showDiscountColumn={showDiscountColumn}
                    isTrial={order.attributes.isTrial}
                  />
                ))}
              </Col>
            </Row>
            <StickyBottomRow className={isMobile ? "" : "m-0"}>
              <Amount order={order} items={orderItems} customer={customer} company={currentCompany} isShow isMobile={isMobile} />
            </StickyBottomRow>
          </div>
          {currentCompany.attributes.customMessage &&
            (isMobile ? (
              <Row className="px-3 py-4 text-center bg-custom-brown text-white small">
                <p className="m-0">{currentCompany.attributes.customMessage}</p>
              </Row>
            ) : (
              <div className="px-3 py-4 text-center bg-light rounded-bottom text-secondary">
                <p className="m-0">{currentCompany.attributes.customMessage}</p>
              </div>
            ))}
          {isMobile ? (
            <Row className="bg-white px-3 pt-4">
              <ExtraInformation
                order={order}
                associatedCropAdvisor={associatedCropAdvisor}
                currentUser={currentUser}
                showDate={currentCompany.attributes.showOrderShipDate}
              />
            </Row>
          ) : (
            <>
              <Row className="px-2 text-center text-md-left">
                <Col>
                  <h5 className="text-white ps-3 ms-md-2 mt-5">Other information</h5>
                </Col>
              </Row>
              <div className="bg-white rounded shadow px-3 pt-4 pb-1">
                <ExtraInformation
                  order={order}
                  associatedCropAdvisor={associatedCropAdvisor}
                  currentUser={currentUser}
                  showDate={currentCompany.attributes.showOrderShipDate}
                />
              </div>
            </>
          )}

          {orderAssets.length > 0 && (
            <>
              {isMobile ? (
                <>
                  <Row className="pt-4 bg-white small">
                    <Col className="fw-bold ms-4 mt-3">Attachments</Col>
                  </Row>
                  <Row className="bg-white small">
                    <Col className="rounded shadow m-3 p-2">
                      <Attachments orderAssets={orderAssets} />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className="px-2 text-center text-md-left">
                    <Col>
                      <h5 className="text-white ps-3 ms-md-2 mt-5">Attachments</h5>
                    </Col>
                  </Row>
                  <div className="bg-white rounded shadow px-3 pb-1">
                    <Attachments orderAssets={orderAssets} />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

Detail.propTypes = {
  order: PropTypes.shape().isRequired,
  orderItems: PropTypes.arrayOf().isRequired,
  orderAssets: PropTypes.arrayOf(PropTypes.shape).isRequired,
  customer: PropTypes.shape().isRequired,
  orderIncluded: PropTypes.arrayOf().isRequired,
  associatedCropAdvisor: PropTypes.shape().isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default Detail;
