import React from "react";

const Logo = ({ currentCompany, height = "40", ...props }) => {
  return (
    <div className={`d-flex justify-content-center ${props?.className ?? ""}`}>
      {currentCompany.attributes.logo ? (
        <img
          src={currentCompany.attributes.logo.file.url}
          height={height}
          className="d-inline-block"
          alt={`Shop ${currentCompany.attributes.name}`}
        />
      ) : (
        `${currentCompany.attributes.name} Shop`
      )}
    </div>
  );
};

export default Logo;
