import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/warehouses";
  },

  resource(id) {
    return `/warehouses/${id}`;
  },
};

export default {
  list(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(), {
      params: {
        ...options,
      },
    });
  },

  find(id) {
    const client = getApiClient(API_URL);
    return client.get(urls.resource(id));
  },

  create(warehouse) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(), warehouse);
  },

  update(id, warehouse) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(id), warehouse);
  },

  destroy(id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(id));
  },
};
