import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/company_roles";
  },
  resource(id) {
    return `${urls.collection()}/${id}`;
  },
  notification(id) {
    return `${urls.resource(id)}/notification`;
  },
  permission(id) {
    return `${urls.resource(id)}/permission`;
  },
  relatedUsers(id) {
    return `${urls.resource(id)}/users`;
  },
};

export default {
  fetchCompanyRoles() {
    const client = getApiClient(API_URL);
    return client.get(urls.collection());
  },

  fetchCompanyRoleUsers(id) {
    const client = getApiClient(API_URL);
    return client.get(urls.relatedUsers(id));
  },

  createCompanyRole(params) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(), params);
  },

  updateCompanyRole(id, params) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(id), params);
  },

  destroyCompanyRole(id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(id));
  },

  addCompanyRoleNotification(id, params) {
    const client = getApiClient(API_URL);
    return client.post(urls.notification(id), params);
  },

  removeCompanyRoleNotification(id, params) {
    const client = getApiClient(API_URL);
    return client.delete(urls.notification(id), params);
  },

  updateCompanyRoleNotification(id, params) {
    const client = getApiClient(API_URL);
    return client.put(urls.notification(id), params);
  },

  addCompanyRolePermission(id, params) {
    const client = getApiClient(API_URL);
    return client.post(urls.permission(id), params);
  },

  updateCompanyRolePermission(id, params) {
    const client = getApiClient(API_URL);
    return client.put(urls.permission(id), params);
  },

  removeCompanyRolePermission(id, params) {
    const client = getApiClient(API_URL);
    return client.delete(urls.permission(id), params);
  },
};
