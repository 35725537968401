import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ProductUnitLabel = ({ text, icon, unit, ...rest }) => (
  <Container {...rest}>
    <ProductUnit>{unit}</ProductUnit>
    {icon && <img src={icon} width="25px" className="ms-1" />}
    <ProductLabel>{text}</ProductLabel>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ProductUnit = styled.span`
  white-space: nowrap;
  font-size: 1.5rem;
  line-height: 1;
`;

const ProductLabel = styled.div`
  white-space: pre;
  line-height: 1;
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-left: 0.5rem;
`;

ProductUnitLabel.defaultProps = {
  icon: null,
};

ProductUnitLabel.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  unit: PropTypes.number.isRequired,
};

export default ProductUnitLabel;
