import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Row from "@ROM-ui/Row";
import { useNavBarHeight } from "@ROM-components/utils/Responsive";

const StickyTopRow = ({ children, isMobile, ...props }) => {
  const navBarHeight = useNavBarHeight();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledTopRow $isMobile={isMobile} $navBarHeight={navBarHeight} {...props}>
      {children}
    </StyledTopRow>
  );
};

StickyTopRow.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const StyledTopRow = styled(Row)`
  position: ${(props) => (props.$isMobile ? "inherit" : "sticky")};
  top: ${(props) => (props.$isMobile ? "inherit" : `${props.$navBarHeight}px`)};
  z-index: 4;
`;

export default StickyTopRow;
