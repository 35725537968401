import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import ihUpdate from "immutability-helper";

import Button from "@ROM-ui/Button";
import Container from "@ROM-ui/Container";
import Col from "@ROM-ui/Col";
import Row from "@ROM-ui/Row";
import Spinner from "@ROM-ui/Spinner";
import IconPointer from "@ROM-ui/IconPointer";
import styled from "styled-components";

import { useHistory } from "react-router-dom";
import useIsMobile from "@ROM-components/utils/Responsive";

import { roundDigits } from "@ROM/App/utils/format";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { selectAllDeliveries, selectAllDeliveryItems } from "@ROM/Deliveries/selectors";
import { update, create } from "@ROM/Deliveries/DeliveryItems/actions";
import { destroy, create as createD, list as listD } from "@ROM/Deliveries/actions";

import { calculateQuantities, createDeliveryPdf } from "@ROM/Deliveries/utils";

import ActionSection from "@ROM/Deliveries/components/OrderDeliveries/ActionSection";
import DeleteConfirmationModal from "@ROM/Deliveries/components/common/DeleteConfirmationModal";
import DeliveryForm from "@ROM/Deliveries/components/common/DeliveryForm";
import DeliveryItemRow from "@ROM/Deliveries/components/common/DeliveryItemRow";
import DeliveryWeightWarning from "@ROM/Deliveries/components/common/DeliveryWeightWarning";
import ItemRow from "@ROM/Deliveries/components/common/ItemRow";
import ItemRowHeader from "@ROM/Deliveries/components/common/ItemRowHeader";

const DeliveriesForm = ({ order, orderIncluded, orderItems }) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [deliveryIdToDelete, setDeliveryIdToDelete] = useState(null);
  const [deliveryIdToDownload, setDeliveryIdToDownload] = useState(null);
  const [showNotesInputs, setShowNotesInputs] = useState(null);

  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const history = useHistory();

  const createDeliveryItem = (item) => dispatch(create(item));

  const createDelivery = (item) => dispatch(createD(item));
  const deleteDelivery = (deliveryId) => dispatch(destroy(deliveryId));
  const listDeliveries = (orderId) => dispatch(listD({ orderId }));

  useEffect(() => {
    if (order) {
      listDeliveries(order.id);
    }
  }, [order?.id]);

  const debouncedUpdateDeliveryItem = useCallback(
    _.debounce((deliveryItem) => dispatch(update(deliveryItem)), 500),
    []
  );

  const deliveries = useSelector(selectAllDeliveries);
  const deliveryItems = useSelector(selectAllDeliveryItems);
  const company = useSelector(selectCurrentCompany);

  const [quantities, setQuantities] = useState({ remaining: {}, selected: {} });
  useEffect(() => {
    setShowNotesInputs(deliveries.reduce((acc, delivery) => ({ ...acc, [delivery.id]: false }), {}));
  }, [deliveries]);

  useEffect(() => {
    setQuantities(calculateQuantities(orderItems, deliveries, deliveryItems));
  }, [orderItems, deliveries, deliveryItems]);

  const handleChange = (deliveryId, id, value) => {
    const available = quantities.remaining[id] + quantities.selected[deliveryId][id];

    if (value === "" || value === undefined || value === "0") return;
    const iValue = Number.parseFloat(value);

    if (iValue < 0 || iValue > available) return;

    const newQuantities = ihUpdate(quantities, {
      remaining: {
        [id]: { $set: roundDigits(available - iValue, 2) },
      },
      selected: {
        [deliveryId]: {
          [id]: { $set: iValue },
        },
      },
    });

    setQuantities(newQuantities);

    let deliveryItem = deliveryItems.find(
      (item) => item.attributes.orderItemId === id && item.attributes.deliveryId.toString() === deliveryId
    );

    deliveryItem = ihUpdate(deliveryItem, { attributes: { quantity: { $set: iValue } } });
    debouncedUpdateDeliveryItem(deliveryItem);
  };

  const handleAddAll = async (deliveryId) => {
    const entries = Object.entries(quantities.remaining);

    for (const entry of entries) {
      const [orderItemId, quantity] = entry;

      if (quantity !== 0) {
        if (!(deliveryId in quantities.selected)) {
          quantities.selected[deliveryId] = {};
        }
        await createDeliveryItem({
          orderItemId: orderItemId,
          deliveryId: deliveryId,
          quantity: quantity,
        });
        quantities.selected[deliveryId][orderItemId] = quantity;
        quantities.remaining[orderItemId] = 0;

        setQuantities({ ...quantities });
      }
    }
  };

  const handleCreateDelivery = () => {
    createDelivery({ orderId: order.id });
  };

  const handleDeleteDelivery = (deliveryId) => {
    if (deliveryId in quantities.selected) {
      Object.entries(quantities.selected[deliveryId]).forEach((entry) => {
        const [orderItemId, quantity] = entry;
        quantities.remaining[orderItemId] += quantity;
      });
      delete quantities.selected[deliveryId];
    }
    deleteDelivery(deliveryId);
    setShowConfirmDeleteModal(false);
  };

  const handlePdfDownload = async (deliveryId) => {
    setDeliveryIdToDownload(deliveryId);
    const delivery = deliveries.find((each) => each.id === deliveryId);
    const delItems = deliveryItems.filter((each) => each.attributes.deliveryId.toString() === deliveryId);
    await createDeliveryPdf(delivery.attributes.deliveryNumber, delItems, order, company, orderIncluded, false);
    setDeliveryIdToDownload(null);
  };

  const handleDeleteConfirm = (deliveryId) => {
    setShowConfirmDeleteModal(true);
    setDeliveryIdToDelete(deliveryId);
  };

  const handleShowNotes = (deliveryId) => {
    showNotesInputs[deliveryId] = !showNotesInputs[deliveryId];
    setShowNotesInputs({ ...showNotesInputs });
  };

  return (
    <Row className={`bg-white p-2 ${isMobile ? "" : "rounded-1"}`}>
      <div className="w-100 d-flex my-3 justify-content-end align-items-center">
        {deliveries.length > 0 && (
          <>
            <strong>Deliveries:</strong>
            {deliveries?.map((delivery, index) => (
              <a key={delivery.id} href={`#delivery-${delivery.id}`} className="link-unstyled ms-2">
                <div className="border px-2 rounded">{index + 1}</div>
              </a>
            ))}
          </>
        )}

        <Button onClick={handleCreateDelivery} variant="outline-primary" className="px-2 py-1 me-2 ms-4">
          <i className="fa fa-plus me-1" />
          New Delivery
        </Button>
      </div>

      <Container>
        <Row>
          <StyledCol md={6}>
            <ItemRowHeader />
            {orderItems?.map((item, index) => (
              <ItemRow
                key={item.id}
                item={item}
                index={index}
                orderId={order.id}
                quantities={quantities}
                setQuantities={setQuantities}
                deliveries={deliveries}
                deliveryItems={deliveryItems}
              />
            ))}
          </StyledCol>
          <StyledCol md={6}>
            {deliveries.map((delivery, index) => {
              const currentDeliveryItems = deliveryItems.filter((item) => item.attributes.deliveryId.toString() === delivery.id);
              return (
                <div key={delivery.id}>
                  <StyledDiv key={delivery.id} id={`delivery-${delivery.id}`}>
                    <div className="d-flex align-items-center mt-4">
                      <h5 className="m-0">
                        <strong>{`Delivery Document ${index + 1}`}</strong>
                      </h5>
                      {deliveryIdToDownload === delivery.id ? (
                        <Spinner animation="border" variant="primary" size="sm" className="mx-3" />
                      ) : (
                        <IconPointer onClick={() => handlePdfDownload(delivery.id)} className="fas fa-print mx-3 text-primary" />
                      )}
                      <IconPointer onClick={() => handleDeleteConfirm(delivery.id)} className="fas fa-trash me-3 text-danger" />
                      {(!delivery?.attributes?.notes || delivery?.attributes?.notes === "") && (
                        <Button className="py-1 px-2 mx-2" variant="outline-primary" onClick={() => handleShowNotes(delivery.id)}>
                          Add Notes
                        </Button>
                      )}
                    </div>
                    <div className="d-flex mt-2">
                      <ActionSection order={order} delivery={delivery} />
                    </div>

                    <DeliveryWeightWarning deliveryItems={currentDeliveryItems} className="mt-3" />

                    <DeliveryForm order={order} currentDelivery={delivery} showNotesInputs={showNotesInputs}>
                      <DropZone className="text-secondary">
                        {currentDeliveryItems.length === 0 && (
                          <Button className="p-1 px-2" variant="link" onClick={() => handleAddAll(delivery.id)}>
                            Add All Products
                          </Button>
                        )}
                        {currentDeliveryItems?.map((item, index) => (
                          <DeliveryItemRow
                            key={item.id}
                            item={item}
                            index={index}
                            handleChange={handleChange}
                            quantities={quantities}
                            setQuantities={setQuantities}
                            currentDelivery={delivery}
                          />
                        ))}
                      </DropZone>
                    </DeliveryForm>
                  </StyledDiv>
                </div>
              );
            })}
          </StyledCol>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end mt-3">
            <Button onClick={() => history.goBack()} variant="success" id="bol-confirm">
              Done
            </Button>
          </Col>
        </Row>
      </Container>

      <DeleteConfirmationModal
        show={showConfirmDeleteModal}
        onCancel={() => setShowConfirmDeleteModal(false)}
        handleConfirm={() => handleDeleteDelivery(deliveryIdToDelete)}
        header="Are you sure you want to delete the delivery?"
      />
    </Row>
  );
};

DeliveriesForm.propTypes = {
  order: PropTypes.shape().isRequired,
  orderIncluded: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  orderItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-width: 2px;
  border-radius: 4px;
  border-color: #707070;
  border-style: dashed;
  margin-top: 1.3rem;
  margin-bottom: 0.5rem;
`;

const StyledCol = styled(Col)`
  max-height: 60vh;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

const StyledDiv = styled.div`
  background-color: #eaeaea;
  border-radius: 0.25rem !important;
  padding: 1rem;
  flex-shrink: 0;
  margin-right: 0.5rem;
  height: 100%;
  margin-bottom: 1rem;
`;

export default DeliveriesForm;
