/* eslint-disable import/prefer-default-export */
import UsersApi from "@ROM/Users/api";
import CustomersApi from "@ROM/Customers/api";

export const loadCropAdvisors = async (params = {}) => {
  const result = await UsersApi.list({ ...params, permissions: ["company:crop-advisor"] });

  const {
    data: { meta },
  } = result;

  const options = result.data.data.map((option) => ({
    value: option.id,
    label: option.attributes.fullName,
  }));

  return { options, meta };
};

export const loadCropAdvisorsForCustomer = async (customer) => {
  const result = await CustomersApi.fetchCropAdvisors(customer.id);

  return result.data.data.map((option) => ({
    value: option.id,
    label: option.attributes.fullName,
  }));
};

export const loadAvailableCropAdvisorsForCustomer = async ({ customer, currentUser }) => {
  const { options: cropAdvisors } = await loadCropAdvisors({ regionId: customer.attributes.regionId });
  const customerCropAdvisors = await loadCropAdvisorsForCustomer(customer);

  const currentUserArray = currentUser ? [{ value: currentUser.id, label: currentUser.attributes.fullName }] : [];

  return [...currentUserArray, ...cropAdvisors, ...customerCropAdvisors].filter(
    (user, index, self) => index === self.findIndex((current) => current.value === user.value)
  );
};
