import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { addCompanyRolePermission, updateCompanyRolePermission, removeCompanyRolePermission } from "@ROM/CompanyRoles/actions";
import { selectPermissionsForCompanyRole } from "@ROM/CompanyRoles/selectors";
import ItemsGroupsList from "@ROM/CompanyAdmin/Company/Roles/ItemsGroupsList";

const PermissionsList = ({ permissions, activeRoleId, className }) => {
  const dispatch = useDispatch();

  const selectedPermissions = useSelector(selectPermissionsForCompanyRole(activeRoleId));

  const addPermission = async (permission) => {
    await dispatch(addCompanyRolePermission(activeRoleId, { permissionId: permission.id }));
  };

  const removePermission = async (permission) => {
    await dispatch(removeCompanyRolePermission(activeRoleId, { permissionId: permission.id }));
  };

  const updatePermissionScope = async (permission, scope) => {
    await dispatch(updateCompanyRolePermission(activeRoleId, { permissionId: permission.id, scope }));
  };

  return (
    <ItemsGroupsList
      className={className}
      itemsName="Permissions"
      items={permissions}
      selectedItems={selectedPermissions}
      add={addPermission}
      remove={removePermission}
      updateScope={updatePermissionScope}
    />
  );
};

PermissionsList.defaultProps = {
  className: "",
};

PermissionsList.propTypes = {
  className: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.shape).isRequired,
  activeRoleId: PropTypes.shape.isRequired,
};

export default PermissionsList;
