import React from "react";
import PropTypes from "prop-types";
import Badge from "@ROM-ui/Badge";
import humps from "humps";

import { IN_PROGRESS, PENDING, SHIPPED, DELIVERED } from "../../constants";
import { getStatusText } from "../../utils";

const getVariant = (status) => {
  switch (humps.camelize(status)) {
    case SHIPPED:
      return "success";
    case IN_PROGRESS:
      return "warning";
    case PENDING:
      return "secondary";
    case DELIVERED:
      return "primary";
    default:
      return "info";
  }
};

const DeliveryStatusBadge = ({ status, className }) => (
  <Badge className={`text-uppercase ${className}`} bg={getVariant(status)}>
    {getStatusText(status)}
  </Badge>
);

DeliveryStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

DeliveryStatusBadge.defaultProps = {
  className: "",
};

export default DeliveryStatusBadge;
