import React from "react";

const UsersTableHeader = () => {
  return (
    <tr>
      <th>Full Name</th>
      <th>E-Mail</th>
      <th>Regions</th>
      <th>Roles</th>
      <th>Created</th>
    </tr>
  );
};

UsersTableHeader.propTypes = {};

export default UsersTableHeader;
