import { createSelector } from "reselect";

const warehousesState = (state) => state.warehouses;

export const selectAllWarehouses = createSelector(warehousesState, (warehouses) => warehouses.all.items);
export const selectPermanentWarehouses = createSelector(
  warehousesState,
  (warehouses) => warehouses.all.items.filter((warehouse) => warehouse.attributes.temporary == null 
  || warehouse.attributes.temporary === false)
);
export const selectAllIncluded = createSelector(warehousesState, (warehouses) => warehouses.all.included);
export const selectWarehousesPagination = createSelector(warehousesState, (warehouses) => warehouses.pagination);
export const selectWarehousesLoading = createSelector(warehousesState, (warehouses) => warehouses.loading);
export const selectCurrentWarehouse = createSelector(warehousesState, (warehouses) => warehouses.current.warehouse);
