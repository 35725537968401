import update from "immutability-helper";
import { handle } from "redux-pack";
import { LIST, DESTROY, FIND, UPDATE, SET_PAGE } from "./actions";

const initialState = {
  loading: false,
  all: {
    items: [],
    included: [],
  },
  current: {
    warehouse: null,
    included: [],
  },
  pagination: {
    currentPage: 1,
    perPage: null,
    totalPages: null,
    totalRecords: null,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};
  switch (type) {
    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            loading: { $set: true },
          });
        },

        finish: (prevState) => {
          return update(prevState, {
            loading: { $set: false },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              included: { $set: json.included },
            },
            pagination: { $set: json.meta.pagination },
          });
        },
      });

    case FIND:
    case UPDATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            current: { warehouse: { $set: json.data }, included: { $set: json.included } },
          });
        },
      });

    case DESTROY:
      return handle(state, action, {
        success: (prevState) => {
          const warehouseId = action.meta.warehouseId;
          let warehouses = { ...prevState.all };
          warehouses.items = warehouses.items.filter((warehouse) => warehouse.id !== warehouseId);
          warehouses.included = warehouses.included.filter((included) => included.attributes.warehouseId !== warehouseId);
          return update(prevState, {
            all: { $set: warehouses },
          });
        },
      });

    case SET_PAGE:
      return update(state, {
        pagination: {
          currentPage: { $set: payload.page },
        },
      });

    default:
      return state;
  }
}

export default reducer;
