/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import Badge from "@ROM-ui/Badge";

const BooleanBadge = ({ value, ...props }) => {
  const boolToString = (boolValue) => (boolValue ? "Yes" : "No");

  return (
    <Badge bg={value ? "success" : "secondary"} {...props}>
      {boolToString(value)}
    </Badge>
  );
};

BooleanBadge.propTypes = {
  value: PropTypes.bool.isRequired,
};

export default BooleanBadge;
