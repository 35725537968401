import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/warehouse_inventories";
  },

  warehouses() {
    return "/warehouse_inventories/warehouses";
  },

  bulkUpdate() {
    return "/warehouse_inventories/bulk_update";
  },

  resource(id) {
    return `/warehouse_inventories/${id}`;
  },
};

export default {
  list(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(), {
      params: {
        ...options,
      },
    });
  },

  listWarehouses(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.warehouses(), options);
  },

  create(warehouseInventory) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(), warehouseInventory);
  },

  update(id, warehouseInventory) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(id), warehouseInventory);
  },

  bulkUpdate(warehouseInventories) {
    const client = getApiClient(API_URL);
    return client.put(urls.bulkUpdate(), warehouseInventories);
  },

  destroy(id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(id));
  },
};
