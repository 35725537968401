import { apiAction, standardAction } from "@ROM-utils/actions";
import CompanyApi from "./api";

export const CURRENT_COMPANY = "companies/api/CURRENT_COMPANY";
export const UPDATE_COMPANY = "companies/api/UPDATE_COMPANY";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";

export function currentCompany() {
  return apiAction(CURRENT_COMPANY, CompanyApi.currentCompany());
}

export function updateCompany(id, attributes) {
  return apiAction(UPDATE_COMPANY, CompanyApi.update(id, attributes));
}

export function clearMessages() {
  return standardAction(CLEAR_MESSAGES);
}
