import React from "react";
import { default as ReactSelectAsyncPaginate } from "react-select-async-paginate";
import PropTypes from "prop-types";

const AsyncPaginate = ({ ...props }) => {
  return (
    <ReactSelectAsyncPaginate
      {...props}
      menuPortalTarget={document.body}
      styles={{ ...props.styles, menuPortal: (base) => ({ ...base, zIndex: 10000 }) }}
    />
  );
};

AsyncPaginate.propTpyes = {
  props: PropTypes.object,
};

export default AsyncPaginate;
