import React from "react";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";

const OrderRow = ({ order, setSelectedOrder, selectedOrderId, deliveries }) => {
  moment.locale("en-us");
  const orderDeliveries = deliveries?.filter((delivery) => delivery.attributes.orderId == order.id);

  const handleClick = (order) => {
    if (selectedOrderId === order.id) {
      setSelectedOrder(null);
    } else {
      setSelectedOrder(order);
    }
  };

  return (
    <>
      {order ? (
        <StyledRow
          className="m-0 align-items-center p-3 border-bottom cursor-pointer"
          selected={order.id === selectedOrderId}
          onClick={() => handleClick(order)}
        >
          <Col md={3} className="text-md-left">
            {order.attributes.invoiceNumber}
          </Col>
          <Col md={3} className="text-md-left">
            {order.attributes.poNumber}
          </Col>
          <Col md={3} className="text-md-right">
            {moment(order.attributes.shipDate).format("MM/DD/YYYY")}
          </Col>
          <Col md={3} className="text-end">
            {orderDeliveries.length}
          </Col>
        </StyledRow>
      ) : (
        <StyledHeader className="m-0 fw-bold text-secondary p-3 border-bottom">
          <Col md={3} className="text-md-left">
            Order #
          </Col>
          <Col md={3} className="text-md-left">
            PO #
          </Col>
          <Col md={3} className="text-md-right">
            Deadline
          </Col>
          <Col md={3} className="text-md-right">
            Deliveries
          </Col>
        </StyledHeader>
      )}
    </>
  );
};

OrderRow.propTypes = {
  order: PropTypes.object,
  setSelectedOrder: PropTypes.func,
  selectedOrderId: PropTypes.number,
  deliveries: PropTypes.array,
};

export default OrderRow;

const StyledHeader = styled(Row)`
  background-color: #f8f8f8;
`;

const StyledRow = styled(Row)`
  background-color: ${(props) => (props.selected ? "#ebfcff" : "")};
  :hover {
    background-color: #ebfcff;
  }
`;
