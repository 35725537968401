import React from "react";
import PropTypes from "prop-types";
import Button from "@ROM-ui/Button";
import { useHistory } from "react-router-dom";
import { homeUrl } from "@ROM-utils/urlHelpers";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";

const SuccessMessage = ({ name }) => {
  let history = useHistory();

  return (
    <Row>
      <Col className="text-center">
        <div className="alert alert-success" role="alert">
          <p>Success! You are now part of {name}</p>
        </div>
        <Button onClick={() => history.push(homeUrl())}>Continue</Button>
      </Col>
    </Row>
  );
};

SuccessMessage.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SuccessMessage;
