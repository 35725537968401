import React from "react";
import PropTypes from "prop-types";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";

import useIsMobile from "@ROM-components/utils/Responsive";

const ContentHeader = ({ children, className }) => {
  const isMobile = useIsMobile();
  return <Row className={isMobile ? "mt-4 my-2" : `my-4 ${className}`}>{children}</Row>;
};

const ContentHeaderLeft = ({ children }) => {
  return <Col className="col-md-8">{children}</Col>;
};

const ContentHeaderRight = ({ children }) => {
  return <Col className="col-md-4 text-end">{children}</Col>;
};

ContentHeader.Left = ContentHeaderLeft;
ContentHeader.Right = ContentHeaderRight;

ContentHeader.defaultProps = {
  className: "",
};

ContentHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

export default ContentHeader;
