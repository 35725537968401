import update from "immutability-helper";
import { handle } from "redux-pack";
import { LIST, DESTROY, SET_PAGE, LIST_WITH_SCROLL, CREATE, FIND, UPDATE } from "./actions";

const initialState = {
  all: {
    items: [],
    included: [],
  },
  current: { priceList: null, included: [] },
  loading: false,
  pagination: {
    currentPage: 1,
    perPage: null,
    totalPages: null,
    totalRecords: null,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};

  switch (type) {
    case LIST_WITH_SCROLL:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $push: json.data },
              included: { $push: json.included },
            },
            pagination: { $set: json.meta.pagination },
            current: { priceList: { $set: null }, included: { $set: [] } },
          });
        },
      });

    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              included: { $set: json.included },
            },
            pagination: { $set: json.meta.pagination },
            current: { priceList: { $set: null }, included: { $set: [] } },
          });
        },
      });

    case FIND:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            current: { priceList: { $set: json.data }, included: { $set: json.included } },
          });
        },
      });

    case UPDATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const priceListId = json.data.id;
          const index = prevState.all.items.findIndex((item) => item.id == priceListId);
          return update(prevState, {
            current: { priceList: { $set: json.data }, included: { $set: json.included } },
            all: {
              items: {
                [index]: {
                  attributes: { $set: json.data.attributes },
                  relationships: { $set: json.data.relationships },
                },
              },
            },
          });
        },
      });

    case CREATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const priceLists = prevState.all.items;
          priceLists.splice(0, 0, json.data);
          return update(prevState, {
            all: {
              items: { $set: priceLists },
            },
          });
        },
      });

    case DESTROY:
      return handle(state, action, {
        success: (prevState) => {
          const priceListId = action.meta.priceListId;
          let priceLists = { ...prevState.all };
          priceLists.items = priceLists.items.filter((priceList) => priceList.id !== priceListId);
          return update(prevState, {
            all: { $set: priceLists },
          });
        },
      });

    case SET_PAGE:
      return update(state, {
        pagination: {
          currentPage: { $set: payload.page },
        },
      });

    default:
      return state;
  }
}

export default reducer;
