import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Section from "@ROM-components/common/Section";
import EditCustomer from "@ROM/Customers/components/Edit";
import { customersBreadcrumbs } from "@ROM/CustomerAdmin/utils";
import { selectCurrentCustomer } from "@ROM/Customers/selectors";
import { useDispatch, useSelector } from "react-redux";
import { find } from "@ROM/Customers/actions";
import CustomerContext from "@ROM/Customers/contexts/CustomerContext";
import Header from "@ROM/CustomerAdmin/components/Header";
import { selectLoading, selectOrders, selectOrdersPagination } from "@ROM/Orders/selectors";
import { setPage, getFilteredOrders } from "@ROM/Orders/actions";
import { customerAdminManageCustomerUrl } from "@ROM-utils/urlHelpers";

const Page = ({ customerId, tab }) => {
  const breadcrumbs = customersBreadcrumbs([{ label: "Edit", active: true }]);
  const dispatch = useDispatch();
  const history = useHistory();
  const customer = useSelector(selectCurrentCustomer);
  const orders = useSelector(selectOrders);
  const ordersLoading = useSelector(selectLoading);
  const pagination = useSelector(selectOrdersPagination);

  useEffect(() => {
    dispatch(find(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    if (customerId) dispatch(getFilteredOrders({ customerId }));
  }, [customerId]);

  const handlePageClick = (page) => {
    dispatch(setPage(page));
    dispatch(getFilteredOrders({ customerId, page }));
  };

  const setTab = (tabName) => {
    history.replace(customerAdminManageCustomerUrl(customerId, tabName));
  };

  return (
    <CustomerContext.Provider value={customer}>
      <Header orders={orders} loading={ordersLoading} pagination={pagination} />
      <Section breadcrumbs={breadcrumbs}>
        <EditCustomer
          orders={orders}
          ordersLoading={ordersLoading}
          pagination={pagination}
          handlePageClick={handlePageClick}
          tab={tab}
          setTab={setTab}
        />
      </Section>
    </CustomerContext.Provider>
  );
};

Page.propTypes = {
  customerId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

export default Page;
