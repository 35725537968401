import React, { useState } from "react";
import PropTypes from "prop-types";
import PointerSpan from "@ROM-ui/PointerSpan";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Button from "@ROM-ui/Button";
import Form from "@ROM-ui/Form";

import { update } from "@ROM/BillOfLadings/actions";
import { useDispatch } from "react-redux";

const DeliveryItemRowHeader = ({ order, billOfLading }) => {
  const dispatch = useDispatch();

  const [showNameEditor, setShowNameEditor] = useState(false);
  const [customName, setCustomName] = useState(billOfLading?.attributes?.customName || order.attributes.poNumberorder);

  const updateCustomName = () => {
    dispatch(update(billOfLading.id, { customName }));
    setShowNameEditor(false);
  };

  const renderOrderName = () => (
    <h5 className="m-0">
      {`Order: ${billOfLading?.attributes?.customName || order.attributes.poNumber} `}
      <PointerSpan
        onClick={() => setShowNameEditor(true)}
        className="fa fa-edit text-primary"
        title="Edit Bill of Landing name"
      />
    </h5>
  );

  const renderNameEditor = () => (
    <div className="my-3">
      <Row>
        <Form.Control type="text" value={customName} onChange={(e) => setCustomName(e.target.value)} />
      </Row>
      <Row className="my-2 d-flex justify-content-between">
        <Button className="" onClick={() => setShowNameEditor(false)} variant="danger">
          Cancel
        </Button>
        <Button className="" onClick={updateCustomName} variant="success">
          Update
        </Button>
      </Row>
    </div>
  );

  return (
    <Row className="px-3 fw-bold text-secondary">
      <Col xs={12} md={5}>
        {showNameEditor ? renderNameEditor() : renderOrderName()}
        <h6 className="fw-normal">{`Invoice: ${order.attributes.invoiceNumber}`}</h6>
      </Col>
      <Col xs={4} md={2} className="text-md-center d-none d-md-block">
        Product Units
      </Col>
      <Col xs={3} md={2} className="text-md-center d-none d-md-block">
        Remaining Units
      </Col>
      <Col xs={3} md={2} className="text-md-center d-none d-md-block">
        Shipping Weight
      </Col>
      <Col xs={2} md={1} />
    </Row>
  );
};

DeliveryItemRowHeader.propTypes = {
  order: PropTypes.shape({
    attributes: {
      poNumber: PropTypes.string,
      invoiceNumber: PropTypes.string,
    },
  }).isRequired,
  billOfLading: PropTypes.shape().isRequired,
};

export default DeliveryItemRowHeader;
