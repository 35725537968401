import { apiAction } from "@ROM-utils/actions";
import CategoriesApi from "./api";

export const LIST = "categories/api/LIST";
export const CREATE = "categories/api/CREATE";
export const CREATE_SUBCATEGORY = "categories/api/CREATE_SUBCATEGORY";
export const DELETE = "categories/api/DELETE";
export const DELETE_SUBCATEGORY = "categories/api/DELETE_SUBCATEGORY";

export function list(options = {}) {
  return apiAction(LIST, CategoriesApi.list(options));
}

export function createCategory(options = {}) {
  return apiAction(CREATE, CategoriesApi.create(options));
}

export function createSubcategory(categoryId, options = {}) {
  return apiAction(CREATE_SUBCATEGORY, CategoriesApi.createSubcategory(categoryId, options));
}

export function deleteCategory(categoryId, options = {}) {
  return apiAction(DELETE, CategoriesApi.delete(categoryId, options), { categoryId });
}

export function deleteSubcategory(categoryId, subcategoryId, options = {}) {
  return apiAction(DELETE_SUBCATEGORY, CategoriesApi.deleteSubcategory(categoryId, subcategoryId, options), { subcategoryId });
}
