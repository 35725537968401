import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/payments";
  },
};

export default {
  createSetupIntent(attributes = {}) {
    const client = getApiClient(API_URL);
    return client.post(`${urls.collection()}/create_setup_intent`, attributes);
  },
};
