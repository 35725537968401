import React from "react";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import PropTypes from "prop-types";

const Address = ({ order, billing = false }) => {
  const { attributes } = order;

  const extraInformation = billing
    ? `${attributes.billingCity}, ${attributes.billingState} ${attributes.billingZip}${
        attributes.billingCountryName ? `, ${attributes.billingCountryName}` : ""
      }`
    : `${attributes.shippingCity}, ${attributes.shippingState} ${attributes.shippingZip}${
        attributes.shippingCountryName ? `, ${attributes.shippingCountryName}` : ""
      }`;

  const addressIdPressent = (billing ? attributes.billingAddressId : attributes.shippingAddressId) !== null;

  return (
    <>
      {addressIdPressent ? (
        <Row>
          <Col sm={12} className="ps-md-4">
            <p className="m-0">
              <strong>{billing ? attributes.billingCustomerCompanyName : attributes.shippingCustomerCompanyName}</strong>
            </p>
          </Col>
          <Col lg={12} xl={7} className="ps-md-4">
            <p className="m-0">
              <strong>{billing ? attributes.billingAddress1 : attributes.shippingAddress1}</strong>
            </p>
            <p className="m-0">
              {billing ? attributes.billingAddress2 : attributes.shippingAddress2 && attributes.shippingAddress2}
            </p>
            <p className="m-0">{extraInformation && extraInformation}</p>
          </Col>
          <Col lg={12} xl={5} className="ps-xl-2 ps-md-4">
            <p className="m-0">
              <strong>{billing ? attributes.billingContact : attributes.shippingContact}</strong>
            </p>
            <p className="m-0">
              {billing
                ? attributes.billingPhone && attributes.billingPhone
                : attributes.shippingPhone && attributes.shippingPhone}
            </p>
            {(attributes.billingEmail || attributes.shippingEmail) && (
              <a href={`mailto:${billing ? attributes.billingEmail : attributes.shippingEmail}`} className="m-0">
                <p className="m-0 text-break">{billing ? attributes.billingEmail : attributes.shippingEmail}</p>
              </a>
            )}
          </Col>
        </Row>
      ) : (
        <p>No address</p>
      )}
    </>
  );
};

Address.propTypes = {
  order: PropTypes.object,
  billing: PropTypes.bool,
};

export default Address;
