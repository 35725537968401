import React, { useState } from "react";
import PropTypes from "prop-types";

import Form from "@ROM-ui/Form";
import AsyncPaginate from "@ROM-components/common/AsyncPaginate";
import Modal from "@ROM-components/common/Modal";

import { loadOptions } from "@ROM/Customers/utils";

const AddCustomerModal = ({ visible, skipCustomers, onAddCustomer, onCancel }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const confirmAdd = () => {
    if (selectedCustomer === null) return;
    onAddCustomer(selectedCustomer.value, isAdmin);
  };

  const filteredLoadOptions = async (...props) => {
    const unfiltered = await loadOptions(...props);
    return {
      ...unfiltered,
      options: unfiltered.options.filter((option) => !skipCustomers.includes(option.value)),
    };
  };

  return (
    <Modal confirmText="Add" isShowing={visible} onConfirm={confirmAdd} onCancel={onCancel} header="Select Customer">
      <AsyncPaginate
        value={selectedCustomer}
        loadOptions={filteredLoadOptions}
        onChange={(event) => setSelectedCustomer(event)}
        options={[]}
        placeholder="Select Customer"
        additional={{ page: 1 }}
      />
      <Form.Check className="mt-3" checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} label="Customer Admin?" />
    </Modal>
  );
};

AddCustomerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onAddCustomer: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  skipCustomers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AddCustomerModal;
