import React from "react";
import PropTypes from "prop-types";
import { Pagination as BootstrapPagination } from "react-bootstrap";
import styled from "styled-components";

import { paginationParts } from "./utils";

const Pagination = ({ pagination, handlePageClick, align, className, ...props }) => {
  const { totalPages: pages, currentPage: page } = pagination;
  const parts = pages && page ? paginationParts(pages, page) : [];

  const translateAlign = (alignKey) => {
    const translation = { left: "start", center: "center", right: "end" };
    return translation[alignKey];
  };

  return (
    pages > 1 && (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <PaginationOnBottom className={`d-flex justify-content-${translateAlign(align)}`} {...props}>
        <First
          key="first"
          active={page === 1}
          disabled={page === 1}
          onClick={() => {
            handlePageClick(1);
          }}
        />
        {parts.map((value, idx) => {
          const active = value === page.toString();
          const disabled = value === "...";
          const key = value === "..." ? `...${idx}` : value;

          return (
            <Item
              key={key}
              active={active}
              disabled={disabled}
              onClick={
                !active
                  ? () => {
                      handlePageClick(value);
                    }
                  : null
              }
            >
              {value}
            </Item>
          );
        })}
        <Last
          key="last"
          active={page === pages}
          disabled={page === pages}
          onClick={() => {
            handlePageClick(pages);
          }}
        />
      </PaginationOnBottom>
    )
  );
};

export default Pagination;

const PaginationOnBottom = styled(BootstrapPagination)`
  z-index: 0 !important;
`;

const Item = styled(BootstrapPagination.Item)`
  z-index: 0 !important;
`;

const First = styled(BootstrapPagination.First)`
  z-index: 0 !important;
`;

const Last = styled(BootstrapPagination.Last)`
  z-index: 0 !important;
`;

Pagination.defaultProps = {
  align: "left",
  className: "",
};

Pagination.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  pagination: PropTypes.shape().isRequired,
  handlePageClick: PropTypes.func.isRequired,
  props: PropTypes.shape(),
};

Pagination.defaultProps = {
  props: null,
};
