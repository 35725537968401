import React from "react";
import PropTypes from "prop-types";

const FilterBar = ({ children, className }) => {
  return <div className={`shadow-sm bg-white rounded-2 text-black-50 p-3 ${className}`}>{children}</div>;
};

FilterBar.defaultProps = {
  className: "",
};

FilterBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FilterBar;
