import React, { useState } from "react";
import Button from "@ROM-ui/Button";
import Form from "@ROM-ui/Form";
import Col from "@ROM-ui/Col";
import Row from "@ROM-ui/Row";
import Section from "@ROM-components/common/Section";
import NewCustomerModal from "@ROM/Customers/components/Modal/NewCustomerModal";
import { loadOptions } from "@ROM/Customers/utils";
import AsyncPaginate from "@ROM-components/common/AsyncPaginate";
import styled from "styled-components";
import PropTypes from "prop-types";
import { canCreateCustomers } from "@ROM/Customers/permissions";

const SelectCustomer = ({ findCustomer, currentCustomer, onSelectCustomer, currentUser }) => {
  const [value, setValue] = useState(null);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleCustomerSelected = (option) => {
    findCustomer(option.value).then(() => setDisabled(false));
  };

  const handleSubmit = () => {
    onSelectCustomer(currentCustomer);
  };
  return (
    <Section>
      <StyledContainer className="mt-5">
        <Form>
          <Row>
            <Col xs={12} sm={6}>
              <h2>Select Customer</h2>
            </Col>
            <Col xs={12} sm={6}>
              {canCreateCustomers(currentUser) && (
                <Button className="py-1 float-end mb-1" variant="outline-primary" onClick={() => setShow(true)}>
                  <i className="fa fa-plus me-1" />
                  Create new customer
                </Button>
              )}
            </Col>
          </Row>

          <div className="mt-2 d-flex">
            <AsyncPaginate
              className="flex-grow-1 me-2"
              id="new-order-customer-selector"
              instanceId="new-order-customer-selector-instance"
              inputId="new-order-customer-selector-input"
              value={value}
              loadOptions={loadOptions}
              onChange={(event) => {
                setValue(event);
                handleCustomerSelected(event);
              }}
              placeholder="Make a selection"
              additional={{ page: 1 }}
            />
            <Button id="new-order-continue-btn" className="flex-grow-0" onClick={handleSubmit} disabled={disabled}>
              Continue
            </Button>
          </div>
        </Form>
        <NewCustomerModal show={show} onCancel={() => setShow(false)} />
      </StyledContainer>
    </Section>
  );
};

SelectCustomer.propTypes = {
  findCustomer: PropTypes.func.isRequired,
  currentCustomer: PropTypes.shape().isRequired,
  onSelectCustomer: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
};

export default SelectCustomer;

const StyledContainer = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 15px;
`;
