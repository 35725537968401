import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";

import { newOrderUrl } from "@ROM-utils/urlHelpers";
import { getFilteredOrders } from "@ROM/Orders/actions";
import { selectLoading, selectOrders, selectOrdersPagination } from "@ROM/Orders/selectors";
import { selectSessionCustomer, selectCurrentUser } from "@ROM/Auth/selectors";
import { canSeeOrdersFromOthers } from "@ROM/Orders/permissions";
import { loadOptions as customerLoadOptions, fetchUsers as creatorsLoadOptions } from "@ROM/Customers/utils";
import useDebounceEffect from "@ROM-hooks/useDebounceEffect";
import useQueryStringFilters from "@ROM-hooks/useQueryStringFilters";
import CustomersApi from "@ROM/Customers/api";
import Button from "@ROM-ui/Button";
import ContentHeader from "@ROM-components/common/ContentHeader";
import OrdersList from "@ROM/Orders/components/List";
import Filters from "@ROM/Orders/components/Filters";
import Pagination from "@ROM-components/common/Pagination";

const Index = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const orders = useSelector(selectOrders);
  const ordersLoading = useSelector(selectLoading);
  const pagination = useSelector(selectOrdersPagination);
  const selectedCustomer = useSelector(selectSessionCustomer);

  const initialFilters = {
    status: "all_active",
    invoiceNumber: null,
    poNumber: null,
    beginDate: null,
    onlyMyOrders: !canSeeOrdersFromOthers(currentUser),
    creator: null,
    customerId: null,
    sort: "-createdAt",
    regionId: null,
    page: 1,
  };

  const [filters, setFilter] = useQueryStringFilters(initialFilters, { setInitialFiltersToQueryString: true });
  const [customerInitialValue, setCustomerInitialValue] = useState({ value: null, label: "All Customers" });

  const handleFilterChange = (name, value) => {
    // Filter changes return to 1st page, except when changing the page.
    setFilter({ page: 1, [name]: value });
  };

  const handleClearFilters = () => {
    setFilter(initialFilters);
  };

  const handlePageClick = (page) => {
    handleFilterChange("page", page);
  };

  const handleColumnSort = (field) => {
    const sortField = field;
    handleFilterChange("sort", `${filters.sort === sortField ? `-${sortField}` : sortField}`);
  };

  const orderCount = (quantity) => {
    if (quantity > 1) {
      return `${quantity} Total Orders`;
    }
    if (quantity === 1) {
      return "One Order";
    }
    return "No Orders";
  };

  useDebounceEffect(() => {
    dispatch(getFilteredOrders(filters));
  }, [filters]);

  useEffect(() => {
    if (filters.customerId) {
      CustomersApi.find(filters.customerId)
        .then((customer) => {
          setCustomerInitialValue({
            value: filters.customerId,
            label: customer.data.data.attributes.name,
          });
        })
        .catch(() => {
          handleFilterChange("customerId", null);
        });
    }
  }, []);

  return (
    <>
      <ContentHeader>
        <ContentHeader.Left>
          <h3 className="fw-bolder text-white">My Orders</h3>
          <h5 className="fw-normal text-white">{orderCount(pagination.totalRecords)}</h5>
        </ContentHeader.Left>
        <ContentHeader.Right>
          <LinkContainer to={newOrderUrl()} className="float-end">
            <Button variant="outline-primary" className="px-5 bg-white text-primary btn" as="a" id="order-creation-btn">
              {selectedCustomer ? "Continue with the Order" : "Start a New Order"}
            </Button>
          </LinkContainer>
        </ContentHeader.Right>
      </ContentHeader>

      <Filters
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleClearFilters={handleClearFilters}
        currentUser={currentUser}
        creatorsLoadOptions={creatorsLoadOptions}
        customerInitialValue={customerInitialValue}
        customerLoadOptions={customerLoadOptions}
      />

      <ScrollableDiv className="shadow-sm p-2 bg-white rounded-2 mb-2">
        <OrdersList
          loading={ordersLoading}
          orders={orders}
          handleColumnSort={handleColumnSort}
          filters={filters}
          currentUser={currentUser}
        />
      </ScrollableDiv>
      <Pagination pagination={pagination} handlePageClick={handlePageClick} align="center" />
    </>
  );
};

const ScrollableDiv = styled.div`
  overflow-x: auto;
  .row {
    flex-wrap: nowrap;
  }
  @media (min-width: 768px) {
    .row:not(:first-child) {
      background-color: #f8f9fa !important;
    }
  }
`;

export default Index;
