import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { selectCurrentCompany } from "@ROM/Company/selectors";
import { update as updateCartItem, create as createOrderItem } from "@ROM/Cart/OrderItems/actions";
import { selectCartOrderId, selectCartOrderItemByProductId } from "@ROM/Cart/selectors";
import PalletPopover from "./PalletPopover";

const palletsStr = (quantity, packagesPerPallet) => {
  if (quantity < packagesPerPallet) {
    return null;
  }
  const pallets = Math.floor(quantity / packagesPerPallet);
  return `${pallets}${quantity % packagesPerPallet === 0 ? "" : "+"} pallet${pallets !== 1 ? "s" : ""}`;
};

const QuantityInput = ({ productId, popoverStyles, packagesPerPallet, isMobile, className }) => {
  const currentCompany = useSelector(selectCurrentCompany);
  const orderId = useSelector(selectCartOrderId);
  const item = useSelector((state) => selectCartOrderItemByProductId(state, productId));

  const dispatch = useDispatch();

  const palletSelectorEnabled = currentCompany?.attributes?.palletSelectorEnabled;

  const [inFocus, setInFocus] = useState(false);
  const [quantity, setQuantity] = useState((item && item.attributes && item.attributes.quantity) || 0);
  const [show, setShow] = useState(false);
  const inputRef = useRef(null);
  const isInitialMountQuantity = useRef(true);

  const [creatingOrderItem, setCreatingOrderItem] = useState(false);
  const [error, setError] = useState(null);

  const handleItemQuantity = async (itemInCart, newQuantity) => {
    const oldQuantity = itemInCart.attributes.quantity;
    let finalQuantity = parseFloat(newQuantity);
    finalQuantity = Number.isNaN(finalQuantity) ? 0 : finalQuantity;

    if (finalQuantity === 0) {
      return;
    }

    const newItem = { ...itemInCart, attributes: { ...itemInCart.attributes, quantity: finalQuantity } };
    await dispatch(updateCartItem(newItem, { oldQuantity }));
  };

  const handleAddToCart = async () => {
    if (quantity === 0) {
      return;
    }

    setCreatingOrderItem(true);
    await dispatch(
      createOrderItem({
        quantity,
        orderId,
        productId,
      })
    );
    setCreatingOrderItem(false);
  };

  const resetItemQuantityIfNeeded = () => {
    if (quantity === 0 || quantity === "") {
      if (item) {
        setQuantity(item.attributes.quantity);
      } else {
        setQuantity(0);
      }
    }
  };

  useEffect(() => {
    if (item && !inFocus && !show && item?.attributes && item?.attributes?.quantity !== quantity) {
      setQuantity(item?.attributes?.quantity);
    } else if (!item) {
      setQuantity(0);
    }
  }, [item]);

  useEffect(() => {
    if (!show) {
      resetItemQuantityIfNeeded();
    }
  }, [show]);

  useEffect(() => {
    if (isInitialMountQuantity.current) {
      isInitialMountQuantity.current = false;
    } else {
      let timeOut = null;

      if (item && quantity === 0) {
        setError("Quantity can't be 0");
      } else if (item && quantity === "") {
        setError("Quantity can't be empty");
      } else {
        setError(null);
      }

      if (item) {
        if (item?.attributes?.quantity !== quantity) {
          timeOut = setTimeout(() => handleItemQuantity(item, quantity), 500);
          return () => clearTimeout(timeOut);
        }
      } else {
        handleAddToCart();
      }
    }
    return undefined;
  }, [quantity]);

  const handleAddPallet = () => {
    if (creatingOrderItem) return;
    let newQuantity = parseFloat(quantity + packagesPerPallet);
    newQuantity = parseFloat(parseFloat(newQuantity).toFixed(3));
    setQuantity(newQuantity);
  };

  const handleRemovePallet = () => {
    if (creatingOrderItem) return;
    let newQuantity = parseFloat(quantity - packagesPerPallet);
    newQuantity = parseFloat(parseFloat(newQuantity).toFixed(3));
    setQuantity(newQuantity);
  };

  const handleChange = (newQuantity) => {
    if (newQuantity === "") {
      setQuantity(newQuantity);
    } else {
      setQuantity(parseFloat(parseFloat(newQuantity).toFixed(3)));
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!show) setShow(true);
  };

  const handleOnFocus = (e) => {
    setInFocus(true);
    e.target.select();
    if (!show) setShow(true);
  };

  const handleOnBlur = () => {
    setInFocus(false);
    resetItemQuantityIfNeeded();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      setShow(false);
    }
  };

  const palletStr = palletsStr(quantity, packagesPerPallet);

  return (
    <div className="position-relative">
      {show && packagesPerPallet && palletSelectorEnabled && (
        <PalletPopover
          handleAddPallet={handleAddPallet}
          handleRemovePallet={handleRemovePallet}
          packagesPerPallet={packagesPerPallet}
          setShow={setShow}
          inputRef={inputRef}
          style={popoverStyles}
          creatingOrderItem={creatingOrderItem}
        />
      )}

      <div className={`my-2 position-relative ${className}`}>
        {error && <ErrorContainer>{error}</ErrorContainer>}
        <StyledInput
          id={`quantity-input-${productId}`}
          className="form-control h-100"
          type="number"
          onChange={(e) => handleChange(e.target.value)}
          onClick={handleClick}
          value={quantity}
          show={show}
          ref={inputRef}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onKeyDown={handleKeyDown}
          readOnly={creatingOrderItem}
        />
        {palletStr && (
          <StyledAddedPallets show={show} isMobile={isMobile}>
            {palletStr}
          </StyledAddedPallets>
        )}
      </div>
    </div>
  );
};

const StyledInput = styled.input`
  position: relative;
  z-index: ${(props) => (props.show ? "3" : "1")};
`;

const StyledAddedPallets = styled.p`
  font-size: x-small;
  margin: 0;
  padding: 0;
  margin-top: 4px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-style: italic;
  background-color: ${(props) => (props.isMobile ? "#2499e7" : "#c4ecff")};
  border-radius: 0.25rem;
  z-index: ${(props) => (props.show ? "3" : "1")};
  position: relative;
  text-align: center;
`;

const ErrorContainer = styled.div`
  position: absolute;
  bottom: 100%;
  background-color: #fee2e2;
  border-radius: 0.25rem;
  padding: 0.5rem;
  font-size: 0.75rem;
  margin-bottom: 0.35rem;
  line-height: 1.2;
  z-index: 10;
  text-align: center;
`;

QuantityInput.defaultProps = {
  className: "mx-3",
  popoverStyles: null,
};

QuantityInput.propTypes = {
  productId: PropTypes.number.isRequired,
  popoverStyles: PropTypes.shape(),
  packagesPerPallet: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default QuantityInput;
