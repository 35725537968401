import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";

import { canListUsers } from "@ROM/Users/permissions";
import { canUpdateCustomersAllParams } from "@ROM/Customers/permissions";
import { regionsForSelect } from "@ROM/Regions/utils";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";
import AsyncPaginate from "@ROM-components/common/AsyncPaginate";
import Select from "@ROM-components/common/Select";

const FormBody = ({ initialized, customer, handleCustomerChange, setUsers, memberships, fetchUsers, currentUser, regions }) => {
  const selectRegions = regionsForSelect(regions);
  const canUpdateAllParams = canUpdateCustomersAllParams(currentUser);

  return (
    <Form className="mb-3">
      <Row className="mb-3">
        <Col>
          <Form.Label>{initialized ? "Customer Name*" : <Skeleton width={100} />}</Form.Label>
          {initialized ? (
            <Form.Control
              type="text"
              value={customer.name || ""}
              id="customer-form-name"
              placeholder="eg. Acme Farms LLC"
              onChange={(e) => {
                handleCustomerChange({ name: e.target.value });
              }}
            />
          ) : (
            <Skeleton />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>{initialized ? "Primary Contact Name" : <Skeleton width={100} />}</Form.Label>
          {initialized ? (
            <Form.Control
              type="text"
              value={customer.primaryContact || ""}
              id="customer-form-primary-contact"
              onChange={(e) => {
                handleCustomerChange({ primaryContact: e.target.value });
              }}
              placeholder="eg. John Smith"
            />
          ) : (
            <Skeleton />
          )}
        </Col>
      </Row>

      {canUpdateAllParams && canListUsers(currentUser) && (
        <Row className="mb-3">
          <Col>
            <Form.Label>{initialized ? "Other users with access to this customer?" : <Skeleton width={100} />}</Form.Label>
            {initialized ? (
              <AsyncPaginate
                value={memberships}
                isMulti
                closeMenuOnSelect={false}
                loadOptions={fetchUsers}
                onChange={setUsers}
                placeholder="Users"
                additional={{
                  page: 1,
                  onlyUsersWithNoRole: true,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              />
            ) : (
              <Skeleton />
            )}
          </Col>
        </Row>
      )}

      {canUpdateAllParams && selectRegions.length > 0 && (
        <Row className="mb-3">
          <Col>
            {initialized ? (
              <>
                <Form.Label>{selectRegions.length > 1 ? "Region" : `Region: ${selectRegions[0].label}`}</Form.Label>
                {selectRegions.length > 1 && (
                  <Select
                    placeholder="Region"
                    options={selectRegions}
                    onChange={(e) => handleCustomerChange({ regionId: e.value })}
                    value={selectRegions.find((option) => option.value === customer?.regionId?.toString())}
                  />
                )}
              </>
            ) : (
              <Skeleton width={100} height={20} />
            )}
          </Col>
        </Row>
      )}

      {canUpdateAllParams && (
        <>
          <Row className="mb-1">
            <Col>
              {initialized ? (
                <Form.Check
                  checked={customer.isActive}
                  onChange={() => {
                    handleCustomerChange({ isActive: !customer.isActive });
                  }}
                  type="checkbox"
                  label="Is Active"
                />
              ) : (
                <Skeleton />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {initialized ? (
                <Form.Check
                  checked={customer.taxExempt}
                  onChange={() => {
                    handleCustomerChange({ taxExempt: !customer.taxExempt });
                  }}
                  type="checkbox"
                  label="Tax Exempt?"
                  id="tax-exempt"
                />
              ) : (
                <Skeleton />
              )}
            </Col>
          </Row>
          <Row className="mb-1">
            <Col>
              {initialized ? (
                <Form.Check
                  checked={customer.needsApproval}
                  onChange={() => {
                    handleCustomerChange({ needsApproval: !customer.needsApproval });
                  }}
                  type="checkbox"
                  label="Needs Approval"
                />
              ) : (
                <Skeleton />
              )}
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

FormBody.propTypes = {
  initialized: PropTypes.bool.isRequired,
  customer: PropTypes.shape.isRequired,
  handleCustomerChange: PropTypes.func.isRequired,
  setUsers: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  memberships: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }).isRequired,
  currentUser: PropTypes.shape.isRequired,
  regions: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default FormBody;
