import React from "react";
import PropTypes from "prop-types";

const TrackingDetail = ({ deliveryShipping, onEdit }) => {
  return deliveryShipping ? (
    <>
      <strong className="me-1">Tracking: </strong>
      {deliveryShipping.attributes.trackingUrl ? (
        <a target="_blank" rel="noreferrer" href={deliveryShipping.attributes.trackingUrl}>
          {deliveryShipping.attributes.trackingNumber}
        </a>
      ) : (
        <span>{deliveryShipping.attributes.trackingNumber}</span>
      )}
      <button type="button" className="p-0 btn btn-link ms-1" onClick={onEdit}>
        <i className="fa fa-edit text-warning" />
      </button>
    </>
  ) : (
    <button type="button" onClick={onEdit} className="p-0 btn btn-link">
      Add Tracking Details
    </button>
  );
};

TrackingDetail.defaultProps = {
  deliveryShipping: null,
};

TrackingDetail.propTypes = {
  onEdit: PropTypes.func.isRequired,
  deliveryShipping: PropTypes.shape({
    attributes: PropTypes.shape({
      trackingNumber: PropTypes.string.isRequired,
      trackingUrl: PropTypes.string,
    }),
  }),
};

export default TrackingDetail;
