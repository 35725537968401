import React, { useState } from "react";
import PropTypes from "prop-types";

import Badge from "@ROM-ui/Badge";
import IconPointer from "@ROM-ui/IconPointer";
import Select from "@ROM-components/common/Select";
import StyledRow from "@ROM/CustomerAdmin/components/common/StyledRow";

const UserRow = ({ user, updateCustomerAdminValue, handleRemove }) => {
  const [role, setRole] = useState(user.attributes.customerRole || "User");
  const options = [
    { value: "User", label: "User" },
    { value: "Customer Admin", label: "Admin" },
  ];

  return (
    <StyledRow>
      {updateCustomerAdminValue ? (
        <Select
          className="w-100"
          placeholder="Role"
          options={options}
          onChange={(event) => {
            setRole(event.value);
            updateCustomerAdminValue(user.id, event.value === "Customer Admin");
          }}
          value={options.find((option) => option.value === role)}
        />
      ) : (
        <div className="w-50">
          <Badge bg="secondary">{role}</Badge>
        </div>
      )}
      <p className="m-0 w-100 text-center">{user.attributes.fullName}</p>
      <p className={`m-0 w-100 ${handleRemove ? "text-center" : "text-end"}`}>{user.attributes.email}</p>
      {handleRemove && <IconPointer className="fas fa-trash text-danger" onClick={() => handleRemove(user.id)} />}
    </StyledRow>
  );
};

UserRow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    attributes: {
      email: PropTypes.string,
      fullName: PropTypes.string,
      customerAdmin: PropTypes.bool,
    },
  }).isRequired,
  updateCustomerAdminValue: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default UserRow;
