import React from "react";
import PropTypes from "prop-types";

import { isBeingSort } from "@ROM-utils/filters";

const PriceListTableHeader = ({ handleFilterChange, filters }) => {
  return (
    <tr>
      <th
        className="cursor-pointer text-nowrap"
        onClick={() => handleFilterChange("sort", `${filters.sort === "name" ? "-name" : "name"}`)}
      >
        Name
        <i className={isBeingSort(filters, "name")} />
      </th>
      <th>&nbsp;</th>
      <th
        className="cursor-pointer text-nowrap"
        onClick={() => handleFilterChange("sort", `${filters.sort === "dateFrom" ? "-dateFrom" : "dateFrom"}`)}
      >
        From date
        <i className={isBeingSort(filters, "dateFrom")} />
      </th>
      <th
        className="cursor-pointer text-nowrap"
        onClick={() => handleFilterChange("sort", `${filters.sort === "dateTo" ? "-dateTo" : "dateTo"}`)}
      >
        To date
        <i className={isBeingSort(filters, "dateTo")} />
      </th>
    </tr>
  );
};

PriceListTableHeader.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};

export default PriceListTableHeader;
