import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/customers";
  },

  resource(id) {
    return `/customers/${id}`;
  },

  cropAdvisors(id) {
    return `/customers/${id}/crop_advisors`;
  },

  paymentMethods(id) {
    return `/customers/${id}/payment_methods`;
  },

  deletePaymentMethod(id) {
    return `/customers/${id}/delete_payment_method`;
  },

  createAssets(id) {
    return `/customers/${id}/assets`;
  },

  deleteAsset(id, assetId) {
    return `/customers/${id}/assets/${assetId}`;
  },

  editAsset(id, assetId) {
    return `/customers/${id}/assets/${assetId}`;
  },
};

export default {
  list(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(), {
      params: {
        ...options,
      },
    });
  },

  fetchCustomer() {
    const client = getApiClient(API_URL);
    return client.get(`${urls.collection()}/cart_order_customer`);
  },

  find(id) {
    const client = getApiClient(API_URL);
    return client.get(urls.resource(id));
  },

  create(customer) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(), customer);
  },

  update(id, customer) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(id), customer);
  },

  destroy(id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(id));
  },

  fetchCropAdvisors(id) {
    const client = getApiClient(API_URL);
    return client.get(urls.cropAdvisors(id));
  },

  listPaymentMethods(id) {
    const client = getApiClient(API_URL);
    return client.get(urls.paymentMethods(id));
  },

  deletePaymentMethod(id, params) {
    const client = getApiClient(API_URL);
    return client.delete(urls.deletePaymentMethod(id), params);
  },

  createAssets(id, assets) {
    const client = getApiClient(API_URL);
    return client.post(urls.createAssets(id), { assets });
  },

  deleteAsset(id, assetId) {
    const client = getApiClient(API_URL);
    return client.delete(urls.deleteAsset(id, assetId));
  },

  editAsset(id, assetId, attributes) {
    const client = getApiClient(API_URL);
    return client.put(urls.editAsset(id, assetId), attributes);
  },
};
