import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import PriceListItemsTable from "@ROM/CompanyAdmin/PriceListItems/Index";
import { selectCurrentPriceList } from "@ROM/PriceLists/selectors";
import { selectPriceListItems, selectPriceListItemsPagination } from "@ROM/PriceListItems/selectors";
import { useHistory } from "react-router-dom";
import { getFilteredPriceListItems, setPage, updateMany as updatePriceListItems } from "@ROM/PriceListItems/actions";
import { destroy, find, update } from "@ROM/PriceLists/actions";
import Detail from "@ROM/CompanyAdmin/PriceLists/Show/Detail";
import DeleteModal from "@ROM/CompanyAdmin/PriceLists/Delete";
import EditModal from "@ROM/CompanyAdmin/PriceLists/Edit";
import { companyAdminPriceListsUrl } from "@ROM-utils/urlHelpers";

const Show = ({ priceListId }) => {
  const priceList = useSelector(selectCurrentPriceList);
  const priceListItems = useSelector(selectPriceListItems);
  const pagination = useSelector(selectPriceListItemsPagination);

  const dispatch = useDispatch();
  const dispatchSetPage = (page) => dispatch(setPage(page));
  const dispatchGetFilteredPriceListItems = (...props) => dispatch(getFilteredPriceListItems(...props));

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPricesEdits, setShowPricesEdits] = useState(false);
  const history = useHistory();

  useEffect(() => {
    dispatch(find(priceListId));
    dispatchGetFilteredPriceListItems(priceListId);
  }, []);

  const onEditClick = async () => {
    await update(priceListId);
    setShowDeleteModal(false);
    history.push(companyAdminPriceListsUrl());
  };

  const onDeleteClick = async () => {
    await destroy(priceListId);
    setShowDeleteModal(false);
    history.push(companyAdminPriceListsUrl());
  };

  const [prices, setPrices] = useState({});
  const [canSave, setCanSave] = useState(true);
  useEffect(() => {
    const calcInitialPrices = (items) =>
      items.reduce(
        (prev, item) => ({
          ...prev,
          [item.id]: item.attributes.unitPriceCents,
        }),
        {}
      );
    setPrices(priceListItems ? calcInitialPrices(priceListItems) : {});
  }, [priceListItems]);

  const onPriceChanged = (itemId, price) => {
    const newPrices = { ...prices, [itemId]: price * 100.0 };
    setCanSave(Object.values(newPrices).every((x) => x !== undefined && x > 0));
    setPrices(newPrices);
  };

  const onPricesSaveClick = async () => {
    const items = Object.entries(prices).map(([itemId, price]) => [itemId, { unitPriceCents: price }]);
    const originals = priceListItems.map((item) => [item.id, { unitPriceCents: item.attributes.unitPriceCents }]);
    await dispatch(updatePriceListItems(priceListId, items, originals));
    setShowPricesEdits(false);
  };

  const onPricesCancelClick = () => {
    setPrices(
      priceListItems.reduce(
        (prev, item) => ({
          ...prev,
          [item.id]: item.attributes.unitPriceCents,
        }),
        {}
      )
    );
    setShowPricesEdits(false);
  };

  return (
    <>
      <Detail
        priceList={priceList}
        itemsLength={pagination.totalRecords}
        handleEdit={() => setShowEditModal(true)}
        handleDelete={() => setShowDeleteModal(true)}
        showPriceEdits={showPricesEdits}
        canSave={canSave}
        onEditPricesClick={() => setShowPricesEdits(true)}
        onSavePricesClick={onPricesSaveClick}
        onCancelPricesClick={onPricesCancelClick}
      />
      {priceList && (
        <PriceListItemsTable
          priceListId={priceListId}
          priceListItems={priceListItems}
          pagination={pagination}
          setPage={dispatchSetPage}
          getFilteredPriceListItems={dispatchGetFilteredPriceListItems}
          prices={prices}
          showPricesEdits={showPricesEdits}
          onPriceChanged={onPriceChanged}
        />
      )}
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={onDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
        currentPriceList={priceList}
      />
      <EditModal
        show={showEditModal}
        onEditClick={onEditClick}
        onCancel={() => setShowEditModal(false)}
        currentPriceList={priceList}
      />
    </>
  );
};

Show.propTypes = {
  priceListId: PropTypes.string.isRequired,
};

export default Show;
