import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Message from "@ROM-common/Message/index";
import AsyncPaginate from "@ROM-components/common/AsyncPaginate";
import Modal from "@ROM-components/common/Modal";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";

import { list as fetchUsers } from "@ROM/Users/actions";
import { create, update } from "@ROM/EmailGroups/actions";
import { fetchUsers as loadUsers } from "@ROM/Customers/utils";

const EmailGroupCreateModal = ({ initialData, initialUsers, onCancel }) => {
  const edit = !!initialData.id;
  const dispatch = useDispatch();

  const [name, setName] = useState(initialData?.attributes?.name || "");
  const [groupUsers, setGroupUsers] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const errorFields = errorMessages.map((error) => error.source?.pointer);

  const currentIds = initialData.relationships?.users.data.map((user) => user.id) || [];
  const options = initialUsers
    .filter((user) => currentIds.includes(user.id))
    .map((user) => ({ value: user.id, label: user.attributes.fullName }));

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    if (currentIds) {
      const selectedUsers = options.filter((option) => currentIds.includes(option.value));
      setGroupUsers(selectedUsers);
    }
  }, [initialUsers]);

  const handleCreate = async () => {
    const userIds = groupUsers.map((user) => user.value);
    const attributes = { name, userIds };
    const action = edit ? update(initialData.id, attributes) : create(attributes);
    try {
      const response = await dispatch(action);
      if (response.payload?.status === 200 || response.payload?.status === 201) {
        onCancel();
      } else if (Array.isArray(response.payload?.data)) {
        setErrorMessages(response.payload.data);
      } else {
        setErrorMessages([{ detail: "Unknown error, please try again later" }]);
      }
    } catch (error) {
      setErrorMessages([{ detail: "Unknown error, please try again later" }]);
    }
  };

  return (
    <Modal
      isShowing
      confirmText={edit ? "Update" : "Create"}
      onConfirm={handleCreate}
      onCancel={onCancel}
      header={edit ? "Update Email Group" : "Create Email Group"}
    >
      <Form className="mb-3">
        <Row>
          <Col>
            <Message message={errorMessages.map((error) => error.detail)} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={errorFields.includes("name") ? "border-danger not-outline" : ""}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Users</Form.Label>
            <AsyncPaginate
              placeholder="Select users"
              id="email-group-users-selector"
              isMulti
              loadOptions={loadUsers}
              options={options}
              onChange={(values) => setGroupUsers(values || [])}
              value={groupUsers}
              additional={{ page: 1 }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

EmailGroupCreateModal.propTypes = {
  initialData: PropTypes.shape().isRequired,
  initialUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default EmailGroupCreateModal;
