import { createSelector } from "reselect";
import { CUSTOMER_CROP_ADVISOR_ROLE } from "@ROM/Customers/constants";

const usersState = (state) => state.users;

export const selectCurrentUser = createSelector(usersState, (users) => users.current.user);

export const selectCurrentUserLoading = createSelector(usersState, (users) => users.current.loading);

export const selectCurrentUserIncluded = createSelector(usersState, (users) => users.current.included);

export const selectAllUsers = createSelector(usersState, (users) => users.all.items);

export const selectAllUsersNotCustomerCropAdvisor = createSelector(selectAllUsers, (users) =>
  users.filter((user) => user.attributes.customerRole !== CUSTOMER_CROP_ADVISOR_ROLE)
);

export const selectAllCustomerCropAdvisorUsers = createSelector(selectAllUsers, (users) =>
  users.filter((user) => user.attributes.customerRole === CUSTOMER_CROP_ADVISOR_ROLE)
);

export const selectUsersIncluded = createSelector(usersState, (users) => users.all.included);

export const selectUsersLoading = createSelector(usersState, (users) => users.all.loading);

export const selectUsersPagination = createSelector(usersState, (users) => users.pagination);

export const selectUsersUpdateErrors = createSelector(usersState, (users) => users.updateError);
