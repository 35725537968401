import React from "react";
import { Route } from "react-router-dom";

import Page from "@ROM-pages/Auth/Account/Index";
import Layout from "@ROM-pages/Layouts/Authenticated";

class RouteHandler extends Route {
  render() {
    return (
      <Layout dontPadMobile>
        <Page />
      </Layout>
    );
  }
}

export default RouteHandler;
