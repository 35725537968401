import React from "react";
import PropTypes from "prop-types";

import { canEditOrderCropAdvisor } from "@ROM/Orders/permissions";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";

const ExtraInformation = ({ order, associatedCropAdvisor, currentUser, showDate }) => {
  const shipDate = new Date(order.attributes.shipDate);

  return (
    <>
      <Row className="ps-md-3">
        {order.attributes.poNumber && (
          <Col xs={12} md={4}>
            <strong>Purchase Order Number</strong>
            <p>{order.attributes.poNumber}</p>
          </Col>
        )}
        {showDate && (
          <Col xs={12} md={4}>
            <strong>Ship Date</strong>
            <p>{shipDate.toLocaleDateString()}</p>
          </Col>
        )}
        <Col />
      </Row>
      {(associatedCropAdvisor || canEditOrderCropAdvisor(currentUser, order.attributes.customerId)) && (
        <div className="ps-md-3">
          <strong>Associated Crop Advisor</strong>
          {associatedCropAdvisor ? (
            <div>
              <p className="m-0">{associatedCropAdvisor.attributes.fullName}</p>
              <p>{associatedCropAdvisor.attributes.email}</p>
            </div>
          ) : (
            <p>Not assigned</p>
          )}
        </div>
      )}
      {order.attributes && order.attributes.notes && (
        <Row className="ps-md-3">
          <Col>
            <strong>Notes</strong>
            <p className="text-break">{order.attributes.notes}</p>
          </Col>
        </Row>
      )}
    </>
  );
};

ExtraInformation.propTypes = {
  order: PropTypes.shape().isRequired,
  associatedCropAdvisor: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  showDate: PropTypes.bool.isRequired,
};

export default ExtraInformation;
