import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/custom_tables";
  },
  resource(id) {
    return `/custom_tables/${id}`;
  },
};

export default {
  list(options = {}) {
    const client = getApiClient(API_URL);
    return client.get(urls.collection(), { params: options });
  },

  create(customTable) {
    const client = getApiClient(API_URL);
    return client.post(urls.collection(), customTable);
  },

  update(id, customTable) {
    const client = getApiClient(API_URL);
    return client.put(urls.resource(id), customTable);
  },

  destroy(id) {
    const client = getApiClient(API_URL);
    return client.delete(urls.resource(id));
  },
};
