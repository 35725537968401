import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import useIsMobile from "@ROM-components/utils/Responsive";

import { OrderItemRowHeaderDesktop, OrderItemRowDesktop } from "./OrderItemRowDesktop";
import { OrderItemRowMobile } from "./OrderItemRowMobile";

export const OrderItemRowHeader = ({ isShow, showDiscountColumn, showTrial }) => {
  const isMobile = useIsMobile();
  // Mobile shows no table header.
  return isMobile ? null : (
    <OrderItemRowHeaderDesktop showDiscountColumn={showDiscountColumn} isShow={isShow} showTrial={showTrial} />
  );
};

OrderItemRowHeader.propTypes = {
  isShow: PropTypes.bool.isRequired,
  showDiscountColumn: PropTypes.bool,
  showTrial: PropTypes.bool.isRequired,
};

OrderItemRowHeader.defaultProps = {
  showDiscountColumn: true,
};

export const OrderItemRow = ({
  item,
  handleRemoveItem,
  handleNotesChange,
  dragHandleProps,
  isShow,
  handleAddDiscount,
  loadingPrice,
  showDiscountColumn,
  showTrial,
  handleTrialProduct,
  canSetDiscounts,
  canSetTrial,
}) => {
  const [note, setNote] = useState(item?.attributes?.notes ?? "");
  const isInitialMountNotes = useRef(true);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (isInitialMountNotes.current) {
      isInitialMountNotes.current = false;
    } else if (handleNotesChange) {
      const timeOutId = setTimeout(() => handleNotesChange(item, note), 500);
      return () => clearTimeout(timeOutId);
    }
    return undefined;
  }, [note]);

  return isMobile ? (
    <OrderItemRowMobile
      item={item}
      handleRemoveItem={handleRemoveItem}
      dragHangleProps={dragHandleProps}
      isShow={isShow}
      note={note}
      setNote={setNote}
      handleAddDiscount={handleAddDiscount}
      loadingPrice={loadingPrice}
      showTrial={showTrial}
      handleTrialProduct={handleTrialProduct}
      canSetDiscounts={canSetDiscounts}
      canSetTrial={canSetTrial}
    />
  ) : (
    <OrderItemRowDesktop
      item={item}
      handleRemoveItem={handleRemoveItem}
      dragHangleProps={dragHandleProps}
      isShow={isShow}
      note={note}
      setNote={setNote}
      handleAddDiscount={handleAddDiscount}
      loadingPrice={loadingPrice}
      showDiscountColumn={showDiscountColumn}
      showTrial={showTrial}
      handleTrialProduct={handleTrialProduct}
      canSetDiscounts={canSetDiscounts}
      canSetTrial={canSetTrial}
    />
  );
};

OrderItemRow.propTypes = {
  item: PropTypes.shape().isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  handleNotesChange: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.shape().isRequired,
  isShow: PropTypes.bool.isRequired,
  handleAddDiscount: PropTypes.func.isRequired,
  loadingPrice: PropTypes.bool.isRequired,
  showDiscountColumn: PropTypes.bool,
  showTrial: PropTypes.bool.isRequired,
  handleTrialProduct: PropTypes.func.isRequired,
  canSetDiscounts: PropTypes.bool.isRequired,
  canSetTrial: PropTypes.bool.isRequired,
};

OrderItemRow.defaultProps = {
  showDiscountColumn: true,
};
