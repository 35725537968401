import { createSelector } from "reselect";
import { isPdf } from "@ROM-utils/assets";

const LINK = "Link";
const productsState = (state) => state.products;

export const selectProducts = createSelector(productsState, (products) => {
  return products.all.items;
});

export const selectRelatedProductsOfCurrentProduct = createSelector(productsState, (products) => {
  const relatedProducts = products?.current?.product?.relationships?.relatedProducts?.data;
  return products?.all?.items?.filter((prod) => relatedProducts?.some((relProd) => prod.id === relProd.id)) ?? [];
});

export const selectTopProducts = createSelector(productsState, (products) => products.top.items);

export const selectFeaturedProducts = createSelector(productsState, (products) => products.featured.items);

export const selectCurrentProduct = createSelector(productsState, (products) => products.current.product);

export const selectCurrentProductAssets = createSelector(productsState, (products) =>
  products.current.included.filter((rel) => rel.type === "asset")
);

export const selectCurrentProductLinks = createSelector(selectCurrentProductAssets, (assets) =>
  assets.filter((asset) => asset.attributes.type === LINK)
);

export const selectCurrentProductPdfs = createSelector(selectCurrentProductAssets, (assets) =>
  assets.filter((asset) => isPdf(asset.attributes.file.url))
);

export const selectCurrentProductImages = createSelector(selectCurrentProductAssets, (assets) =>
  assets.filter((asset) => !isPdf(asset.attributes.file.url) && asset.attributes.type !== LINK)
);

export const selectCurrentProductPriceLists = createSelector(
  productsState,
  (products) => products.current.included?.filter((rel) => rel.type === "priceList") || []
);

export const selectCurrentProductPriceListItems = createSelector(
  productsState,
  (products) => products.current.included?.filter((rel) => rel.type === "priceListItem") || []
);

export const selectCurrentProductInventories = createSelector(
  productsState,
  (products) => products.current.included?.filter((rel) => rel.type === "productInventory") || []
);

export const selectProductsPagination = createSelector(productsState, (products) => products.pagination);

export const selectProductInventories = (productId) =>
  createSelector(productsState, (products) => {
    const product = products.all.items.find((currentProduct) => currentProduct.id === productId);

    const productInventoriesIds = product.relationships.productInventories.data.map((e) => e.id);

    return products.all.included.filter((e) => e.type === "productInventory" && productInventoriesIds.includes(e.id));
  });
