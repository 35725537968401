import { checkPermission } from "@ROM/Permissions/utils";

export const canSeeBillOfLadings = (user) => checkPermission(user, "bill-of-ladings:show");

export const canCreateBillOfLadings = (user) => checkPermission(user, "bill-of-ladings:create");

export const canEditBillOfLadings = (user) => checkPermission(user, "bill-of-ladings:update");

export const canEditBillOfLadingsAllParams = (user) => checkPermission(user, "bill-of-ladings:update:all-params");

export const canDeleteBillOfLadings = (user) => checkPermission(user, "bill-of-ladings:delete");

export const canReorderBillOfLadingsPallets = (user) => checkPermission(user, "bill-of-ladings:re-sort");
