import React from "react";
import PropTypes from "prop-types";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";
import Select from "@ROM-components/common/Select";
import IconPointer from "@ROM-ui/IconPointer";
import InputGroup from "@ROM-ui/InputGroup";
import styled from "styled-components";
import Message from "@ROM-common/Message";

const InvitationsRow = ({ user, updateElement, lastElement, onIconClick, error, haveInvalidEmail, isIncomplete }) => {
  const options = [
    { value: "User", label: "User" },
    { value: "Customer Admin", label: "Admin" },
  ];

  const emailError = haveInvalidEmail(user)
    ? "Invalid Email"
    : isIncomplete(user) && user.email === ""
    ? "Email is Required"
    : "";

  const nameError = isIncomplete(user) && user.fullName === "" ? "Full Name is Required" : "";

  return (
    <>
      <Row className="p-2 align-items-center">
        <Col md={4}>
          <StyledInputGroup>
            <StyledIcon className="fas fa-user text-secondary" />
            <StyledInput
              type="text"
              data-field="fullName"
              value={user.fullName}
              onChange={(event) => updateElement({ ...user, fullName: event.target.value })}
              placeholder="Full Name"
            />
          </StyledInputGroup>
        </Col>
        <Col md={4}>
          <StyledInputGroup>
            <StyledIcon className="fas fa-envelope text-secondary" />
            <StyledInput
              type="email"
              data-field="email"
              value={user.email}
              placeholder="Email"
              onChange={(event) => updateElement({ ...user, email: event.target.value })}
            />
          </StyledInputGroup>
        </Col>
        <Col md={3}>
          <StyledInputGroup>
            <StyledIcon className="fas fa-lock text-secondary" />
            <StyledSelect
              placeholder="Role"
              options={options}
              onChange={(event) => updateElement({ ...user, role: event.value })}
              value={options.find((option) => option.value === user.role)}
            />
          </StyledInputGroup>
        </Col>
        <Col md={1}>
          <StyledTrash
            lastElement={lastElement}
            className={lastElement ? "fas fa-plus-square " : "fas fa-trash"}
            onClick={onIconClick}
          />
        </Col>
      </Row>
      {error && (emailError || nameError) && (
        <Row>
          <Col md={4}>{nameError && <Message message={nameError} type="danger" />}</Col>
          <Col md={4}>{emailError && <Message message={emailError} type="danger" />}</Col>
          <Col md={3} />
          <Col md={1} />
        </Row>
      )}
    </>
  );
};

InvitationsRow.propTypes = {
  user: PropTypes.object,
  updateElement: PropTypes.func,
  lastElement: PropTypes.bool,
  onIconClick: PropTypes.func,
  index: PropTypes.number,
  error: PropTypes.string,
  haveInvalidEmail: PropTypes.func,
  isIncomplete: PropTypes.func,
};

const StyledTrash = styled(IconPointer)`
  color: ${(props) => (props.lastElement ? "#94D60A" : "red")};
`;

const StyledInput = styled(Form.Control)`
  border: none;
`;

const StyledSelect = styled(Select)`
  min-width: 125px;

  & > div {
    border: none;
  }
  width: 80%;
`;

const StyledInputGroup = styled(InputGroup)`
  border: 1px solid #707070;
  padding: 4px;
  border-radius: 15px;
  background-color: white;
  @media (max-width: 812px) {
    margin-bottom: 10px;
  }
`;

const StyledIcon = styled.i`
  color: #94d60a;
  margin: 10px;
`;

export default InvitationsRow;
