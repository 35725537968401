import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Show from "@ROM/CompanyAdmin/PriceLists/Show";
import Section from "@ROM-components/common/Section";
import { priceListsBreadcrumbs } from "@ROM/CompanyAdmin/utils";
import { selectCurrentPriceList } from "@ROM/PriceLists/selectors";

const CompanyAdminPriceListShow = ({ priceListId }) => {
  const priceList = useSelector(selectCurrentPriceList);
  return (
    <Section breadcrumbs={priceListsBreadcrumbs([{ label: priceList?.attributes.name || "Price List", active: true }])}>
      <Show priceListId={priceListId} />
    </Section>
  );
};

CompanyAdminPriceListShow.propTypes = {
  priceListId: PropTypes.number.isRequired,
};

export default CompanyAdminPriceListShow;
