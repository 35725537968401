import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const NumberFormater = ({
  id,
  value,
  className,
  displayType,
  thousandSeparator,
  prefix,
  suffix,
  onValueChange,
  unit,
  renderText,
  disabled,
  placeholder,
}) => {
  const actualSuffix = suffix || (unit ? `/${unit}` : "");

  return (
    <NumberFormat
      id={id}
      value={value / 100}
      className={className}
      displayType={displayType}
      thousandSeparator={thousandSeparator}
      prefix={prefix}
      decimalScale={2}
      fixedDecimalScale
      renderText={renderText}
      suffix={actualSuffix}
      onValueChange={onValueChange}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

NumberFormater.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
  displayType: PropTypes.string,
  thousandSeparator: PropTypes.bool,
  onValueChange: PropTypes.func,
  unit: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  renderText: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
};

NumberFormater.defaultProps = {
  id: null,
  className: "text-nowrap",
  displayType: "text",
  thousandSeparator: true,
  prefix: "$ ",
  suffix: null,
  onValueChange: undefined,
  unit: null,
  renderText: null,
  placeholder: null,
};

export default NumberFormater;
