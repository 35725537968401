import React from "react";
import PropTypes from "prop-types";
import Button from "@ROM-ui/Button";
import NumberFormater from "@ROM-ui/NumberFormater";

import { prefix, suffix } from "../utils";

const DiscountAmount = ({ isShow, item, handleAddDiscount, buttonVariant, buttonClassName, canSetDiscounts }) => {
  const divWrap = (value) => (
    <div id={`order-item-discount-${item.id}`} className="text-nowrap">
      {value}
    </div>
  );
  const ceroDiscount = (item.attributes.discountAmount || 0) === 0;

  const number = (
    <NumberFormater
      value={item.attributes.discountAmount || 0}
      prefix={prefix(item.attributes.discountType)}
      suffix={suffix(item.attributes.discountType)}
      renderText={divWrap}
    />
  );

  if (isShow || !canSetDiscounts) {
    return number;
  }

  return (
    <Button
      className={buttonClassName}
      id={`order-item-edit-discount-${item.id}`}
      size="sm"
      variant={buttonVariant}
      onClick={handleAddDiscount}
    >
      {ceroDiscount ? "Add Discount" : number}
    </Button>
  );
};

DiscountAmount.propTypes = {
  isShow: PropTypes.bool.isRequired,
  item: PropTypes.shape().isRequired,
  handleAddDiscount: PropTypes.func.isRequired,
  buttonVariant: PropTypes.string,
  buttonClassName: PropTypes.string,
  canSetDiscounts: PropTypes.bool.isRequired,
};

DiscountAmount.defaultProps = {
  buttonVariant: "link",
  buttonClassName: "p-0",
};

export default DiscountAmount;
