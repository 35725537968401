import update from "immutability-helper";
import humps from "humps";
import { handle } from "redux-pack";

import { LIST, DESTROY, FIND, UPDATE, CREATE_ASSETS, DELETE_ASSET, EDIT_ASSET } from "./actions";
import { FETCH_USER, LOGOUT } from "../Auth/actions";

const initialState = {
  all: {
    loading: false,
    items: [],
    included: [],
  },
  current: {
    customer: null,
    included: [],
  },
  pagination: {
    currentPage: 1,
    perPage: null,
    totalPages: null,
    totalRecords: null,
  },
  errors: {
    assets: null,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};
  switch (type) {
    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: true },
            },
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            all: {
              loading: { $set: false },
            },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              included: { $set: json.included },
              loading: { $set: false },
            },
            pagination: { $set: json.meta.pagination },
          });
        },
      });

    case FIND:
    case UPDATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            current: {
              customer: { $set: humps.camelizeKeys(json.data) },
              included: { $set: json.included },
            },
          });
        },
      });

    case FETCH_USER:
      return handle(state, action, {
        success: (prevState) => {
          return update(prevState, {
            $set: initialState,
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            $set: initialState,
          });
        },
      });

    case DESTROY:
      return handle(state, action, {
        success: (prevState) => {
          const { customerId } = action.meta;
          const customers = { ...prevState.all };
          customers.items = customers.items.filter((customer) => customer.id !== customerId);
          return update(prevState, {
            all: { $set: customers },
          });
        },
      });

    case LOGOUT:
      return handle(state, action, {
        success: (prevState) => {
          return update(prevState, {
            $set: initialState,
          });
        },

        failure: (prevState) => {
          return update(prevState, {
            $set: initialState,
          });
        },
      });

    case CREATE_ASSETS:
    case DELETE_ASSET:
    case EDIT_ASSET:
      return handle(state, action, {
        success: (prevState) => {
          return update(prevState, {
            current: {
              customer: { $set: humps.camelizeKeys(json.data) },
              included: { $set: json.included },
            },
            errors: {
              $merge: { asset: null },
            },
          });
        },

        failure: (prevState) => {
          const errorMsg = json[0]?.detail || "Unexpected error. Try again later";
          return update(prevState, {
            errors: {
              $merge: { assets: errorMsg },
            },
          });
        },
      });

    default:
      return state;
  }
}

export default reducer;
