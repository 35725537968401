import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import DeliveryStatusBadge from "@ROM/Deliveries/components/common/DeliveryStatusBadge";
import PdfActions from "@ROM/Deliveries/components/PdfActions";
import { getBackgroundColorByStatus } from "@ROM/Deliveries/utils";

const OrderDeliveryListRow = ({ delivery, orderId, deliveriesItems, deliveriesWarehouses }) => {
  const [error, setError] = useState(false);

  const deliveryItems = deliveriesItems.filter((item) => item.relationships.delivery.data.id === delivery.id);
  const deliveryWarehouse = deliveriesWarehouses.find((warehouse) => delivery.relationships.warehouse.data?.id === warehouse.id);
  const quantity = deliveryItems.reduce((acc, item) => acc + item.attributes.quantity, 0);

  return (
    <>
      <StyledRow className="py-2 rounded ms-2" status={delivery.attributes.status}>
        <Col xs={2} className="text-end">
          <DeliveryStatusBadge status={delivery.attributes.status} />
        </Col>
        <Col xs={2} className="text-nowrap ">
          {delivery.attributes.deliveryNumber}
        </Col>
        <Col xs={3} className="text-nowrap ">{`Deliver by: ${delivery.attributes.deliverDate}`}</Col>
        <Col xs={1} className="text-nowrap ">{`Qty: ${quantity}`}</Col>
        <Col xs={3} className="text-nowrap ">{`Warehouse: ${deliveryWarehouse?.attributes?.name || "Unset"}`}</Col>
        <Col xs={1} className="text-end">
          <PdfActions
            deliveryNumber={delivery.attributes.deliveryNumber}
            deliveryItems={deliveryItems}
            orderId={orderId}
            setError={setError}
          />
        </Col>
      </StyledRow>
      {error && <p className="text-danger mt-2 text-end">Something went wrong, please try again later.</p>}
    </>
  );
};

const StyledRow = styled(Row)`
  background-color: ${({ status }) => getBackgroundColorByStatus(status)};
`;

OrderDeliveryListRow.propTypes = {
  delivery: PropTypes.shape({
    id: PropTypes.number,
    attributes: {
      status: PropTypes.string,
      deliverDate: PropTypes.string,
      position: PropTypes.string,
      deliveryNumber: PropTypes.string,
    },
    relationships: {
      warehouse: {
        data: { id: PropTypes.string },
      },
    },
  }).isRequired,
  orderId: PropTypes.number.isRequired,
  deliveriesItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  deliveriesWarehouses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OrderDeliveryListRow;
