import { apiAction } from "@ROM-utils/actions";
import StatusesApi from "./api";

export const SHIP = "delivery_statuses/types/SHIP";
export const IN_PROGRESS = "delivery_statuses/types/IN_PROGRESS";
export const DELIVER = "delivery_statuses/types/DELIVER";

export function inProgress(attributes = {}) {
  return apiAction(IN_PROGRESS, StatusesApi.inProgress(attributes));
}

export function ship(attributes = {}) {
  return apiAction(SHIP, StatusesApi.ship(attributes));
}

export function deliver(attributes = {}) {
  return apiAction(DELIVER, StatusesApi.deliver(attributes));
}
