import styled from "styled-components";
import Row from "@ROM-ui/Row";

const ListHeader = styled(Row)`
  border-bottom: 1px solid #cbd5e0;
  padding-bottom: 0.5rem;
  color: #798aa3;

  div[class*="col"] {
    display: flex;
    align-items: center;

    i {
      font-size: 0.875rem;
    }

    @media (max-width: 768px) {
      justify-content: center;
      font-size: 0.875rem;
    }
  }
`;

const ListContainer = styled.div`
  padding-bottom: 1.5rem;

  .row:nth-of-type(even) {
    background-color: #f8f8f9;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const ListRow = styled(Row)`
  padding: 1.25rem 0;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};

  div[class*="col"] {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      justify-content: center;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 768px) {
    background-color: #f8f8f9;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.2rem;
  }
`;

export { ListHeader, ListContainer, ListRow };
