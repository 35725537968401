import { useRef, useEffect } from "react";

const useDebounceEffect = (effect, deps, { delay = 400, runAfterMount = false } = {}) => {
  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) {
      const handler = setTimeout(() => effect(), delay);
      return () => clearTimeout(handler);
    }

    initialized.current = true;
    if (!runAfterMount) {
      effect();
    }

    return undefined;
  }, [...(deps || []), delay]);
};

export default useDebounceEffect;
