import React from "react";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Panel from "@ROM-ui/Panel";
import PropTypes from "prop-types";
import AddressSelector from "@ROM/Addresses/components/AddressSelector";
import Message from "@ROM-common/Message";
import { MISSING_BILLING_ADDRESS, MISSING_SHIPPING_ADDRESS } from "@ROM-utils/errors";
import { BILLING_TYPE, SHIPPING_TYPE } from "@ROM/Addresses/actions";

const AddressList = ({
  handleBillingAddressSelected,
  stateOrder,
  handleShippingAddressSelected,
  handleShipSameAsBilling,
  shipAddressSameAsBilling,
  handleDeleteAddress,
  customer,
  error,
  editing,
  initializedBillingAddress,
  initializedShipingAddress,
  isMobile,
}) => {
  const billingSelector = (
    <AddressSelector
      type={BILLING_TYPE}
      onSelect={handleBillingAddressSelected}
      selectedId={stateOrder.billingAddressId}
      handleDeleteAddress={handleDeleteAddress}
      customer={customer}
      editing={editing}
    />
  );
  const shippingSelector = (
    <AddressSelector
      type={SHIPPING_TYPE}
      onSelect={handleShippingAddressSelected}
      selectedId={stateOrder.shippingAddressId}
      billingAddressId={stateOrder.billingAddressId}
      handleDeleteAddress={handleDeleteAddress}
      customer={customer}
      editing={editing}
      shipAddressSameAsBilling={shipAddressSameAsBilling}
      handleShipSameAsBilling={handleShipSameAsBilling}
    />
  );
  return isMobile ? (
    <Row className="bg-white px-2 small" id="billing-address">
      <Col>
        <Row>
          <Col className="fw-bold ms-4 mt-3">Billing Address*</Col>
        </Row>

        {(error || editing || initializedBillingAddress) && !stateOrder.billingAddressId && (
          <Row>
            <Col>
              <Message message={MISSING_BILLING_ADDRESS} type="danger" />
            </Col>
          </Row>
        )}
        <Row>
          <Col className="rounded shadow m-3 p-2">{billingSelector}</Col>
        </Row>

        <Row>
          <Col className="fw-bold ms-4 mt-3">Shipping Address*</Col>
        </Row>

        {(error || editing || initializedShipingAddress) && !stateOrder.shippingAddressId && (
          <Row>
            <Col>
              <Message message={MISSING_SHIPPING_ADDRESS} type="danger" />
            </Col>
          </Row>
        )}
        <Row>
          <Col className="rounded shadow m-3 p-2">{shippingSelector}</Col>
        </Row>
      </Col>
    </Row>
  ) : (
    <Row className="mt-4" id="billing-address">
      <Col xs={12} md={6} className="my-4">
        <h5 className="text-white mb-3">Billing Address*</h5>
        {(error || editing || initializedBillingAddress) && !stateOrder.billingAddressId && (
          <Message message={MISSING_BILLING_ADDRESS} type="danger" />
        )}
        <Panel>{billingSelector}</Panel>
      </Col>
      <Col xs={12} md={6} className="my-4">
        <h5 className="text-white mb-3">Shipping Address*</h5>
        {(error || editing || initializedShipingAddress) && !stateOrder.shippingAddressId && (
          <Message message={MISSING_SHIPPING_ADDRESS} type="danger" />
        )}
        <Panel>{shippingSelector}</Panel>
      </Col>
    </Row>
  );
};

AddressList.propTypes = {
  billingAddresses: PropTypes.array,
  handleBillingAddressSelected: PropTypes.func,
  stateOrder: PropTypes.object,
  shippingAddresses: PropTypes.array,
  handleShippingAddressSelected: PropTypes.func,
  handleShipSameAsBilling: PropTypes.func,
  shipAddressSameAsBilling: PropTypes.bool,
  handleDeleteAddress: PropTypes.func,
  customer: PropTypes.object,
  error: PropTypes.string,
  editing: PropTypes.bool,
  initializedBillingAddress: PropTypes.bool,
  initializedShipingAddress: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};

export default AddressList;
