import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Modal from "@ROM-components/common/Modal";
import { destroy as destroyAddress } from "@ROM/Addresses/actions";

const DeleteModal = ({ show = false, address = {}, onCancel, callback }) => {
  const dispatch = useDispatch();

  const handleConfirm = () => {
    const promise = dispatch(destroyAddress(address.id, { type: address.attributes.type }));
    onCancel();
    promise.then(() => {
      if (callback) callback(address);
    });
  };

  return (
    <Modal
      isShowing={show}
      confirmText="Yes, Delete"
      onConfirm={handleConfirm}
      onCancel={onCancel}
      header="Do you want to delete this address?"
      showCloseIcon={false}
    />
  );
};

DeleteModal.propTypes = {
  /**
   * Boolean whether to show the modal
   */
  show: PropTypes.bool.isRequired,

  /**
   * The address to delete
   */
  address: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape(),
  }),

  /*
   * Handler provided by parent
   */

  onCancel: PropTypes.func.isRequired,

  callback: PropTypes.func,
};

DeleteModal.defaultProps = {
  address: {},
  callback: null,
};

export default DeleteModal;
