import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import PropTypes from "prop-types";

import { selectIsLoading, selectAllCustomTables } from "@ROM/CustomTables/selectors";
import { companyAdminUrl } from "@ROM-utils/urlHelpers";
import Section from "@ROM-components/common/Section";
import { customTablesShowBreadcrumbs } from "@ROM/CompanyAdmin/utils";
import CustomTableForm from "@ROM/CustomTables/components/Form";

const Page = ({ customTableId }) => {
  const isLoading = useSelector(selectIsLoading);
  const customTables = useSelector(selectAllCustomTables);
  const table = customTables.find((item) => item.id === customTableId);

  const breadcrumbs = customTablesShowBreadcrumbs(table, [{ label: "Edit", active: true }]);

  if (isLoading) {
    return null;
  }

  if (!table) {
    return <Redirect to={companyAdminUrl()} />;
  }

  return (
    <Section breadcrumbs={breadcrumbs}>
      <CustomTableForm key={table.id} table={table} />
    </Section>
  );
};

Page.propTypes = {
  customTableId: PropTypes.number.isRequired,
};

export default Page;
