import React, { useReducer, useEffect } from "react";
import { reducer } from "@ROM/App/utils/forms";
import Form from "@ROM-ui/Form";
import Col from "@ROM-ui/Col";
import Row from "@ROM-ui/Row";
import Button from "@ROM-ui/Button";
import LoadingSplash from "@ROM-components/LoadingSplash/index";
import { currentCompany as fetchCurrentCompany, updateCompany, clearMessages } from "@ROM/Company/actions";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentCompany,
  selectCompanyErrorUpdate,
  selectCompanySuccessfulUpdate,
  selectCompanyLoading,
} from "@ROM/Company/selectors";
import Message from "@ROM-common/Message/index";
import { emailValid } from "@ROM-utils/email";

const initialState = {
  name: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  fax: "",
  email: "",
  shippingWarning: "",
  autoSelectRefrigerationForWeight: "35000",
  freeShippingPallets: "0",
  customMessage: "",
  mailFromAddress: "",
  mailReplyToAddress: "",
  palletSelectorEnabled: false,
  palletInitialWeight: "0",
  forceDeliveryCreation: false,
  showBillsOfLading: false,
  allowCreditCardUsage: false,
  allowOrderDiscounts: false,
  allowAttachmentOrders: false,
  autoApproveOrders: false,
  autoPopulatePoNumber: false,
  showOrderShipDate: false,
  manuallyApproveFirstTimeCustomers: false,
  deliveredStatusForDeliveries: false,
  favicon: "",
  logo: "",
};

const CompanyForm = () => {
  const currentCompany = useSelector(selectCurrentCompany);
  const error = useSelector(selectCompanyErrorUpdate);
  const success = useSelector(selectCompanySuccessfulUpdate);
  const loading = useSelector(selectCompanyLoading);
  const dispatch = useDispatch();

  const [company, setCompany] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!currentCompany.loading && currentCompany.attributes) setCompany(currentCompany.attributes);
  }, [currentCompany]);

  useEffect(() => {
    dispatch(fetchCurrentCompany());
  }, []);

  useEffect(() => {
    if (success) {
      const timeOutId = setTimeout(() => dispatch(clearMessages()), 2000);
      return () => clearTimeout(timeOutId);
    }
    return undefined;
  }, [success]);

  const handleInputChange = (event) => {
    setCompany({ [event.target.name]: event.target.value });
  };

  const handleCheckboxSelector = (event) => {
    setCompany({ [event.target.name]: !company[event.target.name] });
  };

  const handleCompanySave = () => {
    const settings = {
      shippingWarning: company.shippingWarning,
      autoSelectRefrigerationForWeight: company.autoSelectRefrigerationForWeight,
      freeShippingPallets: company.freeShippingPallets,
      palletInitialWeight: company.palletInitialWeight,
      customMessage: company.customMessage,
      palletSelectorEnabled: company.palletSelectorEnabled,
      forceDeliveryCreation: company.forceDeliveryCreation,
      showBillsOfLading: company.showBillsOfLading,
      allowCreditCardUsage: company.allowCreditCardUsage,
      allowOrderDiscounts: company.allowOrderDiscounts,
      allowAttachmentOrders: company.allowAttachmentOrders,
      autoApproveOrders: company.autoApproveOrders,
      autoPopulatePoNumber: company.autoPopulatePoNumber,
      showOrderShipDate: company.showOrderShipDate,
      deliveredStatusForDeliveries: company.deliveredStatusForDeliveries,
      groupApprovedOrders: company.groupApprovedOrders,
      manuallyApproveFirstTimeCustomers: company.manuallyApproveFirstTimeCustomers,
      mailFromAddress: company.mailFromAddress,
      mailReplyToAddress: company.mailReplyToAddress,
    };

    dispatch(updateCompany(currentCompany.id, { ...company, settings }));

    window.scroll(0, 0);
  };

  const hasInvalidEmail = company.email !== "" && !emailValid(company.email);

  return !loading ? (
    <>
      <Row className="mb-3">
        <Col>
          {success && <Message message="Changes saved" type="success" fluid />}
          {error && <Message message={error.detail} type="danger" fluid />}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Name</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="name"
            id="company-form-name"
            value={company.name || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Address 1</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="address1"
            id="company-form-address-1"
            value={company.address1 || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Address 2</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="address2"
            id="company-form-address-2"
            value={company.address2 || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>City</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="city"
            id="company-form-city"
            value={company.city || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>State</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="state"
            id="company-form-state"
            value={company.state || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Zip</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="zip"
            value={company.zip || ""}
            onChange={handleInputChange}
            id="company-form-zip"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Phone</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="phone"
            id="company-form-phone"
            value={company.phone || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Fax</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="fax"
            id="company-form-fax"
            value={company.fax || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Email</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="email"
            id="company-form-email"
            value={company.email || ""}
            onChange={handleInputChange}
          />
          {hasInvalidEmail && <Message message="Invalid Email" type="danger" className="mt-2" />}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Shipping Warning</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="shippingWarning"
            id="company-form-shipping-warning"
            value={company.shippingWarning || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Auto Select Refrigeration Required on BOL for total weight higher than (lbs)</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="autoSelectRefrigerationForWeight"
            id="company-form-auto-select-refrigeration-for-weight"
            value={company.autoSelectRefrigerationForWeight || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Order Free Shipping Pallets (0 to disable)</Form.Label>
          <Form.Control
            as="input"
            type="number"
            name="freeShippingPallets"
            id="company-form-free-shipping-pallets"
            value={company.freeShippingPallets || "0"}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Initial Pallet Weight (Added to all pallets)</Form.Label>
          <Form.Control
            as="input"
            type="number"
            step="0.01"
            min="0"
            name="palletInitialWeight"
            id="company-form-pallet-initial-weight"
            value={company.palletInitialWeight || "0"}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Custom Message</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="customMessage"
            id="company-form-custom-message"
            value={company.customMessage || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Mail notifications &quot;From&quot; Email address</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="mailFromAddress"
            id="company-form-mail-from-address"
            value={company.mailFromAddress || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Label>Mail notifications &quot;Reply To&quot; Email address</Form.Label>
          <Form.Control
            as="input"
            type="text"
            name="mailReplyToAddress"
            id="company-form-mail-reply-to-address"
            value={company.mailReplyToAddress || ""}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="autoApproveOrders"
            label="Auto approve orders"
            checked={company.autoApproveOrders}
            onChange={handleCheckboxSelector}
          />
        </Col>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="autoPopulatePoNumber"
            label="Auto populate Orders Purchase Order Number"
            checked={company.autoPopulatePoNumber}
            onChange={handleCheckboxSelector}
          />
        </Col>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="showOrderShipDate"
            label="Show Order's Shipping Date"
            checked={company.showOrderShipDate}
            onChange={handleCheckboxSelector}
          />
        </Col>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="manuallyApproveFirstTimeCustomers"
            label="Manually Approve First Time Customers"
            checked={company.manuallyApproveFirstTimeCustomers}
            onChange={handleCheckboxSelector}
          />
        </Col>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="palletSelectorEnabled"
            label="Pallet Selector"
            checked={company.palletSelectorEnabled}
            onChange={handleCheckboxSelector}
          />
        </Col>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="showBillsOfLading"
            label="Show Bills of Ladings"
            checked={company.showBillsOfLading}
            onChange={handleCheckboxSelector}
          />
        </Col>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="forceDeliveryCreation"
            label="Force Delivery Creation"
            checked={company.forceDeliveryCreation}
            onChange={handleCheckboxSelector}
          />
        </Col>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="allowCreditCardUsage"
            label="Allow Credit Card Usage"
            checked={company.allowCreditCardUsage}
            onChange={handleCheckboxSelector}
          />
        </Col>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="allowOrderDiscounts"
            label="Allow Adding Discounts to Orders"
            checked={company.allowOrderDiscounts}
            onChange={handleCheckboxSelector}
          />
        </Col>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="allowAttachmentOrders"
            label="Allow Attachment Orders"
            checked={company.allowAttachmentOrders}
            onChange={handleCheckboxSelector}
          />
        </Col>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="deliveredStatusForDeliveries"
            label="Delivered Status For Orders Deliveries"
            checked={company.deliveredStatusForDeliveries}
            onChange={handleCheckboxSelector}
          />
        </Col>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            name="groupApprovedOrders"
            label="Group approved orders first"
            checked={company.groupApprovedOrders}
            onChange={handleCheckboxSelector}
          />
        </Col>
      </Row>
      <div className="text-end">
        <Button variant="success" onClick={handleCompanySave} disabled={hasInvalidEmail} id="company-form-confirm-btn">
          Save
        </Button>
      </div>
    </>
  ) : (
    <LoadingSplash />
  );
};

export default CompanyForm;
