import update from "immutability-helper";
import { handle } from "redux-pack";
import { CLEAR, CREATE, LIST, DESTROY, LIST_WAREHOUSES, BULK_UPDATE, CLEAR_ERROR } from "./actions";

const initialState = {
  loading: false,
  all: {
    items: [],
    included: [],
  },
  errors: null,
  warehouses: [],
};

const updateNotLoading = (prevState) => update(prevState, { loading: { $set: false } });

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};

  switch (type) {
    case CLEAR:
      return update(state, {
        all: {
          items: { $set: [] },
          included: { $set: [] },
        },
        warehouses: { $set: [] },
        loading: { $set: false },
      });

    case CLEAR_ERROR:
      return update(state, { error: { $set: false } });

    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: [] },
              included: { $set: [] },
            },
            loading: { $set: true },
          });
        },

        finish: updateNotLoading,

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              included: { $set: json.included },
            },
          });
        },
      });

    case LIST_WAREHOUSES:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            warehouses: { $set: [] },
            loading: { $set: true },
          });
        },

        finish: updateNotLoading,

        success: (prevState) => {
          return update(prevState, {
            warehouses: { $set: json.data },
          });
        },
      });

    case CREATE: {
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            loading: { $set: true },
          });
        },

        finish: updateNotLoading,

        success: (prevState) => {
          const includeNew = json.included.filter(
            (item) => !prevState.all.included.find((previous) => previous.type === item.type && previous.id === item.id)
          );
          return update(prevState, {
            all: {
              items: { $push: [json.data] },
              included: { $push: includeNew },
            },
          });
        },
      });
    }

    case DESTROY:
      return handle(state, action, {
        finish: updateNotLoading,

        success: (prevState) => {
          const {
            meta: { warehouseInventoryId },
          } = action;
          const warehouseInventories = { ...prevState.all };
          warehouseInventories.items = warehouseInventories.items.filter(
            (warehouseInventory) => warehouseInventory.id !== warehouseInventoryId
          );
          return update(prevState, {
            all: { $set: warehouseInventories },
          });
        },
      });

    case BULK_UPDATE:
      return handle(state, action, {
        finish: updateNotLoading,

        success: (prevState) => {
          const updatedItems = prevState.all.items.map((item) => {
            const newItem = json.data.find((dataItem) => dataItem.id === item.id);
            return newItem || item;
          });
          return update(prevState, { all: { items: { $set: updatedItems } }, error: { $set: false } });
        },

        failure: (prevState) => {
          return update(prevState, { error: { $set: "Something failed, try again later..." } });
        },
      });

    default:
      return state;
  }
}

export default reducer;
