import React from "react";
import PropTypes from "prop-types";
import Button from "@ROM-ui/Button";
import Form from "@ROM-ui/Form";

const Filters = ({ handleFilterChange, handleClearFilters, filters }) => {
  return (
    <div className="d-flex m-1">
      <Form.Control
        type="text"
        name="fullName"
        placeholder="Full Name"
        onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
        value={filters.fullName || ""}
        className="me-1"
      />
      <Form.Control
        type="text"
        name="email"
        placeholder="Email"
        onChange={(event) => handleFilterChange(event.target.name, event.target.value)}
        value={filters.email || ""}
        className="me-1"
      />
      <Button className="p-0 w-40" size="sm" variant="link" onClick={handleClearFilters}>
        Clear Filters
      </Button>
    </div>
  );
};

Filters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default Filters;
