import { checkPermission } from "@ROM/Permissions/utils";

export const canAccessUsersInCompanyAdmin = (user) => checkPermission(user, "company:admin:users");

export const canListUsers = (user, customerId) => checkPermission(user, "users:index", customerId);
export const canViewUsers = (user) => checkPermission(user, "users:show");
export const canEditUsers = (user) => checkPermission(user, "users:update");

export const canEditUsersRegions = (user) => checkPermission(user, "users:regions:update");

export const canEditCompanyRolesFromUsers = (user) => checkPermission(user, "users:company-roles:update");

export const canEditCustomerRolesFromUsers = (user, customerId = null) =>
  checkPermission(user, "users:customer-roles:update", customerId);

export const canAddCustomerToUsers = (user, customerId = null) => checkPermission(user, "users:customer:add", customerId);
export const canRemoveCustomerFromUsers = (user, customerId = null) => checkPermission(user, "users:customer:remove", customerId);

export const canFilterUsersByRegion = (user) => {
  const permission = checkPermission(user, "users:index");
  return permission?.scope === "all";
};
