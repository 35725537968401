import React, { useReducer, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { reducer as formReducer } from "@ROM/App/utils/forms";
import { selectAllCustomTables, selectError } from "@ROM/CustomTables/selectors";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";
import Select from "@ROM-components/common/Select";
import Button from "@ROM-ui/Button";
import Message from "@ROM-common/Message";
import MultiInputValue, { isEmail } from "@ROM-ui/MultiInputValue";
import Fieldset from "@ROM-ui/Fieldset";
import OptionalSection from "@ROM-ui/OptionalSection";
import FieldForm from "@ROM/CustomTables/components/FieldForm";
import FieldsList from "@ROM/CustomTables/components/FieldsList";
import { VALID_FIELD_NAME_REGEXP, VALID_TABLE_NAME_REGEXP } from "@ROM/CustomTables/constants";
import { create, update } from "@ROM/CustomTables/actions";
import { companyAdminCustomTablesShowUrl } from "@ROM-utils/urlHelpers";

const CustomTableForm = ({ table }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const error = useSelector(selectError);
  const customTables = useSelector(selectAllCustomTables).filter((currentTable) => currentTable.id !== table?.id);

  const tableId = table?.id || null;
  const initialTable = {
    name: table?.attributes.name || "",
    description: table?.attributes.description || "",
    fields: table?.attributes.fields || [],
    notifyOnRowCreation: table ? table.attributes.notifyOnRowCreation : false,
    notifyRecipients: table?.attributes.notifyRecipients || [],
    displayColumn: table?.attributes.displayColumn || null,
    defaultSortField: table?.attributes.defaultSortField || null,
    defaultSortOrder: table?.attributes.defaultSortOrder || null,
    relations: table?.attributes.relations || [],
  };

  const FIELD_SORT_OPTIONS = [
    { label: "asc", value: "ASC" },
    { label: "desc", value: "DESC" },
  ];

  const [formData, updateFormData] = useReducer(formReducer, initialTable);
  const [validationErrors, setValidationErrors] = useState({});

  const buildFieldsOptions = () => formData.fields.map((field) => ({ label: field.name, value: field.name }));

  const validateNewField = ({ field, index, onSuccess }) => {
    const fieldErrors = {};
    if (field.name === "") {
      fieldErrors.name = "Field name cannot be blank";
    } else if (!field.name.match(VALID_FIELD_NAME_REGEXP)) {
      fieldErrors.name =
        "Field name can have only lowercase letters, numbers, hyphens or underscores, and it must start with a letter.";
    } else if (formData.fields.some((currentField, currentIndex) => currentField.name === field.name && currentIndex !== index)) {
      fieldErrors.name = "Field name must be unique.";
    }
    if (field.type === "") {
      fieldErrors.type = "Field type cannot be blank.";
    }

    if (Object.keys(fieldErrors).length > 0) return fieldErrors;

    onSuccess();

    return {};
  };

  const handleNewField = (newField) => {
    return validateNewField({
      field: newField,
      onSuccess: () => {
        setValidationErrors({});
        const order = formData.fields.length;
        updateFormData({ fields: [...formData.fields, { ...newField, order }] });
      },
    });
  };

  const handleFieldUpdate = (index, newField) => {
    return validateNewField({
      field: newField,
      index,
      onSuccess: () => {
        const fields = [...formData.fields];
        fields[index] = newField;
        updateFormData({ fields });
      },
    });
  };

  const handleFieldReorder = (index, newIndex) => {
    const fields = formData.fields.map((item, itemIndex, preFields) => {
      let result = item;
      if (itemIndex === index) result = preFields[newIndex];
      if (itemIndex === newIndex) result = preFields[index];
      result.order = itemIndex;
      return result;
    });
    updateFormData({ fields });
  };

  const handleFieldDelete = (index) => {
    const defaultSortField = formData.defaultSortField === formData.fields[index].name ? null : formData.defaultSortField;
    const fields = formData.fields.filter((_, itemIndex) => itemIndex !== index);
    updateFormData({ defaultSortField, fields });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    setValidationErrors({});

    const tableErrors = {};

    if (formData.name === "") {
      tableErrors.name = "Table name can't be empty.";
    } else if (!formData.name.match(VALID_TABLE_NAME_REGEXP)) {
      tableErrors.name =
        "Table name can have only lowercase letters, numbers, hyphens or underscores, and it must start with a letter.";
    }

    if (formData.fields.length === 0) {
      tableErrors.fields = "Fields can't be empty.";
    }

    window.scrollTo(0, 0);

    if (Object.keys(tableErrors).length === 0) {
      if (tableId === null) {
        const response = await dispatch(create(formData));
        if (!response.error) {
          const newTableId = response.payload.data.data.id;
          history.push(companyAdminCustomTablesShowUrl(newTableId), { message: "Table created successfully" });
        }
      } else {
        const response = await dispatch(update(tableId, formData));
        if (!response.error) {
          history.push(companyAdminCustomTablesShowUrl(tableId), { message: "Table updated successfully" });
        }
      }
    } else {
      setValidationErrors(tableErrors);
    }
  };

  const buildRelationTablesOptions = () =>
    customTables.map((currentTable) => ({ label: currentTable.attributes.name, value: currentTable.attributes.name }));

  const buildRelationTablesSelectValue = () => {
    const currentRelations = formData.relations.map((relation) => relation.withTable);

    return customTables
      .filter((currentTable) => currentRelations.includes(currentTable.attributes.name))
      .map((currentTable) => ({ label: currentTable.attributes.name, value: currentTable.attributes.name }));
  };

  const handleRelationsSelectChange = (event) => {
    let newValue = [];

    if (event) {
      newValue = event.map((relation) => ({ withTable: relation.value }));
    }

    updateFormData({ relations: newValue });
  };

  useEffect(() => {
    if (formData.fields.length === 0) {
      updateFormData({ displayColumn: null });
    }

    const columns = formData.fields.map((field) => field.name);

    if (formData.displayColumn == null || !columns.includes(formData.displayColumn)) {
      updateFormData({ displayColumn: columns[0] });
    }
  }, [formData.fields]);

  return (
    <Form className="mb-3">
      <Message message={error} type="danger" />
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          required
          isInvalid={!!validationErrors.name}
          type="text"
          value={formData.name}
          onChange={(e) => updateFormData({ name: e.target.value })}
        />
        <Form.Control.Feedback type="invalid">{validationErrors.name}</Form.Control.Feedback>
      </Form.Group>
      <div className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={4}
          value={formData.description}
          onChange={(e) => updateFormData({ description: e.target.value })}
        />
      </div>
      <div className="mb-3">
        <Fieldset legend="Fields" showError={!!validationErrors.fields}>
          <FieldsList
            fields={formData.fields}
            updateField={handleFieldUpdate}
            reorderField={handleFieldReorder}
            deleteField={handleFieldDelete}
            showError={!!validationErrors.fields}
            className="mb-3"
          />
          <FieldForm handleSubmit={handleNewField} />
        </Fieldset>
      </div>
      {formData.fields.length > 0 && (
        <Row className="mt-4">
          <Col className="mb-3" md={4}>
            <Form.Label>Display column</Form.Label>
            <Select
              options={buildFieldsOptions()}
              value={buildFieldsOptions().find(({ value }) => value === formData.displayColumn) || ""}
              onChange={(e) => updateFormData({ displayColumn: e.value })}
            />
          </Col>
          <Col className="mb-3" md={4}>
            <Form.Label>Default Sort Field</Form.Label>
            <Select
              options={buildFieldsOptions()}
              value={buildFieldsOptions().find(({ value }) => value === formData.defaultSortField) || ""}
              onChange={(e) => updateFormData({ defaultSortField: e.value })}
            />
          </Col>
          <Col className="mb-3" md={4}>
            <Form.Label>Default Sort Order</Form.Label>
            <Select
              options={FIELD_SORT_OPTIONS}
              value={FIELD_SORT_OPTIONS.find(({ value }) => value === formData.defaultSortOrder) || ""}
              onChange={(e) => updateFormData({ defaultSortOrder: e.value })}
            />
          </Col>
        </Row>
      )}
      <div className="mb-3">
        <Fieldset legend="Relations">
          <Select
            isMulti
            options={buildRelationTablesOptions()}
            value={buildRelationTablesSelectValue()}
            onChange={handleRelationsSelectChange}
          />
        </Fieldset>
      </div>
      <div className="mb-3">
        <OptionalSection show={formData.notifyOnRowCreation}>
          <OptionalSection.Legend>
            <Form.Check
              label="Notify On Row Creation"
              checked={formData.notifyOnRowCreation}
              onChange={() => updateFormData({ notifyOnRowCreation: !formData.notifyOnRowCreation })}
              type="checkbox"
            />
          </OptionalSection.Legend>
          <OptionalSection.Body>
            <Form.Label>Recipients To Notify</Form.Label>
            <MultiInputValue
              values={formData.notifyRecipients}
              onChange={(newValues) => updateFormData({ notifyRecipients: newValues })}
              validate={isEmail}
              validateMessage={(value) => `${value} is not a valid email`}
              placeholder="Email addresses and press Enter"
            />
          </OptionalSection.Body>
        </OptionalSection>
      </div>
      <div className="text-end pt-2">
        <Button onClick={handleCancel} variant="danger" className="me-3">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="success">
          Save
        </Button>
      </div>
    </Form>
  );
};

CustomTableForm.propTypes = {
  table: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({})),
      notifyOnRowCreation: PropTypes.bool,
      notifyRecipients: PropTypes.arrayOf(PropTypes.string),
      displayColumn: PropTypes.string,
      defaultSortField: PropTypes.string,
      defaultSortOrder: PropTypes.string,
      relations: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

CustomTableForm.defaultProps = {
  table: null,
};

export default CustomTableForm;
