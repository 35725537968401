import { checkPermission } from "@ROM/Permissions/utils";
import { customerRelatedUser } from "@ROM/Customers/utils";

const hasAddressPermission = (user, customerId, permission) =>
  checkPermission(user, permission) || customerRelatedUser(user, customerId);

export const canCreateAddresses = (user, customerId = null) => hasAddressPermission(user, customerId, "addresses:create");

export const canEditAddresses = (user, customerId = null) => hasAddressPermission(user, customerId, "addresses:update");

export const canDeleteAddresses = (user, customerId = null) => hasAddressPermission(user, customerId, "addresses:delete");
