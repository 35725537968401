import React from "react";
import Modal from "@ROM-components/common/Modal";
import OrderItemDetail from "@ROM/Orders/components/OrderItems/OrderItemDetail";
import PropTypes from "prop-types";

const OrderItemModal = ({ show = false, onCancel, item }) => {
  return (
    <Modal
      isShowing={show}
      header={"Product Information"}
      confirmText="Save"
      showCloseIcon={true}
      showCancelButton={false}
      showConfirmButton={false}
      onCancel={() => onCancel()}
    >
      <OrderItemDetail item={item} />
    </Modal>
  );
};

OrderItemModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  item: PropTypes.func,
};

export default OrderItemModal;
