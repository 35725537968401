import CustomersApi from "@ROM/Customers/api";
import UsersApi from "@ROM/Users/api";

export const customerRelatedUser = (user, customerId) => user?.attributes.customerIds.includes(customerId) || false;

export async function loadOptions(searchInput, _loadedOptions, { page }) {
  const result = await CustomersApi.list({
    name_or_primary_contact: searchInput,
    page,
  });

  const options = result.data.data.map((option) => {
    const { name, primaryContact } = option.attributes;
    const labelWithContact = primaryContact ? `${name} - ${primaryContact}` : name;

    return {
      value: option.id,
      label: labelWithContact,
    };
  });
  return {
    options,
    hasMore: result.data.meta.pagination.currentPage < result.data.meta.pagination.totalPages,
    additional: {
      page: page + 1,
    },
  };
}

export const fetchUsers = async (fullName, _loadedOptions, additional) => {
  const { page, roles, onlyUsersWithNoRole } = additional;

  const result = await UsersApi.list({ fullName, page, roles, onlyUsersWithNoRole });

  const options = result.data.data.map((option) => ({
    value: option.id,
    label: option.attributes.fullName,
  }));

  return {
    options,
    hasMore: result.data.meta.pagination.currentPage < result.data.meta.pagination.totalPages,
    additional: { ...additional, page: page + 1 },
  };
};

export { fetchUsers as default };
