import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import _ from "lodash";
import { reducer } from "@ROM/App/utils/forms";
import Form from "@ROM-ui/Form";
import { bolBillingInformation } from "@ROM/BillOfLadings/utils";

import { update as updateBillOfLading } from "@ROM/BillOfLadings/actions";

const BillingInformation = ({ currentBillOfLading, company, canEditAllParams }) => {
  const dispatch = useDispatch();

  const initialFormData = bolBillingInformation(currentBillOfLading, company);
  const [formData, setFormData] = useState(initialFormData);

  const updateRequest = useCallback(
    _.debounce((bolId, attributes) => dispatch(updateBillOfLading(bolId, attributes)), 500),
    []
  );

  const valueChanged = (event) => {
    const {
      target: {
        dataset: { field },
        value,
      },
    } = event;

    const next = reducer(formData, { useCompanyBillingInformation: false, [field]: value });
    setFormData(next);
    updateRequest(currentBillOfLading.id, next);
  };

  return (
    <>
      {formData.useCompanyBillingInformation && (
        <div className="small color-gray mb-4">Using company&apos;s billing information. Edit to change.</div>
      )}
      <div className="d-flex">
        <Form.Label>Name</Form.Label>
      </div>
      <div>
        <Form.Control
          type="text"
          data-field="billingName"
          value={formData.billingName}
          onChange={valueChanged}
          disabled={!canEditAllParams}
        />
      </div>

      <div className="d-flex mt-4">
        <Form.Label>Address 1</Form.Label>
      </div>
      <div>
        <Form.Control
          type="text"
          data-field="billingAddress1"
          value={formData.billingAddress1}
          onChange={valueChanged}
          disabled={!canEditAllParams}
        />
      </div>
      <div className="d-flex mt-4">
        <Form.Label>Address 2 (optional)</Form.Label>
      </div>
      <div>
        <Form.Control
          type="text"
          data-field="billingAddress2"
          value={formData.billingAddress2}
          onChange={valueChanged}
          disabled={!canEditAllParams}
        />
      </div>
      <div className="d-flex mt-4">
        <Form.Label className="w-50">City</Form.Label>
        <Form.Label className="w-25">State</Form.Label>
        <Form.Label className="w-25">Zip</Form.Label>
      </div>
      <div className="d-flex">
        <Form.Control
          className="w-50 mb-4"
          type="text"
          data-field="billingCity"
          value={formData.billingCity}
          onChange={valueChanged}
          disabled={!canEditAllParams}
        />
        <Form.Control
          className="w-25 ms-2 mb-4"
          type="text"
          data-field="billingState"
          value={formData.billingState}
          onChange={valueChanged}
          disabled={!canEditAllParams}
        />
        <Form.Control
          className="w-25 ms-2 mb-4"
          type="text"
          data-field="billingZip"
          value={formData.billingZip}
          onChange={valueChanged}
          disabled={!canEditAllParams}
        />
      </div>
    </>
  );
};

BillingInformation.propTypes = {
  currentBillOfLading: PropTypes.shape({
    id: PropTypes.number,
    attributes: {
      billingAddress1: PropTypes.string,
      billingAddress2: PropTypes.string,
      billingCity: PropTypes.string,
      billingState: PropTypes.string,
      billingZip: PropTypes.number,
    },
  }).isRequired,
  company: PropTypes.shape({
    attributes: {
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    },
  }).isRequired,
  canEditAllParams: PropTypes.bool.isRequired,
};

export default BillingInformation;
