import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import Col from "@ROM-ui/Col";

import { list, listWarehouses, bulkUpdate } from "@ROM/WarehouseInventories/actions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import {
  selectLoading,
  selectAllIncluded,
  selectAllWarehouseInventories,
  selectWarehouses,
} from "@ROM/WarehouseInventories/selectors";
import ContentHeader from "@ROM-components/common/ContentHeader";
import WarehouseInventoriesList from "@ROM/WarehouseInventories/components/List";
import Button from "@ROM/WarehouseInventories/components/common/Button";

const Index = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const loading = useSelector(selectLoading);
  const warehouses = useSelector(selectWarehouses);
  const inventories = useSelector(selectAllWarehouseInventories);
  const included = useSelector(selectAllIncluded);

  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);

  const products = included
    .filter((item) => item.type === "productMinimal")
    .reduce((current, item) => ({ ...current, [item.id]: item.attributes }), {});

  useEffect(() => {
    dispatch(listWarehouses());
  }, []);

  useEffect(() => {
    if (selectedWarehouseId === null && warehouses.length > 0) {
      setSelectedWarehouseId(warehouses[0].id);
    }
    if (warehouses.length === 0 && selectedWarehouseId !== null) {
      setSelectedWarehouseId(null);
    }
  }, [warehouses]);

  useEffect(() => {
    if (selectedWarehouseId) {
      dispatch(list({ warehouseId: selectedWarehouseId }));
    }
  }, [selectedWarehouseId]);

  const onInventoryUpdate = (warehouseInventories) => dispatch(bulkUpdate({ warehouseInventories }));

  return (
    <>
      <ContentHeader>
        <Col className="col-md-4">
          <h3 className="fw-bolder text-white">Inventory</h3>
        </Col>
        <Col className="col-md-8 text-end">
          {warehouses.map((warehouse) => (
            <Button
              key={warehouse.id}
              onClick={() => setSelectedWarehouseId(warehouse.id)}
              selected={warehouse.id === selectedWarehouseId}
            >
              {warehouse.attributes.name}
              <div className="small">{warehouse.attributes.displayName}</div>
            </Button>
          ))}
        </Col>
      </ContentHeader>

      <ScrollableDiv className="shadow-sm p-2 bg-white rounded-2 mb-2">
        {loading ? (
          <Skeleton width="100%" height="35px" count="10" />
        ) : (
          <WarehouseInventoriesList
            currentUser={currentUser}
            onInventoryUpdate={onInventoryUpdate}
            warehouseInventories={inventories}
            products={products}
          />
        )}
      </ScrollableDiv>
    </>
  );
};

const ScrollableDiv = styled.div`
  overflow-x: auto;
  .row {
    flex-wrap: nowrap;
  }
  @media (min-width: 768px) {
    .row:not(:first-child) {
      background-color: #f8f9fa !important;
    }
  }
`;

export default Index;
