import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "@ROM-ui/Button";
import Modal from "@ROM-components/common/Modal";
import Form from "@ROM-ui/Form";
import NumberFormater from "@ROM-ui/NumberFormater";

const Refund = ({ size, onRefund, transaction, customer }) => {
  const [amountCents, setAmountCents] = useState(NaN);
  const [refundAll, setRefundAll] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const totalAmount = transaction.attributes.amount;
  const customerName = customer.attributes.name;
  const refoundedAmount = transaction.attributes.refunds.reduce((totalRef, refund) => totalRef + refund.amount, 0);

  const handleRefund = async () => {
    try {
      if (refundAll) {
        await onRefund(null);
      } else if (amountCents > 0) {
        await onRefund(amountCents);
      }
    } catch (e) {
      setError(e.data.detail);
    }
  };

  const onModalClose = () => {
    setAmountCents(NaN);
    setRefundAll(false);
    setError(null);

    setShowConfirmModal(false);
    setShowModal(false);
  };

  const handleShowConfirm = () => {
    if (!refundAll && !(amountCents > 0)) {
      setError("The amount should be greater than 0");
      return;
    }
    setShowModal(false);
    setShowConfirmModal(true);
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)} size={size} variant="secondary">
        Refund charge
      </Button>

      <Modal
        isShowing={showModal}
        onConfirm={handleShowConfirm}
        onCancel={onModalClose}
        header="Refund Charge"
        showCloseIcon={false}
      >
        <Form.Check
          type="checkbox"
          checked={refundAll}
          label="Refund all charge"
          name="refundAll"
          onChange={() => setRefundAll(!refundAll)}
        />
        {!refundAll && (
          <>
            <Form.Label className="mt-2">Amount to refund</Form.Label>
            <NumberFormater
              value={amountCents}
              displayType="input"
              className="d-block w-100 rounded border p-2 bg-light"
              onValueChange={({ floatValue }) => setAmountCents(floatValue * 100)}
            />
            {error && <div className="text-danger mt-2">{error}</div>}
          </>
        )}
      </Modal>

      <Modal
        isShowing={showConfirmModal}
        onConfirm={handleRefund}
        onCancel={onModalClose}
        header="Are you sure?"
        showCloseIcon={false}
      >
        <div className="d-flex">
          <p className="m-0">Do you want to refund</p>
          <NumberFormater value={refundAll ? totalAmount - refoundedAmount : amountCents} className="mx-1 my-0" />
          <p className="m-0">{`to customer ${customerName}?`}</p>
        </div>
      </Modal>
    </>
  );
};

Refund.defaultProps = {
  size: null,
};

Refund.propTypes = {
  size: PropTypes.string,
  onRefund: PropTypes.func.isRequired,
};

export default Refund;
