import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Button from "@ROM-ui/Button";
import Form from "@ROM-ui/Form";
import { getFilteredProducts, find, unsetCurrentProduct, featured } from "@ROM/Products/actions";
import useIsMobile from "@ROM-components/utils/Responsive";
import Modal from "@ROM-components/common/Modal";
import ProductDetail from "@ROM/Products/components/Selector/Detail";
import { selectProducts, selectFeaturedProducts } from "@ROM/Products/selectors";
import ProductList from "./ProductList";
import ProductGrid from "./Grid";
/**
 * Product selector for new Orders
 */

const ProductsModal = ({ products, actions, featuredProducts, customer, show, onCancel, handleRemoveItem }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [initialized, setInitialized] = useState(false);
  const [detailsTabActive, setDetailsTabActive] = useState(true);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const isProductSelected = selectedProductId !== null;
  const [relatedProducts, setRelatedProducts] = useState([]);
  const isMobile = useIsMobile();

  const handleGoBack = () => {
    setSelectedProductId(null);
    actions.unsetCurrentProduct();
  };

  const handleProductSearch = (term) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    if (!initialized) {
      actions.fetchFeaturedProducts({ customerId: customer.id });
      actions.getFilteredProducts({}, { customerId: customer.id });
      setInitialized(true);
    } else {
      const timeOutId = setTimeout(() => actions.getFilteredProducts({ name: searchTerm }, { customerId: customer.id }), 500);
      return () => clearTimeout(timeOutId);
    }
    return undefined;
  }, [searchTerm]);

  return (
    <Modal
      isShowing={show}
      onAfterOpen={() => setSearchTerm("")}
      onCancel={() => onCancel()}
      showCancelButton={false}
      showConfirmButton={false}
      showCloseIcon={false}
      bodyClasses="p-0"
      size="xx-large"
    >
      <div className="d-flex flex-wrap">
        <Col xs={12} lg={8} className="rounded pe-0 ps-0">
          <StyledRowTop className="d-flex align-items-center justify-content-between px-4">
            {initialized && (
              <>
                <Form.Control
                  as="input"
                  placeholder="Search products"
                  value={searchTerm}
                  onChange={(ev) => handleProductSearch(ev.target.value)}
                  className="w-100 me-2"
                  autoFocus
                />
                <StyledClose className="fa fa-times-circle" onClick={onCancel} />
              </>
            )}
          </StyledRowTop>
          <ProductList
            products={initialized ? products : []}
            customer={customer}
            nameFilter={searchTerm}
            selectedProductId={selectedProductId}
            setSelectedProductId={setSelectedProductId}
            setDetailsTabActive={setDetailsTabActive}
            handleRemoveItem={handleRemoveItem}
          />
          <StyledRowBottom className="d-flex align-items-center justify-content-end px-4">
            <Button variant="outline-primary" className="px-5" onClick={onCancel} id="products-modal-done">
              Done
            </Button>
          </StyledRowBottom>
        </Col>

        {!isMobile && (
          <RightCol xs={12} lg={4}>
            {isProductSelected ? (
              <>
                <BackRow className="px-4">
                  <PointerDiv onClick={() => handleGoBack()}>
                    <i className="fas fa-chevron-left pt-1 me-1" />
                    <strong>Back to Featured Products</strong>
                  </PointerDiv>
                </BackRow>
                <StyledTabsRow className="px-0">
                  <Tab active={detailsTabActive} onClick={() => setDetailsTabActive(true)}>
                    <strong>Product Details</strong>
                  </Tab>
                  {relatedProducts.length > 0 && (
                    <Tab active={!detailsTabActive} onClick={() => setDetailsTabActive(false)}>
                      <strong>Related Products</strong>
                    </Tab>
                  )}
                </StyledTabsRow>
                {detailsTabActive ? (
                  <ProductDetail productId={selectedProductId} setRelatedProducts={setRelatedProducts} />
                ) : (
                  <ProductGrid
                    initialized={initialized}
                    products={relatedProducts}
                    setSelectedProductId={setSelectedProductId}
                    setDetailsTabActive={setDetailsTabActive}
                  />
                )}
              </>
            ) : (
              <>
                <StyledRow className="p-4">
                  <strong>Featured Products</strong>
                </StyledRow>
                <ProductGrid
                  initialized={initialized}
                  products={featuredProducts}
                  setSelectedProductId={setSelectedProductId}
                  setDetailsTabActive={setDetailsTabActive}
                />
              </>
            )}
          </RightCol>
        )}
      </div>
    </Modal>
  );
};

ProductsModal.propTypes = {
  products: PropTypes.array,
  featuredProducts: PropTypes.array,
  orderId: PropTypes.string,
  actions: PropTypes.object,
  customer: PropTypes.object,
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  handleRemoveItem: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
  return {
    products: selectProducts(state),
    featuredProducts: selectFeaturedProducts(state),
  };
};

const dispatchToProps = (dispatch) => {
  return {
    actions: {
      getFilteredProducts: bindActionCreators(getFilteredProducts, dispatch),
      find: bindActionCreators(find, dispatch),
      unsetCurrentProduct: bindActionCreators(unsetCurrentProduct, dispatch),
      fetchFeaturedProducts: bindActionCreators(featured, dispatch),
    },
  };
};

export default connect(stateToProps, dispatchToProps)(ProductsModal);

const StyledClose = styled.i`
  font-size: x-large;
  cursor: pointer;
`;

const StyledRowTop = styled.div`
  background-color: #ebebeb;
  border-top-right-radius: 0.25rem;
  height: 90px;
`;

const StyledRowBottom = styled.div`
  background-color: #ebebeb;
  border-bottom-right-radius: 0.25rem;
  height: 90px;
`;

const RightCol = styled(Col)`
  background-color: #353036;
`;

const Tab = styled.div`
  background-color: ${(props) => (props.active ? "#342F36" : "#2d282f")};
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledTabsRow = styled(Row)`
  height: 45px;
  color: white;
  align-content: center;
`;

const BackRow = styled(Row)`
  height: 45px;
  background-color: #211d22;
  color: #96c93d;
  align-content: center;
`;

const PointerDiv = styled(Row)`
  cursor: pointer;
`;

const StyledRow = styled.div`
  height: 90px;
  background-color: #2d282f;
  color: white;
`;
