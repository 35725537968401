import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectUserCompanies } from "@ROM/Auth/selectors";
import Row from "@ROM-ui/Row";
import Company from "../Company/index";

const ContainerPanel = styled.div`
  background: #fff;
  border-radius: 15px;
`;

const CompanyList = () => {
  const companies = useSelector(selectUserCompanies);
  return (
    <ContainerPanel className="col-sm-4">
      <Row className="text-dark">
        {companies.map((company) => (
          <Company key={company.id} company={company} />
        ))}
      </Row>
    </ContainerPanel>
  );
};

export default CompanyList;
