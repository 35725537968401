import React from "react";
import PropTypes from "prop-types";

import Item from "@ROM/CompanyAdmin/Company/Roles/Item";

const ItemsGroup = ({ groupName, items, selectedItems, add, remove, updateScope, className }) => {
  return (
    <div className={className}>
      <div className="d-flex align-items-center mb-2">
        <h5 className="text-nowrap me-3">{groupName}</h5>
        <div className="w-100 bg-dark" style={{ height: "1px" }} />
      </div>

      {items.map((item) => {
        const selectedPermission = selectedItems.find((p) => item.id === p.id);
        const isSelected = !!selectedPermission;
        return (
          <Item
            key={item.id}
            item={item}
            isSelected={isSelected}
            selectedScope={isSelected && selectedPermission.scope}
            add={add}
            remove={remove}
            updateScope={updateScope}
            className="mb-2"
          />
        );
      })}
    </div>
  );
};

ItemsGroup.defaultProps = {
  className: "",
};

ItemsGroup.propTypes = {
  className: PropTypes.string,
  groupName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  updateScope: PropTypes.func.isRequired,
};

export default ItemsGroup;
