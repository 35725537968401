import { apiAction } from "@ROM-utils/actions";
import EmailGroupsApi from "./api";

export const FETCH_EMAIL_GROUPS = "emailGroups/api/FETCH";
export const CREATE_EMAIL_GROUP = "emailGroups/api/CREATE";
export const UPDATE_EMAIL_GROUP = "emailGroups/api/UPDATE";
export const DESTROY_EMAIL_GROUP = "emailGroups/api/DESTROY";

export function fetch() {
  return apiAction(FETCH_EMAIL_GROUPS, EmailGroupsApi.fetch());
}

export function create(attributes) {
  return apiAction(CREATE_EMAIL_GROUP, EmailGroupsApi.create(attributes));
}

export function update(groupId, attributes) {
  return apiAction(UPDATE_EMAIL_GROUP, EmailGroupsApi.update(groupId, attributes));
}

export function destroy(groupId) {
  return apiAction(DESTROY_EMAIL_GROUP, EmailGroupsApi.destroy(groupId), { emailGroupId: groupId });
}
